import {
  GetSearchResponseBody,
  GlobalSearchCompany,
  GlobalSearchContact,
  GlobalSearchProject,
} from '@basisboard/basis-common/lib/api'
import { GlobalSearchEmail } from '@basisboard/basis-common/lib/api/email'
import { SearchEntity } from '@basisboard/basis-common/lib/enums'
import { Container } from '@containrz/react-hook'
import qs from 'qs'
import { get } from '../../api'

export const search = (term: string, includeEntities: SearchEntity[] = []) =>
  get<GetSearchResponseBody>(`/search?${qs.stringify({ q: term, includeEntities })}`)

interface State {
  loading: boolean
  results: GetSearchResponseBody & {
    all: (GlobalSearchProject | GlobalSearchCompany | GlobalSearchContact | GlobalSearchEmail)[]
  }
}

export class SearchState extends Container<State> {
  state = {
    loading: false,
    results: null,
  }

  searchForTerm = (term: string) => {
    this.setState({ loading: true, results: null })

    search(term).then(result =>
      this.setState({
        loading: false,
        results: {
          ...result.data,
          all: [
            ...result.data.projects,
            ...result.data.companies,
            ...result.data.contacts,
            ...result.data.emails,
          ].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
        },
      }),
    )
  }
}
