"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getScrollableParent = exports.cssFontSize = exports.cssCircle = exports.cssSize = exports.hexToRgba = void 0;
const styled_components_1 = require("styled-components");
const hexToRgba = (color, alpha) => {
    const r = parseInt(color.substring(1, 3), 16);
    const g = parseInt(color.substring(3, 5), 16);
    const b = parseInt(color.substring(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
exports.hexToRgba = hexToRgba;
const cssSize = (s, applyLineHeight = false) => (0, styled_components_1.css) `
  width: ${s}px;
  min-width: ${s}px;
  max-width: ${s}px;

  height: ${s}px;
  min-height: ${s}px;
  max-height: ${s}px;

  ${applyLineHeight && `line-height: ${s}px`};
`;
exports.cssSize = cssSize;
const cssCircle = (s) => (0, styled_components_1.css) `
  border-radius: ${s}px;
  ${(0, exports.cssSize)(s)};
`;
exports.cssCircle = cssCircle;
const cssFontSize = (s) => (0, styled_components_1.css) `
  font-size: ${s / 16}rem;
`;
exports.cssFontSize = cssFontSize;
const getScrollableParent = (target) => {
    const isScrollable = (el) => ['auto', 'scroll'].includes(getComputedStyle(el).overflow) ||
        ['auto', 'scroll'].includes(getComputedStyle(el).overflowY) ||
        ['auto', 'scroll'].includes(getComputedStyle(el).overflowX);
    let scrollableElement = target;
    while (!isScrollable(scrollableElement) && scrollableElement.tagName.toUpperCase() !== 'BODY') {
        scrollableElement = scrollableElement.parentElement;
    }
    return scrollableElement;
};
exports.getScrollableParent = getScrollableParent;
