"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZoomIn = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const ZoomIn = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { fill: color, d: "M14.7304338,15.0194798 L10.1703343,10.2770826 C11.2993066,9.18931268 12.0037338,7.66398638 12.0037338,5.9760892 C12.0037338,2.68088075 9.322923,-1.98818531e-15 6.0276446,-1.98818531e-15 C2.7323662,-1.98818531e-15 0.0515553991,2.6808108 0.0515553991,5.9760892 C0.0515553991,9.27129765 2.7323662,11.9521784 6.0276446,11.9521784 C7.25699953,11.9521784 8.40073192,11.5786991 9.35181362,10.9396779 L13.9741014,15.7467817 C14.0771423,15.8538798 14.21467,15.9078136 14.3523376,15.9078136 C14.4831498,15.9078136 14.6141718,15.8591962 14.7158836,15.7613319 C14.9247634,15.5604268 14.931269,15.2282897 14.7304338,15.0194798 Z M1.10085117,5.9760892 C1.10085117,3.25946244 3.31101784,1.04929577 6.0276446,1.04929577 C8.74427136,1.04929577 10.954438,3.25946244 10.954438,5.9760892 C10.954438,8.69271596 8.74427136,10.9028826 6.0276446,10.9028826 C3.31101784,10.9028826 1.10085117,8.69271596 1.10085117,5.9760892 Z" }),
    React.createElement("path", { fill: color, d: "M8.70320892,5.45144131 L6.55229249,5.45144131 L6.55229249,3.30052488 C6.55229249,3.01070939 6.31746009,2.775877 6.0276446,2.775877 C5.73782911,2.775877 5.50299671,3.01070939 5.50299671,3.30052488 L5.50299671,5.45144131 L3.35201033,5.45144131 C3.06219484,5.45144131 2.82736244,5.68627371 2.82736244,5.9760892 C2.82736244,6.26590469 3.06219484,6.50073709 3.35201033,6.50073709 L5.50292676,6.50073709 L5.50292676,8.65172347 C5.50292676,8.94153897 5.73775915,9.17637136 6.02757465,9.17637136 C6.31739014,9.17637136 6.55222254,8.94153897 6.55222254,8.65172347 L6.55222254,6.50073709 L8.70313897,6.50073709 C8.99295446,6.50073709 9.22778685,6.26590469 9.22778685,5.9760892 C9.22778685,5.68627371 8.99295446,5.45144131 8.70320892,5.45144131 Z" })));
exports.ZoomIn = ZoomIn;
