import { colors } from '@basisboard/basis-ui/es/styles'
import { rem } from 'polished'
import styled from 'styled-components'

export const WrapperDiv = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export const Input = styled.input`
  font-size: ${rem(14)};
  color: ${colors.darkBlue};
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  min-width: 120px;
  font-weight: 500;
`
