import * as React from 'react'
import { ActionsWrapper, BodyRow, Table, Td, Th } from './styled'

interface Value {
  [key: string]: React.ReactNode
  onClick?: () => void
}

export interface Props {
  columns: {
    label: string
    key: keyof Value
    width?: number | string
  }[]
  values: Value[]
  renderEmpty?: () => React.ReactElement
}

export const DetailsTable: React.FC<Props> = ({ columns, values, renderEmpty }) => {
  if (!values?.length) {
    return renderEmpty?.() || null
  }

  return (
    <Table>
      <thead>
        <tr>
          {columns.map((c, index) => (
            <Th
              width={c.width ? c.width : index === columns.length - 1 ? 'auto' : 1 / columns.length}
              key={c.label}
            >
              {c.label}
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {values.map((val, index) => (
          <BodyRow
            key={`row-${index}`}
            onClick={
              val.onClick
                ? () => window.getSelection().type !== 'Range' && val.onClick()
                : undefined
            }
          >
            {columns.map(c => (
              <Td key={c.label}>{val[c.key]}</Td>
            ))}

            {val.actions && (
              <td
                style={{
                  verticalAlign: 'middle',
                }}
              >
                <ActionsWrapper>{val.actions}</ActionsWrapper>
              </td>
            )}
          </BodyRow>
        ))}
      </tbody>
    </Table>
  )
}
