import {
  Company,
  FilterEntity,
  FilterOperation,
  FilterRoot,
  USER_ID,
} from '@basisboard/basis-common/lib/api'
import { capitalize, getUniqId, readableName } from '@basisboard/basis-ui/es/utils'
import { getContainer } from '@containrz/react-hook'
import equals from 'ramda/src/equals'
import { StagesState } from '../Stages/container'
import { UsersContainer } from '../Users'
import { CustomFilter, FilterCondition } from './types'

export const clearFilter = filter =>
  Object.keys(filter).reduce((acc, key) => {
    const val = filter[key]
    switch (key) {
      case 'estimatorIds':
        return { ...acc, ...(!val || val.length === 0 ? {} : { estimatorIds: val }) }

      case 'companies':
        return { ...acc, ...(!val || val.length === 0 ? {} : { companies: val }) }

      case 'stageIds':
        return { ...acc, ...(!val || val.length === 0 ? {} : { stageIds: val }) }

      case 'bidDeadline':
      case 'dueDate':
      case 'projectId':
        return {
          ...acc,
        }

      case 'changeState':
        return {
          ...acc,
          ...(!val ? {} : { changeState: val }),
        }

      case 'showDeleted':
        return {
          ...acc,
          ...(!val ? {} : { showDeleted: val }),
        }

      case 'showOnlyDeleted':
        return {
          ...acc,
          ...(!val ? {} : { showOnlyDeleted: val }),
        }

      case 'showArchived':
        return {
          ...acc,
          ...(!val ? {} : { showArchived: val }),
        }

      case 'showDeclined':
        return {
          ...acc,
          ...(!val ? {} : { showDeclined: val }),
        }

      case 'showOnlyArchived':
        return {
          ...acc,
          ...(!val ? {} : { showOnlyArchived: val }),
        }

      case 'deadlineHistoryType':
        return {
          ...acc,
          ...(!val ? {} : { deadlineHistoryType: val }),
        }

      case 'customFilter':
        return {
          ...acc,
          ...(Object.keys(val || {}).length > 0 ? { customFilter: val } : {}),
        }

      default:
        return { ...acc, [key]: filter[key] }
    }
  }, {})

export const getReadableFilter = (customFilters: CustomFilter[], key: string, value: any) => {
  const stagesData = getContainer(StagesState)
  const usersContainer = getContainer(UsersContainer)

  switch (key) {
    case 'estimatorIds':
      return `Estimated by ${(value as USER_ID[])
        .map(userId => usersContainer.state.allUsers.find(u => u.id === userId))
        .map(user => user && readableName(user))
        .join(', ')}`

    case 'assigneeUserIds':
      return `Assigned to ${(value as USER_ID[])
        .map(userId => usersContainer.state.allUsers.find(u => u.id === userId))
        .map(user => user && readableName(user))
        .join(', ')}`

    case 'status':
      return `Status is ${value}`

    case 'companies':
      return `Bids for ${(value as Company).name}`

    case 'stageIds':
      return `${
        stagesData.state.stages.length === value.length
          ? 'All'
          : value.length <= 3
          ? value.map(s => (stagesData.state.stages.find(ss => ss.id === s) || {}).name).join(', ')
          : 'Various'
      } ${value.length === 1 ? 'stage' : 'stages'}`

    case 'projectId':
      return 'Selected bid'

    case 'showOnlyDeleted':
      return 'Deleted bids'

    case 'showDeleted':
      return 'Include deleted bids'

    case 'showArchived':
      return 'Include archived bids'

    case 'showDeclined':
      return 'Include declined bids'

    case 'showOnlyArchived':
      return 'Archived bids'

    case 'changeState':
      return `${capitalize(value)} bids`

    case 'deadlineHistoryType':
      return value

    case 'customFilter':
      const customFilter = customFilters.find(f => equals(f.filter, value))
      return customFilter?.name || ''

    default:
      return ''
  }
}

export const mapConditionsToFilterRoot = (conditions: FilterCondition[]): FilterEntity[] =>
  conditions.map(
    c =>
      ({
        compareValue: c.fieldValue ?? 'empty',
        operation: c.operation,
        ...(c.negation ? { not: c.negation } : {}),
        filterIdentitier: {
          entity: c.entity as 'company' | 'project' | 'contact' | 'email',
          identifier: c.fieldId,
        },
        includeNulls: c.includeNulls,
      } as FilterEntity),
  )

export const getNewCondition = (): FilterCondition => ({
  id: getUniqId(),
  operation: FilterOperation.Equal,
  negation: false,
  filter: 'and',
})

export const mapFilterRootToCondition = (rootFilter: FilterRoot): FilterCondition[] => [
  ...(rootFilter.and || []).map(
    f =>
      ({
        fieldId: f.filterIdentitier.identifier,
        fieldValue: f.compareValue,
        entity: f.filterIdentitier.entity,
        negation: f.not,
        operation: f.operation,
        filter: 'and',
        id: getUniqId(),
        includeNulls: f.includeNulls,
      } as FilterCondition),
  ),
  ...(rootFilter.or || []).map(
    f =>
      ({
        fieldId: f.filterIdentitier.identifier,
        fieldValue: f.compareValue,
        entity: f.filterIdentitier.entity,
        negation: f.not,
        operation: f.operation,
        filter: 'or',
        id: getUniqId(),
        includeNulls: f.includeNulls,
      } as FilterCondition),
  ),
]
