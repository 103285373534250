"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TooltipWrap = exports.Tip = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const styles_1 = require("../../styles");
const Div_1 = require("../Div");
const VARIANT_STYLES = {
    light: {
        background: styles_1.colors.white,
        border: '#dadce3',
    },
    dark: {
        background: styles_1.colors.darkBlue,
        border: styles_1.colors.darkBlue,
    },
};
const createTopArrow = (arrowLeft) => (variant) => {
    const theme = VARIANT_STYLES[variant];
    return (0, styled_components_1.css) `
    background: ${theme.background};
    border: 1px solid ${theme.border};

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: ${arrowLeft};
      transform: translateX(-50%);

      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-bottom: 5px solid ${theme.border};
    }

    &::before {
      top: -5px;
    }
    &::after {
      top: -4px;
      border-bottom: 5px solid ${theme.background};
    }
  `;
};
const createBottomArrow = (arrowLeft) => (variant) => {
    const theme = VARIANT_STYLES[variant];
    return (0, styled_components_1.css) `
    background: ${theme.background};
    border: 1px solid ${theme.border};
    transform: translateY(-5px);

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: ${arrowLeft};
      transform: translateX(-50%);

      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-top: 5px solid ${theme.border};
    }

    &::before {
      bottom: -5px;
    }
    &::after {
      bottom: -4px;
      border-top: 5px solid ${theme.background};
    }
  `;
};
const createLeftArrow = (arrowTop) => (variant) => {
    const theme = VARIANT_STYLES[variant];
    return (0, styled_components_1.css) `
    background: ${theme.background};
    border: 1px solid ${theme.border};
    transform: translateX(5px);

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: ${arrowTop};
      transform: translateY(-50%);

      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 5px solid ${theme.border};
    }

    &::before {
      left: -5px;
    }
    &::after {
      left: -4px;
      border-right: 5px solid ${theme.background};
    }
  `;
};
const createRightArrow = (arrowTop) => (variant) => {
    const theme = VARIANT_STYLES[variant];
    return (0, styled_components_1.css) `
    background: ${theme.background};
    border: 1px solid ${theme.border};
    transform: translateX(5px);

    &::before,
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: ${arrowTop};
      transform: translateY(-50%);

      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 5px solid ${theme.border};
    }

    &::before {
      right: -5px;
    }
    &::after {
      right: -4px;
      border-left: 5px solid ${theme.background};
    }
  `;
};
const createNoArrow = (variant) => {
    const theme = VARIANT_STYLES[variant];
    return (0, styled_components_1.css) `
    background: ${theme.background};
    border: 1px solid ${theme.border};
  `;
};
const TOOLTIP_STYLES = {
    top: createNoArrow,
    topCenter: createBottomArrow('50%'),
    bottomLeft: createTopArrow('20%'),
    bottomCenter: createTopArrow('50%'),
    bottomRight: createTopArrow('85%'),
    rightCenter: createLeftArrow('50%'),
    leftCenter: createRightArrow('50%'),
};
exports.Tip = styled_components_1.default.div `
  padding: ${({ small }) => (small ? `${(0, styles_1.spacing)(0.5)} ${(0, styles_1.spacing)(2)}` : (0, styles_1.spacing)(2))};

  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  max-width: 90vw;

  ${({ variant, position }) => TOOLTIP_STYLES[position](variant)}

  @media print {
    display: none;
  }
`;
exports.TooltipWrap = (0, styled_components_1.default)(Div_1.Div) `
  display: inline-block;
  max-width: 100%;
`;
