"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrowLeft = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const ArrowLeft = ({ width = 16, height = 16, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M5.4922 7.55729L9.03387 4.01562C9.27866 3.77083 9.67449 3.77083 9.91668 4.01562L10.5052 4.60417C10.75 4.84896 10.75 5.24479 10.5052 5.48698L7.99741 8L10.5078 10.5104C10.7526 10.7552 10.7526 11.151 10.5078 11.3932L9.91928 11.9844C9.67449 12.2292 9.27866 12.2292 9.03647 11.9844L5.4948 8.44271C5.24741 8.19792 5.24741 7.80208 5.4922 7.55729Z", fill: color })));
exports.ArrowLeft = ArrowLeft;
