import { UserDropdown } from './UserDropdown'
import { UsersChip } from './UsersChip'
import { UsersInput } from './UsersInput'
import { UsersSelector } from './UsersSelector'

export const Users = {
  Selector: UsersSelector,
  Input: UsersInput,
  Chip: UsersChip,
  Dropdown: UserDropdown,
}
