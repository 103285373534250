"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrowRight = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const ArrowRight = ({ width = 16, height = 16, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M10.5078 7.55729L6.96613 4.01562C6.72134 3.77083 6.32551 3.77083 6.08332 4.01562L5.49478 4.60417C5.24999 4.84896 5.24999 5.24479 5.49478 5.48698L8.00259 8L5.49218 10.5104C5.24738 10.7552 5.24738 11.151 5.49218 11.3932L6.08072 11.9844C6.32551 12.2292 6.72134 12.2292 6.96353 11.9844L10.5052 8.44271C10.7526 8.19792 10.7526 7.80208 10.5078 7.55729Z", fill: color })));
exports.ArrowRight = ArrowRight;
