import { CONTACT_ID, PROJECT_ID } from '@basisboard/basis-common/lib/api'
import { screens } from '../../../screens'
import { showSuccessToast } from '../../Toast'

export const showAddBidsToast = (projectId: PROJECT_ID) =>
  showSuccessToast({
    message: 'Your bid was successfully created.',
    icon: 'Check',
    actions: [
      {
        label: 'See details',
        onAction: () => screens.projectDetails.push(projectId),
      },
    ],
  })

// Contact container or users container
export const showMergeContactsToast = (contactId: CONTACT_ID) =>
  showSuccessToast({
    message: 'The selected contacts were successfuly linked.',
    actions: [
      {
        label: 'See combined contact details',
        onAction: () => screens.contactDetails.push(contactId),
      },
    ],
  })

export const showMergeBidsToast = (projectId: PROJECT_ID) =>
  showSuccessToast({
    message: 'The selected bids were successfully connected.',
    actions: [
      {
        label: 'See details',
        onAction: () => screens.projectDetails.push(projectId),
      },
    ],
  })

export const showSplitBidsToast = (projectId: PROJECT_ID) =>
  showSuccessToast({
    message: 'Your bid was successfully split.',
    actions: [
      {
        label: 'See new bid',
        onAction: () => screens.projectDetails.push(projectId),
      },
    ],
  })
