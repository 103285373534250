import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import styled from 'styled-components'
import { HEADER_HEIGHT_PX } from '../../styles'
import { openNotificationDrawer } from './drawer'
import { NotificationsState } from './state'

export const Header = styled.div`
  padding: 0 ${spacing(4)};
  margin-bottom: ${spacing(0.25)};

  width: 100%;

  border-bottom: 1px solid #eaebee;
`

const BellButton = styled.button`
  width: ${HEADER_HEIGHT_PX};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${transitionMixin};

  &:hover {
    background: ${colors.blueGray};
  }
`

const Counter = styled.div`
  right: -8px;
  top: -8px;

  position: absolute;

  border-radius: 20px;
  height: 20px;
  min-width: 20px;
  line-height: 15px;
  padding: 0 ${spacing(0.5)};

  background: ${colors.accent};
  border: 2px solid ${colors.white};

  color: ${colors.white};
  font-weight: bold;
  font-size: 0.625rem;
  letter-spacing: -1px;
`

export const Notifications = () => {
  const notificationsData = useContainer(NotificationsState)

  const { counter } = notificationsData.state

  const handleOpen = React.useCallback(() => {
    notificationsData.toggleOpen()

    openNotificationDrawer(notificationsData.toggleOpen)
  }, [])

  React.useEffect(() => {
    notificationsData.loadNotifications({ showOnlyUnread: true })
    notificationsData.loadNotifications()
  }, [])

  return (
    <BellButton onClick={handleOpen}>
      <Div position="relative">
        <Icon.Bell />
        {counter > 0 && <Counter>{counter > 99 ? '99+' : counter}</Counter>}
      </Div>
    </BellButton>
  )
}
