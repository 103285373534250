import { schema } from 'normalizr';

const companySchema = new schema.Entity('companies');

const contactSchema = new schema.Entity('contacts');

const estimatorsSchema = new schema.Entity('estimators');

const stageSchema = new schema.Entity('stages');

const bidInviteSchema = new schema.Entity('bidInvites', {
  company: companySchema,
  contacts: [contactSchema],
});

export const projectSchema = new schema.Entity('projects', {
  bidInvites: [bidInviteSchema],
  estimators: [estimatorsSchema],
  stage: stageSchema,
});

export const normalizedSchema = {
  projects: [projectSchema],
};
