"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Quote = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Quote = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M9.69794 7.41211L6.88544 6.58919C6.55992 6.49544 6.33336 6.19076 6.33336 5.85221C6.33336 5.42773 6.67711 5.08398 7.10159 5.08398H8.82815C9.14586 5.08398 9.45836 5.18034 9.71878 5.35742C9.87763 5.46419 10.0912 5.43815 10.2266 5.30534L11.1328 4.41992C11.3177 4.24023 11.2917 3.94076 11.086 3.7819C10.4479 3.2819 9.65107 3.00326 8.83336 3.00065V1.75065C8.83336 1.52148 8.64586 1.33398 8.41669 1.33398H7.58336C7.35419 1.33398 7.16669 1.52148 7.16669 1.75065V3.00065H7.10159C5.44274 3.00065 4.1094 4.42513 4.26305 6.11523C4.37242 7.31576 5.28909 8.29232 6.44534 8.63086L9.11461 9.41211C9.44013 9.50846 9.66669 9.81055 9.66669 10.1491C9.66669 10.5736 9.32294 10.9173 8.89847 10.9173H7.1719C6.85419 10.9173 6.54169 10.821 6.28128 10.6439C6.12242 10.5371 5.90888 10.5632 5.77347 10.696L4.86722 11.5814C4.68232 11.7611 4.70836 12.0605 4.91409 12.2194C5.55211 12.7194 6.34899 12.998 7.16669 13.0007V14.2507C7.16669 14.4798 7.35419 14.6673 7.58336 14.6673H8.41669C8.64586 14.6673 8.83336 14.4798 8.83336 14.2507V12.9954C10.0469 12.972 11.1849 12.2507 11.586 11.1022C12.1459 9.49805 11.2058 7.85221 9.69794 7.41211Z", fill: color })));
exports.Quote = Quote;
