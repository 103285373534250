import {
  GetProjectDetailResponse,
  GetProjectsResponse,
  PROJECT_ID,
} from '@basisboard/basis-common/lib/api'
import { CustomFieldEntity, CustomFieldType } from '@basisboard/basis-common/lib/api/custom-fields'
import { ProjectFieldType } from '@basisboard/basis-common/lib/enums'
import {
  box,
  formatExcelDate,
  formatQuote,
  getSoonestBidDeadline,
  getSoonestDate,
  getSoonestJobWalkDeadline,
  getSoonestRfiDeadline,
  readableName,
} from '@basisboard/basis-ui/es/utils'
import { getContainer } from '@containrz/react-hook'
import moment from 'moment'
import { denormalize } from 'normalizr'
import uniqBy from 'ramda/src/uniqBy'
import values from 'ramda/src/values'
import { Field } from '../../templates/ViewsScreen'
import { NestedProject, NestedProjectDetail } from '../../types'
import { companyRepository } from '../Companies/container'
import { FieldsState } from '../Fields/container'
import { mapCustomFieldToString } from '../Fields/utils'
import { StagesState } from '../Stages/container'
import { UsersContainer } from '../Users'
import { normalizedSchema } from './schema'
import { NormalizedProjects } from './types'

export const createNormalizedProject = (response: GetProjectsResponse) => {
  companyRepository().setCompanies(values(response.companiesMap))

  return {
    entities: {
      projects: response.projects.reduce(
        (acc, project) => ({
          ...acc,
          [project.id]: {
            ...project,

            bidInvites: project.bidInviteIds,

            estimators: project.estimatorIds.filter(estimatorId =>
              getContainer(UsersContainer).state.users.some(u => u.id === estimatorId),
            ),

            stage: project.stageId,

            bidDeadline: getSoonestDate(
              Object.values(response.bidInvitesMap)
                .filter(v => v.projectId === project.id && !!v.bidDeadlineAt)
                .map(v => moment(v.bidDeadlineAt)),
            ),

            rfiDeadline: getSoonestDate(
              Object.values(response.bidInvitesMap)
                .filter(v => v.projectId === project.id && !!v.rfiDeadlineAt)
                .map(v => moment(v.rfiDeadlineAt)),
            ),

            jobWalk: getSoonestDate(
              Object.values(response.bidInvitesMap)
                .filter(v => v.projectId === project.id && !!v.jobWalkAt)
                .map(v => moment(v.jobWalkAt)),
            ),
          },
        }),
        {},
      ),

      companies: response.companiesMap,

      bidInvites: Object.keys(response.bidInvitesMap).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...response.bidInvitesMap[key],
            company: response.bidInvitesMap[key].companyId,
            contacts: response.bidInvitesMap[key].contactIds,
          },
        }),
        {},
      ),

      contacts: response.contactsMap,
    },
  } as NormalizedProjects
}
export const createNestedProjectDetail = (
  projectDetailResponse: GetProjectDetailResponse,
): NestedProjectDetail => {
  const { project, bidInvitesMap, companiesMap, contactsMap } = projectDetailResponse

  companyRepository().setCompanies(values(companiesMap))

  return {
    ...project,
    estimators: (project?.estimatorIds || [])
      .map(estimatorId =>
        getContainer(UsersContainer).state.allUsers.find(u => u.id === estimatorId),
      )
      .filter(e => !!e),
    activities: [],
    unreadMessages: projectDetailResponse.unreadMessages,
    bidInvites: (project?.bidInviteIds || []).map(bidInviteId => {
      const bid = bidInvitesMap[bidInviteId]
      return {
        ...bid,
        company: companiesMap[bid.companyId],
        contacts: bid.contactIds.map(contactId => contactsMap[contactId]),
      }
    }),
  }
}

export const denormalizeProjects = (projects: PROJECT_ID[], entities) =>
  uniqBy(
    ({ id }) => id,
    denormalize({ projects }, normalizedSchema, {
      ...entities,

      companies: companyRepository().state.companies.reduce(
        (acc, c) => ({
          ...acc,
          [c.id]: c,
        }),
        {},
      ),

      estimators: getContainer(UsersContainer).state.users.reduce(
        (acc, u) => ({
          ...acc,
          [u.id]: u,
        }),
        {},
      ),

      stages: getContainer(StagesState).state.stages.reduce(
        (acc, s) => ({
          ...acc,
          [s.id]: s,
        }),
        {},
      ),
    }).projects.filter(p => Boolean(p)) as NestedProject[],
  )

export const exportProjectEntry = (enabledFields: Field[]) => (project: NestedProject) => {
  const fieldsData = getContainer(FieldsState)
  const stagesData = getContainer(StagesState)

  const customFields = fieldsData.getCustomFieldsForEntity(CustomFieldEntity.Project)

  const mapFieldToValue = {
    [ProjectFieldType.ProjectName]: project.name,
    [ProjectFieldType.Companies]: project.bidInvites.map(bi => bi.company.name).join(', '),
    ...formatExcelDate(project.createdAt, ProjectFieldType.CreatedAt),
    ...formatExcelDate(getSoonestBidDeadline(project.bidInvites), ProjectFieldType.BidDeadline),
    ...formatExcelDate(getSoonestJobWalkDeadline(project.bidInvites), ProjectFieldType.JobWalk),
    ...formatExcelDate(getSoonestRfiDeadline(project.bidInvites), ProjectFieldType.RFIDeadline),
    ...formatExcelDate(project.submittedAt, ProjectFieldType.SubmittedAt),
    ...formatExcelDate(project.awardedAt, ProjectFieldType.AwardedAt),
    ...formatExcelDate(project.lostAt, ProjectFieldType.LostAt),
    ...formatExcelDate(project.startAt, ProjectFieldType.StartAt),
    ...formatExcelDate(project.endAt, ProjectFieldType.EndAt),
    [ProjectFieldType.Location]: project.location,
    [ProjectFieldType.Region]: project.region,
    [ProjectFieldType.AddressLine]: project.addressLine,
    [ProjectFieldType.City]: project.city,
    [ProjectFieldType.ZipCode]: project.postalCode,
    [ProjectFieldType.Quotes]: formatQuote(project.quote),
    [ProjectFieldType.Estimator]: (project.estimators || []).map(e => readableName(e)).join(', '),
    [ProjectFieldType.Stage]: project.stage?.name,
    [ProjectFieldType.StageReason]:
      (stagesData.getStageForId(project.stageId)?.reasons || []).find(
        reason => reason.id === project.stageReasonId,
      )?.name || '',
    [ProjectFieldType.Contact]: project.bidInvites
      .reduce((acc, bi) => [...acc, ...bi.contacts], [])
      .map(readableName)
      .join(', '),

    ...customFields.reduce(
      (acc, customField) => ({
        ...acc,
        ...mapCustomFieldToString(customField, project.customFields[customField.id]),
      }),
      {},
    ),
  }

  const getKey = f =>
    f.type === CustomFieldType.Date ? [`${f.label} - Date`, `${f.label} - Time`] : f.label

  return enabledFields.reduce(
    (acc, field) => ({
      ...acc,
      ...box(getKey(field)).fold((keys: string | string[]) =>
        Array.isArray(keys)
          ? {
              [keys[0]]: mapFieldToValue[`${field.id}-Date`],
              [keys[1]]: mapFieldToValue[`${field.id}-Time`],
            }
          : { [keys as string]: mapFieldToValue[field.id] },
      ),
    }),
    {},
  )
}
