import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import styled from 'styled-components'

export const Title = styled.div`
  color: ${colors.darkBlue};
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 32px;
  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(1)};
`

export const Description = styled.div`
  color: ${colors.darkGray};
  font-size: 1rem;
  line-height: 24px;
`
