import {
  COMPANY_ID,
  GetGroupedProjectsRequestQuery,
  GetGroupedProjectsResponseBody,
  GetProjectActivitiesResponse,
  GetProjectCompanyEmailAddress,
  GetProjectDetailResponse,
  GetProjectIdsResponse,
  GetProjectsRequestQuery,
  GetProjectsResponse,
  PatchCompetitorRequestBody,
  PatchCompetitorResponseBody,
  PatchProjectRequest,
  PatchProjectResponse,
  PostCompetitorRequestBody,
  PostCompetitorResponseBody,
  PostProjectDuplicateRequest,
  PostProjectDuplicateResponse,
  PostProjectMergeRequestBody,
  PostProjectMergeResponseBody,
  PostProjectsRequest,
  PostProjectsResponse,
  PROJECT_ID,
  PutProjectEstimatorsRequest,
  PutProjectEstimatorsResponse,
  PutProjectStageRequest,
  PutProjectStageResponse,
  STAGE_ID,
  STAGE_REASON_ID,
  USER_ID,
} from '@basisboard/basis-common/lib/api'
import { isUuid } from '@basisboard/basis-ui/es/utils'
import { stringify } from 'qs'
import mergeDeepRight from 'ramda/src/mergeDeepRight'
import splitEvery from 'ramda/src/splitEvery'
import { del, get, patch, post, put } from '../../api'
import { companyRepository } from '../Companies/container'
import { NormalizedProjects } from './types'
import { createNestedProjectDetail, createNormalizedProject } from './utils'

export const getProjects = (params: GetProjectsRequestQuery) =>
  get<GetProjectsResponse>(`/projects?${stringify(params)}`).then(response =>
    createNormalizedProject(response.data),
  )

export const getProjectGroups = (params?: GetGroupedProjectsRequestQuery) =>
  get<GetGroupedProjectsResponseBody>(`/projects/group?${stringify(params)}`).then(
    response => response.data,
  )

export const getProjectsIds = (params: GetProjectsRequestQuery) =>
  get<GetProjectIdsResponse>(`/projects/ids?${stringify(params)}`).then(response => response.data)

export const getProjectsFromIds = async (
  projectIds: PROJECT_ID[],
  params: GetProjectsRequestQuery = {},
): Promise<NormalizedProjects> => {
  const projects = await Promise.all(
    splitEvery(50, projectIds.filter(Boolean)).map(pIds =>
      getProjects({
        ...params,
        filter: { ...params.filter, projectIds: pIds.filter(Boolean) },
        totalNotes: true,
        includeReminders: true,
      }),
    ),
  )

  return projects.reduce((acc, entities) => mergeDeepRight(acc, entities), {}) as NormalizedProjects
}

export const mergeProjects = (projectId: PROJECT_ID, data: PostProjectMergeRequestBody) =>
  post<PostProjectMergeResponseBody>(`/projects/${projectId}/merge`, data).then(
    response => response.data,
  )

export const deleteProject = (projectId: PROJECT_ID) => del(`/projects/${projectId}`)

export const patchProjectKey = (
  projectId: PROJECT_ID,
  key: keyof PatchProjectRequest,
  value: any,
) => patch<PatchProjectResponse>(`/projects/${projectId}`, JSON.stringify({ [key]: value }))

export const patchProject = (projectId: PROJECT_ID, data: PatchProjectRequest) =>
  patch<PatchProjectResponse>(`/projects/${projectId}`, data).then(
    response => response.data.project,
  )

export const assignEstimators = (projectId: PROJECT_ID, data: PutProjectEstimatorsRequest) =>
  put<PutProjectEstimatorsResponse>(`/projects/${projectId}/estimators`, data).then(
    response => response.data.project,
  )

export const getNestedProjectDetail = (projectId: PROJECT_ID) =>
  get<GetProjectDetailResponse>(`/projects/${projectId}`).then(response =>
    createNestedProjectDetail(response.data),
  )

export const updateProjectReason = (projectId: PROJECT_ID, data: PutProjectStageRequest) =>
  put<PutProjectStageResponse>(`projects/${projectId}/stage`, data).then(response => response.data)

export const putEstimators = (projectId: PROJECT_ID, userIds: USER_ID[]) =>
  put<PutProjectEstimatorsResponse>(
    `/projects/${projectId}/estimators`,
    JSON.stringify({ userIds }),
  )

export const putProjectStage = (
  projectId: PROJECT_ID,
  stageId: STAGE_ID,
  stageReasonId?: STAGE_REASON_ID,
  comment?: string,
) =>
  put<PutProjectStageResponse>(`/projects/${projectId}/stage`, {
    stageId,
    stageReasonId,
    comment,
  })

export const deleteStateChange = (projectId: PROJECT_ID, customerWide: boolean) =>
  del(`/projects/${projectId}/change-state?customerWide=${customerWide}`)

export const postProject = (data: PostProjectsRequest) =>
  post<PostProjectsResponse>('/projects', data).then(response => response.data.projectId)

export const linkCompanyToProject = (body: PostProjectsRequest) =>
  post<PostProjectsResponse>('/projects', JSON.stringify(body)).then(response => response.data)

export const unlinkCompanyFromProject = (projectId: PROJECT_ID, companyId: COMPANY_ID) =>
  del(`/projects/${projectId}/companies/${companyId}`)

export const getProjectActivities = (projectId: PROJECT_ID) =>
  get<GetProjectActivitiesResponse>(`/projects/${projectId}/activities`)

export const duplicateProject = (projectId: PROJECT_ID, body: PostProjectDuplicateRequest) =>
  post<PostProjectDuplicateResponse>(`/projects/${projectId}/duplicate`, body).then(
    response => response.data.projectId,
  )

export const postCompetitorInfo = async (
  projectId: PROJECT_ID,
  companyId: COMPANY_ID,
  body: PostCompetitorRequestBody,
) => {
  let competitorId = companyId
  if (!isUuid(competitorId)) {
    competitorId = (await companyRepository().createNewCompany({ name: companyId })).id
  }

  return post<PostCompetitorResponseBody>(
    `projects/${projectId}/companies/${competitorId}/competitors`,
    body,
  ).then(response => response.data.competitor)
}

export const patchCompetitorInfo = (
  projectId: PROJECT_ID,
  companyId: COMPANY_ID,
  body: PatchCompetitorRequestBody,
) =>
  patch<PatchCompetitorResponseBody>(
    `projects/${projectId}/companies/${companyId}/competitors`,
    body,
  )

export const deleteCompetitorInfo = (projectId: PROJECT_ID, companyId: COMPANY_ID) =>
  del(`projects/${projectId}/companies/${companyId}/competitors`)

export const getEmailAddress = (projectId: PROJECT_ID) =>
  get<GetProjectCompanyEmailAddress>(`/projects/${projectId}/email-address`).then(
    response => response.data.emailAddress,
  )

export const markProjectNotesAsRead = (projectId: PROJECT_ID) =>
  post(`projects/${projectId}/notes/read`)
