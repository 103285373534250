import { FilterRoot } from '@basisboard/basis-common/lib/api'
import { CustomFieldEntity } from '@basisboard/basis-common/lib/api/custom-fields'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { ButtonGroup } from '@basisboard/basis-ui/es/components/ButtonGroup'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Drawer } from '@basisboard/basis-ui/es/components/Drawer'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { RadioGroup } from '@basisboard/basis-ui/es/components/RadioGroup'
import { Tooltip } from '@basisboard/basis-ui/es/components/Tooltip'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { useContainer } from '@containrz/react-hook'
import equals from 'ramda/src/equals'
import * as React from 'react'
import { ViewId } from '../../../../constants'
import { Group, Sort, View } from '../../../../templates/ViewsScreen/types'
import { isAdmin, openConfirmActionModal } from '../../../App'
import { openDrawer } from '../../../Drawer'
import { Field } from '../../../Fields'
import { FiltersStateInstances } from '../../container'
import {
  CreateCustomFiltersDrawer,
  CreateCustomFiltersDrawerProps,
} from '../../drawers/CreateCustomFilter'
import { CustomFilter } from '../../types'
import { CreateFilterButton } from './styled'

interface Props {
  value?: FilterRoot
  viewId: ViewId
  enabledEntities?: CustomFieldEntity[]
  onChange?: (
    p: { customFilter: FilterRoot | null },
    group?: Group,
    fields?: Field[],
    sorts?: Sort[],
  ) => void
  appliedFilter?: Record<string, any>
  openAppliedFilter?: boolean
  getView?: () => View
}

const sortFilterByName = (f1: CustomFilter, f2: CustomFilter) => f1.name.localeCompare(f2.name)

export const openCreateCustomFilterDrawer = (
  props: Omit<CreateCustomFiltersDrawerProps, 'onClose'>,
  onClose: () => void,
) =>
  openDrawer({
    id: 'create-custom-filter',
    title: 'Custom filter',
    content: (
      <CreateCustomFiltersDrawer
        {...(props as any)}
        onClose={() => Drawer.setOpen('create-custom-filter')}
      />
    ),
    onClose,
    width: 768,
  })

export const CustomFilters: React.FC<Props> = ({
  viewId,
  enabledEntities,
  onChange,
  appliedFilter,
  getView,
}) => {
  const filtersData = useContainer(FiltersStateInstances(viewId))

  const filter = appliedFilter || filtersData.state.filter
  const { sharedCustomFilters, customFilters } = filtersData.state

  const allFilters = [
    ...customFilters.sort(sortFilterByName),
    ...sharedCustomFilters.sort(sortFilterByName),
  ]

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const filter = allFilters.find(cf => equals(cf.name, e.target.value))

      if (filter?.filter) {
        filtersData.applyFilter({ customFilter: filter.filter || {} })

        onChange?.(
          { customFilter: filter.filter || null },
          filter.group,
          filter.fields,
          filter.sorts,
        )
      }
    },
    [onChange, JSON.stringify(allFilters)],
  )

  const handleConcludeCreation = (customFilter: FilterRoot) => {
    filtersData.applyFilter({ customFilter })

    onChange?.({ customFilter })
  }

  const handleClose = React.useCallback(() => null, [])

  const currentVal =
    allFilters.find(cf => equals(JSON.stringify(cf.filter), JSON.stringify(filter.customFilter)))
      ?.name || ''

  return (
    <Div data-testid="custom-filters" width={1} mb={spacing(3)}>
      <RadioGroup
        wrapperStyle={{ flexDirection: 'column' }}
        name="custom-filters"
        key={JSON.stringify(allFilters)}
        value={currentVal}
        options={allFilters.map(f => ({
          label: f.name,
          value: f.name,
          disabled: f.invalid,
          node: (
            <ButtonGroup>
              {f.invalid && (
                <Tooltip
                  content={<Text white>This filter is invalid</Text>}
                  position="bottomRight"
                  defaultPosition="bottom"
                  align="right"
                  variant="dark"
                  justify="start"
                  small
                >
                  <Icon.Exclamation size={16} color={colors.error} />
                </Tooltip>
              )}
              <Tooltip
                content={<Text white>This filter is {f.visibility || 'private'}</Text>}
                position="bottomRight"
                defaultPosition="bottom"
                align="right"
                variant="dark"
                justify="start"
                small
              >
                {f.visibility === 'shared' ? <Icon.Link /> : <Icon.Lock />}
              </Tooltip>
            </ButtonGroup>
          ),
          actions: (isAdmin() || f.visibility === 'private') && (
            <ButtonGroup ml="auto">
              <Button.Gradient
                data-testid="edit-custom-filter"
                size={24}
                info="Edit"
                onClick={() => {
                  openCreateCustomFilterDrawer(
                    {
                      filterNames: customFilters.map(f => f.name),
                      enabledEntities,
                      customFilter: f,
                      viewId,
                      getView,
                      onCreate: handleConcludeCreation,
                    },
                    handleClose,
                  )
                }}
                preIcon="Pencil"
                iconSize={12}
              />
              <Button.Gradient
                data-testid="delete-custom-filter"
                size={24}
                info="Delete"
                onClick={() =>
                  openConfirmActionModal({
                    action: () => filtersData.deleteCustomFilters(f),
                    actionLabel: 'Delete filter',
                    prompt: 'Are you sure you want to delete filter?',
                  })
                }
                preIcon="Delete"
                iconSize={12}
              />
            </ButtonGroup>
          ),
        }))}
        onChange={handleChange}
      />
      <CreateFilterButton
        onClick={() =>
          openCreateCustomFilterDrawer(
            {
              filterNames: customFilters.map(f => f.name),
              enabledEntities,
              viewId,
              onCreate: handleConcludeCreation,
              getView,
            },
            handleClose,
          )
        }
      >
        + Add new filter
      </CreateFilterButton>
    </Div>
  )
}
