import {
  EmailStatus,
  EMAIL_ID,
  FilterEntity,
  GetInboxMessagesRequestQuery,
  InboxMessage,
  Message,
  MESSAGE_ID,
  STAGE_ID,
  STAGE_REASON_ID,
} from '@basisboard/basis-common/lib/api'
import { box, fromNullable } from '@basisboard/basis-ui/es/utils'
import any from 'ramda/src/any'
import equals from 'ramda/src/equals'
import omit from 'ramda/src/omit'
import uniq from 'ramda/src/uniq'
import uniqBy from 'ramda/src/uniqBy'
import values from 'ramda/src/values'
import { screens } from '..'
import { appRepository, whoami } from '../../containers/App/container'
import { openConfirmActionModal } from '../../containers/App/modals'
import { MESSAGE_ID_SEARCH_KEY } from '../../containers/Emails/contants'
import { deleteGroupSuggestion, postProjectsFromMessages } from '../../containers/Inbox/api'
import { inboxRepository } from '../../containers/Inbox/container'
import { publishProcessedEvent } from '../../containers/Inbox/helpers'
import { openEmailsModal } from '../../containers/Inbox/modals'
import { showErrorToast, showInfoToast, showSuccessToast } from '../../containers/Toast'
import { eventBus, EventBusType } from '../../services'
import {
  checkAllEntries,
  ViewScreenContainer,
  ViewScreenContainerState,
} from '../../templates/ViewsScreen'
import { isSameFilter } from '../Analytics/utils'
import { DEFAULT_VIEWS, fields } from './constants'
import { InboxViewSettings } from './types'

interface State extends ViewScreenContainerState<InboxMessage> {
  messageIds?: MESSAGE_ID[]
}

export class InboxListContainer extends ViewScreenContainer<
  InboxMessage,
  State,
  InboxViewSettings
> {
  unregister: () => void
  constructor() {
    super('inbox-list', DEFAULT_VIEWS(appRepository().state?.profile?.id))

    this.countLimit = 0

    this.fetchEntries = (data: GetInboxMessagesRequestQuery) =>
      inboxRepository()
        .loadMessages(data)
        .then(response => {
          return { ...response, entries: response.messages }
        })

    this.unregister = eventBus.register(EventBusType.InboxProcessed, ({ origin, payload }) => {
      if (origin === 'Importer') {
        this.setState(s => ({
          entries: s.entries.filter(e => e.id !== payload.message.id),
          selectedEntries: s.selectedEntries.filter(e => e !== payload.message.id),
          messageIds: s.messageIds.filter(e => e !== payload.message.id),
          totalEntries: s.totalEntries - 1,
        }))

        this.viewStateMap.forEach((value, key) => {
          this.viewStateMap.set(key, {
            ...value,
            entries: value.entries.filter(e => e.id !== payload.message.id),
            selectedEntries: value.selectedEntries.filter(e => e !== payload.message.id),
            // eslint-disable-next-line
            // @ts-ignore
            messageIds: value.messageIds.filter(e => e !== payload.message.id),
            totalEntries: value.totalEntries - 1,
          })
        })

        this.getTotals()
      }
    }).unregister

    const defaultViews = [
      ...DEFAULT_VIEWS(whoami()?.id),
      ...(this.state.views || [])
        .filter(v => v.custom)
        .map(v => ({ ...v, filter: omit(['customFilter'], v.filter) })),
    ]

    this.state = {
      ...this.state,
      defaultViews,
    }

    if (
      this.state.views?.length === 0 ||
      defaultViews.some(dv =>
        any(Boolean, [
          !this.state.views.some(sv => sv.id === dv.id),
          !equals(dv.fields, this.state.views.find(v => v.id === dv.id)?.fields),
        ]),
      ) ||
      this.state.views.some(v =>
        any(Boolean, ['statuses' in v.filter, 'communicationTypes' in v.filter]),
      )
    ) {
      this.updateViews(
        this.state.views?.length > 0
          ? this.state.views.map(v => {
              const newFields = values(fields).filter(c => !v.fields.some(f => f.id === c.id))

              return {
                ...v,
                fields: [...v.fields, ...newFields],
                filter: {
                  ...omit(['statuses', 'communicationTypes'], v.filter),
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  ...fromNullable(v.filter?.statuses?.[0]).fold(
                    () => ({}),
                    status => ({ status }),
                  ),
                },
              }
            })
          : defaultViews,
      )
    }
  }

  destroy = () => this.unregister()

  removeEntries = (ids: MESSAGE_ID[]): Partial<State> => ({
    entries: this.state.entries.filter(e => !ids.includes(e.id)),
    messageIds: (this.state.messageIds || []).filter(mId => !ids.includes(mId)),
    totalEntries: this.state.totalEntries - ids.length,
    totals: {
      ...this.state.totals,
      ...(this.state.totals[this.state.selectedView.id]
        ? {
            [this.state.selectedView.id]:
              this.state.totals[this.state.selectedView.id] - ids.length,
          }
        : {}),
    },
  })

  setError = () =>
    this.setState({
      entries: [],
      selectedEntries: [],
      loading: false,
      fetchingMore: false,
      done: false,
      error: true,
    })

  loadEntries = () =>
    this.getEntries()
      .then(({ data }) =>
        inboxRepository()
          .loadAllMessageIds(data as GetInboxMessagesRequestQuery)
          .then(messageIds => this.setState({ messageIds }))
          .catch(() => this.setError()),
      )
      .catch(() => this.setError())

  loadMoreEntries = () =>
    ![this.state.done, this.state.loading, this.state.fetchingMore].includes(true) &&
    this.getEntries({
      isLoadingMore: true,
    }).catch(() => this.setError())

  archiveEmails = (emailIds: MESSAGE_ID | MESSAGE_ID[] = this.state.selectedEntries) =>
    new Promise((resolve, reject) => {
      const ids = Array.isArray(emailIds) ? emailIds : [emailIds]
      const isArray = Array.isArray(emailIds)

      const action = () => {
        isArray && checkAllEntries(false)

        const fallbackState = { ...this.state }

        this.setState({
          selectedEntries: [],
          ...this.removeEntries(ids),
        })

        return Promise.all(
          ids.map(id =>
            inboxRepository().editInboxMessage(id, { status: EmailStatus.Archived, isSpam: false }),
          ),
        )
          .then(d => {
            this.getTotals()

            if (isArray) {
              eventBus.publish(EventBusType.InboxBulkAction, {
                action: 'Trash',
                messageIds: ids,
                bulkSize: ids.length,
              })
            }

            ids.map(messageId => {
              eventBus.publish(EventBusType.InboxTrashed, { messageId, origin: 'List' })
            })

            eventBus.publish(EventBusType.InboxMessageRequiresRefresh, { messageIds: ids })

            showInfoToast({
              message: 'All selected emails were trashed',
              actions: [
                {
                  label: 'See trash',
                  onAction: () => this.onChangeView(this.state.views.find(v => v.id === 'trash')),
                },
              ],
            })

            resolve(d)
          })
          .catch(() => {
            reject()
            showErrorToast({ message: 'Something went wrong while trashing selected emails' })
            this.setState(fallbackState)
          })
      }

      if (isArray) {
        openConfirmActionModal({
          prompt: `Are you sure you want to trash all ${emailIds.length} emails?`,
          actionLabel: 'Trash Emails',
          onClose: reject,
          action,
        })
      } else {
        action()
      }
    })

  deleteEmails = (emailIds: MESSAGE_ID | MESSAGE_ID[] = this.state.selectedEntries) => {
    const ids = Array.isArray(emailIds) ? emailIds : [emailIds]

    openConfirmActionModal({
      prompt: 'Are you sure you want to permanently delete selected emails?',
      actionLabel: 'Delete',
      action: () => {
        Array.isArray(emailIds) && checkAllEntries(false)

        Promise.all(
          ids.map(id => {
            inboxRepository().deleteMessage(id)
          }),
        ).then(() => {
          eventBus.publish(EventBusType.InboxMessageRequiresRefresh, { messageIds: ids })

          this.setState({
            selectedEntries: [],
            ...this.removeEntries(ids),
          })

          this.getTotals()
        })
      },
    })
  }

  moveToInbox = (messageId: MESSAGE_ID | MESSAGE_ID[]) => {
    const messageIds = Array.isArray(messageId) ? messageId : [messageId]

    this.setState({ selectedEntries: [], ...this.removeEntries(messageIds) })

    return Promise.all(
      messageIds.map(mId =>
        inboxRepository().editInboxMessage(mId, { status: EmailStatus.Unlinked, isSpam: false }),
      ),
    ).then(d => {
      this.getTotals()

      if (Array.isArray(messageId)) {
        eventBus.publish(EventBusType.InboxBulkAction, {
          action: 'Move To Inbox',
          messageIds,
          bulkSize: messageIds.length,
        })
      }

      eventBus.publish(EventBusType.InboxMessageRequiresRefresh, { messageIds })

      showSuccessToast({
        message: 'Successfully moved selected emails to inbox',
        actions: [
          {
            label: 'See Inbox',
            onAction: () => this.onChangeView(this.state.views.find(v => v.id === 'all-emails')),
          },
        ],
      })

      messageIds.map(id =>
        eventBus.publish(EventBusType.InboxMovedBack, {
          messageId: id,
          origin: Array.isArray(messageId) ? 'List' : 'Importer',
        }),
      )

      return d
    })
  }

  groupEmails = (emailIds: EMAIL_ID[], groupSuggestion?: boolean) =>
    new Promise((resolve, reject) => {
      openConfirmActionModal({
        prompt: `Are you sure you want to group all ${emailIds.length} emails?`,
        description: box(
          emailIds.map(eId =>
            this.state.entries.find(
              e => e.id === eId || e.suggestedGroupedMessages.some(m => m.id === eId),
            ),
          ),
        )
          .map(emails => emails.filter(e => Boolean(e.company)))
          .map(emails => uniqBy(m => m.company?.name, emails))
          .fold(emails =>
            emails.length > 1
              ? `The selected emails are from multiple companies and will all be merged to ${emails[0].company?.name}`
              : undefined,
          ),
        danger: false,
        actionLabel: 'Group Emails',
        action: () => {
          const ids = [...emailIds]
          const mainEmailId = ids.shift()

          const groupedEmails = ids.reduce((acc, id) => {
            let entry = this.state.entries.find(m => m.id === (groupSuggestion ? mainEmailId : id))

            entry = groupSuggestion
              ? entry?.suggestedGroupedMessages.find(m => m.id === id) || entry
              : entry

            return entry
              ? [...acc, { ...entry, groupedMessages: [] }, ...(entry.groupedMessages || [])]
              : acc
          }, [])

          const fallbackState = { ...this.state }

          this.setState(s => ({
            ...this.removeEntries(ids),
            selectedEntries: [],
            entries: s.entries.reduce(
              (acc, entry) =>
                ids.includes(entry.id)
                  ? acc
                  : [
                      ...acc,
                      entry.id === mainEmailId
                        ? {
                            ...entry,
                            groupedMessages: [...entry.groupedMessages, ...groupedEmails],
                            ...(groupSuggestion ? { suggestedGroupedMessages: [] } : {}),
                          }
                        : entry,
                    ],
              [],
            ),
          }))

          setTimeout(() => {
            checkAllEntries(false)
          }, 100)

          return Promise.all(
            groupedEmails.map(({ id }) =>
              inboxRepository().editInboxMessage(id, { groupedEmailId: mainEmailId }),
            ),
          )
            .then(a => {
              this.getTotals()

              showSuccessToast({
                message: 'Selected emails were successfully grouped together.',
              })

              eventBus.publish(EventBusType.InboxMessageRequiresRefresh, {
                messageIds: [mainEmailId],
              })

              eventBus.publish(EventBusType.InboxBulkAction, {
                action: 'Group',
                messageIds: [mainEmailId, ...ids],
                bulkSize: [mainEmailId, ...ids].length,
              })

              eventBus.publish(EventBusType.InboxGrouped, {
                groupedEmails: [mainEmailId, ...ids],
                groupSuggestion,
              })

              resolve(a)
            })
            .catch(() => {
              reject()
              showErrorToast({ message: 'Something went wrong while grouping emails.' })
              this.setState(fallbackState)
            })
        },
      })
    })

  createProjects = (messageIds: EMAIL_ID[], stageId: STAGE_ID, stageReasonId?: STAGE_REASON_ID) => {
    const entries = this.state.entries

    this.setState({
      selectedEntries: [],
      ...this.removeEntries(messageIds),
    })

    checkAllEntries(false)

    return postProjectsFromMessages({
      messageIds,
      stageId,
      stageReasonId,
    }).then(d => {
      this.getTotals()

      showSuccessToast({
        message: 'Selected emails were linked into bids.',
        actions: [{ label: 'See Bid List', onAction: screens.projectsList.push }],
      })

      eventBus.publish(EventBusType.InboxBulkAction, {
        action: stageReasonId ? 'Decline' : 'Add',
        messageIds,
        bulkSize: messageIds.length,
        stageId,
        stageReasonId,
      })

      messageIds.map(mId =>
        publishProcessedEvent(
          box(entries.find(e => e.id === mId)).fold(message => ({
            ...(message || {}),
            message: {
              id: mId,
              createdAt: message?.createdAt,
              communicationType: message?.communicationType,
            } as Message,
          })),
          stageReasonId ? 'Declined' : 'Added',
          'List',
          {},
          '0s',
        ),
      )

      return d
    })
  }

  onOpenEmail = (id: MESSAGE_ID) => {
    const messages = uniq([
      ...(this.state.entries || []).reduce(
        (acc, e) => [...acc, e.id, ...(e.suggestedGroupedMessages || []).map(s => s.id)],
        [],
      ),
      ...(this.state.messageIds || []),
    ])

    openEmailsModal({
      messages,
      currentMessageIndex: messages.findIndex(m => m === id),
      onInvalidate: this.loadEntries,
      actions: msg =>
        ({
          [EmailStatus.AutoLinked]: [
            {
              label: 'View in Project',
              to: `${screens.projectsList.path}/${
                this.state.entries.find(ms => ms.id === msg.message.id)?.project?.id
              }?tab=emails&${MESSAGE_ID_SEARCH_KEY}=${msg.message.id}`,
              variant: 'Text',
            },
            {
              label: 'Trash',
              onClick: () => this.archiveEmails(msg.message.id),
              variant: 'Danger',
            },
            {
              label: 'Move to Inbox',
              onClick: () => this.moveToInbox(msg.message.id),
              variant: 'Primary',
            },
          ],

          [EmailStatus.Archived]: [
            {
              label: 'Delete',
              onClick: () => this.deleteEmails(msg.message.id),
              variant: 'Danger',
            },
            {
              label: 'Move to Inbox',
              onClick: () => this.moveToInbox(msg.message.id),
              variant: 'Primary',
            },
          ],
        }[msg.message.status] || []),
    })
  }

  rejectGroupSuggestion = (messageId: MESSAGE_ID) => {
    this.setState(s => ({
      entries: s.entries.reduce(
        (acc, entry) => [
          ...acc,
          ...(entry.id === messageId
            ? [{ ...entry, suggestedGroupedMessages: [] }, ...entry.suggestedGroupedMessages]
            : [entry]),
        ],
        [],
      ),
    }))

    return deleteGroupSuggestion(messageId).then(() =>
      eventBus.publish(EventBusType.InboxRejectGroupSuggestion, { messageId }),
    )
  }

  shouldDisplayFilter = (filter: FilterEntity) => {
    if (filter) {
      const defaultFilter = DEFAULT_VIEWS(appRepository().state?.profile?.id).find(
        v => v.id === this.state.selectedView?.id,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      )?.filter.customFilter?.and[0]

      if (defaultFilter) {
        return !isSameFilter(defaultFilter, filter)
      }
    }
    return true
  }
}
