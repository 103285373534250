"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Location = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Location = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M7.48615 14.3983C3.70234 8.91292 3 8.34995 3 6.33398C3 3.57255 5.23857 1.33398 8 1.33398C10.7614 1.33398 13 3.57255 13 6.33398C13 8.34995 12.2977 8.91292 8.51385 14.3983C8.26555 14.757 7.73443 14.757 7.48615 14.3983ZM8 8.41732C9.1506 8.41732 10.0833 7.48458 10.0833 6.33398C10.0833 5.18339 9.1506 4.25065 8 4.25065C6.8494 4.25065 5.91667 5.18339 5.91667 6.33398C5.91667 7.48458 6.8494 8.41732 8 8.41732Z", fill: color })));
exports.Location = Location;
