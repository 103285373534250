import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { borderRadiusMixin, colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled from 'styled-components'
import { left, LeftProps, width, WidthProps } from 'styled-system'

const Wrapper = styled.div`
  width: 100%;
  height: 32px;
  padding: ${spacing(0.25)};
  background: #e8eaec;
  ${borderRadiusMixin};

  display: flex;
  align-items: center;
  position: relative;
`

const Value = styled.button`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1;

  &:focus {
    outline: none;
  }
`

const Marker = styled.div<LeftProps & WidthProps>`
  position: absolute;
  height: 28px;
  background-color: ${colors.white};
  border-radius: 2px;

  ${transitionMixin};

  top: 2px;

  ${left};
  ${width};
`

interface Props {
  filters: {
    label: string
    value: string
  }[]
  currentFilter: string
  onChangeFilter: (filter: string) => void
}

export const FilterToggle: React.FC<Props> = ({ filters, currentFilter, onChangeFilter }) => {
  const [refs] = React.useState(filters.map(() => React.createRef<HTMLButtonElement>()))
  const [, forceRerender] = React.useState(0)

  const activeIndex = filters.findIndex(f => f.value === currentFilter)

  const ref = refs[activeIndex].current

  React.useLayoutEffect(() => {
    forceRerender(r => r + 1)
  }, [])

  return (
    <Wrapper>
      {ref && (
        <Marker left={`${(ref as HTMLButtonElement).offsetLeft}px`} width={ref.clientWidth} />
      )}
      {filters.map((filter, index) => (
        <Value ref={refs[index]} key={filter.value} onClick={() => onChangeFilter(filter.value)}>
          <Text color={filter.value === currentFilter ? colors.darkBlue : colors.mediumGray}>
            {filter.label}
          </Text>
        </Value>
      ))}
    </Wrapper>
  )
}
