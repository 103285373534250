import { Button } from '@basisboard/basis-ui/es/components/Button'
import { colors } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import { Wrapper } from './styled'

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  children: React.ReactNode
}

export const EditTableField = React.forwardRef<HTMLDivElement, Props>(
  ({ children, onClick }, ref) => {
    return (
      <Wrapper ref={ref}>
        {children}

        <Button.Transparent
          style={{
            transition: 'none',
            position: 'absolute',
            right: 4,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
          onClick={e => {
            e.stopPropagation()
            onClick?.(e)
          }}
          postIcon="PencilHollow"
          iconColor={colors.accent}
        />
      </Wrapper>
    )
  },
)
