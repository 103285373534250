import { Div } from '@basisboard/basis-ui/es/components/Div'
import { DetailText } from '@basisboard/basis-ui/es/components/Typography'
import { spacing } from '@basisboard/basis-ui/es/styles'
import times from 'ramda/src/times'
import * as React from 'react'
import { Progress } from './styled'

interface Props {
  current: number
  total: number
}

export const Step: React.FC<Props> = ({ current, total }) => (
  <Div display="flex" flexDirection="column" justifyContent="center">
    <DetailText textAlign="center">
      {current} of {total}
    </DetailText>
    <Div mt={spacing(0.5)} display="flex" alignItems="center">
      {times(
        index => (
          <Progress key={index} active={index < current} />
        ),
        total,
      )}
    </Div>
  </Div>
)
