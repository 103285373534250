import { CustomFieldEntity } from '@basisboard/basis-common/lib/api/custom-fields'
import { Banner } from '@basisboard/basis-ui/es/components/Banner'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { ButtonGroup } from '@basisboard/basis-ui/es/components/ButtonGroup'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Input } from '@basisboard/basis-ui/es/components/Input'
import { Label } from '@basisboard/basis-ui/es/components/Label'
import { Anchor, Subtitle2, Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { box } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import moment from 'moment'
import isEmpty from 'ramda/src/isEmpty'
import * as React from 'react'
import { ButtonText, Checkbox, RadioGroup, Select } from '../../../../components'
import { ViewId } from '../../../../constants'
import { View } from '../../../../templates/ViewsScreen'
import { Visibility } from '../../../../types'
import { isAdmin } from '../../../App'
import { ConditionEntry } from '../../components/ConditionEntry'
import { operationsWithoutValue } from '../../constants'
import { FiltersStateInstances } from '../../container'
import { getNewCondition, mapConditionsToFilterRoot, mapFilterRootToCondition } from '../../helpers'
import { CustomFilter, FilterCondition } from '../../types'
import { enabledFields } from './constants'

const isCompleteCondition = (condition: FilterCondition) =>
  (operationsWithoutValue.includes(condition.operation) || condition.fieldValue) &&
  typeof condition.operation === 'number'

export interface CreateCustomFiltersDrawerProps {
  filterNames: string[]
  customFilter?: CustomFilter
  enabledEntities?: CustomFieldEntity[]
  onClose: () => void
  onCreate: (d: unknown) => void
  viewId: ViewId
  getView?: () => View
}

export const CreateCustomFiltersDrawer: React.FC<CreateCustomFiltersDrawerProps> = ({
  filterNames,
  customFilter,
  enabledEntities,
  onClose,
  onCreate,
  viewId,
  getView,
}) => {
  const filterData = useContainer(FiltersStateInstances(viewId))
  const [filter, setFilter] = React.useState<'and' | 'or'>('and')
  const [conditions, setConditions] = React.useState<FilterCondition[]>([getNewCondition()])
  const [filterName, setFilterName] = React.useState('')
  const [visibility, setVisibility] = React.useState<Visibility>(
    customFilter?.visibility || 'private',
  )
  const [includeFields, setIncludeFields] = React.useState((customFilter?.fields || []).length > 0)
  const [includeGroup, setIncludeGroup] = React.useState(Boolean((customFilter || {})?.group))
  const [includeSorts, setIncludeSorts] = React.useState(Boolean((customFilter || {})?.sorts))

  React.useEffect(() => {
    setIncludeFields((customFilter?.fields || []).length > 0)
  }, [setIncludeFields, customFilter])

  React.useEffect(() => {
    if (!customFilter) {
      return
    }

    box(mapFilterRootToCondition(customFilter.filter)).fold(conditions => {
      setConditions(isEmpty(conditions) ? [getNewCondition()] : conditions)
      setFilter(conditions[0]?.filter)
    })

    setFilterName(customFilter.name)
    setVisibility(customFilter.visibility || 'private')
  }, [customFilter])

  const handleSetCondition = React.useCallback(
    (index, key, val) => {
      setConditions(conds =>
        conds.map((c, i) =>
          i === index
            ? { ...c, [key]: val, ...(key === 'fieldId' ? { fieldValue: null } : {}) }
            : c,
        ),
      )
    },
    [setConditions],
  )

  const handleChangeTitle = React.useCallback(e => setFilterName(e.target.value), [setFilterName])

  const handleDelete = React.useCallback(
    index => {
      if (conditions.length === 1) {
        setConditions([getNewCondition()])
      } else {
        setConditions(conds => conds.map((c, i) => (i === index ? null : c)).filter(c => c))
      }
    },
    [setConditions, conditions],
  )

  const handleSubmit = React.useCallback(() => {
    onClose()

    const newFilter: CustomFilter = {
      name: filterName,
      filter: {
        and:
          filter === 'and' ? mapConditionsToFilterRoot(conditions.filter(isCompleteCondition)) : [],
        or:
          filter === 'or' ? mapConditionsToFilterRoot(conditions.filter(isCompleteCondition)) : [],
      },
      visibility,
    }

    onCreate(newFilter.filter)

    if (customFilter) {
      filterData
        .editCustomFilters(customFilter.name, {
          newFilter: {
            ...newFilter,
            lastEditedAt: moment().toISOString(),
          },
          includeFields,
          includeGroup,
          includeSorts,
          view: getView?.(),
        })
        .then(() => filterData.applyFilter({ customFilter: newFilter.filter }))
    } else {
      filterData
        .addCustomFilters({
          newFilter: { ...newFilter, createdAt: moment().toISOString() },
          includeFields,
          includeGroup,
          includeSorts,
          view: getView?.(),
        })
        .then(() => filterData.applyFilter({ customFilter: newFilter.filter }))
    }
  }, [
    filterName,
    filter,
    conditions,
    customFilter,
    visibility,
    includeFields,
    includeGroup,
    includeSorts,
  ])

  const uniqName = !filterNames.filter(f => f !== customFilter?.name).includes(filterName.trim())
  const canCreate =
    filterName.length > 0 &&
    (conditions.reduce((acc, condition) => acc && isCompleteCondition(condition), true) ||
      includeFields ||
      includeSorts ||
      includeGroup) &&
    uniqName

  return (
    <>
      <Div position="absolute" right={32} top={22}>
        <Anchor
          target="_blank"
          color={colors.accent}
          href="https://intercom.help/basis-board/en/articles/4298167-custom-filters"
        >
          How to get started →
        </Anchor>
      </Div>

      <Div
        borderTop="1px solid #eaebee"
        px={spacing(4)}
        py={spacing(2)}
        pb={spacing(5)}
        height="calc(100% - 16px)"
      >
        <Subtitle2 display="block" mb={spacing(3)}>
          {customFilter ? 'Edit filter' : 'Add new filter'}
        </Subtitle2>
        {customFilter?.invalid && (
          <Banner.Error mb={spacing(3)}>
            This filter can&apos;t be applied because it utilizes deleted custom fields.
          </Banner.Error>
        )}
        <Input
          data-testid="filter-name-input"
          required
          label="Filter name"
          placeholder="Type a name for your filter"
          value={filterName}
          onChange={handleChangeTitle}
          error={uniqName ? undefined : `A filter called “${filterName}” already exists`}
        />

        {isAdmin() && (
          <>
            <Div height={spacing(2)} />

            <RadioGroup
              name="visibility"
              label="Visibility"
              width={348}
              value={visibility}
              options={[
                { label: 'Private', value: 'private' },
                { label: 'Shared', value: 'shared' },
              ]}
              onChange={e => setVisibility(e.target.value as Visibility)}
            />
          </>
        )}

        <Label space={{ mt: spacing(1) }} label="Where" />
        {conditions.map((c, i) => (
          <>
            <ConditionEntry
              {...c}
              enabledEntities={enabledEntities}
              enabledFields={enabledFields}
              allowCustomFields
              onSetCondition={(key, val) => handleSetCondition(i, key, val)}
              onDelete={() => handleDelete(i)}
            />

            {i + 1 < conditions.length && (
              <Div my={spacing(1)} width={88}>
                <Select
                  data-testid="filter-options"
                  value={filter}
                  dropdownWidth="100%"
                  options={[
                    { id: 'and', name: 'And' },
                    { id: 'or', name: 'Or' },
                  ]}
                  hideEmpty
                  onChange={(v: 'and' | 'or') => setFilter(v)}
                  disabled={i > 0}
                />
              </Div>
            )}
          </>
        ))}

        <ButtonText mt={spacing(2)} onClick={() => setConditions(c => [...c, getNewCondition()])}>
          + Add condition
        </ButtonText>

        <Div mt={spacing(2)}>
          <Checkbox
            value={includeFields}
            onChange={e => setIncludeFields(e.target.checked)}
            label="Store selected fields to filter"
          />
        </Div>
        <Div mt={spacing(2)}>
          <Checkbox
            value={includeGroup}
            onChange={e => setIncludeGroup(e.target.checked)}
            label="Store selected group to filter"
          />
        </Div>
        <Div mt={spacing(2)}>
          <Checkbox
            value={includeSorts}
            onChange={e => setIncludeSorts(e.target.checked)}
            label="Store selected sorts to filter"
          />
        </Div>
      </Div>

      <Div
        px={spacing(4)}
        py={spacing(1.5)}
        borderTop="1px solid #eaebee"
        display="flex"
        alignItems="center"
        position="sticky"
        bottom={-40}
        left={0}
        right={0}
        background={colors.white}
      >
        {customFilter && customFilter.lastEditedAt ? (
          <Text>Last modified: {moment(customFilter.lastEditedAt).format('MMM Do, YYYY')}</Text>
        ) : customFilter && customFilter.createdAt ? (
          <Text>Created at: {moment(customFilter.createdAt).format('MMM Do, YYYY')}</Text>
        ) : null}

        <ButtonGroup ml="auto">
          <Button.Default width={80} onClick={onClose}>
            Cancel
          </Button.Default>
          <Button.Primary width={80} onClick={handleSubmit} disabled={!canCreate}>
            {customFilter ? 'Save' : 'Create'}
          </Button.Primary>
        </ButtonGroup>
      </Div>
    </>
  )
}
