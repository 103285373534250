import { Div } from '@basisboard/basis-ui/es/components/Div'
import { colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled from 'styled-components'
import { left, LeftProps, width, WidthProps } from 'styled-system'

export interface FiltersInterface {
  type?: any
  label: string
  value?: any
}

const Show = styled.span`
  font-size: 0.875rem;
  line-height: 24px;
  color: #818181;
`

const Option = styled.button<{ active: boolean }>`
  margin-left: ${spacing(2)};
  position: relative;
  color: ${({ active }) => (active ? colors.white : colors.darkBlue)};
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: -0.001em;

  ${transitionMixin};

  &:focus,
  &:active {
    outline: none;
  }
`

const Marker = styled.div<LeftProps & WidthProps>`
  height: 24px;
  border-radius: 37px;
  background: ${colors.accent};
  position: absolute;
  top: 1px;

  ${transitionMixin};

  ${left};
  ${width}
`

interface Props {
  options: FiltersInterface[]
  activeFilter: any
  setFilter: (filter: FiltersInterface) => void
  showText?: string
}

export const Filters: React.FC<Props> = ({ options, activeFilter, showText, setFilter }) => {
  const [optionsRef] = React.useState(options.map(() => React.createRef<HTMLButtonElement>()))

  const currentRef = React.useMemo(
    () => optionsRef[options.findIndex(o => o.type === activeFilter)]?.current,
    [optionsRef.map(o => o.current), activeFilter],
  )

  return (
    <>
      <Div
        data-testid="filter-wrapper"
        display="flex"
        ml="auto"
        position="relative"
        mr={spacing(1)}
      >
        <Marker width={currentRef?.clientWidth + 16 || 0} left={currentRef?.offsetLeft - 8 || 0} />

        <Show>{showText || 'Show'}:</Show>
        {options.map((option, index) => (
          <Option
            key={option.label}
            active={option.type === activeFilter}
            ref={optionsRef[index]}
            onClick={() => setFilter(option)}
          >
            {option.label}
          </Option>
        ))}
      </Div>
    </>
  )
}
