"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Throttle = exports.throttle = exports.Debounce = exports.debounce = void 0;
// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/explicit-module-boundary-types
const debounce = (func, wait, target, immediate = false) => {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            if (!immediate)
                func.apply(target || this, args);
        }, wait);
        if (immediate && !timeout)
            func.apply(target || this, args);
    };
};
exports.debounce = debounce;
/** Decorates a class method so that it is debounced by the specified duration */
function Debounce(duration) {
    return function innerDecorator(target, key, descriptor) {
        return {
            configurable: true,
            enumerable: descriptor.enumerable,
            get: function getter() {
                // Attach this function to the instance (not the class)
                Object.defineProperty(target, key, {
                    configurable: true,
                    enumerable: descriptor.enumerable,
                    value: (0, exports.debounce)(descriptor.value, duration, this),
                });
                return target[key];
            },
        };
    };
}
exports.Debounce = Debounce;
// copy from https://davidwalsh.name/javascript-debounce-function
// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/explicit-module-boundary-types
const throttle = (func, wait, limit = 25) => {
    let calls = 0;
    let timeout;
    return function (...args) {
        calls++;
        const execute = () => {
            calls = 0;
            func.apply(this, args);
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        };
        if (calls >= limit) {
            execute();
        }
        if (!timeout) {
            timeout = setTimeout(execute, wait);
        }
    };
};
exports.throttle = throttle;
/** Decorates a class method so that it is throttled by the specified config */
function Throttle(wait, limit) {
    return function innerDecorator(target, key, descriptor) {
        return {
            configurable: true,
            enumerable: descriptor.enumerable,
            get: function getter() {
                // Attach this function to the instance (not the class)
                Object.defineProperty(target, key, {
                    configurable: true,
                    enumerable: descriptor.enumerable,
                    value: (0, exports.throttle)(descriptor.value, wait, limit),
                });
                return target[key];
            },
        };
    };
}
exports.Throttle = Throttle;
