"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Banner = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../styles");
const Div_1 = require("../Div");
const Icon_1 = require("../Icon");
const Typography_1 = require("../Typography");
const styled_1 = require("./styled");
__exportStar(require("./props"), exports);
const InfoBanner = (_a) => {
    var { children, onClose, canClose = true, hideIcon = false, height } = _a, space = __rest(_a, ["children", "onClose", "canClose", "hideIcon", "height"]);
    const [showing, setShowing] = React.useState(true);
    const handleClose = React.useCallback(() => {
        onClose === null || onClose === void 0 ? void 0 : onClose();
        setShowing(false);
    }, []);
    if (!showing) {
        return null;
    }
    return (React.createElement(styled_1.BannerWrapper, Object.assign({ background: "#e7eafe" }, space, { height: height }),
        !hideIcon ? (React.createElement(styled_1.IconBox, { background: styles_1.colors.accent },
            React.createElement(Icon_1.Icon.Info, { color: styles_1.colors.white, size: 24 }))) : (React.createElement(Div_1.Div, { width: (0, styles_1.spacing)(2) })),
        typeof children === 'string' ? (React.createElement(Typography_1.Text, { preventClip: true, title: children, color: styles_1.colors.accent }, children)) : (children),
        canClose && (React.createElement(Div_1.Div, { as: "button", ml: "auto", onClick: handleClose },
            React.createElement(Icon_1.Icon.Close, null)))));
};
const WarningBanner = (_a) => {
    var { children, onClose, canClose = true, hideIcon = false } = _a, space = __rest(_a, ["children", "onClose", "canClose", "hideIcon"]);
    const [showing, setShowing] = React.useState(true);
    const handleClose = React.useCallback(() => {
        onClose === null || onClose === void 0 ? void 0 : onClose();
        setShowing(false);
    }, []);
    if (!showing) {
        return null;
    }
    return (React.createElement(styled_1.BannerWrapper, Object.assign({ background: "#FFF7EB" }, space),
        !hideIcon ? (React.createElement(styled_1.IconBox, { background: styles_1.colors.warning },
            React.createElement(Icon_1.Icon.ExclamationLight, { color: styles_1.colors.white, size: 16 }))) : (React.createElement(Div_1.Div, { width: (0, styles_1.spacing)(2) })),
        typeof children === 'string' ? (React.createElement(Typography_1.Text, { preventClip: true, title: children }, children)) : (children),
        canClose && (React.createElement(Div_1.Div, { as: "button", ml: "auto", onClick: handleClose },
            React.createElement(Icon_1.Icon.Close, null)))));
};
const ErrorBanner = (_a) => {
    var { children, onClose, canClose = true, hideIcon = false } = _a, space = __rest(_a, ["children", "onClose", "canClose", "hideIcon"]);
    const [showing, setShowing] = React.useState(true);
    const handleClose = React.useCallback(() => {
        onClose === null || onClose === void 0 ? void 0 : onClose();
        setShowing(false);
    }, []);
    if (!showing) {
        return null;
    }
    return (React.createElement(styled_1.BannerWrapper, Object.assign({ background: "#ff214d33" }, space),
        !hideIcon ? (React.createElement(styled_1.IconBox, { background: styles_1.colors.error },
            React.createElement(Icon_1.Icon.Close, { color: styles_1.colors.white }))) : (React.createElement(Div_1.Div, { width: (0, styles_1.spacing)(2) })),
        typeof children === 'string' ? (React.createElement(Typography_1.Text, { preventClip: true, title: children, color: styles_1.colors.error }, children)) : (children),
        canClose && (React.createElement(Div_1.Div, { as: "button", ml: "auto", onClick: handleClose },
            React.createElement(Icon_1.Icon.Close, null)))));
};
exports.Banner = {
    Error: ErrorBanner,
    Warning: WarningBanner,
    Info: InfoBanner,
};
