import {
  GetCustomFieldSettingResponseBody,
  GetProfileSettingsResponse,
  PatchCustomFieldSettingRequestBody,
  PatchCustomFieldSettingResponseBody,
  PostCustomFieldSettingRequestBody,
  PostCustomFieldSettingResponseBody,
  PutProfileSettingsRequest,
} from '@basisboard/basis-common/lib/api'
import { del, get, patch, post, put } from '../../api'
import { ViewId } from '../../constants'
import { Field } from './types'

export const getFields = (viewId: ViewId) =>
  get<GetProfileSettingsResponse>(`/profile/settings/${viewId}-fields`).then(response => {
    return response.data.value
  })

export const putFields = (viewId: ViewId, updatedSettings: Field[]) =>
  put<PutProfileSettingsRequest>(`/profile/settings/${viewId}-fields`, {
    value: { fields: updatedSettings },
  })

export const getCustomFields = () =>
  get<GetCustomFieldSettingResponseBody>('/custom-field-settings').then(
    response => response.data.customFieldSettings,
  )

export const deleteCustomField = (fieldId: string) => del(`/custom-field-settings/${fieldId}`)

export const updateCustomField = (fieldId: string, field: PatchCustomFieldSettingRequestBody) =>
  patch<PatchCustomFieldSettingResponseBody>(`/custom-field-settings/${fieldId}`, field).then(
    response => response.data.customFieldSetting,
  )

export const createCustomField = (field: PostCustomFieldSettingRequestBody) =>
  post<PostCustomFieldSettingResponseBody>('/custom-field-settings', field).then(
    response => response.data.customFieldSetting,
  )
