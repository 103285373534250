import {
  PatchUserRequestBody,
  PostUserRequestBody,
  User,
  USER_ID,
} from '@basisboard/basis-common/lib/api'
import { apiName } from '@basisboard/basis-ui/es/utils'
import { Container } from '@containrz/core'
import { eventBus, EventBusType } from '../../services'
import { deleteUser, getUsers, patchUser, postUser } from './api'

interface State {
  users: User[]
  allUsers: User[]
}

export class UsersContainer extends Container<State> {
  state = {
    users: [] as User[],
    allUsers: [] as User[],
  }

  loadUsers = (offset = 0) =>
    getUsers({ offset }).then(async data => {
      eventBus.publish(EventBusType.LoadedUsers, { users: data.users })
      this.setState(s => ({
        users: [...s.users, ...data.users.filter(u => u.active)],
        allUsers: [...s.allUsers, ...data.users],
      }))

      if (data.users.length > 0) {
        this.loadUsers(this.state.allUsers.length)
      }

      return this.state.users
    })

  updateUser = (userId: USER_ID, user: PatchUserRequestBody) => {
    this.setState(s => ({
      users: s.users.map(u =>
        u.id === userId ? { ...u, ...user, ...(Boolean(user.name) ? apiName(user.name) : {}) } : u,
      ),
    }))
    eventBus.publish(EventBusType.EditUser, user)
    return patchUser(userId, user)
  }

  addUser = (user: PostUserRequestBody) => {
    return postUser(user).then(
      res =>
        res?.data?.user &&
        this.setState(s => ({
          users: s.users.concat(res.data.user),
          allUsers: s.allUsers.concat(res.data.user),
        })),
    )
  }

  deleteUser = (userId: USER_ID) => {
    this.setState(s => ({ users: s.users.filter(u => u.id !== userId) }))
    eventBus.publish(EventBusType.DeleteUser, { userId })
    return deleteUser(userId)
  }

  getUserById = (userId: USER_ID) => this.state.allUsers.find(u => u.id === userId)
}
