import { FeatureType } from '@basisboard/basis-common/lib/api'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { Logo } from '@basisboard/basis-ui/es/components/Logo'
import { colors } from '@basisboard/basis-ui/es/styles'
import { box, fromBoolean, fromNullable } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import keys from 'ramda/src/keys'
import React, { FunctionComponent } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { MINUTES, ViewId } from '../../constants'
import { screens } from '../../screens'
import { InboxListContainer } from '../../screens/Inbox/state'
import {
  AppState,
  hasFeature,
  hasFeatureOrIsInternal,
  isAdmin,
  isBasisAdmin,
  isInternalUse,
} from '../App/container'
import { Notifications } from '../Notifications'
import { Search } from '../Search'
import Profile from './components/Profile'
import {
  ADMIN_ROUTES,
  BASIS_LITE_NAVBAR_INFO,
  FEATURES_ROUTES,
  HIDDEN_ROUTES,
  NAVBAR_INFO,
} from './constants'
import {
  EmailsCountBadge,
  ItemAnchor,
  List,
  ListItem,
  Menu,
  MenuEntry,
  Nav,
  Separator,
  Spacer,
} from './styled'

const matchRouteWithViewPath = (viewPath: string | string[], matcher?: string) => {
  const isRoute = (route: string) => `/${matcher.split('/')[1]}` === route

  return Array.isArray(viewPath) ? viewPath.map(isRoute).includes(true) : isRoute(viewPath)
}

export const Header: FunctionComponent = () => {
  const location = useLocation()
  const appData = useContainer(AppState)
  const inboxListContainer = useContainer(InboxListContainer)

  React.useEffect(() => {
    inboxListContainer.getTotalsForView('all-emails')

    const interval = setInterval(() => {
      inboxListContainer.getTotalsForView('all-emails')
    }, 2 * MINUTES)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const navBar = hasFeature(FeatureType.BasisLite) ? BASIS_LITE_NAVBAR_INFO : NAVBAR_INFO

  const routes = keys(navBar)
    .filter((k: ViewId) => (isAdmin() ? true : !ADMIN_ROUTES.includes(k)))
    .filter((k: ViewId) => (isInternalUse() ? true : !HIDDEN_ROUTES.includes(k)))
    .filter(k =>
      box(FEATURES_ROUTES.find(f => f.path === navBar[k].path)).fold(featureRoute =>
        featureRoute ? hasFeatureOrIsInternal(featureRoute.feature) : true,
      ),
    )
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          ...navBar[key],
          submenu: fromNullable(navBar[key].submenu).fold(
            () => undefined,
            sub =>
              sub
                .filter(submenuView =>
                  isInternalUse() ? true : !HIDDEN_ROUTES.includes(submenuView.path),
                )
                .filter(submenuView =>
                  box(FEATURES_ROUTES.find(f => f.path === submenuView.path)).fold(featureRoute =>
                    featureRoute ? hasFeatureOrIsInternal(featureRoute.feature) : true,
                  ),
                ),
          ),
        },
      }),
      {},
    ) as typeof navBar

  const currentActiveIndex = React.useMemo(
    () =>
      Object.values(routes).findIndex(v =>
        matchRouteWithViewPath(
          (v.submenu && v.submenu.map(s => s.path)) || v.path || '',
          location.pathname,
        ),
      ) || 0,
    [location.pathname],
  )

  const { showAdminBanner, profile } = appData.state

  if (!profile) {
    return null
  }

  return (
    <>
      <Nav showUpdates={showAdminBanner}>
        <Link aria-label="Navigate to home page" to="/projects">
          <Logo />
        </Link>
        <List>
          {Object.keys(routes).map((key, index) => {
            const viewInfo = routes[key]
            return fromBoolean(!viewInfo.submenu || viewInfo.submenu.length !== 0).fold(
              () => null,
              () => (
                <ListItem key={`${viewInfo.path}-${index}`}>
                  <ItemAnchor
                    to={viewInfo.path || viewInfo.submenu?.[0].path || '/'}
                    selected={index === currentActiveIndex}
                  >
                    {viewInfo.name}
                    {Boolean(viewInfo.submenu) && <Icon.Arrow color={colors.gray} />}
                    {viewInfo.path === screens.inboxList.path && (
                      <EmailsCountBadge>
                        {inboxListContainer.state.totals['all-emails'] ?? 0}
                      </EmailsCountBadge>
                    )}
                    {viewInfo.tag}
                  </ItemAnchor>
                  {viewInfo.submenu && (
                    <Menu>
                      {viewInfo.submenu.map(submenuView => (
                        <MenuEntry key={submenuView.name}>
                          <ItemAnchor
                            to={submenuView.path}
                            selected={matchRouteWithViewPath(submenuView.path, location.pathname)}
                          >
                            {submenuView.name}
                            {submenuView.tag}
                          </ItemAnchor>
                        </MenuEntry>
                      ))}
                    </Menu>
                  )}
                </ListItem>
              ),
            )
          })}
        </List>

        <Search />

        <Separator />
        <Profile />

        <Separator />
        <Notifications />
        <Separator />

        {isBasisAdmin() && !appData.state.showAdminBanner && (
          <Button.Transparent
            preIcon="Tools"
            style={{
              position: 'absolute',
              top: '50%',
              right: 24,
              transform: 'translate(50%, -50%)',
            }}
            iconSize={20}
            iconColor={colors.blueGray400}
            onClick={() => appData.setState(s => ({ showAdminBanner: !s.showAdminBanner }))}
          />
        )}
      </Nav>
      <Spacer />
    </>
  )
}
