import {
  Company,
  CompanyCreate,
  CompanyDetail,
  CompanyMergeSuggestion,
  COMPANY_ID,
  GetCompaniesRequestQuery,
  PatchCompanyRequest,
  PostCompanyMergeRequestBody,
} from '@basisboard/basis-common/lib/api'
import { isUuid } from '@basisboard/basis-ui/es/utils'
import { Container, getContainer } from '@containrz/react-hook'
import moment from 'moment'
import uniqBy from 'ramda/src/uniqBy'
import { MINUTES } from '../../constants'
import { screens } from '../../screens'
import { eventBus, EventBusType } from '../../services'
import { showSuccessToast } from '../Toast'
import {
  createCompany,
  deleteCompany,
  getCompanies,
  getCompanyNames,
  getInsights,
  getMergeSuggestions,
  mergeCompanies,
  patchCompany,
  rejectMergeSuggestion,
} from './api'

export * from './api'

interface State {
  loading: boolean
  companies: Company[]
  companyNames: Pick<Company, 'id' | 'name'>[]
  mergeSuggestions: CompanyMergeSuggestion[]
  mergeSuggestionsCount: number
}

export class CompaniesState extends Container<State> {
  unsubscribe: () => void
  reloadTimeout: number

  constructor() {
    super()

    this.state = {
      loading: false,
      companies: [],
      companyNames: [],
      mergeSuggestions: [],
    } as State
  }

  destroy = () => {
    clearTimeout(this.reloadTimeout)
  }

  setCompanies = (companies: Company[]) =>
    this.setState(s => ({
      companies: uniqBy(({ id }) => id, [...(s.companies || []), ...(companies || [])]),
    }))

  searchCompanies = (searchQuery: string) =>
    getCompanies({ filter: { searchQuery } }).then(cmpns => cmpns.companies)

  getCompanies = (query: GetCompaniesRequestQuery) =>
    getCompanies(query).then(data => {
      this.setState(s => ({ companies: [...(s.companies || []), ...(data.companies || [])] }))
      return data
    })

  loadCompanies = async () => {
    if (this.reloadTimeout) {
      clearTimeout(this.reloadTimeout)
    }

    this.unsubscribe?.()
    this.unsubscribe = null

    let companies = []

    const requestCompanies = async () =>
      getCompanies({ offset: companies.length, filter: { showDeleted: true } }).then(
        async cmpns => {
          companies = [...companies, ...cmpns.companies]

          if (cmpns.companies.length > 0) {
            await requestCompanies()
          }
        },
      )

    await requestCompanies()

    this.setState({
      loading: false,
      companies,
    })
  }

  loadCompaniesNames = () => {
    this.setState({ loading: this.state.companyNames.length === 0 })

    this.reloadTimeout = window.setTimeout(() => {
      this.loadCompaniesNames()
    }, 1 * MINUTES)

    return getCompanyNames().then(companyNames => {
      this.setState({ companyNames, loading: false })

      eventBus.publish(EventBusType.LoadedCompanies, { companyNames })

      return companyNames
    })
  }

  invalidate = () => {
    this.setState({ loading: true })
    this.loadCompanies()
  }

  getCompaniesById = (companyIds: COMPANY_ID[] = []) =>
    companyIds.map(companyId => this.state.companies.find(c => c.id === companyId))

  getCompanyById = (companyId: COMPANY_ID) =>
    getCompanies({ filter: { companyIds: [companyId] } }).then(response => response.companies[0])

  getCompanyNamesById = (companyIds: COMPANY_ID[] = []) =>
    this.state.companyNames.filter(c => companyIds.includes(c.id))

  createNewCompany = (company: Partial<Company>, omitToast?: boolean) => {
    eventBus.publish(EventBusType.CreateCompany, { company })

    if (isUuid(company.id || '')) {
      this.setState(s => ({
        companies: [company as Company, ...s.companies],
      }))

      return Promise.resolve(company as CompanyDetail)
    }

    return createCompany(company as CompanyCreate)
      .then(c => {
        this.setState(s => ({
          companies: [c, ...s.companies],
          companyNames: [...s.companyNames, { id: c.id, name: c.name }],
        }))

        !omitToast &&
          showSuccessToast({
            message: 'This company was successfully created!',
            actions: [
              {
                label: 'See details',
                onAction: () => screens.companyDetails.push(company.id),
              },
            ],
          })

        return c
      })
      .finally(this.loadCompaniesNames)
  }

  patchCompany = (
    companyId: COMPANY_ID,
    key: keyof PatchCompanyRequest,
    value: unknown,
    omitEvent?: boolean,
  ) => {
    this.setState(s => ({
      companies: s.companies.map(c => (c.id === companyId ? { ...c, [key]: value } : c)),
    }))

    return patchCompany(companyId, key, value).then(company => {
      this.setState(s => ({
        companies: uniqBy(
          ({ id }) => id,
          s.companies.some(c => c.id === company.id)
            ? s.companies.map(c => (c.id === company.id ? company : c))
            : [...s.companies, company],
        ),
      }))

      if (!omitEvent) {
        eventBus.publish(EventBusType.EditCompany, {
          companyId,
          [key]: value,
          company,
          updatedFields: { [key]: value },
        })
      }

      return company
    })
  }

  deleteCompany = (companyId: COMPANY_ID) => {
    eventBus.publish(EventBusType.DeleteCompany, { companyId })

    this.setState(s => ({
      companies: s.companies.map(c =>
        c.id === companyId ? { ...c, deletedAt: moment().toISOString() } : c,
      ),
    }))

    return deleteCompany(companyId)
  }

  mergeCompanies = async (
    companyIds: COMPANY_ID[],
    data: Omit<PostCompanyMergeRequestBody, 'companyIds'>,
    mergeSuggestion?: CompanyMergeSuggestion,
  ) => {
    const newCompany = await mergeCompanies(companyIds[0], {
      ...data,
      companyIds: companyIds.slice(1),
    })

    this.setState(s => ({
      companies: uniqBy(({ id }) => id, [
        newCompany,
        ...s.companies.filter(c => !companyIds.includes(c.id)),
      ]),
    }))

    eventBus.publish(EventBusType.MergeCompany, { companyIds, mergeSuggestion })
    if (mergeSuggestion) {
      eventBus.publish(EventBusType.AcceptCompanyMergeSuggestion, { mergeSuggestion })
    }

    this.loadCompanies()

    return newCompany
  }

  blockCompany = (companyId: COMPANY_ID) => {
    eventBus.publish(EventBusType.BlockCompany, { companyId })

    return this.patchCompany(companyId, 'blockedAt', moment().toISOString())
  }

  unblockCompany = (companyId: COMPANY_ID) => this.patchCompany(companyId, 'blockedAt', null)

  loadAllCompanies = async (query: GetCompaniesRequestQuery) =>
    new Promise<Company[]>(async resolve => {
      const companies: Company[] = []

      const fetchMoreCompanies = async () =>
        getCompanies({ offset: companies.length, ...query }).then(async response => {
          companies.push(...response.companies)

          if (response.companies.length > 0) {
            await fetchMoreCompanies()
          }
        })

      await fetchMoreCompanies()

      resolve(companies)
    })

  getInsights = getInsights

  getTotalMergeSuggestions = () =>
    getMergeSuggestions({ limit: 0 }).then(response =>
      this.setState({
        mergeSuggestionsCount: response.paging.total,
      }),
    )

  getMergeSuggestions = (
    query?: Partial<{ limit: number; offset: number }>,
    isLoadMore?: boolean,
  ) =>
    getMergeSuggestions(query).then(response =>
      this.setState(s => ({
        mergeSuggestions: isLoadMore
          ? s.mergeSuggestions.concat(response.suggestions)
          : response.suggestions,
        mergeSuggestionsCount: response.paging.total,
      })),
    )

  rejectMergeSuggestion = (suggestion: CompanyMergeSuggestion) => {
    eventBus.publish(EventBusType.RejectCompanyMergeSuggestion, { suggestion })

    return rejectMergeSuggestion({
      firstCompanyId: suggestion.mainCompany.id,
      secondCompanyId: suggestion.childCompany.id,
    }).then(() =>
      this.getMergeSuggestionWithDelay({ limit: this.state.mergeSuggestions.length || 50 }),
    )
  }

  getMergeSuggestionWithDelay = (
    query?: Partial<{ limit: number; offset: number }>,
    delay = 2000,
  ) =>
    getMergeSuggestions(query).then(response => {
      setTimeout(() => {
        this.setState({
          mergeSuggestions: response.suggestions,
          mergeSuggestionsCount: response.paging.total,
        })
      }, delay)
    })
}

export const companyRepository = () => getContainer(CompaniesState)
