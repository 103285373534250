import { User } from '@basisboard/basis-common/lib/api'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { Tooltip } from '@basisboard/basis-ui/es/components/Tooltip'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, cssCircle, transitionMixin } from '@basisboard/basis-ui/es/styles'
import { readableName } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import isEmpty from 'ramda/src/isEmpty'
import * as React from 'react'
import styled from 'styled-components'
import { PersonChip } from '../../../../components/PersonChip'
import { isReadOnlyUser } from '../../../App/container'
import { showInfoToast } from '../../../Toast'
import { UsersContainer } from '../../container'
import { UsersSelector } from '../UsersSelector'

interface Props {
  value?: string[] | string
  height?: number
  onUpdate?: (users: User[] | User) => void
  initialValue?: string[] | string
  name?: string
  preventEmpty?: boolean
}

export const Initials = styled.span`
  color: #aeabab;
  font-size: 10px;
  line-height: 16px;
`

const Chip = styled.div`
  ${cssCircle(24)};

  cursor: pointer;
  background: ${colors.white};

  ${transitionMixin};

  &:hover {
    background: ${colors.lightGray2};
  }

  @media print {
    display: none;
  }
`

export const UsersChip: React.FC<Props> = ({
  value = [],
  initialValue,
  height,
  onUpdate,
  name,
  preventEmpty,
}) => {
  const { allUsers } = useContainer(UsersContainer).state

  const vals = initialValue || value

  const estimators =
    typeof vals === 'string'
      ? allUsers.find(user => user.id === vals)
      : ((vals as string[]) || []).map(u => allUsers.find(user => user.id === u))

  const isArray = React.useMemo(() => Array.isArray(estimators), [estimators])
  const [addingEstimator, setAddingEstimator] = React.useState(false)
  const targetRef = React.useRef<HTMLDivElement>()

  const [currentEstimators, setCurrentEstimators] = React.useState<User | User[]>(
    isArray ? (estimators as User[]).filter(Boolean) : estimators,
  )

  React.useEffect(() => {
    const val = isEmpty(value) ? initialValue : value

    const est =
      typeof val === 'string'
        ? allUsers.find(user => user.id === val)
        : ((val as string[]) || []).map(u => allUsers.find(user => user.id === u))

    setCurrentEstimators(isArray ? (est as User[]).filter(Boolean) : est)
  }, [JSON.stringify(value), JSON.stringify(initialValue), isArray])

  const handleAssign = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()

    if (isReadOnlyUser()) {
      return
    }

    setAddingEstimator(true)
  }

  const toggleOpen = React.useCallback(
    isOpen => {
      if (!isOpen) {
        if (
          preventEmpty &&
          (Array.isArray(currentEstimators) ? currentEstimators : [currentEstimators]).length === 0
        ) {
          setCurrentEstimators(isArray ? (estimators as User[]).filter(Boolean) : estimators)
          showInfoToast({ message: 'Make sure to select at least one estimator' })
        } else {
          onUpdate?.(currentEstimators)
        }
      }
      setAddingEstimator(isOpen)
    },
    [currentEstimators, estimators],
  )

  return (
    <>
      <Div
        width={1}
        height={height || 24}
        display="flex"
        alignItems="center"
        ref={targetRef}
        onClick={handleAssign}
      >
        {((isArray ? currentEstimators : [currentEstimators]) as User[])
          .filter(Boolean)
          .map(estimator => (
            <PersonChip
              key={estimator.id}
              color={estimator.color}
              chipOnly
              name={readableName(estimator)}
            />
          ))}

        {!isReadOnlyUser() && (
          <Tooltip
            content={<Text white>Add an estimator</Text>}
            variant="dark"
            small
            position="top"
          >
            <Chip data-testid="chip">
              <Icon.Assign />
            </Chip>
          </Tooltip>
        )}
      </Div>

      {name && (
        <select name={name} className="visually-hidden" multiple={isArray}>
          {(isArray
            ? (currentEstimators as User[]).map(e => e.id)
            : [(currentEstimators as User)?.id]
          ).map(e => (
            <option value={e} key={e} selected />
          ))}
        </select>
      )}

      <UsersSelector
        value={(isArray
          ? (currentEstimators as User[]).map(e => e.id)
          : [(currentEstimators as User)?.id]
        ).filter(Boolean)}
        setOpen={toggleOpen}
        isOpen={addingEstimator}
        onSave={selectedEstimators =>
          setCurrentEstimators(
            isArray
              ? selectedEstimators.map(estimatorId => allUsers.find(u => u.id === estimatorId))
              : allUsers.find(u => u.id === [...selectedEstimators].pop()),
          )
        }
        target={targetRef.current}
      />
    </>
  )
}
