"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.geocode = exports.initPlacesAPI = exports.loadGoogleMapScript = void 0;
const loadGoogleMapScript = (apiKey, callback) => {
    if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
        callback();
    }
    else {
        const googleMapScript = document.createElement('script');
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=en`;
        googleMapScript.async = true;
        window.document.body.appendChild(googleMapScript);
        googleMapScript.addEventListener('load', callback);
    }
};
exports.loadGoogleMapScript = loadGoogleMapScript;
const autocompleteOptions = {
    fields: ['address_components', 'formatted_address', 'geometry', 'place_id'],
};
const initPlacesAPI = (inputRef, setPlace) => {
    const autocomplete = new window.google.maps.places.Autocomplete(inputRef, autocompleteOptions);
    new window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
        setPlace(autocomplete.getPlace());
    });
};
exports.initPlacesAPI = initPlacesAPI;
const geocode = async (input, map, callback) => {
    const service = new window.google.maps.places.AutocompleteService();
    return service.getPlacePredictions({ input }, (predictions, status) => {
        if (status === 'OK' && predictions.length > 0) {
            const placesService = new window.google.maps.places.PlacesService(map);
            placesService.getDetails({ placeId: predictions[0].place_id }, (geocodingResult, status) => {
                if (status === 'OK') {
                    return callback(geocodingResult);
                }
            });
        }
    });
};
exports.geocode = geocode;
