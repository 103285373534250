export enum ExperimentType {
  UpdatesBanner = 'updatesBanner',
  CreateTaskAfterLoggingQuote = 'createTaskAfterLoggingQuote',
}

/**
 * Updates Banner.
 */
export interface UpdatesBannerFeature {
  showBanner: boolean;
}

type GetUpdatesBannerFeature = (
  feature: ExperimentType.UpdatesBanner,
) => AppAbFeature<UpdatesBannerFeature>;

/**
 * Create Task After Logging Quote
 */
export interface CreateTaskAfterLoggingQuoteFeature {
  showAtEnd?: boolean;
  showAtNewStep?: boolean;
}

type GetCreateTaskAfterLoggingQuoteFeature = (
  feature: ExperimentType.UpdatesBanner,
) => AppAbFeature<CreateTaskAfterLoggingQuoteFeature>;

/**
 * Interfaces
 */
export interface AppAbFeature<T> {
  a: T;
  b: T;
}

export type FeatureTypes =
  | AppAbFeature<UpdatesBannerFeature>
  | AppAbFeature<CreateTaskAfterLoggingQuoteFeature>;

export type GetFeature = GetUpdatesBannerFeature | GetCreateTaskAfterLoggingQuoteFeature;
