import { CustomFieldValues, SelectOption } from '@basisboard/basis-common/lib/api/custom-fields'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, scrollBarMixin, spacing } from '@basisboard/basis-ui/es/styles'
import { box, fromEmpty } from '@basisboard/basis-ui/es/utils'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { TableFieldProps } from '../../type'
import { getOptionColors } from '../../utils'
import { CustomFieldSelectOption } from '../styled'

const List = styled.ul`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin: 0;
  padding: ${spacing(0.5)} 0;

  overflow: hidden auto;

  ${scrollBarMixin};

  max-height: 240px;
  max-width: 100%;
`

const Entry = styled.span`
  display: inline-block;
  max-width: 100%;
`

const ListEntry = styled.li<{ highlighted?: boolean }>`
  width: 100%;
  max-width: 100%;

  cursor: pointer;

  padding: ${spacing(0.5)} ${spacing(2)};

  ${({ highlighted }) =>
    highlighted &&
    css`
      background-color: ${colors.lightGray3};
    `}
`

export const SelectorValues: React.FC<Partial<
  Pick<TableFieldProps, 'value' | 'onChange' | 'renderOption' | 'allowCreateNewValue'>
> & {
  options: (SelectOption & { value: CustomFieldValues; disabled?: boolean })[]
}> = ({ options, value, renderOption, onChange, allowCreateNewValue }) => {
  const ref = React.useRef<HTMLUListElement>()
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  React.useEffect(() => {
    setSelectedIndex(0)
  }, [JSON.stringify(options)])

  React.useEffect(() => {
    const handleKeyDown = e => {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault()
          setSelectedIndex(i => (i + 1 === options.length ? 0 : i + 1))
          break

        case 'ArrowUp':
          e.preventDefault()
          setSelectedIndex(i => (i - 1 < 0 ? options.length - 1 : i - 1))
          break

        case 'Enter':
          e.preventDefault()
          ;(ref.current.querySelector('.active')?.firstElementChild as HTMLButtonElement)?.focus()
          if (!allowCreateNewValue) {
            onChange?.(options[selectedIndex].value)
          }
          break

        case 'Tab':
          e.preventDefault()
          break
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectedIndex, JSON.stringify(options)])

  return (
    <List ref={ref}>
      {fromEmpty(options).fold(
        opts =>
          opts.filter(Boolean).map((option, index) => (
            <ListEntry
              key={option.value?.toString() || index}
              highlighted={selectedIndex === index}
              className={selectedIndex === index ? 'active' : ''}
              onClick={e => {
                e.stopPropagation()
                !option.disabled && onChange(option.value)
              }}
            >
              <Entry>
                {box({}).fold(
                  props =>
                    renderOption?.(option, props) || (
                      <CustomFieldSelectOption
                        isSelectItem
                        active={value === option.value}
                        {...getOptionColors(option)}
                      >
                        {option.label}
                      </CustomFieldSelectOption>
                    ),
                )}
              </Entry>
            </ListEntry>
          )),
        () => (
          <Div as="li" width={1} p={spacing(2)}>
            <Text textAlign="center">
              {value ? (
                <>
                  All existing values
                  <br />
                  were already selected
                </>
              ) : (
                <>Type to search options...</>
              )}
            </Text>
          </Div>
        ),
      )}
    </List>
  )
}
