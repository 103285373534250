"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Unlink = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Unlink = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M9.24741 11.9015C9.36897 12.023 9.36897 12.2201 9.24741 12.3417L8.08855 13.5005C6.55125 15.0378 4.04982 15.0379 2.51239 13.5005C0.975062 11.9632 0.975062 9.46174 2.51239 7.92441L3.67128 6.76553C3.79283 6.64397 3.98993 6.64397 4.11151 6.76553L5.13869 7.79271C5.26025 7.91427 5.26025 8.11136 5.13869 8.23295L3.97981 9.3918C3.25161 10.12 3.25161 11.3049 3.97981 12.0331C4.70801 12.7613 5.89291 12.7614 6.62116 12.0331L7.78002 10.8743C7.90157 10.7527 8.09867 10.7527 8.22025 10.8743L9.24741 11.9015ZM7.78002 5.15136C7.90157 5.27292 8.09867 5.27292 8.22025 5.15136L9.37911 3.9925C10.1073 3.26423 11.2922 3.26428 12.0205 3.9925C12.7487 4.72073 12.7487 5.9056 12.0205 6.63383L10.8616 7.79269C10.74 7.91424 10.74 8.11134 10.8616 8.23292L11.8888 9.26011C12.0103 9.38166 12.2074 9.38166 12.329 9.26011L13.4879 8.10122C15.0252 6.56387 15.0252 4.06244 13.4879 2.52509C11.9505 0.987758 9.4491 0.987758 7.91172 2.52509L6.75286 3.68394C6.6313 3.8055 6.6313 4.0026 6.75286 4.12418L7.78002 5.15136ZM13.8715 14.4712L14.4585 13.8843C14.7016 13.6411 14.7016 13.2469 14.4585 13.0038L3.00915 1.55442C2.76601 1.31129 2.37182 1.31129 2.12871 1.55442L1.54173 2.1414C1.29859 2.38454 1.29859 2.77873 1.54173 3.02184L12.9911 14.4712C13.2342 14.7143 13.6284 14.7143 13.8715 14.4712Z", fill: color })));
exports.Unlink = Unlink;
