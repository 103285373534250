const BLUE = '#0d28f2'
const ALMOST_BLACK = '#0c141e'
const ERROR_RED = '#ff214c'
const SUCCESS_GREEN = '#32c533'

const MAIN_COLORS = {
  blue: BLUE,
  almostBlack: ALMOST_BLACK,
  darkBlue: '#1c2c40',
  darkGray: '#5c6572',
  gray: '#949ca8',
  mediumGray: '#868e9b',
  lightGray: '#ced3d9',
  lightGray2: '#e6eaed',
  lightGray3: '#f5f6f7',
}

const DEFAULT_STAGES = {
  undecided: '#a9b0b9',
  declined: ERROR_RED,
  accepted: SUCCESS_GREEN,
  estimating: '#ffad32',
  submitted: '#7a33b8',
  awarded: '#26c6da',
  lost: '#ff5722',
  deleted: '#f5f6f7',
}

const STAGES = [
  '#ced3d9',
  '#ff5722',
  '#ffa726',
  '#ffca28',
  '#a5de37',
  '#81c784',
  '#80cbc4',
  '#ab47bc',
  '#ec407a',
  '#ed4694',
  '#a1887f',
  '#bdbdbd',
]

export const colors = {
  accent: BLUE,
  accentActive: '#00aaf1',
  primaryText: ALMOST_BLACK,
  boardIcon: '#b7c1cf',

  border: '#eaebee',

  error: ERROR_RED,
  success: SUCCESS_GREEN,
  warning: '#ffad32',

  white: '#fff',
  black: '#000',

  ...MAIN_COLORS,

  DEFAULT_STAGES,
  STAGES,
}
