import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Tooltip } from '@basisboard/basis-ui/es/components/Tooltip'
import { HeavyText, Text, TypographyProps } from '@basisboard/basis-ui/es/components/Typography'
import * as React from 'react'

const doesTextOverflow = textElement =>
  textElement &&
  textElement.current &&
  textElement.current.clientWidth < textElement.current.scrollWidth

interface Props extends TypographyProps {
  maxWidthMargin?: number
  TextComponent?: React.FC
  dangerouslySetInnerHTML?: any
}

export const TextOverflow: React.FC<Props> = ({ maxWidthMargin = 0, TextComponent, ...props }) => {
  const textElement = React.useRef<HTMLSpanElement>()
  const [, forceUpdate] = React.useState(false)

  React.useLayoutEffect(() => {
    forceUpdate(f => !f)
  }, [])

  const Component = TextComponent || HeavyText

  return (
    <Div maxWidth={`calc(100% - ${maxWidthMargin}px)`}>
      {doesTextOverflow(textElement) ? (
        <Tooltip
          wrapProps={{ display: 'block' }}
          position="bottomCenter"
          variant="dark"
          small
          content={<Text white>{props.children || props.dangerouslySetInnerHTML.__html}</Text>}
        >
          <Component {...(props as any)} display="block" ref={textElement} />
        </Tooltip>
      ) : (
        <Component {...(props as any)} display="block" ref={textElement} />
      )}
    </Div>
  )
}
