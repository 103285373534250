import { ProjectSortV2 } from '@basisboard/basis-common/lib/api'
import { CustomFieldType } from '@basisboard/basis-common/lib/api/custom-fields'
import { SortDirection } from '@basisboard/basis-common/lib/enums'

export const getSortOptionByType = (type: CustomFieldType) => {
  switch (type) {
    case CustomFieldType.Text:
    case CustomFieldType.UserMultiSelect:
    case CustomFieldType.CompanyMultiSelect:
    case CustomFieldType.MultiSelect:
    case CustomFieldType.SingleSelect:
    case CustomFieldType.TeamSelect:
      return [
        { name: 'A → Z', id: SortDirection.ASC },
        { name: 'Z → A', id: SortDirection.DESC },
      ]

    case CustomFieldType.Date:
      return [
        { name: 'Past → Future', id: SortDirection.ASC },
        { name: 'Future → Past', id: SortDirection.DESC },
      ]

    default:
      return [
        { name: '1 → 9', id: SortDirection.ASC },
        { name: '9 → 1', id: SortDirection.DESC },
      ]
  }
}

export const getInitialSorts = (sorts: ProjectSortV2[]) =>
  sorts.length > 0
    ? sorts
    : [{ name: '', direction: SortDirection.ASC, entity: 'project' } as ProjectSortV2]
