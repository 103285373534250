import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Label } from '@basisboard/basis-ui/es/components/Typography'
import {
  borderMixin,
  borderRadiusMixin,
  colors,
  cssSize,
  DEFAULT_BORDER_RADIUS,
  spacing,
  transitionMixin,
} from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { background, BackgroundProps, height, HeightProps, space, SpaceProps } from 'styled-system'
import { ButtonText } from '../../ButtonText'

export interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
  label?: string
  symbol?: React.ReactNode
  suffix?: React.ReactNode
  symbolBackgroundColor?: string
  error?: string
  canToggleVisible?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  space?: SpaceProps
}

export const BaseTextInput = styled.input<{ hasSymbol: boolean; hasError: boolean } & SpaceProps>`
  box-sizing: border-box;
  height: 100%;
  ${borderRadiusMixin};
  ${borderMixin('default')};
  position: relative;
  padding: 0 ${spacing(2)};
  width: 100%;
  background: transparent;

  color: ${colors.darkBlue};
  font-size: 0.875rem;
  font-weight: 500;

  ${({ hasSymbol }) =>
    hasSymbol &&
    css`
      padding-left: 40px;
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${colors.error};
    `}

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${transitionMixin};

  &:focus {
    border-color: #0d28f2;
    outline: 0;
  }

  &::placeholder {
    color: ${colors.gray};
  }

  &:disabled {
    background: #f9fafb;
    color: ${colors.lightGray};

    &::placeholder {
      color: ${colors.lightGray};
    }

    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    ${borderMixin('hover')}
  }

  ${space};
`

const Wrapper = styled.div<HeightProps>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40px;
  ${borderRadiusMixin};
  background: ${colors.white};
  ${height};
`

const Symbol = styled.div<BackgroundProps & HeightProps & { suffix?: boolean }>`
  ${({ height }) => cssSize(height ? +height - 2 : 38)};
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: ${colors.gray};
  background: ${colors.lightGray3};
  ${background};
  position: absolute;

  pointer-events: none;

  top: 1px;
  border-radius: ${DEFAULT_BORDER_RADIUS}px 0 0 ${DEFAULT_BORDER_RADIUS}px;

  ${({ suffix }) =>
    suffix
      ? css`
          right: 40px;
          background: transparent;
        `
      : css`
          left: 1px;
        `};
`

export const TextInput: any = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label,
      symbol,
      symbolBackgroundColor,
      error,
      type,
      canToggleVisible,
      suffix,
      height,
      space,
      ...props
    },
    ref,
  ) => {
    const [showingPassword, setShowingPassword] = React.useState(false)

    const htmlType = type === 'password' && showingPassword ? 'text' : type

    return (
      <>
        {type === 'password' && canToggleVisible ? (
          <Div display="flex" alignItems="center">
            {label && <Label required={props.required}>{label}</Label>}
            <ButtonText
              tabIndex={-1}
              mb={spacing(1)}
              ml="auto"
              onClick={() => setShowingPassword(p => !p)}
              type="button"
            >
              {showingPassword ? 'Hide' : 'Show'} password
            </ButtonText>
          </Div>
        ) : (
          <>{label && <Label required={props.required}>{label}</Label>}</>
        )}
        <Wrapper height={height}>
          <BaseTextInput
            hasSymbol={!!symbol}
            hasError={!!error}
            ref={ref}
            type={htmlType}
            {...space}
            {...(props as any)}
          />
          {symbol && (
            <Symbol height={height} background={symbolBackgroundColor}>
              {symbol}
            </Symbol>
          )}
          {suffix && (
            <Symbol height={height} suffix>
              {suffix}
            </Symbol>
          )}
        </Wrapper>
        {error && error.trim().length > 0 && (
          <Label preventClip error>
            {error}
          </Label>
        )}
      </>
    )
  },
)
