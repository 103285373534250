import equals from 'ramda/src/equals'
import { useEffect, useState } from 'react'
import { InputProps, InputType } from './types'
import { TwitterPicker } from 'react-color'

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const getSelectValues = (select: HTMLSelectElement) => {
  if (!select.multiple) {
    return select.value
  }

  return Array.from(select.options).reduce(
    (acc, option) => (option.selected ? [...acc, option.value] : acc),
    [],
  )
}

const getRadioValue = (radio: HTMLInputElement) => {
  const radios = document.getElementsByName(radio.name)

  return (Array.from(radios).find((r: HTMLInputElement) => r.checked) as HTMLInputElement).value
}

const refs = {}

const initialValues = {
  [InputType.CHECKBOX]: false,
  [InputType.NUMBER]: 0,
}

export const useForm = (fields: InputProps[]) => {
  const [initialFields, setInitialFields] = useState(fields)
  const [uuid] = useState(uuidv4())
  const [initialState] = useState(
    fields.reduce(
      (acc, { name, initialValue, inputType }) => ({
        ...acc,
        [name]: initialValue || initialValues[inputType] || '',
      }),
      [],
    ),
  )

  useEffect(() => {
    setInitialFields(fields)
  }, [equals(fields, initialFields)])

  useEffect(() => {
    return () => {
      if (!refs[uuid]) {
        return
      }

      delete refs[uuid]
    }
  }, [uuid])

  const register = (ref: HTMLInputElement | TwitterPicker) => {
    if (!ref) {
      return
    }

    if (!refs[uuid]) {
      refs[uuid] = {}
    }

    const name = ref.name || ref.props?.name
    if (refs[uuid][name]) {
      return
    }

    ref.value = ref.value || initialState[name] || ''

    refs[uuid][name] = ref
  }

  const getState = () => {
    return Object.keys(refs[uuid]).reduce(
      (acc, key) => ({
        ...acc,
        [key]:
          refs[uuid][key] instanceof HTMLSelectElement
            ? getSelectValues(refs[uuid][key])
            : refs[uuid][key].type === 'checkbox'
            ? refs[uuid][key].checked
            : refs[uuid][key].type === 'radio'
            ? getRadioValue(refs[uuid][key])
            : refs[uuid][key] instanceof TwitterPicker
            ? refs[uuid][key].props.color
            : refs[uuid][key].value,
      }),
      {},
    )
  }

  return {
    register,
    getState,
  }
}
