"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PieChart = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const PieChart = ({ width = 20, height = 20, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 20 20" },
    React.createElement("path", { d: "M19.3696 11.1721H10.6777L16.4663 16.9608C16.6876 17.182 17.0517 17.2 17.2791 16.9857C18.6967 15.6501 19.6718 13.8498 19.9579 11.826C20.007 11.4794 19.7194 11.1721 19.3696 11.1721ZM18.7898 8.79849C18.4879 4.42266 14.9912 0.925959 10.6154 0.624127C10.2813 0.60105 10 0.882735 10 1.21753V9.41387H18.1967C18.5315 9.41387 18.8128 9.13255 18.7898 8.79849ZM8.24177 11.1721V2.48017C8.24177 2.13035 7.93444 1.84281 7.58829 1.89189C3.22309 2.50874 -0.113543 6.3223 0.0417683 10.8926C0.201475 15.5864 4.24287 19.4355 8.93884 19.3765C10.785 19.3534 12.4909 18.7586 13.8934 17.7637C14.1828 17.5586 14.2018 17.1326 13.9509 16.8816L8.24177 11.1721Z", fill: color })));
exports.PieChart = PieChart;
