import {
  PatchCustomFieldSettingRequestBody,
  PostCustomFieldSettingRequestBody,
} from '@basisboard/basis-common/lib/api'
import {
  CustomFieldEntity,
  CustomFieldSetting,
  CustomFieldSingleSelectSetting,
  CustomFieldType,
  CustomFieldUserMultiSelectSetting,
  CustomFieldValues,
  SelectOption,
} from '@basisboard/basis-common/lib/api/custom-fields'
import { box, fromNullable } from '@basisboard/basis-ui/es/utils'
import { Container } from '@containrz/react-hook'
import keys from 'ramda/src/keys'
import omit from 'ramda/src/omit'
import values from 'ramda/src/values'
import { ViewId } from '../../constants'
import { eventBus, EventBusType } from '../../services'
import { appRepository } from '../App/container'
import {
  createCustomField,
  deleteCustomField,
  getCustomFields,
  putFields,
  updateCustomField,
} from './api'
import { LIST_FIELDS } from './constants'
import {
  createColumnsForCustomFields,
  DEFAULT_COLUMN_WIDTH,
  getCustomFieldsForView,
  getInitialState,
} from './helpers'
import { Field } from './types'

type Fields = Record<ViewId, Field[]>

interface State extends Fields {
  customFields: CustomFieldSetting[]
  loading: boolean
}

export class FieldsState extends Container<State> {
  state = { ...(getInitialState([], appRepository().state.profile?.role) as State), loading: true }

  loadCustomFields = async () => {
    const customFields = await getCustomFields()

    const defaultFields = getInitialState([], appRepository().state.profile?.role)
    let shouldUpdateSettings = false

    const state = Object.values(ViewId).reduce((acc, key) => {
      const localFields = defaultFields[key]

      const storedFields: Field[] = fromNullable(
        appRepository().state.profile?.settings[`${key}-fields`]?.fields as Field[],
      ).fold(
        () => localFields,
        fields => fields,
      )

      if (!storedFields) {
        return acc
      }

      const workspaceFields = getCustomFieldsForView(customFields, key)

      const newCustomFields = workspaceFields.filter(
        field => !storedFields.some(storedField => storedField.id === field.id),
      )
      const newLocalFields = localFields.filter(
        field => !storedFields.some(storedField => storedField.id === field.id),
      )

      if (newCustomFields.length > 0 || newLocalFields.length > 0) {
        shouldUpdateSettings = true
      }

      return {
        ...acc,
        [key]: [
          // use stored fields removing any field that may be deleted
          ...storedFields.filter(
            field =>
              localFields.find(f => f.id === field.id) ||
              workspaceFields.find(f => f.id === field.id),
          ),

          // include any new local fields
          ...newLocalFields,

          // include any new custom fields
          ...newCustomFields,
        ],
      }
    }, {}) as State

    if (shouldUpdateSettings) {
      Object.values(ViewId).map(key => putFields(key, state[key]))
    }

    eventBus.publish(EventBusType.LoadedFields, { customFields })
    this.setState({ ...state, customFields, loading: false })
  }

  patchField = (viewId: ViewId, field: Field) => {
    const currentFields = [...this.state[viewId]]
    const updatedFields = this.state[viewId].map(f => (f.id === field.id ? field : f))

    this.setState({ [viewId]: updatedFields })

    putFields(viewId, updatedFields).catch(() => {
      this.setState({ [viewId]: currentFields })
    })
  }

  patchFields = (viewId: ViewId, fields: Field[]) => {
    const currentFields = [...this.state[viewId]]
    this.setState({ [viewId]: fields })

    putFields(viewId, fields).catch(() => {
      this.setState({ [viewId]: currentFields })
    })
  }

  updateField = (viewId: ViewId, field: Field) => {
    this.patchField(viewId, field)

    eventBus.publish(EventBusType.ToggleField, field)
  }

  resortFields = (viewId: ViewId, fields: Field[]) => {
    this.patchFields(viewId, fields)

    eventBus.publish(EventBusType.ResortFields)
  }

  resizeField = (viewId: ViewId, field: Field) => {
    this.patchField(viewId, field)

    eventBus.publish(EventBusType.ResizeField, field)
  }

  resetField = viewId => {
    const initialState = getInitialState(
      this.state.customFields,
      appRepository().state.profile.role,
    )
    this.patchFields(viewId, initialState[viewId])

    eventBus.publish(EventBusType.ResetFields)
  }

  sortColumnsAccordingToFieldsOrder = (columns: any, viewId: ViewId, fields?: Field[]) =>
    [...(fields || this.state[viewId])]
      .map(f =>
        f.isEnabled
          ? {
              ...columns[f.id],
              width: f.width || LIST_FIELDS.find(l => l.id === f.id)?.width || DEFAULT_COLUMN_WIDTH,
              onResize: (width: number) => this.resizeField(viewId, { ...f, width }),
            }
          : null,
      )
      .filter(f => !!f)

  createCustomColumnsForEntity = (entity: CustomFieldEntity) =>
    createColumnsForCustomFields(this.state.customFields, entity)

  getCustomFieldsForEntity = (entity: CustomFieldEntity) =>
    this.state.customFields.filter(c => c.entity === entity)

  getCustomFieldTagOptionForValue = (
    entity: CustomFieldEntity,
    value: CustomFieldValues,
  ): SelectOption =>
    this.getCustomFieldsForEntity(entity)
      .find(cf => cf.isEntityLabel)
      ?.options.find(opt => opt.label === value) || null

  addCustomField = (field: PostCustomFieldSettingRequestBody, preventLog = false) =>
    createCustomField(field)
      .then(customField => {
        this.setState(s => ({
          ...s,
          customFields: [...s.customFields, customField],
        }))

        !preventLog && eventBus.publish(EventBusType.CreateCustomField, customField)
      })
      .then(this.loadCustomFields)

  deleteCustomField = (fieldId: string, preventLog = false) => {
    const field = this.state.customFields.find(f => f.id === fieldId)
    this.setState(s => ({ customFields: s.customFields.filter(c => c.id !== fieldId) }))

    values(ViewId).forEach(viewId => {
      if (this.state[viewId])
        this.patchFields(
          viewId,
          this.state[viewId].filter(({ id }) => id !== fieldId),
        )
    })

    return deleteCustomField(fieldId)
      .then(this.loadCustomFields)
      .then(
        () =>
          !preventLog &&
          eventBus.publish(EventBusType.DeleteCustomField, {
            fieldId,
            entity: field?.entity,
          }),
      )
  }

  updateCustomField = async (
    fieldId: string,
    field: PatchCustomFieldSettingRequestBody,
    preventLog = false,
  ) => {
    const newField = await updateCustomField(fieldId, field)

    const viewFields = omit(['customFields', 'loading'], this.state)
    // update labels on existing views
    const fields = keys(viewFields).reduce(
      (acc, view) =>
        viewFields[view].find(f => f.id === fieldId)
          ? {
              ...acc,
              [view]: viewFields[view].map((f: Field) =>
                f.id === fieldId
                  ? ({
                      ...f,
                      name: newField.label,
                      label: newField.label,
                    } as Field)
                  : f,
              ),
            }
          : acc,
      {},
    )

    this.setState(({ customFields }) => ({
      customFields: customFields.map(c => (c.id === fieldId ? newField : c)),
      ...fields,
    }))

    keys(fields).map(key => {
      putFields(key, fields[key])
    })

    if (!preventLog) {
      eventBus.publish(EventBusType.EditCustomField, {
        fieldId,
        entity: this.state.customFields.find(f => f.id === fieldId).entity,
        changes: field,
      })
    }
  }

  updateCustomFieldsOrder = (fields: CustomFieldSetting[]) => {
    const updatedFields = fields.map((f, i) => ({ ...f, position: i }))
    this.setState({ customFields: updatedFields })

    updatedFields.forEach(field => {
      updateCustomField(field.id, { position: field.position })
    })
  }

  addTagOption = (entity: CustomFieldEntity, option: SelectOption) =>
    box(
      this.getCustomFieldsForEntity(entity).find(
        f => f.isEntityLabel,
      ) as CustomFieldSingleSelectSetting,
    )
      .map(labelField =>
        this.updateCustomField(labelField.id, {
          options: [...labelField.options, option],
        }),
      )
      .fold(() => eventBus.publish(EventBusType.CreateTagForEntity, { entity, option }))

  editTagOption = (entity: CustomFieldEntity, option: SelectOption, initialLabel: string) =>
    box(
      this.getCustomFieldsForEntity(entity).find(
        f => f.isEntityLabel,
      ) as CustomFieldSingleSelectSetting,
    ).fold(labelField =>
      this.updateCustomField(labelField.id, {
        options: labelField.options.map(opt => (opt.label === initialLabel ? option : opt)),
      }),
    )

  deleteTagOption = (entity: CustomFieldEntity, option: SelectOption) =>
    box(
      this.getCustomFieldsForEntity(entity).find(
        f => f.isEntityLabel,
      ) as CustomFieldSingleSelectSetting,
    )
      .map(labelField =>
        this.updateCustomField(labelField.id, {
          options: labelField.options.filter(o => o.label !== option.label),
        }),
      )
      .fold(() => eventBus.publish(EventBusType.DeleteTagFromEntity, { entity, option }))

  getTeams = (): CustomFieldUserMultiSelectSetting[] =>
    this.getCustomFieldsForEntity(CustomFieldEntity.Board).filter(
      f => f.entitySettings.isTeam && f.type === CustomFieldType.UserMultiSelect,
    ) as CustomFieldUserMultiSelectSetting[]

  getDistributionLists = () =>
    this.getCustomFieldsForEntity(CustomFieldEntity.Board).filter(
      field => field.entitySettings.isDistributionList,
    )
}
