import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Div, DivProps } from '@basisboard/basis-ui/es/components/Div'
import {
  GeocodingData,
  PlacesAutocomplete,
} from '@basisboard/basis-ui/es/components/PlacesAutocomplete'
import { Table2 } from '@basisboard/basis-ui/es/components/Table2'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { useHover } from '@basisboard/basis-ui/es/hooks/useHover'
import { colors } from '@basisboard/basis-ui/es/styles/colors'
import { spacing } from '@basisboard/basis-ui/es/styles/spacing'
import React from 'react'
import styled from 'styled-components'
import { config } from '../../../../config'
import { isReadOnlyUser } from '../../../../containers/App/container'

const Wrapper = styled(Div)`
  display: flex;
  align-items: center;
  width: 100%;
`

export interface Props extends DivProps {
  value: string | GeocodingData
  onChange: (geocodingData: GeocodingData) => void
  emptyHoverState?: string | React.ReactNode
  children?: React.ReactNode
}

export const LocationField = ({
  value,
  onChange,
  emptyHoverState = 'Add location',
  ...divProps
}: Props) => {
  const { hovering, ...hoverProps } = useHover()
  const [editing, setEditing] = React.useState(false)
  const [ref, setRef] = React.useState<HTMLDivElement>()

  const handleEdit = () => {
    if (!isReadOnlyUser()) {
      setEditing(true)
    }
  }
  const isEmptyValue = value?.length === 0 || value === 'N/A'

  const isWithinTable = ref?.parentElement.tagName === 'TD'

  const input = (
    <PlacesAutocomplete
      apiKey={config.GOOGLE_MAP_API_KEY}
      value={isEmptyValue ? '' : value}
      onChange={onChange}
      onBlur={() => setEditing(false)}
      minWidth={350}
      autoFocus
    />
  )
  return (
    <Wrapper
      {...hoverProps}
      {...divProps}
      onClick={handleEdit}
      onFocus={handleEdit}
      tabIndex={0}
      ref={setRef}
    >
      {editing ? (
        isWithinTable ? (
          <Table2.ActionsWrapper anchorElement={ref}>{input}</Table2.ActionsWrapper>
        ) : (
          input
        )
      ) : (
        <Text
          data-testid="Project Location"
          style={{ minHeight: '18px' }}
          color={isEmptyValue ? colors.lightGray : undefined}
        >
          {isEmptyValue ? 'Not set' : value}
        </Text>
      )}
      {!isReadOnlyUser() && hovering && !editing && (
        <Div flexShrink={0}>
          <Button.Gradient
            ml={isEmptyValue ? 0 : spacing(1)}
            preIcon={isEmptyValue ? 'Plus' : undefined}
            postIcon={!isEmptyValue ? 'Pencil' : undefined}
            onClick={handleEdit}
          >
            {isEmptyValue ? emptyHoverState : null}
          </Button.Gradient>
        </Div>
      )}
    </Wrapper>
  )
}
