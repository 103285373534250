export enum InboxBulkActions {
  Trash = 'trash',
  Add = 'add',
  Decline = 'decline',
  Group = 'group',
  MoveToInbox = 'moveToInbox',
}

export enum InboxFieldType {
  Subject = 'subject',
  FromEmail = 'from-email',
  CreatedAt = 'created-at',
  CommunicationType = 'communication-type',
  PlatformId = 'platform-id',
  BidDeadlineAt = 'bid-deadline-at',
  ProjectLocation = 'project-location',
  Company = 'company',
  ProjectName = 'project-name',
  ProjectRegion = 'project-region',
  Union = 'union',
  Distance = 'distance',
  TravelTime = 'travel-time',
  ProjectCity = 'project-city',
  ProjectAddressLine = 'project-address-line',
  // CompanyPriority = 'company-priority',
}
