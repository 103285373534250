import { showSplitBidsToast } from '../../containers/Projects'
import { showSuccessToast } from '../../containers/Toast'
import { screens } from '../../screens'
import { eventBus, EventBusType } from '../../services'

eventBus.register(EventBusType.SplitBids, ({ projectId }) => {
  showSplitBidsToast(projectId)
})

eventBus.register(EventBusType.SwitchBidFromProject, ({ projectId }) =>
  showSuccessToast({
    message: 'Switched company to different bid',
    actions: [{ label: 'See bid details', onAction: () => screens.projectDetails.push(projectId) }],
  }),
)
