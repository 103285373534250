import { borderRadiusMixin, colors, spacing } from '@basisboard/basis-ui/es/styles'
import styled from 'styled-components'

export const SelectionBox = styled.div`
  background: ${colors.white};
  overflow: hidden;
  box-shadow: 0px 12px 28px rgba(0, 0, 0, 0.1);
  ${borderRadiusMixin};

  li {
    margin-top: 0 !important;
    background-color: transparent !important;
    margin-bottom: ${spacing(0.5)};
  }

  .ant-select {
    min-height: 40px;
  }

  .ant-select-selection {
    min-height: 40px;
    border: none;
    padding: ${spacing(1)} 0 ${spacing(0.5)};
    background-color: ${colors.lightGray3};
  }

  .ant-select-selection__choice {
    border: none;
    height: 24px;
    display: flex;
    align-items: center;
    padding-left: 0;
    position: relative;
  }
`

export const UserWrapper = styled.div`
  display: flex;
  padding: ${spacing(1)} ${spacing(2)};
  cursor: pointer;

  &:hover {
    background-color: ${colors.lightGray3};
  }
`

export const UsersList = styled.ul`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;

  flex-wrap: wrap;
  list-style: none;

  padding: ${spacing(1)} 0;
`

export const UserEntry = styled.li`
  flex: 1;

  display: flex;
  align-items: center;

  margin: 0 ${spacing(1)} ${spacing(1)} 0;
`

export const Input = styled.input`
  border: none;
  outline: none;
  background: transparent;

  width: 100%;
`
