"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonVariantType = void 0;
var ButtonVariantType;
(function (ButtonVariantType) {
    ButtonVariantType["Default"] = "Default";
    ButtonVariantType["Primary"] = "Primary";
    ButtonVariantType["Danger"] = "Danger";
    ButtonVariantType["Transparent"] = "Transparent";
    ButtonVariantType["Active"] = "Active";
    ButtonVariantType["Gradient"] = "Gradient";
    ButtonVariantType["Text"] = "Text";
    ButtonVariantType["White"] = "White";
    ButtonVariantType["Cyan"] = "Cyan";
    ButtonVariantType["Success"] = "Success";
    ButtonVariantType["Secondary"] = "Secondary";
    ButtonVariantType["Warning"] = "Warning";
    ButtonVariantType["Custom"] = "Custom";
})(ButtonVariantType = exports.ButtonVariantType || (exports.ButtonVariantType = {}));
