"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Modal = void 0;
const React = __importStar(require("react"));
const ReactDOM = __importStar(require("react-dom"));
const colors_1 = require("../../styles/colors");
const constants_1 = require("../../styles/constants");
const focusTrapFactory_1 = require("../../utils/focusTrapFactory");
const monads_1 = require("../../utils/monads");
const Button_1 = require("../Button");
const styled_1 = require("./styled");
const getDescriptionId = (id) => `${id}__description`;
const MODAL_PRINT_ID = 'modal-print-id';
const BaseModal = ({ id, onDismiss, dialogLabel, description, closeLabel, children, width, height, minWidth, focused = true, backgroundColor, hideCloseButton = false, hideScroll = false, stylesOverrides = {}, }) => {
    const [isOpen, setIsOpen] = React.useState(true);
    const modalRef = React.useRef();
    const handleClose = React.useCallback(() => {
        setIsOpen(false);
    }, []);
    React.useEffect(() => {
        if (!focused) {
            return;
        }
        const onBeforePrint = () => {
            const styles = document.createElement('style');
            styles.id = MODAL_PRINT_ID;
            styles.innerHTML = /* css */ `
        @media print {
          #root {
            display: none;
          }
        }
      `;
            document.head.appendChild(styles);
        };
        const onAfterPrint = () => {
            const styles = document.getElementById(MODAL_PRINT_ID);
            document.head.removeChild(styles);
        };
        window.addEventListener('beforeprint', onBeforePrint);
        window.addEventListener('afterprint', onAfterPrint);
        const focusTrap = new focusTrapFactory_1.FocusTrapFactory(modalRef.current);
        const handleAnimationEnd = (e) => {
            if (e.animationName === styled_1.WrapperFadeOut.getName()) {
                focusTrap.destroy();
                onDismiss();
            }
        };
        const handleKeyPress = (ev) => {
            if (ev.key === 'Escape') {
                ev.stopPropagation();
                handleClose();
            }
        };
        const handleClick = (e) => {
            var _a;
            if (!modalRef.current.contains(e.target) &&
                !((_a = document.getElementById('modal-overlay')) === null || _a === void 0 ? void 0 : _a.contains(e.target)) &&
                !Array.from(document.querySelectorAll('.pac-container')).some(el => el.contains(e.target))) {
                handleClose();
            }
        };
        focusTrap.mount();
        modalRef.current.addEventListener('animationend', handleAnimationEnd);
        window.addEventListener('keydown', handleKeyPress);
        window.addEventListener('touchstart', handleClick);
        window.addEventListener('mousedown', handleClick);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
            window.removeEventListener('touchstart', handleClick);
            window.removeEventListener('mousedown', handleClick);
            window.removeEventListener('beforeprint', onBeforePrint);
            window.removeEventListener('afterprint', onAfterPrint);
        };
    }, [focused]);
    return (React.createElement(styled_1.Backdrop, { opening: isOpen },
        React.createElement(styled_1.Wrapper, { width: width, height: height, minWidth: minWidth, id: id, ref: modalRef, "aria-modal": "true", role: "dialog", "aria-label": dialogLabel, "aria-describedby": getDescriptionId(id), "aria-hidden": !isOpen, background: backgroundColor || colors_1.colors.white, overflow: hideScroll ? 'hidden' : 'auto', style: stylesOverrides },
            description && (React.createElement("span", { id: getDescriptionId(id), className: "visually-hidden" }, description)),
            hideCloseButton ? (React.createElement("button", { className: "visually-hidden", onClick: handleClose, "aria-label": closeLabel || 'Close modal' })) : (React.createElement(styled_1.ContentCloseBar, null,
                React.createElement(Button_1.Button.Default, { size: 24, "aria-label": closeLabel || 'Close modal', onClick: handleClose, postIcon: "Close" }))),
            children(handleClose))));
};
const Modal = props => {
    return process.env.NODE_ENV === 'test' ? (React.createElement(BaseModal, Object.assign({}, props))) : (ReactDOM.createPortal(React.createElement(BaseModal, Object.assign({}, props)), (0, monads_1.fromNullable)(document.getElementById(constants_1.MODAL_ID)).fold(() => {
        const container = document.createElement('div');
        container.setAttribute('id', constants_1.MODAL_ID);
        document.body.appendChild(container);
        return container;
    }, container => container)));
};
exports.Modal = Modal;
exports.Modal.Footer = styled_1.Footer;
