import { ComboBox } from '@basisboard/basis-ui/es/components/ComboBox'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { box } from '@basisboard/basis-ui/es/utils'
import moment, { Moment } from 'moment'
import times from 'ramda/src/times'
import * as React from 'react'

export interface TimePickerProps {
  onChange: (date: Moment) => void
  value?: Moment
  showOnTop?: boolean
}

const fillOptions = (current: Moment) =>
  times(
    index =>
      moment(current)
        .add(15 * index, 'minutes')
        .format('hh:mm A'),
    24 * 4,
  )

const roundToNearestQuarter = (time: Moment) =>
  box(time.get('minutes'))
    .map(minutes => Math.ceil(minutes / 15))
    .map(quarter => quarter * 15)
    .fold(minute => time.set({ minute, second: 0 }))

export const TimePicker: React.FC<TimePickerProps> = ({ onChange, showOnTop, value }) => {
  const [currentVal, setCurrentVal] = React.useState(value ? roundToNearestQuarter(value) : null)
  const [options, setOptions] = React.useState([])

  React.useEffect(() => {
    setCurrentVal(value)
    setOptions(fillOptions(roundToNearestQuarter(moment(value || undefined))))
  }, [value, setCurrentVal])

  const handleChange = React.useCallback(
    newVal => {
      const newTime = moment(newVal, 'hh:mm A') || moment()

      if (!newTime.isValid()) {
        return
      }

      const newMoment = (currentVal || moment()).set({
        hour: newTime.hour(),
        minute: newTime.minute(),
        second: 0,
      })

      setOptions(fillOptions(newMoment))
      onChange && onChange(newMoment)
    },
    [currentVal, onChange, setCurrentVal],
  )

  return (
    <Div width={1} position="relative">
      <ComboBox
        options={options.map(opt => ({
          label: opt,
          value: opt,
        }))}
        rev={undefined}
        renderOption={({ label }) => <Text>{label}</Text>}
        multiple={false}
        selectedOptions={
          currentVal
            ? [box(currentVal.format('hh:mm A')).fold(value => ({ label: value, value }))]
            : []
        }
        onSelectOption={opts => (opts[0] ? handleChange(opts[0].label) : null)}
        onBlur={handleChange}
        onFilter={(term, options) =>
          options.filter(({ label }) => label.startsWith(term) || label.startsWith(`0${term}`))
        }
        prefix={<Icon.Clock />}
        showOnTop={showOnTop}
      />
    </Div>
  )
}
