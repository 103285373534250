"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Lock = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Lock = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M12.5834 7.16659H11.9584V5.29159C11.9584 3.10929 10.1823 1.33325 8.00002 1.33325C5.81773 1.33325 4.04169 3.10929 4.04169 5.29159V7.16659H3.41669C2.72658 7.16659 2.16669 7.72648 2.16669 8.41659V13.4166C2.16669 14.1067 2.72658 14.6666 3.41669 14.6666H12.5834C13.2735 14.6666 13.8334 14.1067 13.8334 13.4166V8.41659C13.8334 7.72648 13.2735 7.16659 12.5834 7.16659ZM9.87502 7.16659H6.12502V5.29159C6.12502 4.25773 6.96617 3.41659 8.00002 3.41659C9.03387 3.41659 9.87502 4.25773 9.87502 5.29159V7.16659Z", fill: color })));
exports.Lock = Lock;
