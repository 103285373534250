import {
  borderRadiusMixin,
  colors,
  heightMixin,
  scrollBarMixin,
  spacing,
  transitionMixin,
} from '@basisboard/basis-ui/es/styles'
import { rem } from 'polished'
import styled, { css } from 'styled-components'
import { HEADER_HEIGHT_PX } from '../../styles'

export const ViewScreenViewEntry = styled.li`
  ${heightMixin('small')};
  ${borderRadiusMixin};
  ${transitionMixin};
  color: ${colors.blueGray900};
  font-size: ${rem(13)};
  padding: ${spacing(0.5)} ${spacing(1)};
  line-height: 24px;
  font-weight: 500;
  margin-bottom: ${spacing(1)};

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  svg,
  path {
    fill: ${colors.gray200};
  }

  &:hover,
  &.active {
    background-color: ${colors.blueGray};

    svg,
    path {
      fill: ${colors.gray800};
    }
  }
`

export const ViewScreenWrapper = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT_PX});
  overflow: hidden;

  background-color: ${colors.blueGray};

  display: grid;
  grid-template-rows: min-content 72px auto 32px;
  grid-template-columns: 48px min-content auto 48px;

  grid-template-areas:
    '. banner banner .'
    '. header header .'
    '. bar content .'
    '. . . .';

  @media print {
    grid-template-rows: 0px 0px auto 0px;
    grid-template-columns: 0px 0px auto 0px;

    overflow: visible;
  }
`

export const ViewScreenBanner = styled.div`
  grid-area: banner;

  margin-top: ${spacing(2)};
`

export const ViewScreenHeader = styled.div`
  width: 100%;
  margin: ${spacing(2)} auto;

  display: flex;
  align-items: center;

  grid-area: header;
`

export const ViewScreenAside = styled.aside<{ showSidebar: boolean }>`
  overflow-y: auto;
  overflow-x: hidden;

  ${({ showSidebar }) => css`
    width: ${showSidebar ? 284 : 0}px;
    opacity: ${showSidebar ? 1 : 0};
    padding: ${spacing(showSidebar ? 0.5 : 0)};
    margin: ${spacing(showSidebar ? -0.5 : 0)};
    margin-right: ${spacing(showSidebar ? 2 : 0)};
  `}

  ${scrollBarMixin};
  ${transitionMixin};

  grid-area: bar;
`

export const ViewScreenContent = styled.main`
  grid-area: content;
  overflow-y: hidden;

  margin: ${spacing(-0.5)};
  padding: ${spacing(0.5)};

  height: calc(100% + 8px);

  @media print {
    overflow: visible;
  }
`
