"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RfiDeadline = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const RfiDeadline = ({ width = 16, height = 16, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M14.5301 4.17576C14.472 3.94151 14.1789 3.86214 14.0082 4.03282L12.0793 5.96175L10.3185 5.66836L10.0251 3.90753L11.954 1.97861C12.1257 1.80688 12.043 1.51428 11.8072 1.45565C10.5781 1.15111 9.22481 1.47926 8.26424 2.43957C7.23596 3.46784 6.95295 4.95838 7.38097 6.25799L1.84556 11.7934C1.19731 12.4416 1.19731 13.4927 1.84556 14.141C2.49381 14.7892 3.54491 14.7892 4.19316 14.141L9.7239 8.61026C11.024 9.04372 12.5117 8.7576 13.5467 7.72258C14.5083 6.76097 14.836 5.40559 14.5301 4.17576ZM3.01962 13.5895C2.67591 13.5895 2.39705 13.3106 2.39705 12.9669C2.39705 12.623 2.67591 12.3444 3.01962 12.3444C3.36333 12.3444 3.64219 12.623 3.64219 12.9669C3.64219 13.3106 3.36333 13.5895 3.01962 13.5895Z", fill: color })));
exports.RfiDeadline = RfiDeadline;
