import { Drawer } from '@basisboard/basis-ui/es/components/Drawer'
import { Container } from '@containrz/react-hook'
import { hideIntercom, showIntercom } from '../../services'

export interface DrawerProps {
  id: string
  onClose: () => void
  title?: string
  content: React.ReactNode
  width?: number
  noPadding?: boolean
}

interface State {
  drawers: DrawerProps[]
}

const initialState = {
  drawers: [] as State['drawers'],
}

class DrawerContainer extends Container<State> {
  state = initialState as State

  openDrawer = (drawerData: DrawerProps) => {
    this.setState(s => ({ drawers: [...s.drawers, drawerData] }))

    Drawer.setOpen(drawerData.id)

    hideIntercom()
  }

  closeDrawer = () => {
    const drawers = [...this.state.drawers]
    const drawer = drawers.pop()

    drawer?.onClose?.()

    this.setState({
      drawers,
    })

    if (!drawers.length) {
      showIntercom()
    }
  }
}

export const drawer = new DrawerContainer()

export const { openDrawer } = drawer
