"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Plus = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Plus = ({ width = 16, height = 16, size, color = styles_1.colors.white }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M13.5 7H9V2.5C9 2.22375 8.77625 2 8.5 2H7.5C7.22375 2 7 2.22375 7 2.5V7H2.5C2.22375 7 2 7.22375 2 7.5V8.5C2 8.77625 2.22375 9 2.5 9H7V13.5C7 13.7762 7.22375 14 7.5 14H8.5C8.77625 14 9 13.7762 9 13.5V9H13.5C13.7762 9 14 8.77625 14 8.5V7.5C14 7.22375 13.7762 7 13.5 7Z", fill: color })));
exports.Plus = Plus;
