"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Delete = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Delete = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M2.1665 3.52148V2.79232C2.1665 2.44596 2.44515 2.16732 2.7915 2.16732H5.70817L5.95296 1.68034C6.05713 1.4668 6.27327 1.33398 6.51025 1.33398H9.48682C9.72379 1.33398 9.93994 1.4668 10.0467 1.68034L10.2915 2.16732H13.2082C13.5545 2.16732 13.8332 2.44596 13.8332 2.79232V3.52148C13.8332 3.69336 13.6925 3.83398 13.5207 3.83398H2.479C2.30713 3.83398 2.1665 3.69336 2.1665 3.52148ZM12.979 4.99805L12.4478 13.4954C12.4061 14.1543 11.8592 14.6673 11.2004 14.6673H4.79932C4.14046 14.6673 3.59359 14.1543 3.55192 13.4954L3.02067 4.99805C3.01025 4.81836 3.15348 4.66732 3.33317 4.66732H12.6691C12.8462 4.66732 12.9894 4.81836 12.979 4.99805Z", fill: color })));
exports.Delete = Delete;
