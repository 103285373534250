import { colors, scrollBarMixin, spacing } from '@basisboard/basis-ui/es/styles'
import styled from 'styled-components'
import { height, HeightProps } from 'styled-system'

export const Wrapper = styled.div<HeightProps>`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 100px;
  overflow: auto;

  ${height};

  ${scrollBarMixin};
`

export const ValueList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;

  padding: 0;
  margin: 0;

  & > li:not(:last-child) {
    margin-right: ${spacing(1)};
  }
`

export const OptionsList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;

  padding: 0 ${spacing(1)};
  margin: 0;

  & > li {
    width: 100%;
  }

  & > li:not(:last-child) {
    margin-bottom: ${spacing(1)};
  }
`

export const InputWrapper = styled.div`
  background: ${colors.lightGray3};
  height: 40px;
  margin-bottom: ${spacing(2)};
  margin-top: ${spacing(-2)};
  margin-left: ${spacing(-2)};
  padding: 0 ${spacing(2)};
  display: flex;
  align-items: center;
  width: ${`calc(100% + ${spacing(4)})`};
  position: sticky;
  top: ${spacing(-2)};
  z-index: 1;
`

export const Input = styled.input`
  border: none;
  outline: none;
  background: transparent;

  width: 100%;
`
