import {
  GetQuoteTradesResponseBody,
  PatchQuoteTradeRequestBody,
  PostQuoteTradeRequestBody,
  PostQuoteTradeResponseBody,
  TRADE_ID,
  USER_ID,
} from '@basisboard/basis-common/lib/api'
import { del, get, post, put } from '../../api'

export const getTrades = () =>
  get<GetQuoteTradesResponseBody>('/quote-trades').then(response => response.data.quoteTrades)

export const postTrade = (data: PostQuoteTradeRequestBody) =>
  post<PostQuoteTradeResponseBody>('/quote-trades', data).then(response => response.data.quoteTrade)

export const putTrade = (
  tradeId: TRADE_ID,
  data: { name?: string; defaultEstimatorId?: USER_ID },
) => put(`/quote-trades/${tradeId}`, data)

export const deleteTrade = (tradeId: TRADE_ID) => del(`/quote-trades/${tradeId}`)

export const updateTrade = (tradeId: TRADE_ID, data: PatchQuoteTradeRequestBody) =>
  put<PostQuoteTradeResponseBody>(`/quote-trades/${tradeId}`, data)
