import { CustomFieldEntity } from '@basisboard/basis-common/lib/api/custom-fields'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { spacing } from '@basisboard/basis-ui/es/styles'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import { ViewId } from '../../../../constants'
import { FilterType } from '../../constants'
import { FiltersStateInstances } from '../../container'
import { openFiltersDrawer } from '../../drawers'

interface Props {
  viewId: ViewId
  onUpdate: () => void
  enabledFilters: FilterType[]
  renderCustomFilter?: () => React.ReactNode
  enabledEntities?: CustomFieldEntity[]
}

export const FilterButton: React.FC<Props> = ({
  viewId,
  onUpdate,
  enabledFilters,
  renderCustomFilter,
  enabledEntities,
}) => {
  const filtersData = useContainer(FiltersStateInstances(viewId))

  const { filter } = filtersData.state

  const isActive = Object.keys(filter || {}).length > 0

  return (
    <>
      <Div display="flex">
        <Button.Text
          active={isActive}
          px={spacing(1)}
          onClick={() =>
            openFiltersDrawer({
              viewId,
              onUpdate,
              enabledFilters,
              enabledEntities,
              renderCustomFilter,
              filtersData,
            })
          }
          postIcon="Funnel"
        >
          Filters
        </Button.Text>
      </Div>
    </>
  )
}
