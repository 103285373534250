import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Row } from '@basisboard/basis-ui/es/components/Row'
import { EllipsisOverflow } from '@basisboard/basis-ui/es/components/Typography'
import { borderRadiusMixin, colors, scrollBarMixin, spacing } from '@basisboard/basis-ui/es/styles'
import { rem } from 'polished'
import styled, { css } from 'styled-components'
import {
  background,
  BackgroundProps,
  color,
  ColorProps,
  justifyContent,
  JustifyContentProps,
} from 'styled-system'

export const ActionsWrapper = styled(Row)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;

  padding: 0 ${spacing(1)};
`

export const Wrapper = styled.div<JustifyContentProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  overflow: auto;

  ${scrollBarMixin};

  height: 100%;
  width: 100%;

  ${justifyContent};

  .default-hoverable {
    opacity: 0;
  }

  .hoverable {
    display: none;
  }

  &:hover {
    .default-hoverable {
      opacity: 1;
    }

    .hoverable {
      display: initial;
    }

    ${ActionsWrapper} {
      background-color: ${colors.blueGray} !important;
    }
  }
`

export const CellArea = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
`

export const CustomFieldSelectOption = styled.button<
  { active?: boolean; isSelectItem?: boolean } & ColorProps & BackgroundProps
>`
  height: 24px;
  padding: 0 ${spacing(1)};

  line-height: 24px;
  font-size: ${rem(14)};
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  color: ${colors.darkBlue};
  flex-shrink: 0;

  ${({ active }) => active && 'opacity: 0.5'};
  ${({ isSelectItem }) =>
    isSelectItem
      ? css`
          align-self: center;
          text-align: left;
          width: 100%;
        `
      : css`
          padding-left: ${spacing(1)};
          margin-right: ${spacing(0.5)};
        `};

  :focus {
    outline: none !important;
  }

  ${EllipsisOverflow};

  max-width: 100%;

  ${color};
  ${background};
  ${borderRadiusMixin};
`

export const StyledButton = styled.button``

export const StyledSpan = styled.span`
  margin-right: ${spacing(0.5)};
`

export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  text-transform: none;

  overflow: auto;
  ${scrollBarMixin};
`

export const ActionsButton = styled(Button.Transparent as any)`
  height: 36px;
  width: 36px;
  padding: 0;
  margin: 0 ${spacing(0.25)};

  span {
    font-size: ${rem(12)};
    color: ${colors.blueGray400};
    margin-right: ${spacing(0.25)};
  }

  svg,
  path {
    fill: ${colors.blueGray400};
  }

  &:hover {
    background: ${colors.blueGray200};

    svg,
    path {
      fill: ${colors.blueGray600};
    }

    span {
      color: ${colors.blueGray600};
    }
  }

  &.not-hoverable {
    td:not(:hover) & {
      background: ${colors.white};
    }
  }
`
