"use strict";
// https://github.com/focus-trap/focus-trap
// useful for modals, drawers, dialogs, etc.
Object.defineProperty(exports, "__esModule", { value: true });
exports.FocusTrapFactory = void 0;
const focus_trap_1 = require("focus-trap");
const constants_1 = require("../styles/constants");
class FocusTrapFactory {
    constructor(container) {
        this.mount = () => {
            const firstFocusableEl = this.container.querySelector(constants_1.focussableElements);
            this.focusTrap = (0, focus_trap_1.createFocusTrap)(this.container, {
                preventScroll: true,
                fallbackFocus: firstFocusableEl,
            });
            this.focusTrap.activate();
        };
        this.destroy = () => this.focusTrap.deactivate();
        this.container = container;
    }
}
exports.FocusTrapFactory = FocusTrapFactory;
