import {
  GetNotificationsRequestQuery,
  GetNotificationsResponse,
  PatchNotificationsResponse,
} from '@basisboard/basis-common/lib/api'
import { stringify } from 'qs'
import { get, patch } from '../../api'

export const getNotificationsCounter = () =>
  get('/notifications/new').then(response => response.data?.count || 0)

export const getNotifications = (data?: GetNotificationsRequestQuery) =>
  get<GetNotificationsResponse>(`/notifications?${stringify(data)}`).then(
    response => response.data.notifications,
  )

export const markNotificationAsRead = (notificationId: string, read = true) =>
  patch<PatchNotificationsResponse>(`/notifications/${notificationId}`, { read })

export const markAllAsRead = () => patch('/notifications/all-read')
