"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tooltip = exports.Tip = void 0;
const React = __importStar(require("react"));
const useHover_1 = require("../../hooks/useHover");
const SafeWrapper_1 = require("../SafeWrapper");
const styled_1 = require("./styled");
Object.defineProperty(exports, "Tip", { enumerable: true, get: function () { return styled_1.Tip; } });
const Tooltip = (_a) => {
    var { children, content, hideDelay = 0, align = 'center', defaultPosition = 'bottom', wrapProps, justify, onHover } = _a, rest = __rest(_a, ["children", "content", "hideDelay", "align", "defaultPosition", "wrapProps", "justify", "onHover"]);
    const { hovering, onMouseEnter, onMouseLeave } = (0, useHover_1.useHover)();
    const [show, setShow] = React.useState(false);
    const targetRef = React.useRef();
    const handleHover = () => {
        onHover && onHover();
        onMouseEnter();
    };
    React.useEffect(() => {
        if (!hovering && hideDelay > 0) {
            const timeout = window.setTimeout(() => {
                setShow(hovering);
            }, hideDelay);
            return () => {
                clearTimeout(timeout);
            };
        }
        else {
            setShow(hovering);
        }
    }, [hovering]);
    return (React.createElement(React.Fragment, null,
        React.createElement(styled_1.TooltipWrap, Object.assign({ "data-testid": "tooltip", ref: targetRef, onMouseLeave: onMouseLeave }, wrapProps, { onMouseEnter: handleHover }), children),
        content && (React.createElement(SafeWrapper_1.SafeWrapper, { target: targetRef.current, show: show, align: align, defaultPosition: defaultPosition, justify: justify, preventFocusTrap: true, preventScrollAdjustment: true },
            React.createElement(styled_1.Tip, Object.assign({ onClick: e => e.stopPropagation(), onMouseLeave: onMouseLeave, onMouseEnter: onMouseEnter }, rest), content)))));
};
exports.Tooltip = Tooltip;
