import { colors } from '@basisboard/basis-ui/es/styles'
import { isDarkColor } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import { StagesState } from '../../../../../Stages/container'
import { TableFieldProps } from '../../type'
import { SingleSelect } from '../SingleSelect'

export const StageSelect: React.FC<TableFieldProps> = props => {
  const stages = useContainer(StagesState).state.stages
  const options = React.useMemo(
    () =>
      stages.map(stage => ({
        value: stage.id,
        label: stage.name,
        color: isDarkColor(stage.color) ? colors.white : colors.darkBlue,
        backgroundColor: stage.color,
      })),
    [stages],
  )

  return (
    <SingleSelect {...(props as any)} field={{ ...props.field, options }}>
      {!props.value && 'Not set'}
    </SingleSelect>
  )
}
