import { Card } from '@basisboard/basis-ui/es/components/Card'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { LoadingIndicator } from '@basisboard/basis-ui/es/components/LoadingIndicator'
import { spacing } from '@basisboard/basis-ui/es/styles'
import equals from 'ramda/src/equals'
import * as React from 'react'
import { Label } from '../Label'
import {
  HiddenSelect,
  Input,
  List,
  ListEntry,
  SelectedList,
  SelectedListEntry,
  Wrapper,
} from './styled'

export type SelectOption = {
  value: any
  label?: string
  id?: string
}

export interface BaseSelectProps extends Omit<React.HTMLProps<HTMLSelectElement>, 'onBlur'> {
  label?: string
  selectedOptions: SelectOption[]
  options: SelectOption[]
  renderOption: (option: SelectOption) => React.ReactNode
  loading?: boolean
  onSelectOption?: (options: SelectOption[]) => void
  onFilter?: (term: string, option: SelectOption) => boolean
  onBlur?: (term: string) => void
  suffix?: React.ReactNode
  showOnTop?: boolean
}

export const BaseSelect = React.forwardRef<HTMLSelectElement, BaseSelectProps>(
  (
    {
      label,
      options,
      renderOption,
      onSelectOption,
      selectedOptions,
      loading,
      multiple,
      onFilter,
      onBlur,
      suffix,
      showOnTop,
      ...props
    },
    ref,
  ) => {
    const [focused, setFocused] = React.useState(false)
    const inputRef = React.useRef<HTMLInputElement>()
    const timeout = React.useRef<number>()
    const buttonRef = React.useRef<HTMLDivElement>()
    const [searchTerm, setSearchTerm] = React.useState('')
    const [selectedIndex, setSelectedIndex] = React.useState(-1)

    const [availableOptions, setAvailableOptions] = React.useState(
      onFilter ? options.filter(o => onFilter(searchTerm, o)) : options,
    )

    React.useEffect(() => {
      setAvailableOptions(onFilter ? options.filter(o => onFilter(searchTerm, o)) : options)
    }, [searchTerm, selectedOptions.length, onFilter, options])

    const handleChangeSelected = React.useCallback(
      (delta: 1 | -1) => {
        if (delta + selectedIndex >= availableOptions.length || delta + selectedIndex < -1) {
          return
        }

        setSelectedIndex(s => s + delta)
      },
      [selectedIndex, setSelectedIndex, availableOptions],
    )

    const handleClose = () => {
      setFocused(false)
      setSearchTerm('')
      buttonRef.current?.focus()
    }

    const handleOpen = () => {
      setSelectedIndex(0)
      setFocused(true)
      setSearchTerm('')
      inputRef.current?.focus()
    }

    const selectOption = React.useCallback(
      (option: SelectOption) => {
        inputRef.current?.focus()
        setSelectedIndex(-1)

        setSearchTerm('')
        handleClose()

        if ((selectedOptions || []).some(v => equals(v.value, option?.value))) {
          return
        }

        onSelectOption(multiple ? [...selectedOptions, option] : [option])
      },
      [JSON.stringify(selectedOptions), multiple, onSelectOption],
    )

    const removeOption = React.useCallback(
      (option: SelectOption) => {
        onSelectOption(selectedOptions.filter(opt => !equals(opt, option)))
        inputRef.current?.focus()
      },
      [selectedOptions.length, onSelectOption, selectedOptions],
    )

    const removeLastOption = () => {
      const opts = [...selectedOptions]
      opts.pop()

      onSelectOption(opts || [])
    }

    const handleFocus = () => {
      setFocused(true)

      timeout.current && clearTimeout(timeout.current)
    }

    const handleBlur = () => {
      onBlur && onBlur(searchTerm)
      timeout.current = window.setTimeout(handleClose, 100)
    }

    React.useEffect(() => {
      if (!focused) {
        return
      }

      const listenKeyUp = e => {
        switch (e.code) {
          case 'Escape':
            return handleClose()

          case 'ArrowDown':
            return handleChangeSelected(1)

          case 'ArrowUp':
            return handleChangeSelected(-1)

          case 'Backspace':
            return inputRef.current
              ? !inputRef.current.value && removeLastOption()
              : removeLastOption()

          case 'Enter':
            e.preventDefault()
            return selectedIndex >= 0 && selectOption(availableOptions[selectedIndex])
        }
      }

      window.addEventListener('keyup', listenKeyUp)

      return () => {
        window.removeEventListener('keyup', listenKeyUp)
      }
    }, [
      searchTerm,
      focused,
      selectedIndex,
      selectOption,
      availableOptions.length,
      removeLastOption,
    ])

    const isOpen = (focused || searchTerm.length >= 2) && availableOptions.length > 0

    return (
      <>
        {label && <Label label={label} required={props.required} />}
        <HiddenSelect multiple={multiple} ref={ref} {...(props as any)}>
          {options.map(o => (
            <option
              key={o.label}
              value={o.value}
              selected={selectedOptions.some(v => v.value === o.value)}
            >
              {o.label}
            </option>
          ))}
        </HiddenSelect>
        <Wrapper onClick={handleOpen} ref={buttonRef} focused={focused}>
          <SelectedList>
            {multiple ? (
              selectedOptions.map(o => (
                <SelectedListEntry key={o.label}>
                  {renderOption(o)}

                  <button
                    type="button"
                    onClick={e => {
                      e.stopPropagation()
                      removeOption(o)
                    }}
                  >
                    <Icon.Close size={12} />
                  </button>
                </SelectedListEntry>
              ))
            ) : searchTerm ? null : (
              <SelectedListEntry key={selectedOptions[0]?.label}>
                {selectedOptions[0] && renderOption(selectedOptions[0])}
              </SelectedListEntry>
            )}
            <Div as="li" width={90}>
              <Input
                ref={inputRef}
                onChange={e => setSearchTerm(e.target.value)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={searchTerm}
                onKeyUp={e => {
                  e.stopPropagation()
                  if (e.key === 'Enter') {
                    handleBlur()
                  }
                }}
              />
            </Div>
          </SelectedList>

          {isOpen && (
            <>
              <Card
                zIndex={100000}
                py={spacing(1)}
                position="absolute"
                top={showOnTop ? undefined : 'calc(100% + 4px)'}
                bottom={showOnTop ? 'calc(100% + 4px)' : undefined}
                left={-1}
                width="calc(100% + 2px)"
                maxHeight={150}
                minHeight={48}
                overflow="auto"
              >
                {loading ? (
                  <LoadingIndicator />
                ) : (
                  <List>
                    {availableOptions.map((o, index) => (
                      <ListEntry
                        onMouseDown={e => e.preventDefault()}
                        onClick={e => {
                          selectOption(o)
                          e.stopPropagation()
                        }}
                        key={o.label}
                        selected={selectedIndex === index}
                      >
                        {renderOption(o)}
                      </ListEntry>
                    ))}
                  </List>
                )}
              </Card>
            </>
          )}

          {suffix && (
            <Div
              right="16px"
              top="50%"
              size={16}
              style={{ transform: 'translateY(-50%)' }}
              position="absolute"
            >
              {suffix}
            </Div>
          )}
        </Wrapper>
      </>
    )
  },
)
