import { HeavyText } from '@basisboard/basis-ui/es/components/Typography'
import { colors, cssCircle, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import { box } from '@basisboard/basis-ui/es/utils'
import * as React from 'react'
import styled from 'styled-components'
import { color } from 'styled-system'

const Input = styled.input.attrs({
  type: 'checkbox',
})`
  opacity: 0;
  position: absolute;
`

const Label = styled(HeavyText)`
  display: flex;
  align-items: center;
  color: ${colors.gray};

  ${color};
`

const Box = styled.span<{
  active: boolean
  activeColor: string
  inactiveColor: string
  disabled?: boolean
}>`
  width: 20px;
  height: 14px;
  border-radius: 14px;
  background-color: ${({ active, activeColor, inactiveColor, disabled }) =>
    box(active ? activeColor : inactiveColor).fold(color => (disabled ? `${color}77` : color))};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${transitionMixin};

  &::after {
    content: '';
    ${cssCircle(10)};
    position: absolute;
    background-color: ${colors.white};
    top: 2px;

    ${transitionMixin};

    left: ${({ active }) => (active ? 'calc(100% - 12px)' : '2px')};
  }
`

export interface ToggleInputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'value'> {
  label?: string
  color?: string
  name?: string
  initialValue?: boolean
  value?: boolean
  activeColor?: string
  inactiveColor?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Toggle = React.forwardRef<HTMLInputElement, ToggleInputProps>(
  (
    {
      label,
      initialValue,
      color,
      value,
      activeColor = colors.success,
      inactiveColor = colors.lightGray,
      ...props
    },
    ref,
  ) => {
    const [checked, toggleChecked] = React.useState(initialValue)

    React.useEffect(() => {
      toggleChecked(value)
    }, [value])

    return (
      <Label as="label">
        <Input
          ref={ref}
          {...(props as any)}
          onChange={e => {
            toggleChecked(e.target.checked)
            props.onChange && props.onChange(e)
          }}
          checked={checked}
          id={props.name}
        />
        <Box
          disabled={props.disabled}
          active={checked}
          activeColor={activeColor}
          inactiveColor={inactiveColor}
        />
        {label && (
          <Label as="label" color={color} ml={spacing(1)} htmlFor={props.name}>
            {label}
          </Label>
        )}
      </Label>
    )
  },
)
