import * as projectApi from './api'
import AddBidButton from './components/AddBidButton'
import { DropdownMenu } from './components/DropdownMenu'
import { ListEntry } from './components/ListEntry'
import { ProjectInput } from './components/ProjectInput'

export * from './container'
export * from './modals'
export * from './toasts'
export { projectApi }

/**
 * The components to be used
 */
export const Projects = {
  ListEntry,
  AddBidButton,
  DropdownMenu,
  Input: ProjectInput,
}
