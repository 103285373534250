import { retryLazyLoad } from '@basisboard/basis-ui/es/utils'
import { openModal } from '../../Modal'
import { AddBidProps } from './AddBid/types'
import { AddCompetitorModalProps } from './AddCompetitor/types'
import { DuplicateProjectModalProps } from './DuplicateProject/types'
import { LinkBidsProps } from './LinkBids/types'
import { ManageCompanyInBidProps } from './ManageCompanyInBid/types'
import { SplitBidsProps } from './SplitBids/types'

export const openAddBidModal = (props: AddBidProps) =>
  openModal({
    Component: retryLazyLoad(() => import('./AddBid')),
    width: 576,
    name: 'AddBid',
    props,
  })

export const openLinkBidsModal = (props: LinkBidsProps) =>
  openModal({
    Component: retryLazyLoad(() => import('./LinkBids')),
    width: 576,
    name: 'LinkBids',
    props,
  })

export const openManageCompanyInBidModal = (props: ManageCompanyInBidProps) =>
  openModal({
    Component: retryLazyLoad(() => import('./ManageCompanyInBid')),
    width: 576,
    name: 'ManageCompanyInBid',
    props,
  })

export const openSplitBidsModal = (props: SplitBidsProps) =>
  openModal({
    Component: retryLazyLoad(() => import('./SplitBids')),
    width: 576,
    name: 'SplitBids',
    props,
  })

export const openDuplicateProjectModal = (props: DuplicateProjectModalProps) =>
  openModal({
    Component: retryLazyLoad(() => import('./DuplicateProject')),
    width: 576,
    name: 'DuplicateProject',
    props,
  })

export const openAddCompetitorModal = (props: AddCompetitorModalProps) =>
  openModal({
    Component: retryLazyLoad(() => import('./AddCompetitor')),
    width: 576,
    name: 'AddCompetitor',
    props,
  })
