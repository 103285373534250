import { Div } from '@basisboard/basis-ui/es/components/Div'
import { ToastInterface } from '@basisboard/basis-ui/es/components/Toast'
import { useContainer } from '@containrz/react-hook'
import keys from 'ramda/src/keys'
import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { Toast } from '../../../../components'
import { AppState } from '../../../App/container'
import { ToastContainer } from '../../container'

export const ToastSystem: React.FC = () => {
  const toastData = useContainer(ToastContainer)
  const appData = useContainer(AppState, { watchKeys: ['showAdminBanner'] })
  const location = useLocation()
  const [overrideIndexZ, setOverrideIndexZ] = React.useState(false)

  React.useEffect(() => keys(toastData.state.toasts).forEach(key => toastData.hideToast(key)), [
    location.pathname,
    toastData,
  ])

  React.useEffect(() => {
    const needsIndexZOverride = Object.keys(toastData.state.toasts).some(
      key => !!toastData.state?.toasts?.[key]?.overrideIndexZ,
    )

    if (needsIndexZOverride) {
      setOverrideIndexZ(true)
    } else if (overrideIndexZ) {
      setOverrideIndexZ(false)
    }
  }, [toastData.state.toasts])

  return (
    <Div
      position="fixed"
      right={16}
      top={appData.state.showAdminBanner ? 112 : 72}
      width={720}
      zIndex={overrideIndexZ ? 100000 : undefined}
    >
      {keys(toastData.state.toasts).map(key => {
        const toast = toastData.state.toasts[key] as ToastInterface
        return (
          <Toast
            key={key}
            onHide={() => toastData.hideToast(key)}
            onResetTimer={() => toastData.resetTimer(key)}
            onStopTimer={() => toastData.stopTimer(key)}
            size="medium"
            {...toast}
          />
        )
      })}
    </Div>
  )
}
