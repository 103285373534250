import { Row } from '@basisboard/basis-ui/es/components/Row'
import { borderRadiusMixin, colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import { rem } from 'polished'
import styled from 'styled-components'

export const Wrapper = styled.div<{ isShowing: boolean }>`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;

  width: 100%;
  height: ${({ isShowing }) => (isShowing ? 56 : 0)}px;
  ${({ isShowing }) => isShowing && 'z-index: 100'};
  background-color: ${colors.blueGray800};

  ${transitionMixin('slow')};

  padding-left: ${spacing(6)};
  padding-right: ${spacing(1)};
`

export const LinksWrapper = styled.ul`
  display: flex;
  align-items: center;
  margin-left: ${spacing(3.5)};

  li {
    margin-right: ${spacing(3)};
    a {
      display: flex;
      align-items: center;
      color: ${colors.blueGray400};
      font-size: ${rem(16)};

      svg {
        margin-left: ${spacing(1)};
      }
    }
  }
`

export const Badge = styled(Row)`
  padding: 0 ${spacing(1)};
  background-color: ${colors.accent};
  color: ${colors.white};
  font-size: ${rem(12)};
  ${borderRadiusMixin};
  margin-left: ${spacing(1)};
  height: 20px;

  white-space: nowrap;

  ${transitionMixin};
`
