import {
  EmailStatus,
  FilterIdenfier,
  FilterOperation,
  InboxSortOptions,
  USER_ID,
} from '@basisboard/basis-common/lib/api'
import { CustomFieldEntity } from '@basisboard/basis-common/lib/api/custom-fields'
import { SortDirection } from '@basisboard/basis-common/lib/enums'
import values from 'ramda/src/values'
import { ConditionEntity } from '../../containers/Filters/components/ConditionEntry/types'
import { InboxBulkActions, InboxFieldType } from '../../containers/Inbox/types'
import { Field, View } from '../../templates/ViewsScreen'
import { InboxViewSettings } from './types'

export const fields: { [key: string]: Field } = ([
  {
    id: InboxFieldType.Subject,
    label: 'Email Subject',
    width: 400,
    sortKey: InboxSortOptions.Subject,
  },
  {
    id: InboxFieldType.FromEmail,
    label: 'From',
    width: 280,
    sortKey: InboxSortOptions.FromEmail,
  },
  {
    id: InboxFieldType.CreatedAt,
    label: 'Time Received',
    width: 200,
    sortKey: InboxSortOptions.TimeReceived,
  },
  {
    id: InboxFieldType.CommunicationType,
    label: 'Communication Type',
    width: 200,
    sortKey: InboxSortOptions.CommunicationType,
  },
  {
    id: InboxFieldType.PlatformId,
    label: 'Platform',
    width: 200,
    sortKey: InboxSortOptions.Platform,
  },
  {
    id: InboxFieldType.BidDeadlineAt,
    label: 'Bid Deadline',
    width: 200,
    sortKey: InboxSortOptions.BidDeadline,
  },
  {
    id: InboxFieldType.ProjectLocation,
    label: 'Project Location',
    width: 280,
    sortKey: InboxSortOptions.ProjectLocation,
  },
  {
    id: InboxFieldType.Company,
    label: 'Company',
    width: 280,
    sortKey: InboxSortOptions.Company,
  },
  {
    id: InboxFieldType.ProjectRegion,
    label: 'Project State',
    width: 280,
    sortKey: InboxSortOptions.ProjectRegion,
  },
  {
    id: InboxFieldType.ProjectCity,
    label: 'City',
    width: 280,
    sortKey: InboxSortOptions.ProjectCity,
  },
  {
    id: InboxFieldType.ProjectAddressLine,
    label: 'Address Line',
    width: 280,
    sortKey: InboxSortOptions.ProjectAddressLine,
  },
  {
    id: InboxFieldType.Union,
    label: 'Union/Non Union',
    width: 280,
    sortKey: InboxSortOptions.UnionType,
  },
  {
    id: InboxFieldType.ProjectName,
    label: 'Project Name',
    width: 280,
    sortKey: InboxSortOptions.ProjectName,
  },
  {
    id: InboxFieldType.Distance,
    label: 'Distance',
    width: 200,
    sortKey: InboxSortOptions.Distance,
  },
  {
    id: InboxFieldType.TravelTime,
    label: 'Travel Time',
    width: 200,
    sortKey: InboxSortOptions.TravelTime,
  },
] as Field[]).reduce((acc, field) => ({ ...acc, [field.id]: field }), {})

const fieldsSetWithEnabledFields = (enabledFields: InboxFieldType[]): Field[] => [
  ...enabledFields.map(f => ({ ...fields[f], enabled: true })),
  ...values(fields)
    .filter(f => !enabledFields.includes(f.id as InboxFieldType))
    .map(f => ({ ...f, enabled: false })),
]

export const DEFAULT_VIEWS = (profileId: USER_ID): View<InboxViewSettings>[] => [
  {
    id: 'all-emails',
    name: 'All Emails',
    viewGroup: 'Inbox Views',
    filter: {
      status: EmailStatus.Unlinked,
    },
    fields: fieldsSetWithEnabledFields([
      InboxFieldType.Subject,
      InboxFieldType.BidDeadlineAt,
      InboxFieldType.Company,
      InboxFieldType.ProjectLocation,
      InboxFieldType.PlatformId,
      InboxFieldType.CommunicationType,
      InboxFieldType.CreatedAt,
      InboxFieldType.ProjectAddressLine,
      InboxFieldType.ProjectCity,
      InboxFieldType.TravelTime,
      InboxFieldType.Distance,
    ]),
    displayTotalsInViewsList: true,
    settings: {
      actions: [
        InboxBulkActions.Trash,
        InboxBulkActions.Add,
        InboxBulkActions.Decline,
        InboxBulkActions.Group,
      ],
      hasCommunicationTypeFilter: true,
    },
  },

  {
    id: 'new-projects',
    name: 'New Projects',
    viewGroup: 'Inbox Views',
    filter: {
      status: EmailStatus.Unlinked,
      customFilter: {
        and: [
          {
            not: false,
            filterIdentitier: {
              entity: 'project',
              identifier: FilterIdenfier.ProjectCreatedAt,
            },
            operation: FilterOperation.Empty,
          },
        ],
      },
    },
    sorts: [
      {
        name: InboxSortOptions.BidDeadline,
        direction: SortDirection.ASC,
      },
    ],
    fields: fieldsSetWithEnabledFields([
      InboxFieldType.ProjectName,
      InboxFieldType.BidDeadlineAt,
      InboxFieldType.Company,
      InboxFieldType.ProjectLocation,
      InboxFieldType.PlatformId,
      InboxFieldType.CreatedAt,
      InboxFieldType.ProjectAddressLine,
      InboxFieldType.ProjectCity,
      InboxFieldType.TravelTime,
      InboxFieldType.Distance,
    ]),
    displayTotalsInViewsList: true,
    settings: {
      actions: [
        InboxBulkActions.Trash,
        InboxBulkActions.Add,
        InboxBulkActions.Decline,
        InboxBulkActions.Group,
      ],
    },
  },

  {
    id: 'existing-projects',
    name: 'Existing Projects',
    viewGroup: 'Inbox Views',
    filter: {
      status: EmailStatus.Unlinked,
      customFilter: {
        and: [
          {
            not: true,
            filterIdentitier: {
              entity: 'project',
              identifier: FilterIdenfier.ProjectCreatedAt,
            },
            operation: FilterOperation.Empty,
          },
        ],
      },
    },
    fields: fieldsSetWithEnabledFields([
      InboxFieldType.Subject,
      InboxFieldType.ProjectName,
      InboxFieldType.Company,
      InboxFieldType.FromEmail,
      InboxFieldType.CommunicationType,
      InboxFieldType.CreatedAt,
      InboxFieldType.ProjectAddressLine,
      InboxFieldType.ProjectCity,
      InboxFieldType.TravelTime,
      InboxFieldType.Distance,
    ]),
    displayTotalsInViewsList: true,
    settings: {
      actions: [
        InboxBulkActions.Trash,
        InboxBulkActions.Add,
        InboxBulkActions.Decline,
        InboxBulkActions.Group,
      ],
    },
  },

  {
    id: 'my-projects',
    name: 'My Projects',
    viewGroup: 'Inbox Views',
    filter: {
      status: EmailStatus.Unlinked,
      customFilter: {
        and: [
          {
            not: false,
            filterIdentitier: {
              entity: CustomFieldEntity.Project,
              identifier: FilterIdenfier.EstimatorId,
            },
            operation: FilterOperation.IsAnyOf,
            compareValue: [profileId],
          },
        ],
      },
    },
    fields: fieldsSetWithEnabledFields([
      InboxFieldType.Subject,
      InboxFieldType.ProjectName,
      InboxFieldType.Company,
      InboxFieldType.FromEmail,
      InboxFieldType.CommunicationType,
      InboxFieldType.CreatedAt,
      InboxFieldType.ProjectAddressLine,
      InboxFieldType.ProjectCity,
      InboxFieldType.TravelTime,
      InboxFieldType.Distance,
    ]),
    displayTotalsInViewsList: true,
    settings: {
      actions: [
        InboxBulkActions.Trash,
        InboxBulkActions.Add,
        InboxBulkActions.Decline,
        InboxBulkActions.Group,
      ],
    },
  },

  {
    id: 'trash',
    name: 'Trash',
    viewGroup: 'Other Emails',
    filter: {
      status: EmailStatus.Archived,
    },
    fields: fieldsSetWithEnabledFields([
      InboxFieldType.Subject,
      InboxFieldType.ProjectName,
      InboxFieldType.Company,
      InboxFieldType.FromEmail,
      InboxFieldType.CommunicationType,
      InboxFieldType.CreatedAt,
      InboxFieldType.ProjectAddressLine,
      InboxFieldType.ProjectCity,
      InboxFieldType.TravelTime,
      InboxFieldType.Distance,
    ]),
    settings: {
      actions: [InboxBulkActions.MoveToInbox],
    },
  },

  {
    id: 'automatically-processed',
    name: 'Automatically Processed',
    viewGroup: 'Other Emails',
    filter: {
      status: EmailStatus.AutoLinked,
    },
    fields: fieldsSetWithEnabledFields([
      InboxFieldType.Subject,
      InboxFieldType.ProjectName,
      InboxFieldType.Company,
      InboxFieldType.FromEmail,
      InboxFieldType.CommunicationType,
      InboxFieldType.CreatedAt,
      InboxFieldType.ProjectAddressLine,
      InboxFieldType.ProjectCity,
      InboxFieldType.TravelTime,
      InboxFieldType.Distance,
    ]),
    settings: {
      actions: [InboxBulkActions.MoveToInbox, InboxBulkActions.Trash],
      hasTimePicker: true,
    },
  },

  {
    id: 'marketing-emails',
    name: 'Marketing Emails',
    viewGroup: 'Other Emails',
    filter: {
      status: EmailStatus.Unlinked,
      isSpam: true,
    },
    fields: fieldsSetWithEnabledFields([
      InboxFieldType.Subject,
      InboxFieldType.FromEmail,
      InboxFieldType.CreatedAt,
    ]),
    displayTotalsInViewsList: true,
    settings: {
      actions: [InboxBulkActions.MoveToInbox, InboxBulkActions.Trash],
      message: 'Marketing emails will be automatically trashed after 7 days',
    },
  },
]

export const allowedFilterEntityFields: { [key in ConditionEntity]?: FilterIdenfier[] } = {
  [ConditionEntity.Email]: [
    FilterIdenfier.ContactEmail,
    FilterIdenfier.EmailSubject,
    FilterIdenfier.EmailCommunicationType,
    FilterIdenfier.EmailPlatform,
    FilterIdenfier.EmailCompanyName,
    FilterIdenfier.EmailFromAddress,
    FilterIdenfier.EmailFromName,
    FilterIdenfier.EmailScrapedFromAddress,
    FilterIdenfier.EmailProcessedAt,
    FilterIdenfier.EmailBidDeadline,
  ],
  [ConditionEntity.Project]: [
    FilterIdenfier.EmailProjectName,
    FilterIdenfier.EmailProjectLocation,
    FilterIdenfier.CompanyId,
    FilterIdenfier.ProjectDistance,
    FilterIdenfier.EmailProjectRegion,
    FilterIdenfier.EmailProjectPostalCode,
    FilterIdenfier.EmailDistance,
    FilterIdenfier.EmailTravelTime,
    FilterIdenfier.EmailUnionType,
    FilterIdenfier.EmailProjectCity,
    FilterIdenfier.EmailProjectAddressLine,
  ],
  [ConditionEntity.Company]: [FilterIdenfier.EmailCompanyName],
}
