import {
  GetUsersRequestQuery,
  GetUsersResponse,
  PatchUserRequestBody,
  PatchUserResponseBody,
  PostUserRequestBody,
  PostUserResponseBody,
  USER_ID,
} from '@basisboard/basis-common/lib/api'
import { stringify } from 'qs'
import { del, get, patch, post } from '../../api'

export const getUsers = (data?: GetUsersRequestQuery) =>
  get<GetUsersResponse>(`/users?${stringify(data)}`).then(response => response.data)

export const patchUser = (userId: USER_ID, data: PatchUserRequestBody) =>
  patch<PatchUserResponseBody>(`/users/${userId}`, data)

export const postUser = (data: PostUserRequestBody) => post<PostUserResponseBody>('/users', data)

export const deleteUser = (userId: USER_ID) => del(`/users/${userId}`)
