import { companyRepository } from '../../containers/Companies'
import { showWarningToast } from '../../containers/Toast'
import { eventBus, EventBusType } from '../../services'

eventBus.register(EventBusType.BlockCompany, companyId =>
  showWarningToast({
    message: 'You just blocked this company. This will prevent you from seeing bids from them.',
    actions: [{ label: 'Undo', onAction: () => companyRepository().unblockCompany(companyId) }],
  }),
)
