import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { borderRadiusMixin, colors, spacing } from '@basisboard/basis-ui/es/styles'
import styled from 'styled-components'
import { background, BackgroundProps, height, HeightProps } from 'styled-system'

export const NotesWrapper = styled.div<BackgroundProps>`
  ${background};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  flex-grow: 1;

  height: 100%;
  overflow: hidden;

  @media print {
    display: none;
  }
`

export const NotesHeader = styled.div<HeightProps>`
  border-bottom: 1px solid #eaebee;
  display: flex;
  flex-direction: column;
  ${height};
  padding: ${spacing(2)} ${spacing(4)};

  textarea {
    height: 40px;
    padding: ${spacing(1)} ${spacing(2)};
  }
`

export const NotesTitle = styled.span`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
`

export const NotesBody = styled.div`
  padding: ${spacing(3)} ${spacing(4)};
  padding-bottom: 0;
  flex: 1;

  overflow: auto;
`

export const NoteContent = styled.div`
  margin: ${spacing(1)} 0;
  padding: ${spacing(1)} ${spacing(2)};
  background: ${colors.white};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  ${borderRadiusMixin};

  textarea {
    height: 40px;
    padding: ${spacing(1)} 0;
  }
`

export const NoteAction = styled.button`
  color: ${colors.mediumGray};
  font-size: 0.75rem;
  padding: 0;
`

export const NoteMessage = styled(Text)`
  word-wrap: break-word;
  white-space: pre-line;

  p {
    margin-bottom: ${spacing(0.5)};
  }

  .mention {
    padding: 2px;
    background: #e7eafe;
    ${borderRadiusMixin};
    font-weight: 500;
    color: ${colors.accent};
  }

  img {
    width: 100%;
  }
`

export const NoteActionSeparator = styled.span`
  width: 16px;
  display: inline-block;
  position: relative;
  color: ${colors.mediumGray};

  &::after {
    content: '•';
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
