import { Card } from '@basisboard/basis-ui/es/components/Card'
import { spacing } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import FieldsMenu, { FieldsMenuProps } from './components/FieldsMenu'

export const FieldsDropdown: React.FC<FieldsMenuProps> = props => {
  return (
    <Card pb={spacing(5)} position="relative">
      <FieldsMenu {...(props as any)} />
    </Card>
  )
}
