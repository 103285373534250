import { IconType } from '@basisboard/basis-ui/es/components/Icon'
import { colors } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import { ActionsButton, ActionsWrapper } from '../styled'

interface Props {
  actions?: (
    | { children: React.ReactNode }
    | {
        icon: IconType
        onAction: () => void
        info?: string
        showOnHover?: boolean
        content?: string
      }
  )[]
  onClick: React.MouseEventHandler<HTMLButtonElement>
  onFocus: () => void
}

export const ActionsBar: React.FC<Props> = ({ actions, onClick, onFocus }) => (
  <ActionsWrapper>
    <ActionsButton
      data-testid="edit"
      tabIndex={0}
      iconColor={colors.blueGray400}
      info="Edit value"
      preIcon="Edit"
      className="default-hoverable"
      onClick={e => {
        e.stopPropagation()
        onClick?.(e)
        e.currentTarget.focus()
      }}
      onFocus={onFocus}
    />

    {actions.map(action =>
      'icon' in action ? (
        <ActionsButton
          info={action.info}
          postIcon={action.icon}
          onClick={e => {
            e.stopPropagation()
            action.onAction()
          }}
          className={action.showOnHover ? 'hoverable' : 'not-hoverable'}
          tabIndex={-1}
        >
          {action.content}
        </ActionsButton>
      ) : (
        action.children
      ),
    )}
  </ActionsWrapper>
)
