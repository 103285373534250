import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Card } from '@basisboard/basis-ui/es/components/Card'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Icon, IconType } from '@basisboard/basis-ui/es/components/Icon'
import { LoadingIndicator } from '@basisboard/basis-ui/es/components/LoadingIndicator'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { ToastInterface, ToastType } from '../../containers/Toast/types'
import { ButtonText } from '../ButtonText'
import { Dropdown } from '../Dropdown'
import { ManageReasonsOption, ReasonEntry, ReasonsList, ToastWrapper } from './styled'

const colorForToastTypeMap = {
  [ToastType.Error]: colors.error,
  [ToastType.Success]: colors.success,
  [ToastType.Warning]: colors.warning,
  [ToastType.Info]: colors.accent,
}

const iconForToastTypeMap: { [key: string]: IconType } = {
  [ToastType.Error]: 'Block',
  [ToastType.Success]: 'Check',
  [ToastType.Warning]: 'Exclamation',
  [ToastType.Info]: 'Info',
}

interface ToastProps extends ToastInterface {
  onStopTimer?: () => void
  onResetTimer?: () => void
  onHide?: () => void
  size?: 'medium' | 'small'
  loading?: boolean
}

export const Toast: React.FC<ToastProps> = ({
  onStopTimer,
  onResetTimer,
  onHide,
  size = 'small',
  loading = false,
  ...toast
}) => {
  const [hide, setHide] = React.useState(false)
  const [toastValue, setToastValue] = React.useState('')

  const ToastIcon = Icon[toast.icon || iconForToastTypeMap[toast.type]]
  const color = colorForToastTypeMap[toast.type]

  const selectedOption =
    toast.select &&
    toast.select.options.find(opt =>
      toastValue ? opt.id === toastValue : opt.id === toast.select.value,
    )

  if (hide) {
    return null
  }

  return (
    <ToastWrapper color={color} onMouseEnter={onStopTimer} onMouseLeave={onResetTimer} size={size}>
      <Div ml={spacing(1.5)}>
        {loading ? <LoadingIndicator size={24} /> : <ToastIcon color={color} size={24} />}
      </Div>
      <Text ml={spacing(1)} color={colors.black}>
        {toast.message}
      </Text>

      <Div ml="auto" display="flex" alignItems="center">
        {toast.select && (
          <Div mr={spacing(4)}>
            <Dropdown
              renderTrigger={onOpen => (
                <Button.Transparent onClick={onOpen} postIcon="ArrowDown">
                  {selectedOption ? <Text>{selectedOption.name}</Text> : 'Add reason'}{' '}
                </Button.Transparent>
              )}
              align="right"
            >
              {onClose => (
                <Card>
                  <ReasonsList>
                    {toast.select.options.map(({ id, name }) => (
                      <li key={id}>
                        <ReasonEntry
                          onClick={() => {
                            setToastValue(id)
                            toast.select.onChange(id)
                            onClose()
                          }}
                        >
                          {name}
                        </ReasonEntry>
                      </li>
                    ))}
                  </ReasonsList>
                  <ManageReasonsOption onMouseDown={e => e.preventDefault()}>
                    Manage reasons in your <Link to={toast.select.url}>company profile</Link>
                  </ManageReasonsOption>
                </Card>
              )}
            </Dropdown>
          </Div>
        )}

        {(toast.actions || []).length > 0 && (
          <Div mr={spacing(4)} display="flex" alignItems="center">
            {toast.actions.map((action, index) => (
              <ButtonText
                key={action.label}
                onClick={() => {
                  onHide && onHide()
                  action.onAction()
                }}
                mr={spacing(index - 1 === toast.actions.length ? 0 : 3)}
              >
                {action.label}
              </ButtonText>
            ))}
          </Div>
        )}

        <Button.Transparent
          size={24}
          onClick={() => {
            onHide && onHide()
            setHide(true)
          }}
          mr={spacing(2)}
          postIcon="Close"
          iconSize={20}
        />
      </Div>
    </ToastWrapper>
  )
}
