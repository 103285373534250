import { borderRadiusMixin, colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import styled, { css } from 'styled-components'
import { space, SpaceProps } from 'styled-system'

export const Wrapper = styled.div.attrs({
  tabIndex: 0,
})<{ focused?: boolean }>`
  box-sizing: border-box;
  min-height: 40px;
  ${borderRadiusMixin};
  border: 1px solid #dadce3;
  position: relative;
  padding: ${spacing(0.5)} ${spacing(2)};
  width: 100%;
  background: transparent;
  background-color: ${colors.white};

  display: flex;
  align-items: center;

  outline: 0;

  ${transitionMixin};

  &:hover {
    border-color: ${colors.accent};
  }

  ${({ focused }) =>
    focused &&
    css`
      border-color: ${colors.accent};
    `}
`

export const HiddenSelect = styled.select`
  display: none;
`

export const Input = styled.input`
  border: 0;
  outline: none;
  width: 100%;
  height: 100%;

  color: ${colors.darkBlue};
  font-size: 0.875rem;
  font-weight: 500;

  &:focus {
    width: 100%;
    height: auto;
  }
`

export const List = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
`

export const ListEntry = styled.li<{ selected?: boolean } & SpaceProps>`
  height: 40px;
  width: 100%;
  padding: 0 ${spacing(2)};
  background: ${colors.white};

  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${transitionMixin};

  &:hover {
    background: ${colors.lightGray3};
  }

  ${({ selected }) =>
    selected &&
    css`
      background: ${colors.lightGray3};
    `}

  ${space};
`

export const SelectedList = styled(List)`
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
`

export const SelectedListEntry = styled(ListEntry)`
  display: inline-flex;
  height: 22px;
  width: auto;
  margin: ${spacing(0.5)};
  padding: 0;
`
