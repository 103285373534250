"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconWrapper = exports.Wrapper = exports.BaseInput = exports.LabelText = exports.Label = exports.BaseWrapper = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const styled_system_1 = require("styled-system");
const styles_1 = require("../../styles");
exports.BaseWrapper = (0, styled_components_1.css) `
  ${styles_1.borderRadiusMixin};
  ${(0, styles_1.borderMixin)('default')};
  ${({ height }) => (0, styles_1.heightMixin)(height)};

  padding: 0 ${(0, styles_1.spacing)(1.5)};

  font-size: 0.9375rem;

  background-color: ${styles_1.colors.white};

  ${styles_1.transitionMixin};

  color: ${styles_1.colors.almostBlack};

  &::placeholder {
    color: ${styles_1.colors.gray};
  }

  &:disabled {
    background: #f9fafb;
    color: ${styles_1.colors.lightGray};

    &::placeholder {
      color: ${styles_1.colors.lightGray};
    }

    cursor: not-allowed;
  }

  &:active,
  &:focus,
  &:not(:disabled):hover {
    ${(0, styles_1.borderMixin)('hover')};
  }

  &.error {
    ${(0, styles_1.borderMixin)(styles_1.colors.error)};
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
exports.Label = styled_components_1.default.label `
  display: block;

  ${({ hidden }) => hidden &&
    (0, styled_components_1.css) `
      visibility: hidden;
    `}

  ${styled_system_1.space};
`;
exports.LabelText = styled_components_1.default.span `
  color: ${styles_1.colors.darkGray};
  font-size: 0.875rem;
  line-height: 24px;
  letter-spacing: 0.001em;
  font-weight: 600;
  display: block;
  margin-bottom: ${(0, styles_1.spacing)(0.5)};
`;
exports.BaseInput = styled_components_1.default.input(({ hasIcon, height }) => (0, styled_components_1.css) `
    ${exports.BaseWrapper};

    width: 100%;

    ${hasIcon &&
    (0, styled_components_1.css) `
        padding-left: ${(0, styles_1.spacing)(height === 'small' ? 3 : 5)};
      `};

    :focus {
      outline: none;
      ${(0, styles_1.borderMixin)('dark')};
    }
  `);
exports.Wrapper = styled_components_1.default.div `
  position: relative;
`;
exports.IconWrapper = styled_components_1.default.div `
  position: absolute;
  width: 16px;
  height: ${({ height }) => `calc(${styles_1.HEIGHT_MAP[height]}px - 2px)`};
  top: 1px;
  left: ${({ height }) => (height === 'small' ? 8 : 16)}px;

  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;
