import { Container } from '@containrz/react-hook'
import mergeDeepRight from 'ramda/src/mergeDeepRight'
import { DeepPartial } from 'utility-types'
import { eventBus, EventBusType, hideIntercom, showIntercom } from '../../services'
import { ModalInterfaces } from './types'

interface State {
  isOpen: boolean
  modalData: ModalInterfaces
  modalDataStack: ModalInterfaces[]
  lastOpenAt: number
}

const initialState = {
  isOpen: false,
  modalData: null,
  modalDataStack: [] as ModalInterfaces[],
  lastOpenAt: null,
}

class ModalState extends Container<State> {
  state = initialState as State

  openModal = (modalData: ModalInterfaces) => {
    eventBus.publish(EventBusType.ShowModal, {
      type: 'type' in modalData ? modalData.type : modalData.name,
    })

    this.setState(s => ({
      isOpen: true,
      lastOpenAt: Date.now(),
      modalDataStack: [...s.modalDataStack, modalData],
    }))

    hideIntercom()
  }

  closeModal = (modalId: string) => {
    const modalDataStack = [...this.state.modalDataStack]

    eventBus.publish(EventBusType.CloseModal, {
      type: modalId,
    })

    this.setState({
      modalDataStack: modalDataStack.filter(r => ('type' in r ? r.type : r.name) !== modalId),
      lastOpenAt: Date.now(),
      isOpen: modalDataStack.length > 0,
    })

    if (!modalDataStack.length) {
      showIntercom()
    }
  }

  updateModalProps = (
    modalData:
      | DeepPartial<ModalInterfaces>
      | ((props: ModalInterfaces) => DeepPartial<ModalInterfaces>),
  ) => {
    if (this.state.modalDataStack.length === 0) {
      return
    }

    const data =
      typeof modalData === 'function'
        ? modalData(this.state.modalDataStack[this.state.modalDataStack.length - 1])
        : modalData

    this.setState(s => {
      const modalDataStack = [...s.modalDataStack]
      // eslint-disable-next-line
      // @ts-ignore
      modalDataStack[modalDataStack.length - 1] = mergeDeepRight(
        modalDataStack[modalDataStack.length - 1],
        data,
      )
      return { modalDataStack }
    })
  }
}

export const modal = new ModalState()

export const { openModal, updateModalProps } = modal
