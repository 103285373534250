import { borderRadiusMixin, colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import { darken } from 'polished'
import styled from 'styled-components'

export const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  span {
    font-weight: 500;
    color: ${colors.darkBlue};
    font-size: 0.875rem;
  }

  &::after {
    content: '';
    width: 160px;
  }
`

export const Option = styled.label`
  background-color: ${colors.lightGray3};
  ${borderRadiusMixin};
  margin-bottom: ${spacing(1)};
  padding: 0 ${spacing(2)};
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;

  cursor: pointer;

  ${transitionMixin};

  &:hover {
    background-color: ${darken(0.05, colors.lightGray3)};
  }
`
