import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Label } from '@basisboard/basis-ui/es/components/Typography'
import * as React from 'react'
import { TwitterPicker } from 'react-color'

const colors = [
  '#ff5722',
  '#ffa726',
  '#ffca28',
  '#a5de37',
  '#81c784',
  '#80cbc4',
  '#ab47bc',
  '#ec407a',
  '#ed4694',
  '#a1887f',
]

const rnd = (arr: string[]) => arr[Math.floor(Math.random() * arr.length)]
export const getRandomUserColor = () => rnd(colors)

export interface ColorInputProps extends React.HTMLProps<HTMLInputElement> {
  label?: string
  error?: string
  initialValue?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Color = React.forwardRef<HTMLInputElement, ColorInputProps>(
  ({ label, error, onChange, ...props }, ref) => {
    const [val, setVal] = React.useState(props.initialValue)

    const handleChangeComplete = color => {
      setVal(color.hex)
      onChange && onChange(color.hex)
    }

    return (
      <>
        <Div>
          {label && <Label required={props.required}>{label}</Label>}
          <TwitterPicker
            color={val}
            onChangeComplete={handleChangeComplete}
            triangle="hide"
            colors={colors}
            ref={ref}
            {...(props as any)}
          />
        </Div>
        {error && error.trim().length > 0 && (
          <Label preventClip error>
            {error}
          </Label>
        )}
      </>
    )
  },
)
