"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZoomOut = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const ZoomOut = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { fill: color, d: "M14.7303638,15.0194798 L10.1702643,10.2771526 C11.2992366,9.18938263 12.0036638,7.66398638 12.0036638,5.9760892 C12.0036638,2.6808108 9.3227831,0 6.0276446,0 C2.7323662,0 0.0515553991,2.6808108 0.0515553991,5.9760892 C0.0515553991,9.27129765 2.7323662,11.9521784 6.0276446,11.9521784 C7.25699953,11.9521784 8.40059202,11.5786991 9.35167371,10.9397479 L13.9740315,15.7467817 C14.0770723,15.8538798 14.2146,15.9078136 14.3522676,15.9078136 C14.4830798,15.9078136 14.6141019,15.8591962 14.7158136,15.7613319 C14.9246934,15.5604268 14.9311991,15.2282897 14.7303638,15.0194798 Z M1.10085117,5.9760892 C1.10085117,3.25946244 3.31101784,1.04929577 6.0276446,1.04929577 C8.74427136,1.04929577 10.9543681,3.25946244 10.9543681,5.9760892 C10.9543681,8.69271596 8.74427136,10.9028826 6.0276446,10.9028826 C3.31101784,10.9028826 1.10085117,8.69271596 1.10085117,5.9760892 Z", id: "Shape" }),
    React.createElement("path", { fill: color, d: "M8.70313897,5.45144131 L3.35208028,5.45144131 C3.06226479,5.45144131 2.82743239,5.68627371 2.82743239,5.9760892 C2.82743239,6.26590469 3.06226479,6.50073709 3.35208028,6.50073709 L8.70313897,6.50073709 C8.99295446,6.50073709 9.22778685,6.26590469 9.22778685,5.9760892 C9.22778685,5.68627371 8.99295446,5.45144131 8.70313897,5.45144131 Z", id: "Path" })));
exports.ZoomOut = ZoomOut;
