import { CustomFieldType } from '@basisboard/basis-common/lib/api/custom-fields'
import * as React from 'react'
import { BaseField } from './components/BaseField'
import { BooleanField } from './components/BooleanField'
import { CompanyMultiSelect } from './components/CompanyMultiSelect'
import { DateField } from './components/DateField'
import { MultiSelect } from './components/MultiSelect'
import { RichText } from './components/RichText'
import { SingleSelect } from './components/SingleSelect'
import { StageSelect } from './components/StageSelect'
import { TeamMultiSelect } from './components/TeamMultiSelect'
import { TextField } from './components/TextField'
import { UserMultiSelect } from './components/UserMultiSelect'
import { TableFieldProps } from './type'

const Fields: React.FC<TableFieldProps> = props => {
  props = {
    ...props,
    field: {
      ...props.field,
      ...(props.field?.options
        ? {
            options:
              props.field?.options?.map(opt => ({ ...opt, value: opt.value || opt.label })) ||
              undefined,
          }
        : {}),
    },
  }

  return (
    {
      [CustomFieldType.Boolean]: () => <BooleanField {...(props as any)} />,

      [CustomFieldType.CompanyMultiSelect]: () => <CompanyMultiSelect {...(props as any)} />,

      [CustomFieldType.Date]: () => <DateField {...(props as any)} />,

      [CustomFieldType.Monetary]: () => <TextField {...(props as any)} />,

      [CustomFieldType.MultiSelect]: () => <MultiSelect {...(props as any)} />,

      [CustomFieldType.Number]: () => <TextField {...(props as any)} />,

      [CustomFieldType.Percent]: () => <TextField {...(props as any)} />,

      [CustomFieldType.RichText]: () => <RichText {...(props as any)} />,

      [CustomFieldType.SingleSelect]: () => <SingleSelect {...(props as any)} />,

      [CustomFieldType.TeamSelect]: () => <TeamMultiSelect {...(props as any)} />,

      [CustomFieldType.Text]: () => <TextField {...(props as any)} />,

      [CustomFieldType.UserMultiSelect]: () => <UserMultiSelect {...(props as any)} />,

      [CustomFieldType.StageSelect]: () => <StageSelect {...(props as any)} />,
    }[props.field.type] || (() => <BaseField {...(props as any)} />)
  )()
}

const typedMemo: <T>(c: T) => T = React.memo

export const TableField = typedMemo(Fields)
