import { EllipsisOverflow } from '@basisboard/basis-ui/es/components/Typography'
import { borderRadiusMixin, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import { darken } from 'polished'
import styled, { css } from 'styled-components'
import { maxWidth, MaxWidthProps } from 'styled-system'

export const Container = styled.div<MaxWidthProps & { color: string }>`
  height: 32px;
  min-width: 128px;
  ${borderRadiusMixin};

  justify-self: flex-start;

  ${EllipsisOverflow};
  overflow: hidden;

  button {
    height: 32px;
    width: 100%;
    padding: 0 ${spacing(1)};
    &:focus {
      outline: none;
    }
  }

  div {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  ${maxWidth};

  ${({ color }) => css`
    background: ${color};
    ${transitionMixin};

    &:hover {
      background: ${darken(0.05, color)};
    }
  `};

  @media print {
    display: none;
  }
`

export const Color = styled.div`
  border-radius: 50%;
  margin-right: ${spacing(1)};
  margin-left: ${spacing(-1)};
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
`
