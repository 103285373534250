"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Download = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Download = ({ width = 14, height = 14, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: size || width, height: size || height, viewBox: "0 0 14 14" },
    React.createElement("path", { d: "M6.08855 1.16699H7.91146C8.21452 1.16699 8.45834 1.41081 8.45834 1.71387V5.54199H10.4567C10.8623 5.54199 11.0651 6.0319 10.778 6.31901L7.31218 9.78711C7.14128 9.95801 6.86101 9.95801 6.69011 9.78711L3.21973 6.31901C2.93262 6.0319 3.13542 5.54199 3.54102 5.54199H5.54167V1.71387C5.54167 1.41081 5.78549 1.16699 6.08855 1.16699ZM12.8333 9.7347V12.2868C12.8333 12.5898 12.5895 12.8337 12.2865 12.8337H1.71355C1.41049 12.8337 1.16667 12.5898 1.16667 12.2868V9.7347C1.16667 9.43164 1.41049 9.18783 1.71355 9.18783H5.05632L6.17286 10.3044C6.63087 10.7624 7.36915 10.7624 7.82715 10.3044L8.94369 9.18783H12.2865C12.5895 9.18783 12.8333 9.43164 12.8333 9.7347ZM10.0078 11.7399C10.0078 11.4893 9.80274 11.2842 9.55209 11.2842C9.30144 11.2842 9.09636 11.4893 9.09636 11.7399C9.09636 11.9906 9.30144 12.1956 9.55209 12.1956C9.80274 12.1956 10.0078 11.9906 10.0078 11.7399ZM11.4662 11.7399C11.4662 11.4893 11.2611 11.2842 11.0104 11.2842C10.7598 11.2842 10.5547 11.4893 10.5547 11.7399C10.5547 11.9906 10.7598 12.1956 11.0104 12.1956C11.2611 12.1956 11.4662 11.9906 11.4662 11.7399Z", fill: color })));
exports.Download = Download;
