import { Paragraph } from '@basisboard/basis-ui/es/components/Typography'
import { transitionMixin } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled from 'styled-components'
import { background, BackgroundProps, space, SpaceProps, width, WidthProps } from 'styled-system'
import { MIN_PASSWORD_LENGTH } from '../../constants'

const BaseBar = styled.div<SpaceProps>`
  width: 100%;
  height: 3px;
  position: relative;
  background-color: #eeeeee;
  border-radius: 8px;
  overflow: hidden;
  ${space}
`

const ProgressBar = styled.div<WidthProps & BackgroundProps>`
  height: 3px;
  position: absolute;
  left: 0;
  top: 0;
  ${width};
  ${background};

  ${transitionMixin};
`

const STRENGTH_COLORS = ['#f6204a', '#fbaf12', '#5bd200']

const interpolateColor = (color1: string, color2: string, percentage: number) => {
  const hex = (num: number) => {
    const h = num.toString(16)
    return h.length === 1 ? '0' + h : h
  }

  const ratio = percentage / 100

  const r = Math.ceil(
    parseInt(color1.substring(1, 3), 16) * ratio +
      parseInt(color2.substring(1, 3), 16) * (1 - ratio),
  )
  const g = Math.ceil(
    parseInt(color1.substring(3, 5), 16) * ratio +
      parseInt(color2.substring(3, 5), 16) * (1 - ratio),
  )
  const b = Math.ceil(
    parseInt(color1.substring(5, 7), 16) * ratio +
      parseInt(color2.substring(5, 7), 16) * (1 - ratio),
  )

  return `#${hex(r)}${hex(g)}${hex(b)}`
}

const getPasswordStrength = (strength: number) => {
  if (strength > 70) {
    return 'Strong'
  } else if (strength > 40) {
    return 'Medium'
  }
  return 'Weak'
}

const scorePassword = (pass: string) => {
  let score = 0
  if (!pass) return score

  const letters = {}
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1
    score += 5.0 / letters[pass[i]]
  }

  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
  }

  let variationCount = 0
  for (const check in variations) {
    variationCount += variations[check] === true ? 1 : 0
  }

  score += (variationCount - 1) * 10

  return score
}

interface Props extends SpaceProps {
  password: string
}

export const PasswordStrength: React.FC<Props> = ({ password, ...rest }) => {
  const strength = Math.min(scorePassword(password), 100)

  const strengthColor =
    strength < 40
      ? interpolateColor(STRENGTH_COLORS[1], STRENGTH_COLORS[0], strength)
      : interpolateColor(STRENGTH_COLORS[2], STRENGTH_COLORS[1], strength)

  return (
    <>
      {password.length >= MIN_PASSWORD_LENGTH ? (
        <>
          <BaseBar {...rest}>
            <ProgressBar width={`${strength}%`} background={strengthColor} />
          </BaseBar>
          <Paragraph color={strengthColor} italic>
            Password strength: {getPasswordStrength(strength)}
          </Paragraph>{' '}
        </>
      ) : (
        <Paragraph color={strengthColor} italic>
          Password is too short
        </Paragraph>
      )}
    </>
  )
}
