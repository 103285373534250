"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Outlook = void 0;
const React = __importStar(require("react"));
const Svg_1 = require("./Svg");
const Outlook = ({ width = 16, height = 16, size, color = '#0072C6' }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 52 54" },
    React.createElement("path", { fill: color, d: "M33,11 L33,22.4553639 L36.5095915,24.9760722 C36.6021523,25.0068661 36.8027001,25.0090668 36.8952608,24.9760722 L52,13.3601394 C52,11.9854075 50.8757732,11 50.2413499,11 L33,11 Z" }),
    React.createElement("path", { fill: color, d: "M33.001928,26.2688444 L36.204262,28.6435617 C36.6554036,29.0015384 37.1990872,28.6435617 37.1990872,28.6435617 C36.6573316,29.0015384 52,18 52,18 L52,37.9218173 C52,40.0904899 50.7140538,41 49.2680884,41 L33,41 L33,26.2688444 L33.001928,26.2688444 Z" }),
    React.createElement("g", null,
        React.createElement("path", { fill: color, d: "M16.2683871,21.0828169 C15.099871,21.0828169 14.1687742,21.6223099 13.4812903,22.6992676 C12.7938065,23.7762254 12.4490323,25.2020282 12.4490323,26.9766761 C12.4490323,28.7776901 12.7938065,30.2014648 13.4812903,31.248 C14.1687742,32.2965634 15.0709677,32.8178028 16.1858065,32.8178028 C17.3357419,32.8178028 18.2482581,32.3087324 18.9212903,31.2905915 C19.5943226,30.2724507 19.9329032,28.8588169 19.9329032,27.0517183 C19.9329032,25.1675493 19.6067097,23.7011831 18.9522581,22.6526197 C18.2978065,21.6060845 17.403871,21.0828169 16.2683871,21.0828169 Z" }),
        React.createElement("path", { fill: color, d: "M0,6.08450704 L0,47.4084507 L32,54 L32,0 L0,6.08450704 Z M21.4131613,33.732507 C20.0609032,35.4807887 18.2978065,36.3569577 16.1218065,36.3569577 C14.0015484,36.3569577 12.2756129,35.5091831 10.939871,33.815662 C9.60619355,32.1201127 8.93729032,29.9134648 8.93729032,27.191662 C8.93729032,24.3177465 9.61445161,21.9934648 10.9708387,20.2188169 C12.3272258,18.444169 14.1233548,17.555831 16.3592258,17.555831 C18.4712258,17.555831 20.1806452,18.4036056 21.4833548,20.1032113 C22.788129,21.8028169 23.4405208,24.0419155 23.4405208,26.8225352 C23.4425806,29.6802254 22.7654194,31.9842254 21.4131613,33.732507 Z" }))));
exports.Outlook = Outlook;
