"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddUser = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const AddUser = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M15.6 6.7996H14V5.1996C14 4.9796 13.82 4.7996 13.6 4.7996H12.8C12.58 4.7996 12.4 4.9796 12.4 5.1996V6.7996H10.8C10.58 6.7996 10.4 6.9796 10.4 7.1996V7.9996C10.4 8.2196 10.58 8.3996 10.8 8.3996H12.4V9.9996C12.4 10.2196 12.58 10.3996 12.8 10.3996H13.6C13.82 10.3996 14 10.2196 14 9.9996V8.3996H15.6C15.82 8.3996 16 8.2196 16 7.9996V7.1996C16 6.9796 15.82 6.7996 15.6 6.7996ZM5.59999 7.9996C7.36749 7.9996 8.79999 6.5671 8.79999 4.7996C8.79999 3.03211 7.36749 1.59961 5.59999 1.59961C3.83249 1.59961 2.4 3.03211 2.4 4.7996C2.4 6.5671 3.83249 7.9996 5.59999 7.9996ZM7.83999 8.7996H7.42249C6.86749 9.0546 6.24999 9.1996 5.59999 9.1996C4.94999 9.1996 4.33499 9.0546 3.7775 8.7996H3.36C1.505 8.7996 0 10.3046 0 12.1596V13.1996C0 13.8621 0.537499 14.3996 1.2 14.3996H9.99999C10.6625 14.3996 11.2 13.8621 11.2 13.1996V12.1596C11.2 10.3046 9.69499 8.7996 7.83999 8.7996Z", fill: color })));
exports.AddUser = AddUser;
