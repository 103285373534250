"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Menu = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../styles");
const monads_1 = require("../../utils/monads");
const Icon_1 = require("../Icon");
const Typography_1 = require("../Typography");
const controller_1 = require("./controller");
const styled_1 = require("./styled");
__exportStar(require("./props"), exports);
const MenuContent = ({ entries, selectedValue, footerEntries = [], footer, controller, boxWidth, alignSide, boxMaxHeight, }) => {
    const renderEntry = React.useCallback(entry => (React.createElement("li", { key: entry.label, id: entry.id, role: "option", "aria-selected": entry.id === selectedValue },
        React.createElement(styled_1.EntryButton, { tabIndex: -1, onClick: () => {
                controller.current.close();
                entry.onAction();
            }, color: entry.color, type: "button" }, entry.render || (React.createElement("span", null,
            entry.icon && (0, monads_1.box)(Icon_1.Icon[entry.icon]).fold(Component => React.createElement(Component, null)),
            React.createElement(Typography_1.Paragraph, { color: entry.color || styles_1.colors.blueGray700, as: "span" }, entry.label)))))), []);
    return (React.createElement(React.Fragment, null,
        React.createElement(styled_1.MenuContainer, { alignSide: alignSide, "data-testid": "menu-container", onClick: e => e.stopPropagation(), ref: controller.current.setContentElement, "aria-hidden": "true", width: boxWidth, maxHeight: boxMaxHeight },
            React.createElement(styled_1.MenuList, { role: "listbox", "aria-activedescendant": selectedValue, tabIndex: -1, onKeyDown: controller.current.handleKeyDown },
                entries.map(renderEntry),
                footerEntries.length > 0 && React.createElement(styled_1.Separator, { role: "none" }),
                footerEntries.map(renderEntry)),
            footer && React.createElement(styled_1.FooterContent, null, footer))));
};
const Menu = ({ entries, footerEntries, footer, id, title = '', triggerLabel, selectedValue, renderTrigger, boxWidth, boxMaxHeight, alignSide = 'left', children, }) => {
    const controller = React.useRef(new controller_1.MenuController(id));
    return (React.createElement(styled_1.Wrapper, { id: id },
        (0, monads_1.box)({
            'aria-label': triggerLabel || `Open menu ${title}`,
            'aria-haspopup': 'listbox',
            'aria-expanded': false,
            ref: controller.current.setTriggerElement,
            onClick: controller.current.open,
        }).fold(buttonProps => (renderTrigger === null || renderTrigger === void 0 ? void 0 : renderTrigger(buttonProps)) || (React.createElement(styled_1.Button, Object.assign({}, buttonProps, { type: "button" }),
            React.createElement("span", null,
                title,
                React.createElement(Icon_1.Icon.Arrow, null))))),
        children ? (React.createElement(styled_1.MenuContainer, { alignSide: alignSide, ref: controller.current.setContentElement, onClick: e => e.stopPropagation(), "aria-hidden": "true", role: "listbox", "aria-activedescendant": selectedValue, width: boxWidth, maxHeight: boxMaxHeight }, children)) : (React.createElement(MenuContent, { footerEntries: footerEntries, entries: entries, selectedValue: selectedValue, controller: controller, alignSide: alignSide, boxWidth: boxWidth, boxMaxHeight: boxMaxHeight, footer: footer }))));
};
exports.Menu = Menu;
