"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Assign = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Assign = ({ width = 24, height = 24, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 24 24" },
    React.createElement("circle", { cx: "12", cy: "12", r: "11.5", stroke: color, strokeDasharray: "2 2", fill: styles_1.colors.white }),
    React.createElement("path", { d: "M11.0001 12C12.4734 12 13.6667 10.8066 13.6667 9.33329C13.6667 7.85996 12.4734 6.66663 11.0001 6.66663C9.52675 6.66663 8.33341 7.85996 8.33341 9.33329C8.33341 10.8066 9.52675 12 11.0001 12ZM18.0001 9.66663V7.66663H16.6667V9.66663H14.6667V11H16.6667V13H18.0001V11H20.0001V9.66663H18.0001ZM11.0001 13.3333C9.22008 13.3333 5.66675 14.2266 5.66675 16V17.3333H16.3334V16C16.3334 14.2266 12.7801 13.3333 11.0001 13.3333Z", fill: color })));
exports.Assign = Assign;
