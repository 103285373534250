import { useContainer } from '@containrz/react-hook'
import React from 'react'
import { FieldsState } from '../../../../container'
import { TableFieldProps } from '../../type'
import { MultiSelect } from '../MultiSelect'

export const TeamMultiSelect: React.FC<TableFieldProps> = props => {
  const teams = useContainer(FieldsState).getTeams()
  const options = React.useMemo(
    () =>
      teams.map(team => ({
        value: team.id,
        label: team.label,
      })),
    [teams],
  )

  return (
    <MultiSelect {...(props as any)} field={{ ...props.field, options }}>
      {!props.value && 'Not set'}
    </MultiSelect>
  )
}
