import { Container } from '@containrz/react-hook'
import keys from 'ramda/src/keys'
import values from 'ramda/src/values'
import { get, put } from '../../api'
import { eventBus, EventBusType } from '../eventBus'
import { MAP_FEATURE_TYPE_TO_AB } from './contants'
import { ExperimentType, FeatureTypes } from './types'

const putUserSettings = (key: string, value: Record<string, unknown>) =>
  put(`/profile/settings/${key}`, { value: { [key]: value } })

const getUserSettings = (key: string) =>
  get(`/profile/settings/${key}`).then(response => response.data.value?.[key])

export * from './types'

const EXPERIMENT_SETTINGS_KEY = 'experiments'

const getAb = (probability = 0.5) => (Math.random() < probability ? 'a' : 'b')

type State = Partial<Record<ExperimentType, FeatureTypes['a' | 'b']>>

class Experiments extends Container<State> {
  loading = false
  state = {}

  loadExperiments = () => {
    this.loading = true
    getUserSettings(EXPERIMENT_SETTINGS_KEY)
      .then(storedExperiments => {
        if (storedExperiments) {
          this.setState(storedExperiments)
        }

        const newKeys = values(ExperimentType).filter(
          experiment => !keys(storedExperiments || {}).includes(experiment),
        )

        const state = newKeys.reduce((acc, experiment) => {
          const ab = getAb()
          return {
            ...acc,
            [experiment]: MAP_FEATURE_TYPE_TO_AB[experiment][ab],
          }
        }, {})

        this.setState(state)

        if (newKeys.length > 0) {
          putUserSettings(EXPERIMENT_SETTINGS_KEY, this.state)
        }
      })
      .finally(() => (this.loading = false))
  }

  getExperimentData = (feature: ExperimentType): FeatureTypes['a' | 'b'] => {
    if (!this.state[feature]) {
      const ab = getAb()
      this.setState(s => ({
        ...s,
        [feature]: MAP_FEATURE_TYPE_TO_AB[feature][ab],
      }))
    }

    return this.state[feature]
  }

  setExperimentData = (feature: ExperimentType, experiment: FeatureTypes) => {
    this.setState(s => ({ ...s, [feature]: experiment }))

    putUserSettings(EXPERIMENT_SETTINGS_KEY, this.state)
  }
}

export const experimentsService = new Experiments()

eventBus.register(EventBusType.SessionStarted, () => {
  experimentsService.loadExperiments()
})
