import {
  COMPANY_ID,
  CONTACT_ID,
  GetProjectNotesResponseBody,
  NOTE_ID,
  PatchNoteRequestBody,
  PatchNoteResponseBody,
  PostNotesResponseBody,
  PROJECT_ID,
  QUOTE_ID,
} from '@basisboard/basis-common/lib/api'
import { del, get, patch, post } from '../../api'
import { NoteEntityType } from './types'

export const getNotes = (entity: NoteEntityType, entityId: PROJECT_ID | CONTACT_ID | COMPANY_ID) =>
  get<GetProjectNotesResponseBody>(`/${entity}/${entityId}/notes`).then(
    response => response.data.notes,
  )

export const postNote = (
  entity: NoteEntityType,
  entityId: PROJECT_ID | CONTACT_ID | COMPANY_ID | QUOTE_ID,
  note: unknown,
) =>
  post<PostNotesResponseBody>(`/${entity}/${entityId}/notes`, note).then(
    response => response.data.note,
  )

export const deleteNote = (noteId: NOTE_ID) => del(`/notes/${noteId}`)

export const patchNote = (noteId: NOTE_ID, note: PatchNoteRequestBody) =>
  patch<PatchNoteResponseBody>(`/notes/${noteId}`, note)
