import { Div } from '@basisboard/basis-ui/es/components/Div'
import { HeavyText } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import { SpaceProps } from 'styled-system'

interface Props extends React.HTMLProps<HTMLLabelElement> {
  label: string
  error?: boolean
  required?: boolean
  space?: SpaceProps
}

export const Label: React.FC<Props> = ({ label, error, required, space, children, ...rest }) => (
  <Div as="label" display="flex" alignItems="center" mb={spacing(1)} {...space} {...(rest as any)}>
    <HeavyText color={error ? colors.error : colors.darkGray} italic={!!error} lineHeight="24px">
      {label}{' '}
    </HeavyText>
    {children}
    {required && (
      <HeavyText ml="4px" color={colors.error}>
        *
      </HeavyText>
    )}
  </Div>
)
