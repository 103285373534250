import { ProjectFieldType, SortDirection } from '@basisboard/basis-common/lib/enums'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { ButtonGroup } from '@basisboard/basis-ui/es/components/ButtonGroup'
import { Card } from '@basisboard/basis-ui/es/components/Card'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Select } from '@basisboard/basis-ui/es/components/Select'
import { useEscapeKey } from '@basisboard/basis-ui/es/hooks/useEscapeKey'
import { useOutsideClick } from '@basisboard/basis-ui/es/hooks/useOutsideClick'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { box } from '@basisboard/basis-ui/es/utils'
import all from 'ramda/src/all'
import curry from 'ramda/src/curry'
import isEmpty from 'ramda/src/isEmpty'
import omit from 'ramda/src/omit'
import pick from 'ramda/src/pick'
import * as React from 'react'
import { Field, Sort } from '../../../../templates/ViewsScreen'
import { getSortOptionByType } from '../../helpers'

interface Props {
  sorts?: Sort[]
  fields?: Field[]
  onApplySorts: (sorts: Sort[]) => void
}

export const SortSelector: React.FC<Props> = ({ sorts, fields, onApplySorts }) => {
  const [showDropdown, setShowDropdown] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>()

  const [appliedSorts, setAppliedSorts] = React.useState<any[]>()

  const handleAddCondition = () => setAppliedSorts(s => [...s, {}])

  const applyInitialSorts = () =>
    setAppliedSorts(
      (sorts || []).reduce((acc, s) => {
        const sortField = fields.find(
          f => (typeof f.sortKey === 'string' ? f.sortKey : f.sortKey?.name) === s.name,
        )
        if (sortField) {
          return [...acc, { ...pick(['id', 'type', 'label'], sortField), ...s }]
        }
        return acc
      }, []),
    )

  const handleCreateAppliedSorts = React.useCallback(() => {
    applyInitialSorts()
    setShowDropdown(false)
  }, [JSON.stringify(sorts)])

  const handleRemoveEntry = React.useCallback(
    index => setAppliedSorts(s => s.map((s, i) => (i === index ? null : s)).filter(Boolean)),
    [],
  )

  const handleChangeSortKey = React.useCallback(
    curry((index: number, fieldId: string) => {
      setAppliedSorts(sorts =>
        sorts.map((s, i) =>
          index === i
            ? {
                ...pick(['direction'], s),
                ...box(
                  pick(
                    ['id', 'type', 'label', 'sortKey'],
                    fields.find(f => f.id === fieldId),
                  ),
                ).fold(field =>
                  omit(['sortKey'], {
                    ...field,
                    ...(typeof field.sortKey === 'string'
                      ? { name: field.sortKey }
                      : field.sortKey),
                  }),
                ),
              }
            : s,
        ),
      )
    }),
    [],
  )

  const handleChangeDirection = React.useCallback(
    curry((index: number, direction: SortDirection) => {
      setAppliedSorts(sorts =>
        sorts.map((s, i) =>
          index === i
            ? {
                ...omit(['direction'], s),
                direction,
              }
            : s,
        ),
      )
    }),
    [],
  )

  const handleApplySorts = sorts => {
    onApplySorts(
      sorts.map(s => pick(['name', 'direction', ...(Boolean(s.entity) ? ['entity'] : [])], s)),
    )
    setShowDropdown(false)
  }

  React.useEffect(() => {
    applyInitialSorts()
  }, [JSON.stringify(sorts)])

  useOutsideClick([ref], () => setShowDropdown(false))
  useEscapeKey(() => setShowDropdown(false))

  const canSave = all(
    ({ name, direction }) => Boolean(name) && Boolean(direction),
    appliedSorts || [],
  )

  return (
    <Div position="relative">
      <Button.Text
        color={isEmpty(appliedSorts) ? undefined : colors.accent}
        iconColor={isEmpty(appliedSorts) ? undefined : colors.accent}
        postIcon="Arrow"
        onClick={() => setShowDropdown(true)}
      >
        Sorts
      </Button.Text>

      {showDropdown && (
        <Card
          position="absolute"
          top="100%"
          width={400}
          right={0}
          zIndex={20}
          p={spacing(2)}
          ref={ref}
        >
          {appliedSorts.map((sort, index) => (
            <>
              <ButtonGroup data-testid="sort-group" key={sort.id} mb={spacing(1)}>
                <Select
                  hideEmpty
                  label={index === 0 ? 'Sort by' : 'And then by'}
                  options={fields
                    .filter(
                      f =>
                        Boolean(f.sortKey) &&
                        (!appliedSorts.some(s => s.id === f.id) || sort.id === f.id),
                    )
                    .map(f => ({
                      id: f.id,
                      name: f.label,
                    }))}
                  value={sort.id}
                  sizeVariant="small"
                  dropdownWidth="100%"
                  onChange={handleChangeSortKey(index)}
                />

                <Select
                  hideEmpty
                  label="Direction"
                  options={
                    [
                      ProjectFieldType.Companies,
                      ProjectFieldType.ProjectName,
                      ProjectFieldType.Region,
                      ProjectFieldType.StageReason,
                    ].includes(sort.id)
                      ? [
                          { name: 'A → Z', id: SortDirection.ASC },
                          { name: 'Z → A', id: SortDirection.DESC },
                        ]
                      : getSortOptionByType(sort.type)
                  }
                  value={sort.direction}
                  sizeVariant="small"
                  dropdownWidth="100%"
                  onChange={handleChangeDirection(index)}
                />

                <Button.Default
                  preIcon="Delete"
                  onClick={() => handleRemoveEntry(index)}
                  minWidth={32}
                  mt="auto"
                />
              </ButtonGroup>
            </>
          ))}

          <Button.Text
            p={0}
            color={colors.accent}
            iconColor={colors.accent}
            preIcon="Plus"
            onClick={handleAddCondition}
            height={24}
          >
            Add Sort By
          </Button.Text>

          <ButtonGroup width={1} justifyContent="flex-end">
            <Button.Default onClick={handleCreateAppliedSorts}>Cancel</Button.Default>
            <Button.Primary onClick={() => handleApplySorts(appliedSorts)} disabled={!canSave}>
              Save
            </Button.Primary>
          </ButtonGroup>
        </Card>
      )}
    </Div>
  )
}
