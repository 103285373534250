import { colors } from '@basisboard/basis-ui/es/styles'
import { fuzzySearch } from '@basisboard/basis-ui/es/utils'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { TableFieldProps } from '../../type'
import { getOptionColors } from '../../utils'
import { BaseField } from '../BaseField'
import { SelectorValues } from '../SelectorValues'
import { CustomFieldSelectOption } from '../styled'

const Input = styled.input`
  font-size: ${rem(14)};
  color: ${colors.darkBlue};
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  font-weight: 500;
`

export const Search: React.FC<TableFieldProps> = ({
  field,
  onDismiss,
  customActions,
  forceShowEdit,
  renderOption,
  children,
  onChange,
  value: val,
  allowCreateNewValue,
  actions,
}) => {
  const value = val as string

  const [showEdit, setShowEdit] = React.useState(forceShowEdit || false)
  const [searchQuery, setSearchQuery] = React.useState<string>(allowCreateNewValue ? value : '')
  const [inputRef, setInputRef] = React.useState<HTMLInputElement>()
  const [currentOption, setCurrentOption] = React.useState(
    allowCreateNewValue
      ? { value, label: value, color: '' }
      : field.options.find(opt => opt.value === value),
  )

  const filteredOptions = React.useMemo(
    () =>
      searchQuery
        ? fuzzySearch(field.options, searchQuery || 'basis', {
            options: { keys: ['label'] },
          })
        : field.options,
    [searchQuery, JSON.stringify(field.options)],
  )

  React.useEffect(() => {
    inputRef?.focus()
  }, [inputRef])

  const handleListSelect = (val: string) => {
    onChange(val)
    setCurrentOption(field.options.find(o => o.value === val))
    setShowEdit(false)
    setSearchQuery('')
  }

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newVal = e.currentTarget.value

    if (allowCreateNewValue) {
      onChange(newVal)
      setCurrentOption({ value: newVal, label: newVal, color: '' })
    }
    setSearchQuery(newVal)
  }

  return (
    <BaseField
      readonly={!Boolean(onChange)}
      onDismiss={onDismiss}
      forceShowEdit={forceShowEdit}
      initialShowEdit={showEdit}
      onShowEdit={setShowEdit}
      actions={actions}
      autoApply={true}
      customActions={{
        customActions: (
          <>
            <SelectorValues
              renderOption={renderOption}
              options={filteredOptions}
              value={value}
              onChange={handleListSelect}
              allowCreateNewValue={allowCreateNewValue}
            />

            {customActions?.customActions}
          </>
        ),
      }}
    >
      {showEdit ? (
        <Input
          placeholder={`Type to search${allowCreateNewValue ? ' or create new value' : '...'}`}
          onChange={handleSearchInputChange}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              if (filteredOptions[0] && !allowCreateNewValue) {
                onChange(filteredOptions[0].value)
              }
            }
          }}
          value={searchQuery}
          ref={setInputRef}
        />
      ) : (
        children ||
        renderOption?.(currentOption) || (
          <CustomFieldSelectOption
            as="div"
            {...(currentOption ? getOptionColors(currentOption) : {})}
          >
            {currentOption?.label}
          </CustomFieldSelectOption>
        )
      )}
    </BaseField>
  )
}
