"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FootCell = void 0;
const polished_1 = require("polished");
const styled_components_1 = __importStar(require("styled-components"));
const styled_system_1 = require("styled-system");
const styles_1 = require("../../../../styles");
exports.FootCell = styled_components_1.default.td `
  height: 32px;
  margin: 0;
  padding: 0 ${(0, styles_1.spacing)(2)};

  background-color: ${styles_1.colors.blueGray};

  position: relative;

  font-size: ${(0, polished_1.rem)(10)};
  font-weight: 600;
  text-transform: uppercase;
  color: ${styles_1.colors.lightGray};

  border-bottom: 1px solid ${styles_1.colors.blueGrayBorder};

  :first-child {
    position: sticky;
    left: 0px;

    z-index: 1;
    border-left: unset;
  }

  ${({ hideBorder }) => !hideBorder &&
    (0, styled_components_1.css) `
      &:not(:last-child) {
        ::after {
          content: '';
          pointer-events: none;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          left: 0;
          border-color: ${styles_1.colors.blueGrayBorder};
          border-style: solid;
          border-width: 0;
          border-right-width: 1px;
        }
      }
    `}

  ${styled_system_1.height};
  ${styled_system_1.background};
`;
