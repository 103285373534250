"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScatterPlot = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const ScatterPlot = ({ width = 24, height = 24, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 24 24" },
    React.createElement("path", { d: "M21.375 17H4.5V5.125C4.5 4.77969 4.22031 4.5 3.875 4.5H2.625C2.27969 4.5 2 4.77969 2 5.125V18.25C2 18.9402 2.55977 19.5 3.25 19.5H21.375C21.7203 19.5 22 19.2203 22 18.875V17.625C22 17.2797 21.7203 17 21.375 17Z", fill: color }),
    React.createElement("circle", { cx: "9.5", cy: "7.5", r: "1.5", fill: color }),
    React.createElement("circle", { cx: "8.5", cy: "13.5", r: "1.5", fill: color }),
    React.createElement("circle", { cx: "13.5", cy: "10.5", r: "1.5", fill: color }),
    React.createElement("circle", { cx: "17.5", cy: "13.5", r: "1.5", fill: color }),
    React.createElement("circle", { cx: "18.5", cy: "7.5", r: "1.5", fill: color })));
exports.ScatterPlot = ScatterPlot;
