import { TextAreaInputProps } from '@basisboard/basis-ui/es/components/TextArea'
import { ToggleInputProps } from './components'
import { BaseSelectProps } from './components/BaseSelect'
import { CheckboxInputProps } from './components/Checkbox'
import { ColorInputProps } from './components/Color'
import { RadioInputProps } from './components/Radio'
import { RadioGroupInputProps } from './components/RadioGroup'
import { SelectInputProps } from './components/Select'
import { TextInputProps } from './components/Text'

export enum InputType {
  CHECKBOX = 'CHECKBOX',
  MONEY = 'MONEY',
  PERCENTAGE = 'PERCENTAGE',
  NUMBER = 'NUMBER',
  RADIO = 'RADIO',
  RADIO_GROUP = 'RADIO_GROUP',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  TEXT_AREA = 'TEXT_AREA',
  SEARCH = 'SEARCH',
  TOGGLE = 'TOGGLE',
  COLOR = 'COLOR',
}

interface BaseInputProps<T extends InputType> {
  inputType: T
  pre?: React.ReactElement
  post?: React.ReactElement
  wrap?: (children: React.ReactNode) => React.ReactNode
  nested?: InputProps[]
  initialValue?: any
}

type TextInput = BaseInputProps<InputType.TEXT> & TextInputProps

type TextAreaInput = BaseInputProps<InputType.TEXT_AREA> & TextAreaInputProps

type ToggleInput = BaseInputProps<InputType.TOGGLE> & ToggleInputProps

type NumberInput = BaseInputProps<InputType.NUMBER> & TextInputProps

type CheckboxInput = BaseInputProps<InputType.CHECKBOX> & CheckboxInputProps

type SelectInput = BaseInputProps<InputType.SELECT> & SelectInputProps

type RadioInput = BaseInputProps<InputType.RADIO> & RadioInputProps

type RadioGroupInput = BaseInputProps<InputType.RADIO_GROUP> & RadioGroupInputProps

type SearchInput = BaseInputProps<InputType.SEARCH> & BaseSelectProps

type ColorInput = BaseInputProps<InputType.COLOR> & ColorInputProps

export type InputProps =
  | TextInput
  | TextAreaInput
  | ToggleInput
  | NumberInput
  | CheckboxInput
  | SearchInput
  | SelectInput
  | RadioInput
  | RadioGroupInput
  | ColorInput
