import { GroupByTimeFilter, ProjectSearchQueryGroup } from '@basisboard/basis-common/lib/api'
import { CustomFieldType } from '@basisboard/basis-common/lib/api/custom-fields'
import { SortDirection } from '@basisboard/basis-common/lib/enums'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Card } from '@basisboard/basis-ui/es/components/Card'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Label } from '@basisboard/basis-ui/es/components/Label'
import { spacing } from '@basisboard/basis-ui/es/styles'
import { box, fromNullable, readableGroupByTimeFilter } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import equals from 'ramda/src/equals'
import omit from 'ramda/src/omit'
import values from 'ramda/src/values'
import * as React from 'react'
import { ButtonText, Select } from '../../../../components'
import { ViewId } from '../../../../constants'
import { Group } from '../../../../templates/ViewsScreen'
import { FieldsState } from '../../../Fields'
import { FiltersStateInstances } from '../../../Filters'
import { getSortOptionByType } from '../../../Sorts'
import { getGroups, GroupType } from '../../constants'
import { GroupsState } from '../../state'

interface Props {
  onChange: (val: ProjectSearchQueryGroup) => void
  onClose?: () => void
  selectedGroup?: Group
  options?: GroupType[]
  preventFilterUpdate?: boolean
}

export const Dropdown: React.FC<Props> = ({
  onChange,
  onClose,
  selectedGroup,
  options = getGroups(),
  preventFilterUpdate,
}) => {
  const groupsData = useContainer(GroupsState)
  const fieldsData = useContainer(FieldsState)

  const [group, setGroup] = React.useState<ProjectSearchQueryGroup>(
    selectedGroup || groupsData.state.currentGroup || ({} as ProjectSearchQueryGroup),
  )
  const filtersData = useContainer(FiltersStateInstances(ViewId.ListViewId))

  const handleUpdateFields = React.useCallback(
    updatedGroup => {
      if (preventFilterUpdate) {
        return
      }
      fromNullable(
        [...filtersData.state.customFilters, ...filtersData.state.sharedCustomFilters].find(cf =>
          equals(
            JSON.stringify(cf.filter),
            JSON.stringify(filtersData.state.filter?.customFilter || {}),
          ),
        ),
      ).fold(
        () => groupsData.changeGroup(updatedGroup),

        customFilter => {
          if (!customFilter.group) {
            return groupsData.changeGroup(updatedGroup)
          }

          groupsData.setState({
            currentGroup: updatedGroup,
          })
        },
      )
    },
    [fieldsData],
  )

  const applyGroup = React.useCallback(() => {
    handleUpdateFields(group)

    onChange(group)

    onClose?.()
  }, [onChange, group])

  const renderRow = (g: ProjectSearchQueryGroup) => {
    const selectedOption = options.find(
      opt =>
        opt.group.name === g.name && (!opt.group.time || opt.group.time.field === g.time.field),
    )

    return (
      <>
        <Div display="flex" alignItems="center" justifyContent="space-between">
          <Div width={180}>
            <Select
              options={options}
              value={selectedOption?.id || null}
              sizeVariant="small"
              placeholder="Field"
              onChange={name =>
                setGroup(g => ({
                  ...omit(['time'], g),
                  ...options.find(opt => opt.value === name).group,
                  ...box(options.find(opt => opt.value === name).group).fold(gr =>
                    gr.time && selectedOption?.type === CustomFieldType.Date
                      ? { time: { ...g.time, ...gr.time } }
                      : {},
                  ),
                }))
              }
              hideEmpty
              dropdownWidth="100%"
            />
          </Div>

          <Div width={180}>
            <Select
              disabled={!group.name}
              options={getSortOptionByType(selectedOption?.type)}
              value={g.sortDirection || SortDirection.ASC}
              sizeVariant="small"
              placeholder="Sort direction"
              hideEmpty
              onChange={sortDirection =>
                setGroup(g => ({
                  ...g,
                  sortDirection,
                }))
              }
              dropdownWidth="100%"
            />
          </Div>
        </Div>

        {selectedOption?.type === CustomFieldType.Date && (
          <Div display="flex" alignItems="center" justifyContent="space-between" mt={spacing(1)}>
            <Div width={180}>
              <Select
                options={values(GroupByTimeFilter)
                  .filter(v => v !== GroupByTimeFilter.None)
                  .map(time => ({
                    id: time,
                    name: readableGroupByTimeFilter(time),
                  }))}
                value={g.time?.period || GroupByTimeFilter.Monthly}
                sizeVariant="small"
                placeholder="Time span"
                onChange={period =>
                  setGroup(g => ({
                    ...g,
                    time: {
                      ...(g.time || {}),
                      period,
                    },
                  }))
                }
                hideEmpty
                dropdownWidth="100%"
              />
            </Div>
          </Div>
        )}
      </>
    )
  }

  const canApply = React.useMemo(() => group.name && (!group.time || Boolean(group.time.field)), [
    group,
  ])

  return (
    <Card width={400} p={spacing(2)}>
      <Div display="flex" alignItems="center" justifyContent="space-between" mb={spacing(1)}>
        <Label space={{ mb: 0 }} label="Group by" />
        <ButtonText
          onClick={() => {
            groupsData.changeGroup(null)
            onChange(null)
            onClose?.()
          }}
        >
          Clear groups
        </ButtonText>
      </Div>

      {renderRow(group)}

      <Div display="flex" width={1} mt={spacing(2)}>
        <Button.Primary ml="auto" disabled={!canApply} onClick={applyGroup}>
          Apply
        </Button.Primary>
      </Div>
    </Card>
  )
}
