import { Card } from '@basisboard/basis-ui/es/components/Card'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { SafeWrapper } from '@basisboard/basis-ui/es/components/SafeWrapper'
import * as React from 'react'

export type DropdownProps = React.ComponentProps<typeof Div> &
  Pick<React.ComponentProps<typeof SafeWrapper>, 'align'> & {
    renderTrigger: (onOpen: () => void, isOpened: boolean) => React.ReactNode
    children: (onClose: () => void) => React.ReactNode
    defaultPosition?: 'top' | 'bottom' | 'center'
    zIndex?: number
    triggerStyle?: React.CSSProperties
    onSetTarget?: (ref: React.MutableRefObject<HTMLDivElement>) => void
  }

export const Dropdown: React.FC<DropdownProps> = ({
  renderTrigger,
  children,
  align,
  triggerStyle,
  onSetTarget,
  defaultPosition = 'bottom',
  ...props
}) => {
  const [isShowing, setShowing] = React.useState(false)
  const target = React.useRef<HTMLDivElement>()

  React.useEffect(() => {
    onSetTarget && onSetTarget(target)
  }, [onSetTarget, target])

  return (
    <>
      {/* eslint-disable-next-line */}
      <div ref={target} onClick={e => e.stopPropagation()} style={triggerStyle}>
        {renderTrigger(() => setShowing(true), isShowing)}
      </div>
      <SafeWrapper
        target={target.current}
        align={align}
        onClose={() => setShowing(false)}
        defaultPosition={defaultPosition}
        show={isShowing}
      >
        <Card {...(props as any)}>{children(() => setShowing(false))}</Card>
      </SafeWrapper>
    </>
  )
}
