import { USER_ID } from '@basisboard/basis-common/lib/api'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { SafeWrapper } from '@basisboard/basis-ui/es/components/SafeWrapper'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { box, fuzzySearch, readableName } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import { PersonChip } from '../../../../components/PersonChip'
import { UsersContainer } from '../../container'
import { Input, SelectionBox, UserEntry, UsersList, UserWrapper } from './styled'

interface Props {
  value: USER_ID[]
  setOpen: (open: boolean) => void
  onSave: (value: USER_ID[]) => void
  target: HTMLElement
  isOpen: boolean
  align?: 'left' | 'right'
}

export const UsersSelector: React.FC<Props> = ({
  value,
  setOpen,
  onSave,
  target,
  isOpen,
  align = 'left',
}) => {
  const [searchQuery, setSearchQuery] = React.useState('')
  const inputRef = React.useRef<HTMLInputElement>()
  const { users, allUsers } = useContainer(UsersContainer).state

  const addUser = React.useCallback(
    (user: USER_ID) => {
      onSave([...value, user])
      setSearchQuery('')
      inputRef.current?.focus()
    },
    [onSave, value],
  )

  const handleRemoveUser = React.useCallback(
    (user: USER_ID) => {
      onSave(value.filter(e => e !== user))
      setSearchQuery('')
    },
    [onSave, value],
  )

  const availableUsersOptions = React.useMemo(() => {
    const names = users.map(readableName)

    return users
      .filter(u => !value.some(su => su === u.id))
      .filter(
        u => !searchQuery || fuzzySearch(names, searchQuery, { includeValue: readableName(u) }),
      )
  }, [value, users, searchQuery])

  const handlePressKey = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.nativeEvent.key) {
        case 'Enter':
          availableUsersOptions.length === 1 && addUser(availableUsersOptions[0].id)
          break
        case 'Backspace':
          value.length > 0 && searchQuery.length === 0 && handleRemoveUser([...value].pop())
          break
        default:
          return
      }
    },
    [availableUsersOptions, searchQuery, users],
  )

  return (
    <SafeWrapper target={target} align={align} show={isOpen} onClose={() => setOpen(false)}>
      <Div width={288}>
        <SelectionBox onClick={e => e.stopPropagation()}>
          <Div
            background={colors.lightGray3}
            px={spacing(1.5)}
            width={1}
            minHeight={40}
            position="relative"
          >
            <UsersList>
              {value.map(e =>
                box(allUsers.find(u => u.id === e)).fold(
                  user =>
                    user && (
                      <UserEntry key={user.id}>
                        <PersonChip name={readableName(user)} color={user.color} />
                        <Button.Transparent
                          data-testid="user-close"
                          onClick={() => handleRemoveUser(user.id)}
                          size={16}
                          postIcon="Close"
                        />
                      </UserEntry>
                    ),
                ),
              )}
              <UserEntry>
                <Input
                  ref={inputRef}
                  style={{ height: '100%', width: '100%' }}
                  onChange={e => setSearchQuery(e.target.value)}
                  onKeyDown={handlePressKey}
                  value={searchQuery}
                  autoFocus
                />
              </UserEntry>
            </UsersList>
          </Div>
          <Div py={spacing(1)} width={1} maxHeight={224} overflow="auto">
            {availableUsersOptions.map(user => (
              <UserWrapper
                onClick={e => {
                  e.stopPropagation()
                  addUser(user.id)
                }}
                key={user.id}
              >
                <PersonChip name={readableName(user)} color={user.color} />
                <Div ml="auto">
                  <Button.Text>+ Add</Button.Text>
                </Div>
              </UserWrapper>
            ))}
          </Div>
        </SelectionBox>
      </Div>
    </SafeWrapper>
  )
}
