import { retryLazyLoad } from '@basisboard/basis-ui/es/utils'
import { openModal } from '../../Modal'
import { ManageUserProps } from './ManageUser/types'

export const openManageUserModal = (props: ManageUserProps) =>
  openModal({
    Component: retryLazyLoad(() => import('./ManageUser')),
    width: 576,
    name: 'ManageUser',
    props,
  })
