import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { LoadingIndicator } from '@basisboard/basis-ui/es/components/LoadingIndicator'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors } from '@basisboard/basis-ui/es/styles/colors'
import { spacing } from '@basisboard/basis-ui/es/styles/spacing'
import * as React from 'react'
import { SpaceProps } from 'styled-system'
import { useDebounce } from 'use-debounce'
import { TextInput } from '../../Form'
import { Container, List, ListItem } from './styled'

interface Props<T> {
  placeholder: string
  value: string
  renderListItem: (data: T) => React.ReactNode
  renderCreateItem: (searchTerm: string) => React.ReactNode
  onSelect: (selection: T) => void
  onChangeSearch?: (searchTerm: string) => void
  onCreate: (searchTerm: string) => void
  fetchData: (searchTerm: string) => Promise<T[]>
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  disabled?: boolean
  name?: string
  preventCreatingNew?: boolean
  onBlur?: () => void
  formatNewValue?: (val: string) => T
  emptyError?: string
  space?: SpaceProps
}

export const SearchInput = <T extends { id?: string }>({
  placeholder,
  value,
  renderListItem,
  renderCreateItem,
  onSelect,
  onCreate,
  fetchData,
  prefix,
  suffix,
  disabled,
  onChangeSearch,
  preventCreatingNew,
  formatNewValue,
  onBlur,
  emptyError,
  space,
}: Props<T>) => {
  const [searchTerm, setSearchTerm] = React.useState(value)
  const [showList, setShowList] = React.useState(false)
  const [timeoutId, setTimeoutId] = React.useState(0)
  const [data, setData] = React.useState<T[]>([])
  const [loadingData, setLoadingData] = React.useState(false)
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300)

  React.useEffect(() => {
    setLoadingData(true)
    fetchData(debouncedSearchTerm).then(data => {
      setData(data)
      setLoadingData(false)
    })
  }, [debouncedSearchTerm])

  React.useEffect(() => {
    setSearchTerm(value)
  }, [value])

  const handleChangeSearch = React.useCallback(search => {
    setSearchTerm(search)
    onChangeSearch && onChangeSearch(search)
  }, [])

  return (
    <Container
      onFocus={() => {
        clearTimeout(timeoutId)
        setShowList(true)
      }}
      onBlur={() => {
        setTimeoutId(
          window.setTimeout(() => {
            handleChangeSearch(value)
            setShowList(false)

            onBlur?.()
            if (searchTerm !== value && formatNewValue) {
              onSelect(formatNewValue(searchTerm))
            }
          }),
        )
      }}
    >
      <TextInput
        value={searchTerm}
        placeholder={placeholder}
        symbol={prefix}
        symbolBackgroundColor="transparent"
        suffix={suffix}
        disabled={disabled}
        onChange={e => {
          handleChangeSearch(e.target.value)
        }}
        required={emptyError?.length > 0}
        space={space}
      />
      {emptyError && !searchTerm && (
        <Div backgroundColor={colors.white} zIndex="1" mt={spacing(-0.5)}>
          <Div
            border={`1px solid ${colors.radicalRed}`}
            backgroundColor="#ff214d33"
            borderBottomLeftRadius="4px"
            borderBottomRightRadius="4px"
            display="flex"
            alignItems="center"
            py={spacing(1)}
            px={spacing(2)}
          >
            <Icon.Exclamation color={colors.radicalRed} />
            <Text color={colors.radicalRed}>{emptyError}</Text>
          </Div>
        </Div>
      )}
      {showList && (data?.length > 0 || searchTerm?.length > 0) && (
        <List>
          {loadingData && (
            <ListItem>
              <LoadingIndicator />
            </ListItem>
          )}
          {(data || []).map(d => (
            <ListItem
              data-testid="search-item"
              key={d.id}
              tabIndex={0}
              onClick={e => {
                e.stopPropagation()
                onSelect(d)
                setShowList(false)
              }}
            >
              {renderListItem(d)}
            </ListItem>
          ))}
          {searchTerm?.length > 0 && !preventCreatingNew && (
            <ListItem
              data-testid="search-new-term"
              tabIndex={0}
              onClick={e => {
                e.stopPropagation()
                onCreate(searchTerm)
                setShowList(false)
              }}
            >
              {renderCreateItem(searchTerm)}
            </ListItem>
          )}
        </List>
      )}
    </Container>
  )
}
