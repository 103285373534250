import { UserRole } from '@basisboard/basis-common/lib/api'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { getContainer } from '@containrz/react-hook'
import { Link } from 'react-router-dom'
import { Dropdown } from '../../../../components/Dropdown'
import { PersonChip } from '../../../../components/PersonChip'
import { eventBus, EventBusType } from '../../../../services'
import { signOut, whoami } from '../../../App/container'
import { UsersContainer } from '../../../Users'
import { Menu, MenuEntry, ProfileButton, Wrapper } from './styled'

const Profile = () => {
  if (!whoami()) {
    return null
  }

  const { name, id, role } = whoami()
  const color = (getContainer(UsersContainer).state.allUsers.find(u => u.id === id) || {}).color

  const isAdmin = role === UserRole.Admin

  return (
    <Wrapper>
      <Dropdown
        align="left"
        renderTrigger={onOpen => (
          <ProfileButton
            onClick={() => {
              onOpen()
              eventBus.publish(EventBusType.ShowDropdown, 'profile')
            }}
            data-testid="profile"
          >
            <PersonChip hideTooltip chipOnly name={name} color={color} />
            <Text mr={spacing(2)}>{name}</Text>
            <Icon.Arrow color={colors.gray} />
          </ProfileButton>
        )}
      >
        {onClose => (
          <Menu>
            <MenuEntry>
              <Link to="/settings/my-profile" onClick={onClose}>
                <Text textAlign="left">My profile</Text>
              </Link>
            </MenuEntry>
            <MenuEntry>
              <Link to="/settings/notifications" onClick={onClose}>
                <Text textAlign="left">Notification settings</Text>
              </Link>
            </MenuEntry>
            {isAdmin && (
              <>
                <MenuEntry>
                  <Link to="/settings/customize-fields/bid-details" onClick={onClose}>
                    <Text textAlign="left">Company settings</Text>
                  </Link>
                </MenuEntry>
                <MenuEntry>
                  <Link to="/settings/manage-users" onClick={onClose}>
                    <Text textAlign="left">Manage users</Text>
                  </Link>
                </MenuEntry>
              </>
            )}
            <MenuEntry>
              <Text
                p={0}
                m={0}
                textAlign="left"
                as="button"
                data-testid="logout"
                onClick={() => {
                  signOut()
                  onClose()
                }}
              >
                Log out
              </Text>
            </MenuEntry>
          </Menu>
        )}
      </Dropdown>
    </Wrapper>
  )
}

export default Profile
