import * as React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { Empty } from '../../components'

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

interface Props {
  title?: string
  description?: string
  action?: string
  onAction?: () => void
  imgSrc?: string
  width?: number
}

export const ErrorPage: React.FC<Props> = ({
  title,
  description,
  action,
  onAction,
  width,
  imgSrc,
}) => {
  const history = useHistory()

  return (
    <Wrapper>
      <Empty
        title={title || 'Oops!'}
        description={description || 'This is our fault. Try refreshing your browser.'}
        action={action || 'Go back to previous page'}
        onAction={onAction || (() => history.goBack())}
        image={
          <img
            style={{ width: width || 149 }}
            src={imgSrc || '/imgs/empty/no-results.png'}
            alt="No results illustration"
          />
        }
      />
    </Wrapper>
  )
}
