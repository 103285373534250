import {
  borderMixin,
  borderRadiusMixin,
  colors,
  spacing,
  transitionMixin,
} from '@basisboard/basis-ui/es/styles'
import times from 'ramda/src/times'
import styled, { css } from 'styled-components'
import { space, SpaceProps } from 'styled-system'

export const Wrapper = styled.div.attrs({
  tabIndex: 0,
})<{ focused?: boolean; hideBorders?: boolean; disabled?: boolean; size: 'medium' | 'small' }>`
  box-sizing: border-box;

  min-height: ${({ size }) => (size === 'small' ? '32px' : '40px')};

  ${borderRadiusMixin};
  ${borderMixin('default')};
  position: relative;
  padding: ${spacing(0.5)} ${spacing(2)};
  width: 100%;

  background: ${colors.white};

  display: flex;
  align-items: center;

  outline: 0;

  ${transitionMixin};

  ${({ hideBorders }) =>
    hideBorders &&
    css`
      border: none;
      background: transparent;
      padding: ${spacing(0.5)} 0;
      padding-right: ${spacing(2)};
    `}

  ${({ focused, hideBorders }) =>
    focused &&
    !hideBorders &&
    css`
      border-color: ${colors.accent};
    `}

  ${({ disabled }) =>
    disabled
      ? css`
          background: ${colors.lightGray3};
          cursor: not-allowed;
        `
      : css`
          &:hover {
            ${borderMixin('hover')};
            border-color: ${colors.accent};
          }
        `}
`

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`

export const ListEntry = styled.li<{ selected?: boolean } & SpaceProps>`
  height: 40px;
  width: 100%;
  padding: 0 ${spacing(2)};
  background: ${colors.white};

  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${transitionMixin};

  &:hover {
    background: ${colors.lightGray3};
  }

  ${({ selected }) =>
    selected &&
    css`
      background: ${colors.lightGray3};
    `}

  ${space};
`

export const MultiSelectList = styled(List)`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  flex-wrap: wrap;
`

export const MultiSelectEntry = styled.li<{ hasCustomRender?: boolean }>`
  position: relative;
  display: inline-block;
  margin: ${spacing(0.5)} 0;

  ${({ hasCustomRender }) =>
    !hasCustomRender &&
    css`
      padding-right: ${spacing(2)};
      padding-left: ${spacing(1)};
      ${borderRadiusMixin};
      height: 24px;
      line-height: 24px;
      margin-right: ${spacing(1)};

      ${times(
        index => css`
          &:nth-child(${(index % colors.STAGES.length) + 1}) {
            background-color: ${colors.STAGES[index]}33;

            span {
              color: ${colors.STAGES[index]};
            }
          }
        `,
        colors.STAGES.length,
      )}
    `}
`
