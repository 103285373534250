"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Forward = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Forward = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 14 14" },
    React.createElement("path", { d: "M7.007 7.38786L3.90804 10.4868C3.69385 10.701 3.34749 10.701 3.13558 10.4868L2.62061 9.97184C2.40641 9.75765 2.40641 9.4113 2.62061 9.19938L4.81722 7.00277L2.62061 4.80615C2.40641 4.59196 2.40641 4.24561 2.62061 4.03369L3.1333 3.51416C3.34749 3.29997 3.69385 3.29997 3.90576 3.51416L7.00472 6.61312C7.22119 6.82731 7.22119 7.17367 7.007 7.38786ZM11.382 6.61312L8.28304 3.51416C8.06885 3.29997 7.72249 3.29997 7.51058 3.51416L6.99561 4.02913C6.78141 4.24333 6.78141 4.58968 6.99561 4.80159L9.19222 6.99821L6.99561 9.19482C6.78141 9.40902 6.78141 9.75537 6.99561 9.96729L7.51058 10.4823C7.72477 10.6965 8.07113 10.6965 8.28304 10.4823L11.382 7.3833C11.5962 7.17367 11.5962 6.82731 11.382 6.61312Z", fill: color })));
exports.Forward = Forward;
