import { GetProfileResponse } from '@basisboard/basis-common/lib/api'
import { identifyHeap } from './heap'
import { bootIntercom } from './intercom'
import { bootSentry } from './sentry'
import { identifySmartlook } from './smartlook'

export * from './eventBus'
export * from './experiments'
export * from './intercom'

export const initServices = (profile: GetProfileResponse) => {
  bootIntercom(profile)
  bootSentry(profile)

  identifyHeap(profile)
  identifySmartlook(profile)
}
