"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Envelope = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Envelope = ({ width = 14, height = 14, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 14 14" },
    React.createElement("path", { d: "M5.18058 6.1018H8.8193C9.02034 6.1018 9.18318 5.93897 9.18318 5.73793V5.37406C9.18318 5.17302 9.02034 5.01019 8.8193 5.01019H5.18058C4.97954 5.01019 4.8167 5.17302 4.8167 5.37406V5.73793C4.8167 5.93897 4.97954 6.1018 5.18058 6.1018ZM4.8167 7.92117C4.8167 8.12221 4.97954 8.28504 5.18058 8.28504H8.8193C9.02034 8.28504 9.18318 8.12221 9.18318 7.92117V7.5573C9.18318 7.35626 9.02034 7.19342 8.8193 7.19342H5.18058C4.97954 7.19342 4.8167 7.35626 4.8167 7.5573V7.92117ZM6.99994 10.6759C6.62652 10.6759 6.25309 10.5608 5.93425 10.3305L1.17798 6.89459V11.7418C1.17798 12.3447 1.6667 12.8334 2.2696 12.8334H11.7303C12.3332 12.8334 12.8219 12.3447 12.8219 11.7418V6.89459L8.06563 10.3305C7.74679 10.5606 7.37336 10.6759 6.99994 10.6759ZM12.4037 4.89534C12.2024 4.73751 12.0116 4.589 11.7303 4.37659V3.37276C11.7303 2.76987 11.2416 2.28114 10.6387 2.28114H8.87502C8.80589 2.23111 8.74153 2.18426 8.66943 2.13195C8.28714 1.85291 7.52756 1.18156 6.99994 1.18952C6.47233 1.18156 5.71297 1.85291 5.33045 2.13195C5.25836 2.18426 5.19399 2.23111 5.12486 2.28114H3.36121C2.75832 2.28114 2.2696 2.76987 2.2696 3.37276V4.37659C1.98828 4.58878 1.79747 4.73751 1.5962 4.89534C1.33224 5.10221 1.17802 5.41894 1.17798 5.75431V5.99651L3.36121 7.57367V3.37276H10.6387V7.57367L12.8219 5.99651V5.75431C12.8219 5.41909 12.6677 5.10229 12.4037 4.89534Z", fill: color })));
exports.Envelope = Envelope;
