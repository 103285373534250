import { EllipsisOverflow } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import styled from 'styled-components'
import { height, HeightProps, width, WidthProps } from 'styled-system'

export const Table = styled.table<HeightProps>`
  width: 100%;
  table-layout: fixed;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;

  ${height};
`

export const Th = styled.th<WidthProps>`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 24px;
  color: ${colors.gray};

  text-align: left;

  ${width};
`

export const ActionsWrapper = styled.div`
  margin-right: ${spacing(1)};
  display: flex;
  justify-content: flex-end;

  opacity: 0;
  ${transitionMixin};
`

const HEIGHT_MAP = {
  small: 64,
  medium: 72,
  large: 88,
}

export const BodyRow = styled.tr<{ onClick?: () => void; size?: 'small' | 'medium' | 'large' }>`
  ${({ onClick }) => onClick && 'cursor: pointer'};

  border-right-width: ${spacing(3)};
  border-left-width: ${spacing(3)};
  border-color: transparent;

  padding: ${spacing(3)};

  width: 100%;
  height: ${({ size }) => HEIGHT_MAP[size]}px;
  max-height: ${({ size }) => HEIGHT_MAP[size]}px;
  overflow: hidden;
  ${transitionMixin};

  &:hover {
    background: #f9fafb;

    ${ActionsWrapper} {
      opacity: 1;
    }
  }

  &:not(:last-child) {
    td {
      box-shadow: inset 0px -1px 0px #eaebee;
    }
  }
`

export const Td = styled.td`
  padding-right: ${spacing(2)};
  font-weight: 500;
  color: ${colors.darkBlue};
  font-size: 0.875rem;

  vertical-align: middle;

  ${EllipsisOverflow};

  &:first-child {
    color: ${colors.mediumGray};
  }
`
