import { retryLazyLoad } from '@basisboard/basis-ui/es/utils'
import { openModal } from '../../Modal'
import { ConfirmActionModalProps } from './ConfirmAction/types'

export const openConfirmActionModal = (props: ConfirmActionModalProps) =>
  openModal({
    Component: retryLazyLoad(() => import('./ConfirmAction')),
    name: 'ConfirmAction',
    width: 446,
    props,
  })
