"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobWalk = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const JobWalk = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M4.66634 13.8327H11.333V3.41602C11.333 2.72591 10.7731 2.16602 10.083 2.16602H5.91634C5.22624 2.16602 4.66634 2.72591 4.66634 3.41602V13.8327ZM6.33301 3.83268H9.66634V4.66602H6.33301V3.83268ZM14.6663 5.91602V12.5827C14.6663 13.2728 14.1064 13.8327 13.4163 13.8327H12.1663V4.66602H13.4163C14.1064 4.66602 14.6663 5.22591 14.6663 5.91602ZM3.83301 13.8327H2.58301C1.8929 13.8327 1.33301 13.2728 1.33301 12.5827V5.91602C1.33301 5.22591 1.8929 4.66602 2.58301 4.66602H3.83301V13.8327Z", fill: color })));
exports.JobWalk = JobWalk;
