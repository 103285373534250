import { getUniqId } from '@basisboard/basis-ui/es/utils'
import { Container, getContainer } from '@containrz/react-hook'
import keys from 'ramda/src/keys'
import { SECONDS } from '../../constants'
import { eventBus, EventBusType } from '../../services'
import { ToastInterface, ToastType } from './types'

interface State {
  toasts: { [key: string]: ToastInterface }
}

export class ToastContainer extends Container<State> {
  state = {
    toasts: {},
  }

  timers = {}

  destroy = () => {
    keys(this.timers).forEach(key => {
      clearTimeout(this.timers[key])
    })
  }

  private setTimers = (id: string, duration = 5 * SECONDS) => {
    this.timers = {
      ...this.timers,
      [id]: window.setTimeout(() => {
        this.hideToast(id)
        this.timers = keys(this.timers)
          .filter(k => k !== id)
          .reduce((acc, k) => ({ ...acc, [k]: this.timers[k] }), {})
      }, duration || 5 * SECONDS),
    }
  }

  showToast = (toast: ToastInterface) => {
    const id = getUniqId()

    eventBus.publish(EventBusType.ShowToast, toast)

    this.setTimers(id, toast.duration)

    this.setState(s => ({
      toasts: { ...s.toasts, [id]: toast },
    }))

    return id
  }

  stopTimer = (toastId: string) => clearTimeout(this.timers[toastId])

  resetTimer = (toastId: string) =>
    this.state.toasts[toastId] && this.setTimers(toastId, this.state.toasts[toastId].duration)

  hideToast = (toastId: string) => {
    this.setState(s => ({
      toasts: keys(s.toasts)
        .filter(k => k !== toastId)
        .reduce((acc, k) => ({ ...acc, [k]: s.toasts[k] }), {}),
    }))
  }
}

export const toastContainer = () => getContainer(ToastContainer)

type ShowSpecificToast = (toast: Omit<ToastInterface, 'type'>) => void

export const showErrorToast: ShowSpecificToast = toast =>
  toastContainer().showToast({ ...toast, type: ToastType.Error })

export const showSuccessToast: ShowSpecificToast = toast =>
  toastContainer().showToast({ ...toast, type: ToastType.Success })

export const showWarningToast: ShowSpecificToast = toast =>
  toastContainer().showToast({ ...toast, type: ToastType.Warning })

export const showInfoToast: ShowSpecificToast = toast =>
  toastContainer().showToast({ ...toast, type: ToastType.Info })
