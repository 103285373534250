import { spacing } from '@basisboard/basis-ui/es/styles'
import styled from 'styled-components'

export const Header = styled.div`
  margin: 0 ${spacing(-4)};
  padding: 0 ${spacing(4)};
  height: 40px;
  position: relative;
  border-bottom: 1px solid #eaebee;
`

export const Wrapper = styled.div`
  padding: ${spacing(1)} ${spacing(4)};
  height: 100%;
  flex: 1;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
`
