import { Label } from '@basisboard/basis-ui/es/components/Label'
import { borderRadiusMixin, colors, hexToRgba, spacing } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

interface Props {
  label?: string
  defaultValue?: number
  required?: boolean
  value: number
  name?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  disabled?: boolean
  space?: SpaceProps
  placeholder?: string
}

const Input = styled.input`
  background: ${colors.white};
  box-sizing: border-box;
  padding: 0 ${spacing(2)};
  border: none;
  width: 100%;
  height: 100%;

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: ${hexToRgba(colors.gray, 0.5)};
  }

  &:disabled::placeholder {
    color: transparent;
  }
`

const Wrapper = styled.div<SpaceProps>`
  margin-bottom: ${spacing(1)};
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid #dadce3;
  ${borderRadiusMixin};
  overflow: hidden;

  ${space};
`

const Symbol = styled.div`
  height: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: ${colors.gray};
  background-color: ${colors.lightGray3};
`

export const Money = React.forwardRef<HTMLInputElement, Props>(
  ({ label, space, placeholder, ...props }, ref) => {
    return (
      <>
        {label && <Label required={props.required} label={label} />}
        <Wrapper {...space}>
          <Symbol>$</Symbol>
          <Input placeholder={placeholder} ref={ref} {...(props as any)} type="number" />
        </Wrapper>
      </>
    )
  },
)
