"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExternalLink = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const ExternalLink = ({ width = 16, height = 16, size, color = styles_1.colors.accent, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M14.6673 2.62977V5.59237C14.6673 6.08915 14.0664 6.33264 13.7189 5.98521L12.8924 5.15862L7.25526 10.7957C7.03829 11.0127 6.68655 11.0127 6.46959 10.7957L5.94581 10.2719C5.72885 10.055 5.72885 9.70322 5.94581 9.48628L11.583 3.84913L10.7565 3.02262C10.4076 2.67376 10.6547 2.07422 11.1494 2.07422H14.1118C14.4186 2.07422 14.6673 2.32295 14.6673 2.62977ZM10.756 8.3426L10.3856 8.71297C10.2814 8.81716 10.2229 8.95847 10.2229 9.10582V12.4446H2.81547V5.03718H8.92658C9.07391 5.03717 9.21521 4.97865 9.3194 4.87447L9.68977 4.5041C10.0397 4.1541 9.79188 3.5557 9.29695 3.5557H2.4451C1.83144 3.5557 1.33398 4.05315 1.33398 4.66681V12.815C1.33398 13.4286 1.83144 13.9261 2.4451 13.9261H10.5932C11.2069 13.9261 11.7044 13.4286 11.7044 12.815V8.73542C11.7044 8.24047 11.1059 7.9926 10.756 8.3426Z", fill: color })));
exports.ExternalLink = ExternalLink;
