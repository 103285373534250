"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BullsEye = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const BullsEye = ({ width = 24, height = 24, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 24 24" },
    React.createElement("path", { d: "M12 2.3125C6.64961 2.3125 2.3125 6.64961 2.3125 12C2.3125 17.3504 6.64961 21.6875 12 21.6875C17.3504 21.6875 21.6875 17.3504 21.6875 12C21.6875 6.64961 17.3504 2.3125 12 2.3125ZM12 19.1875C8.02773 19.1875 4.8125 15.973 4.8125 12C4.8125 8.02773 8.02695 4.8125 12 4.8125C15.9723 4.8125 19.1875 8.02695 19.1875 12C19.1875 15.9723 15.973 19.1875 12 19.1875ZM12 7C9.23867 7 7 9.23867 7 12C7 14.7613 9.23867 17 12 17C14.7613 17 17 14.7613 17 12C17 9.23867 14.7613 7 12 7ZM12 14.5C10.6215 14.5 9.5 13.3785 9.5 12C9.5 10.6215 10.6215 9.5 12 9.5C13.3785 9.5 14.5 10.6215 14.5 12C14.5 13.3785 13.3785 14.5 12 14.5Z", fill: color })));
exports.BullsEye = BullsEye;
