import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Div, DivProps } from '@basisboard/basis-ui/es/components/Div'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, DEFAULT_BORDER_RADIUS, spacing } from '@basisboard/basis-ui/es/styles'
import { fromBoolean } from '@basisboard/basis-ui/es/utils'
import * as React from 'react'

interface Props extends DivProps {
  text: string
  textStyle?: React.CSSProperties
  isHtml?: boolean
  action?: {
    label: string
    onAction: () => void
  }
}

export const EmptyBox: React.FC<Props> = ({ text, action, isHtml, textStyle, ...rest }) => (
  <Div
    borderRadius={DEFAULT_BORDER_RADIUS}
    width={1}
    p={spacing(2)}
    background={colors.lightGray3}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    {...rest}
  >
    <Icon.Exclamation />
    {fromBoolean(isHtml).fold(
      () => (
        <Text textAlign="center" width={1} style={textStyle} mt={spacing(1)} preventClip>
          {text}
        </Text>
      ),
      () => (
        <Text
          textAlign="center"
          width={1}
          style={textStyle}
          mt={spacing(1)}
          preventClip
          dangerouslySetInnerHTML={{ __html: text }}
        />
      ),
    )}
    {action && (
      <Button.Gradient type="button" mt={spacing(2)} onClick={action.onAction} preIcon="Plus">
        {action.label}
      </Button.Gradient>
    )}
  </Div>
)
