"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mediaBreakpointUpAndDown = exports.mediaBreakpointDown = exports.mediaBreakpointUp = exports.BreakpointType = void 0;
exports.BreakpointType = {
    small: 576,
    medium: 768,
    large: 992,
    xlarge: 1200,
    xxlarge: 1400,
    xxxlarge: 1680,
};
const mediaBreakpointUp = (bp) => `@media (min-width: ${exports.BreakpointType[bp]}px)`;
exports.mediaBreakpointUp = mediaBreakpointUp;
const mediaBreakpointDown = (bp) => `@media (max-width: ${exports.BreakpointType[bp] - 1}px)`;
exports.mediaBreakpointDown = mediaBreakpointDown;
const mediaBreakpointUpAndDown = (up, down) => `@media (min-width: ${exports.BreakpointType[up]}px) and (max-width: ${exports.BreakpointType[down] - 1}px)`;
exports.mediaBreakpointUpAndDown = mediaBreakpointUpAndDown;
