import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Tooltip } from '@basisboard/basis-ui/es/components/Tooltip'
import { borderRadiusMixin, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { NestedProject } from '../../../../../../types'
import { KeywordTag } from '../../../../../Files/components/Keywords/components/KeywordTag'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { tagColorsVariants } from '../../../../../Files/components/Keywords/components/KeywordTag/styled'

interface Props {
  keywords: NestedProject['keywords']
}

const BaseTag = css`
  height: 24px;
  ${borderRadiusMixin};
  background: ${tagColorsVariants.mainAttachment.default.countBackground};
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
  align-self: flex-start;

  ${transitionMixin};

  position: relative;
  overflow: hidden;

  ${space};

  &:hover {
    p {
      color: ${tagColorsVariants.mainAttachment.hover.countText};
      ${transitionMixin};
    }
    background-color: ${tagColorsVariants.mainAttachment.hover.countBackground};
  }
`
const ShowMoreTag = styled.div`
  ${BaseTag};

  padding: 0 ${spacing(0.5)};
  text-align: center;
  cursor: pointer;
  ${transitionMixin};
`

const renderKeywordEntry = ([key, value]: [string, number]) => (
  <KeywordTag key={key} label={key} count={value} isMain />
)

export const Keywords: React.FC<Props> = ({ keywords }) => {
  // sort keywords by count
  const keywordsEntries = Object.entries(keywords).sort((a, b) => (a[1] > b[1] ? -1 : 1))

  return keywordsEntries.length ? (
    <Div display="inline-flex">
      {renderKeywordEntry(keywordsEntries[0])}
      {keywordsEntries.length > 1 && (
        <Div>
          <Tooltip
            wrapProps={{ height: 24 }}
            variant="light"
            position="top"
            content={
              <Div display="flex" flexDirection="column" justifyContent="center">
                {keywordsEntries.slice(1).map(renderKeywordEntry)}
              </Div>
            }
          >
            <ShowMoreTag data-testid="show-more" onClick={e => e.stopPropagation()}>
              <Text color={tagColorsVariants.mainAttachment.default.countText}>
                +{keywordsEntries.length - 1}
              </Text>
            </ShowMoreTag>
          </Tooltip>
        </Div>
      )}
    </Div>
  ) : (
    <Text textAlign="center">{'N/A'}</Text>
  )
}
