"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Decline = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Decline = ({ width = 24, height = 24, size, color = styles_1.colors.blueGrayBorder, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 576 512" },
    React.createElement("path", { fill: color, d: "M180.5 102.5l99-99c4.7-4.7 12.3-4.7 17 0l99 99c7.6 7.6 2.2 20.5-8.5 20.5h-67v153c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12V123h-67c-10.7 0-16.1-12.9-8.5-20.5zM576 354.2V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V354.2c0-11.8 4.3-23.1 12.1-31.9l101.6-114.2c9.1-10.2 22.2-16.1 35.9-16.1H202c3.3 0 6 2.7 6 6v36c0 3.3-2.7 6-6 6h-52.4l-56.9 64h105l32 64h116.7l32-64h105l-56.9-64H374c-3.3 0-6-2.7-6-6v-36c0-3.3 2.7-6 6-6h52.4c13.7 0 26.8 5.9 35.9 16.1l101.6 114.2c7.8 8.8 12.1 20.2 12.1 31.9zm-48-2.2H408l-32 64H200l-32-64H48v112h480V352z" })));
exports.Decline = Decline;
