import {
  CompanyGetMergeSuggestionsResponseBody,
  CompanyRejectMergeSuggestionsRequestBody,
  COMPANY_ID,
  GetCompaniesRequestQuery,
  GetCompaniesResponse,
  GetCompanyInsightsQuery,
  GetCompanyInsightsResponse,
  GetCompanyNamesResponse,
  GetGroupedCompaniesRequestQuery,
  GetGroupedCompaniesResponseBody,
  GetMergeSuggestionsRequestQuery,
  PatchCompanyRequest,
  PatchCompanyResponse,
  PostCompaniesRequestBody,
  PostCompaniesResponseBody,
  PostCompanyMergeRequestBody,
  PostCompanyMergeResponseBody,
} from '@basisboard/basis-common/lib/api'
import { stringify } from 'qs'
import { del, get, patch, post } from '../../api'

export const createCompany = (body: PostCompaniesRequestBody) =>
  post<PostCompaniesResponseBody>('/companies', body).then(response => response.data.company)

export const getCompanies = (query?: GetCompaniesRequestQuery) =>
  get<GetCompaniesResponse>(`/companies?${stringify(query)}`).then(response => response.data)

export const getCompanyIds = (query?: GetCompaniesRequestQuery) =>
  get<GetCompaniesResponse>(`/companies?${stringify(query)}/ids`).then(
    response => response.data.companies,
  )

export const patchCompany = (companyId: COMPANY_ID, key: keyof PatchCompanyRequest, value: any) =>
  patch<PatchCompanyResponse>(`/companies/${companyId}`, JSON.stringify({ [key]: value })).then(
    response => response.data.company,
  )

export const deleteCompany = (companyId: COMPANY_ID) => del(`/companies/${companyId}`)

export const mergeCompanies = (companyId: COMPANY_ID, data: PostCompanyMergeRequestBody) =>
  post<PostCompanyMergeResponseBody>(`/companies/${companyId}/merge`, data).then(
    response => response.data.company,
  )

export const getCompanyNames = () =>
  get<GetCompanyNamesResponse>('/companies/names').then(response => response.data.companies)

export const getInsights = (companyId: COMPANY_ID, query?: GetCompanyInsightsQuery) =>
  get<GetCompanyInsightsResponse>(`/companies/${companyId}/insights?${stringify(query)}`).then(
    response => response.data.insights,
  )

export const getMergeSuggestions = (query?: GetMergeSuggestionsRequestQuery) =>
  get<CompanyGetMergeSuggestionsResponseBody>(
    `/companies/merge-suggestions?${stringify(query)}`,
  ).then(response => response.data)

export const rejectMergeSuggestion = (body: CompanyRejectMergeSuggestionsRequestBody) =>
  post(`/companies/merge-suggestions/reject`, body)

export const getCompanyGroups = (params?: GetGroupedCompaniesRequestQuery) =>
  get<GetGroupedCompaniesResponseBody>(`/companies/group?${stringify(params)}`).then(
    response => response.data,
  )
