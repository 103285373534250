import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Label } from '@basisboard/basis-ui/es/components/Label'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import {
  borderRadiusMixin,
  colors,
  cssCircle,
  spacing,
  transitionMixin,
} from '@basisboard/basis-ui/es/styles'
import { darken } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components'
import { SpaceProps, width, WidthProps } from 'styled-system'

interface Option {
  label: string
  value: string
  disabled?: boolean
  actions?: React.ReactNode
  node?: React.ReactNode
}

export interface RadioGroupInputProps {
  label?: string
  initialValue?: string
  value?: string
  name: string
  placeholder?: string
  noStyle?: boolean
  wrapperStyle?: React.CSSProperties
  entryStyle?: React.CSSProperties
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  width?: number
  renderEntry?: (radio: React.ReactNode, option: Option) => React.ReactNode
  options: Omit<React.HTMLProps<HTMLInputElement>, 'value'> & Option[]
}

const Actions = styled.div`
  display: none;
  margin-left: auto;
`

const Wrapper = styled.div<SpaceProps & WidthProps>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &::after {
    content: '';
    ${width};
  }
`

const Input = styled.input.attrs({
  type: 'radio',
})`
  opacity: 0;
  position: absolute;
`

const Marker = styled.span<{ active: boolean; disabled?: boolean }>`
  ${cssCircle(16)};

  border: 2px solid ${colors.gray};
  background-color: ${colors.white};
  margin-right: ${spacing(1)};
  position: relative;

  ${transitionMixin};

  &::after {
    content: '';
    position: absolute;

    ${cssCircle(0)};

    ${transitionMixin};
    background-color: ${colors.accent};

    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${colors.lightGray3};
    `};

  ${({ active }) =>
    active &&
    css`
      border-color: ${colors.accent};

      &::after {
        ${cssCircle(8)};
      }
    `}
`

const Value = styled.label<WidthProps & { disabled: boolean }>`
  background-color: ${colors.lightGray3};
  ${borderRadiusMixin};
  margin-bottom: ${spacing(1)};
  padding: 0 ${spacing(2)};
  height: 40px;
  display: flex;
  align-items: center;

  cursor: pointer;

  ${transitionMixin};
  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
          background-color: ${colors.lightGray2};
          & > span {
            color: ${colors.darkGray};
          }
        `
      : css`
          &:hover {
            background-color: ${darken(0.05, colors.lightGray3)};

            ${Actions} {
              display: flex;
            }

            ${Marker} {
              border-color: ${colors.accent};
            }
          }
        `}

  ${width};
`

export const RadioGroup = React.forwardRef<HTMLInputElement, RadioGroupInputProps>(
  (
    {
      label,
      initialValue,
      name,
      onChange,
      options,
      width,
      noStyle,
      value,
      wrapperStyle,
      entryStyle,
      renderEntry,
    },
    ref,
  ) => {
    const [val, setVal] = React.useState(initialValue || value)

    const handleChange = e => {
      setVal(e.target.value)
      onChange?.(e)
    }

    React.useEffect(() => {
      setVal(value || initialValue)
    }, [value, initialValue])

    const wrap = (children, disabled?: boolean) =>
      noStyle ? (
        <Div
          as="label"
          display="flex"
          alignItems="center"
          mb={spacing(1)}
          width={1}
          style={entryStyle}
        >
          {children}
        </Div>
      ) : (
        <Value width={width || 1} disabled={disabled}>
          {children}
        </Value>
      )

    return (
      <>
        {label && <Label label={label} />}
        <Wrapper mb={spacing(noStyle ? 0 : 1)} width={width} style={wrapperStyle}>
          {options.map(({ actions, node, ...option }, index) => (
            <React.Fragment key={option.value}>
              {renderEntry
                ? renderEntry(
                    <Div
                      as="label"
                      display="flex"
                      alignItems="center"
                      mb={spacing(1)}
                      width={1}
                      style={entryStyle}
                    >
                      <Input
                        ref={index === 1 ? ref : undefined}
                        {...option}
                        name={name}
                        onChange={handleChange}
                        checked={option.value === val}
                      />
                      <Marker active={option.value === val} disabled={option.disabled} />
                    </Div>,
                    option,
                  )
                : wrap(
                    <>
                      <Input
                        ref={index === 1 ? ref : undefined}
                        {...option}
                        name={name}
                        onChange={handleChange}
                        checked={option.value === val}
                      />
                      <Marker active={option.value === val} disabled={option.disabled} />
                      <Text>{option.label}</Text>
                      <Div ml="auto" />
                      {actions && <Actions>{actions}</Actions>}
                      {node && <Div ml={spacing(2)}>{node}</Div>}
                    </>,
                    option.disabled,
                  )}
            </React.Fragment>
          ))}
        </Wrapper>
      </>
    )
  },
)
