"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalStyles = void 0;
const styled_components_1 = require("styled-components");
const styles_1 = require("../styles");
const constants_1 = require("../styles/constants");
exports.GlobalStyles = (0, styled_components_1.createGlobalStyle) `
  body, html {
    margin: 0;
    padding: 0;
  }

  body,html,#root {
    height: 100%;
    width: 100%;
  }

  * {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
  }

  .visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }

  .print-hidden {
    @media print {
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      margin: -1px !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
      white-space: nowrap !important;
      border: 0 !important;
    }
  }

  button, a {
    cursor: pointer;
  }

  button {
    background: transparent;
    border: none;
  }

  #${constants_1.MODAL_ID} {
    z-index: ${constants_1.ZIndexValues.Modal};
  }

  #${constants_1.DRAWER_ID} {
    z-index: ${constants_1.ZIndexValues.Drawer};
  }

  #${constants_1.MENU_ID} {
    z-index: ${constants_1.ZIndexValues.Menu};
  }

  button:not([disabled]):focus,
  [href]:not([disabled]):focus,
  input:not([disabled]):focus,
  select:not([disabled]):focus,
  textarea:not([disabled]):focus,
  [tabindex]:not([tabindex="-1"]):focus {
    outline: 1px dotted ${styles_1.colors.gray};
  }

  .hide-focus:focus {
    outline: none !important;
  }

  /* google Places AutoComplete */
  .pac-container{
    z-index: 10002;

    :after {
      background-image: none !important;
      height: 0px;
      margin: 0;
      padding: 0;
    }
  }

  .pac-item {
    font-family: inherit;
    font-size: 0.875rem;
    height: 32px;
    line-height: 32px;
  }
`;
