import { Div } from '@basisboard/basis-ui/es/components/Div'
import { GlobalStyles as UIGlobalStyles } from '@basisboard/basis-ui/es/components/GlobalStyles'
import { colors } from '@basisboard/basis-ui/es/styles'
import { isIE } from '@basisboard/basis-ui/es/utils'
import React from 'react'
import { hot } from 'react-hot-loader'
import { Router } from 'react-router-dom'
import { history, isDev } from '../../config'
import { eventBus, EventBusType } from '../../services'
import { GlobalStyles, HEADER_HEIGHT_PX } from '../../styles'
import { DrawerSystem } from '../Drawer'
import { Header } from '../Header'
import { ModalSystem } from '../Modal'
import { Toast } from '../Toast'
import { AdminBar } from './components/AdminBar'
import { ErrorBoundary } from './components/ErrorBoundary'
import { Routes } from './components/Routes'
import { appRepository, isBasisAdmin, whoami } from './container'

export * from './components'
export * from './container'
export * from './modals'

const App = () => {
  const [, forceRerender] = React.useState(false)

  React.useEffect(() => {
    // do not login for password reset flow
    if (window?.location?.pathname?.match(/reset-password\/.*/)) {
      return
    }

    appRepository()
      .loadProfile()
      .catch(() => forceRerender(f => !f))
  }, [])

  React.useEffect(() => {
    const onPrint = () => {
      eventBus.publish(EventBusType.Print, { page: window.location.pathname })
    }

    window.addEventListener('afterprint', onPrint)

    return () => {
      window.removeEventListener('afterprint', onPrint)
    }
  }, [])

  React.useEffect(() => {
    const { unregister: unregisterStarted } = eventBus.register(EventBusType.SessionStarted, () =>
      forceRerender(f => !f),
    )
    const { unregister: unregisterEnded } = eventBus.register(EventBusType.Logout, () =>
      forceRerender(f => !f),
    )

    return () => {
      unregisterStarted()
      unregisterEnded()
    }
  }, [])

  if (isIE()) {
    return (
      <div style={{ margin: '32px auto' }}>
        <img
          src="/imgs/empty/no-results.png"
          style={{ width: 149, position: 'relative', left: '50%', transform: 'translateX(-50%)' }}
          alt="Computer burning illustration"
        />
        <div style={{ fontSize: 14, color: colors.darkBlue, marginBottom: 8, textAlign: 'center' }}>
          Unfortunately, Basis Board depends on features that Internet Explorer does not support.
        </div>
        <div style={{ fontSize: 14, color: colors.darkBlue, textAlign: 'center' }}>
          Try using{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.google.com/chrome/?brand=CHBD&gclid=EAIaIQobChMIj_PmoZzF6QIVmNxRCh2DUAwTEAAYASAAEgK0afD_BwE&gclsrc=aw.ds"
          >
            Google Chrome
          </a>
          ,{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.mozilla.org/en-US/firefox/new/"
          >
            Firefox
          </a>
          ,{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.googleadservices.com/pagead/aclk?sa=L&ai=DChcSEwiQ0-e-nMXpAhWBjFEKHWsoAE4YABAAGgJ3cw&ohost=www.google.com&cid=CAASEuRoZF2KIjP7QsZgAjiA_XCedA&sig=AOD64_2K-7sKO0xf_UdsiACCa0UXpmMosA&q=&ved=2ahUKEwiwouG-nMXpAhVyCWMBHWwCD0MQ0Qx6BAgTEAE&adurl="
          >
            Opera
          </a>{' '}
          or other modern browser.
        </div>
      </div>
    )
  }

  return (
    <>
      <UIGlobalStyles />
      <GlobalStyles />
      <ErrorBoundary>
        <Router history={history}>
          {isBasisAdmin() && <AdminBar />}
          {Boolean(whoami()) && <Header />}
          {appRepository().state.headerSpace > 0 && (
            <Div height={appRepository().state.headerSpace} />
          )}
          <Div
            height={`calc(100vh - ${HEADER_HEIGHT_PX} ${
              appRepository().state.showAdminBanner ? '- 56px' : ''
            })`}
            width={1}
          >
            <Routes />
          </Div>

          <Toast.System />
          <ModalSystem />
          <DrawerSystem />
        </Router>
        {/* eslint-disable-next-line */}
        <a id="download-anchor" className="visually-hidden" aria-hidden="true" />
      </ErrorBoundary>
    </>
  )
}

export default isDev ? hot(module)(App) : App
