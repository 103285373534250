import { GetNotificationsRequestQuery, Notification } from '@basisboard/basis-common/lib/api'
import { box } from '@basisboard/basis-ui/es/utils'
import { Container } from '@containrz/react-hook'
import { SECONDS } from '../../constants'
import { eventBus, EventBusType } from '../../services'
import {
  getNotifications,
  getNotificationsCounter,
  markAllAsRead,
  markNotificationAsRead,
} from './api'

interface State {
  notifications: Notification[]
  unreadNotifications: Notification[]
  isOpen: boolean
  counter: number
  loading: boolean
  loadingMore: boolean
  done: boolean
  doneUnread: boolean
}

const initialState = {
  isOpen: false,
  notifications: [] as Notification[],
  unreadNotifications: [] as Notification[],
  counter: 0,
  loading: false,
  loadingMore: false,
  done: false,
  doneUnread: false,
}

export class NotificationsState extends Container<State> {
  state = initialState
  interval = null

  loadNotifications = (data?: GetNotificationsRequestQuery, loadingMore = false) => {
    this.setState({ loading: !loadingMore, loadingMore })

    getNotifications(data)
      .then(notifications =>
        this.setState(s => {
          const notificationsKey: keyof State = data?.showOnlyUnread
            ? 'unreadNotifications'
            : 'notifications'
          const doneKey: keyof State = data?.showOnlyUnread ? 'doneUnread' : 'done'

          if (loadingMore) {
            return {
              [notificationsKey]: [...s[notificationsKey], ...notifications],
              ...(notifications.length === 0 ? { [doneKey]: true } : {}),
              loading: false,
              loadingMore: false,
            }
          } else {
            return {
              [notificationsKey]: notifications,
              ...(notifications.length === 0 ? { [doneKey]: true } : {}),
              loading: false,
              loadingMore: false,
            }
          }
        }),
      )
      .finally(this.refresh)
  }

  refresh = () => {
    if (this.interval) {
      return
    }

    this.interval = setInterval(() => {
      getNotificationsCounter().then(counter => this.setState(() => ({ counter })))
    }, 20 * SECONDS)
  }

  loadMoreCallback = (data?: GetNotificationsRequestQuery) => () => {
    if (data?.showOnlyUnread ? this.state.doneUnread : this.state.done) {
      return
    }
    this.loadNotifications({ ...data, offset: this.state.notifications.length }, true)
  }

  destroy = () => clearInterval(this.interval)

  toggleOpen = () => {
    const { isOpen } = this.state
    if (!isOpen) {
      getNotifications().then(notifications => this.setState({ notifications }))
    }

    if (isOpen) {
      eventBus.publish(EventBusType.NotificationsClose)
    } else {
      eventBus.publish(EventBusType.NotificationsOpen)
    }

    this.setState({ isOpen: !isOpen })
  }

  markAsRead = (notificationId: string) =>
    markNotificationAsRead(notificationId).then(r =>
      this.setState(s => ({
        notifications: s.notifications.map(notification =>
          notification.id === r.data.notification.id ? r.data.notification : notification,
        ),
        ...box(s.unreadNotifications.filter(n => n.id !== notificationId)).fold(
          unreadNotifications => ({
            unreadNotifications,
            counter: unreadNotifications.length,
          }),
        ),
      })),
    )

  markAllAsRead = () => {
    this.setState(s => ({
      notifications: s.notifications.map(n => ({ ...n, read: true })),
      unreadNotifications: [],
    }))

    return markAllAsRead()
  }
}
