import {
  PatchPlatformCredentialsRequestBody,
  PlatformCredential,
  PostPlatformCredentialsRequestBody,
} from '@basisboard/basis-common/lib/api'
import { Platform } from '@basisboard/basis-common/lib/enums'
import { Container } from '@containrz/react-hook'
import { eventBus, EventBusType } from '../../services'
import { openConfirmActionModal } from '../App/modals'
import {
  deletePlatform,
  getMissingPlatforms,
  getPlatforms,
  patchPlatform,
  postPlatform,
} from './api'

interface State {
  loading: boolean
  platforms: PlatformCredential[]
  missingPlatforms: Platform[]
}

export class PlatformsContainer extends Container<State> {
  constructor() {
    super()

    this.loadMissingPlatforms()
    this.loadPlatforms()

    this.state = {
      loading: true,
      platforms: [],
      missingPlatforms: [],
    } as State
  }

  loadMissingPlatforms = () =>
    getMissingPlatforms().then(missingPlatforms => this.setState({ missingPlatforms }))

  loadPlatforms = () =>
    getPlatforms()
      .then(platforms => this.setState({ platforms, loading: false }))
      .catch(() => this.setState({ loading: false }))

  createPlatform = (
    platformData: PostPlatformCredentialsRequestBody,
    flowOrigin?: 'settings' | 'importer' | 'listBanner' | 'listEntry',
  ) =>
    postPlatform(platformData)
      .then(platform =>
        this.setState(s => ({
          platforms: [...s.platforms, platform],
          missingPlatforms: s.missingPlatforms.filter(p => p !== platform.platformId),
        })),
      )
      .finally(() =>
        eventBus.publish(EventBusType.SyncPlatform, {
          platform: platformData.platformId,
          flowOrigin,
        }),
      )

  updatePlatform = (platformId: string, platformData: PatchPlatformCredentialsRequestBody) => {
    const prevState = { ...this.state }

    this.setState(s => ({
      platforms: s.platforms.map(p => (p.id === platformId ? { ...p, ...platformData } : p)),
    }))

    return patchPlatform(platformId, platformData).catch(err => {
      this.setState(prevState)
      throw err
    })
  }

  removePlatform = (platformId: string) => {
    openConfirmActionModal({
      prompt: 'Are you sure you want to delete platform sync?',
      actionLabel: 'Delete platform',
      action: () => {
        const platform = this.state.platforms.find(p => p.id === platformId)
        this.setState(s => ({
          platforms: s.platforms.filter(p => p.id !== platformId),
        }))
        return deletePlatform(platformId).finally(() =>
          eventBus.publish(EventBusType.DeletePlatform, { platformId: platform.platformId }),
        )
      },
    })
  }
}
