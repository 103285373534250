"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Label = exports.Box = exports.Input = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const styled_system_1 = require("styled-system");
const styles_1 = require("../../styles");
const Typography_1 = require("../Typography");
exports.Input = styled_components_1.default.input.attrs({
    type: 'checkbox',
}) `
  display: none;
`;
exports.Box = styled_components_1.default.span `
  ${(0, styles_1.cssSize)(16)};
  ${styles_1.borderRadiusMixin};
  border: 2px solid ${styles_1.colors.gray};
  background-color: ${styles_1.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;
exports.Label = (0, styled_components_1.default)(Typography_1.HeavyText) `
  display: flex;
  align-items: center;
  color: ${styles_1.colors.gray};
  width: 100%;
  cursor: pointer;

  ${exports.Box} {
    ${({ disabled }) => disabled && 'opacity: 0.5'};

    svg {
      display: none;
    }
  }

  input[type='checkbox']:checked + ${exports.Box} {
    ${({ disabled, inverse }) => !disabled &&
    (0, styled_components_1.css) `
        border-color: ${styles_1.colors.accent};
        ${inverse && `background-color: ${styles_1.colors.accent}`};
      `};

    svg {
      display: block;
    }
  }

  ${styled_system_1.color};
`;
