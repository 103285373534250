import {
  CUSTOM_FIELD_SETTING_ID,
  ProjectSortV2,
  SortOptions,
} from '@basisboard/basis-common/lib/api'
import { SortDirection } from '@basisboard/basis-common/lib/enums'
import { fromNullable } from '@basisboard/basis-ui/es/utils'
import { Container } from '@containrz/react-hook'
import { ViewId } from '../../constants'
import { eventBus, EventBusType } from '../../services'
import { appRepository } from '../App'
import { updateAdvancedSortsForView, updateSortsForView } from './api'

interface State {
  sorts: ProjectSortV2 | null
  advancedSorts: ProjectSortV2[]
  error: boolean
  loading: boolean
}

export class SortsState extends Container<State> {
  viewId: ViewId

  constructor(viewId: ViewId) {
    super()

    const settings = fromNullable(appRepository().state.profile?.settings).fold(
      () => null,
      s => s,
    )

    this.state = {
      sorts: settings?.[`${viewId}-sort`]?.sorts || null,
      advancedSorts: settings?.[`${viewId}-advancedSorts`]?.sorts || [],
      error: false,
      loading: false,
    }
    this.viewId = viewId
  }

  initializeState = () => {
    const settings = fromNullable(appRepository().state.profile?.settings).fold(
      () => null,
      settings => settings,
    )

    this.setState({
      sorts: settings?.[`${this.viewId}-sort`]?.sorts || null,
      advancedSorts: settings?.[`${this.viewId}-advancedSorts`]?.sorts || [],
    })
  }

  destroy = () => {
    delete SortsStateInstances[this.viewId]
  }

  updateSorts = (sorts: ProjectSortV2) => {
    this.setState({ sorts })

    eventBus.publish(EventBusType.ApplySort, { sorts: sorts?.name || 'Cleared' })

    updateSortsForView(this.viewId, sorts)
  }

  changeSortDirection = (
    sortName: SortOptions | CUSTOM_FIELD_SETTING_ID,
    entity: 'project' | 'company' = 'project',
  ) => {
    const baseSort = this.state.advancedSorts.find(
      sort => sort.name === sortName && sort.entity === entity,
    )

    const sort = baseSort
      ? baseSort.direction === SortDirection.DESC
        ? null
        : { ...baseSort, direction: SortDirection.DESC }
      : { name: sortName, entity, direction: SortDirection.ASC }

    const advancedSorts = (this.state.advancedSorts.some(
      sort => sort.name === sortName && sort.entity === entity,
    )
      ? this.state.advancedSorts.map(s =>
          s.name === baseSort?.name && s.entity === baseSort?.entity ? sort : s,
        )
      : [...this.state.advancedSorts, sort]
    ).filter(s => Boolean(s))

    this.setState({ advancedSorts })

    eventBus.publish(EventBusType.ApplySortByColumn, {
      sorts: sort?.name || 'Cleared',
      direction: sort?.direction || null,
    })

    updateAdvancedSortsForView(this.viewId, advancedSorts)
  }

  applyAdvancedSorts = (advancedSorts: ProjectSortV2[]) => {
    this.setState({ advancedSorts, sorts: null })

    updateAdvancedSortsForView(this.viewId, advancedSorts)

    eventBus.publish(EventBusType.ApplySort, { configuration: advancedSorts })
  }

  clearAdvancedSorts = () => {
    this.setState({ advancedSorts: [] })

    updateAdvancedSortsForView(this.viewId, [])

    eventBus.publish(EventBusType.ClearSorts)
  }
}

export const sortsStateInstancesDict: { [key: string]: SortsState } = {}

export const SortsStateInstances = viewId => {
  if (sortsStateInstancesDict[viewId]) {
    return sortsStateInstancesDict[viewId] as SortsState
  } else {
    const SortsInstance = new SortsState(viewId)
    sortsStateInstancesDict[viewId] = SortsInstance

    return SortsInstance
  }
}
