import { Div } from '@basisboard/basis-ui/es/components/Div'
import { spacing } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import { ButtonText } from '../ButtonText'
import { Description, Title } from './styled'

interface Props {
  image: React.ReactElement
  title: string
  description: string
  action?: string
  onAction?(): void
}

export const Empty: React.FC<Props> = ({ image, title, description, action, onAction }) => {
  return (
    <Div
      alignItems="center"
      display="flex"
      flexDirection="column"
      width={1}
      height="100%"
      justifyContent="center"
    >
      {image}
      <Title>{title}</Title>
      <Description>{description}</Description>
      {action && onAction && (
        <ButtonText mt={spacing(3)} onClick={onAction}>
          {action}
        </ButtonText>
      )}
    </Div>
  )
}
