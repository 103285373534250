import { ScreenType } from '../../screens'
import { CalendarSubscriptionProps } from './screens/CalendarSubscription'
import { CreateCompanyModalProps } from './screens/CreateCompany'
import { CreateDistributionListModalProps } from './screens/CreateDistributionListModal'
import { CreateTaskModalProps } from './screens/CreateTask'
import { CreateTeamModalProps } from './screens/CreateTeam'
import { ExportListProps } from './screens/ExportList'

export enum ModalType {
  CalendarSubscription = 'CalendarSubscription',
  CreateCompany = 'CreateCompany',
  CreateDistributionList = 'CreateDistributionList',
  CreateTask = 'CreateTask',
  CreateTeam = 'CreateTeam',
  ExportList = 'ExportList',
}

export interface BaseModalProps {
  onClose?: () => void
  lastOpenAt?: number
}

interface CalendarSubscriptionInterface {
  type: ModalType.CalendarSubscription
  props?: CalendarSubscriptionProps
}

interface CreateCompanyInterface {
  type: ModalType.CreateCompany
  props: CreateCompanyModalProps
}

interface CreateDistributionListInterface {
  type: ModalType.CreateDistributionList
  props: CreateDistributionListModalProps
}

interface CreateTaskInterface {
  type: ModalType.CreateTask
  props: CreateTaskModalProps
}

interface CreateTeamInterface {
  type: ModalType.CreateTeam
  props: CreateTeamModalProps
}

export interface ComponentInterface<T = unknown> {
  width?: number | string
  minWidth?: number | string
  height?: number | string
  name: string
  Component: ScreenType['component']
  props?: T
  backgroundColor?: string
  hideCloseButton?: boolean
  hideScroll?: boolean
  stylesOverrides?: {
    [key: string]: string
  }
}

interface ExportListInterface {
  type: ModalType.ExportList
  props: ExportListProps
}

export type ModalInterfaces<T = unknown> =
  | CalendarSubscriptionInterface
  | CreateCompanyInterface
  | CreateDistributionListInterface
  | CreateTaskInterface
  | CreateTeamInterface
  | ComponentInterface<T>
  | ExportListInterface
