import { SelectOption } from '@basisboard/basis-common/lib/api/custom-fields'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { borderRadiusMixin, spacing } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled, { css } from 'styled-components'
import {
  background,
  BackgroundProps,
  maxWidth,
  MaxWidthProps,
  space,
  SpaceProps,
} from 'styled-system'

const Wrapper = styled.div<BackgroundProps & MaxWidthProps & { small?: boolean }>`
  padding: 0 ${spacing(1)};
  ${borderRadiusMixin};
  display: inline-block;

  height: 32px;

  ${({ small }) =>
    small &&
    css`
      height: 24px;
    `};

  ${maxWidth};
  ${background};
  ${space};
`

interface Props extends MaxWidthProps, SpaceProps {
  value: SelectOption
  small?: boolean
  as?: string
  onClick?: () => void
}

export const Tag: React.FC<Props> = ({ value, small, ...rest }) =>
  value ? (
    <Wrapper background={value.color} {...(rest as any)} small={small}>
      <Text white lineHeight={small ? '24px' : '32px'}>
        {value.label}
      </Text>
    </Wrapper>
  ) : null
