"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Refresh = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Refresh = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M10.9878 4.804C10.1737 4.04171 9.1172 3.62385 7.99637 3.62484C5.97923 3.62661 4.23783 5.00968 3.75702 6.92822C3.72202 7.06788 3.59759 7.1665 3.45361 7.1665H1.96134C1.76608 7.1665 1.61775 6.98924 1.65387 6.79734C2.21733 3.80515 4.84441 1.5415 8.00033 1.5415C9.73074 1.5415 11.3022 2.22213 12.4617 3.33018L13.3917 2.4001C13.7855 2.00637 14.4587 2.28523 14.4587 2.84205V6.33317C14.4587 6.67835 14.1788 6.95817 13.8337 6.95817H10.3425C9.78572 6.95817 9.50686 6.28497 9.90059 5.89122L10.9878 4.804ZM2.16699 9.0415H5.65811C6.21493 9.0415 6.49379 9.71471 6.10006 10.1085L5.01283 11.1957C5.82694 11.958 6.88353 12.3759 8.00439 12.3749C10.0205 12.373 11.7626 10.9909 12.2436 9.0715C12.2786 8.93184 12.4031 8.83322 12.547 8.83322H14.0393C14.2346 8.83322 14.3829 9.01049 14.3468 9.20239C13.7833 12.1945 11.1562 14.4582 8.00033 14.4582C6.26991 14.4582 4.69848 13.7775 3.539 12.6695L2.60892 13.5996C2.2152 13.9933 1.54199 13.7144 1.54199 13.1576V9.6665C1.54199 9.32132 1.82181 9.0415 2.16699 9.0415Z", fill: color })));
exports.Refresh = Refresh;
