import {
  borderRadiusMixin,
  colors,
  DEFAULT_TRANSITION,
  spacing,
} from '@basisboard/basis-ui/es/styles'
import styled, { css } from 'styled-components'
import { height, HeightProps, position, PositionProps, width, WidthProps } from 'styled-system'

export const Table = styled.table`
  width: 100%;
  table-layout: fixed;

  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 36px;

  position: relative;

  & * {
    box-sizing: border-box;
  }

  @media print {
    display: none;
  }
`

const ResizingHover = css<{ isHover?: boolean }>`
  ${({ isHover }) =>
    isHover
      ? css`
          border-right: 2px solid #e7eafe !important;
        `
      : css`
          &:not(:first-child) {
            border-right: 2px solid transparent !important;
          }
        `};
`

export const Th = styled.th<
  WidthProps & { isHover: boolean; totalEntries?: number; onClick?: () => void }
>`
  color: ${colors.white};
  position: sticky;
  top: 0;

  ${({ onClick }) =>
    !!onClick &&
    css`
      cursor: pointer;
    `};

  background: ${colors.white};
  z-index: 10;

  &:first-child {
    z-index: 11;
    left: 0;
  }

  @media print {
    position: relative;

    ${({ totalEntries }) =>
      totalEntries &&
      css`
        width: ${100 / totalEntries}% !important;
        &:first-child {
          width: 240px !important;
        }
      `};
  }

  ${ResizingHover};
  ${width};
`

export const Td = styled.td<{ isHover: boolean }>`
  z-index: -1;
  ${ResizingHover};
`

export const Tr = styled.tr<{ onClick?: () => void }>`
  ${({ onClick }) => !!onClick && 'cursor: pointer'};

  position: relative;
`

export const Thead = styled.thead`
  ${Th} {
    font-weight: 600;
    font-size: 12px;
    line-height: 30px;
    max-height: 30px;
    letter-spacing: 0.001em;
    color: ${colors.gray};
    padding: 0 ${spacing(2)};

    z-index: 20;
    &:first-child {
      z-index: 30;
    }

    box-sizing: border-box;

    border-bottom: 1px solid #eaebee;

    transition: background-color ${DEFAULT_TRANSITION};

    &.sorted {
      background-color: #e7eafe;
      color: ${colors.accent};
    }

    &:hover {
      background-color: ${colors.lightGray3};

      &.sorted {
        background-color: #e7eafe;
        color: ${colors.accent};
      }
    }

    &:last-child {
      @media print {
        display: none;
      }
    }
  }

  ${Tr} {
    height: 48px;
    background-color: ${colors.white};
  }
`

export const Tbody = styled.tbody<{ showShadow?: boolean }>`
  ${Th}, ${Td} {
    font-size: 14px;
    line-height: 16px;

    height: 64px;

    border-bottom: 1px solid;
    border-color: #eaebee;
    border-right: 2px solid transparent;

    vertical-align: middle;

    color: ${colors.darkBlue};
  }

  ${Th} {
    ${({ showShadow }) =>
      showShadow &&
      css`
        border-right: 1px solid #eaebee;
      `}

    padding: 0 ${spacing(1)};
    font-weight: 600;
    z-index: 10;
    padding-right: ${spacing(1)};
  }

  ${Td} {
    font-weight: 500;
    letter-spacing: -0.001em;
    padding-left: ${spacing(2)};

    &:last-child {
      padding: 0;

      @media print {
        display: none;
      }
    }
  }

  ${Tr} {
    height: 64px;

    &:hover {
      background-color: ${colors.lightGray3};

      ${Th} {
        background-color: ${colors.lightGray3};
      }
    }

    &:last-child {
      ${Td}, ${Th} {
        border-bottom: 0;
      }
    }

    ${Td}, ${Th} {
      @media print {
        page-break-after: auto;
        border-bottom: 1px solid ${colors.borderColor};
      }
    }

  }
`

export const Resizer = styled.div<{ isHover?: boolean }>`
  position: absolute;

  top: 0;
  right: 0;

  cursor: col-resize;

  width: 10px;
  height: 100%;
`

export const Shadow = styled.div<HeightProps & PositionProps>`
  position: fixed;
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  width: 15px;

  ${position}
  ${height}
`

export const EmptyCell = styled.td`
  width: 100%;
  border-bottom: 1px solid #eaebee;
  height: 64px;
  background-color: ${colors.lightGray3}77;
`

export const EmptyCellContent = styled.div`
  margin-top: 20px;
  margin-left: 16px;
  height: 24px;
  width: 65%;
  ${borderRadiusMixin};

  background: linear-gradient(-45deg, ${colors.lightGray}, ${colors.lightGray2});
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`
