import { Button } from '@basisboard/basis-ui/es/components/Button'
import * as React from 'react'
import { isReadOnlyUser } from '../../../App'
import { openAddBidModal } from '../../modals'

interface Props {
  onAddBid(): void
}

const AddBidButton: React.FC<Props> = ({ onAddBid }) => {
  if (isReadOnlyUser()) {
    return null
  }

  return (
    <Button.Primary
      data-testid="add-bid"
      preIcon="Plus"
      onClick={() =>
        openAddBidModal({
          onSubmit: onAddBid,
        })
      }
    >
      Add bid
    </Button.Primary>
  )
}

export default AddBidButton
