import { USER_ID } from '@basisboard/basis-common/lib/api'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Label } from '@basisboard/basis-ui/es/components/Label'
import { spacing } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import { Users } from '../../../Users'

interface Props {
  value: USER_ID[]
  onChange(value: USER_ID[]): void
  label?: string
  required?: boolean
  includeDeleted?: boolean
  name?: string
}

export const EstimatorSelector: React.FC<Props> = ({
  value,
  onChange,
  label = 'Estimator',
  required,
  includeDeleted,
  name,
}) => {
  return (
    <Div mb={spacing(2)}>
      <Label label={label} required={required} />
      <Users.Input value={value} onChange={onChange} name={name} includeDeleted={includeDeleted} />
    </Div>
  )
}
