"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Contact = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Contact = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M8.00033 8.00016C9.84147 8.00016 11.3337 6.50798 11.3337 4.66683C11.3337 2.82568 9.84147 1.3335 8.00033 1.3335C6.15918 1.3335 4.66699 2.82568 4.66699 4.66683C4.66699 6.50798 6.15918 8.00016 8.00033 8.00016ZM10.3337 8.8335H9.89876C9.32064 9.09912 8.67741 9.25016 8.00033 9.25016C7.32324 9.25016 6.68262 9.09912 6.10189 8.8335H5.66699C3.7347 8.8335 2.16699 10.4012 2.16699 12.3335V13.4168C2.16699 14.1069 2.72689 14.6668 3.41699 14.6668H12.5837C13.2738 14.6668 13.8337 14.1069 13.8337 13.4168V12.3335C13.8337 10.4012 12.266 8.8335 10.3337 8.8335Z", fill: color })));
exports.Contact = Contact;
