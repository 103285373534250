"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Copy = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Copy = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M10.4993 13.0002V14.0418C10.4993 14.387 10.2195 14.6668 9.87435 14.6668H2.79102C2.44583 14.6668 2.16602 14.387 2.16602 14.0418V4.4585C2.16602 4.11331 2.44583 3.8335 2.79102 3.8335H4.66602V11.5418C4.66602 12.346 5.32021 13.0002 6.12435 13.0002H10.4993ZM10.4993 4.04183V1.3335H6.12435C5.77917 1.3335 5.49935 1.61331 5.49935 1.9585V11.5418C5.49935 11.887 5.77917 12.1668 6.12435 12.1668H13.2077C13.5529 12.1668 13.8327 11.887 13.8327 11.5418V4.66683H11.1243C10.7806 4.66683 10.4993 4.38558 10.4993 4.04183ZM13.6496 3.23378L11.9324 1.51654C11.8152 1.39934 11.6562 1.3335 11.4905 1.3335H11.3327V3.8335H13.8327V3.67571C13.8327 3.50996 13.7668 3.35099 13.6496 3.23378Z", fill: color })));
exports.Copy = Copy;
