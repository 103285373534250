import { Button } from '@basisboard/basis-ui/es/components/Button'
import { spacing } from '@basisboard/basis-ui/es/styles'
import times from 'ramda/src/times'
import * as React from 'react'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

const DEFAULT_MAX_PAGES = 6

const Wrapper = styled.div<SpaceProps>`
  & > * {
    margin-right: ${spacing(0.5)};
  }

  max-width: 100%;

  display: flex;
  align-items: center;

  flex-wrap: wrap;

  ${space};
`

interface Props {
  currentPage: number
  totalPages: number
  setCurrentPage: (page: number) => void
  space?: SpaceProps
  maxPages?: number
}

export const Pagination: React.FC<Props> = ({
  currentPage,
  setCurrentPage,
  totalPages,
  space,
  maxPages = DEFAULT_MAX_PAGES,
}) => (
  <Wrapper {...space}>
    <Button.Gradient
      data-testid="previous"
      mr={spacing(0.5)}
      mb={spacing(0.5)}
      onClick={() => setCurrentPage(Math.max(0, currentPage - 1))}
      disabled={currentPage === 0}
      preIcon="ArrowLeft"
    />
    {times(
      index => (
        <Button.Gradient
          key={index}
          active={currentPage === index}
          size={32}
          mr={spacing(0.5)}
          mb={spacing(0.5)}
          onClick={() => setCurrentPage(index)}
        >
          {index + 1}
        </Button.Gradient>
      ),
      Math.min(totalPages, maxPages),
    )}
    <Button.Gradient
      data-testid="next"
      mb={spacing(0.5)}
      onClick={() => setCurrentPage(Math.min(totalPages - 1, currentPage + 1))}
      disabled={currentPage === totalPages - 1}
      postIcon="ArrowRight"
    />
  </Wrapper>
)
