"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEscapeKey = void 0;
const react_1 = require("react");
const useEscapeKey = (callback) => {
    (0, react_1.useEffect)(() => {
        const eventHandler = e => {
            if (e.keyCode === 27) {
                callback();
            }
        };
        window.addEventListener('keydown', eventHandler);
        return () => {
            window.removeEventListener('keydown', eventHandler);
        };
    });
};
exports.useEscapeKey = useEscapeKey;
