import { Platform } from '@basisboard/basis-common/lib/enums'
import { Container } from '@containrz/react-hook'
import uniq from 'ramda/src/uniq'
import { eventBus, EventBusType } from '../../../../services'

interface State {
  dismissedPlatforms: Platform[]
}

export class PlatformBannerState extends Container<State> {
  constructor() {
    super()

    this.state = {
      dismissedPlatforms: [],
    } as State

    eventBus.register(EventBusType.CloseModal, ({ type }) => {
      if (type === 'EmailsDetailsModal') {
        this.setState({ dismissedPlatforms: [] })
      }
    })
  }

  dismissPlatform = (platform: Platform) =>
    this.setState(s => ({ dismissedPlatforms: uniq([...s.dismissedPlatforms, platform]) }))
}
