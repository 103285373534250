"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkBid = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const LinkBid = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M1.74999 4.66675H12.5833C12.8135 4.66675 13 4.48029 13 4.25008V2.58341C13 2.35321 12.8135 2.16675 12.5833 2.16675H1.74999C1.51979 2.16675 1.33333 2.35321 1.33333 2.58341V4.25008C1.33333 4.48029 1.51979 4.66675 1.74999 4.66675ZM14.25 6.75008H3.41666C3.18645 6.75008 2.99999 6.93654 2.99999 7.16675V8.83341C2.99999 9.06362 3.18645 9.25008 3.41666 9.25008H14.25C14.4802 9.25008 14.6667 9.06362 14.6667 8.83341V7.16675C14.6667 6.93654 14.4802 6.75008 14.25 6.75008ZM12.5833 11.3334H1.74999C1.51979 11.3334 1.33333 11.5199 1.33333 11.7501V13.4167C1.33333 13.647 1.51979 13.8334 1.74999 13.8334H12.5833C12.8135 13.8334 13 13.647 13 13.4167V11.7501C13 11.5199 12.8135 11.3334 12.5833 11.3334Z", fill: color })));
exports.LinkBid = LinkBid;
