import React from 'react'
import ReactDOM from 'react-dom'
import App from './containers/App'
import './events'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('@axe-core/react');
//   axe(React, ReactDOM, 1000, {});
// }

declare global {
  interface Window {
    Intercom: (action: string, params?: unknown, extraParams?: unknown) => void
    FS: {
      identify: (key: string, params: unknown) => void
      shutdown: () => void
      event: (eventName: string, eventProperties?: unknown) => void
      getCurrentSessionURL: () => string
    }
    amplitude: {
      Identify: () => void
      getInstance: (
        apiKey?: string,
      ) => {
        init: (apiKey: string, userId: string, config?: unknown) => unknown
        identify: (obj: unknown) => unknown
        logEvent: (eventName: string, eventProperties?: unknown) => unknown
        setUserProperties: (obj: unknown) => void
      }
    }
    heap: {
      load: (id: string) => Promise<void>
      identify: (id: string) => void
      addUserProperties: (config: unknown) => void
      track: (event: string, data: unknown) => void
      resetIdentity: () => void
    }
  }
}

const app = <App />

ReactDOM.render(app as any, document.getElementById('root'))

serviceWorkerRegistration.unregister()
// serviceWorkerRegistration.register({
//   onUpdate: registration => {
//     messageSW(registration.waiting, { type: 'SKIP_WAITING' })
//     window.location.href = window.location.href
//     // openModal({
//     //   type: ModalType.UpdateApp,
//     //   props: {
//     //     onInstall: () => {
//     //       messageSW(registration.waiting, { type: 'SKIP_WAITING' });
//     //       window.location.reload();
//     //     },
//     //   },
//     // });
//   },
// })

// reportWebVitals(process.env.NODE_ENV !== 'production' ? console.log : undefined);
