import {
  Stage,
  StageCode,
  StageReason,
  STAGE_ID,
  STAGE_REASON_ID,
} from '@basisboard/basis-common/lib/api'
import { colors } from '@basisboard/basis-ui/es/styles'
import { Container } from '@containrz/react-hook'
import pickAll from 'ramda/src/pickAll'
import { MINUTES } from '../../constants'
import { eventBus, EventBusType } from '../../services'
import { StageDetails } from '../../types'
import {
  addStageReason,
  deleteReason,
  deleteStage,
  getStageDetails,
  getStages,
  patchReason,
  postStages,
} from './api'

export * from './api'

type SimpleStage = Pick<Stage, 'color' | 'name' | 'order'>

interface State {
  stages: StageDetails[]
  loading: boolean
  error: boolean
  reasons: StageReason[]
}

export class StagesState extends Container<State> {
  constructor() {
    super()

    this.state = {
      stages: [],
      loading: false,
      error: false,
      reasons: [],
    } as State

    this.reloadTimeout = window.setTimeout(this.reloadStages, 1 * MINUTES)
  }

  reloadTimeout: number
  editTimeout: number | null = null

  loadStages = (silent = false) => {
    this.setState({
      loading: !silent,
    })

    getStages()
      .then(stages => {
        this.setState({
          stages,
          reasons: stages.reduce((acc, s) => [...acc, ...(s.reasons || [])], []),
          loading: false,
          error: false,
        })

        eventBus.publish(EventBusType.LoadedStages, { stages })

        return stages
      })
      .catch(() => this.setState({ error: true, loading: false }))
  }

  destroy = () => {
    clearTimeout(this.reloadTimeout)
    clearTimeout(this.editTimeout)
  }

  reloadStages = () => {
    return this.loadStages(true)
  }

  editStage = (editedStage: StageDetails) => {
    const { stages } = this.state
    const updatedStages = this.state.stages.map(stage =>
      stage.id === editedStage.id ? editedStage : stage,
    )

    this.setState({ stages: updatedStages })

    if (this.editTimeout) {
      clearTimeout(this.editTimeout)
    }

    this.editTimeout = window.setTimeout(() => {
      this.updateStages(this.state.stages).catch(() => this.setState({ stages }))

      eventBus.publish(EventBusType.EditStage, editedStage)
    }, 5000)
  }

  updateStages = (updatedStages: Array<StageDetails | Pick<Stage, 'color' | 'name' | 'order'>>) => {
    const { stages } = this.state
    this.setState({ stages: updatedStages as StageDetails[] })

    const formattedStages = updatedStages.map(stage =>
      pickAll<StageDetails | SimpleStage, Stage>(
        ['id', 'name', 'color', 'default', 'order', 'code'],
        stage,
      ),
    )

    return postStages(formattedStages)
      .then(this.reloadStages)
      .catch(() => this.setState({ stages }))
  }

  addNewStage = () => {
    const { stages } = this.state
    const newStage = {
      name: 'New stage',
      color: colors.STAGES[Math.floor(Math.random() * colors.STAGES.length)],
      order: this.state.stages[this.state.stages.length - 1].order + 1,
    }

    const updatedStages = [...this.state.stages, newStage]

    eventBus.publish(EventBusType.CreateStage, newStage)

    return this.updateStages(updatedStages).catch(() => this.setState({ stages }))
  }

  addReasonToStage = async (stageCode: StageCode, reason: string) => {
    const newReasonId = await addStageReason(stageCode, reason)

    eventBus.publish(EventBusType.CreateStageReason, { stageCode, reason })

    this.setState(s => ({
      ...s,
      stages: s.stages.map(stage =>
        stage.code === stageCode
          ? {
              ...stage,
              reasons: [
                ...stage.reasons,
                { id: newReasonId, name: reason, stageId: this.getStageForStageCode(stageCode).id },
              ],
            }
          : stage,
      ),
    }))
  }

  editReason = (stageCode: StageCode, stageReasonId: STAGE_REASON_ID, name: string) => {
    this.setState(s => ({
      ...s,
      stages: s.stages.map(stage =>
        stage.code === stageCode
          ? {
              ...stage,
              reasons: stage.reasons.map(reason =>
                reason.id === stageReasonId ? { ...reason, name } : reason,
              ),
            }
          : stage,
      ),
    }))
    return patchReason(stageReasonId, name)
  }

  deleteReason = (stageCode: StageCode, stageReasonId: string) => {
    deleteReason(stageReasonId)
    this.setState(s => ({
      ...s,
      stages: s.stages.map(stage =>
        stage.code === stageCode
          ? {
              ...stage,
              reasons: stage.reasons.filter(reason => reason.id !== stageReasonId),
            }
          : stage,
      ),
    }))

    eventBus.publish(EventBusType.DeleteStageReason, {
      stageCode,
      reason: this.getStageForStageCode(stageCode).reasons.find(r => r.id === stageReasonId),
    })
  }

  deleteStage = (stageId: STAGE_ID, moveToStageId?: STAGE_ID) => {
    const { stages } = this.state
    this.setState(s => {
      const targetStage = moveToStageId ? s.stages.find(stage => stage.id === moveToStageId) : null
      return {
        stages: moveToStageId
          ? s.stages.map(stage => (stage.id === stageId ? { ...targetStage, id: stageId } : stage))
          : s.stages.filter(stage => stage.id !== stageId),
      }
    })

    eventBus.publish(EventBusType.DeleteStage, { stageId, moveToStageId })

    return deleteStage(stageId, moveToStageId)
      .then(this.reloadStages)
      .catch(() => this.setState({ stages }))
  }

  getReasonsForStageCode = (code: StageCode) => this.getStageForStageCode(code).reasons

  getColorForStageCode = (code: StageCode) => this.getStageForStageCode(code).color

  getStageForStageCode = (code: StageCode) => this.state.stages.find(s => s.code === code)

  getStageForId = (stageId: STAGE_ID) => this.state.stages.find(s => s.id === stageId)

  getStageDetails = getStageDetails

  getActiveStagesIds = () =>
    this.state.stages
      .filter(
        stage => ![StageCode.Declined, StageCode.Undecided, StageCode.Lost].includes(stage.code),
      )
      .map(stage => stage.id)
}
