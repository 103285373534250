import { retryLazyLoad } from '@basisboard/basis-ui/es/utils'
import { EmailsDetailsModalProps } from '../../Inbox/modals/EmailsDetails/types'
import { openModal } from '../../Modal'

export const openEmailsModal = (props: EmailsDetailsModalProps) =>
  openModal({
    Component: retryLazyLoad(() => import('./EmailsDetails')),
    name: 'EmailsDetailsModal',
    props,
    hideScroll: true,
    stylesOverrides: {
      overflow: 'visible',
    },
  })
