import { BID_INVITE_ID, USER_ID } from '@basisboard/basis-common/lib/api'
import { CustomFieldType, CustomFieldValues } from '@basisboard/basis-common/lib/api/custom-fields'
import { ProjectFieldType } from '@basisboard/basis-common/lib/enums'
import { getContainer } from '@containrz/core'
import moment from 'moment'
import { ComponentProps } from 'react'
import { NestedBidInvite, NestedProject } from '../../../../types'
import { Fields } from '../../../Fields'
import { StagesState } from '../../../Stages'
import { projectRepository } from '../../container'

const getDateProps = (
  onChangeBidInviteKey: (
    bidInviteId: BID_INVITE_ID,
    key: keyof NestedBidInvite,
    val: CustomFieldValues,
  ) => void,
  bidInvites: NestedBidInvite[],
  key: keyof NestedBidInvite,
): { onChange: (v: CustomFieldValues) => void; value: CustomFieldValues } => ({
  onChange: (val: unknown) =>
    onChangeBidInviteKey(
      findFirstBidInviteWithValue(bidInvites, key)?.id || bidInvites[0].id,
      key,
      val ? moment(val).toISOString() : null,
    ),
  value: findFirstBidInviteWithValue(bidInvites, key)[key] as string,
})

const findFirstBidInviteWithValue = (bidInvites: NestedBidInvite[], key: keyof NestedBidInvite) =>
  bidInvites.find(bi => bi[key]) || ({} as NestedBidInvite)

export const mapDefaultFieldsToCustomFieldProps = (
  project: NestedProject,
  onChangeProjectKey: (key: keyof NestedProject, val: CustomFieldValues) => void,
  onChangeBidInviteKey: (
    bidInviteId: BID_INVITE_ID,
    key: keyof NestedBidInvite,
    val: CustomFieldValues,
  ) => void,
): Record<string, ComponentProps<typeof Fields.TableField>> => ({
  // Date fields
  [ProjectFieldType.AwardedAt]: {
    field: { type: CustomFieldType.Date, label: 'Awarded date' },
    onChange: (val: unknown) => onChangeProjectKey('awardedAt', moment(val).toISOString()),
    value: project.awardedAt,
  },

  [ProjectFieldType.LostAt]: {
    field: { type: CustomFieldType.Date, label: 'Lost date' },
    onChange: (val: unknown) => onChangeProjectKey('lostAt', moment(val).toISOString()),
    value: project.lostAt,
  },

  [ProjectFieldType.SubmittedAt]: {
    field: { type: CustomFieldType.Date, label: 'Submitted date' },
    onChange: (val: unknown) => onChangeProjectKey('submittedAt', moment(val).toISOString()),
    value: project.submittedAt,
  },

  [ProjectFieldType.StartAt]: {
    field: { type: CustomFieldType.Date, label: 'Start date' },
    onChange: (val: unknown) => onChangeProjectKey('startAt', moment(val).toISOString()),
    value: project.startAt,
  },

  [ProjectFieldType.EndAt]: {
    field: { type: CustomFieldType.Date, label: 'End date' },
    onChange: (val: unknown) => onChangeProjectKey('endAt', moment(val).toISOString()),
    value: project.endAt,
  },

  [ProjectFieldType.JobWalk]: {
    field: { type: CustomFieldType.Date, label: 'Job walk' },
    ...getDateProps(onChangeBidInviteKey, project.bidInvites, 'jobWalkAt'),
  },

  [ProjectFieldType.RFIDeadline]: {
    field: { type: CustomFieldType.Date, label: 'RFI deadline' },
    ...getDateProps(onChangeBidInviteKey, project.bidInvites, 'rfiDeadlineAt'),
  },

  // Estimators
  [ProjectFieldType.Estimator]: {
    field: { type: CustomFieldType.UserMultiSelect, label: 'Estimators' },
    onChange: (val: USER_ID[]) => onChangeProjectKey('estimatorIds', val),
    value: project.estimatorIds,
  },

  [ProjectFieldType.Location]: {
    field: { type: CustomFieldType.Text, label: 'Location' },
    onChange: val => onChangeProjectKey('location', val as string),
    value: project.location,
  },

  [ProjectFieldType.StageReason]: {
    field: {
      type: CustomFieldType.SingleSelect,
      label: 'Stage reason',
      options: (getContainer(StagesState).getStageForId(project.stageId)?.reasons || []).map(
        reason => ({
          id: reason.id,
          label: reason.name,
        }),
      ),
    },
    onChange: val =>
      projectRepository().updateProjectReason(project.id, val as string, project.stage.code),
    value: project.stageReasonId,
  },
})
