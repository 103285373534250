"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrowRightLight = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const ArrowRightLight = ({ width = 16, height = 16, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 448 512" },
    React.createElement("path", { d: "M32 212.57A20.57 20.57 0 0 1 52.57 192H224V75a8 8 0 0 1 13.66-5.66L410 241.44a20.56 20.56 0 0 1 0 29.11L237.66 442.63A8 8 0 0 1 224 437V320H52.57A20.57 20.57 0 0 1 32 299.42v-86.85m-32 0v86.85A52.63 52.63 0 0 0 52.57 352H192v85c0 35.51 43 53.5 68.27 28.3l172.3-172.08a52.55 52.55 0 0 0 0-74.4L260.27 46.71C235.15 21.62 192 39.36 192 75v85H52.57A52.63 52.63 0 0 0 0 212.57z", fill: color })));
exports.ArrowRightLight = ArrowRightLight;
