"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Search = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Search = ({ width = 14, height = 14, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 14 14" },
    React.createElement("path", { d: "M13.4716 11.8395L10.8803 9.24825C10.7633 9.1313 10.6048 9.06632 10.4385 9.06632H10.0148C10.7322 8.14884 11.1584 6.99484 11.1584 5.73948C11.1584 2.75313 8.73865 0.333374 5.7523 0.333374C2.76594 0.333374 0.346191 2.75313 0.346191 5.73948C0.346191 8.72584 2.76594 11.1456 5.7523 11.1456C7.00766 11.1456 8.16166 10.7193 9.07913 10.002V10.4256C9.07913 10.592 9.14411 10.7505 9.26107 10.8675L11.8524 13.4588C12.0967 13.7031 12.4917 13.7031 12.7335 13.4588L13.469 12.7232C13.7133 12.4789 13.7133 12.0839 13.4716 11.8395ZM5.7523 9.06632C3.91474 9.06632 2.42546 7.57964 2.42546 5.73948C2.42546 3.90192 3.91214 2.41265 5.7523 2.41265C7.58986 2.41265 9.07913 3.89933 9.07913 5.73948C9.07913 7.57704 7.59245 9.06632 5.7523 9.06632Z", fill: color })));
exports.Search = Search;
