"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortAscArrow = exports.SortDescArrow = exports.SortDesc = exports.SortAsc = exports.Sort = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Sort = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M4.90112 8.83333H11.099C11.6563 8.83333 11.935 9.50781 11.5417 9.90104L8.44279 13C8.198 13.2448 7.80217 13.2448 7.55998 13L4.45842 9.90104C4.06519 9.50781 4.34383 8.83333 4.90112 8.83333ZM11.5417 6.09896L8.44279 3C8.198 2.75521 7.80217 2.75521 7.55998 3L4.45842 6.09896C4.06519 6.49219 4.34383 7.16667 4.90112 7.16667H11.099C11.6563 7.16667 11.935 6.49219 11.5417 6.09896Z", fill: color }),
    React.createElement("path", { d: "M4.90112 8.8335H11.099C11.6563 8.8335 11.935 9.50798 11.5417 9.9012L8.44279 13.0002C8.198 13.245 7.80217 13.245 7.55998 13.0002L4.45842 9.9012C4.06519 9.50798 4.34383 8.8335 4.90112 8.8335Z", fill: color })));
exports.Sort = Sort;
const SortAsc = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M4.90112 8.83333H11.099C11.6563 8.83333 11.935 9.50781 11.5417 9.90104L8.44279 13C8.198 13.2448 7.80217 13.2448 7.55998 13L4.45842 9.90104C4.06519 9.50781 4.34383 8.83333 4.90112 8.83333ZM11.5417 6.09896L8.44279 3C8.198 2.75521 7.80217 2.75521 7.55998 3L4.45842 6.09896C4.06519 6.49219 4.34383 7.16667 4.90112 7.16667H11.099C11.6563 7.16667 11.935 6.49219 11.5417 6.09896Z", fill: color }),
    React.createElement("path", { d: "M4.90112 8.8335H11.099C11.6563 8.8335 11.935 9.50798 11.5417 9.9012L8.44279 13.0002C8.198 13.245 7.80217 13.245 7.55998 13.0002L4.45842 9.9012C4.06519 9.50798 4.34383 8.8335 4.90112 8.8335Z", fill: styles_1.colors.accent })));
exports.SortAsc = SortAsc;
const SortDesc = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M4.90112 8.83333H11.099C11.6563 8.83333 11.935 9.50781 11.5417 9.90104L8.44279 13C8.198 13.2448 7.80217 13.2448 7.55998 13L4.45842 9.90104C4.06519 9.50781 4.34383 8.83333 4.90112 8.83333ZM11.5417 6.09896L8.44279 3C8.198 2.75521 7.80217 2.75521 7.55998 3L4.45842 6.09896C4.06519 6.49219 4.34383 7.16667 4.90112 7.16667H11.099C11.6563 7.16667 11.935 6.49219 11.5417 6.09896Z", fill: styles_1.colors.accent }),
    React.createElement("path", { d: "M4.90112 8.8335H11.099C11.6563 8.8335 11.935 9.50798 11.5417 9.9012L8.44279 13.0002C8.198 13.245 7.80217 13.245 7.55998 13.0002L4.45842 9.9012C4.06519 9.50798 4.34383 8.8335 4.90112 8.8335Z", fill: color })));
exports.SortDesc = SortDesc;
const SortDescArrow = ({ width = 16, height = 16, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 576 512" },
    React.createElement("path", { fill: color, d: "M416 288h-95.1c-17.67 0-32 14.33-32 32s14.33 32 32 32H416c17.67 0 32-14.33 32-32S433.7 288 416 288zM544 32h-223.1c-17.67 0-32 14.33-32 32s14.33 32 32 32H544c17.67 0 32-14.33 32-32S561.7 32 544 32zM352 416h-32c-17.67 0-32 14.33-32 32s14.33 32 32 32h32c17.67 0 31.1-14.33 31.1-32S369.7 416 352 416zM480 160h-159.1c-17.67 0-32 14.33-32 32s14.33 32 32 32H480c17.67 0 32-14.33 32-32S497.7 160 480 160zM192.4 330.7L160 366.1V64.03C160 46.33 145.7 32 128 32S96 46.33 96 64.03v302L63.6 330.7c-6.312-6.883-14.94-10.38-23.61-10.38c-7.719 0-15.47 2.781-21.61 8.414c-13.03 11.95-13.9 32.22-1.969 45.27l87.1 96.09c12.12 13.26 35.06 13.26 47.19 0l87.1-96.09c11.94-13.05 11.06-33.31-1.969-45.27C224.6 316.8 204.4 317.7 192.4 330.7z" })));
exports.SortDescArrow = SortDescArrow;
const SortAscArrow = ({ width = 16, height = 16, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 576 512" },
    React.createElement("path", { fill: color, d: "M416 288h-95.1c-17.67 0-32 14.33-32 32s14.33 32 32 32H416c17.67 0 32-14.33 32-32S433.7 288 416 288zM352 416h-32c-17.67 0-32 14.33-32 32s14.33 32 32 32h32c17.67 0 31.1-14.33 31.1-32S369.7 416 352 416zM480 160h-159.1c-17.67 0-32 14.33-32 32s14.33 32 32 32H480c17.67 0 32-14.33 32-32S497.7 160 480 160zM544 32h-223.1c-17.67 0-32 14.33-32 32s14.33 32 32 32H544c17.67 0 32-14.33 32-32S561.7 32 544 32zM151.6 41.95c-12.12-13.26-35.06-13.26-47.19 0l-87.1 96.09C4.475 151.1 5.35 171.4 18.38 183.3c6.141 5.629 13.89 8.414 21.61 8.414c8.672 0 17.3-3.504 23.61-10.39L96 145.9v302C96 465.7 110.3 480 128 480s32-14.33 32-32.03V145.9L192.4 181.3C204.4 194.3 224.6 195.3 237.6 183.3c13.03-11.95 13.9-32.22 1.969-45.27L151.6 41.95z" })));
exports.SortAscArrow = SortAscArrow;
