import {
  EmailScraper,
  EmailScraperCreate,
  EmailScraperUpdate,
  EMAIL_SCRAPER_ID,
  GetEmailScraperOptionsResponseBody,
} from '@basisboard/basis-common/lib/api'
import { Container } from '@containrz/react-hook'
import { eventBus, EventBusType } from '../../services'
import {
  createEmailScrapers,
  deleteEmailScraper,
  getEmailScrapers,
  getScraperOptions,
  updateEmailScraper,
} from './api'
import { openFixScraperModal } from './modals'

interface State {
  scrapers: EmailScraper[]
}

export class ScrapersState extends Container<State> {
  state = {
    scrapers: [] as EmailScraper[],
  }

  loadScrapers = () => getEmailScrapers().then(scrapers => this.setState({ scrapers }))

  createScraper = (data: EmailScraperCreate) =>
    createEmailScrapers(data).then(scraper => {
      this.setState(s => ({ scrapers: [...s.scrapers, scraper.emailScraper] }))

      return scraper
    })

  deleteScraper = (scraperId: EMAIL_SCRAPER_ID) => {
    this.setState(s => ({ scrapers: s.scrapers.filter(scraper => scraper.id !== scraperId) }))

    return deleteEmailScraper(scraperId)
  }

  getOptions = (scraperId: EMAIL_SCRAPER_ID): Promise<GetEmailScraperOptionsResponseBody> =>
    getScraperOptions(scraperId)

  updateScraper = (scraperId: EMAIL_SCRAPER_ID, data: EmailScraperUpdate) => {
    this.setState(s => ({
      scrapers: s.scrapers.map(scraper =>
        scraperId === scraper.id
          ? ({
              ...scraper,
              ...data,
            } as EmailScraper)
          : scraper,
      ),
    }))

    return updateEmailScraper(scraperId, data)
  }

  toggleScraperEnabled = (scraperId: EMAIL_SCRAPER_ID, active: boolean) => {
    const scraper = this.state.scrapers.find(s => s.id === scraperId)

    // When something is wrong with scraper that it needs a re-login
    if (!scraper.active && Boolean(scraper.firstFailedRunAt)) {
      return openFixScraperModal()
    }

    return updateEmailScraper(scraperId, { active })
      .then(es =>
        this.setState(s => ({
          scrapers: s.scrapers.map(scraper => (scraper.id === scraperId ? es : scraper)),
        })),
      )
      .finally(() => {
        if (active) {
          eventBus.publish(EventBusType.EnableScraper, { scraperId })
        } else {
          eventBus.publish(EventBusType.DisableScraper, { scraperId })
        }
      })
  }
}
