import { GetProfileResponse } from '@basisboard/basis-common/lib/api'
import { config } from '../config'
import { getSessionUrl } from './smartlook'

export const identifyHeap = async ({
  customerWorkspace,
  email,
  id,
  name,
  role,
  trial,
  position,
  flags,
}: GetProfileResponse) => {
  window.heap.identify?.(id)

  try {
    fetch('/heap-account-properties', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        app_id: config.HEAP_ID,
        account_id: customerWorkspace,
        properties: {
          Workspace: customerWorkspace,
          IsOnTrial: trial,
        },
      }),
    })
  } catch (e) {
    // eslint-disable-next-line
    console.error(e, 'Running locally')
  }

  window.heap.addUserProperties({ 'Latest Smartlook Session': getSessionUrl() })

  window.heap.addUserProperties?.({
    Name: name,
    Email: email,
    Workspace: customerWorkspace,
    Role: role,
    Position: position,
    IsOnTrial: trial,
    DisplayName: `${name} @ ${customerWorkspace}`,
    ...flags,
  })
}

export const logHeap = (evt: any, params?: any) => {
  window.heap.track?.(evt, params)
}

export const shutDownHeap = () => window.heap.resetIdentity?.()
