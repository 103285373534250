import { fuzzySearch } from '@basisboard/basis-ui/es/utils'
import React from 'react'
import { TableFieldProps } from '../../type'
import { BaseField } from '../BaseField'
import { MultiSelectedOptions } from '../MultiSelect/components/MultiSelectedOptions'
import { SelectorValues } from '../SelectorValues'
import { Input, WrapperDiv } from './styled'

export const MultiSearch: React.FC<TableFieldProps & {
  handleDeleteOption?: (index: number) => void
}> = props => {
  const {
    field: { options },
    onDismiss,
    customActions,
    forceShowEdit,
    renderOption,
    children,
    onChange,
    value,
    handleDeleteOption,
    actions,
  } = props

  const [showEdit, setShowEdit] = React.useState(forceShowEdit || false)
  const [searchQuery, setSearchQuery] = React.useState<string>()
  const [inputRef, setInputRef] = React.useState<HTMLInputElement>()

  React.useEffect(() => setSearchQuery(''), [value])

  const filteredOptions = React.useMemo(
    () =>
      (searchQuery
        ? fuzzySearch(options, searchQuery || 'basis', {
            options: { keys: ['label'] },
          })
        : options
      ).filter(opt => !(Array.isArray(value) ? value : [value]).some(v => opt.value === v)),
    [options, searchQuery, JSON.stringify(value)],
  )

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
    if (e.key === 'Backspace' && inputRef.value.length === 0) {
      const indexToDelete = Array.isArray(value) ? value.length - 1 : -1

      handleDeleteOption(indexToDelete)
    }
  }

  React.useEffect(() => {
    if (inputRef) {
      inputRef.focus()
    }
  }, [inputRef])

  return (
    <BaseField
      readonly={!Boolean(onChange)}
      onDismiss={onDismiss}
      forceShowEdit={forceShowEdit}
      onShowEdit={setShowEdit}
      actions={actions}
      customActions={{
        width: 250,
        customActions: (
          <>
            <SelectorValues
              renderOption={renderOption}
              // eslint-disable-next-line
              //@ts-ignore
              options={filteredOptions}
              value={value}
              onChange={onChange}
            />

            {customActions?.customActions}
          </>
        ),
      }}
    >
      {showEdit ? (
        <WrapperDiv onClick={e => e.stopPropagation()}>
          <MultiSelectedOptions
            value={value}
            renderOption={renderOption}
            options={options}
            shouldHaveDelete
            onDeleteClick={handleDeleteOption}
          />
          <Input
            placeholder="Type to search..."
            onChange={e => setSearchQuery(e.currentTarget.value)}
            value={searchQuery}
            ref={setInputRef}
            onKeyDown={onKeyDown}
          />
        </WrapperDiv>
      ) : (
        <MultiSelectedOptions
          value={value}
          renderOption={renderOption}
          options={options}
          shouldHaveCommaSeparator
        >
          {children}
        </MultiSelectedOptions>
      )}
    </BaseField>
  )
}
