import {
  GetProfileSettingsResponse,
  PutProfileSettingsRequest,
} from '@basisboard/basis-common/lib/api'
import { get, put } from '../../api'

export const putUserSetting = <T = any>(key: string, value: PutProfileSettingsRequest<T>) =>
  put<T>(`/profile/settings/${key}`, value).then(response => response.data)

export const getUserSettings = (key: string) =>
  get<GetProfileSettingsResponse>(`/profile/settings/${key}`).then(
    response => response.data?.value || null,
  )
