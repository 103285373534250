"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scrollBarMixin = exports.boxShadowMixin = exports.DEFAULT_TRANSITION = exports.transitionMixin = exports.heightMixin = exports.HEIGHT_MAP = exports.DEFAULT_BORDER = exports.borderMixin = exports.borderRadiusMixin = exports.DEFAULT_BORDER_RADIUS = void 0;
const styled_components_1 = require("styled-components");
const utils_1 = require("../../utils");
const colors_1 = require("../colors");
const constants_1 = require("../constants");
const BORDER_RADIUS_MAP = {
    small: 2,
    normal: 4,
    large: 6,
};
exports.DEFAULT_BORDER_RADIUS = BORDER_RADIUS_MAP.normal;
const borderRadiusMixin = (radius = 'normal') => (0, styled_components_1.css) `
  border-radius: ${typeof radius === 'number'
    ? radius
    : BORDER_RADIUS_MAP[radius] || exports.DEFAULT_BORDER_RADIUS}px;
`;
exports.borderRadiusMixin = borderRadiusMixin;
const BORDER_MAP = {
    default: `1px solid ${colors_1.colors.border}`,
    hover: `1px solid ${colors_1.colors.accent}`,
};
const borderMixin = (borderConfig = 'default') => `
  border: ${typeof borderConfig === 'string'
    ? BORDER_MAP[borderConfig] || `1px solid ${borderConfig}`
    : BORDER_MAP.default};
`;
exports.borderMixin = borderMixin;
exports.DEFAULT_BORDER = BORDER_MAP.default;
exports.HEIGHT_MAP = {
    xsmall: 24,
    small: 32,
    medium: 40,
    large: 50,
};
const heightMixin = (height = 'medium') => (0, styled_components_1.css) `
  height: ${typeof height === 'number'
    ? height
    : exports.HEIGHT_MAP[height] || exports.HEIGHT_MAP.medium}px;
`;
exports.heightMixin = heightMixin;
/**
 * Transition config
 */
const transitionMixin = (transition = 'medium', prop) => (0, styled_components_1.css) `
  transition: ${prop || ''}
    ${typeof transition === 'number'
    ? `${transition}ms`
    : constants_1.TransitionType[transition] || constants_1.TransitionType.medium}
    ease;
`;
exports.transitionMixin = transitionMixin;
exports.DEFAULT_TRANSITION = constants_1.TransitionType['medium'];
const BOX_SHADOW_MAP = {
    dark: 'rgb(0 0 0 / 10%) 0px 0px 2px 0px, rgb(0 0 0 / 10%) 0px 2px 4px 0px',
    light: '0px 4px 4px 0px rgb(0 0 0 / 10%)',
};
const boxShadowMixin = (boxShadow = 'dark') => (0, styled_components_1.css) `
    box-shadow: ${typeof boxShadow === 'string'
    ? BOX_SHADOW_MAP[boxShadow] || boxShadow
    : BOX_SHADOW_MAP.dark};
  `;
exports.boxShadowMixin = boxShadowMixin;
const scrollBarMixin = (prop, color) => (0, utils_1.box)(typeof prop === 'number' ? prop : 5).fold(size => (0, styled_components_1.css) `
      ::-webkit-scrollbar-track {
        height: ${size}px;
        width: ${size}px;
        border-radius: ${size}px;
        background-color: transparent;
      }

      ::-webkit-scrollbar {
        height: ${size}px;
        width: ${size}px;
        border-radius: ${size}px;
        background-color: transparent;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: ${size}px;
        background-color: ${color || colors_1.colors.blueGrayBorder};
      }
    `);
exports.scrollBarMixin = scrollBarMixin;
