import { FeatureType, Project } from '@basisboard/basis-common/lib/api'
import {
  CustomFieldEntity,
  CustomFieldType,
  CustomFieldValues,
} from '@basisboard/basis-common/lib/api/custom-fields'
import { ProjectFieldType } from '@basisboard/basis-common/lib/enums'
import { Checkbox } from '@basisboard/basis-ui/es/components/Checkbox'
import { Table2 } from '@basisboard/basis-ui/es/components/Table2'
import { colors } from '@basisboard/basis-ui/es/styles'
import { box, formatDate, formatQuote, readableName } from '@basisboard/basis-ui/es/utils'
import { getContainer } from '@containrz/core'
import keys from 'ramda/src/keys'
import * as React from 'react'
import { CompanyChip } from '../../../../components'
import { screens } from '../../../../screens'
import { LocationField } from '../../../../templates/DetailsTab/components/LocationField'
import { Field } from '../../../../templates/ViewsScreen'
import { NestedProject } from '../../../../types'
import { bidInviteRepository } from '../../../BidInvites'
import { Fields, FieldsState } from '../../../Fields'
import { StagesState } from '../../../Stages'
import { projectRepository } from '../../container'
import { openDuplicateProjectModal } from '../../modals'
import { BidDeadlinePicker } from './components/BidDeadlinePicker'
import { Name } from './components/Name'
import { mapDefaultFieldsToCustomFieldProps } from './utils'
import { Keywords } from './components/Keywords'
import { hasFeature } from '../../../App'

interface ListEntryProps {
  project: NestedProject
  columns: Field[]
  onSelect: (select: boolean) => void
  onUpdate: (key: string, value: any) => void
  onArchive: (archive: boolean) => void
  onDelete: () => void
  onDuplicate: (newProject: NestedProject) => void
  selected: boolean
}

export const ListEntry: React.FC<ListEntryProps> = ({
  project,
  onSelect,
  onUpdate,
  onArchive,
  onDelete,
  onDuplicate,
  columns,
  selected,
}: ListEntryProps) => {
  const [currentProject, setCurrentProject] = React.useState(project)

  React.useEffect(() => {
    setCurrentProject(project)
  }, [JSON.stringify(project)])

  const updateProjectKey = (key: string, value: any) => {
    onUpdate(key, value)
  }

  const onReadNotes = () => {
    projectRepository().markProjectNotesAsRead(project.id)
    updateProjectKey('unreadNotes', 0)
  }

  const customFields = React.useMemo(
    () => getContainer(FieldsState).getCustomFieldsForEntity(CustomFieldEntity.Project),
    [],
  )

  const isBasisLiteFeatureFlag = hasFeature(FeatureType.BasisLite)

  const data = React.useMemo(
    () => ({
      id: currentProject.id,

      ...customFields.reduce(
        (acc, field) => ({
          ...acc,
          [field.id]: (
            <Fields.TableField
              field={field}
              actions={[]}
              onChange={val =>
                updateProjectKey('customFields', {
                  [field.id]: val,
                })
              }
              value={currentProject.customFields[field.id]}
            />
          ),
        }),
        {},
      ),

      ...box(
        mapDefaultFieldsToCustomFieldProps(
          currentProject,
          (key: keyof Project, val: CustomFieldValues) => updateProjectKey(key, val),
          (id, key: any, value) => {
            updateProjectKey(
              'bidInvites',
              currentProject.bidInvites.map(bi =>
                bi.id === id
                  ? {
                      ...bi,
                      [key]: value,
                    }
                  : bi,
              ),
            )

            bidInviteRepository.patchBidInvite(id, key, value)
          },
        ),
      ).fold(fields =>
        keys(fields).reduce(
          (acc, key) => ({ ...acc, [key]: <Fields.TableField {...fields[key]} actions={[]} /> }),
          {},
        ),
      ),

      [ProjectFieldType.ProjectName]: (
        <Name
          project={currentProject}
          onChange={name => updateProjectKey('name', name)}
          onReadNotes={onReadNotes}
        />
      ),

      [ProjectFieldType.BidDeadline]: (
        <BidDeadlinePicker
          project={currentProject}
          onChange={updatedBidInvites => {
            updateProjectKey(
              'bidInvites',
              currentProject.bidInvites.map(bi => {
                const updatedBidInvite = updatedBidInvites.find(({ id }) => id === bi.id)
                if (updatedBidInvite) {
                  return {
                    ...bi,
                    ...updatedBidInvite,
                  }
                }
                return bi
              }),
            )

            Promise.all(
              updatedBidInvites.map(({ id, bidDeadlineAt }) =>
                bidInviteRepository.patchBidInvite(id, 'bidDeadlineAt', bidDeadlineAt),
              ),
            )
          }}
        />
      ),

      [ProjectFieldType.Quotes]: (
        <p style={{ textAlign: 'right', margin: 0 }}>{formatQuote(currentProject.quote)}</p>
      ),

      [ProjectFieldType.CreatedAt]: formatDate(currentProject.createdAt),

      [ProjectFieldType.Location]: (
        <LocationField
          value={currentProject.location}
          width="100%"
          onChange={geocodeResult => {
            updateProjectKey('geocodingData', geocodeResult)
          }}
        />
      ),

      [ProjectFieldType.Region]: currentProject.region,

      [ProjectFieldType.ZipCode]: currentProject.postalCode,
      [ProjectFieldType.AddressLine]: currentProject.addressLine,
      [ProjectFieldType.City]: currentProject.city,

      [ProjectFieldType.Companies]: (
        <CompanyChip
          companies={currentProject.bidInvites
            .filter(bi => Boolean(bi?.company) && !Boolean(bi.company.deletedAt))
            .map(b => ({
              name: b.company.name,
              tag: getContainer(FieldsState).getCustomFieldTagOptionForValue(
                CustomFieldEntity.Company,
                b.company.customFields[
                  getContainer(FieldsState)
                    .getCustomFieldsForEntity(CustomFieldEntity.Company)
                    .find(c => c.isEntityLabel)?.id || ''
                ],
              ),
              onClick: !isBasisLiteFeatureFlag
                ? e => {
                    e.stopPropagation()
                    screens.companyDetails.push(b.company.id)
                  }
                : null,
            }))}
        />
      ),

      [ProjectFieldType.Stage]: (
        <Fields.TableField
          field={{ type: CustomFieldType.StageSelect }}
          nonNullable
          onChange={(newStageId: string) => updateProjectKey('stageId', newStageId)}
          value={currentProject.stageId}
        />
      ),

      [ProjectFieldType.StageReason]: (
        <Fields.TableField
          field={{
            type: CustomFieldType.SingleSelect,
            options: box(getContainer(StagesState))
              .fold(stageContainer =>
                stageContainer.getReasonsForStageCode(
                  stageContainer.getStageForId(currentProject.stageId)?.code,
                ),
              )
              .map(stageReason => ({
                value: stageReason.id,
                label: stageReason.name,
              })),
          }}
          nonNullable
          onChange={(newStageReasonId: string) => {
            updateProjectKey('stageReasonId', newStageReasonId)
            projectRepository().updateProjectReason(
              currentProject.id,
              newStageReasonId,
              getContainer(StagesState).getStageForId(currentProject.stageId)?.code,
            )
          }}
          value={currentProject.stageReasonId}
        />
      ),

      [ProjectFieldType.Contact]: (
        <CompanyChip
          companies={currentProject.bidInvites
            .reduce((acc, bi) => [...acc, ...bi.contacts], [])
            .map(contact => ({
              name: readableName(contact),
              onClick: !isBasisLiteFeatureFlag
                ? e => {
                    e.stopPropagation()
                    screens.contactDetails.push(contact.id)
                  }
                : null,
            }))}
        />
      ),

      [ProjectFieldType.Keyword]: <Keywords keywords={currentProject.keywords}></Keywords>,
    }),
    [JSON.stringify(currentProject)],
  )

  return (
    <Table2.Tr
      data-testid="bid"
      actionsPosition="left"
      actionsWidth={100}
      key={JSON.stringify(currentProject)}
      actions={[
        {
          label: 'Duplicate',
          onAction: () =>
            openDuplicateProjectModal({
              project,
              onConclude: onDuplicate,
            }),
        },
        {
          label: Boolean(project.archivedAt) ? 'Unarchive' : 'Archive',
          onAction: () => onArchive(!Boolean(project.archivedAt)),
          color: colors.warning,
        },
        {
          label: 'Delete',
          onAction: onDelete,
          color: colors.error,
        },
      ]}
      onClick={() => screens.projectDetails.push(currentProject.id)}
    >
      <Table2.Td
        style={{ padding: 0, paddingLeft: 12 }}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <Checkbox
          value={selected}
          onChange={e => {
            onSelect(e.target.checked)
          }}
        />
      </Table2.Td>
      {columns.map((c, i) => (
        <Table2.Td
          className={i === 0 ? 'sticky' : undefined}
          key={`${project.id}-${c.id as string}`}
          data-testid={c.id as string}
          preventHover={([
            ProjectFieldType.Quotes,
            ProjectFieldType.CreatedAt,
            ProjectFieldType.Region,
            ProjectFieldType.ZipCode,
            ProjectFieldType.AddressLine,
            ProjectFieldType.City,
            ProjectFieldType.Companies,
            ProjectFieldType.Contact,
          ] as string[]).includes(c.id)}
        >
          {data[c.id]}
        </Table2.Td>
      ))}
      <Table2.Td />
    </Table2.Tr>
  )
}
