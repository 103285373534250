import { fromNullable } from '@basisboard/basis-ui/es/utils'
import { getContainer } from '@containrz/react-hook'
import equals from 'ramda/src/equals'
import values from 'ramda/src/values'
import { ViewId } from '../../constants'
import { FieldsState, getFields } from '../../containers/Fields'
import { FiltersStateInstances } from '../../containers/Filters'
import { GroupsState } from '../../containers/Groups'
import { eventBus, EventBusType } from '../../services'

eventBus.register(EventBusType.LoadedFilters, (viewId: ViewId) => {
  const filterData = getContainer(FiltersStateInstances(viewId))

  const appliedCustomFilter = [
    ...filterData.state.customFilters,
    ...filterData.state.sharedCustomFilters,
  ].find(cf =>
    equals(JSON.stringify(cf.filter), JSON.stringify(filterData.state.filter?.customFilter || {})),
  )

  if (appliedCustomFilter && (appliedCustomFilter.fields || []).length > 0) {
    getContainer(FieldsState).setState({ [viewId]: appliedCustomFilter.fields })
  }
  if (appliedCustomFilter && appliedCustomFilter.group) {
    getContainer(GroupsState).setState({ currentGroup: appliedCustomFilter.group })
  }
})

eventBus.register(EventBusType.ApplyFilter, async ({ viewId }) => {
  const filterData = getContainer(FiltersStateInstances(viewId))
  const fieldsData = getContainer(FieldsState)
  const groupsData = getContainer(GroupsState)

  const appliedCustomFilter = [
    ...filterData.state.customFilters,
    ...filterData.state.sharedCustomFilters,
  ].find(cf =>
    equals(JSON.stringify(cf.filter), JSON.stringify(filterData.state.filter?.customFilter || {})),
  )

  if (appliedCustomFilter && (appliedCustomFilter.fields || []).length > 0) {
    fieldsData.setState({ [viewId]: appliedCustomFilter.fields })
  } else if (appliedCustomFilter && appliedCustomFilter.group) {
    groupsData.setState({ currentGroup: appliedCustomFilter.group })
  } else {
    groupsData.setState(s => ({ currentGroup: s.storedGroup }))

    const storedFields = await getFields(viewId).then(response => response.fields)

    fromNullable(storedFields).fold(
      () => null,
      fields =>
        fieldsData.setState({
          [viewId]: fields,
        }),
    )
  }
})

eventBus.register(EventBusType.DeleteCustomField, async ({ fieldId }) => {
  values(ViewId).forEach(viewId => {
    const filterData = getContainer(FiltersStateInstances(viewId))
    const customFilters = [
      ...filterData.state.customFilters,
      ...filterData.state.sharedCustomFilters,
    ]

    const newCustomFilters = customFilters.map(cf => {
      const filters = [...cf.filter.and, ...cf.filter.or]
      const invalid =
        cf.invalid ||
        filters.some(({ filterIdentitier }) => filterIdentitier.identifier === fieldId)

      return {
        ...cf,
        fields: cf.fields?.filter(fi => fi.id !== fieldId),
        invalid,
      }
    })

    const selectedFilterIncludesField =
      filterData.state.filter?.customFilter?.and?.some(
        operation => operation.filterIdentitier.identifier === fieldId,
      ) ||
      filterData.state.filter?.customFilter?.or?.some(
        operation => operation.filterIdentitier.identifier === fieldId,
      )

    if (selectedFilterIncludesField) {
      filterData.applyFilter({})
    }

    filterData.setCustomFilters(newCustomFilters)
  })
})
