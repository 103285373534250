"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeaderResizeController = void 0;
class HeaderResizeController {
    constructor(onResize, cellRef, minWidth, setResizing) {
        this.size = 0;
        this.newSize = 0;
        this.initialX = 0;
        this.minHeaderWidth = 200;
        this.onMouseMove = (e) => {
            e.stopPropagation();
            e.preventDefault();
            const delta = e.pageX - this.initialX;
            this.newSize = Math.max(this.minHeaderWidth, this.size + delta);
            this.cellRef.current.setAttribute('style', `width: ${this.newSize}px`);
        };
        this.onMouseDown = (initialWidth) => (e) => {
            e.preventDefault();
            e.stopPropagation();
            document.body.style.cursor = 'col-resize';
            document.body.style.userSelect = 'none';
            this.size = initialWidth || e.currentTarget.parentElement.clientWidth;
            this.handle = e.currentTarget;
            this.initialX = e.pageX;
            this.cellRef.current.setAttribute('style', `width: ${initialWidth}px`);
            this.handle.classList.add('active');
            this.setResizing(true);
            window.addEventListener('mouseup', this.onMouseUp);
            window.addEventListener('mousemove', this.onMouseMove);
        };
        this.onMouseUp = (e) => {
            var _a;
            e.stopPropagation();
            e.preventDefault();
            document.body.style.cursor = '';
            document.body.style.userSelect = '';
            this.handle.classList.remove('active');
            (_a = this.onResize) === null || _a === void 0 ? void 0 : _a.call(this, this.newSize);
            setTimeout(() => {
                this.setResizing(false);
            }, 50);
            this.handle = null;
            window.removeEventListener('mouseup', this.onMouseUp);
            window.removeEventListener('mousemove', this.onMouseMove);
        };
        this.onResize = onResize;
        this.cellRef = cellRef;
        this.minHeaderWidth = minWidth;
        this.setResizing = setResizing;
    }
}
exports.HeaderResizeController = HeaderResizeController;
