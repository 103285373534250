import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Tooltip } from '@basisboard/basis-ui/es/components/Tooltip'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, cssCircle } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled from 'styled-components'
import { BackgroundProps, SpaceProps } from 'styled-system'

export const Circle = styled(Div)<{ size?: number }>`
  ${({ size }) => cssCircle(size || 8)};
  background-color: ${colors.accent};

  display: inline-block;
`

interface Props extends SpaceProps, BackgroundProps {
  content?: string
}

export const NewCircle: React.FC<Props> = ({ content, ...rest }) =>
  content ? (
    <Tooltip
      content={<Text white>{content}</Text>}
      position="topCenter"
      defaultPosition="top"
      variant="dark"
      small
      align="center"
    >
      <Circle data-testid="new-circle" {...rest} />
    </Tooltip>
  ) : (
    <Circle data-testid="new-circle" {...rest} />
  )
