"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PencilHollow = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const PencilHollow = ({ width = 16, height = 16, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M2 14H5.13144L13.3515 5.77998C13.7667 5.36472 14 4.80152 14 4.21426C14 3.627 13.7667 3.0638 13.3515 2.64854C12.9362 2.23329 12.373 2 11.7857 2C11.1985 2 10.6353 2.23329 10.22 2.64854L2 10.8686V14Z", stroke: color, strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round", fill: "transparent" }),
    React.createElement("path", { d: "M10 4L12 6", stroke: color, strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round", fill: "transparent" })));
exports.PencilHollow = PencilHollow;
