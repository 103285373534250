"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tools = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Tools = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 512 512" },
    React.createElement("path", { fill: color, d: "M223.1 96.1l.0046 48.74L253.7 174.6c-6.878-34.87 3.493-70.37 28.49-95.37c20.25-20.25 47.12-31.13 74.99-31.13l1.125-.0001L300.1 105.2l15.13 90.61l90.62 15.11l57.24-57.25c.2526 28.25-10.62 55.49-31.24 75.99c-9.249 9.375-20.12 16.5-31.75 21.63c1.75 1.625 3.874 2.899 5.625 4.648l30.63 30.72c10.5-6.375 20.5-14 29.5-23c37.99-37.1 53.61-94.24 40.61-146.5c-3.001-12.25-12.38-21.87-24.38-25.24c-12.25-3.373-25.25 .1273-33.1 9.002l-58.74 58.62l-32.37-5.371l-5.378-32.5l58.62-58.5c8.874-8.1 12.25-21.1 8.871-33.1c-3.251-12.12-13-21.5-25.25-24.49c-53.12-13.24-107.9 2.01-146.5 40.51c-10.25 10.25-18.5 21.75-25.37 33.1l1.125 .7498L223.1 96.1zM106 453.9c-12.75 12.75-35.25 12.75-48.12 .0045c-6.375-6.374-10-14.1-10-23.1c-.0009-9.124 3.498-17.62 9.997-23.1L192.3 271.6L158.4 237.7l-134.4 134.2c-15.5 15.5-23.1 36.12-23.99 57.1s8.503 42.49 24 57.99c15.5 15.5 36.12 23.99 57.1 23.99c21.87-.002 42.5-8.503 57.99-24l100.9-100.9c-9.626-15.87-15.13-33.87-15.63-52.12L106 453.9zM501.1 395.6l-117.1-117c-23.13-23.12-57.65-27.65-85.39-13.9L191.1 158L191.1 95.99l-127.1-95.99L0 63.1l96 127.1l62.04 .0077l106.7 106.6c-13.62 27.75-9.218 62.27 13.91 85.39l117 117.1c14.5 14.62 38.21 14.62 52.71-.0016l52.75-52.75C515.6 433.7 515.6 410.1 501.1 395.6z" })));
exports.Tools = Tools;
