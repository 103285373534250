"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Company = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Company = ({ width = 24, height = 24, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 24 24" },
    React.createElement("circle", { cx: "12", cy: "12", r: "12", fill: styles_1.colors.lightGray2 }),
    React.createElement("path", { d: "M17.5212 17.8335H17.0003V5.9585C17.0003 5.61331 16.7205 5.3335 16.3753 5.3335H7.62533C7.28014 5.3335 7.00033 5.61331 7.00033 5.9585V17.8335H6.47949C6.30691 17.8335 6.16699 17.9734 6.16699 18.146V18.6668H17.8337V18.146C17.8337 17.9734 17.6937 17.8335 17.5212 17.8335ZM9.50033 7.31266C9.50033 7.14008 9.64025 7.00016 9.81283 7.00016H10.8545C11.0271 7.00016 11.167 7.14008 11.167 7.31266V8.35433C11.167 8.52691 11.0271 8.66683 10.8545 8.66683H9.81283C9.64025 8.66683 9.50033 8.52691 9.50033 8.35433V7.31266ZM9.50033 9.81266C9.50033 9.64008 9.64025 9.50016 9.81283 9.50016H10.8545C11.0271 9.50016 11.167 9.64008 11.167 9.81266V10.8543C11.167 11.0269 11.0271 11.1668 10.8545 11.1668H9.81283C9.64025 11.1668 9.50033 11.0269 9.50033 10.8543V9.81266ZM10.8545 13.6668H9.81283C9.64025 13.6668 9.50033 13.5269 9.50033 13.3543V12.3127C9.50033 12.1401 9.64025 12.0002 9.81283 12.0002H10.8545C11.0271 12.0002 11.167 12.1401 11.167 12.3127V13.3543C11.167 13.5269 11.0271 13.6668 10.8545 13.6668ZM12.8337 17.8335H11.167V15.646C11.167 15.4734 11.3069 15.3335 11.4795 15.3335H12.5212C12.6937 15.3335 12.8337 15.4734 12.8337 15.646V17.8335ZM14.5003 13.3543C14.5003 13.5269 14.3604 13.6668 14.1878 13.6668H13.1462C12.9736 13.6668 12.8337 13.5269 12.8337 13.3543V12.3127C12.8337 12.1401 12.9736 12.0002 13.1462 12.0002H14.1878C14.3604 12.0002 14.5003 12.1401 14.5003 12.3127V13.3543ZM14.5003 10.8543C14.5003 11.0269 14.3604 11.1668 14.1878 11.1668H13.1462C12.9736 11.1668 12.8337 11.0269 12.8337 10.8543V9.81266C12.8337 9.64008 12.9736 9.50016 13.1462 9.50016H14.1878C14.3604 9.50016 14.5003 9.64008 14.5003 9.81266V10.8543ZM14.5003 8.35433C14.5003 8.52691 14.3604 8.66683 14.1878 8.66683H13.1462C12.9736 8.66683 12.8337 8.52691 12.8337 8.35433V7.31266C12.8337 7.14008 12.9736 7.00016 13.1462 7.00016H14.1878C14.3604 7.00016 14.5003 7.14008 14.5003 7.31266V8.35433Z", fill: color })));
exports.Company = Company;
