import { LoadingIndicator } from '@basisboard/basis-ui/es/components/LoadingIndicator'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import Loadable from 'react-loadable'
import { Redirect, Route } from 'react-router-dom'
import { ScreenType } from '../../../screens'
import { eventBus, EventBusType, openIntercom } from '../../../services'
import { ErrorPage } from '../../../templates/ErrorPage'
import { appRepository, AppState, isInternalUse } from '../container'

const componentMap = new Map()
const getComponent = (component: any) => {
  if (componentMap.has(component)) {
    return componentMap.get(component)
  }

  const newComponent = Loadable({
    loader: () => component,
    loading: LoadingIndicator,
  })

  componentMap.set(component, newComponent)

  return newComponent
}

eventBus.register(EventBusType.Logout, () => {
  componentMap.clear()
})

export const SmartRoute: React.FC<{ screen: ScreenType }> = ({ screen }) => {
  useContainer(AppState)

  return (
    <Route
      exact={!screen.notExact}
      path={screen.path}
      render={({ location }) => {
        const { profile, loadingProfile, customerSettings } = appRepository().state

        const Component = getComponent(screen.component)

        if (!screen.requiresLogin) {
          return <Component />
        }

        if (loadingProfile) {
          return <LoadingIndicator />
        }

        if (!profile) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )
        }

        const hasPermission =
          isInternalUse() ||
          !screen.requiresPermissions ||
          screen.requiresPermissions.includes(profile.role)

        if (!hasPermission) {
          return (
            <ErrorPage
              imgSrc="/imgs/auth/auth-ilustration.png"
              width={300}
              title="You do not have permission to access this page"
              description="Ask for an admin to provide you with the necessary permission to further explore your basisboard"
            />
          )
        }

        const hasFeature =
          isInternalUse() ||
          !screen.requiresFeature ||
          customerSettings?.settings?.enabledFeatures.includes(screen.requiresFeature)

        return hasFeature ? (
          <Component />
        ) : (
          <ErrorPage
            title="Seems like your tier doesn't include access to this page"
            description="Want to get access to this page? Get in touch with our team and query about our tiers."
            action="Get in touch"
            onAction={openIntercom}
          />
        )
      }}
    />
  )
}
