import { CustomFieldSetting } from '@basisboard/basis-common/lib/api/custom-fields'
import { Field } from './types'

export const checkAllEntries = (checked: boolean) => {
  const table = document.querySelector('table')
  if (!table) {
    return
  }

  Array.from(table.querySelectorAll('td:nth-child(1) input[type="checkbox"]')).forEach(
    (node: HTMLInputElement) => {
      node.checked = checked
    },
  )
}

export const mapCustomFieldToField = (
  { id, label, type }: CustomFieldSetting,
  entity?: 'company' | 'project',
): Field => ({
  id,
  label,
  type,
  sortKey: entity
    ? {
        name: id,
        entity,
      }
    : id,
  width: 200,
  enabled: false,
})
