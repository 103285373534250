import { FilterIdenfier, FilterOperation, UnionType } from '@basisboard/basis-common/lib/api'
import {
  CustomFieldEntity,
  CustomFieldSetting,
  CustomFieldType,
} from '@basisboard/basis-common/lib/api/custom-fields'
import { CommunicationType, Platform } from '@basisboard/basis-common/lib/enums'
import { colors } from '@basisboard/basis-ui/es/styles'
import { readableCommunicationType, readableName } from '@basisboard/basis-ui/es/utils'
import { getContainer } from '@containrz/react-hook'
import moment from 'moment'
import values from 'ramda/src/values'
import { companyRepository } from '../Companies'
import { mapPlatformToString } from '../Platforms'
import { ScrapersState } from '../Scrapers/container'
import { StagesState } from '../Stages'
import { UsersContainer } from '../Users'
import { ConditionEntity } from './components/ConditionEntry/types'

export const mapUnionTypeToLabel: Record<UnionType, string> = {
  [UnionType.Union]: 'Union',
  [UnionType.Unknown]: 'Unknown',
  [UnionType.NonUnion]: 'Non Union',
  [UnionType.PreVailingWage]: 'Prevailing Wage',
}

export enum FilterType {
  Custom,
  Estimator,
  ShowBids,
  Status,
  Company,
  ReminderType,
  ReminderName,
  ReminderStatus,
}

export const OPERATION_OPTIONS: {
  name: string
  id: string
  operation: FilterOperation
  type?: CustomFieldType
  not?: boolean
}[] = [
  { id: '1', name: 'Is on or after', operation: FilterOperation.After },
  { id: '2', name: 'Is on or before', operation: FilterOperation.Before },
  { id: '3', name: 'Contains', operation: FilterOperation.Contain },
  { id: '4', name: 'Does not contain', operation: FilterOperation.Contain, not: true },
  { id: '5', name: 'Is empty', operation: FilterOperation.Empty },
  { id: '6', name: 'Is not empty', operation: FilterOperation.Empty, not: true },
  { id: '7', name: 'Is equal', operation: FilterOperation.Equal },
  { id: '8', name: 'Is not equal', operation: FilterOperation.Equal, not: true },
  { id: '9', name: 'Is greater than', operation: FilterOperation.Greater },
  { id: '10', name: 'Is not greater than', operation: FilterOperation.Greater, not: true },
  { id: '11', name: 'Is greater than or equal to', operation: FilterOperation.GreaterEqual },
  { id: '12', name: 'Is lesser than', operation: FilterOperation.Less },
  { id: '13', name: 'Is no lesser than', operation: FilterOperation.Less, not: true },
  { id: '14', name: 'Is lesser than or equal to', operation: FilterOperation.LessEqual },
  { id: '15', name: 'Is any of', operation: FilterOperation.IsAnyOf },
  { id: '16', name: 'Is none of', operation: FilterOperation.IsAnyOf, not: true },
  {
    id: '17',
    name: 'Past days',
    operation: FilterOperation.PastDays,
    type: CustomFieldType.Number,
  },
  { id: '18', name: 'Past 7 days', operation: FilterOperation.Past7Days },
  { id: '19', name: 'Past 30 days', operation: FilterOperation.Past30Days },
  {
    id: '20',
    name: 'Next days',
    operation: FilterOperation.NextDays,
    type: CustomFieldType.Number,
  },
  { id: '21', name: 'Next 7 days', operation: FilterOperation.Next7Days },
  { id: '22', name: 'Next 30 days', operation: FilterOperation.Next30Days },
  { id: '23', name: 'Next 365 days', operation: FilterOperation.Next365Days },
]

export const mapOperationToOption = (operations: FilterOperation[]) =>
  operations.reduce(
    (acc, operation) => [
      ...acc,
      ...OPERATION_OPTIONS.filter(({ operation: op }) => op === operation),
    ],
    [],
  )

export const mapDefaultValueForType = {
  [CustomFieldType.Boolean]: true,
  [CustomFieldType.Date]: moment().toISOString(),
  [CustomFieldType.Number]: undefined,
  [CustomFieldType.Percent]: undefined,
  [CustomFieldType.Monetary]: undefined,
}

export const mapDefaultOperationForType = {
  [CustomFieldType.Boolean]: FilterOperation.Equal,
  [CustomFieldType.Date]: FilterOperation.Equal,
  [CustomFieldType.Number]: FilterOperation.Equal,
  [CustomFieldType.Percent]: FilterOperation.Equal,
  [CustomFieldType.Monetary]: FilterOperation.Equal,
  [CustomFieldType.SingleSelect]: FilterOperation.IsAnyOf,
  [CustomFieldType.MultiSelect]: FilterOperation.IsAnyOf,
}

export const getProjectFields = (): CustomFieldSetting[] => [
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.Name.toString(),
    label: 'Name',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.EmailProjectName.toString(),
    label: 'Name',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.ProjectCreatedAt.toString(),
    label: 'Created date',
    type: CustomFieldType.Date,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.BidDueDate.toString(),
    label: 'Bid deadline',
    type: CustomFieldType.Date,
  },
  {
    entity: CustomFieldEntity.Project,
    type: CustomFieldType.MultiSelect,
    id: FilterIdenfier.EstimatorId.toString(),
    label: 'Estimator',
    options: getContainer(UsersContainer).state.allUsers.map(user => ({
      color: user.color,
      label: user.id,
    })),
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.StageId.toString(),
    label: 'Stages',
    type: CustomFieldType.SingleSelect,
    options: getContainer(StagesState).state.stages.map(stage => ({
      color: stage.color,
      label: stage.id,
    })),
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.CompanyId.toString(),
    label: 'Companies',
    type: CustomFieldType.MultiSelect,
    options: companyRepository().state.companyNames.map(company => ({
      color: colors.accentActive,
      label: company.name,
      value: company.id,
      id: company.id,
    })),
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.RFIDueDate.toString(),
    label: 'RFI deadline',
    type: CustomFieldType.Date,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.JobWalkAt.toString(),
    label: 'Job walk',
    type: CustomFieldType.Date,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.ProjectStartAt.toString(),
    label: 'Project start',
    type: CustomFieldType.Date,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.ProjectEndAt.toString(),
    label: 'Project end',
    type: CustomFieldType.Date,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.Location.toString(),
    label: 'Location',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.EmailProjectLocation.toString(),
    label: 'Location',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.ProjectRegion.toString(),
    label: 'State',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.EmailProjectRegion.toString(),
    label: 'State',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.ProjectPostalCode.toString(),
    label: 'Zip code',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.EmailProjectPostalCode.toString(),
    label: 'Zip code',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.ProjectAddressLine.toString(),
    label: 'Address Line',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.EmailProjectAddressLine.toString(),
    label: 'Address Line',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.ProjectCity.toString(),
    label: 'City',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.EmailProjectCity.toString(),
    label: 'City',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.SubmittedAt.toString(),
    label: 'Submitted date',
    type: CustomFieldType.Date,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.LostAt.toString(),
    label: 'Lost date',
    type: CustomFieldType.Date,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.AwardedAt.toString(),
    label: 'Awarded date',
    type: CustomFieldType.Date,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.WonAmount.toString(),
    label: 'Won Amount',
    type: CustomFieldType.Monetary,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.LostAmount.toString(),
    label: 'Lost Amount',
    type: CustomFieldType.Monetary,
  },
  {
    entity: CustomFieldEntity.Project,
    id: FilterIdenfier.SubmittedAmount.toString(),
    label: 'Submitted Amount',
    type: CustomFieldType.Monetary,
  },
  {
    entity: ConditionEntity.Project as any,
    id: FilterIdenfier.EmailUnionType.toString(),
    label: 'Union Status',
    type: CustomFieldType.MultiSelect,
    options: values(UnionType)
      .filter(t => t !== UnionType.Unknown)
      .map(value => ({
        label: mapUnionTypeToLabel[value],
        color: colors.darkCyan,
        id: value,
      })),
  },
  {
    entity: ConditionEntity.Project as any,
    id: FilterIdenfier.ProjectUnionType.toString(),
    label: 'Union Status',
    type: CustomFieldType.MultiSelect,
    options: values(UnionType)
      .filter(t => t !== UnionType.Unknown)
      .map(value => ({
        label: mapUnionTypeToLabel[value],
        color: colors.darkCyan,
        id: value,
      })),
  },
  {
    entity: ConditionEntity.Project as any,
    id: FilterIdenfier.EmailTravelTime.toString(),
    label: 'Travel Time',
    type: CustomFieldType.Number,
  },
  {
    entity: ConditionEntity.Project as any,
    id: FilterIdenfier.EmailDistance.toString(),
    label: 'Distance',
    type: CustomFieldType.Number,
  },
  {
    entity: ConditionEntity.Project as any,
    id: FilterIdenfier.ProjectKeywords.toString(),
    label: 'Keywords',
    type: CustomFieldType.Text,
  },
  {
    entity: ConditionEntity.Project as any,
    id: FilterIdenfier.ProjectUnreadNotes.toString(),
    label: 'New Notes',
    type: CustomFieldType.Text,
  },
]

export const getCompanyFields = (): CustomFieldSetting[] => [
  {
    entity: CustomFieldEntity.Company,
    id: FilterIdenfier.CompanyName.toString(),
    label: 'Name',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Company,
    id: FilterIdenfier.CompanyAddress.toString(),
    label: 'Address',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Company,
    id: FilterIdenfier.CompanyWebsite.toString(),
    label: 'Website',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Company,
    id: FilterIdenfier.WonAmount.toString(),
    label: 'Won Amount',
    type: CustomFieldType.Monetary,
  },
  {
    entity: CustomFieldEntity.Company,
    id: FilterIdenfier.LostAmount.toString(),
    label: 'Lost Amount',
    type: CustomFieldType.Monetary,
  },
  {
    entity: CustomFieldEntity.Company,
    id: FilterIdenfier.SubmittedAmount.toString(),
    label: 'Submitted Amount',
    type: CustomFieldType.Monetary,
  },
  {
    entity: ConditionEntity.Company as any,
    id: FilterIdenfier.EmailCompanyName.toString(),
    label: 'Name',
    type: CustomFieldType.Text,
  },
]

export const getContactFields = (): CustomFieldSetting[] => [
  {
    entity: CustomFieldEntity.Contact,
    id: FilterIdenfier.ContactName.toString(),
    label: 'Name',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Contact,
    id: FilterIdenfier.ContactTitle.toString(),
    label: 'Title',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Contact,
    id: FilterIdenfier.ContactEmail.toString(),
    label: 'Email',
    type: CustomFieldType.Text,
  },
  {
    entity: CustomFieldEntity.Contact,
    id: FilterIdenfier.ContactPhone.toString(),
    label: 'Phone',
    type: CustomFieldType.Text,
  },
]

export const getEmailFields = (): CustomFieldSetting[] => [
  {
    entity: ConditionEntity.Email as any,
    id: FilterIdenfier.EmailSubject.toString(),
    label: 'Subject',
    type: CustomFieldType.Text,
  },
  {
    entity: ConditionEntity.Email as any,
    id: FilterIdenfier.EmailCommunicationType.toString(),
    label: 'Email type',
    type: CustomFieldType.SingleSelect,
    options: values(CommunicationType).map(communicationType => ({
      label: readableCommunicationType(communicationType),
      color: colors.cyan,
      id: communicationType,
    })),
  },
  {
    entity: ConditionEntity.Email as any,
    id: FilterIdenfier.EmailPlatform.toString(),
    label: 'Platform',
    type: CustomFieldType.SingleSelect,
    options: values(Platform).map(platform => ({
      label: mapPlatformToString[platform],
      color: colors.darkCyan,
      id: platform,
    })),
  },
  {
    entity: ConditionEntity.Email as any,
    id: FilterIdenfier.EmailFromAddress.toString(),
    label: 'From Email Address',
    type: CustomFieldType.Text,
  },
  {
    entity: ConditionEntity.Email as any,
    id: FilterIdenfier.EmailFromName.toString(),
    label: 'From Name',
    type: CustomFieldType.Text,
  },
  {
    entity: ConditionEntity.Email as any,
    id: FilterIdenfier.EmailScrapedFromAddress.toString(),
    label: 'Scraped From Email Address',
    type: CustomFieldType.SingleSelect,
    options: getContainer(ScrapersState).state.scrapers.map(s => ({
      label: s.email,
      id: s.email,
      color: '',
    })),
  },
  {
    entity: ConditionEntity.Email as any,
    id: FilterIdenfier.EmailProcessedAt.toString(),
    label: 'Time Received',
    type: CustomFieldType.Date,
  },
  {
    entity: ConditionEntity.Email as any,
    id: FilterIdenfier.EmailBidDeadline.toString(),
    label: 'Bid deadline',
    type: CustomFieldType.Date,
  },
]

export const operationsWithoutValue = [
  FilterOperation.Next30Days,
  FilterOperation.Next365Days,
  FilterOperation.Next7Days,
  FilterOperation.Past7Days,
  FilterOperation.Past30Days,
  FilterOperation.Empty,
]

export const mapConditionEntityToLabel = {
  [ConditionEntity.Project]: 'Project',
  [ConditionEntity.Company]: 'Company',
  [ConditionEntity.Contact]: 'Contacts',
  [ConditionEntity.Email]: 'Email',
}

export const mapCustomFieldToOptions = (customField: CustomFieldSetting): CustomFieldSetting => {
  return (
    {
      [CustomFieldType.CompanyMultiSelect]: {
        ...customField,
        options: companyRepository().state.companyNames.map(company => ({
          color: colors.accentActive,
          value: company.id,
          label: company.name,
        })),
        section: customField.entity,
      },
      [CustomFieldType.UserMultiSelect]: {
        ...customField,
        options: getContainer(UsersContainer).state.allUsers.map(user => ({
          color: user.color,
          value: user.id,
          label: readableName(user),
        })),
        section: customField.entity,
      },
    }[customField?.type] || { ...customField, section: customField.entity }
  )
}
