import { Row } from '@basisboard/basis-ui/es/components/Row'
import { borderRadiusMixin, colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import { rem } from 'polished'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { HEADER_HEIGHT_PX, NOTIFICATION_BANNER_HEIGHT_PX } from '../../styles'

export const Nav = styled.nav.attrs({
  role: 'navigation',
})<{ showUpdates: boolean }>`
  grid-area: navbar;
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: ${HEADER_HEIGHT_PX};
  justify-content: space-between;
  padding: 0 ${spacing(6)};
  position: fixed;
  width: 100%;
  z-index: 5;
  top: 0;

  background-color: ${colors.white};

  @media print {
    display: none !important;
  }

  ${transitionMixin('slow', 'top')};

  ${({ showUpdates }) =>
    showUpdates &&
    css`
      top: ${NOTIFICATION_BANNER_HEIGHT_PX};
    `}

  & > div:hover {
    background: ${colors.blueGray};
  }
`

export const List = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;

  height: 100%;

  margin-right: auto;

  margin-left: ${spacing(6)};
`

export const Menu = styled.ul`
  position: absolute;
  top: calc(100% - 8px);
  left: 0;

  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;

  list-style: none;

  width: 200px;

  padding: ${spacing(2)} 0;
  background-color: ${colors.white};
  border: 1px solid #dadce3;
  ${borderRadiusMixin};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
`

export const MenuEntry = styled.li`
  width: 100%;
  height: 32px;

  &:hover {
    background: ${colors.blueGray};
  }

  ${transitionMixin};

  & a {
    line-height: 32px;
  }
`

export const ListItem = styled.li`
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;

  ${Menu} {
    display: none;
  }

  &:hover {
    ${Menu} {
      display: flex;
    }
  }
`

export const EmailsCountBadge = styled(Row)`
  padding: 0 ${spacing(1)};
  background-color: ${colors.gray};
  color: ${colors.white};
  font-size: ${rem(12)};
  ${borderRadiusMixin};
  margin-left: ${spacing(1)};
  height: 20px;

  ${transitionMixin};
`

export const ItemAnchor = styled(Link)<{
  selected?: boolean
  hasSubmenu?: boolean
}>`
  color: ${colors.gray};
  padding: 0 ${spacing(2)};
  text-decoration: none;
  font-weight: 600;
  font-size: 0.875rem;
  height: 100%;
  display: flex;
  align-items: center;

  ${transitionMixin};

  svg {
    margin-left: ${spacing(1)};
  }

  ${({ selected }) =>
    selected &&
    css`
      color: ${colors.accent};
      background: ${colors.blueGray};
      svg > * {
        fill: ${colors.accent};
      }

      ${EmailsCountBadge} {
        background: ${colors.accent};
      }
    `};

  &:hover {
    color: ${colors.accent};
    background: ${colors.blueGray};

    svg > * {
      fill: ${colors.accent};
    }

    ${EmailsCountBadge} {
      background: ${colors.accent};
    }
  }

  ${({ hasSubmenu, selected }) =>
    hasSubmenu &&
    css`
      &:after {
        content: '';
        margin-left: ${spacing(1)};
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid ${selected ? colors.accent : colors.gray};
        position: absolute;
        top: 9px;
      }
    `};
`

export const Spacer = styled.div`
  height: ${HEADER_HEIGHT_PX};
`

export const Separator = styled.div`
  height: 100%;
  background-color: #eaebee;
  width: 1px;
`
