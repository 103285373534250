import { FeatureType } from '@basisboard/basis-common/lib/api'
import { Card } from '@basisboard/basis-ui/es/components/Card'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { LoadingIndicator } from '@basisboard/basis-ui/es/components/LoadingIndicator'
import { SafeWrapper } from '@basisboard/basis-ui/es/components/SafeWrapper'
import { Tabs } from '@basisboard/basis-ui/es/components/Tabs'
import { HeavyText } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import styled from 'styled-components'
import { width, WidthProps } from 'styled-system'
import { useDebounce } from 'use-debounce'
import { Backdrop, Form, InputType } from '../../components'
import { eventBus, EventBusType } from '../../services'
import { hasFeature } from '../App/container'
import { SearchResult } from './components/SearchResult'
import { SearchState } from './state'

const Wrapper = styled.div<WidthProps>`
  height: 32px;
  margin-left: auto;
  margin-right: ${spacing(3)};
  position: relative;

  ${transitionMixin};

  ${width};
`

export const Search = () => {
  const [searchTerm, setSearchTerm] = React.useState('')
  const [focus, setFocus] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const target = React.useRef<HTMLDivElement>()
  const searchData = useContainer(SearchState)

  const { loading, results } = searchData.state

  React.useEffect(() => {
    if (focus && !open) {
      setOpen(true)
    }
  }, [focus])

  const [term] = useDebounce(searchTerm, 300)

  React.useEffect(() => {
    if (term.length >= 2) {
      searchData.searchForTerm(term)

      eventBus.publish(EventBusType.SearchBy, term)
    }
  }, [term])

  const handleChangeFilter = (filter: string) => {
    eventBus.publish(EventBusType.ApplySearchByFilter, { filter })
  }

  const handleClickResult = (result, filter) => {
    setOpen(false)
    eventBus.publish(EventBusType.SearchResultClick, {
      resultType: filter === 'all' ? result.type : filter,
    })
  }

  const renderResults = React.useCallback(
    selectedFilter => {
      const resultsLength = (results || { [selectedFilter]: [] })[selectedFilter].length
      return (
        <>
          <Div background={colors.lightGray3} height={32} pl={spacing(2)}>
            <HeavyText lineHeight="32px" color={colors.gray}>
              {resultsLength === 0
                ? `Your search didn't return any result for ${selectedFilter}`
                : `Showing ${resultsLength} results`}
            </HeavyText>
          </Div>
          <Div maxHeight={240} overflow="auto">
            {loading && <LoadingIndicator />}
            {resultsLength > 0 &&
              (results || { [selectedFilter]: [] })[selectedFilter].map(result => (
                <SearchResult
                  key={result.id}
                  {...result}
                  onClick={() => handleClickResult(result, selectedFilter)}
                />
              ))}
          </Div>
        </>
      )
    },
    [loading],
  )

  const liteTabIds = ['all', 'projects', 'emails']

  const tabs = React.useMemo(
    () =>
      [
        {
          id: 'all',
          label: 'All',
          component: renderResults('all'),
        },
        {
          id: 'projects',
          label: 'Bids',
          component: renderResults('projects'),
        },
        {
          id: 'companies',
          label: 'Companies',
          component: renderResults('companies'),
        },
        {
          id: 'contacts',
          label: 'People',
          component: renderResults('contacts'),
        },
        {
          id: 'emails',
          label: 'Emails',
          component: renderResults('emails'),
        },
      ].filter(tab => !hasFeature(FeatureType.BasisLite) || liteTabIds.includes(tab.id)),
    [loading],
  )

  return (
    <Wrapper width={open ? 400 : 320} ref={target}>
      {open && <Backdrop onClick={() => setOpen(false)} />}
      <Form
        fields={[
          {
            inputType: InputType.TEXT,
            placeholder: 'Search',
            autoComplete: 'off',
            type: 'search',
            height: 32,
            symbolBackgroundColor: 'transparent',
            symbol: <Icon.Search />,
            value: searchTerm,
            onChange: e => setSearchTerm(e.target.value),
            onFocus: () => setFocus(true),
            onBlur: () => setFocus(false),
          },
        ]}
      />
      {searchTerm.length >= 3 && (
        <SafeWrapper
          target={target.current}
          align="right"
          defaultPosition="bottom"
          show={searchTerm.length >= 3 && open}
          preventFocusTrap
        >
          <Card width={464}>
            <Tabs
              tabGroupId="search-tabs"
              tabs={tabs}
              onChangeTab={handleChangeFilter}
              tabSpace={{ px: spacing(2) }}
            />
          </Card>
        </SafeWrapper>
      )}
    </Wrapper>
  )
}
