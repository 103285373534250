import { useEffect } from 'react'
import { KEYCODE_ESC } from '../constants'

export const useEscapeKey = (callback: () => void) => {
  useEffect(() => {
    const eventHandler = e => {
      if (e.keyCode === KEYCODE_ESC) {
        callback()
      }
    }

    window.addEventListener('keydown', eventHandler)

    return () => {
      window.removeEventListener('keydown', eventHandler)
    }
  })
}
