"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Attachment = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Attachment = ({ width = 16, height = 16, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M3.30222 13.4748C1.78357 11.9079 1.81187 9.38099 3.33824 7.81971L8.7901 2.24308C9.94191 1.06486 11.8142 1.06476 12.9661 2.24308C14.1068 3.40991 14.1082 5.29257 12.9661 6.46078L8.21367 11.3172C7.43771 12.1109 6.16993 12.0998 5.40712 11.2913C4.67223 10.5123 4.6958 9.2777 5.44486 8.51149L9.18087 4.69511C9.34154 4.53101 9.60483 4.5282 9.76894 4.68885L10.3631 5.2705C10.5272 5.43118 10.53 5.69446 10.3694 5.85857L6.63376 9.67458C6.50557 9.8057 6.49767 10.0236 6.61692 10.15C6.73055 10.2704 6.90919 10.2724 7.02466 10.1543L11.7771 5.29787C12.2868 4.77644 12.2868 3.9275 11.7768 3.40578C11.278 2.89566 10.4784 2.8954 9.97941 3.40578L4.52753 8.98239C3.62401 9.90663 3.61008 11.4027 4.49658 12.3174C5.38053 13.2295 6.80938 13.2306 7.69497 12.3248L12.167 7.75042C12.3275 7.58618 12.5908 7.58322 12.755 7.74377L13.3496 8.32503C13.5139 8.48557 13.5169 8.74886 13.3563 8.9131L8.88429 13.4874C7.33585 15.0713 4.83119 15.0525 3.30222 13.4748Z", fill: color })));
exports.Attachment = Attachment;
