import {
  Notification,
  NotificationBidInviteAssigned,
  NotificationBidInviteDateSoon,
  NotificationBidInviteDueDateChanged,
  NotificationCompanyNewNote,
  NotificationContactNewNote,
  NotificationEmailMessageNotSent,
  NotificationMissingPlatformCredentials,
  NotificationNewCompanyInProject,
  NotificationNewMessage,
  NotificationNewNote,
  NotificationNylasAccountStatusChanged,
  NotificationQuoteNewNote,
  NotificationReminder,
  NotificationStageChanged,
  NotificationType,
} from '@basisboard/basis-common/lib/api'
import { IconType } from '@basisboard/basis-ui/es/components/Icon'
import { colors } from '@basisboard/basis-ui/es/styles'
import { readableCommunicationType, readableName } from '@basisboard/basis-ui/es/utils'
import moment from 'moment'
import * as React from 'react'
import { PersonChip } from '../../../../components'
import { NotificationProps } from './types'

export const TYPE_TO_ICON: Record<
  NotificationType,
  {
    color: string
    icon: IconType | ((p: Partial<NotificationProps>) => React.ReactNode)
  }
> = {
  [NotificationType.BidInviteDateSoon]: {
    color: '#FDD835',
    icon: 'Clock',
  },
  [NotificationType.BidInvitePastDueDate]: {
    color: '#FB8C00',
    icon: 'Exclamation',
  },
  [NotificationType.BidInviteDueDateChanged]: {
    color: colors.error,
    icon: 'Exclamation',
  },
  [NotificationType.NewMessage]: {
    color: '#B39DDB',
    icon: 'Notes',
  },
  [NotificationType.NewNote]: {
    color: colors.STAGES[7],
    icon: 'Notes',
  },
  [NotificationType.CompanyNewNote]: {
    color: colors.STAGES[4],
    icon: 'Notes',
  },
  [NotificationType.ProjectAssigned]: {
    color: '',
    icon: ({ me }) => (
      <PersonChip chipOnly hideTooltip name={readableName(me)} color={me.color} size={32} />
    ),
  },
  [NotificationType.Reminder]: {
    color: '#FDD835',
    icon: 'Clock',
  },
  [NotificationType.QuoteNewNote]: {
    color: colors.STAGES[11],
    icon: 'Notes',
  },
  [NotificationType.NewCompanyInProject]: {
    color: colors.STAGES[1],
    icon: 'Company',
  },
  [NotificationType.StageChanged]: {
    color: colors.STAGES[6],
    icon: 'Stages',
  },
  [NotificationType.EmailNotSent]: {
    color: colors.error,
    icon: 'InboxLight',
  },
  [NotificationType.NylasAccountStatusChanged]: {
    color: colors.warning,
    icon: 'Email',
  },
  [NotificationType.ContactNewNote]: {
    color: colors.STAGES[7],
    icon: 'Notes',
  },
  [NotificationType.MissingPlatformCredentials]: {
    color: colors.warning,
    icon: 'Exclamation',
  },
}

export const TYPE_TO_TEXT: Record<
  NotificationType,
  {
    text: (notification: Notification) => string
  }
> = {
  [NotificationType.BidInviteDateSoon]: {
    text: ({
      notificationData: { projectName, type, bidDeadlineAt },
    }: NotificationBidInviteDateSoon) =>
      `<strong>${projectName}</strong> is due ${type} on <strong>${moment(bidDeadlineAt).format(
        'MMMM D, YYYY',
      )}</strong>.`,
  },
  [NotificationType.BidInvitePastDueDate]: {
    text: (notification: NotificationBidInviteDateSoon) =>
      `<strong>${notification.notificationData.projectName}</strong> is now past due. Update the stage of the bid to reflect the appropriate status.`,
  },
  [NotificationType.BidInviteDueDateChanged]: {
    text: (notification: NotificationBidInviteDueDateChanged) =>
      `<strong>${notification.notificationData.projectName}</strong> has a possible due date update. See the bid invite details to make sure it's correct.`,
  },
  [NotificationType.NewMessage]: {
    text: ({ notificationData: { communicationType, projectName } }: NotificationNewMessage) =>
      `A new <strong>${readableCommunicationType(
        communicationType,
      )}</strong> email has been added for <strong>${projectName}</strong>.`,
  },
  [NotificationType.NewNote]: {
    text: ({ notificationData: { actorName, mentioned, projectName } }: NotificationNewNote) =>
      `<strong>${actorName}</strong> ${
        mentioned ? `mentioned you` : `left a note`
      } on the bid <strong>${projectName}</strong>.`,
  },
  [NotificationType.CompanyNewNote]: {
    text: ({
      notificationData: { actorName, mentioned, companyName },
    }: NotificationCompanyNewNote) =>
      `<strong>${actorName}</strong> ${
        mentioned ? `mentioned you` : `left a note`
      } on the company <strong>${companyName}</strong>.`,
  },
  [NotificationType.ContactNewNote]: {
    text: ({
      notificationData: { actorName, mentioned, contactFirstName, contactLastName },
    }: NotificationContactNewNote) =>
      `<strong>${actorName}</strong> ${
        mentioned ? `mentioned you` : `left a note`
      } on the contact <strong>${contactFirstName} ${contactLastName}</strong>.`,
  },
  [NotificationType.QuoteNewNote]: {
    text: ({ notificationData: { actorName, mentioned } }: NotificationQuoteNewNote) =>
      `<strong>${actorName}</strong> ${mentioned ? `mentioned you` : `left a note`} on a quote`,
  },
  [NotificationType.ProjectAssigned]: {
    text: ({ notificationData: { projectName, isTeam } }: NotificationBidInviteAssigned) =>
      `<strong>${projectName}</strong> has been assigned to ${
        isTeam ? "a team you're a member of" : 'you'
      }.`,
  },
  [NotificationType.Reminder]: {
    text: ({ notificationData: { projectName, reminderAt } }: NotificationReminder) =>
      `Your task for bid <strong>${projectName}</strong> is due on ${moment(reminderAt).format(
        'MMMM D, YYYY',
      )}`,
  },
  [NotificationType.NewCompanyInProject]: {
    text: ({ notificationData: { projectName, companyName } }: NotificationNewCompanyInProject) =>
      `Company <strong>${companyName}</strong> was added to bid <strong>${projectName}</strong>`,
  },
  [NotificationType.StageChanged]: {
    text: ({ notificationData: { projectName, stageName } }: NotificationStageChanged) =>
      `Bid <strong>${projectName}</strong> was moved to stage <strong>${stageName}</strong>`,
  },
  [NotificationType.EmailNotSent]: {
    text: ({ notificationData: { projectName, subject } }: NotificationEmailMessageNotSent) =>
      `Email with subject <strong>${subject}</strong> on bid <strong>${projectName}</strong> was not sent`,
  },
  [NotificationType.NylasAccountStatusChanged]: {
    text: ({ notificationData: { status } }: NotificationNylasAccountStatusChanged) =>
      `An email sync was moved to status <strong>${status}</strong>`,
  },
  [NotificationType.MissingPlatformCredentials]: {
    text: ({ notificationData: { platforms } }: NotificationMissingPlatformCredentials) =>
      `The following platforms are missing credentials: ${platforms}`,
  },
}

export const TYPE_TO_URL: {
  [key in NotificationType]?: {
    getUrl: (notification: Notification) => string
  }
} = {
  [NotificationType.NewMessage]: {
    getUrl: (notification: NotificationNewMessage) =>
      `/projects/${notification.projectId}?tab=emails&messageId=${notification.messageId}`,
  },
  [NotificationType.CompanyNewNote]: {
    getUrl: (notification: NotificationCompanyNewNote) => `/companies/${notification.companyId}`,
  },
  [NotificationType.ContactNewNote]: {
    getUrl: (notification: NotificationContactNewNote) => `/people/${notification.contactId}`,
  },
  [NotificationType.QuoteNewNote]: {
    getUrl: (notification: NotificationQuoteNewNote) =>
      `/projects/${notification.projectId}?tab=quote`,
  },
  [NotificationType.NewCompanyInProject]: {
    getUrl: (notification: NotificationNewCompanyInProject) =>
      `/projects/${notification.projectId}`,
  },
  [NotificationType.StageChanged]: {
    getUrl: (notification: NotificationStageChanged) => `/projects/${notification.projectId}`,
  },
  [NotificationType.Reminder]: {
    getUrl: (notification: NotificationStageChanged) =>
      `/projects/${notification.projectId}?tab=tasks`,
  },
  [NotificationType.EmailNotSent]: {
    getUrl: (notification: NotificationStageChanged) =>
      `/projects/${notification.projectId}?tab=emails`,
  },
  [NotificationType.NylasAccountStatusChanged]: {
    getUrl: () => `/settings/email-accounts`,
  },
}
