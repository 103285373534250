import { IconType } from '@basisboard/basis-ui/es/components/Icon'

export enum ToastType {
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
}

export interface ToastInterface {
  type: ToastType
  message: string
  icon?: IconType
  onDismiss?: () => void
  children?: React.ReactNode
  loading?: boolean
  duration?: number
  select?: {
    options: { id: string; name: string }[]
    onChange: (val: string) => void
    url: string
    value?: string
  }
  actions?: {
    label: string
    onAction: () => void
  }[]
  overrideIndexZ?: boolean
}
