"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrowDown = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const ArrowDown = ({ width = 14, height = 14, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 14 14" },
    React.createElement("path", { d: "M6.61275 9.19413L3.51379 6.09517C3.2996 5.88098 3.2996 5.53463 3.51379 5.32271L4.02877 4.80774C4.24296 4.59355 4.58931 4.59355 4.80123 4.80774L7.00012 7.00207L9.19674 4.80546C9.41093 4.59127 9.75728 4.59127 9.9692 4.80546L10.4864 5.32043C10.7006 5.53463 10.7006 5.88098 10.4864 6.09289L7.38749 9.19185C7.1733 9.40832 6.82694 9.40832 6.61275 9.19413Z", fill: color })));
exports.ArrowDown = ArrowDown;
