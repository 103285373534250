"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tag = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Tag = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M1.33398 7.89907V2.5835C1.33398 1.89313 1.89362 1.3335 2.58398 1.3335H7.89956C8.23108 1.3335 8.54902 1.46519 8.78344 1.69962L14.3012 7.21738C14.7893 7.70553 14.7893 8.49699 14.3012 8.98514L8.98563 14.3007C8.49747 14.7889 7.70602 14.7889 7.21786 14.3007L1.7001 8.78295C1.46568 8.54853 1.33399 8.23059 1.33398 7.89907ZM4.25065 3.00016C3.56029 3.00016 3.00065 3.5598 3.00065 4.25016C3.00065 4.94053 3.56029 5.50016 4.25065 5.50016C4.94102 5.50016 5.50065 4.94053 5.50065 4.25016C5.50065 3.5598 4.94102 3.00016 4.25065 3.00016Z", fill: color })));
exports.Tag = Tag;
