import {
  GetCustomerPdlStatsResponseBody,
  GetCustomerSettingsResponseBody,
  GetPlanCategoriesResponseBody,
  GetProfileResponse,
  PatchCustomerSettingsRequestBody,
} from '@basisboard/basis-common/lib/api'
import moment from 'moment'
import { del, get, patch, put } from '../../api'

export const getProfile = () => get<GetProfileResponse>('/profile').then(response => response.data)

export const patchProfile = (body: unknown) => patch('/profile', JSON.stringify(body))

export const updateLastUpdateTime = (val: unknown) =>
  put('/profile/settings/lastUpdate', JSON.stringify({ value: val }))

export const setLastLogin = () =>
  put('/profile/settings/lastLogin', { value: { lastLogin: moment().toISOString() } })

export const setNotifications = notifications =>
  put('/profile/settings/NOTIFICATIONS', { value: notifications })

export const getCustomerSettings = () =>
  get<GetCustomerSettingsResponseBody>('/customer-settings').then(response => response.data)

export const patchCustomerSettings = (body: PatchCustomerSettingsRequestBody) =>
  patch<GetCustomerSettingsResponseBody>('/customer-settings', body).then(response => response.data)

export const putUserSetting = (key: string, value: Record<string, unknown>) =>
  put(`/profile/settings/${key}`, { value: value })

export const putUserSettings = (key: string, value: Record<string, unknown>) =>
  put(`/profile/settings/${key}`, { value: { [key]: value } })

export const getUserSettings = (key: string) =>
  get(`/profile/settings/${key}`).then(response => response.data.value[key])

export const logout = () => del('/sessions')

export const getPlanCategories = () =>
  get<GetPlanCategoriesResponseBody>('/plan-categories').then(response => response.data)

export const getPdlStats = () =>
  get<GetCustomerPdlStatsResponseBody>('/customers/pdl-stats')
    .then(response => response.data)
    .catch(() => null)
