"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableController = exports.SHOW_SHADOW_CLASS_NAME = void 0;
exports.SHOW_SHADOW_CLASS_NAME = 'show-shadow';
class TableController {
    constructor() {
        this.setTableRef = (tableRef) => (this.tableRef = tableRef);
        this.setLoadMoreSentinelRef = (loadMoreSentinelRef) => (this.loadMoreSentinelRef = loadMoreSentinelRef);
        this.setShowLineSentinelRef = (showLineSentinelRef) => (this.showLineSentinelRef = showLineSentinelRef);
        this.setShowBlurSentinelRef = (showBlurSentinelRef) => (this.showBlurSentinelRef = showBlurSentinelRef);
        this.registerListeners = (onReachedEnd) => {
            const tableObserver = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    var _a;
                    (_a = this.tableRef) === null || _a === void 0 ? void 0 : _a.classList[entry.isIntersecting ? 'remove' : 'add'](exports.SHOW_SHADOW_CLASS_NAME);
                });
            }, {
                rootMargin: '5px',
            });
            tableObserver.observe(this.showLineSentinelRef);
            const loadMoreObserver = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        onReachedEnd === null || onReachedEnd === void 0 ? void 0 : onReachedEnd();
                    }
                });
            }, {
                rootMargin: '5px',
            });
            loadMoreObserver.observe(this.loadMoreSentinelRef);
            return () => {
                tableObserver.disconnect();
                loadMoreObserver.disconnect();
            };
        };
    }
}
exports.TableController = TableController;
