import { CustomFieldValues } from '@basisboard/basis-common/lib/api/custom-fields'
import { Table2 } from '@basisboard/basis-ui/es/components/Table2'
import { useOutsideClick } from '@basisboard/basis-ui/es/hooks/useOutsideClick'
import { changeFocusToElement } from '@basisboard/basis-ui/es/utils'
import * as React from 'react'
import { TableFieldProps } from '../../type'
import { ActionsBar } from '../ActionsBar'
import { MultiSearch } from '../MultiSearch'
import { SelectorValues } from '../SelectorValues'
import { CellArea, FieldWrapper, Wrapper } from '../styled'
import { MultiSelectedOptions } from './components/MultiSelectedOptions'

interface Options {
  value: string
  label: string
}

const getOptionValues = (options: Options[], value: CustomFieldValues) => {
  if (Array.isArray(value)) {
    return value.reduce((acc, curr) => {
      const optionFound = options.find(opt => opt.value === curr)

      if (!optionFound) {
        return acc
      }

      return [...acc, optionFound.value]
    }, [])
  }

  const optionFound = options.find(opt => opt.value === value)
  return optionFound ? [optionFound] : []
}

export const MultiSelect: React.FC<TableFieldProps> = props => {
  const {
    value,
    field: { options },
    onChange,
    renderOption,
    onDismiss,
    customActions,
    forceShowEdit,
    children,
    readonly,
    actions,
    autoApply,
    ...remainingProps
  } = props

  const [showEdit, setShowEdit] = React.useState(forceShowEdit || false)
  const [ref, setRef] = React.useState<HTMLDivElement>()

  const [chosenOptions, setChosenOptions] = React.useState<string[]>(
    getOptionValues(options || [], value),
  )

  const handleChange = (val: string) =>
    setChosenOptions(opts => {
      const newOptions = [...opts, val]

      autoApply && onChange?.(newOptions)

      return newOptions
    })

  const handleDeleteOption = (optionIndex: number) => {
    const newOptions = chosenOptions.filter((_, index) => index !== optionIndex)

    setChosenOptions(newOptions)
  }

  React.useEffect(() => {
    if (options?.length > 0 && value) {
      setChosenOptions(getOptionValues(options || [], value))
    }
  }, [options, value])

  useOutsideClick([{ current: ref?.parentElement }], e => {
    if (!document.querySelector('#overlays').contains(e.target as Node) && showEdit) {
      setShowEdit(false)
      onChange?.(chosenOptions)
      onDismiss?.()
    }
  })

  const handleDismiss = React.useCallback(
    (preventStoring?: boolean) => {
      if (!preventStoring) {
        onChange?.(chosenOptions)
      }
      onDismiss?.()
    },
    [chosenOptions],
  )

  React.useEffect(() => {
    if (!showEdit || !ref) {
      return
    }

    const onKeyDown = e => {
      if (e.key === 'Enter') {
        e.preventDefault()
      }
      if (e.key === 'Escape') {
        setShowEdit(false)
        setChosenOptions(getOptionValues(options || [], value))
        onDismiss?.()
      } else if (e.key === 'Tab') {
        e.preventDefault()
        ref.setAttribute('tabindex', '0')
        ref.focus()
        changeFocusToElement(e.shiftKey ? 'prev' : 'next', document.body)
        ref.removeAttribute('tabindex')
        setShowEdit(false)
        onChange?.(chosenOptions)
        onDismiss?.()
      }
    }

    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [showEdit, ref, chosenOptions])

  if (props.searchable || (props.field.options || []).length > 7) {
    return (
      <MultiSearch
        {...(props as any)}
        value={chosenOptions}
        onChange={handleChange}
        onDismiss={handleDismiss}
        handleDeleteOption={handleDeleteOption}
      />
    )
  }

  return (
    <Wrapper
      ref={setRef}
      {...remainingProps}
      onClick={actions ? undefined : e => e.stopPropagation()}
    >
      <MultiSelectedOptions
        value={chosenOptions}
        renderOption={renderOption}
        options={options}
        shouldHaveCommaSeparator
      >
        {children}
      </MultiSelectedOptions>

      {actions && (
        <ActionsBar
          actions={actions}
          onClick={() => window.getSelection().type !== 'Range' && ref?.focus()}
          onFocus={() => setShowEdit(true)}
        />
      )}

      {showEdit && ref ? (
        <Table2.ActionsWrapper
          width={250}
          {...customActions}
          anchorElement={ref.parentElement}
          customActions={
            <>
              <SelectorValues
                renderOption={renderOption}
                options={options.filter(opt => !chosenOptions.some(v => opt.value === v))}
                value={chosenOptions}
                onChange={handleChange}
              />

              {customActions?.customActions}
            </>
          }
        >
          <FieldWrapper>
            {chosenOptions.length > 0 ? (
              <MultiSelectedOptions
                value={chosenOptions}
                renderOption={renderOption}
                options={options}
                shouldHaveDelete
                onDeleteClick={handleDeleteOption}
              >
                {children}
              </MultiSelectedOptions>
            ) : null}
          </FieldWrapper>
        </Table2.ActionsWrapper>
      ) : (
        Boolean(onChange) &&
        !readonly &&
        !actions && (
          <CellArea
            tabIndex={0}
            onFocus={() => setShowEdit(true)}
            onClick={() => window.getSelection().type !== 'Range' && ref.focus()}
          />
        )
      )}
    </Wrapper>
  )
}
