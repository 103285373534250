import { LoadingIndicator } from '@basisboard/basis-ui/es/components/LoadingIndicator'
import { Modal as BaseModal } from '@basisboard/basis-ui/es/components/Modal'
import { retryLazyLoad } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import Loadable from 'react-loadable'
import { ScreenType } from '../../screens'
import { Footer } from './screens/styled'
import { modal } from './state'
import { ComponentInterface, ModalType } from './types'

export * from './state'
export * from './types'

const COMPONENTS: Record<ModalType, { Component: ScreenType['component']; width: number }> = {
  [ModalType.CalendarSubscription]: {
    Component: retryLazyLoad(() => import('./screens/CalendarSubscription')),
    width: 576,
  },

  [ModalType.CreateCompany]: {
    Component: retryLazyLoad(() => import('./screens/CreateCompany')),
    width: 600,
  },

  [ModalType.CreateDistributionList]: {
    Component: retryLazyLoad(() => import('./screens/CreateDistributionListModal')),
    width: 640,
  },

  [ModalType.CreateTask]: {
    Component: retryLazyLoad(() => import('./screens/CreateTask')),
    width: 600,
  },

  [ModalType.CreateTeam]: {
    Component: retryLazyLoad(() => import('./screens/CreateTeam')),
    width: 600,
  },

  [ModalType.ExportList]: {
    Component: retryLazyLoad(() => import('./screens/ExportList')),
    width: 576,
  },
}

const componentMap = new Map()
const getComponent = (component: any) => {
  if (componentMap.has(component)) {
    return componentMap.get(component)
  }

  const newComponent = Loadable({
    loader: () => component,
    loading: LoadingIndicator,
  })

  componentMap.set(component, newComponent)

  return newComponent
}

export const Modal = {
  Footer: Footer,
}

export const ModalSystem = () => {
  const modalState = useContainer(modal)
  const { modalDataStack } = modalState.state

  if (modalDataStack.length === 0) {
    return <div className="visually-hidden" style={{ visibility: 'hidden' }} />
  }

  return (
    <>
      {modalDataStack.map((modalData, index) => {
        const { Component, width } =
          'type' in modalData ? COMPONENTS[modalData.type] : (modalData as ComponentInterface)

        const modalType =
          'type' in modalData ? modalData.type : (modalData as ComponentInterface).name

        const ModalComponent = getComponent(Component)

        const focused = index === modalDataStack.length - 1

        const id = 'type' in modalData ? modalData.type : modalData.name

        return (
          <BaseModal
            key={modalType}
            {...modalData}
            width={(modalData as ComponentInterface).width || width}
            minWidth={(modalData as ComponentInterface).minWidth}
            focused={focused}
            onDismiss={() => modalState.closeModal(id)}
            id={id}
          >
            {onClose => (
              <ModalComponent
                {...((modalData?.props as any) || {})}
                onClose={onClose}
                lastOpenAt={modalState.state.lastOpenAt}
              />
            )}
          </BaseModal>
        )
      })}
    </>
  )
}
