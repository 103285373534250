"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BidDeadline = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const BidDeadline = ({ width = 16, height = 16, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M7.99935 1.54163C4.43164 1.54163 1.54102 4.43225 1.54102 7.99996C1.54102 11.5677 4.43164 14.4583 7.99935 14.4583C11.5671 14.4583 14.4577 11.5677 14.4577 7.99996C14.4577 4.43225 11.5671 1.54163 7.99935 1.54163ZM9.48633 10.6588L7.18945 8.98954C7.10872 8.92965 7.06185 8.8359 7.06185 8.73694V4.35413C7.06185 4.18225 7.20247 4.04163 7.37435 4.04163H8.62435C8.79622 4.04163 8.93685 4.18225 8.93685 4.35413V7.94006L10.5905 9.14319C10.7311 9.24475 10.7598 9.44006 10.6582 9.58069L9.92383 10.5911C9.82226 10.7291 9.62695 10.7604 9.48633 10.6588Z", fill: color })));
exports.BidDeadline = BidDeadline;
