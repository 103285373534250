"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Row = void 0;
const React = __importStar(require("react"));
const styled_components_1 = __importStar(require("styled-components"));
const useHover_1 = require("../../../../hooks/useHover");
const useOutsideClick_1 = require("../../../../hooks/useOutsideClick");
const styles_1 = require("../../../../styles");
const Button_1 = require("../../../Button");
const Card_1 = require("../../../Card");
const Icon_1 = require("../../../Icon");
const SafeWrapper_1 = require("../../../SafeWrapper");
const Tr = styled_components_1.default.tr `
  ${({ onClick }) => (Boolean(onClick) ? 'cursor: pointer' : '')};
  ${({ isHovering }) => isHovering &&
    (0, styled_components_1.css) `
      &:hover td {
        background-color: ${styles_1.colors.blueGray};
      }
    `}
`;
const MenuWrapper = styled_components_1.default.div `
  position: absolute;
  transform: translate(-50%, 50%);
`;
const MenuTrigger = styled_components_1.default.button `
  padding: 0;
  margin: 0;

  height: 24px;
  width: 12px;
  ${styles_1.boxShadowMixin};
  ${styles_1.borderRadiusMixin};
  background-color: ${styles_1.colors.lightGray};

  svg {
    margin-top: 4px;
    margin-left: -2px;
    transform: rotate(90deg);
  }
`;
const Row = ({ onClick, actions, actionsWidth, actionsPosition = 'right', children, highlightHover, className, }) => {
    const _a = (0, useHover_1.useHover)(), { hovering } = _a, callbacks = __rest(_a, ["hovering"]);
    const [showActions, setShowActions] = React.useState(false);
    const ref = React.useRef();
    const triggerRef = React.useRef();
    (0, useOutsideClick_1.useOutsideClick)([ref, triggerRef], e => {
        var _a;
        if (!document.getElementById('overlays').contains(e.target) &&
            !((_a = document.getElementById('modal-overlay')) === null || _a === void 0 ? void 0 : _a.contains(e.target))) {
            return setShowActions(false);
        }
        let t = e.target;
        const limit = 5;
        let c = 0;
        while (t && t.tagName !== 'BUTTON' && c < limit) {
            t = t.parentElement;
            c += 1;
        }
        if (t.classList.contains('menu-trigger')) {
            setShowActions(false);
        }
    });
    return (React.createElement(Tr, Object.assign({ className: className, "data-testid": "row", onClick: onClick
            ? () => {
                if (!['INPUT', 'SELECT', 'TEXTAREA'].includes(document.activeElement.tagName)) {
                    onClick();
                }
            }
            : undefined, ref: ref }, (actions ? callbacks : {}), { isHovering: highlightHover }),
        children,
        (hovering || showActions) && (React.createElement(SafeWrapper_1.SafeWrapper, { preventFocusTrap: true, show: true, target: ref.current.firstElementChild, defaultPosition: "center", align: "left", preventScrollAdjustment: true },
            React.createElement(MenuWrapper, null,
                React.createElement(MenuTrigger, { "data-testid": "menu-trigger", ref: triggerRef, className: "menu-trigger", onClick: e => {
                        e.stopPropagation();
                        setShowActions(true);
                    } },
                    React.createElement(Icon_1.Icon.Ellipsis, null)),
                showActions && (React.createElement(Card_1.Card, Object.assign({ position: "absolute" }, { [actionsPosition]: 20 }, { arrowPosition: actionsPosition, top: "50%", width: actionsWidth, padding: (0, styles_1.spacing)(1), style: { transform: 'translateY(-50%)' } }),
                    React.createElement("ul", null, actions.map(action => (React.createElement("li", { key: action.label },
                        React.createElement(Button_1.Button.Text, { color: action.color, p: 0, onClick: e => {
                                e.stopPropagation();
                                action.onAction(e);
                            } }, action.label))))))))))));
};
exports.Row = Row;
