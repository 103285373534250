import { Calendar } from '@basisboard/basis-ui/es/components/Calendar'
import { Card } from '@basisboard/basis-ui/es/components/Card'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { spacing } from '@basisboard/basis-ui/es/styles'
import moment, { Moment } from 'moment'
import * as React from 'react'
import { useEnterKey, useEscapeKey } from '../../../../hooks'
import { Label } from '../Label'
import { Input, Wrapper } from './styled'

export interface DateSelectProps {
  label?: string
  required?: boolean
  value?: Moment
  initialValue?: Moment
  onChange?: (date: Moment) => void
  hideBorder?: boolean
  alignRight?: boolean
  hideIcon?: boolean
  showOnTop?: boolean
  name?: string
  preventDefaultDate?: boolean
}

export const DateSelect = React.forwardRef<HTMLInputElement, DateSelectProps>(
  (
    {
      label,
      value,
      initialValue,
      onChange,
      hideBorder,
      alignRight,
      hideIcon,
      showOnTop,
      preventDefaultDate,
      ...props
    },
    ref,
  ) => {
    const now = preventDefaultDate ? undefined : moment()

    const [focused, setFocused] = React.useState(false)
    const [isOpen, setIsOpen] = React.useState(false)
    const timeout = React.useRef<number>()
    const buttonRef = React.useRef<HTMLDivElement>()
    const inputRef = React.useRef<HTMLInputElement>()
    const [date, setDate] = React.useState(initialValue || value || now)
    const [localValue, setLocalValue] = React.useState(initialValue || value || now)

    React.useEffect(() => {
      setDate(initialValue || value || now)
    }, [value])

    const handleChangeDate = React.useCallback(
      (d: string | Moment) => {
        setDate(typeof d === 'string' ? moment(d) : d)
        const parsedDate = moment(d)
        if (parsedDate.isValid()) {
          setLocalValue(parsedDate)
        }
      },
      [setDate, value],
    )

    const handleFocus = () => {
      setFocused(true)
      timeout.current && clearTimeout(timeout.current)
    }

    const handleBlur = () => {
      onChange?.(
        localValue?.isValid()
          ? localValue.set({
              hour: (value || moment()).get('hour'),
              minute: (value || moment()).get('minute'),
              second: (value || moment()).get('second'),
            })
          : null,
      )
      timeout.current = window.setTimeout(handleClose, 100)
    }

    const handleClose = () => {
      setFocused(false)
      setIsOpen(false)
      buttonRef.current?.blur()
    }

    const handleOpen = () => {
      setFocused(true)
      setIsOpen(true)
      inputRef.current?.focus()
    }

    useEscapeKey(handleClose)
    useEnterKey(handleBlur)

    return (
      <>
        {label && <Label label={label} required={props.required} />}
        <Wrapper
          onClick={handleOpen}
          ref={buttonRef}
          focused={focused}
          hideBorder={hideBorder}
          hideIcon={hideIcon}
        >
          <input
            className="visually-hidden"
            ref={ref}
            {...(props as any)}
            value={localValue?.toISOString() || undefined}
          />
          <Div>
            <Input
              data-testid="date-input"
              ref={inputRef}
              onChange={e => handleChangeDate(e.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={date?.format('MMM DD, YYYY')}
              required={props.required}
            />
          </Div>
          {!hideIcon && (
            <Div
              position="absolute"
              left={16}
              size={16}
              top="50%"
              style={{ transform: 'translateY(-50%)' }}
            >
              <Icon.Calendar />
            </Div>
          )}
          {isOpen && (
            <Card
              zIndex={Number.MAX_SAFE_INTEGER}
              position="absolute"
              top={showOnTop ? undefined : 'calc(100% + 4px)'}
              bottom={showOnTop ? 'calc(100% + 4px)' : undefined}
              {...(alignRight ? { right: '-1px' } : { left: '-1px' })}
              width={288}
              p={spacing(2)}
              onMouseEnter={e => e.stopPropagation()}
              onMouseLeave={e => e.stopPropagation()}
              onMouseDown={e => {
                e.preventDefault()
              }}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
              style={{ pointerEvents: 'all' }}
            >
              <Calendar
                value={localValue}
                onChange={handleChangeDate}
                initialValue={initialValue}
              />
            </Card>
          )}
        </Wrapper>
      </>
    )
  },
)
