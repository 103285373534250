"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabsController = void 0;
const monads_1 = require("../../utils/monads");
class TabsController {
    constructor(onChangeTab) {
        this.highlighterRef = (highlighter) => (this.highlighterElement = highlighter);
        this.listRef = (list) => (this.listElement = list);
        this.adjustHighlighterSize = (mirrorElement) => (0, monads_1.fromNullable)(mirrorElement).fold(() => null, () => this.highlighterElement.setAttribute('style', `width: ${mirrorElement.clientWidth}px;transform: translateX(${mirrorElement.offsetLeft}px)`));
        this.loadHighlighter = () => this.adjustHighlighterSize(this.listElement.querySelector(`[aria-selected="true"]`));
        this.changeTab = (selectedElement) => {
            var _a;
            this.adjustHighlighterSize(selectedElement);
            Array.from(this.listElement.querySelectorAll('[role="tab"]')).forEach(tab => {
                tab.setAttribute('aria-selected', 'false');
                document.getElementById(`${tab.id}_tab`).setAttribute('aria-hidden', 'true');
            });
            selectedElement.setAttribute('aria-selected', 'true');
            selectedElement.setAttribute('tabindex', '0');
            document.getElementById(`${selectedElement.id}_tab`).setAttribute('aria-hidden', 'false');
            (_a = this.onChangeTab) === null || _a === void 0 ? void 0 : _a.call(this, selectedElement.id);
        };
        this.handleChange = (e) => {
            this.changeTab(e.currentTarget);
        };
        this.handleKeyDown = (ev) => {
            var _a, _b;
            const { key } = ev;
            switch (key) {
                case 'ArrowRight': {
                    let nextTab = ev.target.nextElementSibling;
                    if (!nextTab) {
                        nextTab = (_a = ev.target.parentNode) === null || _a === void 0 ? void 0 : _a.firstElementChild;
                    }
                    ev.currentTarget.setAttribute('tabindex', '-1');
                    nextTab.focus();
                    this.changeTab(nextTab);
                    return;
                }
                case 'ArrowLeft': {
                    let nextTab = ev.target.previousElementSibling;
                    if (!nextTab) {
                        nextTab = (_b = ev.target.parentNode) === null || _b === void 0 ? void 0 : _b.lastElementChild;
                    }
                    ev.currentTarget.setAttribute('tabindex', '-1');
                    nextTab.focus();
                    this.changeTab(nextTab);
                    return;
                }
            }
        };
        this.onChangeTab = onChangeTab;
    }
}
exports.TabsController = TabsController;
