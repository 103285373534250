import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Card } from '@basisboard/basis-ui/es/components/Card'
import { Checkbox } from '@basisboard/basis-ui/es/components/Checkbox'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { Row } from '@basisboard/basis-ui/es/components/Row'
import { SafeWrapper } from '@basisboard/basis-ui/es/components/SafeWrapper'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import insert from 'ramda/src/insert'
import uniqBy from 'ramda/src/uniqBy'
import * as React from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Field } from '../../templates/ViewsScreen/types'
import { Entry, List, Wrapper } from './styled'

interface FieldEntryProps extends Field {
  onToggleField: (fieldId: string, state: boolean) => void
  onMoveField: (id: string, index: number) => void
  onCommit: () => void
  findFieldIndex: (fieldId: string) => { field: Field; index: number }
  enableFirstField: boolean
}

const FieldEntry: React.FC<FieldEntryProps> = ({
  id,
  onMoveField,
  onToggleField,
  onCommit,
  enabled,
  label,
  findFieldIndex,
  enableFirstField,
}) => {
  const originalIndex = findFieldIndex(id).index
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'field-entry',
      item: { id, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item
        const didDrop = monitor.didDrop()
        if (!didDrop) {
          onMoveField(droppedId, originalIndex)
        } else {
          setTimeout(() => onCommit(), 1)
        }
      },
    }),
    [id, originalIndex, onMoveField],
  )

  const [, drop] = useDrop(
    () => ({
      accept: 'field-entry',
      hover({ id: draggedId }: Field) {
        if (draggedId !== id) {
          const { index: overIndex } = findFieldIndex(id)
          onMoveField(draggedId, overIndex)
        }
      },
    }),
    [findFieldIndex, onMoveField],
  )

  const opacity = isDragging ? 0.5 : 1

  return (
    <Entry
      ref={originalIndex === 0 && !enableFirstField ? undefined : node => drag(drop(node))}
      style={{ opacity }}
    >
      <Row mr={spacing(0.25)} ml={spacing(-1)} style={{ cursor: 'grab' }}>
        <Icon.DragHandle />
      </Row>
      <Checkbox
        color={colors.darkBlue}
        disabled={originalIndex === 0 && !enableFirstField}
        value={enabled}
        label={label}
        onChange={e => onToggleField(id, e.target.checked)}
      />
    </Entry>
  )
}

interface Props {
  fields: Field[]
  onToggleField: (fieldId: string, state: boolean) => void
  onReorderFields: (fields: Field[]) => void
  onResetFields?: () => void
  enableFirstField?: boolean
}

export const TableFieldsToggle: React.FC<Props> = ({
  fields: baseFields,
  onToggleField,
  onReorderFields,
  onResetFields,
  enableFirstField = false,
}) => {
  const [fields, setFields] = React.useState(baseFields)
  const targetRef = React.useRef<HTMLDivElement>()
  const [showFields, setShowFields] = React.useState(false)

  const findField = React.useCallback(
    (id: string) => {
      const field = fields.filter(c => `${c.id}` === id)[0]

      return {
        field,
        index: fields.indexOf(field),
      }
    },
    [fields],
  )

  const moveField = React.useCallback(
    (id: string, atIndex: number) => {
      const { field } = findField(id)
      setFields(f =>
        uniqBy(
          ({ id }) => id,
          insert(
            atIndex,
            field,
            f.filter(fl => fl.id !== field.id),
          ),
        ),
      )
    },
    [findField, fields, setFields],
  )

  React.useEffect(() => {
    if (JSON.stringify(fields) !== JSON.stringify(baseFields)) {
      setFields(baseFields)
    }
  }, [JSON.stringify(baseFields)])

  const handleCommit = React.useCallback(() => onReorderFields(fields), [fields])

  return (
    <>
      <Wrapper ref={targetRef}>
        <button data-testid="list-settings" onClick={() => setShowFields(true)}>
          <Icon.Column color={colors.blueGray500} size={16} />
        </button>
      </Wrapper>

      <SafeWrapper
        target={targetRef.current}
        align="right"
        defaultPosition="bottom"
        onClose={() => setShowFields(false)}
        show={showFields}
        preventFocusTrap
        preventScrollAdjustment
      >
        <Card arrowPosition="top" arrowOffset={92} width={220}>
          <DndProvider backend={HTML5Backend}>
            <List>
              {fields.map(field => (
                <FieldEntry
                  enableFirstField={enableFirstField}
                  key={`${field.id}-${field.enabled}`}
                  {...field}
                  findFieldIndex={findField}
                  onToggleField={onToggleField}
                  onMoveField={moveField}
                  onCommit={handleCommit}
                />
              ))}
            </List>
          </DndProvider>

          {onResetFields && (
            <Row
              height={40}
              borderTop={`1px solid ${colors.blueGray}`}
              position="sticky"
              px={spacing(2)}
              bottom={0}
              left={0}
              right={0}
            >
              <Button.White height={24} width={1} onClick={onResetFields}>
                Reset Fields
              </Button.White>
            </Row>
          )}
        </Card>
      </SafeWrapper>
    </>
  )
}
