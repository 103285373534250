import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { TextArea } from '@basisboard/basis-ui/es/components/TextArea'
import { spacing } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { height, HeightProps, width, WidthProps } from 'styled-system'
import { RadioGroup, Toggle } from './components'
import { BaseSelect } from './components/BaseSelect'
import { Checkbox } from './components/Checkbox'
import { Color } from './components/Color'
import { Radio } from './components/Radio'
import { Select } from './components/Select'
import { TextInput } from './components/Text'
import { setValue } from './helpers'
import { InputProps, InputType } from './types'
import { useForm } from './useForm'

export * from './components'
export * from './types'

interface Props extends WidthProps, HeightProps {
  fields: InputProps[]
  onSubmit?: (x: unknown) => void
  submitLabel?: string
  disabled?: boolean
  submitSection?: React.ReactNode
}

const Wrapper = styled.form<WidthProps & HeightProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${width};
  ${height};
`

const Inputs = {
  [InputType.CHECKBOX]: Checkbox,
  [InputType.NUMBER]: styled((TextInput as unknown) as AnyStyledComponent).attrs({
    type: 'number',
  })``,
  [InputType.TEXT]: TextInput,
  [InputType.TEXT_AREA]: TextArea,
  [InputType.SEARCH]: BaseSelect,
  [InputType.SELECT]: Select,
  [InputType.RADIO]: Radio,
  [InputType.RADIO_GROUP]: RadioGroup,
  [InputType.TOGGLE]: Toggle,
  [InputType.COLOR]: Color,
}

const clearState = state =>
  Object.keys(state).reduce((acc, key) => setValue({ ...acc }, key, state[key]), {})

export const Form: React.FC<Props> = ({
  fields,
  onSubmit,
  submitSection,
  submitLabel,
  disabled,
  ...props
}) => {
  const { getState, register } = useForm(fields)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    onSubmit && onSubmit(clearState(getState()))
  }

  const renderField = (baseName = '') => ({
    inputType,
    pre,
    post,
    wrap,
    nested,
    name,
    ...rest
  }: InputProps) => {
    const Input: React.ElementType = Inputs[inputType]
    const w = wrap || ((v: React.ReactNode) => v)

    const nestedName = `${baseName ? `${baseName}.` : ''}${name}`

    return (
      <Div mb={spacing(2)} key={`${inputType}-${name}`}>
        {pre && pre}
        {w(
          <>
            <Input
              {...rest}
              ref={register}
              placeholder={rest.placeholder || rest.label}
              onChange={rest.onChange}
              name={nestedName}
            />

            {nested && nested.map(renderField(nestedName))}
          </>,
        )}
        {post && post}
      </Div>
    )
  }

  return (
    <Wrapper onSubmit={handleSubmit} {...(props as any)}>
      {fields.map(renderField(''))}

      {onSubmit && !submitSection && (
        <Div width={1} display="flex" justifyContent="flex-end" mt={spacing(4)}>
          <Button.Primary type="submit" height={40} disabled={disabled}>
            {submitLabel || 'Save'}
          </Button.Primary>
        </Div>
      )}
      {submitSection}
    </Wrapper>
  )
}
