import {
  ProjectSearchGroupName,
  ProjectSearchQueryGroup,
  ProjectSortV2,
  SortOptions,
} from '@basisboard/basis-common/lib/api'
import { fromNullable } from '@basisboard/basis-ui/es/utils'
import { Container, getContainer } from '@containrz/react-hook'
import { ViewId } from '../../constants'
import { eventBus, EventBusType } from '../../services'
import { appRepository } from '../App/container'
import { FieldsState } from '../Fields/container'
import { getGroup, putGroup } from './api'

interface State {
  currentGroup: ProjectSearchQueryGroup | null
  storedGroup: ProjectSearchQueryGroup | null
}

const mapSortToGroup = {
  [SortOptions.Contacts]: ProjectSearchGroupName.Contact,
  [SortOptions.Companies]: ProjectSearchGroupName.Company,
  [SortOptions.Stage]: ProjectSearchGroupName.Stage,
  [SortOptions.Estimators]: ProjectSearchGroupName.Estimator,
}

export class GroupsState extends Container<State> {
  constructor() {
    super()

    const group = this.getInitialGroup()

    this.state = {
      currentGroup: group,
      storedGroup: group,
    }
  }

  getInitialGroup = () => {
    let group =
      (appRepository().state.profile?.settings || {})[`${ViewId.ListViewId}-group`] || null

    if ((group || ({} as ProjectSortV2)).entity) {
      group = {
        name: mapSortToGroup[(group as ProjectSortV2).name] || (group as ProjectSortV2).name,
      } as ProjectSearchQueryGroup
    }

    return group
  }

  getGroup = () => getGroup(ViewId.ListViewId)

  changeGroup = (currentGroup: ProjectSearchQueryGroup | null) => {
    this.setState({ currentGroup, storedGroup: currentGroup })

    if (!currentGroup) {
      eventBus.publish(EventBusType.ClearGroupBy)
    } else {
      eventBus.publish(
        EventBusType.ApplyGroupBy,
        fromNullable(
          getContainer(FieldsState).state.customFields.find(f => f.id === currentGroup.name),
        ).fold(
          () => ({
            group: currentGroup,
            type: 'Default',
          }),
          field => ({
            group: currentGroup,
            field,
            type: 'Custom',
          }),
        ),
      )
    }

    return putGroup(ViewId.ListViewId, currentGroup)
  }
}
