import { PutProfileSettingsRequest } from '@basisboard/basis-common/lib/api'
import { get, put } from '../../api'
import { ViewId } from '../../constants'

export const updateFiltersForView = (viewId: ViewId, filterSettings: unknown | null) =>
  put<PutProfileSettingsRequest>(`/profile/settings/${viewId}-filter`, {
    value: filterSettings,
  }).then(r => r.data)

export const setCustomFiltersForView = (viewId: ViewId, customFilters: any) =>
  put(`/profile/settings/${viewId}-customFilters`, { value: { customFilters } }).then(r => r.data)

export const setPublicCustomFiltersForView = (viewId: ViewId, customFilters: any) =>
  put(`/dashboard/settings/${viewId}-customFilters`, { value: { customFilters } }).then(r => r.data)

export const getSharedCustomFiltersForView = (viewId: ViewId) =>
  get(`/dashboard/settings/${viewId}-customFilters`).then(
    response => response.data.value?.customFilters || [],
  )
