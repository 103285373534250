"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Label = exports.DetailText = exports.HeavyText = exports.Span = exports.Anchor = exports.Text = exports.Paragraph = exports.Subtitle2 = exports.Subtitle = exports.H3 = exports.H2 = exports.H1 = exports.EllipsisOverflow = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const styled_system_1 = require("styled-system");
const styles_1 = require("../../styles");
const BaseTypography = (0, styled_components_1.css) `
  margin: 0;

  ${styled_system_1.space};
  ${styled_system_1.width};
  ${styled_system_1.color};
  ${styled_system_1.textAlign};
  ${styled_system_1.lineHeight};
  ${styled_system_1.fontWeight};
  ${styled_system_1.fontSize};
  ${styled_system_1.display};

  letter-spacing: 0.001em;

  ${({ bold, capitalize, italic, uppercase, noWrap, white }) => (0, styled_components_1.css) `
    ${bold && 'font-weight: bold'};
    ${italic && 'font-style: italic'};
    ${uppercase && 'text-transform: uppercase'};
    ${capitalize && 'text-transform: capitalize'};
    ${noWrap && 'white-space: nowrap;'};
    ${white && `color: ${styles_1.colors.white}`};
  `};
`;
exports.EllipsisOverflow = (0, styled_components_1.css) `
  ${({ preventClip }) => !preventClip &&
    (0, styled_components_1.css) `
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
`;
exports.H1 = styled_components_1.default.h2 `
  font-size: 2.5rem;
  font-weight: bold;

  color: ${styles_1.colors.darkBlue};

  ${BaseTypography}
`;
exports.H2 = styled_components_1.default.h2 `
  font-size: 2rem;
  font-weight: bold;

  color: ${styles_1.colors.darkBlue};

  ${BaseTypography}
`;
exports.H3 = styled_components_1.default.h3 `
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: -0.015em;

  color: ${styles_1.colors.darkBlue};

  ${BaseTypography}
`;
exports.Subtitle = styled_components_1.default.h4 `
  font-size: 1.25rem;
  font-weight: 600;

  color: ${styles_1.colors.darkBlue};

  ${BaseTypography}
`;
exports.Subtitle2 = styled_components_1.default.h5 `
  font-size: 1rem;
  font-weight: 600;

  color: ${styles_1.colors.darkBlue};

  ${BaseTypography}
`;
exports.Paragraph = styled_components_1.default.p `
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 24px;
  color: ${styles_1.colors.mediumGray};
  letter-spacing: -0.01em;
  ${BaseTypography};
`;
exports.Text = styled_components_1.default.p `
  font-size: 0.875rem;
  font-weight: 500;

  color: ${styles_1.colors.darkBlue};
  display: block;

  ${BaseTypography};
  ${exports.EllipsisOverflow};
`;
exports.Anchor = styled_components_1.default.a `
  font-size: 0.875rem;
  font-weight: 500;

  color: ${styles_1.colors.accent};

  &:hover {
    color: ${styles_1.colors.gray};
  }

  ${BaseTypography};
  ${exports.EllipsisOverflow};
`;
exports.Span = styled_components_1.default.span `
  font-size: 0.875rem;
  font-weight: 500;

  color: ${styles_1.colors.primaryText};

  ${BaseTypography};
  ${exports.EllipsisOverflow};
`;
exports.HeavyText = styled_components_1.default.p `
  font-size: 0.875rem;
  line-height: 24px;
  font-weight: 600;
  color: ${styles_1.colors.darkBlue};

  ${BaseTypography};
  ${exports.EllipsisOverflow};
`;
exports.DetailText = styled_components_1.default.span `
  font-size: 0.75rem;
  line-height: 12px;
  color: ${styles_1.colors.gray};

  ${BaseTypography};
`;
exports.Label = (0, styled_components_1.default)(exports.HeavyText)(({ required, error }) => (0, styled_components_1.css) `
    display: flex;
    align-items: center;
    color: ${styles_1.colors.darkGray};

    ${error &&
    (0, styled_components_1.css) `
        color: ${styles_1.colors.error};
      `}

    ${required &&
    (0, styled_components_1.css) `
        &::after {
          content: '*';
          font-size: 0.875rem;
          line-height: 24px;
          font-weight: 600;
          color: ${styles_1.colors.error};
          margin-left: ${(0, styles_1.spacing)(0.5)};
        }
      `};
    ${exports.EllipsisOverflow};
    ${BaseTypography};
  `);
