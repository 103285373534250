import {
  CustomFieldEntity,
  CustomFieldSetting,
  SelectOption,
} from '@basisboard/basis-common/lib/api/custom-fields'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { borderRadiusMixin, colors, spacing } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled from 'styled-components'
import { Select } from '../../../../../components'
import { renderCustomFieldsMultiSelect } from '../../../../../renderers/renderCustomFieldsMultiSelect'

interface Props {
  value: string | string[]
  onChange: (val: string | string[]) => void
  options: (SelectOption & { id: string })[]
  multiple?: boolean
  renderEntry?: (opt: { name: string; id: string }, isSelected?: boolean) => React.ReactNode
  label: string
  field?: Partial<Pick<CustomFieldSetting, 'label' | 'id' | 'type' | 'options'>>
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 40px;
  position: relative;

  display: flex;
  align-items: center;

  ${borderRadiusMixin};
  border: 1px dotted transparent;

  &:hover {
    border-color: ${colors.accent};
  }
`

const EditWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;

  padding: 0 ${spacing(1)};

  & > button,
  & > .add-text {
    visibility: hidden;
  }

  &:hover {
    & > button,
    & > .add-text {
      visibility: visible;

      &:hover path {
        stroke: ${colors.accent};
      }
    }
  }
`

export const SelectType: React.FC<Props> = ({
  value,
  onChange,
  options,
  multiple,
  renderEntry,
  label,
  field,
}) => {
  const ref = React.useRef<HTMLDivElement>()
  const [isOpen, setOpen] = React.useState(false)
  const [localValue, setLocalValue] = React.useState(value)

  React.useEffect(() => {
    if (!isOpen) {
      return
    }

    const handleKeyDown = e => {
      if (e.key === 'Escape') {
        e.stopPropagation()
        setOpen(false)
      }
    }

    const handleClick = e => {
      if (ref.current.contains(e.target)) {
        e.preventDefault()
        setOpen(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('click', handleClick)
    }
  }, [isOpen])

  const handleChange = val => {
    setLocalValue(val)
    onChange(val)
  }

  if (options.length === 0) {
    return <Text>-</Text>
  }

  return (
    <Wrapper
      ref={ref}
      onClick={e => {
        e.stopPropagation()
        setOpen(true)
      }}
    >
      {(Array.isArray(localValue) && localValue.length > 0) ||
      (!Array.isArray(localValue) && localValue) ||
      isOpen ? (
        <Select
          value={multiple ? (localValue as string[]) : (localValue as string)}
          hideBorders
          hideEmpty={multiple}
          multiple={multiple}
          renderOption={
            multiple
              ? (opt, selected) =>
                  renderCustomFieldsMultiSelect(
                    {
                      options: field?.options || options,
                      type: CustomFieldEntity.Board as never,
                    },
                    opt,
                    selected,
                  )
              : renderEntry
          }
          showArrows
          open={isOpen}
          onChangeOpenStatus={setOpen}
          onChange={handleChange}
          options={options.map(opt => ({
            name: opt.label,
            id: opt.id || opt.label,
          }))}
          multiSelectListStyle={{
            overflow: 'auto',
            height: 48,
          }}
        />
      ) : (
        <EditWrapper
          onClick={e => {
            e.stopPropagation()
            setOpen(true)
          }}
        >
          <Text className="add-text" lineHeight="40px" color={colors.mediumGray}>
            Add {label?.toLowerCase()}
          </Text>

          <Button.Transparent postIcon="PencilHollow" ml="auto" />
        </EditWrapper>
      )}
    </Wrapper>
  )
}
