import { useCallback, useEffect, useState } from 'react';

export const useResizeCol = (setColSize: (colSize: number) => void, resizeEnded: () => void) => {
  const [size, setSize] = useState(0);
  const [initialX, setInitialX] = useState(0);
  const [resizing, setResizing] = useState(false);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setSize(e.currentTarget.parentElement.clientWidth);
    setInitialX(e.pageX);
    setResizing(true);

    document.body.style.cursor = 'col-resize';
    document.body.style.userSelect = 'none';
  };

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!resizing) {
        return;
      }
      const delta = e.pageX - initialX;
      const newSize = size + delta;

      setColSize(newSize);
    },
    [resizing, size, initialX],
  );

  const onMouseUp = useCallback(() => {
    setResizing(false);
    document.body.style.cursor = '';
    document.body.style.userSelect = '';
    resizeEnded();
  }, [resizeEnded]);

  useEffect(() => {
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [resizing, size, initialX, resizeEnded, onMouseUp, onMouseMove]);

  return {
    onMouseDown,
    onMouseUp,
  };
};
