import 'react-quill/dist/quill.snow.css'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  @media print {
    body {
      zoom: 80%;
    }
    .titleBar {
      position: static;
    }
  }

  table {
    border-collapse: collapse;
  }

  ul, ol {
    margin: 0;
    padding: 0;
    list-style: none
  }

  a {
    text-decoration: none;
  }

  body {
    margin: 0;
    box-sizing: border-box;
    font-family: 'IBM Plex Sans', sans-serif;
    min-width: 1280px;

    @media print {
      overflow: visible;
    }

    & > iframe:not(.intercom-lightweight-app) {
      display: none;
    }
  }

  .hidden {
    display: none;
  }

  * {
    font-family: 'IBM Plex Sans', sans-serif;
  }

  #modals {
    position: absolute;
    z-index: 10000;

    @media print {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      margin-top: 200px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  #overlays, #modal-overlay {
    position: absolute;
    z-index: 10001;
  }

  #toasts {
    z-index: 10000;
    position: absolute;
  }
`
