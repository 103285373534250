"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Ellipsis = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Ellipsis = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M9.87496 8C9.87496 9.03646 9.03642 9.875 7.99996 9.875C6.9635 9.875 6.12496 9.03646 6.12496 8C6.12496 6.96354 6.9635 6.125 7.99996 6.125C9.03642 6.125 9.87496 6.96354 9.87496 8ZM12.5833 6.125C11.5468 6.125 10.7083 6.96354 10.7083 8C10.7083 9.03646 11.5468 9.875 12.5833 9.875C13.6198 9.875 14.4583 9.03646 14.4583 8C14.4583 6.96354 13.6198 6.125 12.5833 6.125ZM3.41663 6.125C2.38017 6.125 1.54163 6.96354 1.54163 8C1.54163 9.03646 2.38017 9.875 3.41663 9.875C4.45308 9.875 5.29163 9.03646 5.29163 8C5.29163 6.96354 4.45308 6.125 3.41663 6.125Z", fill: color })));
exports.Ellipsis = Ellipsis;
