import { COMPANY_ID } from '@basisboard/basis-common/lib/api'
import { screens } from '../../../screens'
import { showSuccessToast } from '../../Toast'

export const showMergeCompanyToast = (companyId: COMPANY_ID) =>
  showSuccessToast({
    message: 'The selected companies were successfuly linked.',
    actions: [
      {
        label: 'See combined companies details',
        onAction: () => screens.companyDetails.push(companyId),
      },
    ],
  })
