import { Label } from '@basisboard/basis-ui/es/components/Label'
import { Companies } from '../../../Companies'

export const CompanySelector = ({ value, onChange }) => {
  return (
    <>
      <Label label="Companies" />
      <Companies.Input value={value} onChange={onChange} canCreate={false} />
    </>
  )
}
