"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TruckFast = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const TruckFast = ({ width = 24, height = 19, size, color = styles_1.colors.black, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 640 512" },
    React.createElement("path", { fill: color, d: "M272 192C280.8 192 288 184.8 288 176C288 167.2 280.8 160 272 160h-224C39.16 160 32 167.2 32 176C32 184.8 39.16 192 47.1 192H272zM624 384H608V247.7c0-19.08-6.844-37.56-19.25-52.05l-71.02-82.86C508.6 102.1 495.3 96 481.3 96H416V64c0-35.35-28.65-64-64-64H128C92.65 0 64 28.65 64 64v32H15.1C7.164 96 0 103.2 0 111.1C0 120.8 7.164 128 15.1 128h224C248.8 128 256 120.8 256 112C256 103.2 248.8 96 240 96H96V64c0-17.67 14.33-32 32-32h224c17.67 0 32 14.33 32 32v224c0 8.836 7.164 16 16 16S416 296.8 416 288V256h160v128h-5.6C557.2 346.8 521.7 320 480 320s-77.18 26.77-90.4 64H250.1C236.9 346.8 201.7 320 160 320c-24.7 0-46.99 9.59-64 24.91V256h144C248.8 256 256 248.8 256 240C256 231.2 248.8 224 240 224h-224C7.164 224 0 231.2 0 240C0 248.8 7.164 256 15.1 256H64v160c0 53.02 42.98 96 96 96s96-42.98 96-96h128c0 52.94 43.06 96 96 96s96-43.06 96-96h48c8.844 0 16-7.156 16-16S632.8 384 624 384zM416 224V128h65.28c4.688 0 9.109 2.031 12.14 5.578l71.03 82.86C566.5 218.8 568.2 221.3 569.7 224H416zM160 480c-35.29 0-64-28.71-64-64s28.71-64 64-64s64 28.71 64 64S195.3 480 160 480zM480 480c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64S515.3 480 480 480z" })));
exports.TruckFast = TruckFast;
