import values from 'ramda/src/values'
import * as React from 'react'
import { Redirect, Route, Router } from 'react-router-dom'
import { history } from '../../../config'
import { screens } from '../../../screens'
import { SmartRoute } from './SmartRoute'

export const Routes = () => (
  <Router history={history}>
    {values(screens).map(screen => (
      <SmartRoute key={screen.path} screen={screen} />
    ))}

    <Route exact path="/">
      <Redirect to="/projects" />
    </Route>
  </Router>
)
