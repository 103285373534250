"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrawerControllerFactory = exports.DrawerController = void 0;
const styles_1 = require("../../styles");
const focusTrapFactory_1 = require("../../utils/focusTrapFactory");
const monads_1 = require("../../utils/monads");
class DrawerController {
    constructor(id) {
        this.setContentRef = (content) => (this.contentElement = content);
        this.setWrapperRef = (content) => {
            this.wrapperElement = content;
            this.focusTrap = new focusTrapFactory_1.FocusTrapFactory(content);
        };
        this.setCallbacks = (onClose, onOpen) => {
            this.onClose = onClose;
            this.onOpen = onOpen;
        };
        this.toggleOpen = () => (0, monads_1.fromNullable)(this.wrapperElement).fold(() => {
            // eslint-disable-next-line no-console
            console.error('No drawer was found with provided id');
        }, drawer => {
            var _a;
            if (((_a = drawer.attributes[styles_1.ARIA_HIDDEN]) === null || _a === void 0 ? void 0 : _a.value) === 'true') {
                this.onSetOpen();
            }
            else {
                this.onSetClose();
            }
        });
        this.onSetOpen = () => {
            var _a;
            this.focusTrap.mount();
            this.wrapperElement.setAttribute(styles_1.ARIA_HIDDEN, 'false');
            this.registerListeners();
            (_a = this.onOpen) === null || _a === void 0 ? void 0 : _a.call(this);
        };
        this.onSetClose = () => {
            this.focusTrap.destroy();
            this.wrapperElement.addEventListener('animationend', this.handleAnimationEnd);
            this.wrapperElement.setAttribute(styles_1.ARIA_HIDDEN, 'true');
            this.cleanupListeners();
        };
        this.isOpen = () => (0, monads_1.fromNullable)(this.wrapperElement).fold(() => false, drawer => { var _a; return ((_a = drawer.attributes[styles_1.ARIA_HIDDEN]) === null || _a === void 0 ? void 0 : _a.value) === 'false' || false; });
        this.handleOpen = () => {
            this.registerListeners();
        };
        this.handleAnimationEnd = () => {
            var _a;
            this.wrapperElement.removeEventListener('animationend', this.handleAnimationEnd);
            (_a = this.onClose) === null || _a === void 0 ? void 0 : _a.call(this);
        };
        this.handleClose = () => {
            if (!this.isOpen()) {
                return;
            }
            this.cleanupListeners();
            this.toggleOpen();
        };
        this.setOpen = (isOpen) => {
            if (isOpen && !this.isOpen()) {
                this.focusTrap.mount();
                this.registerListeners();
            }
            else if (!isOpen && this.isOpen()) {
                this.focusTrap.destroy();
                this.cleanupListeners();
            }
        };
        this.handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                this.handleClose();
            }
        };
        this.handleClick = (e) => {
            var _a, _b, _c;
            if (!((_a = this.contentElement) === null || _a === void 0 ? void 0 : _a.contains(e.target)) &&
                !((_b = document.getElementById('overlays')) === null || _b === void 0 ? void 0 : _b.contains(e.target)) &&
                !((_c = document.getElementById('modals')) === null || _c === void 0 ? void 0 : _c.contains(e.target))) {
                this.handleClose();
            }
        };
        this.registerListeners = () => {
            window.addEventListener('keydown', this.handleKeyDown);
            window.addEventListener('touchstart', this.handleClick);
            window.addEventListener('mousedown', this.handleClick);
        };
        this.cleanupListeners = () => {
            window.removeEventListener('keydown', this.handleKeyDown);
            window.removeEventListener('touchstart', this.handleClick);
            window.removeEventListener('mousedown', this.handleClick);
        };
        this.id = id;
    }
}
exports.DrawerController = DrawerController;
const drawerControllerMap = new Map();
const DrawerControllerFactory = (id) => {
    return (0, monads_1.fromNullable)(drawerControllerMap.get(id)).fold(() => (0, monads_1.box)(new DrawerController(id)).fold(drawerController => {
        drawerControllerMap.set(id, drawerController);
        return drawerController;
    }), drawerController => drawerController);
};
exports.DrawerControllerFactory = DrawerControllerFactory;
