import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Card } from '@basisboard/basis-ui/es/components/Card'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled from 'styled-components'
import { ButtonText, DropdownList } from '../../../../components'
import { NestedProject } from '../../../../types'
import { openConfirmActionModal } from '../../../App'
import { projectRepository } from '../../container'
import { openDuplicateProjectModal, openLinkBidsModal } from '../../modals'

const Menu = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: 0;
  padding: ${spacing(2)} 0;
`

const MenuEntry = styled.li`
  width: 100%;
  height: 32px;

  padding: 0 ${spacing(2)};

  &:hover {
    background: ${colors.lightGray3};
  }
  ${transitionMixin};

  & * {
    line-height: 32px;
    width: 100%;
  }
`

interface Props {
  project: NestedProject
  onInvalidate: () => void
  onDuplicate?: (project: NestedProject) => void
  onArchive?: () => void
  onDelete?: () => void
  onClose?: () => void
}

export const DropdownMenu: React.FC<Props> = ({
  project,
  onInvalidate,
  onDuplicate,
  onArchive,
  onDelete,
  onClose,
}) => {
  const getEntries = onClose => [
    <ButtonText
      variant="dropdown"
      key="link"
      onClick={e => {
        e.stopPropagation()
        onClose()
        openLinkBidsModal({ entity: 'project', entries: project, onConclude: onInvalidate })
      }}
    >
      Link bids
    </ButtonText>,

    <ButtonText
      variant="dropdown"
      key="duplicate"
      onClick={e => {
        e.stopPropagation()
        onClose()
        openDuplicateProjectModal({ project, onConclude: onDuplicate })
      }}
    >
      Duplicate
    </ButtonText>,

    <ButtonText
      variant="dropdown"
      key="archive"
      onClick={e => {
        e.stopPropagation()
        onClose()
        openConfirmActionModal({
          prompt: 'Do you want to archive this project?',
          actionLabel: 'Archive',
          action: () => {
            projectRepository().archive(project.id, !Boolean(project.archivedAt))
            onArchive?.()
          },
        })
      }}
    >
      {project.archivedAt ? 'Unarchive' : 'Archive'}
    </ButtonText>,

    <ButtonText
      key="delete"
      variant="danger"
      onClick={e => {
        e.stopPropagation()
        onClose()
        openConfirmActionModal({
          prompt: 'Are you sure you want to delete this project?',
          actionLabel: 'Delete',
          action: () =>
            projectRepository()
              .delete(project.id)
              .then(() => onDelete?.()),
        })
      }}
    >
      Delete
    </ButtonText>,
  ]

  if (onClose) {
    return (
      <Card width={200}>
        <Menu onClick={e => e.stopPropagation()}>
          {React.Children.toArray(getEntries(onClose)).map((child, index) => (
            <MenuEntry key={index} onClick={onClose}>
              {child}
            </MenuEntry>
          ))}
        </Menu>
      </Card>
    )
  }

  return (
    <DropdownList
      align="right"
      width={200}
      renderTrigger={onOpen => (
        <Button.Transparent
          p={0}
          width={32}
          onClick={e => {
            e.stopPropagation()
            onOpen()
          }}
          onMouseEnter={e => e.stopPropagation()}
          onMouseLeave={e => e.stopPropagation()}
          style={{ zIndex: 1 }}
        >
          <Icon.Ellipsis />
        </Button.Transparent>
      )}
    >
      {getEntries}
    </DropdownList>
  )
}
