"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Edit = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Edit = ({ width = 14, height = 12, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 14 12" },
    React.createElement("path", { d: "M9.6527 2L11.7407 4.08796C11.8286 4.17592 11.8286 4.31944 11.7407 4.4074L6.6851 9.46296L4.53696 9.70139C4.24992 9.73379 4.00686 9.49074 4.03927 9.2037L4.2777 7.05555L9.33325 2C9.42122 1.91203 9.56473 1.91203 9.6527 2ZM13.4027 1.4699L12.2731 0.340274C11.9212 -0.0115775 11.3495 -0.0115775 10.9953 0.340274L10.1758 1.15972C10.0879 1.24768 10.0879 1.3912 10.1758 1.47916L12.2638 3.56713C12.3518 3.65509 12.4953 3.65509 12.5833 3.56713L13.4027 2.74768C13.7545 2.39352 13.7545 1.82176 13.4027 1.4699ZM9.22214 8.08796V10.4444H1.81473V3.03703H7.13418C7.20825 3.03703 7.2777 3.00694 7.33094 2.95602L8.25686 2.03009C8.43279 1.85416 8.30779 1.55555 8.0601 1.55555H1.44436C0.830937 1.55555 0.333252 2.05324 0.333252 2.66666V10.8148C0.333252 11.4282 0.830937 11.9259 1.44436 11.9259H9.59251C10.2059 11.9259 10.7036 11.4282 10.7036 10.8148V7.16203C10.7036 6.91435 10.405 6.79166 10.2291 6.96527L9.30316 7.8912C9.25223 7.94444 9.22214 8.01389 9.22214 8.08796Z", fill: color })));
exports.Edit = Edit;
