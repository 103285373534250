"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tryCatch = exports.left = exports.right = exports.fromEmpty = exports.fromBoolean = exports.fromNullable = exports.box = void 0;
const box = (x) => ({
    map: func => (0, exports.box)(func(x)),
    fold: func => func(x),
    inspect: () => `box(${x})`,
});
exports.box = box;
const fromNullable = (x) => x !== null && x !== undefined && (typeof x === 'number' ? !Number.isNaN(x) : true)
    ? (0, exports.right)(x)
    : (0, exports.left)(x);
exports.fromNullable = fromNullable;
const fromBoolean = (x) => (x ? (0, exports.right)(x) : (0, exports.left)(x));
exports.fromBoolean = fromBoolean;
const fromEmpty = (x) => Array.isArray(x) && x.length === 0 ? (0, exports.right)(x) : (0, exports.left)(x);
exports.fromEmpty = fromEmpty;
const right = (x) => ({
    chain: func => func(x),
    map: func => (0, exports.right)(func(x)),
    fold: (_, func) => func(x),
    inspect: () => `right(${x})`,
});
exports.right = right;
const left = (x) => ({
    chain: () => (0, exports.left)(x),
    map: () => (0, exports.left)(x),
    fold: func => func(x),
    inspect: () => `left(${x})`,
});
exports.left = left;
const tryCatch = (fn) => {
    try {
        return (0, exports.right)(fn());
    }
    catch (e) {
        return (0, exports.left)(e);
    }
};
exports.tryCatch = tryCatch;
