import {
  COMPANY_ID,
  CONTACT_ID,
  FeatureType,
  PROJECT_ID,
  UserRole,
} from '@basisboard/basis-common/lib/api'
import { retryLazyLoad } from '@basisboard/basis-ui/es/utils'
import * as React from 'react'
import { history } from '../config'

export interface ScreenType {
  path: string
  requiresLogin?: boolean
  requiresPermissions?: UserRole[]
  requiresFeature?: FeatureType
  notExact?: boolean
  component: Promise<{ default: React.ComponentType<any> }>
  push: (...args: any[]) => void
}

export enum Screen {
  Analytics = 'analytics',
  Board = 'board',
  Calendar = 'calendar',
  Companies = 'companies',
  CompanyDetails = 'companyDetails',
  ContactDetails = 'contactDetails',
  Contacts = 'contacts',
  ForgotPassword = 'forgotPassword',
  InboxList = 'inboxList',
  Login = 'login',
  ProjectDetails = 'projectDetails',
  ProjectsList = 'projectsList',
  SharedListScreen = 'sharedList',
  Reports = 'reports',
  ResetPassword = 'resetPassword',
  Settings = 'settings',
  TasksList = 'tasksList',
}

export const screens: Record<Screen, ScreenType> = {
  [Screen.Analytics]: {
    path: '/analytics',
    notExact: true,
    push: () => history.push('/analytics'),
    component: retryLazyLoad(() => import('./AnalyticsInsights')),
    requiresLogin: true,
    requiresFeature: FeatureType.AnalyticsDashboard,
    requiresPermissions: [UserRole.Admin],
  },

  [Screen.Board]: {
    path: '/board',
    push: () => history.push('/board'),
    component: retryLazyLoad(() => import('./Board')),
    requiresLogin: true,
  },

  [Screen.Calendar]: {
    path: '/calendar',
    push: () => history.push('/calendar'),
    component: retryLazyLoad(() => import('./Calendar')),
    requiresLogin: true,
  },

  [Screen.Companies]: {
    path: '/companies',
    push: () => history.push('/companies'),
    component: retryLazyLoad(() => import('./Companies')),
    requiresLogin: true,
  },

  [Screen.CompanyDetails]: {
    path: '/companies/:companyId',
    push: (companyId: COMPANY_ID) => history.push(`/companies/${companyId}`),
    component: retryLazyLoad(() => import('./CompanyDetails')),
    requiresLogin: true,
  },

  [Screen.ContactDetails]: {
    path: '/people/:personId',
    push: (personId: CONTACT_ID) => history.push(`/people/${personId}`),
    component: retryLazyLoad(() => import('./PeopleDetails')),
    requiresLogin: true,
  },

  [Screen.Contacts]: {
    path: '/people',
    push: () => history.push('/people'),
    component: retryLazyLoad(() => import('./Contacts')),
    requiresLogin: true,
  },

  [Screen.ForgotPassword]: {
    path: '/forgot-password',
    push: () => history.push('/forgot-password'),
    component: retryLazyLoad(() => import('./Auth/ForgotPassword')),
  },

  [Screen.InboxList]: {
    path: '/inbox',
    push: () => history.push('/inbox'),
    component: retryLazyLoad(() => import('./Inbox')),
    requiresLogin: true,
    requiresFeature: FeatureType.EmailInbox,
  },

  [Screen.Login]: {
    path: '/login',
    push: () => history.push('/login'),
    component: retryLazyLoad(() => import('./Auth/Login')),
  },

  [Screen.ProjectDetails]: {
    path: '/projects/:projectId',
    push: (projectId: PROJECT_ID, details = '') => history.push(`/projects/${projectId}${details}`),
    component: retryLazyLoad(() => import('./ProjectDetails')),
    requiresLogin: true,
  },

  [Screen.ProjectsList]: {
    path: '/projects',
    push: () => history.push('/projects'),
    component: retryLazyLoad(() => import('./Projects')),
    requiresLogin: true,
  },

  [Screen.SharedListScreen]: {
    path: '/shared-list/:sharedId',
    push: a => history.push(`/shared-list/${a}`),
    component: retryLazyLoad(() => import('./SharedListScreen')),
  },

  [Screen.Reports]: {
    path: '/reports',
    push: () => history.push('/reports'),
    component: retryLazyLoad(() => import('./Analytics')),
    requiresLogin: true,
    requiresFeature: FeatureType.GroupAnalytics,
    requiresPermissions: [UserRole.Admin],
  },

  [Screen.ResetPassword]: {
    path: '/reset-password/:passwordResetToken',
    push: () => history.push('/reset-password/:passwordResetToken'),
    component: retryLazyLoad(() => import('./Auth/ResetPassword')),
  },

  [Screen.Settings]: {
    path: '/settings',
    push: () => history.push('/settings'),
    component: retryLazyLoad(() => import('./Settings')),
    notExact: true,
  },

  [Screen.TasksList]: {
    path: '/tasks',
    push: () => history.push('/tasks'),
    component: retryLazyLoad(() => import('./TasksList')),
  },
}
