import { STAGE_REASON_ID } from '@basisboard/basis-common/lib/api'
import { settingsScreens } from '../../../screens/Settings/screens'
import { showInfoToast } from '../../Toast'
import { ToastInterface, ToastType } from '../../Toast/types'

export const showMarkAsLostToast = (
  options: { name: string; id: string }[],
  onChange: (stageReasonId: STAGE_REASON_ID) => void,
  value?: STAGE_REASON_ID,
) => showInfoToast(markAsLostToastProps(options, onChange, value))

export const showMarkAsDeclinedToast = (
  options: { name: string; id: string }[],
  onChange: (stageReasonId: STAGE_REASON_ID) => void,
  value?: STAGE_REASON_ID,
) => showInfoToast(markAsDeclinedToastProps(options, onChange, value))

export const markAsLostToastProps = (
  options: { name: string; id: string }[],
  onChange: (stageReasonId: STAGE_REASON_ID) => void,
  value?: STAGE_REASON_ID,
): ToastInterface => ({
  type: ToastType.Info,
  message: 'The bid was marked as lost. Do you want to add a reason?',
  select: {
    options,
    onChange,
    value,
    url: settingsScreens.lostReasons.path,
  },
})

export const markAsDeclinedToastProps = (
  options: { name: string; id: string }[],
  onChange: (stageReasonId: STAGE_REASON_ID) => void,
  value?: STAGE_REASON_ID,
): ToastInterface => ({
  type: ToastType.Info,
  message: 'The bid was marked as declined. Do you want to add a reason?',
  select: {
    options,
    onChange,
    value,
    url: settingsScreens.declineReasons.path,
  },
})
