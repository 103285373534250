"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadingIndicator = void 0;
const React = __importStar(require("react"));
const styled_components_1 = __importStar(require("styled-components"));
const styled_system_1 = require("styled-system");
const styles_1 = require("../../styles");
const Load = (0, styled_components_1.keyframes) `
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const Loader = styled_components_1.default.div `
  border-radius: 50%;
  ${styled_system_1.size};

  & {
    margin: 60px auto;
    position: relative;
    text-indent: -9999em;
    border-top: 5px solid rgba(145, 145, 145, 0.2);
    border-right: 5px solid rgba(145, 145, 145, 0.2);
    border-bottom: 5px solid rgba(145, 145, 145, 0.2);
    border-left: 5px solid ${styles_1.colors.accent};
    transform: translateZ(0);
    animation: ${Load} 1.1s infinite linear;
  }
`;
const Box = styled_components_1.default.div `
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: ${(0, styles_1.spacing)(1.5)};
  justify-content: center;
  min-height: inherit;
  flex-grow: 1;
  ${styled_system_1.height};
`;
const LoadingIndicator = ({ scope, size = 30, height, }) => {
    return (React.createElement(Box, { "data-testid": "loader", "aria-live": "polite", role: "progressbar", "aria-valuetext": `Loading ${scope || ''}`, "aria-busy": "true", height: height },
        React.createElement(Loader, { size: size })));
};
exports.LoadingIndicator = LoadingIndicator;
