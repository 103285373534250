import { StageCode, STAGE_ID } from '@basisboard/basis-common/lib/api'
import { Row } from '@basisboard/basis-ui/es/components/Row'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { spacing } from '@basisboard/basis-ui/es/styles'
import { box } from '@basisboard/basis-ui/es/utils/monads'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import { Circle, Select } from '../../../../components'
import { StagesState } from '../../container'
import { isFinalStage } from '../../helpers'

interface Props {
  defaultValue?: STAGE_ID
  value?: STAGE_ID
  name?: string
  label?: string
  onChange?: (val: STAGE_ID) => void
  hideFinalStages?: boolean
}

export const StagesInput: React.FC<Props> = ({
  defaultValue,
  value,
  name,
  label,
  onChange,
  hideFinalStages,
}) => {
  const stagesData = useContainer(StagesState)

  return (
    <Select
      name={name || 'stageId'}
      label={label}
      defaultValue={defaultValue || stagesData.getStageForStageCode(StageCode.Undecided).id}
      value={value}
      hideEmpty
      dropdownWidth={1}
      options={stagesData.state.stages.filter(s => (hideFinalStages ? !isFinalStage(s) : true))}
      onChange={onChange}
      renderOption={({ id }) =>
        box(stagesData.getStageForId(id)).fold(stage => (
          <Row justifyContent="flex-start">
            <Circle background={stage.color} mr={spacing(1)} />
            <Text>{stage.name}</Text>
          </Row>
        ))
      }
    />
  )
}
