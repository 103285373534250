import { StageCode, STAGE_ID } from '@basisboard/basis-common/lib/api'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Card } from '@basisboard/basis-ui/es/components/Card'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { SafeWrapper } from '@basisboard/basis-ui/es/components/SafeWrapper'
import { EllipsisOverflow, Text } from '@basisboard/basis-ui/es/components/Typography'
import {
  borderRadiusMixin,
  colors,
  hexToRgba,
  spacing,
  transitionMixin,
} from '@basisboard/basis-ui/es/styles'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { StagesState } from '../../container'

interface Props {
  currentStage?: STAGE_ID
  onSelectStage?: (stage: STAGE_ID) => void
  name?: string
}

const Wrapper = styled(Div)`
  button span {
    ${EllipsisOverflow};
  }
`

const Entry = styled.li<{ hoverBackground: string }>`
  height: 24px;
  padding: 0 ${spacing(1)};

  display: flex;
  align-items: center;

  ${borderRadiusMixin};
  ${transitionMixin};

  &:not(:last-child) {
    margin-bottom: ${spacing(1)};
  }

  cursor: pointer;

  &:hover {
    ${({ hoverBackground }) => css`
      background-color: ${hoverBackground};
    `}
  }
`

export const Dropdown: React.FC<Props> = ({ currentStage, onSelectStage, name }) => {
  const ref = React.useRef<HTMLButtonElement>()
  const stagesData = useContainer(StagesState)
  const [showOptions, setShowOptions] = React.useState(false)
  const [localStage, setLocalStage] = React.useState(
    currentStage || stagesData.getStageForStageCode(StageCode.Undecided).id,
  )

  React.useEffect(() => {
    if (currentStage && currentStage !== localStage) {
      setLocalStage(currentStage)
    }
  }, [currentStage])

  const { name: stageName, color: stageColor } = stagesData.getStageForId(localStage)

  return (
    <Wrapper position="relative" ref={ref} width={120}>
      <Button.Custom
        backgroundColor={colors.transparent}
        color={stageColor}
        iconColor={stageColor}
        onClick={() => setShowOptions(true)}
        postIcon="Arrow"
        width={1}
      >
        {stageName}
      </Button.Custom>

      {name && <input name={name} value={localStage} className="visually-hidden" />}

      <SafeWrapper
        target={ref.current}
        align="right"
        defaultPosition="bottom"
        show={showOptions}
        onClose={() => setShowOptions(false)}
      >
        <Card arrowPosition="top" arrowOffset={50}>
          <Div as="ul" width={160} maxHeight={320} overflow="auto" p={spacing(1)} pt={spacing(2)}>
            {stagesData.state.stages.map(({ color, name, id }) => (
              <Entry
                key={id}
                hoverBackground={hexToRgba(color, 0.2)}
                onClick={() => {
                  setLocalStage(id)
                  onSelectStage?.(id)
                  setShowOptions(false)
                }}
              >
                <Text color={color}>{name}</Text>
              </Entry>
            ))}
          </Div>
        </Card>
      </SafeWrapper>
    </Wrapper>
  )
}
