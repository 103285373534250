import axios, { AxiosError } from 'axios'
import { config } from './config'

axios.defaults.baseURL = config.API_URL || 'http://dev.staging.basisboard.com:8055/v1'
axios.defaults.withCredentials = true
axios.defaults.headers = {
  'Content-Type': 'application/json',
}

const { get, patch, post, put, delete: del } = axios

export { del, get, patch, post, put }

export type ApiError = AxiosError
