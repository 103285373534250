export enum EventBusType {
  // Analytics
  AnalyticsChangedVisualization = 'Analytics - Changed visualisation type',
  AnalyticsChangedQuery = 'Analytics - Changed query',
  AnalyticsPrintReport = 'Analytics - Printed report',
  AnalyticsViewedDashboard = 'Analytics - Viewed insights',

  // App
  Login = 'Auth - Logged in',
  Logout = 'Auth - Logged out',
  FirstSessionStarted = "Auth - Started user's first session",
  SessionStarted = 'Auth - Started session',
  LoadedUsers = 'User - Loaded users',
  EditUser = 'User - Edited user',
  DeleteUser = 'User - Deleted user',
  EditProfile = 'User - Edited profile',
  EditPassword = 'User - Edited password',

  // Attachments
  CreatedAttachment = 'File - Created attachment',
  DeletedAttachment = 'File - Deleted attachment',
  CreatedFolder = 'File - Created folder',
  DeletedFolder = 'File - Deleted folder',

  // Bid packages - teams
  CreatedTeam = 'Bid Packages - Created Team',
  EditedTeam = 'Bid Packages - Edited Team',
  CreatedScope = 'Bid Packages - Created Scope',
  EditedScope = 'Bid Packages - Edited Scope',

  // Bid packages - quote
  QuotesEditedProposal = 'Quotes - Edited proposal',
  QuotesDeletedProposal = 'Quotes - Deleted proposal',
  QuotesAddedScope = 'Quotes - Added scope',
  QuotesDeletedScope = 'Quotes - Deleted scope',
  QuotesEditedScope = 'Quotes - Edited scope',
  QuotesEditedAllScopes = 'Quotes - Edited all scopes',
  QuotesAddedAlternate = 'Quotes - Added alternate',
  QuotesEditedAlternate = 'Quotes - Edited alternate',
  QuotesDeletedAlternate = 'Quotes - Deleted alternate',
  QuotesEditedAllAlternates = 'Quotes - Edited all alternates',
  QuotesAddedDiscount = 'Quotes - Added discount',
  QuotesEditedDiscount = 'Quotes - Edited discount',
  QuotesUploadedAttachment = 'Quotes - Uploaded attachment',
  QuotesDeletedAttachment = 'Quotes - Deleted attachment',
  QuotesAwardedCompany = 'Quotes - Awarded company',
  QuotesAddedCompany = 'Quotes - Added company',
  QuotesEditedCompanyStatus = 'Quotes - Edited company status',

  // Calendar
  CreateCalendarSubscription = 'Calendar - Created subscription',
  DownloadCalendarSubscription = 'Calendar - Downloaded subscription',
  ChangeCalendarView = 'Calendar - Changed view',
  ChangeCalendarDate = 'Calendar - Changed date',
  ApplyCalendarDeadlineFilter = 'Calendar - Applied deadline filter',
  ApplyCalendarDeadlineTypeFilter = 'Calendar - Applied deadline type filter',

  // Companies
  LoadedCompanies = 'Company - Loaded companies',
  CreateCompany = 'Company - Created company',
  EditCompany = 'Company - Edited company',
  DeleteCompany = 'Company - Deleted company',
  BlockCompany = 'Company - Blocked company',
  MergeCompany = 'Company - Merged company',
  AcceptCompanyMergeSuggestion = 'Company - Accepted Company Merge',
  RejectCompanyMergeSuggestion = 'Company - Rejected Company Merge',

  // Contacts
  CreateContact = 'Contact - created contact',
  EditContact = 'Contact - Edited contact',
  DeleteContact = 'Contact - Deleted contact',
  MergeContact = 'Contact - Merged contact',

  // Emails
  DeleteEmail = 'Email - Deleted email',
  ReopenEmail = 'Email - Repeoned email',
  ViewEmail = 'Email - Viewed email',
  MarkEmailRead = 'Email - Marked email as read',
  MarkEmailUnread = 'Email - Marked email as unread',
  DownloadEmail = 'Email - Downloaded email',
  SendEmail = 'Email - Sent email message',
  SendEmailError = "Email - Couldn't send email message",
  ArchiveDuplicateEmails = 'Email - Archived duplicate email messages',
  CreateDistributionList = 'Email - Created distribution list',
  EditDistributionList = 'Email - Edited distribution list',

  // Fields
  LoadedFields = 'Field - Loaded fields',
  CreateCustomField = 'Field - Created custom field',
  EditCustomField = 'Field - Edited custom field',
  DeleteCustomField = 'Field - Deleted custom field',
  CreateTagForEntity = 'Field - Created tag for entity',
  DeleteTagFromEntity = 'Field - Deleted tag from entity',
  // -- UI
  ToggleField = 'Field - Toggled field',
  ResizeField = 'Field - Resized field',
  ResetFields = 'Field - Reset fields',
  ResortFields = 'Field - Resorted fields in list',

  // Filters
  LoadedFilters = 'Filter - Loaded filters',
  ApplyFilter = 'Filter - Applied filter',
  ApplyQuickFilter = 'Filter - Applied quick filter',
  ClearAllFilters = 'Filter - Cleared all filters',
  CreateCustomFilter = 'Filter - Created custom filter',
  EditCustommFilter = 'Filter - Edited custom filter',
  DeleteCustomFilter = 'Filter - Deleted custom filter',

  // Groups
  ApplyGroupBy = 'Group - Applied group by',
  ClearGroupBy = 'Group - Cleared group by',

  // Inbox
  InboxProcessed = 'Inbox - Processed message',
  InboxLoaded = 'Inbox - Loaded message',
  InboxReportedDataQuality = 'Inbox - Reported data quality',
  InboxTrashed = 'Inbox - Trashed message',
  InboxGrouped = 'Inbox - Grouped messages',
  InboxMovedBack = 'Inbox - Moved back to inbox',
  InboxBulkAction = 'Inbox - Bulk action',
  InboxMessageRequiresRefresh = 'Inbox - Message requires refresh',
  InboxRejectGroupSuggestion = 'Inbox - Rejected group suggestion',

  // Notes
  CreateNote = 'Notes - Created note for entity',
  EditNote = 'Notes - Edited note for entity',
  DeleteNote = 'Notes - Deleted note for entity',
  ShowNotes = 'Notes - Shown notes for entity',
  HideNotes = 'Notes - Hidden notes for entity',

  // Notifications
  ToggleNotificationSetting = 'Notifications - Toggled notification setting',
  // -- UI
  NotificationsOpen = 'Notifications - Opened notifications',
  NotificationsClose = 'Notifications - Closed notifications',
  NotificationClick = 'Notifications - Clicked notification entry',
  ChangeTableRowSize = 'UI - Changed row size',

  // Platforms
  SyncPlatform = 'Platforms - Synced platform',
  DeletePlatform = 'Platforms - Deleted platform',

  // Print
  Print = 'Print - Printed page',

  // Projects
  AssignEstimator = 'Project - Assigned estimator to project',
  CreateProject = 'Project - Created project',
  EditProject = 'Project - Edited project',
  DeleteProject = 'Project - Deleted project',
  ArchiveProject = 'Project - Archived project',
  ChangeProjectStage = "Project - Changed project's stage",
  LinkCompanyToProject = 'Project - Linked company to project',
  UnlinkCompanyFromProject = 'Project - Unlinked company from project',
  LinkContactToProject = 'Project - Linked contact to project',
  UnlinkContactToProject = 'Project - Unlinked contact to project',
  SplitBids = 'Project - Split bids',
  SwitchBidFromProject = 'Project - Switched bid from project',
  MergeProjects = 'Project - Merged projects',
  CreatePlanLink = 'Project - Created plan link',
  DuplicateProject = 'Project - Duplicated project',
  CreateCompetitor = 'Project - Created competitor',
  EditCompetitor = 'Project - Edited competitor',
  DeleteCompetitor = 'Project - Deleted competitor',
  RefreshProjects = 'Project - Refreshed projects',
  MarkNotesAsRead = 'Project - marked notes as read',

  // Quotes
  CreateQuoteSuccess = 'Quote - Created quote',
  CreateQuoteError = "Quote - Couldn't create quote",
  EditQuote = 'Quote - Edited quote',
  AwardQuote = 'Quote - Awarded quote',
  DeleteQuote = 'Quote - Deleted quote',
  CreateRevision = 'Quote - Created revision',
  DeleteRevision = 'Quote - Deleted revision',
  CreateQuoteTrade = 'Quote - Created quote trade',
  EditQuoteTrade = 'Quote - Edited quote trade',
  DeleteQuoteTrade = 'Quote - Deleted quote trade',

  // Rules
  CreateRule = 'Rules - Created rule',
  EditRule = 'Rules - Edited rule',
  DeleteRule = 'Rules - Deleted rule',
  DisableRule = 'Rules - Disabled rule',
  EnableRule = 'Rules - Enabled rule',

  // Scrapers
  CreateScraper = 'Scrapers - Created scraper',
  EditScraper = 'Scrapers - Edited scraper',
  DeleteScraper = 'Scrapers - Deleted scraper',
  DisableScraper = 'Scrapers - Disabled scraper',
  EnableScraper = 'Scrapers - Enabled scraper',

  // Search
  SearchBy = 'Search - Searched for',
  ApplySearchByFilter = 'Search - Applied search results by',
  SearchResultClick = 'Search - Clicked search result',

  // Shared views
  CreateSharedView = 'Shared View - Created shared view',
  EditSharedView = 'Shared View - Edited shared view',
  DeleteSharedView = 'Shared View - Deleted shared view',

  // Sorts
  ApplySort = 'Sort - Applied sort',
  ApplySortByColumn = 'Sort - Applied sort by column',
  ClearSorts = 'Sort - Cleared sorts',

  // Stages
  LoadedStages = 'Stage - Loaded stages',
  CreateStage = 'Stage - Created stage',
  EditStage = 'Stage - Edited stage',
  DeleteStage = 'Stage - Deleted stage',
  CreateStageReason = 'Stage - Created reason for stage',
  DeleteStageReason = 'Stage - Deleted reason for stage',

  // Tasks
  CreateTaskSuccess = 'Task - Created task',
  CreateTaskError = "Task - Couldn't create task",
  DeleteTask = 'Task - Deleted task',
  EditTask = 'Task - Edited task',
  CompleteTask = 'Task - Completed task',

  // Teams
  CreateTeam = 'Old Teams - Created team',
  DeleteTeam = 'Old Teams - Deleted team',
  EditTeam = 'Old Teams - Edited team',

  // UI
  // -- Context menu
  ShowContextMenu = 'UI - Showed context menu',
  // -- Dropdown
  ShowDropdown = 'UI - Showed dropdown',
  // -- Toast
  ShowToast = 'UI - Showed toast',
  // -- Modal
  ShowModal = 'UI - Showed modal',
  CloseModal = 'UI - Closed modal',
  // -- Details screen
  ChangeDetailsTab = 'UI - Changed details tab to',
  // -- List view
  InlineEdit = 'UI - Edited field inline',
  BulkEdit = 'UI - Edited field in chunk',
  // -- Board view
  HideColumnEntries = 'UI - Hided column entries',
  ShowColumnEntries = 'UI - Showed column entries',
  HideColumn = 'UI - Hided column',
  ShowColumn = 'UI - Showed column',
  DragToChangeStage = 'UI - Draggged to change stage',
  // -- Exports
  ExportFile = 'UI - Exported file',
  // -- Copy
  CopyFieldToClipboard = 'UI - Copied field to clipboard',
  // -- Error
  Error = 'Error - Thrown error',
}
