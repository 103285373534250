import { spacing } from '@basisboard/basis-ui/es/styles'
import styled from 'styled-components'

export const Footer = styled.footer`
  margin: auto -${spacing(4)} -${spacing(5)};
  padding: ${spacing(3)} ${spacing(4)};
  width: calc(100% + ${spacing(8)});
  box-shadow: inset 0px 1px 0px #eaebee;
  display: flex;
  height: 88px;
  justify-content: space-between;
  align-items: center;
`
