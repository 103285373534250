import { Checkbox } from '@basisboard/basis-ui/es/components/Checkbox'
import * as React from 'react'
import { TableFieldProps } from '../../type'
import { Wrapper } from '../styled'

export const BooleanField: React.FC<TableFieldProps> = ({ onChange, value, ...rest }) => {
  const [val, setVal] = React.useState<boolean>(!!value)

  React.useEffect(() => {
    setVal(!!value)
  }, [value])

  return (
    <Wrapper as="label" {...rest} onClick={e => e.stopPropagation()}>
      <Checkbox
        value={val}
        checked={val}
        disabled={!Boolean(onChange)}
        onChange={v => {
          onChange?.(v.currentTarget.checked)
          setVal(v.currentTarget.checked)
        }}
      />
    </Wrapper>
  )
}
