import { Table2 } from '@basisboard/basis-ui/es/components/Table2'
import { useOutsideClick } from '@basisboard/basis-ui/es/hooks/useOutsideClick'
import { changeFocusToElement, getUniqId } from '@basisboard/basis-ui/es/utils'
import * as React from 'react'
import { TableFieldProps } from '../../type'
import { ActionsBar } from '../ActionsBar'
import { CellArea, FieldWrapper, Wrapper } from '../styled'

export const BaseField: React.FC<Partial<TableFieldProps> & {
  onShowEdit?: (showEdit: boolean) => void
  noPadding?: boolean
  shouldCloseWhenOutsideClick?: (e: MouseEvent) => boolean
}> = ({
  onDismiss,
  children,
  customActions,
  forceShowEdit,
  initialShowEdit,
  onShowEdit,
  noPadding,
  readonly,
  actions,
  autoApply,
  shouldCloseWhenOutsideClick = () => true,
}) => {
  const [showEdit, setShowEdit] = React.useState(initialShowEdit || forceShowEdit || false)
  const [ref, setRef] = React.useState<HTMLDivElement>()

  const baseFieldId = React.useMemo(() => getUniqId(), [])

  useOutsideClick([{ current: ref }], e => {
    if (!showEdit || !shouldCloseWhenOutsideClick(e)) {
      return
    }

    const parentOverlay = document.getElementById(baseFieldId)

    if (
      (e.target as HTMLElement).classList.contains('dismiss-overlay') ||
      (!ref?.parentElement.contains(e.target as Node) &&
        !parentOverlay?.contains(e.target as Node) &&
        !ref?.isSameNode(e.target as Node))
    ) {
      setShowEdit(false)
      onDismiss?.()
    }
  })

  React.useEffect(() => {
    onShowEdit?.(showEdit)
  }, [showEdit])

  React.useEffect(() => {
    if (!autoApply) {
      return
    }
    setShowEdit?.(initialShowEdit)
  }, [initialShowEdit])

  React.useEffect(() => {
    if (!showEdit) {
      return
    }

    const onKeyDown = e => {
      if (e.key === 'Escape') {
        setShowEdit(false)
        onDismiss?.(true)
      } else if (e.key === 'Tab') {
        let node = ref as HTMLElement

        while (node && node.parentElement.tagName !== 'TABLE') {
          node = node.parentElement
        }

        e.preventDefault()
        ref?.setAttribute('tabindex', '0')
        ref?.focus()
        changeFocusToElement(e.shiftKey ? 'prev' : 'next', node || document.body)
        ref?.removeAttribute('tabindex')
        setShowEdit(false)
        onDismiss?.()
      }
    }

    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [showEdit])

  return (
    <Wrapper ref={setRef} onClick={actions ? undefined : e => e.stopPropagation()}>
      {showEdit && ref ? (
        <Table2.ActionsWrapper
          id={baseFieldId}
          width={250}
          {...customActions}
          anchorElement={ref?.parentElement}
          noPadding={noPadding}
        >
          <FieldWrapper>{children}</FieldWrapper>
        </Table2.ActionsWrapper>
      ) : (
        <>
          {children}
          {actions && (
            <ActionsBar
              actions={actions}
              onClick={() => window.getSelection().type !== 'Range' && ref?.focus()}
              onFocus={() => setShowEdit(true)}
            />
          )}
          {!readonly && !actions && (
            <CellArea
              tabIndex={0}
              onClick={() => window.getSelection().type !== 'Range' && ref?.focus()}
              onFocus={() => setShowEdit(true)}
            />
          )}
        </>
      )}
    </Wrapper>
  )
}
