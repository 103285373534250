"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Expand = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Expand = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 448 512" },
    React.createElement("path", { fill: color, d: "M296 391.1c-6.141 0-12.28 2.344-16.97 7.031L248 430.1v-86.06c0-13.25-10.75-24-24-24s-24 10.75-24 24v86.06l-31.03-31.03C164.3 394.3 158.1 391.1 152 391.1c-12.82 0-24 10.33-24 24c0 6.141 2.344 12.28 7.031 16.97l72 72.01C209.6 507.5 215.5 512 224 512s14.4-4.461 16.97-7.031l72-72.01C317.7 428.3 320 422.1 320 415.1C320 402.3 308.8 391.1 296 391.1zM152 119.1c6.141 0 12.28-2.344 16.97-7.031L200 81.91v86.07c0 13.25 10.75 24 24 24s24-10.75 24-24V81.91l31.03 31.03C283.7 117.6 289.8 119.1 296 119.1c18.79 0 24-17.2 24-23.1c0-6.141-2.344-12.28-7.031-16.97l-72-72.01C234.9 .9766 227.7 0 223.1 0C220.3 0 213.1 .9687 207 7l-72 72.01C130.3 83.7 128 89.84 128 95.98C128 109.7 139.2 119.1 152 119.1zM424 232H24C10.75 232 0 242.7 0 255.1S10.75 280 24 280h400c13.25 0 24-10.76 24-24.01S437.3 232 424 232z" })));
exports.Expand = Expand;
