import { borderRadiusMixin, colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import styled from 'styled-components'

export const CreateFilterButton = styled.button`
  height: 40px;
  border: 1px dashed #dadce3;
  ${borderRadiusMixin};
  width: 100%;
  color: ${colors.accent};
  margin-bottom: ${spacing(2)};

  ${transitionMixin};

  &:focus {
    outline: none;
  }

  &:hover {
    border-color: ${colors.accent};
  }
`
