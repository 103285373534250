import { GetProfileResponse } from '@basisboard/basis-common/lib/api'
import * as Sentry from '@sentry/browser'
import { config, isDev } from '../config'
import { getSessionUrl } from './smartlook'

Sentry.init({
  dsn: config.SENTRY_DSN,
  release: process.env.COMMIT_SHA,
  ignoreErrors: [
    /ChunkLoadError/gi,
    /Request failed with status code/gi,
    'ResizeObserver loop limit exceeded',
    /Request aborted/gi,
    /Invalid regular expression/gi,
  ],
})

// expose Sentry object for SmartLook https://help.smartlook.com/en/articles/3244594-sentry
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
window.Sentry = Sentry

export const bootSentry = ({
  name,
  email,
  customerWorkspace,
  trial,
  position,
}: GetProfileResponse) => {
  if (isDev) return

  Sentry.setTag('workspace', customerWorkspace)
  Sentry.setTag('sessionUrl', getSessionUrl())

  return Sentry.configureScope(function(scope) {
    scope.setUser({ email, username: name, customerWorkspace, trial, position })
  })
}
