import { CustomFieldValues } from '@basisboard/basis-common/lib/api/custom-fields'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { Table2 } from '@basisboard/basis-ui/es/components/Table2'
import { Span } from '@basisboard/basis-ui/es/components/Typography'
import { colors } from '@basisboard/basis-ui/es/styles'
import { changeFocusToElement } from '@basisboard/basis-ui/es/utils'
import * as React from 'react'
import { TableFieldProps } from '../../type'
import { getOptionColors } from '../../utils'
import { ActionsBar } from '../ActionsBar'
import { Search } from '../Search'
import { SelectorValues } from '../SelectorValues'
import { CellArea, CustomFieldSelectOption, StyledButton, Wrapper } from '../styled'

export const SingleSelect: React.FC<TableFieldProps> = props => {
  const {
    value,
    field,
    onChange,
    renderOption,
    onDismiss,
    customActions,
    forceShowEdit,
    children,
    readonly,
    nonNullable,
    actions,
  } = props

  const [showEdit, setShowEdit] = React.useState(forceShowEdit || false)
  const [ref, setRef] = React.useState<HTMLDivElement>()

  const handleChange = (val: CustomFieldValues) => {
    onChange?.(val)
    setShowEdit(false)
  }

  React.useEffect(() => {
    if (!showEdit || !ref) {
      return
    }

    const onKeyDown = e => {
      switch (e.key) {
        case 'Escape':
          setShowEdit(false)
          onDismiss?.()
          break

        case 'Tab':
          e.preventDefault()
          ref.setAttribute('tabindex', '0')
          ref.focus()
          changeFocusToElement(e.shiftKey ? 'prev' : 'next', document.body)
          ref.removeAttribute('tabindex')
          setShowEdit(false)
          onDismiss?.()
          break

        default:
          break
      }
    }

    const onClick = e => {
      if (
        !ref.parentElement.contains(e.target) &&
        !document.querySelector('#overlays').contains(e.target) &&
        !ref.isSameNode(e.target)
      ) {
        setShowEdit(false)
        onDismiss?.()
      }
    }

    window.addEventListener('keydown', onKeyDown)
    window.addEventListener('mousedown', onClick)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('mousedown', onClick)
    }
  }, [showEdit, ref])

  if (props.searchable || (field.options || []).length > 7) {
    return <Search {...(props as any)} onChange={handleChange} />
  }

  const currentOption = (field.options || []).find(opt => opt.value === value)

  return (
    <Wrapper ref={setRef}>
      {children || renderOption?.(currentOption) || currentOption ? (
        <CustomFieldSelectOption
          as="div"
          {...(currentOption ? getOptionColors(currentOption) : {})}
        >
          {currentOption?.label}
        </CustomFieldSelectOption>
      ) : (
        <Span color={colors.lightGray}>Not set</Span>
      )}

      {actions && (
        <ActionsBar
          actions={actions}
          onClick={() => window.getSelection().type !== 'Range' && setShowEdit(true)}
          onFocus={() => setShowEdit(true)}
        />
      )}

      {showEdit && ref ? (
        <Table2.ActionsWrapper
          width={250}
          {...customActions}
          anchorElement={ref.parentElement}
          customActions={
            <>
              <SelectorValues
                renderOption={renderOption}
                options={field.options}
                value={value}
                onChange={handleChange}
              />

              {customActions?.customActions}
            </>
          }
        >
          {children || renderOption?.(currentOption) || (
            <CustomFieldSelectOption
              as="div"
              {...(currentOption ? getOptionColors(currentOption) : {})}
            >
              {currentOption?.label}

              {currentOption && !nonNullable && (
                <StyledButton
                  onClick={e => {
                    e.stopPropagation()
                    onChange(null)
                  }}
                >
                  <Icon.Close />
                </StyledButton>
              )}
            </CustomFieldSelectOption>
          )}
        </Table2.ActionsWrapper>
      ) : (
        !readonly &&
        !actions && (
          <CellArea
            tabIndex={0}
            onFocus={() => setShowEdit(true)}
            onClick={() => window.getSelection().type !== 'Range' && ref.focus()}
          />
        )
      )}
    </Wrapper>
  )
}
