"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuController = void 0;
const styles_1 = require("../../styles");
const constants_1 = require("../../styles/constants");
const changeFocusToElement = function (element, baseElement) {
    var _a;
    if (document.activeElement) {
        const focussable = Array.from(baseElement === null || baseElement === void 0 ? void 0 : baseElement.querySelectorAll(constants_1.focussableElements)).filter((element) => element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement);
        const index = focussable.indexOf(document.activeElement);
        if (index > -1) {
            ;
            (_a = focussable[index + (element === 'next' ? 1 : -1)]) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }
};
class MenuController {
    constructor(id) {
        this.setContentElement = (el) => (this.contentElement = el);
        this.setTriggerElement = (el) => (this.triggerElement = el);
        this.open = () => {
            var _a, _b, _c;
            this.registerListeners();
            if (!this.triggerElement) {
                this.triggerElement = document
                    .getElementById(this.id)
                    .querySelector('[aria-haspopup=listbox]');
            }
            (_a = this.contentElement) === null || _a === void 0 ? void 0 : _a.focus();
            (_b = this.contentElement) === null || _b === void 0 ? void 0 : _b.setAttribute(styles_1.ARIA_HIDDEN, 'false');
            (_c = this.triggerElement) === null || _c === void 0 ? void 0 : _c.setAttribute(styles_1.ARIA_EXPANDED, 'true');
            Array.from(this.contentElement.querySelectorAll('button')).forEach(button => button.removeAttribute('tab-index'));
        };
        this.close = () => {
            var _a, _b, _c, _d;
            this.cleanupListeners();
            (_a = this.contentElement) === null || _a === void 0 ? void 0 : _a.setAttribute(styles_1.ARIA_HIDDEN, 'true');
            (_b = this.triggerElement) === null || _b === void 0 ? void 0 : _b.setAttribute(styles_1.ARIA_EXPANDED, 'false');
            (_c = this.triggerElement) === null || _c === void 0 ? void 0 : _c.focus();
            Array.from(this.contentElement.querySelectorAll('button')).forEach(button => button.setAttribute('tab-index', '-1'));
            (_d = this.contentElement) === null || _d === void 0 ? void 0 : _d.addEventListener('animationend', this.closeCleanup);
        };
        this.closeCleanup = () => {
            var _a;
            (_a = this.contentElement) === null || _a === void 0 ? void 0 : _a.removeEventListener('animationend', this.closeCleanup);
        };
        this.handleKeyDown = (ev) => {
            if (ev.key === 'ArrowDown') {
                ev.preventDefault();
                changeFocusToElement('next', this.contentElement);
            }
            else if (ev.key === 'ArrowUp') {
                ev.preventDefault();
                changeFocusToElement('prev', this.contentElement);
            }
        };
        this.handleButtonKeyDown = (ev) => {
            if (ev.key === 'Escape') {
                this.close();
            }
        };
        this.handleClick = (e) => {
            var _a, _b;
            if (!((_a = this.contentElement) === null || _a === void 0 ? void 0 : _a.contains(e.target)) &&
                !((_b = document.getElementById('overlays')) === null || _b === void 0 ? void 0 : _b.contains(e.target))) {
                this.close();
            }
        };
        this.registerListeners = () => {
            window.addEventListener('keydown', this.handleButtonKeyDown);
            window.addEventListener('touchstart', this.handleClick);
            window.addEventListener('mousedown', this.handleClick);
        };
        this.cleanupListeners = () => {
            window.removeEventListener('keydown', this.handleButtonKeyDown);
            window.removeEventListener('touchstart', this.handleClick);
            window.removeEventListener('mousedown', this.handleClick);
        };
        this.id = id;
    }
}
exports.MenuController = MenuController;
