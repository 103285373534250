"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Building = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Building = ({ width = 24, height = 24, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 24 24" },
    React.createElement("path", { d: "M20.2812 20.75H19.5V2.9375C19.5 2.41973 19.0803 2 18.5625 2H5.4375C4.91973 2 4.5 2.41973 4.5 2.9375V20.75H3.71875C3.45988 20.75 3.25 20.9599 3.25 21.2188V22H20.75V21.2188C20.75 20.9599 20.5401 20.75 20.2812 20.75ZM8.25 4.96875C8.25 4.70988 8.45988 4.5 8.71875 4.5H10.2812C10.5401 4.5 10.75 4.70988 10.75 4.96875V6.53125C10.75 6.79012 10.5401 7 10.2812 7H8.71875C8.45988 7 8.25 6.79012 8.25 6.53125V4.96875ZM8.25 8.71875C8.25 8.45988 8.45988 8.25 8.71875 8.25H10.2812C10.5401 8.25 10.75 8.45988 10.75 8.71875V10.2812C10.75 10.5401 10.5401 10.75 10.2812 10.75H8.71875C8.45988 10.75 8.25 10.5401 8.25 10.2812V8.71875ZM10.2812 14.5H8.71875C8.45988 14.5 8.25 14.2901 8.25 14.0312V12.4688C8.25 12.2099 8.45988 12 8.71875 12H10.2812C10.5401 12 10.75 12.2099 10.75 12.4688V14.0312C10.75 14.2901 10.5401 14.5 10.2812 14.5ZM13.25 20.75H10.75V17.4688C10.75 17.2099 10.9599 17 11.2188 17H12.7812C13.0401 17 13.25 17.2099 13.25 17.4688V20.75ZM15.75 14.0312C15.75 14.2901 15.5401 14.5 15.2812 14.5H13.7188C13.4599 14.5 13.25 14.2901 13.25 14.0312V12.4688C13.25 12.2099 13.4599 12 13.7188 12H15.2812C15.5401 12 15.75 12.2099 15.75 12.4688V14.0312ZM15.75 10.2812C15.75 10.5401 15.5401 10.75 15.2812 10.75H13.7188C13.4599 10.75 13.25 10.5401 13.25 10.2812V8.71875C13.25 8.45988 13.4599 8.25 13.7188 8.25H15.2812C15.5401 8.25 15.75 8.45988 15.75 8.71875V10.2812ZM15.75 6.53125C15.75 6.79012 15.5401 7 15.2812 7H13.7188C13.4599 7 13.25 6.79012 13.25 6.53125V4.96875C13.25 4.70988 13.4599 4.5 13.7188 4.5H15.2812C15.5401 4.5 15.75 4.70988 15.75 4.96875V6.53125Z", fill: color })));
exports.Building = Building;
