import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Drawer } from '@basisboard/basis-ui/es/components/Drawer'
import { H3 } from '@basisboard/basis-ui/es/components/Typography'
import { spacing } from '@basisboard/basis-ui/es/styles'
import { useContainer } from '@containrz/react-hook'
import { drawer } from './container'

export * from './container'

export const DrawerSystem = () => {
  const drawerContainer = useContainer(drawer)

  if (drawerContainer.state.drawers.length === 0) {
    return null
  }

  return (
    <>
      {drawerContainer.state.drawers.map((drawer, index) => (
        <Drawer
          key={drawer.id}
          closeDrawerAriaLabel="Close drawer"
          {...drawer}
          onClose={drawerContainer.closeDrawer}
          focused={index === drawerContainer.state.drawers.length - 1}
          isOpen
        >
          <Div display="flex" flexDirection="column" position="relative" height="100%">
            {drawer.title && (
              <Div mt={spacing(1)} mb={spacing(3)}>
                <Div display="flex" alignItems="center">
                  <H3 pl={spacing(4)} mr={spacing(1)}>
                    {drawer.title}
                  </H3>
                </Div>
              </Div>
            )}
            <Div flex={1} overflowY="auto">
              {drawer.content}
            </Div>
          </Div>
        </Drawer>
      ))}
    </>
  )
}
