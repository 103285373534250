"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadFolder = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const DownloadFolder = ({ width = 16, height = 16, size, color = styles_1.colors.accent, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 512 512" },
    React.createElement("path", { d: "M464 128H272l-54.63-54.63A32 32 0 0 0 194.74 64H48a48 48 0 0 0-48 48v288a48 48 0 0 0 48 48h416a48 48 0 0 0 48-48V176a48 48 0 0 0-48-48Zm0 272H48V112h140.12L232 155.88V256h-48.88a12 12 0 0 0-8.45 20.52L247 348.29a12.81 12.81 0 0 0 18 0l72.31-71.77a12 12 0 0 0-8.43-20.52H280v-80h184Z", fill: color })));
exports.DownloadFolder = DownloadFolder;
