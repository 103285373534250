"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrowUp = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const ArrowUp = ({ width = 14, height = 14, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 14 14" },
    React.createElement("path", { d: "M7.38749 4.80587L10.4865 7.90483C10.7006 8.11902 10.7006 8.46537 10.4865 8.67729L9.97148 9.19226C9.75728 9.40645 9.41093 9.40645 9.19902 9.19226L7.00012 6.99793L4.80351 9.19454C4.58931 9.40873 4.24296 9.40873 4.03105 9.19454L3.51379 8.67957C3.2996 8.46537 3.2996 8.11902 3.51379 7.9071L6.61275 4.80815C6.82695 4.59168 7.1733 4.59168 7.38749 4.80587Z", fill: color })));
exports.ArrowUp = ArrowUp;
