"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateFolder = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const CreateFolder = ({ width = 16, height = 16, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("g", { clipPath: "url(#clip0)" },
        React.createElement("path", { d: "M13.6875 4.5H8.4375L6.6875 2.75H2.3125C1.9644 2.75 1.63056 2.88828 1.38442 3.13442C1.13828 3.38056 1 3.7144 1 4.0625L1 11.9375C1 12.2856 1.13828 12.6194 1.38442 12.8656C1.63056 13.1117 1.9644 13.25 2.3125 13.25H13.6875C14.0356 13.25 14.3694 13.1117 14.6156 12.8656C14.8617 12.6194 15 12.2856 15 11.9375V5.8125C15 5.4644 14.8617 5.13056 14.6156 4.88442C14.3694 4.63828 14.0356 4.5 13.6875 4.5ZM10.8301 9.09375C10.8301 9.20978 10.784 9.32106 10.7019 9.40311C10.6199 9.48516 10.5086 9.53125 10.3926 9.53125H8.64258V11.2812C8.64258 11.3973 8.59648 11.5086 8.51444 11.5906C8.43239 11.6727 8.32111 11.7188 8.20508 11.7188H7.76758C7.65155 11.7188 7.54027 11.6727 7.45822 11.5906C7.37617 11.5086 7.33008 11.3973 7.33008 11.2812V9.53125H5.58008C5.46405 9.53125 5.35277 9.48516 5.27072 9.40311C5.18867 9.32106 5.14258 9.20978 5.14258 9.09375V8.65625C5.14258 8.54022 5.18867 8.42894 5.27072 8.34689C5.35277 8.26484 5.46405 8.21875 5.58008 8.21875H7.33008V6.46875C7.33008 6.35272 7.37617 6.24144 7.45822 6.15939C7.54027 6.07734 7.65155 6.03125 7.76758 6.03125H8.20508C8.32111 6.03125 8.43239 6.07734 8.51444 6.15939C8.59648 6.24144 8.64258 6.35272 8.64258 6.46875V8.21875H10.3926C10.5086 8.21875 10.6199 8.26484 10.7019 8.34689C10.784 8.42894 10.8301 8.54022 10.8301 8.65625V9.09375Z", fill: color })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0" },
            React.createElement("rect", { width: "14", height: "14", fill: "white", transform: "translate(1 1)" })))));
exports.CreateFolder = CreateFolder;
