import { Platform } from '@basisboard/basis-common/lib/enums'

export const mapPlatformToString: Record<Platform, string> = {
  [Platform.Bidmail]: 'Bidmail',
  [Platform.BuildingConnected]: 'Building Connected',
  [Platform.Gradebeam]: 'GradeBeam',
  [Platform.ISQFT]: 'iSqFt',
  [Platform.Planhub]: 'PlanHub',
  [Platform.Procore]: 'Procore',
  [Platform.Smartbid]: 'Smartbid',
  [Platform.TheBluebook]: 'The Blue Book',
  [Platform.PanteraTools]: 'Pantera Tools',
  [Platform.PipelineSuite]: 'Pipeline Suite',
  [Platform.ProcureWare]: 'Procure Ware',
  [Platform.BidTracer]: 'Bidtracer',
  [Platform.Unknown]: 'Unknown',
}

export const allowedPlatforms = [
  Platform.Planhub,
  Platform.Procore,
  Platform.ISQFT,
  Platform.BuildingConnected,
]
