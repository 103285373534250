import { Circle } from '@basisboard/basis-ui/es/components/Circle'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { PrintOnly } from '@basisboard/basis-ui/es/components/PrintOnly'
import { Tooltip } from '@basisboard/basis-ui/es/components/Tooltip'
import { DetailText, HeavyText, Text } from '@basisboard/basis-ui/es/components/Typography'
import { cssSize, spacing } from '@basisboard/basis-ui/es/styles'
import { getInitials } from '@basisboard/basis-ui/es/utils'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { background, BackgroundProps, space, SpaceProps } from 'styled-system'

const InitialsChip = styled.div<BackgroundProps & { large?: boolean; size?: number } & SpaceProps>`
  border-radius: 50%;

  ${({ large }) =>
    large
      ? css`
          ${cssSize(104, true)};
          font-size: 2rem;
        `
      : css`
          ${cssSize(24, true)};
          font-size: 0.625rem;
        `}

  ${({ size }) =>
    size &&
    css`
      ${cssSize(size, true)};
      line-height: ${size}px;
    `}

  margin-right: ${spacing(0.5)};

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  color: white;
  text-align: center;
  text-transform: uppercase;

  @media print {
    display: none;
  }

  ${background};
  ${space};
`

const Wrapper = styled.div<SpaceProps>`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: ${spacing(1)};
  }

  ${space};
`

interface Props extends SpaceProps {
  color: string
  name: string
  email?: string
  chipOnly?: boolean
  hideTooltip?: boolean
  light?: boolean
  large?: boolean
  size?: number
  isCurrentUser?: boolean
  showIcon?: boolean
  chipSpace?: SpaceProps
  fontSize?: number | string
  omitChip?: boolean
}

export const PersonChip: React.FC<Props> = ({
  color,
  name,
  chipOnly,
  hideTooltip,
  light,
  isCurrentUser,
  large,
  size,
  email,
  showIcon,
  chipSpace,
  fontSize,
  omitChip,
  ...rest
}) => {
  if (chipOnly) {
    return hideTooltip ? (
      <InitialsChip {...(chipSpace || {})} large={large} background={color} size={size}>
        {getInitials(name)}
      </InitialsChip>
    ) : (
      <Tooltip position="top" variant="dark" small content={<Text white>{name}</Text>}>
        <InitialsChip data-testid="initials-chip" {...(chipSpace || {})} background={color}>
          {getInitials(name)}
        </InitialsChip>

        <PrintOnly>
          <HeavyText preventClip mr={spacing(1)}>
            {name}
          </HeavyText>
        </PrintOnly>
      </Tooltip>
    )
  }

  const fixedName = isCurrentUser ? 'You' : name

  return (
    <Wrapper {...rest}>
      {omitChip ? (
        <Circle size={8} backgroundColor={color} />
      ) : (
        <InitialsChip {...(chipSpace || {})} background={color} size={size}>
          {showIcon ? <Icon.User size={16} /> : getInitials(name)}
        </InitialsChip>
      )}
      <Div ml={spacing(0.5)}>
        <HeavyText fontSize={fontSize} fontWeight={light ? 500 : 600} lineHeight="16px">
          {fixedName}
        </HeavyText>
        {email && (
          <DetailText fontWeight={500} as="div" lineHeight="16px">
            {email}
          </DetailText>
        )}
      </Div>

      <PrintOnly>
        <HeavyText as="span">{fixedName}</HeavyText>
      </PrintOnly>
    </Wrapper>
  )
}
