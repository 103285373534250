import { borderRadiusMixin, colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import styled from 'styled-components'

export const Progress = styled.div<{ active?: boolean }>`
  width: 32px;
  height: 4px;
  ${borderRadiusMixin};
  background-color: ${colors.lightGray};
  position: relative;
  overflow: hidden;

  &:not(:last-child) {
    margin-right: ${spacing(0.5)};
  }

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    background-color: ${colors.accent};
    width: ${({ active }) => (active ? '100%' : 0)};
    ${transitionMixin};
  }
`
