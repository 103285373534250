"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Clock = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Clock = ({ width = 16, height = 16, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M7.99996 1.54199C4.43225 1.54199 1.54163 4.43262 1.54163 8.00033C1.54163 11.568 4.43225 14.4587 7.99996 14.4587C11.5677 14.4587 14.4583 11.568 14.4583 8.00033C14.4583 4.43262 11.5677 1.54199 7.99996 1.54199ZM9.48694 10.6592L7.19006 8.98991C7.10933 8.93001 7.06246 8.83626 7.06246 8.7373V4.35449C7.06246 4.18262 7.20308 4.04199 7.37496 4.04199H8.62496C8.79683 4.04199 8.93746 4.18262 8.93746 4.35449V7.94043L10.5911 9.14355C10.7317 9.24512 10.7604 9.44043 10.6588 9.58105L9.92444 10.5915C9.82288 10.7295 9.62756 10.7607 9.48694 10.6592Z", fill: color })));
exports.Clock = Clock;
