"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Crown = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Crown = ({ width = 24, height = 24, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 24 24" },
    React.createElement("path", { d: "M18.5 18H5.5C5.225 18 5 18.225 5 18.5V19.5C5 19.775 5.225 20 5.5 20H18.5C18.775 20 19 19.775 19 19.5V18.5C19 18.225 18.775 18 18.5 18ZM20.5 8C19.6719 8 19 8.67188 19 9.5C19 9.72188 19.05 9.92812 19.1375 10.1187L16.875 11.475C16.3938 11.7625 15.7719 11.6 15.4937 11.1125L12.9469 6.65625C13.2812 6.38125 13.5 5.96875 13.5 5.5C13.5 4.67188 12.8281 4 12 4C11.1719 4 10.5 4.67188 10.5 5.5C10.5 5.96875 10.7188 6.38125 11.0531 6.65625L8.50625 11.1125C8.22813 11.6 7.60313 11.7625 7.125 11.475L4.86562 10.1187C4.95 9.93125 5.00313 9.72188 5.00313 9.5C5.00313 8.67188 4.33125 8 3.50312 8C2.675 8 2 8.67188 2 9.5C2 10.3281 2.67188 11 3.5 11C3.58125 11 3.6625 10.9875 3.74062 10.975L6 17H18L20.2594 10.975C20.3375 10.9875 20.4188 11 20.5 11C21.3281 11 22 10.3281 22 9.5C22 8.67188 21.3281 8 20.5 8Z", fill: color })));
exports.Crown = Crown;
