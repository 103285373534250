"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stages = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Stages = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M1.68136 5.19877L7.7239 7.93989C7.9003 8.02004 8.09978 8.02004 8.27617 7.93989L14.3187 5.19877C14.748 5.00396 14.748 4.35519 14.3187 4.16038L8.27643 1.419C8.10107 1.33885 7.89953 1.33885 7.72416 1.419L1.68136 4.16012C1.25205 4.35493 1.25205 5.00396 1.68136 5.19877ZM14.319 7.48878L12.8121 6.80577L8.6191 8.70642C8.42299 8.7954 8.21469 8.84053 8.00017 8.84053C7.78564 8.84053 7.5776 8.7954 7.38123 8.70642L3.18849 6.80577L1.68136 7.48878C1.25205 7.68333 1.25205 8.33184 1.68136 8.5264L7.7239 11.2654C7.9003 11.3453 8.09978 11.3453 8.27617 11.2654L14.319 8.5264C14.7483 8.33184 14.7483 7.68333 14.319 7.48878ZM14.319 10.804L12.8178 10.1235L8.6191 12.0268C8.42299 12.1158 8.21469 12.1609 8.00017 12.1609C7.78564 12.1609 7.5776 12.1158 7.38123 12.0268L3.18279 10.1235L1.68136 10.804C1.25205 10.9985 1.25205 11.647 1.68136 11.8416L7.7239 14.5806C7.9003 14.6605 8.09978 14.6605 8.27617 14.5806L14.319 11.8416C14.7483 11.647 14.7483 10.9985 14.319 10.804Z", fill: color })));
exports.Stages = Stages;
