import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { getUniqId } from '@basisboard/basis-ui/es/utils'
import React from 'react'
import { Editor } from '../../../../../../components/Editor'
import { TableFieldProps } from '../../type'
import { BaseField } from '../BaseField'

const uniqId = getUniqId()

export const RichText: React.FC<TableFieldProps> = ({
  value,
  field,
  forceShowEdit,
  onChange,
  customActions,
  actions,
}) => {
  const [text, setText] = React.useState(value || field.initialValue || '')
  const [editing, setEditing] = React.useState(false)

  const handleEdit = React.useCallback(() => {
    if (text !== value) {
      onChange(text)
    }
  }, [text, value])

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.nativeEvent.code === 'Escape') {
        setText(value)
        return
      }
      if (editing) {
        e.preventDefault()
        e.stopPropagation()
      }
    },
    [setText],
  )

  React.useEffect(() => {
    if (!editing) {
      return
    }
    const onkeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setEditing(false)
        setText(value)
      }

      e.stopPropagation()
    }

    window.addEventListener('keydown', onkeyDown, { capture: true })
    return () => {
      window.removeEventListener('keydown', onkeyDown, { capture: true })
    }
  }, [editing])

  return (
    <BaseField
      forceShowEdit={forceShowEdit}
      onShowEdit={setEditing}
      customActions={customActions}
      onDismiss={handleEdit}
      readonly={!Boolean(onChange)}
      noPadding
      actions={actions}
    >
      {editing ? (
        <Editor
          onKeyDown={handleKeyDown}
          height={180}
          defaultValue={text}
          onChange={val => setText(val)}
          onBlur={handleEdit}
          allowMentions
          id={`table-field-rich-text-${uniqId}`}
          name={`table-field-rich-text-${uniqId}`}
        />
      ) : (
        <Text dangerouslySetInnerHTML={{ __html: (value as string) || 'Not set' }} />
      )}
    </BaseField>
  )
}
