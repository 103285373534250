"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarExport = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const CalendarExport = ({ width = 16, height = 16, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M14.4926 6.18157L11.1592 9.32937C10.8078 9.6612 10.2221 9.41518 10.2221 8.92548V7.25967C6.8755 7.28213 5.46367 8.07247 6.40791 11.2262C6.51168 11.5728 6.11054 11.8412 5.82906 11.6273C4.92705 10.9422 4.11103 9.63157 4.11103 8.30868C4.11103 4.9768 6.83323 4.31562 10.2221 4.29683V2.62981C10.2221 2.13967 10.8082 1.89444 11.1592 2.22592L14.4926 5.37379C14.7245 5.59285 14.7247 5.96234 14.4926 6.18157ZM10.2221 10.8501V12.4443H2.81473V5.03694H3.99334C4.06893 5.03689 4.14123 5.00602 4.19353 4.95146C4.53966 4.59109 4.93976 4.30585 5.37441 4.07933C5.63205 3.94504 5.53659 3.55546 5.24605 3.55546H2.44436C1.83071 3.55546 1.33325 4.05292 1.33325 4.66657V12.8147C1.33325 13.4284 1.83071 13.9258 2.44436 13.9258H10.5925C11.2062 13.9258 11.7036 13.4284 11.7036 12.8147V10.759C11.7036 10.5672 11.5139 10.4334 11.333 10.4974C11.0794 10.5871 10.8078 10.6139 10.5416 10.5756C10.3733 10.5514 10.2221 10.6801 10.2221 10.8501Z", fill: color })));
exports.CalendarExport = CalendarExport;
