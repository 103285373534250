import {
  COMPANY_ID,
  GetProjectsRequestQuery,
  PatchCompetitorRequestBody,
  PatchProjectRequest,
  PostCompetitorRequestBody,
  PostProjectDuplicateRequest,
  PostProjectMergeRequestBody,
  PostProjectsRequest,
  ProjectDetail,
  PROJECT_ID,
  StageCode,
  STAGE_ID,
  STAGE_REASON_ID,
  User,
  USER_ID,
} from '@basisboard/basis-common/lib/api'
import { box, fromBoolean } from '@basisboard/basis-ui/es/utils'
import { Container, getContainer } from '@containrz/react-hook'
import moment from 'moment'
import { normalize } from 'normalizr'
import keys from 'ramda/src/keys'
import mergeDeepRight from 'ramda/src/mergeDeepRight'
import omit from 'ramda/src/omit'
import pick from 'ramda/src/pick'
import { MINUTES } from '../../constants'
import { screens } from '../../screens'
import { eventBus, EventBusType } from '../../services'
import { NestedProject, NestedProjectDetail } from '../../types'
import { StagesState } from '../Stages/container'
import { showSuccessToast } from '../Toast'
import {
  assignEstimators,
  deleteCompetitorInfo,
  deleteProject,
  deleteStateChange,
  duplicateProject,
  getEmailAddress,
  getNestedProjectDetail,
  getProjectActivities,
  getProjectsFromIds,
  getProjectsIds,
  linkCompanyToProject,
  markProjectNotesAsRead,
  mergeProjects,
  patchCompetitorInfo,
  patchProject,
  patchProjectKey,
  postCompetitorInfo,
  postProject,
  putProjectStage,
  unlinkCompanyFromProject,
  updateProjectReason,
} from './api'
import { projectSchema } from './schema'
import { showAddBidsToast } from './toasts'
import { NormalizedProjects } from './types'
import { createNestedProjectDetail, denormalizeProjects } from './utils'

export * from './utils'

class ProjectsContainer extends Container<NormalizedProjects> {
  private timer: number
  state = {
    entities: {
      projects: {},
      companies: {},
      contacts: {},
      bidInvites: {},
    },
  }

  lastUpdatedAt = moment()

  constructor() {
    super()

    this.timer = window.setInterval(() => this.silentlyUpdateProjects(), 2 * MINUTES)
  }

  destroy = () => {
    clearInterval(this.timer)
  }

  getProjectDetails = (projectId: PROJECT_ID) =>
    getNestedProjectDetail(projectId).then(project => {
      this.updateProject((project as unknown) as NestedProject)
      return project
    })

  getProjects = (params: GetProjectsRequestQuery) =>
    getProjectsIds(params)
      .then(re => re.projectIds)
      .then(this.getProjectsFromIds)

  getProjectsWithTotalCount = (params: GetProjectsRequestQuery) =>
    getProjectsIds(params).then(async response => {
      const projects = await this.getProjectsFromIds(response.projectIds)
      return {
        projects,
        totalCount: response.paging?.total || 0,
      }
    })

  silentlyUpdateProjects = async () => {
    const { projectIds } = await getProjectsIds({
      filter: { lastUpdatedAt: this.lastUpdatedAt.toISOString() },
    })

    this.lastUpdatedAt = moment()

    if (projectIds.length > 0) {
      getProjectsFromIds(projectIds).then(projects => {
        const normalizedProjects = mergeDeepRight(this.state, projects)
        this.setState(normalizedProjects)

        eventBus.publish(EventBusType.RefreshProjects, { projectIds })
      })
    }
  }

  getProjectsFromIds = async (
    projectIds: PROJECT_ID[],
    params: GetProjectsRequestQuery = {},
    flush?: boolean,
  ): Promise<NestedProject[]> => {
    const missingProjects = flush
      ? projectIds
      : projectIds.filter(p => !this.state.entities.projects[p])

    if (missingProjects.length > 0) {
      await getProjectsFromIds(missingProjects, params).then(entities =>
        this.setState(mergeDeepRight(this.state, entities)),
      )
    }

    return denormalizeProjects(projectIds, this.state.entities)
  }

  getExistingProjectsFromIds = (projectIds: PROJECT_ID[]) =>
    box(projectIds.filter(p => !!this.state.entities.projects[p])).fold(projectIds =>
      denormalizeProjects(projectIds, this.state.entities),
    )

  searchProjects = async (
    searchQuery: string,
    params: GetProjectsRequestQuery = {},
  ): Promise<NestedProject[]> =>
    (searchQuery || '').length > 0 &&
    this.getProjects({
      ...params,
      filter: {
        ...(params?.filter || {}),
        searchQuery,
      },
    } as GetProjectsRequestQuery)

  updateProject = (project: NestedProject | NestedProjectDetail) => {
    const entities = normalize(project, projectSchema)

    this.setState(mergeDeepRight(this.state, entities))
  }

  delete = (projectId: PROJECT_ID) => {
    eventBus.publish(EventBusType.DeleteProject, { projectId })

    this.updateProject({
      ...this.state.entities.projects[projectId],
      deletedAt: moment().toISOString(),
      archivedAt: null,
    })

    return deleteProject(projectId).then(() => patchProjectKey(projectId, 'archivedAt', null))
  }

  archive = (projectId: PROJECT_ID, archive: boolean) =>
    fromBoolean(archive).fold(
      () =>
        this.patchProject({
          ...this.state.entities.projects[projectId],
          archivedAt: null,
        }),
      () => {
        eventBus.publish(EventBusType.ArchiveProject, { projectId })
        return this.patchProject({
          ...this.state.entities.projects[projectId],
          archivedAt: moment().toISOString(),
          deletedAt: null,
        })
      },
    )

  patchProject = (project: NestedProject) => {
    const fixedProject: PatchProjectRequest = box(
      pick(
        ['name', 'location', 'customFields', 'deletedAt', 'archivedAt'],
        project,
      ) as PatchProjectRequest,
    ).fold((p: PatchProjectRequest) =>
      keys(p).reduce(
        (proj, key) =>
          this.state.entities.projects[project.id][key] === proj[key] ? omit([key], proj) : proj,
        p,
      ),
    )

    if (keys(fixedProject).length) {
      this.updateProject(project)

      return patchProject(project.id, fixedProject).then(response => {
        this.updateProject(response as NestedProject)

        eventBus.publish(EventBusType.EditProject, {
          projectId: project.id,
          updatedFields: fixedProject,
        })

        return response
      })
    }
  }

  patchProjectKey = (
    projectId: PROJECT_ID,
    key: keyof PatchProjectRequest | 'estimatorIds' | string,
    value: any,
  ): Promise<NestedProject | ProjectDetail> => {
    if (key === 'estimatorIds') {
      this.assignProjectEstimators(projectId, value)
      return this.getProjectsFromIds([projectId]).then(response => response[0])
    }

    const project = this.state.entities.projects[projectId]

    if (project) {
      this.updateProject({
        ...project,
        [key]: value,
      })
    }

    eventBus.publish(EventBusType.EditProject, {
      projectId,
      updatedFields: { [key]: value },
    })

    return project?.[key] !== value
      ? patchProject(projectId, { [key]: value }).then(response => {
          this.updateProject(response as NestedProject)
          return response
        })
      : this.getProjectsFromIds([projectId]).then(response => {
          this.updateProject(response[0] as NestedProject)
          return response[0]
        })
  }

  updateProjectReason = (
    projectId: PROJECT_ID,
    stageReasonId: STAGE_REASON_ID,
    stageCode: StageCode,
  ) => {
    this.updateProject({
      ...this.state.entities.projects[projectId],
      stageReasonId,
    })

    updateProjectReason(projectId, { stageReasonId, stageCode })
  }

  assignProjectEstimators = (projectId: PROJECT_ID, estimators: (User | USER_ID)[]) => {
    const estimatorIds = estimators.map(e => (e as User).id || (e as USER_ID))

    this.updateProject({
      ...this.state.entities.projects[projectId],
      estimators,
      estimatorIds,
    })

    deleteStateChange(projectId, true)

    return assignEstimators(projectId, { userIds: estimatorIds }).then(project => {
      eventBus.publish(EventBusType.AssignEstimator, { projectId, estimatorIds })

      this.updateProject(project as NestedProject)
      return project
    })
  }

  changeStageForProject = (
    stageId: STAGE_ID,
    projectId: PROJECT_ID,
    stageReasonId?: STAGE_REASON_ID,
    comment?: string,
  ) => {
    const newStage = getContainer(StagesState).state.stages.find(s => s.id === stageId)

    if (!newStage || this.state.entities?.projects?.[projectId]?.stageId === stageId) {
      return Promise.reject('Stage not found or not changed')
    }

    eventBus.publish(EventBusType.ChangeProjectStage, {
      projectId,
      stageId,
      stage: newStage.name,
      stageReasonId,
      comment,
    })

    this.updateProject({
      ...this.state.entities.projects[projectId],
      projectId,
      stage: newStage,
      stageId,
      stageReasonId,
    })

    if (this.state.entities.projects[projectId]?.deletedAt) {
      this.patchProjectKey(projectId, 'deletedAt', null)
    }

    return putProjectStage(projectId, stageId, stageReasonId, comment).then(() =>
      deleteStateChange(projectId, true),
    )
  }

  createProject = (data: PostProjectsRequest, omitToast?: boolean) =>
    postProject(data).then(projectId => {
      eventBus.publish(EventBusType.CreateProject, { projectId })

      !omitToast && showAddBidsToast(projectId)

      return projectId
    })

  linkCompanyToProject = (body: PostProjectsRequest) => {
    eventBus.publish(EventBusType.LinkCompanyToProject, body)
    return linkCompanyToProject(body)
  }

  unlinkCompanyFromProject = (projectId: PROJECT_ID, companyId: COMPANY_ID) => {
    eventBus.publish(EventBusType.UnlinkCompanyFromProject, { projectId, companyId })
    return unlinkCompanyFromProject(projectId, companyId)
  }

  mergeProjects = (projectId: PROJECT_ID, data: PostProjectMergeRequestBody) =>
    mergeProjects(projectId, data).then(({ project, ...entities }) => {
      eventBus.publish(EventBusType.MergeProjects, { projectId, data })

      return (createNestedProjectDetail({ project, ...entities }) as never) as NestedProject
    })

  getActivities = getProjectActivities

  duplicate = (projectId: PROJECT_ID, data: PostProjectDuplicateRequest) =>
    duplicateProject(projectId, data).then(response => {
      eventBus.publish(EventBusType.DuplicateProject, data)

      showSuccessToast({
        message: 'Successfully duplicated the bid.',
        actions: [{ label: 'See details', onAction: () => screens.projectDetails.push(response) }],
      })

      return response
    })

  createCompetitor = (
    projectId: PROJECT_ID,
    companyId: COMPANY_ID,
    body: PostCompetitorRequestBody,
  ) =>
    postCompetitorInfo(projectId, companyId, body).then(response =>
      eventBus.publish(EventBusType.CreateCompetitor, response),
    )

  editCompetitor = (
    projectId: PROJECT_ID,
    companyId: COMPANY_ID,
    body: PatchCompetitorRequestBody,
  ) =>
    patchCompetitorInfo(projectId, companyId, body).then(() =>
      eventBus.publish(EventBusType.EditCompetitor, body),
    )

  removeCompetitor = (projectId: PROJECT_ID, companyId: COMPANY_ID) =>
    deleteCompetitorInfo(projectId, companyId).then(() =>
      eventBus.publish(EventBusType.DeleteCompetitor),
    )

  getEmailAddress = getEmailAddress

  markProjectNotesAsRead = (projectId: PROJECT_ID) => {
    markProjectNotesAsRead(projectId)

    eventBus.publish(EventBusType.MarkNotesAsRead)

    this.updateProject({
      ...this.state.entities.projects[projectId],
      unreadNotes: 0,
    })
  }
}

export const projectRepository = () => getContainer(ProjectsContainer)
