import { borderMixin, borderRadiusMixin, colors, spacing } from '@basisboard/basis-ui/es/styles'
import styled, { css } from 'styled-components'

export const Banner = styled.div<{ borderColor?: string }>(
  ({ borderColor }) => css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${borderMixin(borderColor || colors.blueGrayBorder)};
  ${borderRadiusMixin}
  padding: ${spacing(2)} ${spacing(3)};
  margin-bottom: ${spacing(3)};
  overflow: hidden;
`,
)
