import { colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled from 'styled-components'
import { maxHeight, MaxHeightProps } from 'styled-system'
import { Dropdown, DropdownProps } from '../Dropdown'

const Menu = styled.ul<MaxHeightProps>`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: 0;
  padding: ${spacing(2)} 0;
  overflow: auto;

  ${maxHeight};
`

const MenuEntry = styled.li`
  width: 100%;
  height: 32px;

  padding: 0 ${spacing(2)};

  &:hover {
    background: ${colors.lightGray3};
  }
  ${transitionMixin};

  & > * {
    line-height: 32px;
    width: 100%;
  }
`

export const DropdownList: React.FC<DropdownProps & MaxHeightProps> = ({ children, ...rest }) => (
  <Dropdown {...rest}>
    {onClose => (
      <Menu onClick={e => e.stopPropagation()} {...(rest as MaxHeightProps)}>
        {React.Children.toArray(children(onClose)).map((child, index) => (
          <MenuEntry key={index} onClick={onClose}>
            {child}
          </MenuEntry>
        ))}
      </Menu>
    )}
  </Dropdown>
)
