import { Button } from '@basisboard/basis-ui/es/components/Button'
import { IconType } from '@basisboard/basis-ui/es/components/Icon'
import { spacing } from '@basisboard/basis-ui/es/styles'
import { box, fromNullable } from '@basisboard/basis-ui/es/utils'
import * as React from 'react'
import styled from 'styled-components'
import { width, WidthProps } from 'styled-system'
import { Dropdown } from '../Dropdown'

const MenuList = styled.ul.attrs({
  role: 'listbox',
  tabIndex: -1,
})<WidthProps>`
  list-style: none;
  padding: ${spacing(1.5)} ${spacing(1)};

  margin: 0 ${spacing(-2)};

  max-height: 260px;
  width: calc(100% + ${spacing(4)});

  overflow: auto;

  ${width};
`

const MenuItem = styled.li.attrs<{ selected?: boolean }>(({ selected }) => ({
  role: 'option',
  'aria-selected': selected,
}))<{ selected?: boolean }>`
  width: 100%;

  button {
    width: 100%;
  }

  button > span {
    justify-content: flex-start;
  }
`

interface RenderAction {
  render: (onClose: () => void) => React.ReactNode
  active: boolean
}

interface ObjectAction {
  label: string
  onAction: () => void
  active: boolean
}

interface Props {
  title: string
  label?: string
  align?: 'left' | 'right'
  width?: number
  icon?: IconType
  preIcon?: IconType
  renderTrigger?: (onOpen: () => void) => React.ReactNode
  actions: (ObjectAction | RenderAction)[]
}

export const Menu: React.FC<Props> = ({
  title,
  label,
  icon,
  preIcon,
  actions,
  renderTrigger,
  align = 'left',
  width = 140,
}) => {
  return (
    <Dropdown
      height={'auto'}
      align={align}
      renderTrigger={
        renderTrigger
          ? renderTrigger
          : (onOpen, isOpened) => (
              <Button.Text
                aria-haspopup="listbox"
                aria-expanded={isOpened}
                aria-label={label}
                postIcon={icon || 'ArrowDown'}
                preIcon={preIcon}
                onClick={onOpen}
              >
                {title}
              </Button.Text>
            )
      }
    >
      {onClose => (
        <MenuList width={width}>
          {actions.map((action, index) => (
            <MenuItem key={index} selected={action.active}>
              {fromNullable((action as RenderAction).render).fold(
                () =>
                  box(action as ObjectAction).fold(({ label, active, onAction }) => (
                    <Button.Text
                      active={active}
                      onClick={() => {
                        onAction()
                        onClose()
                      }}
                    >
                      {label}
                    </Button.Text>
                  )),
                () => (action as RenderAction).render(onClose),
              )}
            </MenuItem>
          ))}
        </MenuList>
      )}
    </Dropdown>
  )
}
