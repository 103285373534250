import {
  BID_INVITE_ID,
  COMPANY_ID,
  CONTACT_ID,
  PatchBidInviteRequest,
  PostSplitBidInviteRequest,
  PROJECT_ID,
} from '@basisboard/basis-common/lib/api'
import { eventBus, EventBusType } from '../../services'
import { showSuccessToast } from '../Toast'
import {
  linkContactFromBidInvite,
  patchBidInvite,
  splitBids,
  switchCompany,
  switchProject,
} from './api'

export * from './api'

class BidInviteRepository {
  splitBids = (bidInviteId: BID_INVITE_ID, params: PostSplitBidInviteRequest) =>
    splitBids(bidInviteId, params).then(response => {
      eventBus.publish(EventBusType.SplitBids, { projectId: response.projectId })
      return response
    })

  patchBidInvite = (bidInviteId: BID_INVITE_ID, key: keyof PatchBidInviteRequest, value: any) => {
    return patchBidInvite(bidInviteId, key, value)
  }

  switchCompany = (bidInviteId: BID_INVITE_ID, companyId: COMPANY_ID) => {
    return switchCompany(bidInviteId, companyId)
  }

  switchProject = (bidInviteId: BID_INVITE_ID, projectId: PROJECT_ID) =>
    switchProject(bidInviteId, projectId).then(response => {
      eventBus.publish(EventBusType.SwitchBidFromProject, {
        projectId,
      })
      return response
    })

  linkContactFromBidInvite = (contactId: CONTACT_ID, bidInviteId: BID_INVITE_ID) =>
    linkContactFromBidInvite(contactId, bidInviteId).then(response => {
      eventBus.publish(EventBusType.LinkContactToProject, { contactId, bidInviteId })
      showSuccessToast({ message: 'A new contact was successfully linked to this project.' })
      return response
    })
}

export const bidInviteRepository = new BidInviteRepository()
