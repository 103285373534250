"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Robot = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Robot = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 20 16" },
    React.createElement("path", { d: "M6 12.75H8V11.25H6V12.75ZM18 6H17C17 4.375 15.625 3 14 3H10.75V0.75C10.75 0.34375 10.4062 0 10 0C9.5625 0 9.25 0.34375 9.25 0.75V3H6C4.34375 3 3 4.375 3 6H2C1.15625 6 0.5 6.6875 0.5 7.5V11.5C0.5 12.3438 1.15625 13 2 13H3C3 14.6562 4.34375 16 6 16H14C15.625 16 17 14.6562 17 13H18C18.8125 13 19.5 12.3438 19.5 11.5V7.5C19.5 6.6875 18.8125 6 18 6ZM3 11.5H2V7.5H3V11.5ZM15.5 13C15.4688 13.8438 14.8125 14.5 14 14.5H6C5.15625 14.5 4.5 13.8438 4.5 13V6C4.5 5.1875 5.15625 4.5 6 4.5H14C14.8125 4.5 15.5 5.1875 15.5 6V13ZM18 11.5H17V7.5H18V11.5ZM7.5 6.5C6.65625 6.5 6 7.1875 6 8C6 8.84375 6.65625 9.5 7.5 9.5C8.3125 9.5 9 8.84375 9 8C9 7.1875 8.3125 6.5 7.5 6.5ZM12.5 6.5C11.6562 6.5 11 7.1875 11 8C11 8.84375 11.6562 9.5 12.5 9.5C13.3125 9.5 14 8.84375 14 8C14 7.1875 13.3125 6.5 12.5 6.5ZM12 12.75H14V11.25H12V12.75ZM9 12.75H11V11.25H9V12.75Z", fill: color })));
exports.Robot = Robot;
