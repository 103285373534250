"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Check = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Check = ({ width = 16, height = 16, size, color = styles_1.colors.white }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M5.86184 12.7761L1.52851 8.44281C1.26817 8.18247 1.26817 7.76036 1.52851 7.49999L2.47129 6.55718C2.73163 6.29682 3.15377 6.29682 3.4141 6.55718L6.33325 9.4763L12.5857 3.22385C12.8461 2.96351 13.2682 2.96351 13.5285 3.22385L14.4713 4.16666C14.7317 4.427 14.7317 4.84911 14.4713 5.10947L6.80465 12.7762C6.54429 13.0365 6.12218 13.0365 5.86184 12.7761Z", fill: color })));
exports.Check = Check;
