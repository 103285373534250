import { CompanyWithStats } from '@basisboard/basis-common/lib/api'
import { Badge } from '@basisboard/basis-ui/es/components/Badge'
import { Row } from '@basisboard/basis-ui/es/components/Row'
import { DetailText, HeavyText } from '@basisboard/basis-ui/es/components/Typography'
import { colors } from '@basisboard/basis-ui/es/styles'
import { isUuid } from '@basisboard/basis-ui/es/utils/helpers'
import * as React from 'react'
import { SearchInput } from '../../../../components/Input/SearchInput'
import { companyRepository } from '../../container'

type CompanyItem = Pick<CompanyWithStats, 'id' | 'name' | 'openBidsCount' | 'previousBidsCount'>

interface Props {
  value: Partial<CompanyItem>
  defaultOptions?: Partial<CompanyItem>[]
  onChange(value: Partial<CompanyItem>): void
  disabled?: boolean
  canCreate?: boolean
  name?: string
  placeholder?: string
  omitEmptyError?: boolean
}

export const CompanyInput: React.FC<Props> = ({
  value,
  onChange,
  disabled,
  canCreate = true,
  name,
  placeholder,
  defaultOptions = [],
  omitEmptyError,
}) => {
  const renderListItem = (company: CompanyItem) => (
    <Row justifyContent="space-between" width="100%" minHeight={32}>
      <HeavyText>{company.name}</HeavyText>
      <DetailText ml="auto" style={{ flexShrink: 0 }}>
        {company.openBidsCount} Open and {company.previousBidsCount} Previous Projects
      </DetailText>
    </Row>
  )

  const renderCreateItem = searchTerm => (
    <Row minHeight={32}>
      <DetailText color={colors.gray}>
        Add{' '}
        <HeavyText as="span" fontSize="12px">
          <strong>“{searchTerm}”</strong>
        </HeavyText>{' '}
        as a new company
      </DetailText>
    </Row>
  )

  const fetchCompanies = (name: string) =>
    Promise.resolve(
      companyRepository().state.companyNames.filter(c => {
        const regex = new RegExp(name, 'i')
        return regex.test(c.name)
      }),
    )

  return (
    <SearchInput<Partial<CompanyItem>>
      placeholder={placeholder || 'Search for a company'}
      value={value ? value.name : ''}
      fetchData={c =>
        (c || '').length > 0
          ? fetchCompanies(c)
          : Promise.resolve(
              defaultOptions.map(dOpt => {
                const companyWithStats = companyRepository().state.companyNames.find(
                  c => c.name === dOpt.name,
                )
                return companyWithStats ? companyWithStats : dOpt
              }),
            )
      }
      renderListItem={renderListItem}
      renderCreateItem={canCreate ? renderCreateItem : () => null}
      onCreate={(searchTerm: string) => {
        onChange({ name: searchTerm })
      }}
      onSelect={onChange}
      formatNewValue={val => ({ name: val })}
      suffix={canCreate && !isUuid(value?.id ?? '') && value?.name?.length !== 0 && <Badge.New />}
      disabled={disabled}
      name={name}
      emptyError={omitEmptyError ? undefined : 'You must select or create a company for this Bid'}
    />
  )
}
