import { copyTextToClipboard } from '@basisboard/basis-ui/es/utils'
import { showErrorToast, showSuccessToast } from '../../containers/Toast'
import { eventBus, EventBusType } from '../../services'

eventBus.register(EventBusType.CreateQuoteError, (err: Error) => {
  showErrorToast({
    message: 'Something went wrong while creating your quote',
    actions: [
      { label: 'Copy error', onAction: () => copyTextToClipboard(JSON.stringify(err.message)) },
    ],
  })
})

eventBus.register(EventBusType.CreateRevision, () => {
  showSuccessToast({ message: 'Successfully added a revision to your quote.' })
})
