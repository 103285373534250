import { CustomFieldType } from '@basisboard/basis-common/lib/api/custom-fields'
import {
  CompanyFieldType,
  ContactFieldType,
  ProjectFieldType,
  TasksFieldType,
} from '@basisboard/basis-common/lib/enums'
import { ViewId } from '../../constants'
import { Field } from './types'

declare module '@basisboard/basis-common/lib/api/custom-fields' {
  export enum CustomFieldType {
    Custom = 'custom',
    StageSelect = 'stage-select',
    ContactMultiSelect = 'contact-multi-select',
  }
}

export const createField = (
  id: string,
  type: CustomFieldType,
  name: string,
  isStatic?: boolean,
  width?: number,
  isEnabled = true,
) => ({
  id,
  type,
  name,
  label: name,
  width,
  isEnabled,
  isStatic,
})

export const DEFAULT_LIST_FIELDS: Field[] = [
  createField(ProjectFieldType.ProjectName, CustomFieldType.Text, 'Project name', true, 360),
  createField(ProjectFieldType.Companies, CustomFieldType.Custom, 'Companies', false, 360),
  createField(ProjectFieldType.BidDeadline, CustomFieldType.Date, 'Bid deadline', false, 240),
  createField(ProjectFieldType.StartAt, CustomFieldType.Date, 'Project start', false, 240),
  createField(ProjectFieldType.EndAt, CustomFieldType.Date, 'Project end', false, 240),
  createField(ProjectFieldType.Estimator, CustomFieldType.Text, 'Estimator', false, 200),
  createField(ProjectFieldType.Stage, CustomFieldType.Text, 'Stage', false, 200),
  createField(ProjectFieldType.Quotes, CustomFieldType.Monetary, 'Quotes', false, 240, false),
  createField(ProjectFieldType.CreatedAt, CustomFieldType.Date, 'Created date', false, 240, false),
  createField(ProjectFieldType.JobWalk, CustomFieldType.Date, 'Job walk', false, 240, false),
  createField(
    ProjectFieldType.RFIDeadline,
    CustomFieldType.Date,
    'RFI deadline',
    false,
    240,
    false,
  ),
  createField(
    ProjectFieldType.SubmittedAt,
    CustomFieldType.Date,
    'Submitted date',
    false,
    240,
    false,
  ),
  createField(ProjectFieldType.AwardedAt, CustomFieldType.Date, 'Awarded date', false, 240, false),
  createField(ProjectFieldType.LostAt, CustomFieldType.Date, 'Lost date', false, 240, false),
  createField(ProjectFieldType.Location, CustomFieldType.Text, 'Location', false, 160),
  createField(ProjectFieldType.Region, CustomFieldType.Text, 'Region', false, 160),
  createField(ProjectFieldType.ZipCode, CustomFieldType.Text, 'Zip code', false, 160),
  createField(
    ProjectFieldType.StageReason,
    CustomFieldType.SingleSelect,
    'Stage reason',
    false,
    200,
    false,
  ),
  createField(ProjectFieldType.Contact, CustomFieldType.Custom, 'Contact', false, 200, false),
  createField(ProjectFieldType.Keyword, CustomFieldType.Custom, 'Keywords', false, 200, false),
]

const DEFAULT_BOARD_FIELDS: Field[] = [
  createField(ProjectFieldType.ProjectName, CustomFieldType.Text, 'Project name', true),
  createField(ProjectFieldType.BidDeadline, CustomFieldType.Date, 'Bid deadline'),
  createField(ProjectFieldType.RFIDeadline, CustomFieldType.Date, 'RFI deadline'),
  createField(ProjectFieldType.JobWalk, CustomFieldType.Date, 'Job walk'),
  createField(ProjectFieldType.Quotes, CustomFieldType.Monetary, 'Quotes'),
  createField(ProjectFieldType.Location, CustomFieldType.Text, 'Location'),
  createField(ProjectFieldType.Companies, CustomFieldType.Custom, 'Companies'),
]

const DEFAULT_CALENDAR_FIELDS: Field[] = [
  createField(ProjectFieldType.ProjectName, CustomFieldType.Text, 'Project name', true),
  createField(ProjectFieldType.BidDeadline, CustomFieldType.Date, 'Deadline', true),
  createField(ProjectFieldType.Companies, CustomFieldType.Custom, 'Companies'),
]

const DEFAULT_CONTACTS_FIELDS: Field[] = [
  createField(ContactFieldType.Name, CustomFieldType.Text, 'Name', true, 182),
  createField(ContactFieldType.Title, CustomFieldType.Text, 'Title', false, 250),
  createField(ContactFieldType.Email, CustomFieldType.Text, 'Email', false),
  createField(ContactFieldType.Phone, CustomFieldType.Text, 'Phone', false, 350),
  createField(ContactFieldType.Company, CustomFieldType.Custom, 'Company', false, 200),
  createField(ContactFieldType.OpenBidsCount, CustomFieldType.Number, '# of Open bids', false, 150),
]

const DEFAULT_ACCOUNTS_FIELDS: Field[] = [
  createField(CompanyFieldType.Name, CustomFieldType.Text, 'Name', true, 328),
  createField(CompanyFieldType.Website, CustomFieldType.Text, 'Website', false, 366),
  createField(CompanyFieldType.Address, CustomFieldType.Text, 'Address', false, 366),
  createField(CompanyFieldType.OpenBidsCount, CustomFieldType.Number, 'Open bids', false, 150),
]

const DEFAULT_QUOTE_FIELDS: Field[] = [
  createField('amount', CustomFieldType.Monetary, 'Amount', true, 328),
  createField('margin', CustomFieldType.Percent, 'Margin', true, 366),
]

export const DEFAULT_TASKS_FIELDS: Field[] = [
  createField(TasksFieldType.Name, CustomFieldType.Text, 'Task name', true, 328),
  createField(TasksFieldType.ProjectName, CustomFieldType.Text, 'Bid name', false, 200),
  createField(TasksFieldType.ReminderTime, CustomFieldType.Date, 'Reminder time', false, 200),
  createField(TasksFieldType.Assignee, CustomFieldType.UserMultiSelect, 'Assignee', false, 200),
  createField(TasksFieldType.Type, CustomFieldType.SingleSelect, 'Type', false, 200),
  createField(TasksFieldType.Notes, CustomFieldType.Text, 'Notes', false, 200),
  createField(
    TasksFieldType.ProjectEstimators,
    CustomFieldType.UserMultiSelect,
    'Project estimators',
    false,
    200,
  ),
]

export const DEFAULT_FIELDS_SETTINGS: {
  [key in ViewId]?: {
    fields: Field[]
  }
} = {
  [ViewId.ListViewId]: { fields: DEFAULT_LIST_FIELDS },
  [ViewId.BoardViewId]: { fields: DEFAULT_BOARD_FIELDS },
  [ViewId.CalendarViewId]: { fields: DEFAULT_CALENDAR_FIELDS },
  [ViewId.ContactsViewId]: { fields: DEFAULT_CONTACTS_FIELDS },
  [ViewId.AccountsViewId]: { fields: DEFAULT_ACCOUNTS_FIELDS },
  [ViewId.QuoteViewId]: { fields: DEFAULT_QUOTE_FIELDS },
  [ViewId.TasksViewId]: { fields: DEFAULT_TASKS_FIELDS },
}

export const LIST_FIELDS = [
  ...DEFAULT_LIST_FIELDS,
  ...DEFAULT_CONTACTS_FIELDS,
  ...DEFAULT_ACCOUNTS_FIELDS,
]
