import { NOTE_ID, USER_ID } from '@basisboard/basis-common/lib/api'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { LoadingIndicator } from '@basisboard/basis-ui/es/components/LoadingIndicator'
import { DetailText, H3, Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { fromEmpty, fromNullable, replaceMentionByName } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import moment from 'moment'
import uniq from 'ramda/src/uniq'
import * as React from 'react'
import { EmptyBox, Mention, PersonChip } from '../../../../components'
import { AppState, openConfirmActionModal } from '../../../App'
import { FieldsState } from '../../../Fields'
import { UsersContainer } from '../../../Users'
import { NotesContainer } from '../../container'
import { NoteEntityType } from '../../types'
import {
  NoteAction,
  NoteActionSeparator,
  NoteContent,
  NoteMessage,
  NotesBody,
  NotesHeader,
  NotesWrapper,
} from './styled'

interface Props {
  header?: string
  headerHeight?: number
  entity: NoteEntityType
  entityId: string
}

export const NotesSection: React.FC<Props> = ({ header, headerHeight, entity, entityId }) => {
  const [editMinHeight, setEditMinHeight] = React.useState(40)
  const [mentions, setMentions] = React.useState<USER_ID[]>([])
  const [message, setMessage] = React.useState('')
  const [editingId, setEditingId] = React.useState<NOTE_ID>()
  const [editingContent, setEditingContent] = React.useState<NOTE_ID>()
  const editRef = React.useRef<HTMLTextAreaElement>()
  const notesBodyRef = React.useRef<HTMLDivElement>()
  const appData = useContainer(AppState)
  const { allUsers, users } = useContainer(UsersContainer).state
  const fieldsData = useContainer(FieldsState)
  const [holdingShift, setHoldingShift] = React.useState(false)
  const notesData = useContainer(NotesContainer)
  const [loadingIds, setLoadingIds] = React.useState<Array<NOTE_ID | 'new'>>([])
  const [errorIds, setErrorIds] = React.useState<Array<NOTE_ID | 'new'>>([])

  React.useEffect(() => {
    if (!entity || !entityId) {
      return
    }

    notesData.loadNotes(entity, entityId)
  }, [entity, entityId])

  const { notes, loading } = notesData.state

  const { profile } = appData.state

  const handleSubmitNote = () => {
    if (!message.trim()) {
      return
    }
    setLoadingIds(loadingIds.concat('new'))
    setErrorIds(errorIds.filter(id => id !== 'new'))

    notesData
      .createNote({ message, mentions })
      .then(() => {
        setMentions([])
        setMessage('')
        setLoadingIds(loadingIds.filter(id => id !== 'new'))
      })
      .catch(() => {
        setLoadingIds(loadingIds.filter(id => id !== 'new'))
        setErrorIds(errorIds.concat('new'))
      })
  }

  const handlePatchNote = (noteId: NOTE_ID) => {
    const note = notes.find(n => n.id === noteId)

    if (editingContent === note.message) {
      setEditingId(null)
      return
    }

    setEditMinHeight(40)
    setLoadingIds(loadingIds.concat(noteId))
    setErrorIds(errorIds.filter(id => id !== noteId))
    notesData
      .patchNote({
        ...note,
        message: editingContent,
      })
      .then(() => {
        setLoadingIds(loadingIds.filter(id => id !== noteId))
        setEditingId(null)
      })
      .catch(() => {
        setEditingId(null)
        setLoadingIds(loadingIds.filter(id => id !== noteId))
        setErrorIds(errorIds.concat(noteId))
      })
  }

  React.useEffect(() => {
    if (!notesBodyRef.current) {
      return
    }
  }, [notes, notesBodyRef.current])

  React.useEffect(() => {
    if (!editingContent) {
      editRef.current = null
    }
  }, [editingContent])

  React.useLayoutEffect(() => {
    if (editRef.current) {
      setEditMinHeight(editRef.current.scrollHeight)
    }
  }, [editRef.current])

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <NotesWrapper data-testid="note-wrapper" role="complementary">
      <NotesHeader height={headerHeight}>
        {header && <H3 mb={spacing(1)}>{header}</H3>}
        <Mention
          disabled={loadingIds.includes('new')}
          users={[
            ...fieldsData.getTeams().map(t => ({
              firstName: '',
              lastName: t.label,
              id: t.id,
              color: t.entitySettings.color,
            })),
            ...users,
          ]}
          p={`${spacing(1)} ${spacing(2)}`}
          placeholder="Type your notes here..."
          value={message}
          onKeyDown={e => {
            if (e.nativeEvent.key === 'Meta' || e.nativeEvent.key === 'Control') {
              setHoldingShift(true)
            }

            if (e.nativeEvent.key === 'Enter' && holdingShift) {
              handleSubmitNote()
            }
          }}
          onKeyUp={e => {
            if ((e.nativeEvent.key === 'Meta' || e.nativeEvent.key === 'Control') && holdingShift) {
              setHoldingShift(false)
            }
          }}
          onBlur={() => setHoldingShift(false)}
          onChange={(e, _, __, mnts) => {
            setMessage(e.target.value)
            setMentions(
              uniq(
                mnts.reduce(
                  (acc, m) => [
                    ...acc,
                    ...fromNullable(fieldsData.getTeams().find(t => t.id === m.id)).fold(
                      () => [m.id],
                      team => team.initialValue as USER_ID[],
                    ),
                  ],
                  [],
                ),
              ),
            )
          }}
        />
        {errorIds.includes('new') && (
          <Text color={colors.error} fontSize={12} mt={spacing(1)}>
            Something went wrong, please try again later.
          </Text>
        )}
        <Div display="flex" justifyContent="flex-end" alignItems="center" mt={spacing(1)}>
          <Button.Primary
            width={80}
            onClick={handleSubmitNote}
            disabled={message.length === 0 || loadingIds.includes('new')}
          >
            {loadingIds.includes('new') ? <LoadingIndicator /> : 'Send'}
          </Button.Primary>
        </Div>
      </NotesHeader>
      <NotesBody ref={notesBodyRef}>
        {fromEmpty(notes).fold(
          () =>
            notes.map(note => {
              const createdByCurrentUser = note.author.id === profile.id
              const isEditing = Boolean(note.id) && editingId === note.id

              return (
                <Div key={note.id} mb={spacing(3)}>
                  <PersonChip
                    color={(allUsers.find(u => u.id === note.author.id) || {}).color}
                    name={note.author.name}
                    isCurrentUser={createdByCurrentUser}
                  />
                  <NoteContent data-testid="note-content">
                    {isEditing ? (
                      <Mention
                        disabled={loadingIds.includes(note.id)}
                        py={spacing(1)}
                        users={users}
                        autoFocus
                        borderless
                        value={editingContent}
                        ref={editRef}
                        minHeight={editMinHeight}
                        onChange={(e, _, __, mnts) => {
                          setEditingContent(e.target.value)
                          setEditMinHeight(e.target.scrollHeight)
                          notesData.updateNote(note.id, {
                            mentions: uniq(
                              mnts.reduce(
                                (acc, m) => [
                                  ...acc,
                                  ...fromNullable(
                                    fieldsData.getTeams().find(t => t.id === m.id),
                                  ).fold(
                                    () => [m.id],
                                    team => team.initialValue as USER_ID[],
                                  ),
                                ],
                                [],
                              ),
                            ),
                          })
                        }}
                      />
                    ) : (
                      <NoteMessage
                        preventClip
                        dangerouslySetInnerHTML={{
                          __html: replaceMentionByName(note.message),
                        }}
                      />
                    )}
                  </NoteContent>
                  {errorIds.includes(note.id) && (
                    <Text color={colors.error} fontSize={12} mb={spacing(1)}>
                      Something went wrong, please try again later.
                    </Text>
                  )}
                  <Div data-testid="note-actions" display="flex" alignItems="center">
                    <DetailText>{moment(note.createdAt).fromNow()}</DetailText>
                    {createdByCurrentUser && (
                      <>
                        <NoteActionSeparator />
                        {isEditing ? (
                          <>
                            <NoteAction
                              key="save"
                              onClick={() => handlePatchNote(note.id)}
                              disabled={loadingIds.includes(note.id)}
                            >
                              {loadingIds.includes(note.id) ? 'Saving...' : 'Save'}
                            </NoteAction>
                            <NoteActionSeparator />
                            <NoteAction
                              key="cancel"
                              onClick={() => {
                                setEditingId(null)
                              }}
                            >
                              Cancel
                            </NoteAction>
                          </>
                        ) : (
                          <>
                            <NoteAction
                              key="edit"
                              onClick={() => {
                                setEditingId(note.id)
                                setEditingContent(note.message)
                              }}
                            >
                              Edit
                            </NoteAction>
                            <NoteActionSeparator />
                            <NoteAction
                              key="delete"
                              onClick={() =>
                                openConfirmActionModal({
                                  prompt: 'Are you sure you want to delete note?',
                                  actionLabel: 'Delete note',
                                  action: () => notesData.deleteNote(note.id),
                                })
                              }
                            >
                              Delete
                            </NoteAction>
                          </>
                        )}
                      </>
                    )}
                  </Div>
                </Div>
              )
            }),
          () => (
            <EmptyBox
              text="This bid has no comments, so far.<br/>Start a discussion by creating the first one!"
              isHtml
              textStyle={{ textAlign: 'center' }}
            />
          ),
        )}
      </NotesBody>
    </NotesWrapper>
  )
}
