"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Input = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../styles");
const monads_1 = require("../../utils/monads");
const Icon_1 = require("../Icon");
const Typography_1 = require("../Typography");
const styled_1 = require("./styled");
__exportStar(require("./props"), exports);
const Input = function (_a) {
    var { error, space, formatMask, icon, label, ref, height = 'medium', type } = _a, props = __rest(_a, ["error", "space", "formatMask", "icon", "label", "ref", "height", "type"]);
    const handleChange = (e) => {
        var _a;
        if (e.target.value && formatMask) {
            e.target.value = formatMask(e.target.value);
        }
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, e);
    };
    return (React.createElement(styled_1.Label, Object.assign({}, space, { hidden: props.hidden, className: props.className }),
        label && (React.createElement(styled_1.LabelText, null,
            label,
            props.required && React.createElement(Typography_1.Span, { color: styles_1.colors.error }, " *"))),
        React.createElement(styled_1.Wrapper, null,
            React.createElement(styled_1.BaseInput, Object.assign({}, props, { height: height, placeholder: props.placeholder || label, onChange: handleChange, ref: ref, as: "input", className: error && 'error', hasIcon: Boolean(icon), type: type })),
            icon && (React.createElement(styled_1.IconWrapper, { height: height, "aria-hidden": "true" }, (0, monads_1.box)(Icon_1.Icon[icon]).fold(Container => (React.createElement(Container, { color: styles_1.colors.gray, width: 15, height: 16 })))))),
        error && (React.createElement(Typography_1.Paragraph, { mt: (0, styles_1.spacing)(1), color: styles_1.colors.error }, error))));
};
exports.Input = Input;
exports.Input.Text = exports.Input;
exports.Input.Number = function (props) {
    return React.createElement(exports.Input, Object.assign({}, props, { type: "number" }));
};
exports.Input.Percentage = function (props) {
    return React.createElement(exports.Input, Object.assign({}, props, { type: "number", min: 0, max: 100, icon: "Percentage" }));
};
exports.Input.Monetary = function (props) {
    return React.createElement(exports.Input, Object.assign({}, props, { type: "number", step: "0.01", icon: "DollarMark" }));
};
