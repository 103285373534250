import { Button } from '@basisboard/basis-ui/es/components/Button'
import { ButtonGroup } from '@basisboard/basis-ui/es/components/ButtonGroup'
import { Card } from '@basisboard/basis-ui/es/components/Card'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Label } from '@basisboard/basis-ui/es/components/Label'
import { SafeWrapper } from '@basisboard/basis-ui/es/components/SafeWrapper'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { borderRadiusMixin, colors, spacing } from '@basisboard/basis-ui/es/styles'
import { formatDate } from '@basisboard/basis-ui/es/utils'
import { Moment } from 'moment'
import * as React from 'react'
import styled from 'styled-components'
import { DatePicker, TextOverflow, TimePicker } from '../../../../../components'

interface Props {
  value: Moment
  label: string
  onChange: (date: Moment) => void
  onFormat?: (date: Moment | string | null) => string
}

const DateButton = styled.div`
  max-width: 100%;
  padding: 0 ${spacing(1)};
  height: 100%;
  max-height: 40px;
  text-align: left;
  z-index: 0;

  display: flex;
  align-items: center;

  ${borderRadiusMixin};
  border: 1px dotted transparent;

  & > button,
  & > .add-text {
    visibility: hidden;
  }

  &:hover {
    border-color: ${colors.accent};

    & > button,
    & > .add-text {
      visibility: visible;

      &:hover path {
        stroke: ${colors.accent};
      }
    }
  }
`

export const DateSelectType: React.FC<Props> = ({
  value,
  onChange,
  label,
  onFormat = formatDate,
}) => {
  const [currentVal, setCurrentVal] = React.useState(value)
  const [renderVal, setRenderVal] = React.useState(value)
  const [showBox, setShowBox] = React.useState(false)
  const buttonRef = React.useRef<HTMLDivElement>()

  const handleSubmit = React.useCallback(() => {
    onChange(currentVal)
    setRenderVal(currentVal)
  }, [currentVal, onChange, setRenderVal])

  return (
    <>
      <DateButton
        ref={buttonRef}
        onClick={e => {
          e.stopPropagation()
          if (onChange) {
            setShowBox(true)
          }
        }}
      >
        {Boolean(value) || Boolean(renderVal) ? (
          <TextOverflow maxWidthMargin={32} TextComponent={Text}>
            {onFormat(renderVal)}
          </TextOverflow>
        ) : (
          <Text className="add-text" pl={spacing(1)} color={colors.mediumGray}>
            Add {label?.toLowerCase()}
          </Text>
        )}
        <Button.Transparent
          ml={spacing(1)}
          disabled={!onChange}
          onClick={e => {
            e.stopPropagation()
            setShowBox(true)
          }}
          postIcon="PencilHollow"
        />
      </DateButton>

      <SafeWrapper
        target={buttonRef.current}
        onClose={() => setShowBox(false)}
        defaultPosition="bottom"
        align="left"
        show={showBox}
      >
        <Card padding={spacing(2)} width={300}>
          <Div mb={spacing(1)}>
            <Label label="Date" />
            <DatePicker value={currentVal} onChange={setCurrentVal} />
          </Div>
          <Label label="Time" />
          <TimePicker value={currentVal} onChange={setCurrentVal} />

          <ButtonGroup mt={spacing(2)}>
            <Button.Default ml="auto" onClick={() => setShowBox(false)}>
              Cancel
            </Button.Default>
            <Button.Primary
              onClick={() => {
                handleSubmit()
                setShowBox(false)
              }}
            >
              Save
            </Button.Primary>
          </ButtonGroup>
        </Card>
      </SafeWrapper>
    </>
  )
}
