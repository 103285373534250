import { UserRole } from '@basisboard/basis-common/lib/api'
import { box, retryLazyLoad } from '@basisboard/basis-ui/es/utils'
import keys from 'ramda/src/keys'
import pick from 'ramda/src/pick'
import { ScreenType } from '..'
import { history } from '../../config'
import { SettingsMenu } from '../../templates/SettingsScreen'

enum Screen {
  MyProfile = 'myProfile',
  ResetPassword = 'resetPassword',
  Notifications = 'notifications',
  EmailAccounts = 'emailAccounts',
  StagesSettings = 'stagesSettings',
  BoardSettings = 'boardSettings',
  BidCustomFields = 'bidCustomFields',
  CompanyCustomFields = 'companyCustomFields',
  ContactCustomFields = 'contactCustomFields',
  QuoteCustomFields = 'quoteCustomFields',
  ManageUsers = 'manageUsers',
  DeclineReasons = 'declineReasons',
  LostReasons = 'lostReasons',
  Rules = 'rules',
  Trades = 'trades',
  Platforms = 'platforms',
  Keywords = 'keywords',
  PlanDownloading = 'planDownloading',
}

export const settingsScreens = box<{ [key in Screen]: ScreenType & { label: string } }>({
  [Screen.MyProfile]: {
    label: 'My profile',
    path: '/settings/my-profile',
    push: () => history.push('/settings/my-profile'),
    component: retryLazyLoad(() => import('./pages/MyProfile')),
  },

  [Screen.ResetPassword]: {
    label: 'Password',
    path: '/settings/reset-password',
    push: () => history.push('/settings/reset-password'),
    component: retryLazyLoad(() => import('./pages/Password')),
  },

  [Screen.Notifications]: {
    label: 'Notifications',
    path: '/settings/notifications',
    push: () => history.push('/settings/notifications'),
    component: retryLazyLoad(() => import('./pages/Notifications')),
  },

  [Screen.EmailAccounts]: {
    label: 'Email accounts',
    path: '/settings/email-accounts',
    push: () => history.push('/settings/email-accounts'),
    component: retryLazyLoad(() => import('./pages/EmailAccounts')),
  },

  [Screen.StagesSettings]: {
    label: 'Stages settings',
    path: '/settings/stages-settings',
    push: () => history.push('/settings/stages-settings'),
    component: retryLazyLoad(() => import('./pages/StagesSettings')),
  },

  [Screen.Rules]: {
    label: 'Rules',
    path: '/settings/rules',
    push: () => history.push('/settings/rules'),
    component: retryLazyLoad(() => import('./pages/Rules')),
  },

  [Screen.Trades]: {
    label: 'Trades',
    path: '/settings/trades',
    push: () => history.push('/settings/trades'),
    component: retryLazyLoad(() => import('./pages/Trades')),
  },

  [Screen.BoardSettings]: {
    label: 'Board settings',
    path: '/settings/board-settings',
    push: () => history.push('/settings/board-settings'),
    component: retryLazyLoad(() => import('./pages/BoardSettings')),
  },

  [Screen.BidCustomFields]: {
    label: 'Bid details',
    path: '/settings/customize-fields/bid-details',
    push: () => history.push('/settings/customize-fields/bid-details'),
    requiresPermissions: [UserRole.Admin],
    component: retryLazyLoad(() => import('./pages/CustomFields')),
  },

  [Screen.CompanyCustomFields]: {
    label: 'Companies',
    path: '/settings/customize-fields/companies',
    push: () => history.push('/settings/customize-fields/companies'),
    requiresPermissions: [UserRole.Admin],
    component: retryLazyLoad(() => import('./pages/CustomFields')),
  },

  [Screen.ContactCustomFields]: {
    label: 'People',
    path: '/settings/customize-fields/people',
    push: () => history.push('/settings/customize-fields/people'),
    requiresPermissions: [UserRole.Admin],
    component: retryLazyLoad(() => import('./pages/CustomFields')),
  },

  [Screen.QuoteCustomFields]: {
    label: 'Quotes',
    path: '/settings/customize-fields/quotes',
    push: () => history.push('/settings/customize-fields/quotes'),
    requiresPermissions: [UserRole.Admin],
    component: retryLazyLoad(() => import('./pages/CustomFields')),
  },

  [Screen.Platforms]: {
    label: 'Platforms',
    path: '/settings/platforms',
    push: () => history.push('/settings/platforms'),
    requiresPermissions: [UserRole.Admin],
    component: retryLazyLoad(() => import('./pages/Platforms')),
  },

  [Screen.ManageUsers]: {
    label: 'Manage users',
    path: '/settings/manage-users',
    push: () => history.push('/settings/manage-users'),
    requiresPermissions: [UserRole.Admin],
    component: retryLazyLoad(() => import('./pages/ManageUsers')),
  },

  [Screen.DeclineReasons]: {
    label: 'Decline reasons',
    path: '/settings/decline-reasons',
    push: () => history.push('/settings/decline-reasons'),
    requiresPermissions: [UserRole.Admin],
    component: retryLazyLoad(() => import('./pages/DeclineReasons')),
  },

  [Screen.LostReasons]: {
    label: 'Lost reasons',
    path: '/settings/lost-reasons',
    push: () => history.push('/settings/lost-reasons'),
    requiresPermissions: [UserRole.Admin],
    component: retryLazyLoad(() => import('./pages/LostReasons')),
  },

  [Screen.Keywords]: {
    label: 'Keywords',
    path: '/settings/keywords',
    push: () => history.push('/settings/keywords'),
    requiresPermissions: [UserRole.Admin],
    component: retryLazyLoad(() => import('./pages/Keywords')),
  },
  [Screen.PlanDownloading]: {
    label: 'Plan downloading',
    path: '/settings/plan-downloading',
    push: () => history.push('/settings/plan-downloading'),
    requiresPermissions: [UserRole.Admin],
    component: retryLazyLoad(() => import('./pages/PlanDownloading')),
  },
}).fold(screens =>
  keys(screens).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        ...screens[key],
        requiresLogin: true,
      },
    }),
    {},
  ),
) as { [key in Screen]: ScreenType & { label: string } }

const pickMenuData = (screen: ScreenType & { label: string }) => pick(['label', 'path'], screen)

export const USER_MENU: SettingsMenu = {
  label: 'Personal',
  items: [
    pickMenuData(settingsScreens[Screen.MyProfile]),
    pickMenuData(settingsScreens[Screen.ResetPassword]),
    pickMenuData(settingsScreens[Screen.Notifications]),
    pickMenuData(settingsScreens[Screen.EmailAccounts]),
  ],
}

export const ADMIN_MENU: SettingsMenu = {
  label: 'Company',
  items: [
    pickMenuData(settingsScreens[Screen.Rules]),
    pickMenuData(settingsScreens[Screen.Keywords]),
    pickMenuData(settingsScreens[Screen.PlanDownloading]),
    pickMenuData(settingsScreens[Screen.StagesSettings]),
    {
      label: 'Board settings',
      path: '/settings/board-settings',
    },
    {
      label: 'Trades',
      path: '/settings/trades',
    },
    {
      label: 'Customize fields',
      items: [
        pickMenuData(settingsScreens[Screen.BidCustomFields]),
        pickMenuData(settingsScreens[Screen.CompanyCustomFields]),
        pickMenuData(settingsScreens[Screen.ContactCustomFields]),
        pickMenuData(settingsScreens[Screen.QuoteCustomFields]),
      ],
    },
    pickMenuData(settingsScreens[Screen.ManageUsers]),
    pickMenuData(settingsScreens[Screen.DeclineReasons]),
    pickMenuData(settingsScreens[Screen.LostReasons]),
    pickMenuData(settingsScreens[Screen.Platforms]),
  ],
}
