"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Shred = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Shred = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 401 401" },
    React.createElement("g", { fill: color },
        React.createElement("path", { d: "M334.5,131.549V0h-269v131.677c-19.736,2.681-35,19.632-35,40.091v70.652c0,21.485,16.831,39.106,38,40.388V401h52V283h18\n\t\tv118h53V283h18v118h53V283h18v118h52V282.808c21.169-1.282,38-18.903,38-40.388v-70.652\n\t\tC370.5,150.964,354.719,133.783,334.5,131.549z M176.5,386h-23V283h23V386z M247.5,386h-23V283h23V386z M319.5,15v116h-239V15\n\t\tH319.5z M105.5,386h-22V283h22V386z M317.5,386h-22V283h22V386z M355.5,242.42c0,14.044-11.426,25.58-25.471,25.58H70.971\n\t\tC56.926,268,45.5,256.465,45.5,242.42v-70.652c0-12.167,8.577-22.362,20-24.873V147h269v-0.302c11.919,2.12,21,12.551,21,25.07\n\t\tV242.42z" }),
        React.createElement("rect", { x: "110.5", y: "42", width: "89", height: "15" }),
        React.createElement("rect", { x: "110.5", y: "83", width: "179", height: "15" }))));
exports.Shred = Shred;
