"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BarChart = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const BarChart = ({ width = 16, height = 16, size, color = styles_1.colors.darkBlue, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M12.4 11H13.1C13.3 11 13.5 10.8 13.5 10.6V3.4C13.5 3.2 13.3 3 13.1 3H12.4C12.2 3 12 3.2 12 3.4V10.6C12 10.8 12.2 11 12.4 11ZM6.4 11H7.1C7.3 11 7.5 10.8 7.5 10.6V4.4C7.5 4.2 7.3 4 7.1 4H6.4C6.2 4 6 4.2 6 4.4V10.6C6 10.8 6.2 11 6.4 11ZM9.4 11H10.1C10.3 11 10.5 10.8 10.5 10.6V6.4C10.5 6.2 10.3 6 10.1 6H9.4C9.2 6 9 6.2 9 6.4V10.6C9 10.8 9.2 11 9.4 11ZM15.5 12.5H1.5V2.5C1.5 2.22375 1.27625 2 1 2H0.5C0.22375 2 0 2.22375 0 2.5V13C0 13.5522 0.447812 14 1 14H15.5C15.7762 14 16 13.7762 16 13.5V13C16 12.7238 15.7762 12.5 15.5 12.5ZM3.4 11H4.1C4.3 11 4.5 10.8 4.5 10.6V8.4C4.5 8.2 4.3 8 4.1 8H3.4C3.2 8 3 8.2 3 8.4V10.6C3 10.8 3.2 11 3.4 11Z", fill: color })));
exports.BarChart = BarChart;
