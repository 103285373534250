import {
  FeatureType,
  GetCustomerPdlStatsResponseBody,
  GetCustomerSettingsResponseBody,
  GetProfileResponse,
  PatchCustomerSettingsRequestBody,
  UserRole,
} from '@basisboard/basis-common/lib/api'
import { fromNullable, getInternalUse } from '@basisboard/basis-ui/es/utils'
import { clearContainers, Container, getContainer } from '@containrz/react-hook'
import * as Sentry from '@sentry/browser'
import axios from 'axios'
import * as Cookies from 'js-cookie'
import { screens } from '../../screens'
import { eventBus, EventBusType, initServices, shutdownIntercom } from '../../services'
import { shutDownHeap } from '../../services/heap'
import { shutDownSmartlook } from '../../services/smartlook'
import {
  getCustomerSettings,
  getPdlStats,
  getPlanCategories,
  getProfile,
  logout,
  patchCustomerSettings,
  patchProfile,
  setLastLogin,
  setNotifications,
} from './api'
import { AdminBarContainer } from './components/AdminBar/container'

export * from './api'

interface State {
  profile: GetProfileResponse
  adminProfile?: GetProfileResponse
  customerSettings: GetCustomerSettingsResponseBody
  loadingProfile: boolean
  showAdminBanner: boolean
  headerSpace: number
  showNotes: boolean
  planCategories?: string[]
  pdlStats?: GetCustomerPdlStatsResponseBody
}

const initialState: State = {
  profile: null,
  customerSettings: null,
  loadingProfile: true,
  showAdminBanner: false,
  headerSpace: 0,
  showNotes: true,
}

export class AppState extends Container<State> {
  state = initialState

  loadProfile = () => {
    if (Boolean(this.state.profile)) {
      return
    }

    this.setState({ loadingProfile: true })

    return getProfile()
      .then(async profile => {
        initServices(profile)

        const customerSettings = await this.loadCustomerSettings()

        const planCategories = (await this.loadPlanCategories()).planCategories

        const pdlStats = await this.loadPdlStats()

        if (profile.basisAdmin) {
          const { impersonations } = getContainer(AdminBarContainer).state

          const impersonatedUserProfile: GetProfileResponse | null = await fromNullable(
            impersonations[profile.customerWorkspace],
          ).fold(
            () => Promise.resolve(null),
            userId => {
              axios.defaults.headers = {
                ...axios.defaults.headers,
                'user-id': userId,
              }
              return getProfile()
            },
          )

          setLastLogin()
          this.setState({
            profile: impersonatedUserProfile || profile,
            ...(profile.basisAdmin ? { adminProfile: profile } : {}),
            loadingProfile: false,
            showAdminBanner: Boolean(impersonatedUserProfile),
            customerSettings,
            planCategories,
            pdlStats,
          })
        } else {
          this.setState({
            profile,
            loadingProfile: false,
            customerSettings,
            planCategories,
            pdlStats,
          })
        }

        if (this.state.profile) {
          eventBus.publish(EventBusType.SessionStarted, this.state.profile.email)
        }

        return this.state.profile
      })
      .catch(() => {
        this.setState({ loadingProfile: false })
      })
      .then(() => this.state.profile)
  }

  updateProfile = (profile: Partial<GetProfileResponse>) => {
    this.setState(s => ({ profile: { ...s.profile, ...profile } }))
    eventBus.publish(EventBusType.EditProfile, profile)
    return patchProfile(profile)
  }

  loadCustomerSettings = () => getCustomerSettings()

  loadPlanCategories = () => getPlanCategories()

  loadPdlStats = () => getPdlStats()

  logout = () =>
    logout()
      .then(() => {
        shutdownIntercom()
        shutDownHeap()
        shutDownSmartlook()

        this.setState(initialState)

        screens.login.push()

        Cookies.remove('XSRF-TOKEN')

        eventBus.publish(EventBusType.Logout)
      })
      .finally(clearContainers)

  isInternalUse = () => {
    const { profile } = this.state

    return fromNullable(profile).fold(
      () => false,
      () => getInternalUse(profile.customerWorkspace, profile.email) || profile.hidden,
    )
  }

  isReadOnlyUser = () => this.state.profile && this.state.profile.role === UserRole.ReadOnly

  updateNotifications = (notifications: unknown) => {
    this.setState(s => ({
      profile: {
        ...s.profile,
        settings: {
          ...s.profile.settings,
          NOTIFICATIONS: notifications,
        },
      },
    }))
    setNotifications(notifications)
  }

  setShowNotes = (showNotes: boolean, omitEvent = false) => {
    if (!omitEvent) {
      eventBus.publish(showNotes ? EventBusType.ShowNotes : EventBusType.HideNotes)
    }
    this.setState({ showNotes })
  }

  updateCustomerSettings = (data: PatchCustomerSettingsRequestBody) =>
    patchCustomerSettings(data).then(customerSettings => this.setState({ customerSettings }))
}

axios.interceptors.response.use(
  response => response,
  error => {
    const appData = appRepository()

    if (error.response?.status === 401) {
      if (appData.state.profile) {
        window.location.href = window.location.origin
        appData.state.profile && appData.logout()
      }
    } else {
      Sentry.captureException(error, {
        tags: {
          error_type: 'api',
        },
      })
    }

    return Promise.reject(error)
  },
)

export const appRepository = () => getContainer(AppState)

export const getEnabledFeatures = () =>
  appRepository().state.customerSettings?.settings?.enabledFeatures || []

export const hasFeatureOrIsInternal = (f: FeatureType) =>
  getEnabledFeatures().includes(f) || isInternalUse()

export const hasFeature = (f: FeatureType) => getEnabledFeatures().includes(f)

export const isInternalUse = appRepository().isInternalUse

export const whoami = () => appRepository().state.profile

export const isReadOnlyUser = () => whoami()?.role === UserRole.ReadOnly

export const isAdmin = () => whoami()?.role === UserRole.Admin

export const isBasisAdmin = () => appRepository().state.adminProfile?.basisAdmin

export const signOut = appRepository().logout
