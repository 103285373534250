import {
  PatchQuoteTradeRequestBody,
  QuoteTrade,
  TRADE_ID,
  USER_ID,
} from '@basisboard/basis-common/lib/api'
import { Container, getContainer } from '@containrz/react-hook'
import { eventBus, EventBusType } from '../../services/eventBus'
import { deleteTrade, getTrades, postTrade, updateTrade } from './api'

interface State {
  trades: QuoteTrade[]
}

export class TradesContainer extends Container<State> {
  constructor() {
    super()

    this.state = {
      trades: [],
    } as State

    this.loadTrades()
  }

  loadTrades = () =>
    getTrades().then(trades =>
      this.setState({
        trades: trades.sort((t1, t2) => (t1.name > t2.name ? 1 : t1.name < t2.name ? -1 : 0)),
      }),
    )

  createTrade = (data: { name: string; defaultEstimatorId?: USER_ID }) =>
    postTrade(data)
      .then(trade => {
        this.setState(s => ({ trades: [trade, ...s.trades] }))
        return trade
      })
      .finally(() => eventBus.publish(EventBusType.CreateQuoteTrade, data))

  deleteTrade = (tradeId: TRADE_ID) => {
    this.setState(s => ({ trades: s.trades.filter(t => t.id !== tradeId) }))
    return deleteTrade(tradeId).finally(() =>
      eventBus.publish(EventBusType.DeleteQuoteTrade, { tradeId }),
    )
  }

  patchTrade = (tradeId: TRADE_ID, data: PatchQuoteTradeRequestBody) => {
    this.setState(s => ({ trades: s.trades.map(t => (t.id === tradeId ? { ...t, ...data } : t)) }))

    return updateTrade(tradeId, data).finally(() =>
      eventBus.publish(EventBusType.EditQuoteTrade, { tradeId, data }),
    )
  }

  getTradeNameById = (tradeId: TRADE_ID) =>
    this.state.trades.find(t => t.id === tradeId)?.name || ''
}

export const tradesRepository = () => getContainer(TradesContainer)
