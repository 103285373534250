import { USER_ID } from '@basisboard/basis-common/lib/api'
import { box } from '@basisboard/basis-ui/es/utils'
import { Container } from '@containrz/react-hook'
import * as Cookies from 'js-cookie'
import keys from 'ramda/src/keys'

interface State {
  impersonations: Record<string, USER_ID>
}

const baseCookieName = 'basis-admin-impersonating-'

export const getImpersonatingCookieName = (customerWorkspace: string) =>
  `${baseCookieName}${customerWorkspace}`

const extractCustomerWorkspaceFromCookie = (cookieValue: string) =>
  cookieValue.replace(baseCookieName, '')

export class AdminBarContainer extends Container<State> {
  constructor() {
    super()

    this.state = {
      impersonations: document.cookie
        .split(/; */)
        .filter(c => c.startsWith(baseCookieName))
        .reduce(
          (acc, cookie) =>
            box(cookie.split('=')).fold(([key, value]) => ({
              ...acc,
              [extractCustomerWorkspaceFromCookie(key)]: value,
            })),
          {},
        ),
    }
  }

  impersonateUser = (userId: USER_ID, customerWorkspace: string) => {
    if (!Boolean(userId)) {
      Cookies.remove(getImpersonatingCookieName(customerWorkspace))
    } else {
      Cookies.set(getImpersonatingCookieName(customerWorkspace), userId)
    }

    this.setState(s => ({
      impersonations: userId
        ? {
            ...s.impersonations,
            [customerWorkspace]: userId,
          }
        : keys(s.impersonations).reduce(
            (acc, key) =>
              key !== customerWorkspace
                ? {
                    ...acc,
                    [key]: s.impersonations[key],
                  }
                : acc,
            {},
          ),
    }))

    window.location.href = window.location.href
  }
}
