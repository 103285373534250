import { useState } from 'react';

export const useHover = () => {
  const [hovering, setHovering] = useState(false);

  return {
    hovering,
    onMouseEnter: () => setHovering(true),
    onMouseLeave: () => setHovering(false),
  };
};
