import { EllipsisOverflow } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import { darken } from 'polished'
import styled from 'styled-components'
import { width, WidthProps } from 'styled-system'

export const Table = styled.table`
  table-layout: fixed;
  width: 100%;
`

export const Th = styled.th<WidthProps>`
  &:first-child {
    padding-left: ${spacing(2)};
  }

  font-size: 0.75rem;
  font-weight: 600;
  line-height: 32px;
  color: ${colors.gray};

  text-align: left;

  ${width};
`

export const ActionsWrapper = styled.div`
  margin-right: ${spacing(1)};
  display: flex;
  justify-content: flex-end;

  opacity: 0;
  ${transitionMixin};
`

export const BodyRow = styled.tr<{ onClick?: () => void }>`
  ${({ onClick }) => onClick && 'cursor: pointer'};

  padding: 0 ${spacing(2)};
  height: 56px;
  background: ${colors.lightGray3};
  overflow: hidden;
  ${transitionMixin};

  &:hover {
    background: ${darken(0.05, colors.lightGray3)};

    ${ActionsWrapper} {
      opacity: 1;
    }
  }

  &:not(:last-child) {
    border-bottom: ${spacing(1)} solid;
    border-color: ${colors.white};
  }
`

export const Td = styled.td`
  padding-right: ${spacing(2)};
  font-weight: 500;
  color: ${colors.darkBlue};
  font-size: 0.875rem;

  vertical-align: middle;

  ${EllipsisOverflow};

  &:first-child {
    padding-left: ${spacing(2)};
    font-weight: 600;
  }
`
