import {
  FilterIdenfier,
  FilterOperation,
  getAllowedOperationForCustomFieldType,
} from '@basisboard/basis-common/lib/api'
import {
  CustomFieldEntity,
  CustomFieldSetting,
  CustomFieldType,
} from '@basisboard/basis-common/lib/api/custom-fields'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Checkbox } from '@basisboard/basis-ui/es/components/Checkbox'
import { ComboBox } from '@basisboard/basis-ui/es/components/ComboBox'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Input } from '@basisboard/basis-ui/es/components/Input'
import { Row } from '@basisboard/basis-ui/es/components/Row'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { box, fromNullable, fuzzySearch, isUuid, readableName } from '@basisboard/basis-ui/es/utils'
import { getContainer, useContainer } from '@containrz/react-hook'
import moment from 'moment'
import * as React from 'react'
import { DatePicker, PersonChip, Tag } from '../../../../components'
import { companyRepository } from '../../../Companies'
import { FieldsState } from '../../../Fields'
import { StagesState } from '../../../Stages'
import { UsersContainer } from '../../../Users'
import {
  getCompanyFields,
  getContactFields,
  getEmailFields,
  getProjectFields,
  mapConditionEntityToLabel,
  mapCustomFieldToOptions,
  mapDefaultValueForType,
  mapOperationToOption,
  operationsWithoutValue,
  OPERATION_OPTIONS,
} from '../../constants'
import { FilterCondition } from '../../types'
import { ConditionEntity } from './types'

const includeNullsFields = [
  FilterIdenfier.EmailDistance.toString(),
  FilterIdenfier.EmailTravelTime.toString(),
  FilterIdenfier.EmailUnionType.toString(),
]

const getEntityFields = (e: ConditionEntity | CustomFieldEntity) =>
  ({
    [ConditionEntity.Project]: getProjectFields(),
    [ConditionEntity.Company]: getCompanyFields(),
    [ConditionEntity.Contact]: getContactFields(),
    [ConditionEntity.Email]: getEmailFields(),
  }[e] || [])

interface Props extends FilterCondition {
  onSetCondition: (key: keyof FilterCondition, value: any) => void
  onDelete: () => void
  enabledEntities?: (CustomFieldEntity | ConditionEntity)[]
  enabledFields?: { [key in ConditionEntity]?: Array<FilterIdenfier | string> }
  width?: number
  allowCustomFields?: boolean
}

export const ConditionEntry: React.FC<Props> = ({
  onSetCondition,
  onDelete,
  enabledEntities = [ConditionEntity.Project, ConditionEntity.Company],
  enabledFields,
  width = 704,
  allowCustomFields = false,
  ...c
}) => {
  const fieldsData = useContainer(FieldsState)
  const usersContainer = useContainer(UsersContainer)

  const userList = React.useMemo(
    () =>
      usersContainer.state.users.map(user => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      })),
    [usersContainer.state.users],
  )

  const maxOptionsToDisplay = 50

  const enabledEntitiesFields: Array<CustomFieldSetting & {
    section: ConditionEntity
  }> = enabledEntities
    .reduce(
      (acc, e) => [
        ...acc,
        ...getEntityFields(e).map(f => ({ ...f, section: e })),
        ...(allowCustomFields
          ? fieldsData.getCustomFieldsForEntity(e as any).map(mapCustomFieldToOptions)
          : []),
      ],
      [],
    )
    .filter(f => {
      if (enabledFields?.[f.entity]?.length > 0) {
        return enabledFields?.[f.entity]?.some(fi => fi.toString() === f.id) || isUuid(f.id)
      }
      return true
    })

  const entityFieldLabels = React.useMemo(
    () => enabledEntitiesFields.map(f => ({ label: f.label })),
    [enabledEntitiesFields],
  )

  React.useEffect(() => {
    if (enabledEntities.length === 1) {
      onSetCondition('entity', enabledEntities[0])
    }
  }, [enabledEntities])

  const selectedField =
    enabledEntitiesFields.find(f => f.id === c.fieldId) ||
    {
      [CustomFieldEntity.Project]: getProjectFields(),
      [CustomFieldEntity.Company]: getCompanyFields(),
      [CustomFieldEntity.Contact]: getContactFields(),
      [ConditionEntity.Email]: getEmailFields(),
    }[c.entity]?.find(f => f.id === c.fieldId?.toString())

  React.useEffect(() => {
    if (selectedField && !c.fieldValue && typeof c.fieldValue !== 'string') {
      onSetCondition('fieldValue', mapDefaultValueForType[selectedField.type] || '')
    }
  }, [selectedField, c.operation, c.fieldValue])

  const allowedOperations = selectedField
    ? getAllowedOperationForCustomFieldType(selectedField.type).filter(o => {
        if (c.fieldId === FilterIdenfier.EmailProcessedAt.toString()) {
          return ![
            FilterOperation.NextDays,
            FilterOperation.Next7Days,
            FilterOperation.Next30Days,
            FilterOperation.Next365Days,
          ].includes(o)
        }

        if (includeNullsFields.includes(c.fieldId)) {
          return o !== FilterOperation.Empty
        }

        if (
          c.fieldId === FilterIdenfier.ProjectKeywords.toString() ||
          c.fieldId === FilterIdenfier.ProjectUnreadNotes.toString()
        ) {
          return o === FilterOperation.Empty
        }

        return true
      })
    : []

  const selectedOperationOption = OPERATION_OPTIONS.find(
    op => Boolean(op.not) === Boolean(c.negation) && c.operation === op.operation,
  )

  const operationOptions = mapOperationToOption(allowedOperations)

  const renderInputForFieldType = React.useCallback(
    (selectedField: CustomFieldSetting, operation?: FilterOperation) => {
      if (!selectedField || operationsWithoutValue.includes(c.operation)) {
        return <Input placeholder="Value" disabled />
      }

      const type = selectedOperationOption?.type || selectedField.type

      switch (type) {
        case CustomFieldType.MultiSelect:
        case CustomFieldType.SingleSelect:
          return (
            <ComboBox
              addArrowIcon
              disabled={!c.fieldId || operation === 0 || operation === null}
              multiple={operation === FilterOperation.IsAnyOf}
              dropdownHeight={400}
              selectedOptions={
                Array.isArray(c.fieldValue)
                  ? c.fieldValue
                      .map(value =>
                        box(
                          (selectedField.options || []).find(
                            option => option.id === value || option.label === value,
                          ),
                        ).fold(
                          option =>
                            option && {
                              value: option.id,
                              label: option.label,
                              configs: { color: option.color },
                            },
                        ),
                      )
                      .filter(Boolean)
                  : c.fieldValue
                  ? [
                      {
                        value: c.fieldValue,
                        label: '',
                      },
                    ]
                  : []
              }
              renderOption={({ value, label, configs }) => {
                if (selectedField.isEntityLabel) {
                  return (
                    <Tag
                      pr={spacing(4)}
                      mr={spacing(1)}
                      small
                      maxWidth={120}
                      value={(selectedField.options || []).find(
                        opt => opt.label === label || opt.label === value,
                      )}
                    />
                  )
                }

                switch (c.fieldId.toString()) {
                  case FilterIdenfier.StageId.toString():
                    return fromNullable(
                      getContainer(StagesState).getStageForId(value || label),
                    ).fold(
                      () => null,
                      stage => {
                        const { name, color } = stage

                        return (
                          <Div display="flex" alignItems="center" pr={spacing(4)} height={24}>
                            <Div size={8} mr={spacing(1)} borderRadius="8px" background={color} />
                            <Text>{name}</Text>
                          </Div>
                        )
                      },
                    )

                  case FilterIdenfier.CompanyId.toString():
                    return box(
                      companyRepository().getCompanyNamesById([value || label])[0]?.name,
                    ).fold(name => (
                      <Div display="flex" alignItems="center" pr={spacing(4)} height={24}>
                        <Div
                          size={8}
                          mr={spacing(1)}
                          borderRadius="8px"
                          background={colors.accentActive}
                        />
                        <Text>{name}</Text>
                      </Div>
                    ))

                  case FilterIdenfier.EstimatorId.toString():
                    return box(
                      usersContainer.state.allUsers.find(u => {
                        return u.id === value || u.id === label
                      }),
                    ).fold(({ color, ...rest }) => {
                      return (
                        <Div display="flex" alignItems="center" pr={spacing(4)} height={24}>
                          <PersonChip color={color} name={readableName(rest)} />
                        </Div>
                      )
                    })

                  case FilterIdenfier.EmailCommunicationType.toString():
                  case FilterIdenfier.EmailPlatform.toString():
                    return (
                      <Div display="flex" alignItems="center" pr={spacing(4)} height={24}>
                        <Div
                          size={8}
                          mr={spacing(1)}
                          borderRadius="8px"
                          background={configs?.color || colors.accentActive}
                        />
                        <Text>{label}</Text>
                      </Div>
                    )

                  default:
                    return <Text>{label || value}</Text>
                }
              }}
              options={
                selectedField.options
                  .filter(f => f.label)
                  .splice(0, maxOptionsToDisplay)
                  .map(f => ({
                    label: f.label,
                    value: f.id || f.label,
                    configs: { color: f.color },
                  })) || []
              }
              onSelectOption={options => {
                const optionsIds = options.map(({ value, label }) => value || label)

                onSetCondition('fieldValue', optionsIds.length > 0 ? optionsIds : undefined)
              }}
              onFilter={(term, options) => {
                if (!term) {
                  return options
                }

                const validOptions =
                  c.fieldId.toString() === FilterIdenfier.EstimatorId.toString()
                    ? userList.map(u => ({ ...u, label: u.label })).filter(Boolean)
                    : c.fieldId.toString() === FilterIdenfier.StageId.toString()
                    ? selectedField.options.map(({ value, label }) =>
                        fromNullable(getContainer(StagesState).getStageForId(value || label)).fold(
                          () => null,
                          stage => ({
                            ...stage,
                            label: stage.name,
                            value: stage.id,
                          }),
                        ),
                      )
                    : selectedField.options

                const validEntities = fuzzySearch(
                  validOptions.filter(option => option.label).map(option => option.label),
                  term,
                  {
                    options: { keys: ['label'] },
                  },
                )

                return Array.isArray(validEntities)
                  ? validOptions.filter(option =>
                      validEntities.slice(0, maxOptionsToDisplay).some(e => e === option.label),
                    )
                  : validOptions
              }}
              placeholder={
                selectedField.options.length > maxOptionsToDisplay ? 'Type to filter...' : null
              }
            />
          )

        case CustomFieldType.Boolean:
          return (
            <ComboBox
              addArrowIcon
              disabled={!c.fieldId || operation === 0 || operation === null}
              selectedOptions={
                selectedField ? [{ value: selectedField.id, label: selectedField.label }] : []
              }
              renderOption={({ label }) => <Text>{label}</Text>}
              options={[
                { value: 'true', label: 'True' },
                { value: 'false', label: 'False' },
              ]}
              onSelectOption={option => {
                onSetCondition('fieldValue', Boolean(option[0].value))
              }}
            />
          )

        case CustomFieldType.UserMultiSelect:
          return (
            <ComboBox
              addArrowIcon
              disabled={!c.fieldId || operation === 0 || operation === null}
              multiple={operation === FilterOperation.IsAnyOf}
              dropdownHeight={400}
              selectedOptions={
                c?.fieldValue?.length > 0
                  ? Array.isArray(c.fieldValue)
                    ? c.fieldValue.map(uid => ({
                        value: uid,
                        label: readableName(usersContainer.state.allUsers.find(u => u.id === uid)),
                      }))
                    : [
                        {
                          value: c.fieldValue,
                          label: userList.find(u => u.value === c.fieldValue).label,
                        },
                      ]
                  : []
              }
              renderOption={({ value, label }) => (
                <Div pr={spacing(4)} height={24}>
                  <PersonChip
                    {...fromNullable(usersContainer.state.allUsers.find(u => u.id === value)).fold(
                      () => ({ name: label, color: undefined }),
                      user => ({ name: readableName(user), color: user.color }),
                    )}
                  />
                </Div>
              )}
              options={usersContainer.state.allUsers.map(u => ({
                value: u.id,
                label: `${u.firstName} ${u.lastName}`,
              }))}
              onSelectOption={options => {
                const optionsIds = options.map(({ value }) => value)

                onSetCondition('fieldValue', optionsIds.length > 0 ? optionsIds : undefined)
              }}
              onFilter={(term, options) => {
                if (!term) {
                  return options
                }

                const validEntities = fuzzySearch(userList, term, {
                  options: { keys: ['label'] },
                })

                return Array.isArray(validEntities)
                  ? options.filter(option => validEntities.some(e => e === option.label))
                  : options
              }}
            />
          )

        case CustomFieldType.CompanyMultiSelect:
          return (
            <ComboBox
              addArrowIcon
              disabled={!c.fieldId || operation === 0 || operation === null}
              multiple={operation === FilterOperation.IsAnyOf}
              renderOption={({ label }) => (
                <Div display="flex" alignItems="center" pr={spacing(4)} height={24}>
                  <Div
                    size={8}
                    mr={spacing(1)}
                    borderRadius="8px"
                    background={colors.accentActive}
                  />
                  <Text>{label}</Text>
                </Div>
              )}
              options={selectedField.options || []}
              onSelectOption={options => {
                const optionsIds = options.map(({ value }) => value)

                onSetCondition('fieldValue', optionsIds.length > 0 ? optionsIds : undefined)
              }}
              onFilter={(term, options) => {
                if (!term) {
                  return options
                }

                const validEntities = fuzzySearch(selectedField.options, term, {
                  options: { keys: ['label'] },
                })

                return Array.isArray(validEntities)
                  ? options.filter(option => validEntities.some(e => e === option.label))
                  : options
              }}
            />
          )

        case CustomFieldType.Date:
          return (
            <DatePicker
              alignRight
              value={c.fieldValue ? moment(c.fieldValue) : null}
              onChange={val => onSetCondition('fieldValue', val.format('YYYY-MM-DD'))}
            />
          )

        case CustomFieldType.Monetary:
          return (
            <Input
              type="number"
              value={(+c.fieldValue ?? 0) / 100}
              disabled={!c.fieldId}
              onChange={e => onSetCondition('fieldValue', +e.currentTarget.value * 100)}
            />
          )

        case CustomFieldType.Percent:
          return (
            <Input
              type="number"
              value={+c.fieldValue}
              disabled={!c.fieldId}
              onChange={e => onSetCondition('fieldValue', +e.currentTarget.value)}
            />
          )

        case CustomFieldType.Number:
          return (
            <Input
              type="number"
              value={c.fieldValue}
              disabled={!c.fieldId}
              onChange={e => onSetCondition('fieldValue', e.currentTarget.value)}
            />
          )

        default:
          return (
            <Input
              value={c.fieldValue}
              disabled={!c.fieldId}
              onChange={e => onSetCondition('fieldValue', e.currentTarget.value)}
            />
          )
      }
    },
    [c, onSetCondition, usersContainer.state.users, selectedOperationOption],
  )

  return (
    <Div mb={spacing(1)} key={c.id} width={width}>
      <Div display="flex" alignItems="center" justifyContent="space-between">
        <Div data-testid="filter-option" width="33%">
          <ComboBox
            addArrowIcon
            options={enabledEntitiesFields.map(f => ({
              value: f.id,
              label: f.label,
              section: f.section,
            }))}
            renderOption={option => <Text capitalize>{option.label}</Text>}
            onSelectOption={option => {
              onSetCondition('entity', option?.[0].section)
              onSetCondition('operation', null)
              onSetCondition('fieldId', option?.[0].value)
            }}
            selectedOptions={
              selectedField
                ? [
                    {
                      value: selectedField.id,
                      label: selectedField.label,
                      section: mapConditionEntityToLabel[c.entity] || c.entity,
                    },
                  ]
                : []
            }
            onFilter={(term, options) => {
              if (!term) {
                return options
              }

              const validEntities = fuzzySearch(entityFieldLabels, term, {
                options: { keys: ['label'] },
              })

              return Array.isArray(validEntities)
                ? options.filter(option => validEntities.some(e => e === option.label))
                : options
            }}
            dropdownWidth={250}
            dropdownHeight={400}
          />
        </Div>
        <Div data-testid="filter-condition" width="24%">
          <ComboBox
            disabled={allowedOperations.length === 0}
            hideDisabledText
            addArrowIcon
            selectedOptions={
              selectedOperationOption?.id
                ? [{ value: selectedOperationOption?.id, label: selectedOperationOption?.name }]
                : []
            }
            renderOption={({ label }) => <Text>{label}</Text>}
            options={operationOptions.map(o => ({
              value: o.id,
              label: o.name,
            }))}
            dropdownWidth={220}
            dropdownHeight={300}
            onSelectOption={option => {
              const optionConfigs = OPERATION_OPTIONS.find(op => op.id === option[0]?.value)

              if (!optionConfigs) {
                return
              }

              const { operation, not } = optionConfigs

              onSetCondition('operation', operation)
              onSetCondition('negation', not)
              onSetCondition('fieldValue', mapDefaultValueForType[selectedField.type])
            }}
            onFilter={(term, options) => {
              if (!term) {
                return options
              }

              const validEntities = fuzzySearch(operationOptions, term, {
                options: { keys: ['name'] },
              })

              return Array.isArray(validEntities)
                ? options.filter(option => validEntities.some(e => e === option.label))
                : options
            }}
          />
        </Div>

        <Div data-testid="filter-condition-text" width="33%" display="flex" alignItems="center">
          <Div flexGrow="1" width="100%">
            {renderInputForFieldType(selectedField, c.operation)}
          </Div>
          {{
            [FilterIdenfier.EmailTravelTime.toString()]: (
              <Text preventClip ml={spacing(1)}>
                Mins
              </Text>
            ),
            [FilterIdenfier.EmailDistance.toString()]: (
              <Text preventClip ml={spacing(1)}>
                Miles
              </Text>
            ),
          }[c.fieldId] || null}
        </Div>

        <Button.Transparent postIcon="Delete" onClick={onDelete} />
      </Div>
      {includeNullsFields.includes(c.fieldId) && (
        <Row justifyContent="flex-end" mt={spacing(1.5)}>
          <Div ml="auto">
            <Checkbox
              label={`Include ${
                c.fieldId === FilterIdenfier.EmailUnionType.toString()
                  ? '‘unknown’'
                  : 'blank locations'
              }`}
              onChange={e => onSetCondition('includeNulls', e.currentTarget.checked)}
              value={c.includeNulls}
            />
          </Div>
        </Row>
      )}
    </Div>
  )
}
