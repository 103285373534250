"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Bell = void 0;
const React = __importStar(require("react"));
const Svg_1 = require("./Svg");
const Bell = ({ width = 24, height = 24, size, color = '#B3BCCA' }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 24 24" },
    React.createElement("path", { d: "M12.0001 22C13.3797 22 14.4989 20.8809 14.4989 19.5H9.50123C9.50123 20.8809 10.6204 22 12.0001 22ZM20.4137 16.152C19.659 15.341 18.2469 14.1211 18.2469 10.125C18.2469 7.08984 16.1188 4.66016 13.2493 4.06406V3.25C13.2493 2.55977 12.6899 2 12.0001 2C11.3102 2 10.7508 2.55977 10.7508 3.25V4.06406C7.8813 4.66016 5.75318 7.08984 5.75318 10.125C5.75318 14.1211 4.34107 15.341 3.58638 16.152C3.35201 16.4039 3.2481 16.7051 3.25003 17C3.25435 17.6406 3.75708 18.25 4.50396 18.25H19.4961C20.243 18.25 20.7461 17.6406 20.7501 17C20.752 16.7051 20.6481 16.4035 20.4137 16.152Z", fill: color })));
exports.Bell = Bell;
