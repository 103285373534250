import { SelectOption } from '@basisboard/basis-common/lib/api/custom-fields'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Tooltip } from '@basisboard/basis-ui/es/components/Tooltip'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { borderRadiusMixin, colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { background, BackgroundProps, space, SpaceProps } from 'styled-system'

interface Company {
  name?: string
  id?: string
  tag?: SelectOption
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

interface Props extends SpaceProps {
  companies: Company | Company[]
  chipStyle?: React.CSSProperties
  labelStyle?: React.CSSProperties
  onClose?: () => void
}

const BaseChip = css`
  height: 24px;
  ${borderRadiusMixin};
  background: #e6f7ff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
  align-self: flex-start;

  ${transitionMixin};

  position: relative;
  overflow: hidden;

  ${space};

  &:hover {
    p {
      color: ${colors.white};
      ${transitionMixin};
    }
    background-color: ${colors.teal};
  }
`

const Chip = styled.button.attrs({ type: 'button' })<{ mb?: boolean }>`
  ${BaseChip};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  padding: 0 ${spacing(1.5)};
  margin-right: ${spacing(0.5)};
  display: inline-flex;
  align-items: center;
  height: 24px;

  ${({ mb }) =>
    mb &&
    css`
      &:not(:last-child) {
        margin-bottom: ${spacing(1)};
      }
    `};
`

const ShowMoreChip = styled.div`
  ${BaseChip};

  padding: 0 ${spacing(0.5)};
  text-align: center;
  cursor: pointer;
  ${transitionMixin};
`

const Tag = styled.div<BackgroundProps>`
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  ${background};
`

export const CompanyChip: React.FC<Props> = ({
  companies,
  chipStyle,
  labelStyle,
  onClose,
  ...rest
}) => {
  const isArray = Array.isArray(companies)

  const wrapper = (name, children) =>
    name === 'Unidentified company' ? (
      <Tooltip
        key={name}
        variant="dark"
        position="top"
        small
        content={
          <Text white textAlign="center">
            We were unable to properly scrape this company.
            <br />
            Please update the project to display the correct
            <br />
            company record by switching company entry.
          </Text>
        }
      >
        {children}
      </Tooltip>
    ) : (
      children
    )

  const renderChip = ({ name, tag, onClick }: Company, mb = false, index = 0) =>
    wrapper(
      name,
      <Chip
        {...rest}
        key={`${name}-${tag || 'no-tag'}-${index}`}
        mb={mb}
        as={(onClick ? 'button' : 'div') as never}
        onClick={
          onClick
            ? (e: React.MouseEvent<HTMLButtonElement>) => {
                onClick(e)
              }
            : undefined
        }
        disabled={!Boolean(onClick)}
        style={chipStyle}
      >
        {tag && tag.label !== 'No tag' && (
          <Tooltip variant="dark" position="top" small content={<Text white>{tag.label}</Text>}>
            <Tag background={tag.color} data-testid={`tag-${tag.label}`} />
          </Tooltip>
        )}
        <Text color={colors.teal} style={labelStyle}>
          {name}
        </Text>

        {onClose && (
          <Button.Transparent
            postIcon="Close"
            ml={spacing(2)}
            size={16}
            onClick={e => {
              onClose()
              e.stopPropagation()
            }}
          />
        )}
      </Chip>,
    )

  if (isArray && (companies as Company[]).length === 0) {
    return null
  }

  return (
    <Div display="flex" maxWidth="100%" height={24}>
      {renderChip(
        isArray && (companies as Company[]).length > 0 ? companies[0] : (companies as Company),
      )}
      {isArray && (companies as Company[]).length > 1 && (
        <Div>
          <Tooltip
            wrapProps={{ height: 24 }}
            variant="light"
            position="top"
            content={
              <Div display="flex" flexDirection="column" justifyContent="center">
                {(companies as Company[])
                  .slice(1)
                  .map((company, index) => renderChip(company, true, index))}
              </Div>
            }
            hideDelay={200}
          >
            <ShowMoreChip
              data-testid="show-more"
              onClick={e => e.stopPropagation()}
              style={chipStyle}
            >
              <Text color={colors.teal} style={labelStyle}>
                +{(companies as Company[]).length - 1}
              </Text>
            </ShowMoreChip>
          </Tooltip>
        </Div>
      )}
    </Div>
  )
}
