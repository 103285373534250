import { copyTextToClipboard } from '@basisboard/basis-ui/es/utils'
import { showErrorToast, showSuccessToast } from '../../containers/Toast'
import { eventBus, EventBusType } from '../../services'

eventBus.register(EventBusType.CreateTaskSuccess, () =>
  showSuccessToast({ message: 'Your task was successfully created.' }),
)

eventBus.register(EventBusType.CreateTaskError, (err: Error) =>
  showErrorToast({
    message: 'Something went wrong while creating your task',
    actions: [
      { label: 'Copy error', onAction: () => copyTextToClipboard(JSON.stringify(err.message)) },
    ],
  }),
)
