import {
  CustomFieldMultiSelectSetting,
  CustomFieldSetting,
  CustomFieldSingleSelectSetting,
  CustomFieldTeamSelectSetting,
  CustomFieldType,
  CustomFieldValues,
} from '@basisboard/basis-common/lib/api/custom-fields'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { DEFAULT_BORDER_RADIUS, spacing } from '@basisboard/basis-ui/es/styles'
import {
  capitalize,
  formatDate,
  formatExcelDate,
  formatQuote,
  readableName,
} from '@basisboard/basis-ui/es/utils'
import { getContainer } from '@containrz/react-hook'
import { darken } from 'polished'
import { Optional } from 'utility-types'
import { companyRepository } from '../Companies/container'
import { UsersContainer } from '../Users'
import { FieldsState } from './container'

export const renderCustomFieldsMultiSelect = (
  field: Pick<
    CustomFieldMultiSelectSetting | CustomFieldSingleSelectSetting | CustomFieldTeamSelectSetting,
    'options' | 'type'
  >,
  { name, id }: { name: string; id: string },
  selected = false,
) => {
  const color = field.options.find(opt => opt.label === id).color

  return (
    <Div
      height={24}
      background={color}
      borderRadius={DEFAULT_BORDER_RADIUS}
      pl={spacing(1)}
      pr={spacing(selected ? 3 : 1)}
      mr={spacing(selected ? 1 : 0)}
    >
      <Text lineHeight="24px" color={darken(0.5, color)}>
        {name}
      </Text>
    </Div>
  )
}

export const mapCustomFieldToString = (
  customField: Optional<Pick<CustomFieldSetting, 'id' | 'type' | 'isEntityLabel' | 'options'>>,
  value: CustomFieldValues,
  splitDateAndTime = true,
): { [key: string]: CustomFieldValues } => {
  const setValue = v => ({ [customField.id]: v })

  if (!value) {
    return setValue('')
  }

  const { allUsers } = getContainer(UsersContainer).state
  const teams = getContainer(FieldsState).getTeams()

  switch (customField.type) {
    case CustomFieldType.Boolean:
      return setValue(capitalize(Boolean(value).toString()))
    case CustomFieldType.Date:
      return splitDateAndTime
        ? formatExcelDate(value as string, customField.id)
        : setValue(formatDate(value as string, true))
    case CustomFieldType.SingleSelect:
      return setValue(value)
    case CustomFieldType.MultiSelect:
      return setValue(Array.isArray(value) ? value.join(', ') : value)
    case CustomFieldType.Percent:
      return setValue(`${value}%`)
    case CustomFieldType.Monetary:
      return setValue(formatQuote(+value))
    case CustomFieldType.UserMultiSelect:
      return setValue(
        ((Array.isArray(value) ? (value as string[]) : [value]) || []).map(userId =>
          readableName(allUsers.find(user => user.id === userId)),
        ),
      )
    case CustomFieldType.CompanyMultiSelect:
      return setValue(
        companyRepository()
          .getCompanyNamesById((value as string[]) || [])
          .filter(c => Boolean(c))
          .map(company => company.name),
      )
    case CustomFieldType.TeamSelect:
      return setValue(
        ((Array.isArray(value) ? value : ([value] as string[])) || []).map(
          v => teams.find(t => t.id === v).label,
        ),
      )
    default:
      return setValue(value)
  }
}
