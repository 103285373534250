"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Export = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Export = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { 
        // d="M14.4928 6.18182L11.1594 9.32962C10.808 9.66145 10.2224 9.41543 10.2224 8.92573V7.25992C6.87574 7.28237 5.46391 8.07272 6.40815 11.2264C6.51192 11.573 6.11079 11.8414 5.82931 11.6276C4.92729 10.9424 4.11127 9.63182 4.11127 8.30892C4.11127 4.97705 6.83347 4.31587 10.2224 4.29707V2.63006C10.2224 2.13992 10.8084 1.89469 11.1594 2.22617L14.4928 5.37404C14.7247 5.59309 14.7249 5.96258 14.4928 6.18182ZM10.2224 10.8503V12.4446H2.81498V5.03719H3.99359C4.06917 5.03714 4.14147 5.00627 4.19377 4.9517C4.53991 4.59133 4.94 4.3061 5.37465 4.07957C5.63229 3.94529 5.53683 3.55571 5.2463 3.55571H2.44461C1.83095 3.55571 1.3335 4.05316 1.3335 4.66682V12.815C1.3335 13.4286 1.83095 13.9261 2.44461 13.9261H10.5928C11.2064 13.9261 11.7039 13.4286 11.7039 12.815V10.7593C11.7039 10.5674 11.5141 10.4337 11.3332 10.4976C11.0796 10.5873 10.8081 10.6142 10.5418 10.5758C10.3735 10.5516 10.2224 10.6803 10.2224 10.8503Z"
        d: "M8.83333 4.87516V1.3335H3.625C3.27865 1.3335 3 1.61214 3 1.9585V14.0418C3 14.3882 3.27865 14.6668 3.625 14.6668H12.375C12.7214 14.6668 13 14.3882 13 14.0418V5.50016H9.45833C9.11458 5.50016 8.83333 5.21891 8.83333 4.87516ZM10.8242 10.3793L8.31328 12.8715C8.1401 13.0437 7.86042 13.0437 7.68724 12.8715L5.1763 10.3793C4.91198 10.1171 5.0974 9.66683 5.46927 9.66683H7.16667V7.5835C7.16667 7.35329 7.35313 7.16683 7.58333 7.16683H8.41667C8.64688 7.16683 8.83333 7.35329 8.83333 7.5835V9.66683H10.5307C10.9026 9.66683 11.088 10.1171 10.8242 10.3793ZM12.8177 4.06787L10.2682 1.51579C10.151 1.3986 9.99219 1.3335 9.82552 1.3335H9.66667V4.66683H13V4.50798C13 4.34391 12.9349 4.18506 12.8177 4.06787Z", fill: color })));
exports.Export = Export;
