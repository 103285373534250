import { FilterIdenfier } from '@basisboard/basis-common/lib/api'
import { ConditionEntity } from '../../components/ConditionEntry/types'
import { getCompanyFields, getProjectFields } from '../../constants'

export const enabledFields = {
  [ConditionEntity.Project]: getProjectFields()
    .filter(
      f =>
        ![
          FilterIdenfier.EmailProjectName.toString(),
          FilterIdenfier.EmailUnionType.toString(),
          FilterIdenfier.EmailCompanyName.toString(),
          FilterIdenfier.EmailProjectLocation.toString(),
          FilterIdenfier.EmailDistance.toString(),
          FilterIdenfier.EmailTravelTime.toString(),
          FilterIdenfier.EmailProjectAddressLine.toString(),
          FilterIdenfier.EmailProjectCity.toString(),
          FilterIdenfier.EmailProjectPostalCode.toString(),
          FilterIdenfier.EmailProjectRegion.toString(),
        ].includes(f.id),
    )
    .map(f => f.id),
  [ConditionEntity.Company]: getCompanyFields()
    .filter(f => f.id !== FilterIdenfier.EmailCompanyName.toString())
    .map(f => f.id),
}
