import { GetCustomerSettingsResponseBody } from '@basisboard/basis-common/lib/api'
import { BannerCtaAction, BannerType } from '@basisboard/basis-common/lib/enums'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Icon, IconType } from '@basisboard/basis-ui/es/components/Icon'
import { Row } from '@basisboard/basis-ui/es/components/Row'
import { Subtitle, Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { box } from '@basisboard/basis-ui/es/utils'
import { rem } from 'polished'
import * as React from 'react'
import { openIntercom } from '../../../../services'
import { Banner } from '../styled'

type Size = 'small' | 'medium' | 'large'

const mapTypeToIcon: Record<BannerType, IconType> = {
  [BannerType.Danger]: 'Block',
  [BannerType.Warning]: 'Exclamation',
  [BannerType.Info]: 'Info',
}

const colorForToastTypeMap: Record<BannerType, string> = {
  [BannerType.Danger]: colors.error,
  [BannerType.Warning]: colors.warning,
  [BannerType.Info]: colors.accent,
}

const mapSizeToTitleSize: Record<Size, string> = {
  small: rem(14),
  medium: rem(16),
  large: rem(20),
}

const mapSizeToSubtitleSize: Record<Size, string> = {
  small: rem(12),
  medium: rem(14),
  large: rem(14),
}

export const OperationBanner: React.FC<GetCustomerSettingsResponseBody['banners'][number] & {
  size?: Size
}> = ({ body, title, type, ctaText, ctaUrl, ctaAction, size = 'large' }) => {
  const buttonProps =
    ctaAction === BannerCtaAction.OpenIntercom
      ? {
          onClick: openIntercom,
        }
      : {
          href: ctaUrl,
        }

  return (
    <Banner borderColor={colorForToastTypeMap[type]}>
      <Div>
        {box(Icon[mapTypeToIcon[type]]).fold(Ic => (
          <Ic size={36} color={colorForToastTypeMap[type]} />
        ))}
      </Div>

      <Div flex={1} ml={spacing(2)}>
        <Subtitle preventClip fontSize={mapSizeToTitleSize[size]}>
          {title}
        </Subtitle>

        <Text preventClip fontSize={mapSizeToSubtitleSize[size]}>
          {body}
        </Text>
      </Div>

      <Row flexDirection="column" ml="auto">
        <Button.Primary {...buttonProps}>{ctaText}</Button.Primary>
      </Row>
    </Banner>
  )
}
