"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Close = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Close = ({ width = 12, height = 12, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: size || width, height: size || height, viewBox: "0 0 12 12" },
    React.createElement("path", { d: "M7.30312 6L9.25762 4.04551C9.49746 3.80566 9.49746 3.4168 9.25762 3.17676L8.82324 2.74238C8.5834 2.50254 8.19453 2.50254 7.95449 2.74238L6 4.69688L4.04551 2.74238C3.80566 2.50254 3.4168 2.50254 3.17676 2.74238L2.74238 3.17676C2.50254 3.4166 2.50254 3.80547 2.74238 4.04551L4.69688 6L2.74238 7.95449C2.50254 8.19434 2.50254 8.5832 2.74238 8.82324L3.17676 9.25762C3.4166 9.49746 3.80566 9.49746 4.04551 9.25762L6 7.30312L7.95449 9.25762C8.19434 9.49746 8.5834 9.49746 8.82324 9.25762L9.25762 8.82324C9.49746 8.5834 9.49746 8.19453 9.25762 7.95449L7.30312 6Z", fill: color })));
exports.Close = Close;
