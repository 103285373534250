import { Div } from '@basisboard/basis-ui/es/components/Div'
import * as React from 'react'
import { useEscapeKey } from '../../hooks'

interface Props {
  onClick: () => void
  onMouseEnter?: (e: React.MouseEvent) => void
  zIndex?: number
}

export const Backdrop: React.FC<Props> = ({ onClick, zIndex, children, onMouseEnter }) => {
  useEscapeKey(onClick)
  return (
    <>
      <Div
        onMouseEnter={onMouseEnter}
        onClick={e => {
          onClick()
          e.stopPropagation()
        }}
        position="fixed"
        left={0}
        top={0}
        right={0}
        bottom={0}
        zIndex={typeof zIndex === 'number' ? zIndex : -1}
      />
      {children}
    </>
  )
}
