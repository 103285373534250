import { ChangeState } from '@basisboard/basis-common/lib/api'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { spacing } from '@basisboard/basis-ui/es/styles'
import { RadioGroup } from '../../../../components'

enum Status {
  All = 'All',
  New = 'New',
  Update = 'Update',
}

const FILTER_FOR_STATUS = {
  [Status.All]: { changeState: null },
  [Status.New]: { changeState: ChangeState.New },
  [Status.Update]: { changeState: ChangeState.Update },
}

const getStatus = ({ changeState }) => {
  const state = {
    [ChangeState.New]: Status.New,
    [ChangeState.Update]: Status.Update,
  }

  return state[changeState] || Status.All
}

export const StatusRadioGroup = ({ value, onChange }) => {
  return (
    <Div mb={spacing(2)}>
      <RadioGroup
        label="Project status"
        width={160}
        name="projectStatus"
        initialValue={getStatus(value)}
        onChange={e => onChange(FILTER_FOR_STATUS[e.target.value])}
        options={[
          {
            label: 'All',
            value: Status.All,
          },
          {
            label: 'New',
            value: Status.New,
          },
          {
            label: 'Updated',
            value: Status.Update,
          },
        ]}
      />
    </Div>
  )
}
