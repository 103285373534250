"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Toggle = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Toggle = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M14.6667 7.99999C14.6667 10.4546 12.6768 12.4444 10.2222 12.4444H5.77777C3.32317 12.4444 1.33333 10.4546 1.33333 7.99999C1.33333 5.54538 3.32317 3.55554 5.77777 3.55554H10.2222C12.6768 3.55554 14.6667 5.54538 14.6667 7.99999ZM10.2222 5.03702C8.58469 5.03702 7.25925 6.36223 7.25925 7.99999C7.25925 9.63751 8.58446 10.9629 10.2222 10.9629C11.8597 10.9629 13.1852 9.63774 13.1852 7.99999C13.1852 6.36246 11.86 5.03702 10.2222 5.03702Z", fill: color })));
exports.Toggle = Toggle;
