import { box } from '@basisboard/basis-ui/es/utils'
import * as merge from 'deepmerge'

export const setValue = (obj: unknown, path: string, value: any) =>
  merge.all([
    obj,
    path
      .split('.')
      .reverse()
      .reduce(
        (acc, key) =>
          box(parseInt(key)).fold(intKey =>
            !Number.isNaN(intKey) &&
            typeof intKey === 'number' &&
            intKey.toString().length === key.length
              ? [acc]
              : { [key]: acc },
          ),
        value,
      ),
  ])
