import {
  CustomFieldMultiSelectSetting,
  CustomFieldSingleSelectSetting,
  CustomFieldTeamSelectSetting,
} from '@basisboard/basis-common/lib/api/custom-fields'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { DEFAULT_BORDER_RADIUS, spacing } from '@basisboard/basis-ui/es/styles'
import { darken } from 'polished'

export const renderCustomFieldsMultiSelect = (
  field: Pick<
    CustomFieldMultiSelectSetting | CustomFieldSingleSelectSetting | CustomFieldTeamSelectSetting,
    'options' | 'type'
  >,
  { name, id }: { name: string; id: string },
  selected = false,
) => {
  const color = field.options.find(opt => opt.label === id).color

  return (
    <Div
      height={24}
      background={color}
      borderRadius={DEFAULT_BORDER_RADIUS}
      pl={spacing(1)}
      pr={spacing(selected ? 3 : 1)}
      mr={spacing(selected ? 1 : 0)}
    >
      <Text lineHeight="24px" color={darken(0.5, color)}>
        {name}
      </Text>
    </Div>
  )
}
