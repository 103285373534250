"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Collapse = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Collapse = ({ width = 24, height = 24, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: size || width, height: size || height, viewBox: "0 0 448 512" },
    React.createElement("path", { fill: color, d: "M207 184.1C209.6 187.5 215.5 192 224 192s14.4-4.461 16.97-7.031l72-72C317.7 108.3 320 102.1 320 96c0-13.71-11.21-24-24-24c-6.141 0-12.28 2.344-16.97 7.031L248 110.1V24C248 10.75 237.3 0 224 0S200 10.75 200 24v86.06L168.1 79.03C164.3 74.34 158.1 72 152 72C138.3 72 128 83.21 128 96c0 6.141 2.344 12.28 7.031 16.97L207 184.1zM240.1 327C234.9 321 227.7 320 224 320c-3.682 0-10.94 .9906-16.97 7.022l-72 72C130.3 403.7 128 409.9 128 416c0 13.71 11.21 24 24 24c6.141 0 12.28-2.344 16.97-7.031L200 401.9V488C200 501.3 210.8 512 224 512s24-10.75 24-24v-86.06l31.03 31.03C283.7 437.7 289.8 440 296 440c18.79 0 24-17.2 24-24c0-6.141-2.344-12.28-7.031-16.97L240.1 327zM424 232H24C10.75 232 0 242.7 0 255.1S10.75 280 24 280h400c13.25 0 24-10.76 24-24.01S437.3 232 424 232z" })));
exports.Collapse = Collapse;
