import { COMPANY_ID } from '@basisboard/basis-common/lib/api'
import { box } from '@basisboard/basis-ui/es/utils'
import * as React from 'react'
import { CompanyChip, Searcher } from '../../../../components'
import { screens } from '../../../../screens'
import { companyRepository } from '../../container'

interface Props {
  onChange: (companies: COMPANY_ID[]) => void
  value: COMPANY_ID[]
  height?: number
  isBasisLiteFeatureFlag?: boolean
}

export const Select = ({ onChange, value, height, isBasisLiteFeatureFlag }: Props) => {
  const [localVal, setVal] = React.useState(value)

  const handleChange = val => {
    setVal(val)
    onChange(val)
  }

  return (
    <Searcher
      height={height}
      onChange={companies => handleChange(companies.filter(c => !!c).map(c => c.id))}
      options={companyRepository().state.companyNames}
      renderEntry={(e, isOption) => (
        <CompanyChip
          {...(isOption
            ? {}
            : {
                onClose: () => handleChange((localVal as string[]).filter(c => !!c && c !== e.id)),
              })}
          companies={{
            name: e.name,
            onClick: !isBasisLiteFeatureFlag
              ? ev => {
                  if (!isOption) {
                    ev.stopPropagation()
                    screens.companyDetails.push(e.id)
                  }
                }
              : undefined,
          }}
        />
      )}
      value={companyRepository().getCompanyNamesById((localVal as string[]) || [])}
      onFilter={(company, search) =>
        !search ? true : box(new RegExp(search, 'i')).fold(regex => regex.test(company.name))
      }
    />
  )
}
