import { FilterRoot } from '@basisboard/basis-common/lib/api/custom-filter'
import equals from 'ramda/src/equals'
import { openDrawer } from '../../Drawer'
import { openCreateCustomFilterDrawer } from '../components/CustomFilters'
import { FiltersDrawer, FiltersDrawerProps } from './Filters'

export const openFiltersDrawer = (props: FiltersDrawerProps) => {
  const { viewId, filtersData, getView, openAppliedFilter, enabledEntities, onChangeFilter } = props

  if (!openAppliedFilter) {
    return openDrawer({
      id: 'filters-drawer',
      title: 'Filter',
      content: <FiltersDrawer {...(props as any)} />,
      onClose: () => null,
    })
  }

  const { sharedCustomFilters, customFilters, filter } = filtersData.state

  return openCreateCustomFilterDrawer(
    {
      filterNames: customFilters.map(f => f.name),
      enabledEntities,
      viewId,
      onCreate: (f: FilterRoot) => {
        onChangeFilter?.({ customFilter: f })
      },
      customFilter: [...(sharedCustomFilters || []), ...(customFilters || [])].find(cf =>
        equals(JSON.stringify(cf.filter), JSON.stringify(filter.customFilter)),
      ),
      getView,
    },
    () => null,
  )
}
