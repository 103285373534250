"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Star = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Star = ({ width = 24, height = 24, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 24 24" },
    React.createElement("path", { d: "M11.0035 3.72915L8.73611 8.32638L3.6632 9.06596C2.75348 9.1979 2.38889 10.3194 3.04861 10.9618L6.71875 14.5382L5.8507 19.5903C5.69445 20.5035 6.65625 21.1875 7.46181 20.7604L12 18.375L16.5382 20.7604C17.3438 21.184 18.3056 20.5035 18.1493 19.5903L17.2813 14.5382L20.9514 10.9618C21.6111 10.3194 21.2465 9.1979 20.3368 9.06596L15.2639 8.32638L12.9965 3.72915C12.5903 2.90971 11.4132 2.89929 11.0035 3.72915Z", fill: color })));
exports.Star = Star;
