import {
  borderRadiusMixin,
  colors,
  DEFAULT_BORDER_RADIUS,
  spacing,
  transitionMixin,
} from '@basisboard/basis-ui/es/styles'
import { box, readableName } from '@basisboard/basis-ui/es/utils'
import { Mention as BaseMention, MentionsInput as BaseMentionInput } from 'react-mentions'
import styled, { AnyStyledComponent } from 'styled-components'
import {
  maxHeight,
  MaxHeightProps,
  minHeight,
  MinHeightProps,
  space,
  SpaceProps,
} from 'styled-system'
import { PersonChip } from '../../PersonChip'

export const MentionsInput = styled((BaseMentionInput as unknown) as AnyStyledComponent).attrs({
  style: {
    suggestions: {
      marginTop: spacing(4),
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
      borderRadius: DEFAULT_BORDER_RADIUS,
      list: {
        paddingTop: spacing(1),
        position: 'relative',
        backgroundColor: 'white',
        maxHeight: 220,
        overflow: 'auto',
        fontSize: 14,
      },
      item: {
        padding: `${spacing(1)} ${spacing(2)}`,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        '&focused': {
          backgroundColor: '#fafafa',
        },
      },
    },
  },
})<MinHeightProps & MaxHeightProps & SpaceProps & { borderless?: boolean }>`
  ${maxHeight};
  ${minHeight};
  ${space};
  background-color: ${colors.white};
  height: 75px;
  font-size: 0.875rem;

  div {
    height: 100%;
  }

  textarea {
    border: ${({ borderless }) => (borderless ? 'none' : '1px solid #dadce3')};

    box-sizing: border-box;
    ${borderRadiusMixin};
    padding: ${spacing(2)};
    margin: 0;
    margin-bottom: ${spacing(1)};
    width: 100%;
    resize: none;

    height: 75px;

    font-size: 0.875rem;

    ${transitionMixin};

    &::placeholder {
      color: ${colors.gray};
    }

    &:hover {
      border-color: #0d28f2;
    }

    &:focus {
      border-color: #0d28f2;
      outline: 0;
    }

    ${maxHeight};
    ${minHeight};
  }
`

export const Mention = ({ users, ...rest }) => (
  <MentionsInput {...rest}>
    <BaseMention
      trigger="@"
      data={users.map(user => ({
        display: readableName(user),
        id: user.id,
      }))}
      style={{
        padding: 1,
        borderRadius: DEFAULT_BORDER_RADIUS,
        fontWeight: 'bold',
        color: 'transparent',
        background: '#e7eafe', // lighten(0.2, colors.accentActive),
        margin: 0,
      }}
      displayTransform={(_, display) => `@${display}`}
      renderSuggestion={suggestion =>
        box(users.find(u => u.id === suggestion.id)).fold(user => (
          <PersonChip color={user.color} name={readableName(user)} />
        ))
      }
    />
  </MentionsInput>
)
