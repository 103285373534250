"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Notes = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Notes = ({ width = 16, height = 16, size, color = styles_1.colors.boardIcon, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M12.9906 2.33398H3.0101C2.09262 2.33398 1.34668 3.07992 1.34668 3.9974V11.4828C1.34668 12.4003 2.09262 13.1462 3.0101 13.1462H5.50522V15.3294C5.50522 15.5841 5.79632 15.7323 6.00165 15.5815L9.24791 13.1462H12.9906C13.9081 13.1462 14.654 12.4003 14.654 11.4828V3.9974C14.654 3.07992 13.9081 2.33398 12.9906 2.33398Z", fill: color })));
exports.Notes = Notes;
