import {
  DeleteStageResponse,
  GetStageDetailResponse,
  GetStagesResponse,
  PostStageReasonsResponseBody,
  PostStagesResponse,
  Stage,
  StageCode,
  STAGE_ID,
} from '@basisboard/basis-common/lib/api'
import { del, get, patch, post } from '../../api'
import { StageDetails } from '../../types'

export const getStages = (): Promise<StageDetails[]> =>
  get<GetStagesResponse>('/stages').then(response =>
    response.data.stages.map(s => ({
      ...s,
      reasons: Object.values(response.data.stageReasonsMap).filter(r => r.stageId === s.id),
    })),
  )

export const postStages = (formattedStages: Stage[]) =>
  post<PostStagesResponse>('stages', {
    stages: formattedStages,
  }).then(response =>
    response.data.stages.map(s => ({
      ...s,
      stageReasons: s.stageReasonIds.map(r => response.data.stageReasonsMap[r]),
    })),
  )

export const deleteStage = (stageId: STAGE_ID, moveToStageId?: STAGE_ID) => {
  const deleteStagesApiUrl =
    moveToStageId === undefined
      ? `/stages/${stageId}`
      : `/stages/${stageId}?newStageId=${moveToStageId}`
  return del<DeleteStageResponse>(deleteStagesApiUrl)
}

export const addStageReason = (stageCode: StageCode, name: string) =>
  post<PostStageReasonsResponseBody>('stage-reasons', {
    stageCode,
    name,
  }).then(response => response.data.stageReasonId)

export const patchReason = (stageReasonId: string, name: string) =>
  patch(`stage-reasons/${stageReasonId}`, {
    name,
  })

export const deleteReason = (stageReasonId: string) => del(`stage-reasons/${stageReasonId}`)

export const getStageDetails = (stageId: STAGE_ID) =>
  get<GetStageDetailResponse>(`/stages/${stageId}`).then(response => response.data.stage)
