"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Link = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Link = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M9.82196 6.17787C11.3748 7.73236 11.3535 10.2246 9.83132 11.7553C9.82846 11.7584 9.82508 11.7618 9.82196 11.7649L8.07537 13.5115C6.53489 15.0519 4.02862 15.0517 2.48837 13.5115C0.947888 11.9712 0.947888 9.46468 2.48837 7.92446L3.45279 6.96004C3.70854 6.70429 4.14898 6.87427 4.16218 7.2357C4.17902 7.69631 4.26162 8.15908 4.41403 8.60596C4.46565 8.75728 4.42877 8.92466 4.31571 9.03772L3.97557 9.37787C3.24715 10.1063 3.2243 11.2924 3.94555 12.0279C4.67392 12.7707 5.87111 12.7751 6.60507 12.0412L8.35166 10.2948C9.08437 9.56212 9.0813 8.37782 8.35166 7.64817C8.25546 7.55216 8.15857 7.47757 8.08288 7.42546C7.97431 7.3509 7.90737 7.22942 7.90233 7.09782C7.89203 6.82317 7.98934 6.54015 8.20637 6.32313L8.75358 5.77589C8.89708 5.6324 9.12218 5.61477 9.28858 5.7309C9.47913 5.86396 9.65762 6.01353 9.82196 6.17787ZM13.485 2.51466C11.9447 0.974385 9.43847 0.974177 7.89798 2.51466L6.1514 4.26125C6.14828 4.26436 6.1449 4.26774 6.14204 4.27086C4.61986 5.80152 4.59852 8.29376 6.1514 9.84825C6.31573 10.0126 6.49421 10.1621 6.68476 10.2952C6.85115 10.4113 7.07628 10.3937 7.21975 10.2502L7.76696 9.70296C7.98399 9.48594 8.0813 9.20292 8.07101 8.92828C8.06596 8.79667 7.99902 8.67519 7.89045 8.60064C7.81476 8.54852 7.71787 8.47393 7.62168 8.37792C6.89203 7.64828 6.88897 6.46397 7.62168 5.73127L9.36826 3.98494C10.1022 3.25098 11.2994 3.2554 12.0278 3.99819C12.749 4.73373 12.7262 5.9198 11.9978 6.64822L11.6576 6.98837C11.5446 7.10143 11.5077 7.26881 11.5593 7.42013C11.7117 7.86702 11.7943 8.32978 11.8111 8.7904C11.8244 9.15182 12.2648 9.32181 12.5205 9.06605L13.485 8.10164C15.0255 6.56144 15.0255 4.05488 13.485 2.51466Z", fill: color })));
exports.Link = Link;
