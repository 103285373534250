import { Project } from '@basisboard/basis-common/lib/api/projects'
import { SearchEntity } from '@basisboard/basis-common/lib/enums'
import { Badge } from '@basisboard/basis-ui/es/components/Badge'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { HeavyText, Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { fromNullable } from '@basisboard/basis-ui/es/utils/monads'
import * as React from 'react'
import { SpaceProps } from 'styled-system'
import { SearchInput } from '../../../../components/Input/SearchInput'
import { NestedProject } from '../../../../types'
import { search } from '../../../Search/state'
import { projectRepository } from '../../container'

interface Props {
  onChange: (project: Partial<Project>) => void
  value?: Partial<Project>
  allowCreatingNew?: boolean
  space?: SpaceProps
  omitNewTag?: boolean
}

export const ProjectInput: React.FC<Props> = ({
  onChange,
  value,
  allowCreatingNew,
  space,
  omitNewTag,
}) => {
  const [project, setProject] = React.useState<Partial<NestedProject>>(value || { name: 'N/A' })
  const [searchTerm, setSearchTerm] = React.useState(project.name)

  React.useEffect(() => {
    if (!value) {
      onChange(project)
    }
  }, [])

  const renderCreateItem = (searchTerm: string) => (
    <HeavyText color={colors.gray} ml={spacing(1)}>
      Add{' '}
      <HeavyText as="span">
        <strong>“{searchTerm}”</strong>
      </HeavyText>{' '}
      as a new bid
    </HeavyText>
  )

  const handleCreate = (searchTerm: string) => onChange({ name: searchTerm })

  const searchProjects = (term: string) =>
    search(term, [SearchEntity.Project])
      .then(re => re.data.projects.map(p => p.id))
      .then(projectRepository().getProjectsFromIds)

  return (
    <SearchInput<Partial<NestedProject>>
      placeholder="Search for a bid"
      value={project?.name || searchTerm}
      fetchData={searchProjects}
      space={space}
      renderListItem={(project: NestedProject) => (
        <Div display="flex" aligItems="center" width={1} overflow="hidden">
          <Div
            background={colors.lightGray3}
            display="flex"
            alignItems="center"
            justifyContent="center"
            size={24}
            minWidth={24}
            minHeight={24}
            borderRadius={24}
          >
            <Icon.DollarMark />
          </Div>
          <Text ml={spacing(1)} lineHeight="24px">
            {project.name}
          </Text>
        </Div>
      )}
      suffix={omitNewTag ? undefined : value && value.id === undefined && <Badge.New />}
      formatNewValue={val => ({ name: val })}
      renderCreateItem={allowCreatingNew ? renderCreateItem : () => null}
      onChangeSearch={setSearchTerm}
      onCreate={allowCreatingNew ? handleCreate : () => null}
      onSelect={project => {
        onChange(project)
        setProject(project)
      }}
      onBlur={() =>
        fromNullable(
          Object.values(projectRepository().state.entities.projects).find(
            (project: NestedProject) => project.name === searchTerm,
          ),
        ).fold(
          () => {
            onChange({ name: searchTerm })
            setProject({ name: searchTerm })
          },
          project => {
            onChange(project)
            setProject(project)
          },
        )
      }
    />
  )
}
