import { UserRole } from '@basisboard/basis-common/lib/api'
import {
  CustomFieldEntity,
  CustomFieldSetting,
  CustomFieldType,
} from '@basisboard/basis-common/lib/api/custom-fields'
import { ProjectFieldType } from '@basisboard/basis-common/lib/enums'
import { ViewId } from '../../constants'
import { createField, DEFAULT_FIELDS_SETTINGS } from './constants'

export const createColumnsForCustomFields = (
  customFields: CustomFieldSetting[],
  entity: CustomFieldEntity,
) =>
  customFields
    .filter(c => c.entity === entity)
    .reduce(
      (acc, field) => ({
        ...acc,
        [field.id]: {
          label: field.label,
          dataIndex: field.id,
          key: field.id,
          sortKey: field.id,
          type: field.type,
        },
      }),
      {},
    )

export const DEFAULT_COLUMN_WIDTH = 200

export const mapViewTypeToCustomFieldEntity = {
  [ViewId.ListViewId]: CustomFieldEntity.Project,
  [ViewId.BoardViewId]: CustomFieldEntity.Project,
  [ViewId.CalendarViewId]: CustomFieldEntity.Project,
  [ViewId.ContactsViewId]: CustomFieldEntity.Contact,
  [ViewId.AccountsViewId]: CustomFieldEntity.Company,
}

export const getCustomFieldsForView = (customFields: CustomFieldSetting[], key) =>
  customFields
    .filter(c => c.entity === mapViewTypeToCustomFieldEntity[key] && !c.isEntityLabel)
    .map((c: CustomFieldSetting) =>
      createField(c.id, c.type, c.label, false, DEFAULT_COLUMN_WIDTH, false),
    )

export const getInitialState = (customFields: CustomFieldSetting[], userRole?: UserRole) =>
  Object.values(ViewId).reduce(
    (acc, key) =>
      DEFAULT_FIELDS_SETTINGS[key]
        ? {
            ...acc,
            [key]: [
              ...DEFAULT_FIELDS_SETTINGS[key].fields.filter(
                f => f.id !== ProjectFieldType.Quotes || userRole !== UserRole.RestrictedMember,
              ),
              ...customFields
                .filter(c => c.entity === mapViewTypeToCustomFieldEntity[key] && !c.isEntityLabel)
                .map((c: CustomFieldSetting) =>
                  createField(c.id, c.type, c.label, false, DEFAULT_COLUMN_WIDTH, false),
                ),
            ],
          }
        : acc,
    { customFields, loading: false },
  )

export const isSelectField = (type: CustomFieldType) =>
  [
    CustomFieldType.MultiSelect,
    CustomFieldType.SingleSelect,
    CustomFieldType.UserMultiSelect,
    CustomFieldType.TeamSelect,
    CustomFieldType.CompanyMultiSelect,
  ].includes(type)

export const isGroupableField = (type: CustomFieldType) =>
  [
    CustomFieldType.SingleSelect,
    CustomFieldType.TeamSelect,
    CustomFieldType.MultiSelect,
    CustomFieldType.UserMultiSelect,
    CustomFieldType.CompanyMultiSelect,
  ].includes(type)
