import { User } from '@basisboard/basis-common/lib/api'
import {
  CustomFieldSetting,
  CustomFieldType,
  CustomFieldValues,
} from '@basisboard/basis-common/lib/api/custom-fields'
import { Checkbox } from '@basisboard/basis-ui/es/components/Checkbox'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { scrollBarMixin } from '@basisboard/basis-ui/es/styles'
import { formatQuote } from '@basisboard/basis-ui/es/utils'
import { getContainer } from '@containrz/react-hook'
import moment, { Moment } from 'moment'
import * as React from 'react'
import styled from 'styled-components'
import { Optional } from 'utility-types'
import { TextOverflow } from '../../../../components'
import { eventBus, EventBusType } from '../../../../services'
import { showErrorToast } from '../../../Toast'
import { Users } from '../../../Users'
import { FieldsState } from '../../container'
import { CompanySelectType } from './components/CompanySelectType'
import { DateSelectType } from './components/DateSelectType'
import { RichTextType } from './components/RichTextType'
import { SelectType } from './components/SelectType'
import { TextType } from './components/TextType'

interface Props {
  customField: Optional<
    Pick<CustomFieldSetting, 'label' | 'id' | 'type' | 'isEntityLabel' | 'options'>
  >
  value: CustomFieldValues | Moment
  onChange: (newVal: CustomFieldValues | Moment) => void
  format?: (val: CustomFieldValues | Moment) => void
}

const Box = styled.div`
  max-width: 100%;
  overflow: auto;

  ${scrollBarMixin};
`

export const ListEntry: React.FC<Props> = ({ customField, value, onChange, format }) => {
  const renderOverflow = content => <TextOverflow TextComponent={Text}>{content}</TextOverflow>

  const renderWrapper = content => (
    <Box onClick={e => e.stopPropagation()} onMouseDown={e => e.stopPropagation()}>
      {content}
    </Box>
  )

  const handleChange = (newVal: CustomFieldValues | Moment) => {
    if (!onChange) {
      return showErrorToast({ message: "This field can't be edited" })
    }

    eventBus.publish(EventBusType.InlineEdit, {
      field: customField.label,
      view: window.location.pathname,
    })
    onChange(newVal)
  }

  switch (customField.type) {
    case CustomFieldType.Boolean:
      return (
        <Div
          as="label"
          width={1}
          height="100%"
          display="flex"
          alignItems="center"
          onClick={e => e.stopPropagation()}
        >
          <Checkbox value={Boolean(value)} onChange={e => handleChange(e.target.checked)} />
        </Div>
      )

    case CustomFieldType.Date:
      return renderWrapper(
        <DateSelectType
          label={customField.label}
          value={value ? moment(value as string) : null}
          onChange={onChange ? handleChange : null}
          onFormat={format as any}
        />,
      )

    case CustomFieldType.SingleSelect:
      return (
        <SelectType
          label={customField.label}
          value={value as string[]}
          options={customField.options}
          onChange={handleChange}
        />
      )

    case CustomFieldType.MultiSelect:
      return (
        <SelectType
          label={customField.label}
          value={value as string[]}
          options={customField.options}
          onChange={handleChange}
          multiple
        />
      )

    case CustomFieldType.TeamSelect:
      const teams = getContainer(FieldsState).getTeams()

      return (
        <SelectType
          label={customField.label}
          value={value as string[]}
          options={teams.map(opt => ({
            id: opt.id,
            label: opt.label,
            color: opt.entitySettings.color,
          }))}
          onChange={v =>
            handleChange(
              ((v || []) as string[]).map(teamId => teams.find(team => team.id === teamId).id),
            )
          }
          multiple
          field={{
            ...customField,
            options: teams.map(opt => ({
              label: opt.id,
              color: opt.entitySettings.color,
            })),
          }}
        />
      )

    case CustomFieldType.UserMultiSelect:
      return renderWrapper(
        <Users.Chip
          height={32}
          value={value as string[] | string}
          onUpdate={
            typeof value === 'string'
              ? (user: User) => onChange(user.id)
              : (users: User[]) => onChange(users.map(u => u.id))
          }
        />,
      )

    case CustomFieldType.CompanyMultiSelect:
      return <CompanySelectType value={value as string[]} onChange={onChange} />

    case CustomFieldType.Percent:
      return (
        <TextType
          type="number"
          label={customField.label}
          value={value as number}
          onChange={handleChange}
          formatter={v => `${v}%`}
        />
      )

    case CustomFieldType.Monetary:
      return (
        <TextType
          type="number"
          label={customField.label}
          value={value as number}
          onChange={handleChange}
          formatter={v => formatQuote(+v * 100)}
          valueFormatter={v => v as number}
          submitFormatter={v => v as number}
        />
      )

    case CustomFieldType.Text:
      return (
        <TextType
          label={customField.label}
          type="text"
          value={value as CustomFieldValues}
          onChange={handleChange}
        />
      )

    case CustomFieldType.RichText:
      return (
        <RichTextType
          label={customField.label}
          value={(value as string) || ''}
          onChange={val => handleChange(val)}
        />
      )

    default:
      return renderOverflow(value)
  }
}
