import { colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import styled from 'styled-components'
import { HEADER_HEIGHT_PX } from '../../../../styles'

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
`

export const ProfileButton = styled.button`
  padding: 0 ${spacing(3)};
  display: flex;
  height: ${HEADER_HEIGHT_PX};
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }
`

export const Menu = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 176px;
  height: 226px;

  margin: 0;
  padding: ${spacing(2)} 0;
`

export const MenuEntry = styled.li`
  width: 100%;
  height: 32px;

  margin: ${spacing(0.25)} 0;

  &:hover {
    background: ${colors.lightGray3};
  }
  ${transitionMixin};

  & > * {
    color: ${colors.darkBlue};

    & span {
      color: ${colors.darkBlue};
    }

    line-height: 32px;
    width: 100%;
    padding: 0 ${spacing(2)};
    display: block;
  }
`
