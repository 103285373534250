"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Drawer = void 0;
const React = __importStar(require("react"));
const Button_1 = require("../Button");
const consts_1 = require("./consts");
const controller_1 = require("./controller");
const styled_1 = require("./styled");
__exportStar(require("./props"), exports);
const Drawer = ({ id, isOpen = false, defaultOpen, width = 576, openFrom = 'right', children, onClose, closeDrawerAriaLabel, focused = true, noPadding = false, }) => {
    const [open, setOpen] = React.useState(typeof defaultOpen === 'boolean' ? defaultOpen : isOpen);
    const controller = React.useRef((0, controller_1.DrawerControllerFactory)(id));
    React.useEffect(() => {
        if (open) {
            controller.current.onSetOpen();
        }
    }, []);
    React.useEffect(() => {
        if (focused) {
            return;
        }
        controller.current.cleanupListeners();
        return () => {
            controller.current.registerListeners();
        };
    }, [focused]);
    React.useEffect(() => {
        controller.current.setCallbacks(onClose);
    }, [onClose]);
    React.useEffect(() => {
        if (open !== isOpen) {
            setOpen(isOpen);
            controller.current.setOpen(isOpen);
        }
    }, [isOpen, id]);
    return (React.createElement(styled_1.Wrapper, { openFrom: openFrom, id: id, className: open ? consts_1.DRAWER_OPEN_CLASS : undefined, "aria-hidden": true, ref: controller.current.setWrapperRef },
        React.createElement(styled_1.Backdrop, null),
        React.createElement(styled_1.Content, { id: `${id}--content`, ref: controller.current.setContentRef, width: width, noPadding: noPadding },
            children,
            React.createElement(styled_1.ContentCloseBar, null,
                React.createElement(Button_1.Button.Default, { size: 24, preIcon: "Close", "aria-label": closeDrawerAriaLabel || 'Close drawer', onClick: controller.current.handleClose })))));
};
exports.Drawer = Drawer;
Drawer.setOpen = (id) => (0, controller_1.DrawerControllerFactory)(id).toggleOpen();
