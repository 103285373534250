import { borderRadiusMixin, colors, scrollBarMixin, spacing } from '@basisboard/basis-ui/es/styles'
import { darken } from 'polished'
import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 40px;
  height: 40px;

  button {
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  background-color: ${colors.blueGray};
  position: sticky;
  left: calc(100% - 40px);
  top: 0;
  z-index: 10;

  :hover {
    background-color: ${darken(0.05, colors.blueGray)};
  }
`

export const List = styled.ul`
  padding: ${spacing(2)};
  max-height: 400px;
  overflow: auto;

  ${scrollBarMixin};
`

export const Entry = styled.li`
  width: calc(100% + ${spacing(2)});
  display: flex;
  align-items: center;

  height: 32px;

  margin: 0 ${spacing(-1)};
  padding: 0 ${spacing(1)};

  cursor: pointer;

  :hover {
    background-color: ${colors.blueGray};
  }

  ${borderRadiusMixin};

  &:not(:last-child) {
    margin-bottom: ${spacing(1)};
  }
`
