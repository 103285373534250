import { Platform } from '@basisboard/basis-common/lib/enums'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { Row } from '@basisboard/basis-ui/es/components/Row'
import {
  DetailText,
  HeavyText,
  Span,
  Subtitle2,
  Text,
} from '@basisboard/basis-ui/es/components/Typography'
import { borderRadiusMixin, boxShadowMixin, colors, spacing } from '@basisboard/basis-ui/es/styles'
import { box } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import { darken, lighten } from 'polished'
import * as React from 'react'
import styled from 'styled-components'
import { height, HeightProps } from 'styled-system'
import { allowedPlatforms, mapPlatformToString } from '../../constants'
import { PlatformsContainer } from '../../container'
import { openCreatePlatformModal } from '../../modals'
import { PlatformBannerState } from './state'

const Wrapper = styled.div<HeightProps>`
  padding: ${spacing(2)};
  background-color: ${lighten(0.4, colors.error)};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${borderRadiusMixin};
  ${boxShadowMixin};

  ${height};
`

interface BannerProps {
  platforms: Platform | Platform[]
  compact?: boolean
  origin?: 'importer' | 'listBanner'
}

export const Banner = ({ platforms, compact, origin }: BannerProps) => {
  const bannerData = useContainer(PlatformBannerState)
  const platformsData = useContainer(PlatformsContainer)

  const platformEntries = Array.isArray(platforms) ? platforms : [platforms]
  const platform = platformEntries[0]

  if (
    bannerData.state.dismissedPlatforms.includes(platform) ||
    !allowedPlatforms.includes(platform)
  ) {
    return null
  }

  return (
    <Wrapper data-testid="banner" height={platformEntries.length > 1 ? 120 : compact ? 100 : 88}>
      <Div>
        <Row justifyContent="flex-start" mb={spacing(1)}>
          <Icon.Exclamation size={16} color={colors.error} />
          {compact ? (
            <HeavyText ml={spacing(1)} color={colors.error}>
              {mapPlatformToString[platform]} not connected to Basis
            </HeavyText>
          ) : (
            <Subtitle2 ml={spacing(1)} color={colors.error}>
              {platformEntries.length > 1
                ? 'Connect your bidding platforms to Basis'
                : `${mapPlatformToString[platform]} not connected to Basis`}
            </Subtitle2>
          )}
        </Row>
        {box(
          <>
            {platformEntries.length > 1 ? (
              <>
                <Span as="p" my={spacing(1.5)} color={colors.error}>
                  Connect to your bidding platforms to get more accurate information into Basis,
                  reducing time needed to process emails into Basis.
                </Span>
                <Span as="p" color={colors.error}>
                  We’ve identified the following Platforms with missing connection information:{' '}
                  {box(platformEntries.map(p => mapPlatformToString[p]))
                    .map(entries => {
                      if (entries.length === 1) {
                        return { entries }
                      }
                      const lastEntry = entries.pop()
                      return { entries, lastEntry }
                    })
                    .fold(({ entries, lastEntry }) => (
                      <>
                        <Span color={darken(0.2, colors.error)}>{entries.join(', ')}</Span>
                        {lastEntry && (
                          <>
                            {' '}
                            and <Span color={darken(0.2, colors.error)}>{lastEntry}</Span>
                          </>
                        )}
                      </>
                    ))}
                </Span>
              </>
            ) : (
              <>
                Connect to {mapPlatformToString[platform]} to get more accurate information into{' '}
                {compact ? <br /> : null} Basis, reducing time needed to process emails into Basis.
              </>
            )}
          </>,
        ).fold(content =>
          compact ? (
            <DetailText color={colors.error}>{content}</DetailText>
          ) : (
            <Text color={colors.error}>{content}</Text>
          ),
        )}
      </Div>

      <Row flexDirection="column">
        <Button.Danger
          onClick={() => openCreatePlatformModal({ platformId: platform, flowOrigin: origin })}
        >
          Connect Now
        </Button.Danger>
        <Button.Custom
          data-testid="banner-close"
          onClick={() => {
            bannerData.dismissPlatform(platform)
            platformsData.setState({ missingPlatforms: [] })
          }}
          color={colors.error}
          backgroundColor={colors.transparent}
        >
          Close
        </Button.Custom>
      </Row>
    </Wrapper>
  )
}
