import { HeavyText } from '@basisboard/basis-ui/es/components/Typography'
import { borderRadiusMixin, colors, cssSize, spacing } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { color } from 'styled-system'

const Input = styled.input.attrs({
  type: 'checkbox',
})`
  display: none;
`

const Label = styled(HeavyText)`
  display: flex;
  align-items: center;
  color: ${colors.gray};

  ${color};
`

const Check = ({ disabled, inverse }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M4.39644 9.58214L1.14644 6.33214C0.951187 6.13689 0.951187 5.82031 1.14644 5.62503L1.85353 4.91792C2.04878 4.72265 2.36539 4.72265 2.56064 4.91792L4.75 7.10726L9.43935 2.41792C9.63461 2.22267 9.95121 2.22267 10.1465 2.41792L10.8535 3.12503C11.0488 3.32029 11.0488 3.63687 10.8535 3.83214L5.10355 9.58216C4.90828 9.77742 4.59169 9.77742 4.39644 9.58214Z"
      fill={disabled ? colors.gray : inverse ? colors.white : colors.accent}
    />
  </svg>
)

const Box = styled.span<{ active: boolean; disabled?: boolean; inverse?: boolean }>`
  ${cssSize(16)};
  ${borderRadiusMixin};
  border: 2px solid ${colors.gray};
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ active, disabled, inverse }) =>
    active &&
    !disabled &&
    css`
      border-color: ${colors.accent};
      ${inverse && `background-color: ${colors.accent}`};
    `};

  ${({ disabled }) => disabled && 'opacity: 0.5'};
`

export interface CheckboxInputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'value'> {
  label?: string
  color?: string
  name?: string
  initialValue?: boolean
  value?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  inverse?: boolean
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxInputProps>(
  ({ label, initialValue, color, value, disabled = false, inverse, ...props }, ref) => {
    const [check, toggleChecked] = React.useState<boolean>(Boolean(initialValue || value))

    React.useEffect(() => {
      toggleChecked(Boolean(value))
    }, [toggleChecked, value])

    return (
      <Label as="label">
        <Input
          ref={ref}
          {...(props as any)}
          disabled={disabled}
          onChange={e => {
            toggleChecked(e.target.checked)
            props.onChange && props.onChange(e)
          }}
          // defaultChecked={initialValue}
          checked={typeof check === 'boolean' ? check : initialValue}
          id={props.name}
        />
        <Box active={check} disabled={disabled} inverse={inverse}>
          {check && <Check disabled={disabled} inverse={inverse} />}
        </Box>
        {/* {label && <TextOverflow ml={spacing(1)} color={color}>{label}</TextOverflow>} */}
        {label && (
          <Label color={color} ml={spacing(1)}>
            {label}
          </Label>
        )}
      </Label>
    )
  },
)
