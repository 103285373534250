import { COMPANY_ID, CONTACT_ID, PROJECT_ID, QUOTE_ID } from '@basisboard/basis-common/lib/api'
import * as React from 'react'
import { openDrawer } from '../../Drawer'
import { NotesSection } from '../components/NotesSection'
import { NoteEntityType } from '../types'

interface Props {
  entity?: NoteEntityType
  entityId?: PROJECT_ID | CONTACT_ID | COMPANY_ID | QUOTE_ID
  onClose?: () => void
}

const NotesDrawer: React.FC<Props> = NotesSection

export const openNotesDrawer = (props: Props) =>
  openDrawer({
    id: 'notes-drawer',
    title: 'Notes',
    content: <NotesDrawer {...(props as any)} />,
    onClose: props.onClose,
  })
