import { ProjectSortV2 } from '@basisboard/basis-common/lib/api'
import { CustomFieldType } from '@basisboard/basis-common/lib/api/custom-fields'
import { SortDirection } from '@basisboard/basis-common/lib/enums'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { ButtonGroup } from '@basisboard/basis-ui/es/components/ButtonGroup'
import { Card } from '@basisboard/basis-ui/es/components/Card'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Label } from '@basisboard/basis-ui/es/components/Label'
import { spacing } from '@basisboard/basis-ui/es/styles'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import { ButtonText, Select } from '../../../../components'
import { ViewId } from '../../../../constants'
import { SortsStateInstances } from '../../container'
import { getInitialSorts, getSortOptionByType } from '../../helpers'

interface Props {
  viewId: ViewId
  sortableFields: {
    name: string
    id: string
    type: CustomFieldType
  }[]
  onApplySort: () => void
  onClose: () => void
}

export const SortDropdown: React.FC<Props> = ({ viewId, sortableFields, onApplySort, onClose }) => {
  const sortsData = useContainer(SortsStateInstances(viewId))

  const [sorts, setSorts] = React.useState<ProjectSortV2[]>(
    getInitialSorts(sortsData.state.advancedSorts),
  )

  const handleAddSortCondition = React.useCallback(() => {
    setSorts(s => [...s, { name: '', direction: SortDirection.ASC, entity: 'project' }])
  }, [setSorts])

  const handleRemoveSort = React.useCallback(
    (index: number) =>
      setSorts(s => {
        const sorts = s.filter((sort, i) => i !== index)

        return sorts.length
          ? sorts
          : [{ name: '', direction: SortDirection.ASC, entity: 'project' }]
      }),
    [setSorts],
  )

  const handleSetSortCondition = React.useCallback(
    (index: number, key: keyof ProjectSortV2, value: string) =>
      setSorts(s => s.map((sort, i) => (i === index ? { ...sort, [key]: value } : sort))),
    [],
  )

  const handleClear = React.useCallback(() => {
    sortsData.clearAdvancedSorts()
    onApplySort()
    setSorts(getInitialSorts(sortsData.state.advancedSorts))
  }, [sortsData, onApplySort, setSorts, sortsData.state.advancedSorts])

  const handleApply = React.useCallback(() => {
    sortsData.applyAdvancedSorts(sorts)
    onApplySort()
  }, [sortsData, onApplySort, sorts])

  const handleClose = React.useCallback(() => {
    setSorts(getInitialSorts(sortsData.state.advancedSorts))
  }, [sortsData, setSorts])

  const isActive = sortsData.state.advancedSorts.length > 0

  const canApply = sorts.reduce((acc, sort) => acc && !!sort.name, true)

  return (
    <Card>
      <Div width={400} p={spacing(2)}>
        {sorts.map((sort, index) => (
          <>
            <Div display="flex" alignItems="center" justifyContent="space-between">
              <Label label={index === 0 ? 'Sort by' : 'And then by'} />
              {index === 0 && (
                <ButtonText
                  data-testid="sort-clear"
                  mb={spacing(1)}
                  onClick={handleClear}
                  disabled={!isActive}
                >
                  Clear sorts
                </ButtonText>
              )}
            </Div>

            <Div display="flex" alignItems="center" justifyContent="space-between" mb={spacing(1)}>
              <Div data-testid="sort-select" width={160}>
                <Select
                  options={sortableFields}
                  value={sort.name}
                  sizeVariant="small"
                  placeholder="Field"
                  onChange={val => handleSetSortCondition(index, 'name', val)}
                  hideEmpty
                  dropdownWidth="100%"
                />
              </Div>

              <Div data-testid="sort-condition" width={160}>
                <Select
                  options={getSortOptionByType(
                    sortableFields.find(opt => opt.id === sorts[index].name)?.type ||
                      CustomFieldType.Text,
                  )}
                  value={sort.direction}
                  sizeVariant="small"
                  placeholder="Direction"
                  onChange={val => handleSetSortCondition(index, 'direction', val)}
                  hideEmpty
                  dropdownWidth="100%"
                />
              </Div>

              <Button.Default
                data-testid="sort-close"
                postIcon="Close"
                onClick={() => handleRemoveSort(index)}
              />
            </Div>
          </>
        ))}

        <ButtonText
          data-testid="sort-add-condition"
          disabled={!canApply}
          mt={spacing(2)}
          onClick={handleAddSortCondition}
        >
          + Add condition
        </ButtonText>

        <ButtonGroup width={1} mt={spacing(2)}>
          <Button.Default
            data-testid="sort-cancel"
            ml="auto"
            onClick={() => {
              onClose()
              handleClose()
            }}
          >
            Cancel
          </Button.Default>

          <Button.Primary
            data-testid="sort-apply"
            disabled={!canApply}
            onClick={() => {
              onClose()
              handleApply()
            }}
          >
            Apply
          </Button.Primary>
        </ButtonGroup>
      </Div>
    </Card>
  )
}
