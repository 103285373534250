import { CustomFieldValues, SelectOption } from '@basisboard/basis-common/lib/api/custom-fields'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import * as React from 'react'
import { TableFieldProps } from '../../../type'
import { getOptionColors } from '../../../utils'
import { CustomFieldSelectOption, StyledButton, StyledSpan } from '../../styled'

interface MultiSelectedOptionsProps {
  value: CustomFieldValues
  renderOption?: (
    option: SelectOption & { value: TableFieldProps['value'] },
    props?: unknown,
  ) => React.ReactNode
  options: any
  shouldHaveDelete?: boolean
  shouldHaveCommaSeparator?: boolean
  onDeleteClick?: (index: number) => void
}

export const MultiSelectedOptions: React.FC<MultiSelectedOptionsProps> = props => {
  const {
    children,
    renderOption,
    shouldHaveDelete,
    shouldHaveCommaSeparator,
    value,
    options,
    onDeleteClick,
  } = props

  const multiChildren = React.useMemo(() => {
    if (!children) {
      return null
    }

    if (Array.isArray(children)) {
      return children.map((child, index) => (
        <>
          {child}
          {typeof child === 'string' && shouldHaveCommaSeparator && index < children.length - 1 ? (
            <StyledSpan>,</StyledSpan>
          ) : null}
          {shouldHaveDelete ? (
            <StyledButton
              onClick={e => {
                e.stopPropagation()
                onDeleteClick(index)
              }}
            >
              <Icon.Close />
            </StyledButton>
          ) : null}
        </>
      ))
    }

    return children
  }, [children])

  const currentOptions = (
    (Array.isArray(value)
      ? value?.map(val => options?.find(o => o.value === val)) || []
      : options.filter(o => o.value === value)) ?? []
  ).filter(Boolean)

  return (
    multiChildren ||
    currentOptions.map((option, index) =>
      renderOption ? (
        <CustomFieldSelectOption key={index} as="div">
          {renderOption(option, { isSelectItem: false })}
          {shouldHaveDelete && (
            <StyledButton
              onClick={e => {
                e.stopPropagation()
                onDeleteClick(index)
              }}
            >
              <Icon.Close />
            </StyledButton>
          )}
        </CustomFieldSelectOption>
      ) : (
        <CustomFieldSelectOption key={index} as="div" {...(option ? getOptionColors(option) : {})}>
          {option?.label}
          {shouldHaveDelete && (
            <StyledButton
              onClick={e => {
                e.stopPropagation()
                onDeleteClick(index)
              }}
            >
              <Icon.Close />
            </StyledButton>
          )}
        </CustomFieldSelectOption>
      ),
    )
  )
}
