import { borderRadiusMixin, colors, scrollBarMixin, spacing } from '@basisboard/basis-ui/es/styles'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  max-width: 100%;

  overflow: auto;
  height: 100%;
  max-height: 40px;

  position: relative;

  border: 1px dotted transparent;
  ${borderRadiusMixin};
  padding: 0 ${spacing(5)} 0 ${spacing(1)};

  cursor: pointer;

  & > button,
  & > .add-text {
    visibility: hidden;
  }

  &:hover {
    border-color: ${colors.accent};

    & > button,
    & > .add-text {
      visibility: visible;
    }
  }

  ${scrollBarMixin};
`
