"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseButton = exports.Button = void 0;
const polished_1 = require("polished");
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const styled_components_1 = __importStar(require("styled-components"));
const styled_system_1 = require("styled-system");
const styles_1 = require("../../styles");
const monads_1 = require("../../utils/monads");
const Div_1 = require("../Div");
const Icon_1 = require("../Icon");
const Tooltip_1 = require("../Tooltip");
const Typography_1 = require("../Typography");
const props_1 = require("./props");
const getIconColor = (variant) => {
    if (variant === props_1.ButtonVariantType.Active) {
        return styles_1.colors.accent;
    }
    else if ([props_1.ButtonVariantType.Primary, props_1.ButtonVariantType.Danger, props_1.ButtonVariantType.Cyan].includes(variant)) {
        return styles_1.colors.white;
    }
    else if (variant === props_1.ButtonVariantType.Warning) {
        return styles_1.colors.warning;
    }
    return styles_1.colors.gray;
};
const createVariant = (background, color, extraCss) => (0, styled_components_1.css) `
  background: ${background};
  color: ${color};

  &:hover {
    color: ${color};
    background-color: ${background === 'transparent'
    ? styles_1.colors.lightGray2
    : (0, polished_1.darken)(0.05, background)};
  }

  &:disabled {
    color: ${color};
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${extraCss};
`;
const VARIANTS = {
    [props_1.ButtonVariantType.Default]: createVariant('#E8EAEC', styles_1.colors.mediumGray),
    [props_1.ButtonVariantType.Primary]: createVariant(styles_1.colors.accent, styles_1.colors.white),
    [props_1.ButtonVariantType.Secondary]: createVariant(styles_1.colors.darkGray, styles_1.colors.white),
    [props_1.ButtonVariantType.Danger]: createVariant(styles_1.colors.error, styles_1.colors.white),
    [props_1.ButtonVariantType.Active]: createVariant('#E7EAFE', styles_1.colors.accent),
    [props_1.ButtonVariantType.Transparent]: createVariant('transparent', styles_1.colors.mediumGray),
    [props_1.ButtonVariantType.Cyan]: createVariant(styles_1.colors.cyan, styles_1.colors.white),
    [props_1.ButtonVariantType.Success]: createVariant(styles_1.colors.green, styles_1.colors.white),
    [props_1.ButtonVariantType.Warning]: createVariant(styles_1.colors.transparent, styles_1.colors.warning, (0, styled_components_1.css) `
      border: 1px solid ${styles_1.colors.warning};
    `),
    [props_1.ButtonVariantType.White]: createVariant(styles_1.colors.white, styles_1.colors.gray, (0, styled_components_1.css) `
      border: 1px solid #dbdee7;
    `),
    [props_1.ButtonVariantType.Gradient]: createVariant(styles_1.colors.white, styles_1.colors.gray, 
    // eslint-disable-next-line
    // @ts-ignore
    (0, styled_components_1.css) `
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);

      ${({ active }) => active &&
        (0, styled_components_1.css) `
          color: ${styles_1.colors.darkBlue};
          background: ${styles_1.colors.lightGray3};
        `}
    `),
    [props_1.ButtonVariantType.Text]: createVariant('transparent', styles_1.colors.darkBlue, 
    // eslint-disable-next-line
    // @ts-ignore
    (0, styled_components_1.css) `
      ${({ active }) => active &&
        (0, styled_components_1.css) `
          color: ${styles_1.colors.accent};

          & path {
            fill: ${styles_1.colors.accent};
          }
        `}

      &:hover {
        color: ${styles_1.colors.accent};
        background: transparent;

        & path {
          fill: ${styles_1.colors.accent};
        }
      }
    `),
    [props_1.ButtonVariantType.Custom]: createVariant('transparent', 'transparent', 
    // eslint-disable-next-line
    // @ts-ignore
    (0, styled_components_1.css) `
      ${styled_system_1.background};
      ${styled_system_1.color};

      &:hover {
        ${styled_system_1.color};
        background-color: ${({ backgroundColor }) => (0, polished_1.darken)(0.05, backgroundColor)};
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    `),
};
exports.Button = styled_components_1.default.button `
  height: 32px;
  width: auto;
  color: inherit;

  font-weight: 500;
  font-size: 0.875rem;
  text-decoration: none;
  box-sizing: border-box;

  border: none;

  padding: 0 ${(0, styles_1.spacing)(2)};
  letter-spacing: 0.001rem;
  display: inline-block;

  ${styles_1.borderRadiusMixin};
  ${styles_1.transitionMixin};

  & > span {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 0;
    align-items: center;
    justify-content: center;
  }

  ${({ variant }) => VARIANTS[variant || 'default']};

  &:focus {
    outline: none;
  }

  &:hover {
    ${styled_system_1.background};
  }

  ${styled_system_1.size};
  ${styled_system_1.space};
  ${styled_system_1.width};
  ${styled_system_1.minWidth}
  ${styled_system_1.height};
  ${styled_system_1.borderRadius};
  ${styled_system_1.border};
  ${styled_system_1.background};
  ${styled_system_1.color};
`;
exports.BaseButton = React.forwardRef((_a, ref) => {
    var { info, children, type = 'button' } = _a, props = __rest(_a, ["info", "children", "type"]);
    const renderButton = React.useCallback(() => {
        const { preIcon, postIcon, iconColor, iconSize, href, to } = props, rest = __rest(props, ["preIcon", "postIcon", "iconColor", "iconSize", "href", "to"]);
        const extraProps = !children && Boolean(!!postIcon || !!preIcon)
            ? { size: props.size || 32, p: 0 }
            : { px: Boolean(!!postIcon || !!preIcon) ? (0, styles_1.spacing)(1) : (0, styles_1.spacing)(2) };
        return (
        // eslint-disable-next-line
        // @ts-ignore
        React.createElement(exports.Button, Object.assign({ as: to ? react_router_dom_1.Link : href ? 'a' : 'button', to: to, href: href, type: type }, extraProps, rest, { ref: ref }),
            React.createElement("span", null,
                preIcon &&
                    (0, monads_1.box)(Icon_1.Icon[typeof preIcon === 'string' ? preIcon : preIcon.type]).fold(Ic => (React.createElement(Ic, { size: iconSize || 16, color: preIcon.color ||
                            iconColor ||
                            getIconColor(props.variant) }))),
                children && (React.createElement(Div_1.Div, { as: "span", mr: (0, styles_1.spacing)(postIcon ? 1 : 0), ml: (0, styles_1.spacing)(preIcon ? 1 : 0) }, children)),
                postIcon &&
                    (0, monads_1.box)(Icon_1.Icon[typeof postIcon === 'string' ? postIcon : postIcon.type]).fold(Ic => (React.createElement(Ic, { size: iconSize || 16, color: postIcon.color ||
                            iconColor ||
                            getIconColor(props.variant) }))))));
    }, [props]);
    return (0, monads_1.fromNullable)(info).fold(renderButton, info => (React.createElement(Tooltip_1.Tooltip, { content: React.createElement(Typography_1.Text, { white: true, dangerouslySetInnerHTML: { __html: info } }), position: "topCenter", align: "center", variant: "dark", small: true, defaultPosition: "top" }, renderButton())));
});
