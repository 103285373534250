import { STAGE_ID } from '@basisboard/basis-common/lib/api'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Label } from '@basisboard/basis-ui/es/components/Label'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { spacing } from '@basisboard/basis-ui/es/styles'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import { Checkbox, TextOverflow } from '../../../../components'
import { StagesState } from '../../../Stages'
import { Option, Options } from './styled'

interface Val {
  stageIds?: STAGE_ID[]
  showDeleted?: boolean
  showOnlyDeleted?: boolean
  showArchived?: boolean
  showOnlyArchived?: boolean
  showBlocked?: boolean
  showDeclined?: boolean
}

interface Props {
  value: Val
  onChange: (value: Val) => void
}

export const ShowBidsOptions: React.FC<Props> = ({ value, onChange }) => {
  const stagesData = useContainer(StagesState)

  const { stages } = stagesData.state

  const toggleStage = React.useCallback(
    (stageId: STAGE_ID) => e => {
      const stageIds = value.stageIds || []

      if (e.target.checked) {
        !stageIds.includes(stageId) &&
          onChange({
            ...value,
            ...(value.showOnlyArchived ? { showArchived: true, showOnlyArchived: undefined } : {}),
            ...(value.showOnlyDeleted ? { showDeleted: true, showOnlyDeleted: undefined } : {}),
            stageIds: [...stageIds, stageId],
          })
      } else {
        const newStages = [...stageIds.filter(s => s !== stageId)]
        onChange({
          ...value,
          ...(value.showArchived ? { showOnlyArchived: newStages.length === 0 } : {}),
          ...(value.showDeleted ? { showOnlyDeleted: newStages.length === 0 } : {}),
          stageIds: newStages,
        })
      }
    },
    [value],
  )

  const toggleDeleted = e =>
    onChange({
      ...value,
      showDeleted: e.target.checked,
      showOnlyDeleted: (value.stageIds || []).length === 0 && e.target.checked,
    })

  const toggleArchived = e =>
    onChange({
      ...value,
      showArchived: e.target.checked,
      showOnlyArchived: (value.stageIds || []).length === 0 && e.target.checked,
    })

  const toggleBlocked = e =>
    onChange({
      ...value,
      showBlocked: e.target.checked,
    })

  return (
    <Div mb={spacing(2)} key={JSON.stringify(value)}>
      <Label label="Stages" />

      <Options>
        {stages.map(stage => (
          <Option key={stage.id}>
            <Checkbox
              value={(value.stageIds || []).includes(stage.id)}
              onChange={toggleStage(stage.id)}
            />
            <TextOverflow TextComponent={Text} width={100} ml={spacing(1)} lineHeight="24px">
              {stage.name}
            </TextOverflow>
          </Option>
        ))}
      </Options>

      <Label space={{ mt: spacing(2) }} label="Include bids that are" />

      <Options>
        <Option>
          <Checkbox value={value.showDeleted} onChange={toggleDeleted} />
          <Text ml={spacing(1)} lineHeight="24px">
            Deleted
          </Text>
        </Option>
        <Option>
          <Checkbox value={value.showArchived} onChange={toggleArchived} />
          <Text ml={spacing(1)} lineHeight="24px">
            Archived
          </Text>
        </Option>
        <Option>
          <Checkbox value={value.showBlocked} onChange={toggleBlocked} />
          <Text ml={spacing(1)} lineHeight="24px">
            Blocked
          </Text>
        </Option>
      </Options>
    </Div>
  )
}
