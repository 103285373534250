import { history } from '../../../config'
import { openConfirmActionModal } from '../../App'

export const openFixScraperModal = () =>
  openConfirmActionModal({
    action: async () => {
      history.push('/settings/email-accounts')
    },
    danger: false,
    prompt: "Seems like we can't connect to your email",
    actionLabel: 'Update email settings',
    description:
      "We'll need you to re-login to your email account. Do you want to be redirected to email settings?",
  })
