import React from 'react'
import { SpaceProps } from 'styled-system'
import { Count, Keyword, Wrap } from './styled'

interface Props extends SpaceProps {
  label: string
  count: number
  isMain?: boolean
  isActive?: boolean
  onClick?: () => void
}

export const KeywordTag: React.FC<Props> = ({
  label,
  count,
  isMain = false,
  isActive = false,
  onClick,
  ...space
}) => (
  <Wrap {...space} onClick={onClick} isMain={isMain} disabled={count === 0}>
    <Keyword isMain={isMain} isActive={isActive}>
      {label}
    </Keyword>
    <Count isMain={isMain} isActive={isActive}>
      {count}
    </Count>
  </Wrap>
)
