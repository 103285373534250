import { borderRadiusMixin, colors, spacing } from '@basisboard/basis-ui/es/styles'
import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const List = styled.div`
  background: ${colors.white};
  ${borderRadiusMixin('large')};
  border: 1px solid #dadce3;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  left: 0;
  max-height: 200px;
  overflow: auto;
  position: absolute;
  top: calc(100% + 4px);
  width: 100%;
  z-index: 5;
`

export const ListItem = styled.div`
  cursor: pointer;
  display: flex;
  padding: ${spacing(1)} ${spacing(2)};

  & + & {
    border-top: 1px solid ${colors.border};
  }
  &:hover {
    background-color: ${colors.lightGray3};
  }
`
