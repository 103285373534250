import { Div } from '@basisboard/basis-ui/es/components/Div'
import splitEvery from 'ramda/src/splitEvery'
import * as React from 'react'
import { Pagination } from '../Pagination'
import { BodyRow, Table as Tab, Td, Th } from './styled'

interface Value {
  [key: string]: React.ReactNode
  onClick?: () => void
}

export interface TableInfoProps {
  columns: {
    label: string
    key: keyof Value
    width?: number | string
  }[]
  values: Value[]
  totalColumns?: number
  size?: 'small' | 'medium' | 'large'
  preventPagination?: boolean
  tableHeight?: number
}

export const TableInfo: React.FC<TableInfoProps> = ({
  columns,
  values,
  preventPagination,
  size = 'small',
  tableHeight,
}) => {
  const [pageSize] = React.useState(5)
  const [, forceRerender] = React.useState(5)
  const bodyRef = React.useRef<HTMLTableSectionElement>()
  const [currentPage, setCurrentPage] = React.useState(0)

  React.useEffect(() => {
    setCurrentPage(0)
  }, [values])

  const pages = splitEvery(preventPagination ? values.length : pageSize, values)

  React.useLayoutEffect(() => {
    if (bodyRef.current) {
      forceRerender(c => c + 1)
    }
  }, [bodyRef.current])

  if (pages.length === 0) {
    return null
  }

  return (
    <Div width={1} position="relative">
      <Tab height={tableHeight}>
        <thead>
          <tr>
            {columns.map(c => (
              <Th width={c.width || 'auto'} key={`${c.key}-${c.label}`}>
                {c.label}
              </Th>
            ))}
          </tr>
        </thead>
        <tbody ref={bodyRef}>
          {(pages[currentPage] || []).map((val, index) => (
            <BodyRow
              data-testid="body-row"
              size={size}
              key={`row-${index}`}
              onClick={
                val.onClick
                  ? () => window.getSelection().type !== 'Range' && val.onClick()
                  : undefined
              }
            >
              {columns.map((c, i) => (
                <Td key={`col-${c.label}-${i}`}>{val[c.key]}</Td>
              ))}
            </BodyRow>
          ))}
        </tbody>
      </Tab>

      {pages.length > 1 && (
        <Div display="flex" alignItems="center" justifyContent="center" height={80}>
          <Pagination
            currentPage={currentPage}
            totalPages={pages.length}
            setCurrentPage={setCurrentPage}
          />
        </Div>
      )}
    </Div>
  )
}
