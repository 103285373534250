import { showErrorToast, showInfoToast } from '../../containers/Toast'
import { eventBus, EventBusType } from '../../services'

eventBus.register(EventBusType.SendEmail, () => {
  showInfoToast({
    message: 'Your message is being sent. Allow few minutes for it to be processed.',
  })
})

eventBus.register(EventBusType.SendEmailError, () => {
  showErrorToast({ message: 'Something went wront while sending your email' })
})
