"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Label = void 0;
const React = __importStar(require("react"));
const Div_1 = require("../Div");
const styles_1 = require("../../styles");
const Typography_1 = require("../Typography");
const Label = (_a) => {
    var { label, error, required, space, children } = _a, rest = __rest(_a, ["label", "error", "required", "space", "children"]);
    return (React.createElement(Div_1.Div, Object.assign({ as: "label", display: "flex", alignItems: "center", mb: (0, styles_1.spacing)(1) }, space, rest),
        React.createElement(Typography_1.HeavyText, { color: error ? styles_1.colors.error : styles_1.colors.darkGray, italic: !!error, lineHeight: "24px" },
            label,
            ' '),
        children,
        required && (React.createElement(Typography_1.HeavyText, { ml: "4px", color: styles_1.colors.error }, "*"))));
};
exports.Label = Label;
