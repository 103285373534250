import { colors, hexToRgba } from '@basisboard/basis-ui/es/styles'
import { isDarkColor } from '@basisboard/basis-ui/es/utils'
import { darken } from 'polished'

class TrackKeyPress {
  pressedKeys: string[] = []

  constructor() {
    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('keyup', this.handleKeyUp)
  }

  handleKeyDown = e => {
    this.pressedKeys.push(e.key)
  }

  handleKeyUp = e => {
    this.pressedKeys = this.pressedKeys.filter(key => key !== e.key)
  }
}

const trackPressedKeys = new TrackKeyPress()

export const isKeyPressed = (key: string) => trackPressedKeys.pressedKeys.includes(key)

export const getOptionColors = ({
  color,
  backgroundColor,
}: { color?: string; backgroundColor?: string } = {}) => {
  if (Boolean(color) && Boolean(backgroundColor)) {
    return { color, backgroundColor }
  }

  if (isDarkColor(color || '')) {
    return {
      color: color?.toLowerCase() === '#ced3d9' ? colors.gray600 : color,
      backgroundColor: backgroundColor || (color ? hexToRgba(color, 0.4) : undefined),
    }
  } else {
    return {
      color: darken(0.5, color),
      backgroundColor: backgroundColor || color,
    }
  }
}
