import {
  GetEmailScraperOptionsResponseBody,
  GetEmailScrapersResponseBody,
  PatchEmailScraperRequestBody,
  PatchEmailScraperResponseBody,
  PostEmailScraperRequestBody,
  PostEmailScraperResponseBody,
  PostEmailScrapersTokens,
} from '@basisboard/basis-common/lib/api'
import { del, get, patch, post } from '../../api'

export const getEmailScrapers = () =>
  get<GetEmailScrapersResponseBody>('/email-scrapers').then(response => response.data.emailScrapers)

export const createEmailScrapers = (data: PostEmailScraperRequestBody) =>
  post<PostEmailScraperResponseBody>('/email-scrapers', data).then(response => response.data)

export const deleteEmailScraper = (scraperId: string) => del(`/email-scrapers/${scraperId}`)

export const updateEmailScraper = (scraperId: string, data: PatchEmailScraperRequestBody) =>
  patch<PatchEmailScraperResponseBody>(`/email-scrapers/${scraperId}`, data).then(
    response => response.data.emailScraper,
  )

export const getScraperOptions = (scraperId: string) =>
  get<GetEmailScraperOptionsResponseBody>(`/email-scrapers/${scraperId}/options`).then(
    result => result.data,
  )

export const postEmailScraperToken = () =>
  post<PostEmailScrapersTokens>('/email-scrapers/tokens').then(
    response => response.data.authorizationToken,
  )
