import { Span } from '@basisboard/basis-ui/es/components/Typography'
import { colors } from '@basisboard/basis-ui/es/styles'
import { getContainer } from '@containrz/react-hook'
import isEmpty from 'ramda/src/isEmpty'
import React from 'react'
import { companyRepository } from '../../../../../Companies/container'
import { TableFieldProps } from '../../type'
import { MultiSelect } from '../MultiSelect'
import { CustomFieldSelectOption } from '../styled'

export const CompanyMultiSelect: React.FC<TableFieldProps> = props => {
  const { companyNames } = getContainer(companyRepository()).state
  const companies = React.useMemo(
    () =>
      companyNames.map(company => ({
        value: company.id,
        label: company.name,
      })),
    [companyNames],
  )

  const selectedOptions = React.useMemo(() => {
    if (!props.value) return null

    if (Array.isArray(props.value)) {
      return props.value.map(companyId => {
        const company = companies.find(u => u.value === companyId)
        return (
          <CustomFieldSelectOption key={companyId} color={colors.teal} backgroundColor="#e6f7ff">
            {company?.label}
          </CustomFieldSelectOption>
        ) // <CompanyChip key={companyId} companies={{ id: companyId, name: company?.label }} />
      })
    }

    const company = companies.find(u => u.value === props.value)
    return company ? (
      <CustomFieldSelectOption color={colors.teal} backgroundColor="#e6f7ff">
        {company?.label}
      </CustomFieldSelectOption>
    ) : null
    //<CompanyChip companies={{ id: company.value, name: company.label }} /> : null
  }, [props.value])

  return (
    <MultiSelect
      {...(props as any)}
      searchable
      field={{ ...props.field, options: companies }}
      renderOption={(option, props) => (
        <CustomFieldSelectOption {...(props as any)} color={colors.teal} backgroundColor="#e6f7ff">
          {option.label}
        </CustomFieldSelectOption>
      )}
    >
      {isEmpty(selectedOptions) || !selectedOptions ? (
        <Span color={colors.lightGray}>Not set</Span>
      ) : (
        selectedOptions
      )}
    </MultiSelect>
  )
}
