"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SideView = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const SideView = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 17 16" },
    React.createElement("path", { d: "M0 2.3125C0 1.15039 0.929688 0.1875 2.125 0.1875H14.875C16.0371 0.1875 17 1.15039 17 2.3125V12.9375C17 14.1328 16.0371 15.0625 14.875 15.0625H2.125C0.929688 15.0625 0 14.1328 0 12.9375V2.3125ZM7.4375 13.4688H14.875C15.1406 13.4688 15.4062 13.2363 15.4062 12.9375V2.3125C15.4062 2.04688 15.1406 1.78125 14.875 1.78125H7.4375V13.4688ZM2.92188 3.90625H4.51562C4.94727 3.90625 5.3125 3.57422 5.3125 3.10938C5.3125 2.67773 4.94727 2.3125 4.51562 2.3125H2.92188C2.45703 2.3125 2.125 2.67773 2.125 3.10938C2.125 3.57422 2.45703 3.90625 2.92188 3.90625ZM2.92188 5.5C2.45703 5.5 2.125 5.86523 2.125 6.29688C2.125 6.76172 2.45703 7.09375 2.92188 7.09375H4.51562C4.94727 7.09375 5.3125 6.76172 5.3125 6.29688C5.3125 5.86523 4.94727 5.5 4.51562 5.5H2.92188ZM2.92188 10.2812H4.51562C4.94727 10.2812 5.3125 9.94922 5.3125 9.48438C5.3125 9.05273 4.94727 8.6875 4.51562 8.6875H2.92188C2.45703 8.6875 2.125 9.05273 2.125 9.48438C2.125 9.94922 2.45703 10.2812 2.92188 10.2812Z", fill: color })));
exports.SideView = SideView;
