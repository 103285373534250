import { CustomFieldType } from '@basisboard/basis-common/lib/api/custom-fields'
import { Table2 } from '@basisboard/basis-ui/es/components/Table2'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors } from '@basisboard/basis-ui/es/styles'
import { changeFocusToElement, formatQuote, fromNullable } from '@basisboard/basis-ui/es/utils'
import * as React from 'react'
import styled from 'styled-components'
import { TableFieldProps } from '../../type'
import { ActionsBar } from '../ActionsBar'
import { CellArea, Wrapper } from '../styled'

const Input = styled.input`
  font-size: 0.875rem;
  color: ${colors.darkBlue};
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  font-weight: 500;

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const mapCustomFieldTypeToInputType = {
  [CustomFieldType.Monetary]: 'number',
  [CustomFieldType.Percent]: 'number',
  [CustomFieldType.Number]: 'number',
}

const mapValueToInputValue = (type: CustomFieldType, val?: number) =>
  ({
    [CustomFieldType.Monetary]: (val || 0) / 100,
  }[type] || (val === 0 ? undefined : val))

const mapValueToOnChangeValue = (type: CustomFieldType, val?: string) =>
  ({
    [CustomFieldType.Monetary]: Math.round(parseFloat(val) * 100),
    [CustomFieldType.Percent]: +val || 0,
    [CustomFieldType.Number]: +val || 0,
  }[type] || val)

const mapFormatValue = (type: CustomFieldType, val?: string) =>
  ({
    [CustomFieldType.Monetary]: formatQuote(+val),
    [CustomFieldType.Percent]: `${+val || 0}%`,
  }[type] || val)

export const TextField: React.FC<TableFieldProps> = ({
  field,
  onChange,
  value,
  wrappingElement,
  readonly,
  customActions,
  actions,
  placeholder,
  alignNumbersLeft,
  ...rest
}) => {
  const wrapperRef = React.useRef<HTMLDivElement>()
  const [editing, setEditing] = React.useState(false)

  const type = mapCustomFieldTypeToInputType[field.type] || 'text'

  const handleBlur = React.useCallback(
    e => {
      if (e.currentTarget.getAttribute('ignore') === 'true') {
        return setTimeout(() => {
          setEditing(false)
        }, 1)
      }

      const val = mapValueToOnChangeValue(field.type, e.target.value)

      if (val !== value && [Boolean(val), Boolean(value)].includes(true)) {
        onChange?.(val)
      }

      setTimeout(() => {
        setEditing(false)
      }, 1)
    },
    [value],
  )

  const renderInput = React.useCallback(
    () => (
      <Input
        style={{
          textAlign:
            rest.justifyContent === 'flex-end' || (type === 'number' && !alignNumbersLeft)
              ? 'right'
              : 'left',
        }}
        defaultValue={mapValueToInputValue(field.type, value as number)}
        placeholder={field.label}
        onBlur={handleBlur}
        autoFocus
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.currentTarget.blur()
          } else if (e.key === 'Escape') {
            e.currentTarget.setAttribute('ignore', 'true')
            e.currentTarget.blur()
          } else if (e.key === 'Tab') {
            e.preventDefault()
            e.stopPropagation()
            changeFocusToElement(e.shiftKey ? 'prev' : 'next', document.body)
          }
        }}
        type={type}
      />
    ),
    [value],
  )

  const El = wrappingElement || Text

  const hasValidValue =
    typeof value !== undefined && { string: Boolean(value), number: true }[typeof value]

  return (
    <Wrapper
      ref={wrapperRef}
      justifyContent={type === 'number' && !alignNumbersLeft ? 'flex-end' : 'flex-start'}
      {...rest}
      style={{ overflow: 'hidden' }}
    >
      {editing ? (
        fromNullable(customActions).fold(renderInput, () => (
          <Table2.ActionsWrapper width={250} {...customActions}>
            {renderInput()}
          </Table2.ActionsWrapper>
        ))
      ) : (
        <>
          <El
            style={{
              color: hasValidValue ? undefined : colors.lightGray,
            }}
          >
            {hasValidValue
              ? mapFormatValue(field.type, value as string) || field.label
              : placeholder || 'Not set'}
          </El>
          {actions && (
            <ActionsBar
              actions={actions}
              onClick={() => window.getSelection().type !== 'Range' && setEditing(true)}
              onFocus={() => setEditing(true)}
            />
          )}
          {readonly || actions ? null : (
            <CellArea
              tabIndex={0}
              onFocus={() => setEditing(true)}
              onClick={() => {
                window.getSelection().type !== 'Range' && setEditing(true)
              }}
            />
          )}
        </>
      )}
    </Wrapper>
  )
}
