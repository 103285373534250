import { getContainer } from '@containrz/react-hook'
import values from 'ramda/src/values'
import { ViewId } from '../../constants'
import { companyRepository } from '../../containers/Companies'
import { FieldsState } from '../../containers/Fields'
import { FiltersStateInstances } from '../../containers/Filters'
import { ScrapersState } from '../../containers/Scrapers'
import { SortsStateInstances } from '../../containers/Sorts'
import { StagesState } from '../../containers/Stages'
import { tradesRepository } from '../../containers/Trades'
import { UsersContainer } from '../../containers/Users'
import { eventBus, EventBusType } from '../../services'

eventBus.register(EventBusType.FirstSessionStarted, () => {
  /**
   * Onboarding logic.
   */
})

eventBus.register(EventBusType.SessionStarted, () => {
  // Load fields
  getContainer(FieldsState).loadCustomFields()
  getContainer(UsersContainer).loadUsers()

  values(ViewId).forEach(key => {
    // Load filters
    FiltersStateInstances(key).loadFilters()

    // Load sorts
    SortsStateInstances(key).initializeState()
  })

  // Load trades
  tradesRepository().loadTrades()

  // Load stages
  getContainer(StagesState).loadStages()

  // Load scrapers
  getContainer(ScrapersState).loadScrapers()

  // Load companies names
  companyRepository().loadCompaniesNames()
})
