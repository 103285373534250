import { formatDate, getUniqId } from '@basisboard/basis-ui/es/utils'
import moment from 'moment'
import keys from 'ramda/src/keys'
import values from 'ramda/src/values'
import { isDev } from '../../config'
import { log } from '../log'
import { EventBusType } from './types'

export * from './types'

const analyticsBlacklist = [
  EventBusType.LoadedFields,
  EventBusType.LoadedFilters,
  EventBusType.LoadedStages,
  EventBusType.LoadedUsers,
  EventBusType.LoadedCompanies,
  EventBusType.InboxMessageRequiresRefresh,
  EventBusType.RefreshProjects,
]

const track = (evt: any, params?: any) => {
  if (process.env.NODE_ENV === 'test') {
    return
  }

  if (isDev) {
    // eslint-disable-next-line
    console.log(evt, params ? JSON.stringify(params) : '')
  } else {
    log(evt, { ...params, dateTime: formatDate(moment()), dayOfWeek: moment().format('dddd') })
  }
}

interface State {
  subscriptions: {
    [key in EventBusType]?: {
      [key: string]: (args: any) => void
    }
  }
}

class EventBus {
  state: State = {
    subscriptions: {},
  }

  register = (type: EventBusType, listener: (data?: any) => void) => {
    if (process.env.NODE_ENV === 'test') {
      return
    }

    const identifier = getUniqId()
    this.state = {
      subscriptions: {
        ...this.state.subscriptions,
        [type]: {
          ...this.state.subscriptions[type],
          [identifier]: listener,
        },
      },
    }

    return {
      unregister: () => this.unsubscribe(type, identifier),
      id: identifier,
    }
  }

  unsubscribe = (type: EventBusType, identifier: string) =>
    (this.state = {
      subscriptions: {
        ...this.state.subscriptions,
        [type]: keys(this.state.subscriptions[type])
          .filter(key => key != identifier)
          .reduce(
            (acc, key) => ({
              ...acc,
              [key]: this.state.subscriptions[type][key],
            }),
            {},
          ),
      },
    })

  publish = (type: EventBusType, data?: any) => {
    if (!analyticsBlacklist.includes(type)) {
      track(type, data)
    }

    if (type in this.state.subscriptions) {
      values(this.state.subscriptions[type]).forEach(val => {
        val(data)
      })
    }
  }
}

export const eventBus = new EventBus()
