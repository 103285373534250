"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cloud = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Cloud = ({ width = 24, height = 24, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: size || width, height: size || height, viewBox: "0 0 24 24" },
    React.createElement("path", { d: "M18.8 11.0813C18.9281 10.7469 19 10.3812 19 10C19 8.34375 17.6562 7 16 7C15.3844 7 14.8094 7.1875 14.3344 7.50625C13.4688 6.00625 11.8531 5 10 5C7.2375 5 5 7.2375 5 10C5 10.0844 5.00312 10.1688 5.00625 10.2531C3.25625 10.8688 2 12.5375 2 14.5C2 16.9844 4.01562 19 6.5 19H18C20.2094 19 22 17.2094 22 15C22 13.0656 20.625 11.45 18.8 11.0813ZM15.2937 13H13.25V16.5C13.25 16.775 13.025 17 12.75 17H11.25C10.975 17 10.75 16.775 10.75 16.5V13H8.70625C8.25938 13 8.0375 12.4625 8.35313 12.1469L11.6469 8.85312C11.8406 8.65937 12.1594 8.65937 12.3531 8.85312L15.6469 12.1469C15.9625 12.4625 15.7375 13 15.2937 13Z", fill: color })));
exports.Cloud = Cloud;
