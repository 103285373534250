import {
  COMPANY_ID,
  CONTACT_ID,
  GlobalSearchCompany,
  GlobalSearchContact,
  GlobalSearchProject,
  MESSAGE_ID,
  PROJECT_ID,
} from '@basisboard/basis-common/lib/api'
import { GlobalSearchEmail } from '@basisboard/basis-common/lib/api/email'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { DetailText, Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import { box, fromBoolean } from '@basisboard/basis-ui/es/utils'
import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { showWarningToast } from '../../../containers/Toast'
import { MESSAGE_ID_SEARCH_KEY } from '../../Emails/contants'

const Wrapper = styled(Link)`
  height: 64px;
  width: 100%;
  background: ${colors.white};
  padding: 0 16px;
  display: flex;
  align-items: center;

  ${transitionMixin};

  &:hover {
    background: #f9fafb;
  }
`

type Props = (
  | GlobalSearchContact
  | GlobalSearchCompany
  | GlobalSearchProject
  | GlobalSearchEmail
) & {
  onClick: () => void
}

const getSubtitleForType = (
  type: 'company' | 'project' | 'contact' | 'email',
  props: Partial<
    GlobalSearchContact | GlobalSearchCompany | GlobalSearchProject | GlobalSearchEmail
  >,
) => {
  const TEXT_FOR_TYPE = {
    company: (props as GlobalSearchCompany).address,
    project: box(props as GlobalSearchProject).fold(p =>
      fromBoolean(p.fromSubjectQuery).fold(
        () => p.location?.substring(0, 100),
        () => 'Matching email found in this project',
      ),
    ),
    contact: (props as GlobalSearchContact).companyName,
    email: (props as GlobalSearchEmail).fromEmail,
  }

  return TEXT_FOR_TYPE[type]
}

const BASE_URL_FOR_TYPE = {
  company: (_: Partial<GlobalSearchCompany>, companyId: COMPANY_ID) => `/companies/${companyId}`,
  project: (_: Partial<GlobalSearchProject>, projectId: PROJECT_ID) => `/projects/${projectId}`,
  contact: (_: Partial<GlobalSearchContact>, contactId: CONTACT_ID) => `/people/${contactId}`,
  email: (rest: Partial<GlobalSearchEmail>, messageId: MESSAGE_ID) =>
    rest.projects[0]
      ? `/projects/${rest.projects[0].id}?tab=emails&${MESSAGE_ID_SEARCH_KEY}=${messageId}`
      : window.location.href,
}

const ICONS_FOR_TYPE = {
  company: <Icon.Building size={16} color={colors.gray} />,
  project: <Icon.DollarMark size={16} color={colors.gray} />,
  contact: <Icon.User size={16} color={colors.gray} />,
  email: <Icon.Email size={16} color={colors.gray} />,
}

const ONCLICK_FOR_TYPE = {
  company: () => null,
  project: () => null,
  contact: () => null,
  email: (rest: Partial<GlobalSearchEmail>) => {
    if (!rest.projects[0]) {
      showWarningToast({ message: 'This email is not linked to any bid.' })
    }
  },
}

export const SearchResult: React.FC<Props> = ({ id, type, onClick, ...rest }) => (
  <Wrapper
    to={BASE_URL_FOR_TYPE[type](rest, id)}
    onClick={() => {
      ONCLICK_FOR_TYPE[type](rest)
      onClick()
    }}
  >
    <Div display="flex" alignItems="center" width={1}>
      <Div
        size={24}
        borderRadius={24}
        background="#e6eaed"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {ICONS_FOR_TYPE[type]}
      </Div>
      <Div width={1} ml={spacing(1.5)} display="flex" flexDirection="column" overflow="hidden">
        <Text>{(rest as GlobalSearchProject).name || (rest as GlobalSearchEmail).subject}</Text>
        <DetailText>{getSubtitleForType(type, rest)}</DetailText>
      </Div>
    </Div>
  </Wrapper>
)
