import {
  FilterEntity,
  FilterIdenfier,
  FilterOperation,
  FilterRoot,
  GetInboxMessagesRequestQuery,
} from '@basisboard/basis-common/lib/api'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { ButtonGroup } from '@basisboard/basis-ui/es/components/ButtonGroup'
import { Card } from '@basisboard/basis-ui/es/components/Card'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { useEscapeKey } from '@basisboard/basis-ui/es/hooks/useEscapeKey'
import { useOutsideClick } from '@basisboard/basis-ui/es/hooks/useOutsideClick'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import { Filter } from '../../../../templates/ViewsScreen/types'
import { getNewCondition, mapConditionsToFilterRoot, mapFilterRootToCondition } from '../../helpers'
import { FilterCondition } from '../../types'
import { ConditionEntry } from '../ConditionEntry'
import { ConditionEntity } from '../ConditionEntry/types'

const canSubmitFilter = (f: FilterEntity): boolean =>
  typeof f.operation === 'number' &&
  Boolean(f.filterIdentitier.identifier) &&
  Boolean(f.filterIdentitier.entity) &&
  (f.operation === FilterOperation.Empty ? true : Boolean(f.compareValue))

interface Props {
  currentFilter: FilterRoot
  enabledEntities?: ConditionEntity[]
  enabledFields?: { [key in ConditionEntity]?: FilterIdenfier[] }
  allowCustomFields?: boolean
  onChange: (v: Partial<Filter> | null) => void
  clearOtherKeys?: boolean
  viewDefaultFilter?: Filter
}

export const DropdownFilters: React.FC<Props> = ({
  currentFilter,
  enabledEntities = [ConditionEntity.Project, ConditionEntity.Company],
  enabledFields,
  onChange,
  allowCustomFields,
  viewDefaultFilter,
}) => {
  const [showDropdown, setShowDropdown] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>()

  const [conditions, setConditions] = React.useState<FilterCondition[]>(
    currentFilter?.and.length > 0 ? mapFilterRootToCondition(currentFilter) : [getNewCondition()],
  )

  useOutsideClick([ref], () => setShowDropdown(false))
  useEscapeKey(() => setShowDropdown(false))

  React.useEffect(() => {
    setConditions(
      currentFilter.and.length > 0 ? mapFilterRootToCondition(currentFilter) : [getNewCondition()],
    )
  }, [JSON.stringify(currentFilter), showDropdown])

  const handleSetCondition = React.useCallback(
    (index, key, val) => {
      setConditions(conds =>
        conds.map((c, i) =>
          i === index
            ? { ...c, [key]: val, ...(key === 'fieldId' ? { fieldValue: null } : {}) }
            : c,
        ),
      )
    },
    [setConditions],
  )

  const handleDeleteCondition = React.useCallback(
    index => {
      if (conditions.length === 1) {
        setConditions([getNewCondition()])
        // eslint-disable-next-line
        // @ts-ignore
        onChange({ ...viewDefaultFilter, customFilter: viewDefaultFilter?.customFilter })
      } else {
        setConditions(conds => conds.map((c, i) => (i === index ? null : c)).filter(c => c))
      }
    },
    [setConditions, conditions],
  )

  const handleSubmit = React.useCallback(() => {
    const customFilter = {
      and: [
        ...((viewDefaultFilter as GetInboxMessagesRequestQuery['filter'])?.customFilter?.and || []),
        ...(mapConditionsToFilterRoot(conditions) || []),
      ],
      or: [],
    }

    onChange({ customFilter })
    setShowDropdown(false)
  }, [conditions])

  const canSubmit =
    conditions.length > 0 &&
    mapConditionsToFilterRoot(conditions).reduce((acc, c) => acc && canSubmitFilter(c), true)

  return (
    <Div position="relative">
      <Button.Text
        postIcon="Arrow"
        onClick={() => setShowDropdown(true)}
        {...(mapFilterRootToCondition(currentFilter).length > 0
          ? { color: colors.accent, iconColor: colors.accent }
          : {})}
      >
        Filters
      </Button.Text>

      {showDropdown && (
        <Card
          width={736}
          p={spacing(2)}
          position="absolute"
          top="calc(100% + 4px)"
          right={0}
          zIndex={20}
          ref={ref}
          arrowPosition="top"
          arrowOffset={350}
        >
          <Div mb={spacing(2)}>
            {conditions.map((c, i) => (
              <>
                <ConditionEntry
                  {...c}
                  width={704}
                  enabledEntities={enabledEntities}
                  onSetCondition={(key, val) => handleSetCondition(i, key, val)}
                  onDelete={() => handleDeleteCondition(i)}
                  enabledFields={enabledFields}
                  allowCustomFields={allowCustomFields}
                />

                {i + 1 < conditions.length && (
                  <Div my={spacing(1)} width={88}>
                    <Text>And</Text>
                  </Div>
                )}
              </>
            ))}
          </Div>
          <ButtonGroup justifyContent="space-between">
            <Button.Text
              mt={spacing(2)}
              onClick={() => setConditions(c => [...c, getNewCondition()])}
            >
              + Add condition
            </Button.Text>
            <Button.Primary disabled={!canSubmit} onClick={handleSubmit}>
              Apply Filters
            </Button.Primary>
          </ButtonGroup>
        </Card>
      )}
    </Div>
  )
}
