import { Div } from '@basisboard/basis-ui/es/components/Div'
import { DetailText, HeavyText } from '@basisboard/basis-ui/es/components/Typography'
import { spacing } from '@basisboard/basis-ui/es/styles'
import times from 'ramda/src/times'
import * as React from 'react'
import styled from 'styled-components'
import { height, HeightProps } from 'styled-system'
import { Tbody, Th } from '../styled'
import { Row } from './Row'

const GroupRow = styled.tr`
  height: 40px;

  cursor: pointer;

  & > td,
  & > th {
    border-bottom: 1px solid #eaebee;
    border-top: 1px solid #eaebee;
    background: #f9fafb;
  }
`

const SpacerRow = styled.tr<HeightProps>`
  ${height};
`

export const Group = ({
  group,
  columns,
  dataSource,
  onClickRow,
  onShowContextMenu,
  hoverResizingIndex,
  resizingColumnIndex,
}) => {
  const [isOpen] = React.useState(true)

  if (dataSource.length === 0) {
    return null
  }

  return (
    <>
      <Tbody style={{ borderSpacing: spacing(2) }}>
        <SpacerRow height={16} />
        <GroupRow>
          <Th isHover={false} style={{ padding: 0, height: 40 }}>
            <Div display="flex" alignItems="center">
              {typeof group.renderer === 'function' ? (
                group.renderer()
              ) : (
                <HeavyText ml={spacing(2)} preventClip style={{ whiteSpace: 'nowrap' }}>
                  {group.renderer || group.label} <DetailText>{dataSource.length} bids</DetailText>
                </HeavyText>
              )}
            </Div>
          </Th>
          {group.columns
            ? group.columns.slice(1).map((col, index) => (
                <td style={{ paddingLeft: spacing(2) }} key={`col-${index}`}>
                  {col && col(dataSource)}
                </td>
              ))
            : times(i => <td key={i} />, columns.length - 1)}
        </GroupRow>
        <SpacerRow height={8} />
      </Tbody>
      {isOpen && (
        <Tbody>
          {dataSource.map(data => (
            <Row
              key={data.key}
              data={data}
              columns={columns}
              onShowContextMenu={onShowContextMenu}
              onClickRow={onClickRow}
              hoverResizingIndex={hoverResizingIndex}
              resizingColumnIndex={resizingColumnIndex}
            />
          ))}
        </Tbody>
      )}
    </>
  )
}
