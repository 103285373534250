"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Archive = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Archive = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M2.16634 13.0003C2.16634 13.4613 2.53874 13.8337 2.99967 13.8337H12.9997C13.4606 13.8337 13.833 13.4613 13.833 13.0003V5.50033H2.16634V13.0003ZM6.33301 7.47949C6.33301 7.30762 6.47363 7.16699 6.64551 7.16699H9.35384C9.52572 7.16699 9.66634 7.30762 9.66634 7.47949V7.68783C9.66634 7.8597 9.52572 8.00033 9.35384 8.00033H6.64551C6.47363 8.00033 6.33301 7.8597 6.33301 7.68783V7.47949ZM13.833 2.16699H2.16634C1.7054 2.16699 1.33301 2.53939 1.33301 3.00033V4.25033C1.33301 4.47949 1.52051 4.66699 1.74967 4.66699H14.2497C14.4788 4.66699 14.6663 4.47949 14.6663 4.25033V3.00033C14.6663 2.53939 14.2939 2.16699 13.833 2.16699Z", fill: color })));
exports.Archive = Archive;
