import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Card } from '@basisboard/basis-ui/es/components/Card'
import { SafeWrapper } from '@basisboard/basis-ui/es/components/SafeWrapper'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { borderRadiusMixin, colors, spacing } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled from 'styled-components'
import { Backdrop, Editor } from '../../../../../components'
import { KEYCODE_ESC } from '../../../../../constants'

const TextWrapper = styled(Text)`
  padding: 0 ${spacing(1)};

  height: 24px;
  line-height: 24px;

  p {
    margin: 0;
  }

  display: inline;
  white-space: nowrap;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  max-width: 100%;
  height: 100%;
  max-height: 40px;

  border: 1px dotted transparent;
  ${borderRadiusMixin};
  padding-right: ${spacing(1)};

  & > button,
  & > .add-text {
    visibility: hidden;
  }

  &:hover {
    border-color: ${colors.accent};

    & > button,
    & > .add-text {
      visibility: visible;

      &:hover path {
        stroke: ${colors.accent};
      }
    }
  }
`

interface Props {
  value: string
  onChange: (val: string) => void
  label: string
}

export const RichTextType: React.FC<Props> = ({ value, label, onChange }) => {
  const [isEditing, setEditing] = React.useState(false)
  const [text, setText] = React.useState(value)
  const wrapperRef = React.useRef<HTMLDivElement>()

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.keyCode === KEYCODE_ESC) {
        setText(value)
      }
    },
    [setText],
  )

  const handleBlur = React.useCallback(() => {
    if (text !== value) {
      onChange(text)
    }
  }, [text, value])

  return (
    <Wrapper
      ref={wrapperRef}
      onClick={e => {
        e.stopPropagation()
        setEditing(true)
      }}
    >
      {value ? (
        <TextWrapper dangerouslySetInnerHTML={{ __html: value }} />
      ) : (
        <Text className="add-text" pl={spacing(1)} lineHeight="40px" color={colors.mediumGray}>
          Add {label?.toLowerCase()}
        </Text>
      )}

      <Button.Transparent
        ml="auto"
        onClick={e => {
          e.stopPropagation()
          setEditing(true)
        }}
        postIcon="PencilHollow"
      />

      <SafeWrapper
        target={wrapperRef.current}
        align="left"
        defaultPosition="bottom"
        show={isEditing}
      >
        <Backdrop onClick={() => setEditing(false)} />

        <Card width={500} height={260} style={{ transform: 'translateY(-48px)' }}>
          <Editor
            onKeyDown={handleKeyDown}
            height={260}
            defaultValue={text}
            onChange={val => setText(val)}
            onBlur={handleBlur}
            allowMentions
          />
        </Card>
      </SafeWrapper>
    </Wrapper>
  )
}
