import {
  COMPANY_ID,
  CONTACT_ID,
  Note,
  NoteCreate,
  NoteUpdate,
  NOTE_ID,
  PROJECT_ID,
  QUOTE_ID,
} from '@basisboard/basis-common/lib/api'
import { Container } from '@containrz/react-hook'
import moment from 'moment'
import pickAll from 'ramda/src/pickAll'
import { eventBus, EventBusType } from '../../services'
import { whoami } from '../App'
import { deleteNote, getNotes, patchNote, postNote } from './api'
import { NoteEntityType } from './types'

export * from './api'
export * from './types'

export interface NotesContainerState {
  entity: NoteEntityType
  entityId: PROJECT_ID | CONTACT_ID | COMPANY_ID | QUOTE_ID
  notes: Note[]
  loading: boolean
}

export class NotesContainer extends Container<NotesContainerState> {
  state = {
    entity: null,
    entityId: null,
    notes: [] as Note[],
    loading: false,
  }

  loadNotes = (entity: NoteEntityType, entityId: string, silent = false) => {
    this.setState({ loading: !silent, entity, entityId })

    return getNotes(entity, entityId).then(notes => this.setState({ notes, loading: false }))
  }

  updateNote = (noteId: string, values: NoteUpdate) => {
    this.setState({
      notes: this.state.notes.map(note => (note.id === noteId ? note : { ...note, values })),
    })
  }

  createNote = (note: NoteCreate) => {
    const { entity, entityId } = this.state

    eventBus.publish(EventBusType.CreateNote, { ...note, entity, entityId })

    return postNote(entity, entityId, note)
      .then(createdNote => {
        const { name, id } = whoami()

        this.setState(s => ({
          notes: [
            {
              ...createdNote,
              author: { name, id },
              createdAt: moment().toISOString(),
            } as Note,
            ...s.notes,
          ],
        }))
      })
      .then(() => this.loadNotes(entity, entityId, true))
  }

  deleteNote = (noteId: NOTE_ID) => {
    this.setState(s => ({ notes: s.notes.filter(n => n.id !== noteId) }))

    const { entity, entityId } = this.state

    eventBus.publish(EventBusType.DeleteNote, { noteId, entity, entityId })

    deleteNote(noteId)
  }

  patchNote = (note: Note) => {
    const { entity, entityId } = this.state
    eventBus.publish(EventBusType.EditNote, { note, entity, entityId })

    return patchNote(note.id, pickAll(['message', 'sticky', 'mentions'], note)).then(
      ({ data: { note: patchedNote } }) => {
        this.setState(s => ({
          notes: s.notes.map(n => (n.id === note.id ? patchedNote : n)),
        }))
      },
    )
  }
}
