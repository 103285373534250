import values from 'ramda/src/values'
import * as React from 'react'
import { ViewId } from '../../constants'
import { screens } from '../../screens'

export interface Route {
  name: string
  tag?: React.ReactNode
  path?: string
  submenu?: Route[]
}

export const NAVBAR_INFO: { [key: string]: Route } = {
  [ViewId.InboxViewId]: {
    name: 'Inbox',
    path: screens.inboxList.path,
  },

  [ViewId.ListViewId]: {
    name: 'Bids',
    submenu: [
      {
        name: 'List',
        path: screens.projectsList.path,
      },
      {
        name: 'Board',
        path: screens.board.path,
      },
      {
        name: 'Calendar',
        path: screens.calendar.path,
      },
    ],
  },

  [ViewId.AnalyticsViewId]: {
    name: 'Analytics',
    submenu: [
      {
        name: 'Analytics',
        path: screens.analytics.path,
      },
      {
        name: 'Reports',
        path: screens.reports.path,
      },
    ],
  },

  [ViewId.TasksViewId]: {
    name: 'Tasks',
    path: screens.tasksList.path,
  },

  [ViewId.ContactsViewId]: {
    name: 'Contacts',
    submenu: [
      {
        name: 'People',
        path: screens.contacts.path,
      },
      {
        name: 'Companies',
        path: screens.companies.path,
      },
    ],
  },
}

export const BASIS_LITE_NAVBAR_INFO: { [key: string]: Route } = {
  [ViewId.InboxViewId]: {
    name: 'Inbox',
    path: screens.inboxList.path,
  },

  [ViewId.ListViewId]: {
    name: 'List',
    path: screens.projectsList.path,
  },

  [ViewId.BoardViewId]: {
    name: 'Board',
    path: screens.board.path,
  },

  [ViewId.CalendarViewId]: {
    name: 'Calendar',
    path: screens.calendar.path,
  },
}

export const FEATURES_ROUTES = values(screens)
  .filter(s => Boolean(s.requiresFeature))
  .map(s => ({
    path: s.path,
    feature: s.requiresFeature,
  }))

export const HIDDEN_ROUTES = []

export const ADMIN_ROUTES = [ViewId.AnalyticsViewId]
