"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Hashtag = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Hashtag = ({ width = 20, height = 20, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 20 20" },
    React.createElement("path", { d: "M17.0526 7.59467L17.2852 6.29259C17.3279 6.05317 17.1438 5.83331 16.9006 5.83331H14.4654L14.9414 3.16759C14.9841 2.92817 14.8001 2.70831 14.5569 2.70831H13.2342C13.045 2.70832 12.8829 2.84398 12.8497 3.03029L12.3491 5.83331H9.13835L9.61436 3.16759C9.65713 2.92817 9.47305 2.70831 9.22982 2.70831H7.90716C7.71791 2.70832 7.55589 2.84398 7.52263 3.03029L7.02207 5.83331H4.44733C4.25808 5.83332 4.09606 5.96898 4.0628 6.15529L3.83028 7.45737C3.78754 7.69679 3.97162 7.91665 4.21481 7.91665H6.65004L5.90599 12.0833H3.33125C3.142 12.0833 2.97998 12.219 2.94672 12.4053L2.7142 13.7074C2.67146 13.9468 2.85554 14.1666 3.09873 14.1666H5.53396L5.05795 16.8324C5.01521 17.0718 5.19929 17.2916 5.44248 17.2916H6.76514C6.95439 17.2916 7.11642 17.156 7.14968 16.9697L7.65026 14.1666H10.861L10.385 16.8324C10.3422 17.0718 10.5263 17.2916 10.7695 17.2916H12.0922C12.2814 17.2916 12.4435 17.156 12.4767 16.9697L12.9773 14.1666H15.552C15.7413 14.1666 15.9033 14.031 15.9366 13.8447L16.1691 12.5426C16.2118 12.3032 16.0277 12.0833 15.7845 12.0833H13.3493L14.0934 7.91665H16.6681C16.8574 7.91664 17.0194 7.78098 17.0526 7.59467ZM11.233 12.0833H8.0223L8.76634 7.91665H11.9771L11.233 12.0833Z", fill: color })));
exports.Hashtag = Hashtag;
