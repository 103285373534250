import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors } from '@basisboard/basis-ui/es/styles'
import { Draggable } from 'react-beautiful-dnd'
import { Toggle } from '../../../../../components'

const FieldsItem = ({ field, index, onChange, disabled }) => (
  <Draggable draggableId={field.id} index={index} isDragDisabled={disabled}>
    {provided => (
      <Div
        ref={provided.innerRef}
        {...provided.draggableProps}
        background={colors.white}
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        paddingY="8px"
        width={1}
      >
        <Div alignItems="center" display="flex">
          <Div {...provided.dragHandleProps} alignItems="center" display="flex" marginRight="8px">
            <Icon.DragHandle color="#bdbdbd" />
          </Div>
          <Div maxWidth={160}>
            <Text>{field.name}</Text>
          </Div>
        </Div>
        <Toggle
          value={field.isEnabled}
          onChange={e => onChange(e.target.checked)}
          disabled={disabled}
        />
      </Div>
    )}
  </Draggable>
)

export default FieldsItem
