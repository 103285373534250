import { GetProfileResponse } from '@basisboard/basis-common/lib/api'
import { config } from '../config'

export const bootIntercom = ({
  name,
  email,
  trial,
  position,
  customerWorkspace,
  flags,
}: GetProfileResponse) => {
  if (!process.env.PERCY_TOKEN) {
    window.Intercom('boot', {
      app_id: config.INTERCOM_APP_ID,
      name: name,
      email: email,
      created_at: Date.now(),
      trial,
      position,
      workspace: customerWorkspace,
      ...flags,
    })
  }
}

export const shutdownIntercom = () => window.Intercom('shutdown')

export const hideIntercom = () =>
  window.Intercom('update', {
    hide_default_launcher: true,
  })

export const showIntercom = () =>
  window.Intercom('update', {
    hide_default_launcher: false,
  })

export const logIntercom = (evt: any, params?: any) => window.Intercom('trackEvent', evt, params)

export const openIntercom = () => window.Intercom('showNewMessage')
