"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Back = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Back = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M8.88538 12.9242L8.30725 13.5023C8.06246 13.7471 7.66663 13.7471 7.42444 13.5023L2.36194 8.44238C2.11715 8.19759 2.11715 7.80176 2.36194 7.55957L7.42444 2.49707C7.66923 2.25228 8.06506 2.25228 8.30725 2.49707L8.88538 3.0752C9.13277 3.32259 9.12756 3.72624 8.87496 3.96842L5.73694 6.95801H13.2213C13.5677 6.95801 13.8463 7.23665 13.8463 7.58301V8.41634C13.8463 8.7627 13.5677 9.04134 13.2213 9.04134H5.73694L8.87496 12.0309C9.13017 12.2731 9.13538 12.6768 8.88538 12.9242Z", fill: color })));
exports.Back = Back;
