import { colors } from '@basisboard/basis-ui/es/styles'
import styled, { css } from 'styled-components'
import { space, SpaceProps, width, WidthProps } from 'styled-system'

type Variant = 'default' | 'danger' | 'dropdown'

export const ButtonText = styled.button.attrs({ type: 'button' })<
  SpaceProps & WidthProps & { variant?: Variant }
>`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 16px;
  padding: 0;

  display: flex;
  align-items: center;
  letter-spacing: 0.001em;

  ${({ variant = 'default' }) => css`
    color: ${variant === 'danger'
      ? colors.error
      : variant === 'dropdown'
      ? colors.darkBlue
      : colors.accent};
  `}

  &:disabled {
    cursor: not-allowed;
    color: ${colors.accent}99;
  }

  ${space};
  ${width};
`
