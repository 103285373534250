import { CustomFieldEntity, CustomFieldType } from '@basisboard/basis-common/lib/api/custom-fields'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { IconType } from '@basisboard/basis-ui/es/components/Icon'
import { Tooltip } from '@basisboard/basis-ui/es/components/Tooltip'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { box, capitalize } from '@basisboard/basis-ui/es/utils'
import { getContainer } from '@containrz/core'
import * as React from 'react'
import { NewCircle } from '../../../../../../components'
import { NestedProject } from '../../../../../../types'
import {
  NameWrapper,
  TagCircle,
  TagContent,
} from '../../../../../Projects/components/ListEntry/components/Name/styled'
import { Fields, FieldsState } from '../../../../../Fields'
import { NoteEntityType, openNotesDrawer } from '../../../../../Notes'

interface Props {
  project: NestedProject
  onReadNotes: () => void
  onChange: (name: string) => void
}

export const Name: React.FC<Props> = ({ project, onChange, onReadNotes }) => {
  const tag = React.useMemo(
    () =>
      box(getContainer(FieldsState).getCustomFieldsForEntity(CustomFieldEntity.Project))
        .map(customFields => customFields.find(cf => cf.isEntityLabel))
        .fold(label =>
          label
            ? getContainer(FieldsState).getCustomFieldTagOptionForValue(
                CustomFieldEntity.Project,
                project.customFields[label.id],
              )
            : undefined,
        ),
    [JSON.stringify(project.customFields)],
  )

  const actions = React.useMemo(
    () => [
      ...(tag && tag.label !== 'No tag'
        ? [
            {
              children: (
                <Tooltip
                  variant="light"
                  position="topCenter"
                  content={<TagContent background={tag.color}>{tag.label}</TagContent>}
                  defaultPosition="top"
                  small
                >
                  <TagCircle data-testid="list-tag" background={tag.color} />
                </Tooltip>
              ),
            },
          ]
        : []),
      project.unreadNotes
        ? {
            icon: 'Notes' as IconType,
            content: project.unreadNotes?.toString() || '',
            info: 'See unread notes',
            showOnHover: false,
            notHoverBackground: colors.white,
            onAction: () => {
              openNotesDrawer({ entity: NoteEntityType.PROJECTS, entityId: project.id })
              onReadNotes()
            },
          }
        : {
            icon: 'Notes' as IconType,
            content: project.totalNotes?.toString() || '',
            info: 'See discussion',
            showOnHover: true,
            onAction: () =>
              openNotesDrawer({ entity: NoteEntityType.PROJECTS, entityId: project.id }),
          },
    ],
    [tag],
  )

  return (
    <NameWrapper>
      {project.archivedAt ? (
        <NewCircle background="#FFAD32" content="Archived" />
      ) : (
        project.changeState && <NewCircle content={capitalize(project.changeState)} />
      )}

      <Div
        width={1}
        ml={
          [Boolean(project.archivedAt), Boolean(project.changeState)].includes(true)
            ? spacing(0.5)
            : undefined
        }
      >
        <Fields.TableField
          actions={actions}
          field={{ label: 'Name', type: CustomFieldType.Text }}
          onChange={onChange}
          value={project.name}
        />
      </Div>
    </NameWrapper>
  )
}
