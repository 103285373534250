"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wrapper = exports.ContentCloseBar = exports.Content = exports.Backdrop = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const styled_system_1 = require("styled-system");
const styles_1 = require("../../styles");
const constants_1 = require("../../styles/constants");
const monads_1 = require("../../utils/monads");
exports.Backdrop = styled_components_1.default.div `
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.45);
`;
exports.Content = styled_components_1.default.div `
  ${styled_system_1.width};
  position: absolute;
  bottom: 0;
  top: 56px;
  background-color: ${styles_1.colors.white};
  border-radius: 4px 0 0 0;
  padding: ${({ noPadding }) => (noPadding ? 0 : (0, styles_1.spacing)(5))} 0;

  overflow: auto;
`;
exports.ContentCloseBar = styled_components_1.default.button `
  position: absolute;
  top: 16px;
  right: 16px;
`;
exports.Wrapper = styled_components_1.default.div(({ openFrom }) => (0, monads_1.box)(openFrom === 'left').fold(fromLeft => (0, styled_components_1.css) `
      position: fixed;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;

      z-index: ${constants_1.ZIndexValues.Drawer};

      ${exports.Backdrop} {
        ${(0, styles_1.transitionMixin)('slow', 'opacity')};
      }

      ${exports.Content} {
        ${fromLeft ? 'right: 100%' : 'left: 100%'};

        ${(0, styles_1.transitionMixin)('slow', 'transform')};
      }

      @keyframes fadeOut {
        99.999% {
          visibility: visible;
        }
        100% {
          visibility: hidden;
        }
      }

      &[aria-hidden='true'] {
        animation: fadeOut 500ms forwards;

        ${exports.Backdrop} {
          opacity: 0;
        }

        ${exports.Content} {
          transform: translateX(0);
        }
      }

      &[aria-hidden='false'] {
        visibility: visible;

        ${exports.Backdrop} {
          opacity: 1;
        }

        ${exports.Content} {
          transform: translateX(${fromLeft ? '100%' : '-100%'});
        }
      }
    `));
