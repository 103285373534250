import {
  GetPlatformCredentialsResponseBody,
  MissingPlatformCredentialsResponseBody,
  PatchPlatformCredentialsRequestBody,
  PlatformCredential,
  PostPlatformCredentialsRequestBody,
} from '@basisboard/basis-common/lib/api'
import { del, get, patch, post } from '../../api'

export const getPlatforms = () =>
  get<GetPlatformCredentialsResponseBody>('/platform-credentials').then(
    response => response.data.credentials,
  )

export const postPlatform = (body: PostPlatformCredentialsRequestBody) =>
  post<PlatformCredential>('/platform-credentials', body).then(response => response.data)

export const deletePlatform = (platformId: string) => del(`/platform-credentials/${platformId}`)

export const patchPlatform = (platformId: string, body: PatchPlatformCredentialsRequestBody) =>
  patch<PlatformCredential>(`/platform-credentials/${platformId}`, body).then(
    response => response.data,
  )

export const getMissingPlatforms = () =>
  get<MissingPlatformCredentialsResponseBody>('/platform-credentials/missing-platforms').then(
    response => response.data.platforms,
  )
