"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DollarMark = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const DollarMark = ({ width = 16, height = 16, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M9.69794 7.41145L6.88544 6.58854C6.55992 6.49479 6.33336 6.1901 6.33336 5.85156C6.33336 5.42708 6.67711 5.08333 7.10159 5.08333H8.82815C9.14586 5.08333 9.45836 5.17968 9.71878 5.35677C9.87763 5.46354 10.0912 5.4375 10.2266 5.30468L11.1328 4.41927C11.3177 4.23958 11.2917 3.9401 11.086 3.78124C10.4479 3.28124 9.65107 3.0026 8.83336 2.99999V1.74999C8.83336 1.52083 8.64586 1.33333 8.41669 1.33333H7.58336C7.35419 1.33333 7.16669 1.52083 7.16669 1.74999V2.99999H7.10159C5.44274 2.99999 4.1094 4.42447 4.26305 6.11458C4.37242 7.3151 5.28909 8.29166 6.44534 8.6302L9.11461 9.41145C9.44013 9.50781 9.66669 9.80989 9.66669 10.1484C9.66669 10.5729 9.32294 10.9167 8.89847 10.9167H7.1719C6.85419 10.9167 6.54169 10.8203 6.28128 10.6432C6.12242 10.5365 5.90888 10.5625 5.77347 10.6953L4.86722 11.5807C4.68232 11.7604 4.70836 12.0599 4.91409 12.2187C5.55211 12.7187 6.34899 12.9974 7.16669 13V14.25C7.16669 14.4792 7.35419 14.6667 7.58336 14.6667H8.41669C8.64586 14.6667 8.83336 14.4792 8.83336 14.25V12.9948C10.0469 12.9713 11.1849 12.25 11.586 11.1016C12.1459 9.49739 11.2058 7.85156 9.69794 7.41145Z", fill: color })));
exports.DollarMark = DollarMark;
