import {
  Company,
  CompanyGroupValues,
  ProjectGroupValues,
  Proposal,
  ProposalGroupValues,
} from '@basisboard/basis-common/lib/api'
import { CustomFieldType } from '@basisboard/basis-common/lib/api/custom-fields'
import { isUuid, readableName } from '@basisboard/basis-ui/es/utils'
import { getContainer } from '@containrz/react-hook'
import { NestedProject } from '../../types'
import { companyRepository } from '../Companies'
import { FieldsState } from '../Fields'
import { StagesState } from '../Stages'
import { TradesContainer } from '../Trades'
import { UsersContainer } from '../Users'

export const createGroups = (
  group:
    | ProjectGroupValues['groupType']
    | CompanyGroupValues['groupType']
    | ProposalGroupValues['groupType'],
): {
  getName: (groupId: string) => string
  manipulateValue?: (
    groupId: string,
    value: NestedProject | Company | Proposal,
  ) => NestedProject | Company | Proposal
} => {
  switch (group) {
    case 'company':
      return {
        getName: (companyId: string) =>
          companyRepository().getCompanyNamesById([companyId])[0]?.name || 'Unidentified company',
        manipulateValue: (companyId: string, val: NestedProject) =>
          isUuid(companyId)
            ? 'bidInviteIds' in val
              ? { ...val, bidInvites: val.bidInvites.filter(bi => bi.companyId === companyId) }
              : val
            : val,
      }

    case 'contact':
      return {
        getName: (stageId: string) => getContainer(StagesState).getStageForId(stageId).name,
      }

    case 'date':
      return {
        getName: (date: string) => date,
      }

    case 'estimator':
      return {
        getName: (userId: string) =>
          readableName(
            getContainer(UsersContainer).state.allUsers.find(u => u.id === userId) || {
              firstName: 'Unknown',
              lastName: 'User',
            },
          ),
      }

    case 'stage':
      return {
        getName: (stageId: string) =>
          getContainer(StagesState).getStageForId(stageId)?.name || 'Undefined stage',
      }

    case 'region':
      return {
        getName: (region: string) => region,
      }

    case 'trade':
      return {
        getName: (tradeId: string) =>
          isUuid(tradeId) ? getContainer(TradesContainer).getTradeNameById(tradeId) : tradeId,
      }

    default:
      const field = getContainer(FieldsState).state.customFields.find(c => c.id === group)

      if (!field) {
        return {
          getName: () => 'Unknown',
        }
      }

      switch (field.type) {
        case CustomFieldType.CompanyMultiSelect:
          return {
            getName: (value: string) =>
              Array.isArray(value)
                ? companyRepository()
                    .getCompanyNamesById(value)
                    .filter(Boolean)
                    .map(c => c.name)
                    .join(', ')
                : companyRepository().getCompanyNamesById([value])[0]?.name || 'Unselected',
          }

        case CustomFieldType.UserMultiSelect:
          return {
            getName: (value: string) =>
              Array.isArray(value)
                ? value
                    .map(
                      v =>
                        readableName(
                          getContainer(UsersContainer).state.allUsers.find(u => u.id === v) || {
                            firstName: 'Unknown',
                            lastName: 'User',
                          },
                        ) || 'Unselected',
                    )
                    .join(', ')
                : readableName(
                    getContainer(UsersContainer).state.allUsers.find(u => u.id === value) || {
                      firstName: 'Unknown',
                      lastName: 'User',
                    },
                  ) || '',
          }

        case CustomFieldType.TeamSelect:
          return {
            getName: (value: string) =>
              Array.isArray(value)
                ? value
                    .map(
                      v =>
                        getContainer(FieldsState).state.customFields.find(c => c.id === v)?.label ||
                        'Unselected',
                    )
                    .join(', ')
                : getContainer(FieldsState).state.customFields.find(c => c.id === value)?.label ||
                  'Unselected',
          }

        default:
          return {
            getName: (value: string) => `${value}`,
          }
      }
  }
}
