import { fromNullable } from '@basisboard/basis-ui/es/utils'
import * as React from 'react'
import { Select } from '../../../../Companies/components/Select'
import { EditTableField } from '../../EditTableField'

export const CompanySelectType = ({ value, onChange }) => {
  const ref = React.useRef<HTMLDivElement>()

  const handleClick = React.useCallback(() => {
    if (!ref.current) {
      return
    }

    fromNullable(ref.current.querySelector('#searcher')).fold(
      () => {
        const inputs = ref.current.getElementsByTagName('INPUT')
        ;(inputs[0] as HTMLInputElement)?.focus()
      },
      (searcher: HTMLDivElement) => searcher.click(),
    )
  }, [ref])

  return (
    <EditTableField ref={ref} onClick={handleClick}>
      <Select height={32} value={value as string[]} onChange={onChange} />
    </EditTableField>
  )
}
