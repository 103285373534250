"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Reply = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Reply = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M1.54975 6.27697L6.13319 2.31902C6.53439 1.97254 7.1667 2.25382 7.1667 2.79204V4.87676C11.3498 4.92465 14.6667 5.76301 14.6667 9.72723C14.6667 11.3273 13.6359 12.9124 12.4966 13.7411C12.141 13.9997 11.6343 13.6751 11.7654 13.2559C12.9462 9.47954 11.2053 8.47699 7.1667 8.41887V10.7083C7.1667 11.2474 6.53389 11.5274 6.13319 11.1813L1.54975 7.22301C1.26144 6.974 1.26105 6.52632 1.54975 6.27697Z", fill: color })));
exports.Reply = Reply;
