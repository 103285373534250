import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import {
  borderRadiusMixin,
  colors,
  DEFAULT_BORDER_RADIUS,
  spacing,
} from '@basisboard/basis-ui/es/styles'
import { readableName } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import ReactQuill from 'react-quill'
import styled, { css } from 'styled-components'
import { UsersContainer } from '../../containers/Users/container'
import { createCustomTools, FORMATS, MODULES } from './config'

/* eslint-disable jsx-a11y/no-onchange */

type EditorSize = 'normal' | 'small'

const MAP_TOOLBAR_HEIGHT: Record<EditorSize, string> = {
  normal: '40px',
  small: '32px',
}

const MAP_TOOLBAR_BUTTON_SIZE: Record<EditorSize, string> = {
  normal: '32px',
  small: '24px',
}

const MAP_TOOLBAR_ICON_SIZE: Record<EditorSize, string> = {
  normal: '16px',
  small: '12px',
}

const Wrapper = styled.div<{ size: EditorSize; showRadius?: boolean; children?: React.ReactNode }>(
  ({ size, showRadius }) => css`
    #toolbar {
      ${showRadius && `border-radius: ${DEFAULT_BORDER_RADIUS} ${DEFAULT_BORDER_RADIUS} 0 0;`}
      /* border-bottom: none !important; */
      border-color: #eaebee;
      background-color: #f9f9f9;
      padding: 0 ${spacing(1)};

      display: flex;
      align-items: center;
      height: ${MAP_TOOLBAR_HEIGHT[size]};

      > span {
        display: flex;
        align-items: center;
      }

      button {
        width: ${MAP_TOOLBAR_BUTTON_SIZE[size]};
        height: ${MAP_TOOLBAR_BUTTON_SIZE[size]};
      }

      svg {
        width: ${MAP_TOOLBAR_ICON_SIZE[size]};
        height: ${MAP_TOOLBAR_ICON_SIZE[size]};
      }
    }

    .ql-container.ql-snow {
      ${showRadius && `border-radius: 0 0 ${DEFAULT_BORDER_RADIUS}px ${DEFAULT_BORDER_RADIUS}px;`};
      border-color: #eaebee;
      border-top: none;
      background: ${colors.white};
    }

    .ql-mention-list li {
      height: 32px;
      color: ${colors.darkBlue};
      font-size: 0.875rem;
      line-height: 32px;

      :hover,
      :active,
      &.selected {
        background: ${colors.lightGray3};
      }
    }

    .mention {
      padding: 2px;
      background: #e7eafe;
      ${borderRadiusMixin};
      font-weight: 500;
      color: ${colors.accent};
    }

    .ql-editor {
      color: ${colors.darkBlue};
      font-weight: 500;
      overflow: visible;

      .ql-hidden {
        display: none;
      }

      padding: ${spacing(2)} ${spacing(4)};
    }
  `,
)

interface ToolbarProps {
  allowMentions?: boolean
  allowFields?: boolean
  onSelectAttachment?: (e: any) => void
}

const Toolbar: React.FC<ToolbarProps> = ({ allowMentions, allowFields, onSelectAttachment }) => (
  <div id="toolbar">
    <span className="ql-formats">
      <select className="ql-header" defaultValue={''} onChange={e => e.persist()}>
        <option value="1" />
        <option value="2" />
        <option selected />
      </select>
    </span>

    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-link" />
      <select className="ql-color">
        <option value="red" />
        <option value="green" />
        <option value="blue" />
        <option value="orange" />
        <option value="violet" />
        <option value="#d0d1d2" />
        <option selected />
      </select>
    </span>

    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-image" type="button" />
    </span>

    <span className="ql-formats">
      {onSelectAttachment && (
        <button type="button" onClick={onSelectAttachment}>
          <Icon.Attachment color="#444" />
        </button>
      )}
      {allowMentions && (
        <button className="ql-mentions">
          <Icon.User color="#444" />
        </button>
      )}
      {allowFields && (
        <button className="ql-fields">
          <Icon.Toggle color="#444" />
        </button>
      )}
    </span>
  </div>
)

interface Props extends React.ComponentProps<typeof ReactQuill>, ToolbarProps {
  onChange?: (val: string) => void
  height?: number
  size?: EditorSize
  noRadius?: boolean
  banner?: React.ReactNode
  name?: string
}

export const Editor: React.FC<Props> = ({
  allowMentions,
  allowFields,
  onSelectAttachment,
  height,
  size = 'normal',
  noRadius,
  banner,
  onChange,
  ...props
}) => {
  const [val, setVal] = React.useState<string>((props.defaultValue as string) || '')
  const { users } = useContainer(UsersContainer).state
  const quillRef = React.useRef()

  const customModules = React.useMemo(
    () =>
      createCustomTools(
        allowMentions,
        allowFields,
        users.map(u => ({
          id: u.id,
          value: readableName(u),
        })),
      ),
    [allowMentions, allowFields, users],
  )

  return (
    <Wrapper size={size} showRadius={!Boolean(noRadius)}>
      <Toolbar
        allowMentions={allowMentions}
        allowFields={allowFields}
        onSelectAttachment={onSelectAttachment}
      />
      {props.name && <input className="visually-hidden" name={props.name} value={val} />}
      {banner}
      <ReactQuill
        {...(props as any)}
        onChange={v => {
          setVal(v)
          onChange?.(v)
        }}
        value={val}
        id={props.id || 'ql-editor'}
        modules={{
          ...MODULES,
          ...customModules,
        }}
        formats={FORMATS}
        preserveWhitespace
        style={{
          height: height || '100%',
          borderRadius: DEFAULT_BORDER_RADIUS,
          overflowY: 'scroll',
        }}
        ref={quillRef}
        theme="snow"
        bounds={`#${props.id || 'ql-editor'}`}
      />
    </Wrapper>
  )
}
