import { Notification as NotificationInterface } from '@basisboard/basis-common/lib/api'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { LoadingIndicator } from '@basisboard/basis-ui/es/components/LoadingIndicator'
import { LoadMoreSentinel } from '@basisboard/basis-ui/es/components/LoadMoreSentinel'
import { Tabs } from '@basisboard/basis-ui/es/components/Tabs'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import { ButtonText, Empty } from '../../../components'
import { eventBus, EventBusType } from '../../../services'
import { AppState } from '../../App/container'
import { openDrawer } from '../../Drawer'
import { UsersContainer } from '../../Users'
import { Notification } from '../components/Notification'
import { NotificationsState } from '../state'
import { Header, MarkAllAsReadButtonWrap, NotificationList } from './styled'

enum NotificationFilter {
  ALL = 'ALL',
  UNREAD = 'UNREAD',
}

const Notifications = () => {
  const notificationsData = useContainer(NotificationsState)
  const appData = useContainer(AppState)
  const { users } = useContainer(UsersContainer).state

  const {
    loading,
    loadingMore,
    notifications: baseNotifications,
    unreadNotifications,
    counter,
  } = notificationsData.state

  React.useEffect(() => {
    notificationsData.loadNotifications({ showOnlyUnread: true, offset: 0 })
    notificationsData.loadNotifications()
  }, [])

  const me = users.find(user => user.id === appData.state.profile.id)
  const totalUnreadNotifications = baseNotifications.filter(n => !n.read).length

  const renderNotifications = React.useCallback(
    (notifications: NotificationInterface[], showOnlyUnread) => (
      <>
        {loading ? (
          <LoadingIndicator />
        ) : notifications.length > 0 ? (
          <>
            <NotificationList>
              {notifications.map(notification => (
                <Notification
                  key={notification.id}
                  notification={notification}
                  me={me}
                  onClose={() => {
                    notificationsData.toggleOpen()
                    notificationsData.markAsRead(notification.id)

                    eventBus.publish(EventBusType.NotificationClick, {
                      notificationId: notification.id,
                      toUrl: `/projects/${notification.projectId}`,
                    })
                  }}
                />
              ))}
            </NotificationList>
            {loadingMore ? (
              <Div display="flex" alignItems="center" justifyContent="center" height={32}>
                <LoadingIndicator />
              </Div>
            ) : (
              <LoadMoreSentinel
                onLoadMore={notificationsData.loadMoreCallback({
                  showOnlyUnread,
                })}
              />
            )}
          </>
        ) : (
          <Empty
            title="No notifications"
            description="There are no new notifications yet!"
            image={
              <img
                style={{ width: 169 }}
                src="/imgs/empty/no-notifications.png"
                alt="No notifications illustration"
              />
            }
          />
        )}
      </>
    ),
    [],
  )

  return (
    <>
      <Header>
        <Div width={1} display="flex" justifyContent="space-between">
          <Tabs
            defaultTab={NotificationFilter.UNREAD}
            tabSpace={{ ml: spacing(2), px: spacing(2) }}
            tabGroupId="notification-tabs"
            tabs={[
              {
                id: NotificationFilter.ALL,
                label: 'All',
                component: renderNotifications(baseNotifications, false),
              },
              {
                id: NotificationFilter.UNREAD,
                label: 'Unread',
                component: renderNotifications(unreadNotifications, true),
              },
            ]}
          />
          {Math.max(totalUnreadNotifications, counter) > 0 && (
            <MarkAllAsReadButtonWrap>
              <ButtonText
                mr={spacing(3)}
                onClick={notificationsData.markAllAsRead}
                color={colors.darkBlue}
              >
                Mark all as read
              </ButtonText>
            </MarkAllAsReadButtonWrap>
          )}
        </Div>
      </Header>
    </>
  )
}

export const openNotificationDrawer = (onClose: () => void) =>
  openDrawer({
    id: 'notifications-drawer',
    title: 'Notifications',
    content: <Notifications />,
    onClose,
  })
