import { CustomFieldEntity } from '@basisboard/basis-common/lib/api/custom-fields'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Tabs } from '@basisboard/basis-ui/es/components/Tabs'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import { ViewId } from '../../../../constants'
import { Group, Sort, View } from '../../../../templates/ViewsScreen/types'
import { Field } from '../../../Fields'
import { CompanySelector } from '../../components/CompanySelector'
import { CustomFilters } from '../../components/CustomFilters'
import { EstimatorSelector } from '../../components/EstimatorSelector'
import { ShowBidsOptions } from '../../components/ShowBidsOptions'
import { StatusRadioGroup } from '../../components/StatusRadioGroup'
import { FilterType } from '../../constants'
import { FiltersState } from '../../container'
import { Header, Wrapper } from './styled'

export interface FiltersDrawerProps {
  viewId: ViewId
  enabledFilters: FilterType[]
  onUpdate?: () => void
  onChangeFilter?: (p: Record<string, any>, group?: Group, fields?: Field[], sorts?: Sort[]) => void
  onClearFilters?: () => void
  renderCustomFilter?: () => React.ReactNode
  enabledEntities?: CustomFieldEntity[]
  getView?: () => View
  openAppliedFilter?: boolean
  filtersData: FiltersState
}

export const FiltersDrawer: React.FC<FiltersDrawerProps> = ({
  viewId,
  enabledFilters,
  onUpdate,
  renderCustomFilter,
  onChangeFilter,
  enabledEntities,
  getView,
  filtersData,
}) => {
  const [, forceRerender] = React.useState(false)

  const { loading } = filtersData.state
  const filter = (getView ? getView().filter : filtersData.state.filter) || {}

  if (loading) {
    return null
  }

  const hasCustomFilters = enabledFilters.includes(FilterType.Custom)

  return (
    <Wrapper>
      <Header>
        <Div position="absolute" right={32} top="8px" zIndex={1}>
          <Text
            ml="auto"
            color={colors.accent}
            as="button"
            onClick={() => {
              onChangeFilter?.(null)
              filtersData.reset()
              onUpdate?.()

              forceRerender(d => !d)
            }}
          >
            Clear filters
          </Text>
        </Div>
      </Header>
      <Div display="flex" justifyContent="space-between" position="relative">
        <Div mt={spacing(-5)} width={'100%'}>
          <Tabs
            contentSpace={{ pt: spacing(4) }}
            tabGroupId="filter-type-tabs"
            tabSpace={{ mr: spacing(4) }}
            tabs={[
              ...(hasCustomFilters
                ? [
                    {
                      id: 'custom',
                      label: 'Custom filters',
                      component: (
                        <CustomFilters
                          viewId={viewId}
                          getView={getView}
                          enabledEntities={enabledEntities}
                          appliedFilter={filter}
                          onChange={(...props) => {
                            onChangeFilter?.(...props)
                            forceRerender(d => !d)
                            onUpdate?.()
                          }}
                        />
                      ),
                    },
                  ]
                : []),

              ...((hasCustomFilters ? enabledFilters.length > 1 : true)
                ? [
                    {
                      id: 'default',
                      label: 'Quick filters',
                      component: (
                        <>
                          {enabledFilters.includes(FilterType.Estimator) && (
                            <EstimatorSelector
                              value={(filter || {}).estimatorIds || []}
                              onChange={value => {
                                const newFilter = {
                                  ...filter,
                                  estimatorIds: value,
                                  customFilter: null,
                                }
                                onChangeFilter?.(newFilter)
                                filtersData.applyFilter(newFilter).then(() => onUpdate?.())

                                forceRerender(d => !d)
                              }}
                              includeDeleted
                            />
                          )}
                          {enabledFilters.includes(FilterType.ShowBids) && (
                            <ShowBidsOptions
                              value={filter}
                              onChange={value => {
                                const newFilter = { ...filter, ...value, customFilter: null }

                                onChangeFilter?.(newFilter)
                                filtersData.applyFilter(newFilter).then(() => onUpdate?.())

                                forceRerender(d => !d)
                              }}
                            />
                          )}
                          {enabledFilters.includes(FilterType.Status) && (
                            <StatusRadioGroup
                              value={filter}
                              onChange={value => {
                                const newFilter = { ...filter, ...value, customFilter: null }

                                onChangeFilter?.(newFilter)
                                filtersData.applyFilter(newFilter).then(() => onUpdate?.())

                                forceRerender(d => !d)
                              }}
                            />
                          )}
                          {enabledFilters.includes(FilterType.Company) && (
                            <CompanySelector
                              value={(filter || {}).companies}
                              onChange={value => {
                                const newFilter = {
                                  ...filter,
                                  companies: value,
                                  customFilter: null,
                                }
                                onChangeFilter?.(newFilter)
                                filtersData.applyFilter(newFilter).then(() => onUpdate?.())

                                forceRerender(d => !d)
                              }}
                            />
                          )}
                          {renderCustomFilter && renderCustomFilter()}
                          {enabledFilters.includes(FilterType.ReminderStatus) && (
                            <StatusRadioGroup
                              value={filter}
                              onChange={value => {
                                const newFilter = { ...filter, ...value }
                                onChangeFilter?.(newFilter)
                                filtersData.applyFilter(newFilter).then(() => onUpdate?.())

                                forceRerender(d => !d)
                              }}
                            />
                          )}
                        </>
                      ),
                    },
                  ]
                : []),
            ]}
          />
        </Div>
      </Div>
    </Wrapper>
  )
}
