import { BID_INVITE_ID } from '@basisboard/basis-common/lib/api'
import { CustomFieldType } from '@basisboard/basis-common/lib/api/custom-fields'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Table2 } from '@basisboard/basis-ui/es/components/Table2'
import { formatMomentWithTime } from '@basisboard/basis-ui/es/components/Timeline'
import { Subtitle2 } from '@basisboard/basis-ui/es/components/Typography'
import { colors } from '@basisboard/basis-ui/es/styles'
import { formatDate, getClosestBidDeadline, getSoonestDate } from '@basisboard/basis-ui/es/utils'
import moment from 'moment'
import * as React from 'react'
import { NestedProject } from '../../../../../../types'
import { Fields } from '../../../../../Fields'
import { BaseField } from '../../../../../Fields/components/TableField/components/BaseField'

interface Props {
  project: NestedProject
  onChange: (updatedBidInvites: { id: BID_INVITE_ID; bidDeadlineAt: string }[]) => void
}

export const BidDeadlinePicker: React.FC<Props> = ({ project, onChange }) => {
  const [dates, setDates] = React.useState(
    project.bidInvites.map(bi => ({
      id: bi.id,
      deadline: bi.bidDeadlineAt,
      companyName: bi.company.name,
      changed: false,
    })),
  )

  const [showEdit, setShowEdit] = React.useState(false)

  return dates.length > 1 ? (
    <BaseField
      onShowEdit={setShowEdit}
      actions={[]}
      onDismiss={() =>
        onChange(
          dates.filter(d => d.changed).map(({ id, deadline }) => ({ id, bidDeadlineAt: deadline })),
        )
      }
      shouldCloseWhenOutsideClick={() => !document.querySelector('.dismiss-overlay')}
      customActions={{
        width: 400,
        customActions: (
          <Div>
            <Table2.Wrapper>
              <Table2.TBody>
                {dates.map((date, index) => (
                  <Table2.Tr key={date.id}>
                    <Table2.Th>
                      <span>{date.companyName}</span>
                    </Table2.Th>
                    <Table2.Td>
                      <Fields.TableField
                        actions={[]}
                        field={{ type: CustomFieldType.Date, label: 'Bid Deadline' }}
                        value={date.deadline}
                        onChange={(deadline: string) =>
                          setDates(d =>
                            d.map((date, i) =>
                              i === index ? { ...date, deadline, changed: true } : date,
                            ),
                          )
                        }
                      />
                    </Table2.Td>
                  </Table2.Tr>
                ))}
              </Table2.TBody>
            </Table2.Wrapper>
          </Div>
        ),
      }}
    >
      {showEdit ? (
        <Subtitle2 width={1} textAlign="center" color={colors.blueGray600}>
          Earliest bid deadline:{' '}
          {formatDate(
            getSoonestDate(dates.filter(d => Boolean(d.deadline)).map(d => moment(d.deadline))),
            true,
          ) || 'N/A'}
        </Subtitle2>
      ) : (
        formatMomentWithTime(getClosestBidDeadline(project.bidInvites))
      )}
    </BaseField>
  ) : (
    <Fields.TableField
      actions={[]}
      field={{ type: CustomFieldType.Date, label: 'Bid Deadline' }}
      value={dates[0].deadline}
      onChange={(deadline: string) => onChange([{ id: dates[0].id, bidDeadlineAt: deadline }])}
    />
  )
}
