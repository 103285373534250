import { USER_ID } from '@basisboard/basis-common/lib/api'
import { ComboBox, SelectOption } from '@basisboard/basis-ui/es/components/ComboBox'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { Label } from '@basisboard/basis-ui/es/components/Typography'
import { spacing } from '@basisboard/basis-ui/es/styles'
import { box, fuzzySearch, readableName } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import { PersonChip } from '../../../../components/PersonChip'
import { UsersContainer } from '../../container'

const mapUserToOption = user => ({
  value: user.id,
  label: readableName(user),
})

interface Props {
  value?: USER_ID[] | USER_ID
  defaultValue?: USER_ID[] | USER_ID
  onChange?: (value: USER_ID[] | USER_ID) => void
  multiple?: boolean
  name?: string
  label?: string
  required?: boolean
  includeDeleted?: boolean
}

export const UsersInput: React.FC<Props> = ({
  defaultValue,
  value,
  label,
  onChange,
  name,
  multiple = true,
  required,
  includeDeleted,
}) => {
  const { users, allUsers } = useContainer(UsersContainer).state
  const isArray = React.useMemo(() => Array.isArray(value || defaultValue) && multiple, [
    value,
    defaultValue,
    multiple,
  ])
  const [localValue, setLocalValue] = React.useState(defaultValue || value)

  React.useEffect(() => {
    setLocalValue(value || defaultValue)
  }, [value])

  const allUsersOptions = React.useMemo(() => allUsers.map(mapUserToOption), [allUsers])
  const activeUsersOptions = React.useMemo(() => users.map(mapUserToOption), [users])

  const validUsersOptions = includeDeleted ? allUsersOptions : activeUsersOptions

  const setSelectedOptions = (options: SelectOption[]) => {
    if (isArray) {
      box(options.filter(Boolean).map(opt => opt.value)).fold(val => {
        onChange?.(val)
        setLocalValue(val)
      })
    } else {
      box(options.length > 0 ? options.pop().value : undefined).fold(val => {
        onChange?.(val)
        setLocalValue(val)
      })
    }
  }

  const selectedOptions = isArray
    ? allUsersOptions.filter(e => localValue.includes(e.value))
    : [allUsersOptions.find(e => e.value === localValue)].filter(Boolean)

  return (
    <>
      {label && (
        <Label mb={spacing(0.5)} required={required}>
          {label}
        </Label>
      )}
      <ComboBox
        prefix={<Icon.User size={16} />}
        name={name}
        multiple={multiple}
        renderOption={({ value }) => {
          const user = allUsers.find(u => u.id === value)
          return <PersonChip color={user.color} name={readableName(user)} />
        }}
        options={validUsersOptions}
        selectedOptions={selectedOptions}
        onSelectOption={setSelectedOptions}
        required={required}
        onFilter={(term, options) => {
          if (!term) {
            return options
          }

          const validUsers = fuzzySearch(validUsersOptions, term, { options: { keys: ['label'] } })

          return Array.isArray(validUsers)
            ? (validUsers as typeof validUsersOptions).filter(e =>
                options.some(option => e.label === option.label),
              )
            : options
        }}
      />
    </>
  )
}
