"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Inbox = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Inbox = ({ width = 16, height = 16, size, color = styles_1.colors.accent }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M14.48 7.72008L12.0335 4.05031C11.8275 3.74121 11.4805 3.55555 11.109 3.55554H4.89093C4.51943 3.55554 4.17251 3.74121 3.96644 4.05031L1.51993 7.72008C1.39825 7.90259 1.33331 8.11704 1.33331 8.3364V11.3333C1.33331 11.947 1.83077 12.4444 2.44442 12.4444H13.5555C14.1692 12.4444 14.6666 11.947 14.6666 11.3333V8.3364C14.6666 8.11704 14.6017 7.90259 14.48 7.72008ZM5.08915 5.03702H10.9108L12.8861 7.99999H10.037L9.29628 9.48147H6.70368L5.96294 7.99999H3.11382L5.08915 5.03702Z", fill: color })));
exports.Inbox = Inbox;
