import { Card } from '@basisboard/basis-ui/es/components/Card'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Icon, IconType } from '@basisboard/basis-ui/es/components/Icon'
import { Label, Text } from '@basisboard/basis-ui/es/components/Typography'
import { useOutsideClick } from '@basisboard/basis-ui/es/hooks/useOutsideClick'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { fromNullable } from '@basisboard/basis-ui/es/utils'
import equals from 'ramda/src/equals'
import uniq from 'ramda/src/uniq'
import * as React from 'react'
import { useEscapeKey } from '../../../../hooks'
import { List, ListEntry, MultiSelectEntry, MultiSelectList, Wrapper } from './styled'

export interface SelectInputProps extends Omit<React.HTMLProps<HTMLSelectElement>, 'onChange'> {
  label?: string
  options: {
    name: string
    id: string
    color?: string
  }[]
  name?: string
  onChange?: ((val: string) => void) | ((val: string[]) => void)
  open?: boolean
  value?: string | string[]
  initialValue?: string | string[]
  hideEmpty?: boolean
  required?: boolean
  hideBorders?: boolean
  wrapperStyle?: React.CSSProperties
  showArrows?: boolean
  renderOption?: (
    opt: { name: string; id: string; color?: string },
    isSelected?: boolean,
  ) => React.ReactNode
  onChangeOpenStatus?: (open: boolean) => void
  icon?: IconType
  iconColor?: string
  dropdownWidth?: number | string
  dropdownRender?: () => React.ReactNode
  multiSelectListStyle?: React.CSSProperties
  sizeVariant?: 'medium' | 'small'
}

export const Select = React.forwardRef<HTMLSelectElement, SelectInputProps>(
  (
    {
      label,
      options,
      name,
      onChange,
      renderOption,
      hideEmpty,
      required,
      hideBorders,
      value,
      initialValue,
      multiple,
      open = false,
      onChangeOpenStatus,
      placeholder,
      icon,
      iconColor,
      dropdownWidth,
      dropdownRender,
      showArrows,
      defaultValue,
      disabled = false,
      multiSelectListStyle,
      wrapperStyle,
      sizeVariant = 'medium',
    },
    ref,
  ) => {
    const [focused, setFocused] = React.useState(false)
    const optionList = React.useRef<HTMLUListElement>()
    const [currentValue, setCurrentValue] = React.useState(initialValue || value || defaultValue)
    const [selectedIndex, setSelectedIndex] = React.useState(-1)
    const wrapperRef = React.useRef<HTMLDivElement>()

    React.useEffect(() => {
      if (open && !focused) {
        handleFocus()
      }
    }, [open])

    React.useEffect(() => {
      if (value !== undefined && !equals(value, currentValue)) {
        setCurrentValue(value)
      }
    }, [value])

    const handleOpen = () => {
      if (focused || open) {
        handleBlur()
      } else {
        handleFocus()
      }
    }

    const handleBlur = () => {
      onChangeOpenStatus && onChangeOpenStatus(false)
      setFocused(false)
    }

    const handleFocus = () => {
      setFocused(true)
      onChangeOpenStatus && onChangeOpenStatus(true)
    }

    const handleChange = (val: string) => {
      !multiple && handleBlur()

      const newValue: any = multiple
        ? [...((currentValue as string[]) || [])].includes(val)
          ? (currentValue as string[]).filter(v => v !== val)
          : [...((currentValue as string[]) || []), val]
        : val
      onChange?.(newValue)
      setCurrentValue((multiple ? uniq([...((currentValue as string[]) || []), val]) : val) as any)
    }

    const handleRenderOption = (id: string, isSelected?: boolean) => {
      const option = options.find(o => o.id === id)

      return fromNullable(option).fold(
        () => null,
        opt => renderOption?.(opt, isSelected) || <Text mr={spacing(2)}>{option.name}</Text>,
      )
    }

    const removeOption = (id: string) => {
      const val = (currentValue as string[]).filter(v => v !== id) as any
      setCurrentValue(val)
      onChange?.(val)
      handleFocus()
    }

    useEscapeKey(handleBlur)
    useOutsideClick([optionList, wrapperRef], handleBlur)

    React.useEffect(() => {
      if (!focused) {
        return
      }

      const listenKeyUp = e => {
        switch (e.code) {
          case 'Escape':
            return handleBlur()

          case 'ArrowDown':
            return handleChangeSelected(1)

          case 'ArrowUp':
            return handleChangeSelected(-1)

          case 'Enter':
            e.preventDefault()
            return handleChange(options[selectedIndex]?.id)
        }
      }

      window.addEventListener('keyup', listenKeyUp)

      return () => {
        window.removeEventListener('keyup', listenKeyUp)
      }
    }, [focused, selectedIndex, options.length, currentValue])

    const handleChangeSelected = React.useCallback(
      (delta: 1 | -1) => {
        if (delta + selectedIndex > options.length || delta + selectedIndex < -1) {
          return
        }
        setSelectedIndex(s => s + delta)

        optionList.current.querySelector('.selected')?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      },
      [selectedIndex, setSelectedIndex, options],
    )

    const SelectIcon = Icon[icon] || (focused || open ? Icon.ArrowUp : Icon.ArrowDown)

    return (
      <>
        {label && <Label required={required}>{label}</Label>}
        <Wrapper
          data-testid="select"
          onClick={handleOpen}
          hideBorders={hideBorders}
          onMouseDown={e => e.preventDefault()}
          disabled={disabled}
          style={wrapperStyle}
          size={sizeVariant}
          onFocus={handleFocus}
          onBlur={handleBlur}
          focused={focused}
          ref={wrapperRef}
        >
          <select
            className="visually-hidden"
            ref={ref}
            name={name}
            value={currentValue || undefined}
            multiple={multiple}
            disabled={disabled}
            tabIndex={-1}
            required={required}
          >
            {!hideEmpty && <option value={null} />}

            {options.map(o => (
              <option
                key={o.id}
                value={o.id}
                selected={
                  Array.isArray(currentValue) ? currentValue.includes(o.id) : o.id === currentValue
                }
              >
                {o.name}
              </option>
            ))}
          </select>

          {(Array.isArray(currentValue) ? (
            currentValue.length === 0
          ) : (
            !currentValue
          )) ? (
            <>{placeholder && <Text color={colors.mediumGray}>{placeholder}</Text>}</>
          ) : Array.isArray(currentValue) ? (
            <MultiSelectList data-testid="multi-select-list" style={multiSelectListStyle}>
              {currentValue.map(val => (
                <MultiSelectEntry key={val} hasCustomRender={!!renderOption}>
                  {handleRenderOption(val, true)}
                  <Div position="absolute" right="12px" height={24} py="6px" top={-2}>
                    <button
                      style={{ zIndex: 1, padding: 0, height: 12 }}
                      onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        removeOption(val)
                      }}
                    >
                      <Icon.Close />
                    </button>
                  </Div>
                </MultiSelectEntry>
              ))}
            </MultiSelectList>
          ) : (
            handleRenderOption(currentValue as string, true)
          )}

          {(!hideBorders || showArrows) && (
            <Div
              position="absolute"
              right={hideBorders ? '2px' : '16px'}
              size={16}
              top="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ transform: 'translateY(-50%)' }}
            >
              <SelectIcon color={iconColor} />
            </Div>
          )}

          {(focused || open) && (
            <Card
              zIndex={Number.MAX_SAFE_INTEGER}
              position="absolute"
              top={hideBorders && !multiple ? -9 : 'calc(100% + 4px)'}
              left={hideBorders ? 0 : '-1px'}
              onMouseEnter={e => e.stopPropagation()}
              onMouseLeave={e => e.stopPropagation()}
              onClick={e => e.stopPropagation()}
              width={dropdownWidth || 288}
              onBlur={handleBlur}
            >
              <Div maxHeight={192} overflow="auto" py={spacing(1)}>
                <List ref={optionList}>
                  {options.map((opt, index) => (
                    <ListEntry
                      data-testid="list-entry"
                      className={selectedIndex === index && 'selected'}
                      onMouseDown={e => e.preventDefault()}
                      key={opt.id}
                      onClick={() => handleChange(opt.id)}
                      selected={
                        Array.isArray(currentValue)
                          ? currentValue.includes(opt.id)
                          : index === selectedIndex
                      }
                    >
                      {renderOption ? renderOption(opt) : <Text>{opt.name}</Text>}
                    </ListEntry>
                  ))}
                  {!hideEmpty && !multiple && (
                    <ListEntry
                      onMouseDown={e => e.preventDefault()}
                      onClick={() => handleChange(null)}
                    >
                      -
                    </ListEntry>
                  )}
                </List>
              </Div>
              {dropdownRender && <Div tabIndex={-1}>{dropdownRender()}</Div>}
            </Card>
          )}
        </Wrapper>
      </>
    )
  },
)
