import { CompanyWithStats } from '@basisboard/basis-common/lib/api'
import { CustomFieldEntity, CustomFieldType } from '@basisboard/basis-common/lib/api/custom-fields'
import { CompanyFieldType } from '@basisboard/basis-common/lib/enums'
import { Checkbox } from '@basisboard/basis-ui/es/components/Checkbox'
import { Circle } from '@basisboard/basis-ui/es/components/Circle'
import { IconType } from '@basisboard/basis-ui/es/components/Icon'
import { Table2 } from '@basisboard/basis-ui/es/components/Table2'
import { Tooltip } from '@basisboard/basis-ui/es/components/Tooltip'
import { borderRadiusMixin, colors, spacing } from '@basisboard/basis-ui/es/styles'
import { box } from '@basisboard/basis-ui/es/utils'
import { getContainer } from '@containrz/core'
import * as React from 'react'
import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'
import { screens } from '../../../../screens'
import { Field } from '../../../../templates/ViewsScreen'
import { Fields, FieldsState } from '../../../Fields'
import { NoteEntityType, openNotesDrawer } from '../../../Notes'
import { companyRepository } from '../../container'

interface ListEntryProps {
  company: CompanyWithStats
  columns: Field[]
  onSelect: (select: boolean) => void
  onDelete: () => void
  selected: boolean
}

const TagContent = styled.div<BackgroundProps>`
  ${background};
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 ${spacing(1)};
  margin: ${spacing(1)} 0;
  color: ${colors.white};
  ${borderRadiusMixin};
`

export const ListEntry: React.FC<ListEntryProps> = ({
  company,
  onDelete,
  onSelect,
  columns,
  selected,
}: ListEntryProps) => {
  const [currentCompany, setCurrentCompany] = React.useState(company)

  const updateCompanyKey = React.useCallback(
    (key, value) => {
      setCurrentCompany(c => ({
        ...c,
        [key]: key === 'customFields' ? { ...c.customFields, ...value } : value,
      }))

      return companyRepository().patchCompany(currentCompany.id, key, value)
    },
    [currentCompany],
  )

  const tag = React.useMemo(
    () =>
      box(getContainer(FieldsState).getCustomFieldsForEntity(CustomFieldEntity.Company))
        .map(customFields => customFields.find(cf => cf.isEntityLabel))
        .fold(label =>
          label
            ? getContainer(FieldsState).getCustomFieldTagOptionForValue(
                CustomFieldEntity.Company,
                company.customFields[label.id],
              )
            : undefined,
        ),
    [JSON.stringify(company.customFields)],
  )

  const customFields = React.useMemo(
    () => getContainer(FieldsState).getCustomFieldsForEntity(CustomFieldEntity.Company),
    [],
  )

  const data = React.useMemo(
    () => ({
      id: currentCompany.id,

      ...customFields.reduce(
        (acc, field) => ({
          ...acc,
          [field.id]: (
            <Fields.TableField
              field={field}
              searchable={[
                CustomFieldType.CompanyMultiSelect,
                CustomFieldType.MultiSelect,
                CustomFieldType.UserMultiSelect,
                CustomFieldType.TeamSelect,
              ].includes(field.type)}
              onChange={val =>
                updateCompanyKey('customFields', {
                  [field.id]: val,
                })
              }
              value={currentCompany.customFields[field.id]}
              actions={[]}
            />
          ),
        }),
        {},
      ),

      [CompanyFieldType.Name]: (
        <Fields.TableField
          field={{ label: 'Name', type: CustomFieldType.Text }}
          onChange={val => updateCompanyKey('name', val)}
          actions={[
            ...(tag && tag.label !== 'No tag'
              ? [
                  {
                    children: (
                      <Tooltip
                        variant="light"
                        position="topCenter"
                        content={<TagContent background={tag.color}>{tag.label}</TagContent>}
                        defaultPosition="top"
                        small
                      >
                        <Circle data-testid="tag-circle" size={12} background={tag.color} />
                      </Tooltip>
                    ),
                  },
                ]
              : []),
            {
              icon: 'Notes' as IconType,
              info: 'See discussion',
              showOnHover: true,
              onAction: () =>
                openNotesDrawer({ entity: NoteEntityType.COMPANIES, entityId: currentCompany.id }),
            },
          ]}
          value={currentCompany.name}
        />
      ),

      [CompanyFieldType.Website]: (
        <Fields.TableField
          field={{ label: 'Website', type: CustomFieldType.Text }}
          onChange={val => updateCompanyKey('website', val)}
          value={currentCompany.website}
          actions={[]}
        />
      ),

      [CompanyFieldType.Address]: (
        <Fields.TableField
          field={{ label: 'Address', type: CustomFieldType.Text }}
          onChange={val => updateCompanyKey('address', val)}
          value={currentCompany.address}
          actions={[]}
        />
      ),

      [CompanyFieldType.OpenBidsCount]: currentCompany.openBidsCount || 0,
    }),
    [JSON.stringify(currentCompany), tag],
  )

  return (
    <Table2.Tr
      onClick={() => screens.companyDetails.push(currentCompany.id)}
      actions={
        currentCompany.deletedAt
          ? undefined
          : [{ label: 'Delete', onAction: onDelete, color: colors.error }]
      }
      actionsWidth={100}
      actionsPosition="left"
    >
      <Table2.Td
        style={{ padding: 0, paddingLeft: 12 }}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <Checkbox
          value={selected}
          onChange={e => {
            onSelect(e.target.checked)
          }}
        />
      </Table2.Td>

      {columns.map((c, i) => (
        <Table2.Td
          data-testid={c.label}
          key={c.id as string}
          className={i === 0 ? 'sticky' : undefined}
          preventHover={([
            CompanyFieldType.OpenBidsCount,
            CompanyFieldType.HitRatioCount,
            CompanyFieldType.HitRatioRevenue,
            CompanyFieldType.TotalLost,
            CompanyFieldType.TotalSubmitted,
            CompanyFieldType.TotalWon,
          ] as string[]).includes(c.id)}
        >
          {data[c.id]}
        </Table2.Td>
      ))}

      <Table2.Td />
    </Table2.Tr>
  )
}
