import {
  borderRadiusMixin,
  colors,
  DEFAULT_BORDER_RADIUS,
  spacing,
} from '@basisboard/basis-ui/es/styles'
import styled from 'styled-components'

const mapSizeToPx = {
  medium: 56,
  small: 40,
}

export const ToastWrapper = styled.div<{
  color: string
  size: 'medium' | 'small'
}>`
  width: 100%;
  height: ${({ size }) => mapSizeToPx[size]}px;
  margin-bottom: ${spacing(1)};
  ${borderRadiusMixin};

  position: relative;

  display: flex;
  align-items: center;

  background-color: ${colors.white};
  border: 1px solid #dadce3;

  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

  &::before {
    content: '';
    position: absolute;
    border-radius: ${DEFAULT_BORDER_RADIUS} 0 0 ${DEFAULT_BORDER_RADIUS};
    height: 100%;
    width: 4px;
    left: 0;
    top: 0;
    background-color: ${({ color }) => color};
  }
`

export const ManageReasonsOption = styled.div`
  color: ${colors.darkGray};
  font-size: 12px;
  line-height: 16px;
  padding: 8px 16px 24px 16px;
`

export const ReasonsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: ${spacing(2)} 0;
`

export const ReasonEntry = styled.button`
  padding-left: ${spacing(2)};
  height: 32px;
  color: ${colors.darkBlue};
  width: 100%;
  text-align: left;

  &:hover {
    background-color: ${colors.lightGray3};
  }
`
