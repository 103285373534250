"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.focussableElements = exports.MENU_ID = exports.DRAWER_ID = exports.MODAL_ID = exports.ZIndexValues = exports.TransitionType = void 0;
exports.TransitionType = {
    fast: '100ms',
    medium: '250ms',
    slow: '500ms',
};
var ZIndexValues;
(function (ZIndexValues) {
    ZIndexValues[ZIndexValues["Menu"] = 500] = "Menu";
    ZIndexValues[ZIndexValues["Drawer"] = 1000] = "Drawer";
    ZIndexValues[ZIndexValues["Modal"] = 2000] = "Modal";
})(ZIndexValues = exports.ZIndexValues || (exports.ZIndexValues = {}));
exports.MODAL_ID = 'modals';
exports.DRAWER_ID = 'drawers';
exports.MENU_ID = 'menus';
exports.focussableElements = 'button:not([disabled]), [href]:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])';
