import { Div } from '@basisboard/basis-ui/es/components/Div'
import { LoadingIndicator } from '@basisboard/basis-ui/es/components/LoadingIndicator'
import { colors, DEFAULT_BORDER_RADIUS, spacing } from '@basisboard/basis-ui/es/styles'
import { fromNullable } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import equals from 'ramda/src/equals'
import insert from 'ramda/src/insert'
import isEmpty from 'ramda/src/isEmpty'
import remove from 'ramda/src/remove'
import * as React from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { ButtonText } from '../../../../../components'
import { ViewId } from '../../../../../constants'
import { FiltersStateInstances } from '../../../../Filters/container'
import { FieldsState } from '../../../container'
import FieldsItem from './FieldsItem'

export interface FieldsMenuProps {
  viewId: ViewId
  onUpdate?: () => void
}

const FieldsMenu: React.FC<FieldsMenuProps> = ({ viewId, onUpdate }) => {
  const fieldsState = useContainer(FieldsState)
  const filtersData = useContainer(FiltersStateInstances(viewId))

  const handleUpdateFields = React.useCallback(
    updatedField => {
      fromNullable(
        [...filtersData.state.customFilters, ...filtersData.state.sharedCustomFilters].find(cf =>
          equals(
            JSON.stringify(cf.filter),
            JSON.stringify(filtersData.state.filter?.customFilter || {}),
          ),
        ),
      ).fold(
        () => fieldsState.updateField(viewId, updatedField),

        customFilter => {
          if (isEmpty(customFilter.fields || [])) {
            return fieldsState.updateField(viewId, updatedField)
          }

          fieldsState.setState({
            [viewId]: fieldsState.state[viewId].map(field =>
              field.id === updatedField.id ? updatedField : field,
            ),
          })
        },
      )
    },
    [fieldsState, viewId],
  )

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result

    if (destination === null) return

    const { index: start } = source
    const { index: end } = destination

    if (fieldsState.state[viewId][end].isStatic) return

    const updatedFields = insert(
      end,
      fieldsState.state[viewId][start],
      remove(start, 1, fieldsState.state[viewId]),
    )

    fromNullable(
      [...filtersData.state.customFilters, ...filtersData.state.sharedCustomFilters].find(cf =>
        equals(
          JSON.stringify(cf.filter),
          JSON.stringify(filtersData.state.filter?.customFilter || {}),
        ),
      ),
    ).fold(
      () => fieldsState.resortFields(viewId, updatedFields),

      customFilter => {
        if (isEmpty(customFilter.fields || [])) {
          return fieldsState.resortFields(viewId, updatedFields)
        }

        fieldsState.setState({
          [viewId]: updatedFields,
        })
      },
    )

    onUpdate && onUpdate()
  }

  const handleResetFields = () => {
    fieldsState.resetField(viewId)
    onUpdate && onUpdate()
  }

  return (
    <>
      <Div background={colors.white} overflow="auto" height="100%" maxHeight={260} p={spacing(2)}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="fields">
            {provided => (
              <Div ref={provided.innerRef} {...provided.droppableProps}>
                {(() => {
                  if (fieldsState.state[viewId] === undefined) return <LoadingIndicator />
                  return fieldsState.state[viewId].map((field, index) => (
                    <FieldsItem
                      key={field.id}
                      field={field}
                      index={index}
                      onChange={enabled => handleUpdateFields({ ...field, isEnabled: enabled })}
                      disabled={field.isStatic}
                    />
                  ))
                })()}
                {provided.placeholder}
              </Div>
            )}
          </Droppable>
        </DragDropContext>
      </Div>
      <Div
        background={colors.white}
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        height={40}
        borderTop="1px solid #dadce3"
        borderRadius={`0 0 ${DEFAULT_BORDER_RADIUS}px ${DEFAULT_BORDER_RADIUS}`}
      >
        <ButtonText onClick={handleResetFields} mt={spacing(1.5)} mx="auto">
          Restore to default fields
        </ButtonText>
      </Div>
    </>
  )
}

export default FieldsMenu
