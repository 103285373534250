"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Checkbox = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../styles");
const styled_1 = require("./styled");
const Check = ({ disabled, inverse }) => (React.createElement("svg", { width: "12", height: "12", viewBox: "0 0 12 12", fill: "none" },
    React.createElement("path", { d: "M4.39644 9.58214L1.14644 6.33214C0.951187 6.13689 0.951187 5.82031 1.14644 5.62503L1.85353 4.91792C2.04878 4.72265 2.36539 4.72265 2.56064 4.91792L4.75 7.10726L9.43935 2.41792C9.63461 2.22267 9.95121 2.22267 10.1465 2.41792L10.8535 3.12503C11.0488 3.32029 11.0488 3.63687 10.8535 3.83214L5.10355 9.58216C4.90828 9.77742 4.59169 9.77742 4.39644 9.58214Z", fill: disabled ? styles_1.colors.gray : inverse ? styles_1.colors.white : styles_1.colors.accent })));
exports.Checkbox = React.forwardRef((_a, ref) => {
    var { label, initialValue, color, value, disabled = false, inverse, labelStyles = {} } = _a, props = __rest(_a, ["label", "initialValue", "color", "value", "disabled", "inverse", "labelStyles"]);
    const [check, toggleChecked] = React.useState(typeof value === 'boolean' ? value : initialValue);
    React.useEffect(() => {
        if (check !== value && typeof value === 'boolean') {
            toggleChecked(value);
        }
    }, [value]);
    return (React.createElement(styled_1.Label, { as: "label", active: check, disabled: disabled, inverse: inverse },
        React.createElement(styled_1.Input, Object.assign({ ref: ref }, props, { disabled: disabled, onChange: e => {
                var _a;
                toggleChecked(e.target.checked);
                (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, e);
            }, checked: typeof check === 'boolean' ? check : initialValue, id: props.name })),
        React.createElement(styled_1.Box, { role: "checkbox" },
            React.createElement(Check, { disabled: disabled, inverse: inverse })),
        label && (
        // eslint-disable-next-line
        // @ts-ignore
        React.createElement(styled_1.Label, Object.assign({ color: color, ml: (0, styles_1.spacing)(1) }, labelStyles), label))));
});
