import {
  ProjectSearchGroupName,
  ProjectSearchQueryGroup,
  TimeGroupField,
} from '@basisboard/basis-common/lib/api'
import { CustomFieldEntity, CustomFieldType } from '@basisboard/basis-common/lib/api/custom-fields'
import { SortDirection } from '@basisboard/basis-common/lib/enums'
import { getContainer } from '@containrz/react-hook'
import { FieldsState, isGroupableField } from '../Fields'

export interface GroupType {
  label: string
  name: string
  value: string
  id: string
  type: CustomFieldType
  group: ProjectSearchQueryGroup
}

export const getGroups = (): GroupType[] => [
  {
    label: 'Companies',
    name: 'Companies',
    value: ProjectSearchGroupName.Company,
    id: ProjectSearchGroupName.Company,
    type: CustomFieldType.Text,
    group: {
      name: ProjectSearchGroupName.Company,
    },
  },

  {
    label: 'Estimators',
    name: 'Estimators',
    value: ProjectSearchGroupName.Estimator,
    id: ProjectSearchGroupName.Estimator,
    type: CustomFieldType.Text,
    group: {
      name: ProjectSearchGroupName.Estimator,
    },
  },

  {
    label: 'Stages',
    name: 'Stages',
    value: ProjectSearchGroupName.Stage,
    id: ProjectSearchGroupName.Stage,
    type: CustomFieldType.Number,
    group: {
      name: ProjectSearchGroupName.Stage,
    },
  },

  {
    label: 'Project State',
    name: 'Project State',
    value: `${ProjectSearchGroupName.Region}-Project region`,
    id: `${ProjectSearchGroupName.Region}-Project region`,
    type: CustomFieldType.Text,
    group: {
      name: ProjectSearchGroupName.Region,
    },
  },

  {
    label: 'Awarded date',
    name: 'Awarded date',
    value: `${ProjectSearchGroupName.Date}-Awarded date`,
    id: `${ProjectSearchGroupName.Date}-Awarded date`,
    type: CustomFieldType.Date,
    group: {
      name: ProjectSearchGroupName.Date,
      time: {
        field: TimeGroupField.AwardedAt,
      },
    },
  },

  {
    label: 'Bid due date',
    name: 'Bid due date',
    value: `${ProjectSearchGroupName.Date}-Bid due date`,
    id: `${ProjectSearchGroupName.Date}-Bid due date`,
    type: CustomFieldType.Date,
    group: {
      name: ProjectSearchGroupName.Date,
      time: {
        field: TimeGroupField.BidDueDateAt,
      },
    },
  },

  {
    label: 'Created date',
    name: 'Created date',
    value: `${ProjectSearchGroupName.Date}-Created date`,
    id: `${ProjectSearchGroupName.Date}-Created date`,
    type: CustomFieldType.Date,
    group: {
      name: ProjectSearchGroupName.Date,
      time: {
        field: TimeGroupField.CreatedAt,
      },
    },
  },

  {
    label: 'Lost date',
    name: 'Lost date',
    value: `${ProjectSearchGroupName.Date}-Lost date`,
    id: `${ProjectSearchGroupName.Date}-Lost date`,
    type: CustomFieldType.Date,
    group: {
      name: ProjectSearchGroupName.Date,
      time: {
        field: TimeGroupField.LostAt,
      },
    },
  },

  {
    label: 'Submitted date',
    name: 'Submitted date',
    value: `${ProjectSearchGroupName.Date}-Submitted date`,
    id: `${ProjectSearchGroupName.Date}-Submitted date`,
    type: CustomFieldType.Date,
    group: {
      name: ProjectSearchGroupName.Date,
      time: {
        field: TimeGroupField.SubmittedAt,
      },
    },
  },

  ...getContainer(FieldsState)
    .getCustomFieldsForEntity(CustomFieldEntity.Project)
    .filter(field => isGroupableField(field.type))
    .map(f => ({
      label: f.label,
      name: f.label,
      value: f.id,
      id: f.id,
      type: f.type,
      group: {
        name: f.id,
        sortDirection: SortDirection.ASC,
      },
    })),
]

const getCompanyGroups = () => [
  ...getContainer(FieldsState)
    .getCustomFieldsForEntity(CustomFieldEntity.Company)
    .filter(field => isGroupableField(field.type))
    .map(f => ({
      label: f.label,
      name: f.label,
      value: f.id,
      id: f.id,
      type: f.type,
      group: {
        name: f.id,
        sortDirection: SortDirection.ASC,
      },
    })),
]

export const getGroupsByEntity = (entity: CustomFieldEntity) =>
  ({
    [CustomFieldEntity.Project]: getGroups(),
    [CustomFieldEntity.Company]: getCompanyGroups(),
  }[entity])
