import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { Logo } from '@basisboard/basis-ui/es/components/Logo'
import { Menu } from '@basisboard/basis-ui/es/components/Menu'
import { Row } from '@basisboard/basis-ui/es/components/Row'
import { Subtitle, Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { fromNullable, readableName } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import { rem } from 'polished'
import { UsersContainer } from '../../../Users'
import { AppState, whoami } from '../../container'
import { AdminBarContainer } from './container'
import { Badge, LinksWrapper, Wrapper } from './styled'

export const AdminBar = () => {
  const appData = useContainer(AppState)
  const adminData = useContainer(AdminBarContainer)
  const userData = useContainer(UsersContainer)

  const profile = appData.state.profile

  const currentImpersonation = fromNullable(
    adminData.state.impersonations?.[profile?.customerWorkspace],
  ).fold(
    () => profile,
    userId => userData.state.users.find(u => u.id === userId) || profile,
  )

  const admin = appData.state.adminProfile || appData.state.profile

  return (
    <Wrapper isShowing={appData.state.showAdminBanner}>
      <Logo size={20} color={colors.blueGray500} />

      <Subtitle fontWeight={500} mx={spacing(2)} white fontSize={rem(20)}>
        Basis Admin
      </Subtitle>

      <Icon.Tools size={20} color={colors.blueGray500} />

      <LinksWrapper>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://basisboard.retool.com/apps/80f7faa0-9eb0-11eb-8dd0-b3a843282d60/Customers/Email%20scrapers?customerId=${appData.state.profile.customerWorkspace}`}
          >
            Scraper Stats <Icon.ArrowPointRight color={colors.blueGray400} />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://basisboard.retool.com/apps/551cd9a0-9e92-11eb-91c4-6f8b33974754/Customers/Dashboard?customerId=${appData.state.profile.customerWorkspace}`}
          >
            Activity Stats <Icon.ArrowPointRight color={colors.blueGray400} />
          </a>
        </li>
      </LinksWrapper>

      <Row ml="auto">
        <Menu
          id="impersonation-menu"
          entries={[
            {
              id: admin.id,
              label: admin.email,
              onAction: () => adminData.impersonateUser(null, whoami().customerWorkspace),
              render: (
                <Row>
                  <Text color={colors.blueGray700}>{admin.name}</Text>
                  <Badge ml={spacing(1.5)}>Basis Admin</Badge>
                </Row>
              ),
            },

            ...userData.state.users.map(u => ({
              id: u.id,
              label: `${readableName(u)}${u.email ? ` (${u.email})` : ''}`,
              render: (
                <Row>
                  <Text color={colors.blueGray700}>
                    <strong>{readableName(u)}</strong> {u.email}
                  </Text>
                </Row>
              ),
              onAction: () => adminData.impersonateUser(u.id, whoami().customerWorkspace),
            })),
          ]}
          alignSide="right"
          boxMaxHeight={300}
          renderTrigger={(props: any) => (
            <Button.Custom
              {...(props as any)}
              style={{ border: `1px solid ${colors.blueGray400}` }}
              color={colors.blueGray400}
              iconColor={colors.blueGray400}
              backgroundColor="transparent"
              postIcon="Arrow"
            >
              User:{' '}
              {'name' in currentImpersonation
                ? currentImpersonation.name
                : readableName(currentImpersonation)}
            </Button.Custom>
          )}
        />

        <Button.Transparent
          preIcon="Hidden"
          ml={spacing(1)}
          iconSize={20}
          iconColor={colors.blueGray300}
          onClick={() => appData.setState(s => ({ showAdminBanner: !s.showAdminBanner }))}
        />
      </Row>
    </Wrapper>
  )
}
