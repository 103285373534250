import { EditTableField } from './EditTableField'
import { FieldsDropdown } from './FieldsDropdown'
import { FieldInput } from './Input'
import { ListEntry } from './ListEntry'
import { TableField } from './TableField'
import { CellArea, CustomFieldSelectOption } from './TableField/components/styled'

export const Fields = {
  CellArea,
  CustomFieldSelectOption,
  Dropdown: FieldsDropdown,
  Input: FieldInput,
  ListEntry,
  EditTableField,
  TableField,
}
