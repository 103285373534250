"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sector = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Sector = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M14.2374 4.66083C14.2374 10.4121 10.7043 13.6185 8.47935 14.5454C8.17226 14.6733 7.82678 14.6733 7.51969 14.5454C4.73755 13.3863 1.76172 9.81968 1.76172 4.66083C1.76172 4.15718 2.06456 3.70293 2.52946 3.50922L7.51972 1.42995C7.82681 1.302 8.17229 1.302 8.47938 1.42995L13.4696 3.50922C13.9345 3.70294 14.2374 4.15719 14.2374 4.66083ZM7.99954 12.9341L8.00125 12.935C10.4375 11.7215 12.4846 8.87237 12.5709 4.9368L7.99954 3.03206V12.9341Z", fill: color })));
exports.Sector = Sector;
