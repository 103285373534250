"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeaderCell = exports.Resizer = exports.HeaderCellWrapper = void 0;
const enums_1 = require("@basisboard/basis-common/lib/enums");
const polished_1 = require("polished");
const React = __importStar(require("react"));
const styled_components_1 = __importStar(require("styled-components"));
const styled_system_1 = require("styled-system");
const styles_1 = require("../../../../styles");
const utils_1 = require("../../../../utils");
const Div_1 = require("../../../Div");
const Icon_1 = require("../../../Icon");
const Row_1 = require("../../../Row");
const Typography_1 = require("../../../Typography");
const ActionsWrapper_1 = require("../ActionsWrapper");
const controller_1 = require("./controller");
exports.HeaderCellWrapper = styled_components_1.default.th `
  height: 40px;
  margin: 0;
  padding: 0 ${(0, styles_1.spacing)(2)};

  background-color: ${styles_1.colors.blueGray};

  & > div > span {
      max-width: 100%;
      ${Typography_1.EllipsisOverflow};
      font-size: ${(0, polished_1.rem)(12)};
      font-weight: 500;
      text-transform: uppercase;
      text-align: left;
      color: ${styles_1.colors.blueGray500};
      overflow: hidden;
    }

  position: relative;

  ::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    border-width: 0;
    border-color: ${styles_1.colors.blueGrayBorder};
    border-style: solid;
  }

  ${({ hideBorder }) => !hideBorder &&
    (0, styled_components_1.css /* css */) `
      ::after {
        border-bottom-width: 1px;
      }

      &:not(:last-child)::after {
        border-right-width: 1px;
      }
    `}

  z-index: 3;

  :first-child, &.sticky {
    position: sticky;
    left: 0px;

    z-index: 4;
  }

  &.sticky {
    left: 40px;
  }

  ${styled_system_1.width};
  ${styled_system_1.height};
  ${styled_system_1.background};
  ${styled_system_1.space};

  ${({ onClick }) => onClick &&
    (0, styled_components_1.css) `
      cursor: pointer;
      :hover {
        background-color: ${(0, polished_1.darken)(0.05, styles_1.colors.blueGray)};
      }
    `};

  ${({ showActions }) => showActions && 'pointer-events: none'};
  }
`;
exports.Resizer = styled_components_1.default.div `
  width: 10px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-right: 2px solid transparent;

  :hover,
  &.active {
    cursor: col-resize;
    border-right: 2px solid #e7eafe;
  }
`;
exports.HeaderCell = React.forwardRef(({ children, width, minWidth = 50, actionsWidth, height, onResize, onSort, preventResize, sortDirection, sortable, customActions, backgroundProps, hideBorder, spaceProps, sticky, }, forwardRef) => {
    const [showActions, setShowActions] = React.useState(false);
    const [resizing, setResizing] = React.useState(false);
    const ref = React.useRef();
    const resizeHandler = React.useRef(new controller_1.HeaderResizeController(onResize, ref, minWidth, setResizing));
    React.useEffect(() => {
        if (!showActions)
            return;
        const onKeyDown = e => {
            if (e.key === 'Escape') {
                setShowActions(false);
            }
        };
        const onClick = e => {
            if (!ref.current.contains(e.target) &&
                !document.querySelector('#overlays').contains(e.target)) {
                setShowActions(false);
            }
        };
        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('click', onClick);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('click', onClick);
        };
    }, [showActions]);
    const combinedRefs = (0, utils_1.mergeRefs)([ref, forwardRef]);
    const handleClick = customActions
        ? () => setShowActions(true)
        : sortable
            ? () => onSort === null || onSort === void 0 ? void 0 : onSort(sortDirection
                ? { [enums_1.SortDirection.ASC]: enums_1.SortDirection.DESC, [enums_1.SortDirection.DESC]: null }[sortDirection]
                : enums_1.SortDirection.ASC)
            : undefined;
    return (React.createElement(exports.HeaderCellWrapper, Object.assign({ "data-testid": "header-cell", ref: combinedRefs, width: width, height: height, onClick: resizing ? undefined : handleClick, className: sticky ? 'sticky' : undefined, showActions: showActions, hideBorder: hideBorder }, backgroundProps, spaceProps),
        React.createElement(Row_1.Row, { width: 1, justifyContent: "flex-start" },
            children,
            sortDirection &&
                (0, utils_1.box)(Icon_1.Icon[sortDirection === enums_1.SortDirection.ASC ? 'SortAscArrow' : 'SortDescArrow']).fold(Ic => (React.createElement(Div_1.Div, { ml: "auto" },
                    React.createElement(Ic, { color: styles_1.colors.blueGray500 }))))),
        !preventResize && (React.createElement(exports.Resizer, { "aria-hidden": true, role: "separator", onMouseDown: typeof width !== 'string' && resizeHandler.current.onMouseDown(width) })),
        showActions && (React.createElement(ActionsWrapper_1.ActionsWrapper, { width: actionsWidth || width || minWidth, anchorElement: ref.current, onSort: onSort, sortDirection: sortDirection, sortable: sortable, customActions: customActions === null || customActions === void 0 ? void 0 : customActions(() => setShowActions(false)), onDismiss: () => setShowActions(false) }, children))));
});
