"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectedListEntry = exports.SelectedList = exports.NestedListItem = exports.ListEntry = exports.List = exports.Input = exports.Wrapper = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const styled_system_1 = require("styled-system");
const styles_1 = require("../../styles");
exports.Wrapper = styled_components_1.default.div(({ focused, hasPrefix, disabled, height }) => (0, styled_components_1.css) `
  box-sizing: border-box;
  ${(0, styles_1.heightMixin)(height)};
  ${styles_1.borderRadiusMixin};
  ${(0, styles_1.borderMixin)('default')};
  position: relative;
  padding: ${(0, styles_1.spacing)(0.5)} ${(0, styles_1.spacing)(2)};
  width: 100%;
  background: transparent;
  background-color: ${styles_1.colors.white};
  display: flex;
  align-items: center;

  ${styled_system_1.width};

  outline: 0;

  transition: ${styles_1.DEFAULT_TRANSITION};

  &:hover {
    ${(0, styles_1.borderMixin)('hover')};
  }

  ${!disabled &&
    focused &&
    `
      border-color: ${styles_1.colors.accent};
    `}

  ${disabled &&
    focused &&
    `
    outline: 0;
  `}

  ${hasPrefix &&
    `
      padding-left: ${(0, styles_1.spacing)(5)};
    `}

  ${disabled
    ? `
          background: ${styles_1.colors.lightGray3};
          cursor: not-allowed;
        `
    : `
          &:hover {
            border-color: ${styles_1.colors.accent};
          }
        `}
`);
exports.Input = styled_components_1.default.input(({ disabled, hasIcon }) => (0, styled_components_1.css) `
    border: 0;
    outline: none;
    width: ${hasIcon ? 'calc(100% - 16px)' : '100%'};
    height: auto;

    color: ${styles_1.colors.darkBlue};
    font-size: 0.875rem;
    font-weight: 500;

    text-overflow: ellipsis;

    &:focus {
      width: ${hasIcon ? 'calc(100% - 16px)' : '100%'};
      && {
        outline: none;
      }
    }

    ${disabled &&
    (0, styled_components_1.css) `
        background: ${styles_1.colors.lightGray3};
        cursor: inherit;
      `}
  `);
exports.List = styled_components_1.default.ul `
  list-style: none;
  padding: 0;
  width: 100%;
`;
exports.ListEntry = styled_components_1.default.li `
  height: 40px;
  width: 100%;
  padding: 0 ${(0, styles_1.spacing)(2)};
  background: ${styles_1.colors.white};

  display: flex;
  align-items: center;
  justify-content: flex-start;

  transition: ${styles_1.DEFAULT_TRANSITION};

  &:hover {
    background: ${styles_1.colors.lightGray3};
  }

  ${({ selected }) => selected &&
    (0, styled_components_1.css) `
      background: ${styles_1.colors.lightGray3};
    `}

  ${styled_system_1.space};
`;
exports.NestedListItem = styled_components_1.default.li `
  height: auto;
  width: 100%;
  padding: 0;
  ${styled_system_1.space};
`;
exports.SelectedList = (0, styled_components_1.default)(exports.List) `
  margin: 0;
  display: flex;
  align-items: center;
  overflow: auto;
  ${styles_1.scrollBarMixin};
`;
exports.SelectedListEntry = (0, styled_components_1.default)(exports.ListEntry) `
  display: inline-flex;
  height: 22px;
  width: auto;
  margin: ${(0, styles_1.spacing)(0.5)};
  padding: 0;
`;
