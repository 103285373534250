"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoubleCheck = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const DoubleCheck = ({ width = 20, height = 20, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 20 20" },
    React.createElement("path", { d: "M18.0734 7.25309L16.8973 6.07018C16.5725 5.74367 16.0458 5.74367 15.7213 6.07018L7.92074 13.9155L4.27876 10.2528C3.95388 9.92593 3.42724 9.92593 3.10268 10.2528L1.92659 11.4357C1.6017 11.7622 1.6017 12.2921 1.92659 12.6186L7.33269 18.0559C7.65758 18.3827 8.18422 18.3827 8.50878 18.0559L18.0734 8.43633C18.3983 8.10949 18.3983 7.57993 18.0734 7.25309ZM7.09455 10.8515C7.31678 11.0796 7.6852 11.0796 7.91359 10.8574L14.8035 4.026C15.0315 3.80378 15.0315 3.43535 14.8093 3.20696L13.4464 1.83854C13.2242 1.61047 12.8558 1.61047 12.6274 1.83269L7.52145 6.90352L5.30475 4.66927C5.08253 4.4412 4.71411 4.4412 4.48604 4.66342L3.1173 6.02047C2.88923 6.2427 2.88923 6.61112 3.11145 6.83919L7.09455 10.8515Z", fill: color })));
exports.DoubleCheck = DoubleCheck;
