import { EmailVariable } from '@basisboard/basis-common/lib/api'
import { fromBoolean } from '@basisboard/basis-ui/es/utils'
import 'quill-mention'
import 'quill-mention/dist/quill.mention.css'
import { Quill } from 'react-quill'

const Embed = Quill.import('blots/block')

class MentionsBlot extends Embed {}
MentionsBlot.blotName = 'mentions'
MentionsBlot.tagName = 'span'
MentionsBlot.className = 'ql-mention-select'
Quill.register(MentionsBlot)

function insertMention() {
  const text = '@'

  const cursorPosition = this.quill.getSelection()?.index || 0

  this.quill.insertText(cursorPosition, text)
}

function insertField() {
  const text = '#'

  const cursorPosition = this.quill.getSelection()?.index || 0

  this.quill.insertText(cursorPosition, text)
}

export const MODULES = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      mentions: insertMention,
      fields: insertField,
    },
  },
  clipboard: {
    matchVisual: true,
  },
}

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
export const FORMATS = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'mention',
  'field',
]

interface MentionValue {
  id: string
  value: string
}

const wrapEmailVariable = (variable: EmailVariable) => `##${variable}##`

const fieldsValues: MentionValue[] = [
  { id: wrapEmailVariable(EmailVariable.ProjectName), value: "Project's name" },
  { id: wrapEmailVariable(EmailVariable.ProjectLocation), value: "Project's location" },
]

const createSource = (searchTerm, renderList, denotationChar, users) => {
  const values = denotationChar === '@' ? users : fieldsValues

  if (searchTerm.length === 0) {
    renderList(values, searchTerm)
  } else {
    const matches = []
    for (let i = 0; i < values.length; i++)
      if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i])
    renderList(matches, searchTerm)
  }
}

export const createCustomTools = (
  allowMentions: boolean,
  allowFields: boolean,
  users: MentionValue[],
) =>
  fromBoolean(allowMentions || allowFields).fold(
    () => ({}),
    () => ({
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: [...(allowMentions ? ['@'] : []), ...(allowFields ? ['#'] : [])],
        showDenotationChar: true,
        source: (searchTerm, renderList, denotationChar) =>
          createSource(searchTerm, renderList, denotationChar, users),
      },
    }),
  )
