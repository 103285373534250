import {
  FilterEntity,
  FilterIdenfier,
  FilterOperation,
  FilterRoot,
  TimeFrameFilter,
} from '@basisboard/basis-common/lib/api'
import { CustomFieldEntity } from '@basisboard/basis-common/lib/api/custom-fields'
import { box } from '@basisboard/basis-ui/es/utils'
import moment from 'moment'
import equals from 'ramda/src/equals'

export const getDatesForTimeRange = (timeRange: TimeFrameFilter) =>
  timeRange !== TimeFrameFilter.AllTime
    ? {
        from: {
          [TimeFrameFilter.Last12Months]: moment().subtract(12, 'months'),
          [TimeFrameFilter.Last3Months]: moment().subtract(3, 'months'),
          [TimeFrameFilter.PreviousMonth]: moment()
            .subtract(1, 'month')
            .startOf('month'),
          [TimeFrameFilter.PreviousYear]: moment()
            .subtract(1, 'year')
            .startOf('year'),
          [TimeFrameFilter.ThisMonth]: moment().startOf('month'),
          [TimeFrameFilter.ThisYear]: moment().startOf('year'),
        }[timeRange],

        to: {
          [TimeFrameFilter.Last12Months]: moment(),
          [TimeFrameFilter.Last3Months]: moment(),
          [TimeFrameFilter.PreviousMonth]: moment()
            .subtract(1, 'month')
            .endOf('month'),
          [TimeFrameFilter.PreviousYear]: moment()
            .subtract(1, 'year')
            .endOf('year'),
          [TimeFrameFilter.ThisMonth]: moment().endOf('month'),
          [TimeFrameFilter.ThisYear]: moment().endOf('year'),
        }[timeRange],
      }
    : {}

export const createFilterRoot = (
  entity: CustomFieldEntity,
  identifier: FilterIdenfier | string,
  timeRange: TimeFrameFilter,
): FilterRoot =>
  box(getDatesForTimeRange(timeRange)).fold(({ from, to }) => ({
    and: [
      {
        filterIdentitier: {
          entity:
            {
              [CustomFieldEntity.Project]: 'project',
              [CustomFieldEntity.Company]: 'company',
            }[entity] || 'project',
          identifier,
        } as any,

        ...(timeRange === TimeFrameFilter.AllTime
          ? { not: true, operation: FilterOperation.Empty }
          : { operation: FilterOperation.After }),

        compareValue: from?.format('YYYY-MM-DD') || 'empty',
      },

      ...(timeRange === TimeFrameFilter.AllTime
        ? []
        : [
            {
              filterIdentitier: {
                entity:
                  {
                    [CustomFieldEntity.Project]: 'project',
                    [CustomFieldEntity.Company]: 'company',
                  }[entity] || 'project',
                identifier,
              } as any,
              operation: FilterOperation.Before,
              compareValue: to?.format('YYYY-MM-DD') || 'empty',
            },
          ]),
    ],
  }))

export const isSameFilter = (a: FilterEntity, b: FilterEntity) =>
  equals(a.compareValue === b.compareValue) &&
  Boolean(a.not) === Boolean(b.not) &&
  a.operation === b.operation &&
  a.filterIdentitier.identifier === b.filterIdentitier.identifier
