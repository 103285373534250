import { StageCode, STAGE_ID } from '@basisboard/basis-common/lib/api'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { PrintOnly } from '@basisboard/basis-ui/es/components/PrintOnly'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { box, isDarkColor } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import React, { FunctionComponent } from 'react'
import { useLocation } from 'react-router'
import { Menu, Searcher } from '../../../../components'
import { NestedProject } from '../../../../types'
import { projectRepository } from '../../../Projects/container'
import { showErrorToast } from '../../../Toast'
import { StagesState } from '../../container'
import { showMarkAsDeclinedToast } from '../../toasts'
import { Color, Container } from './styled'

interface Props {
  selectedStageId: STAGE_ID
  project: Pick<NestedProject, 'id' | 'name' | 'location' | 'bidDeadline' | 'customFields'>
  onChange?: (value: string) => void
  deleted?: boolean
  maxWidth?: number
  name?: string
  searchable?: boolean
}

export const StagesSelect: FunctionComponent<Props> = ({
  selectedStageId,
  project,
  onChange,
  deleted,
  maxWidth,
  name,
  searchable = false,
}) => {
  const [currentStageId, setCurrentStageId] = React.useState(selectedStageId)
  const stagesData = useContainer(StagesState)
  const location = useLocation()

  React.useEffect(() => {
    setCurrentStageId(selectedStageId)
  }, [selectedStageId])

  const { stages, error, loading } = stagesData.state

  if (loading || error) {
    return null
  }

  const selectedStage = stages.find(stage => stage.id === currentStageId)

  if (!selectedStage) return null

  const shouldShowDeclinedToast = location.pathname === '/projects'

  const handleChangeStage = (stageId: STAGE_ID) => {
    const stage = stages.find(stage => stage.id === stageId)

    if (stage === undefined) return
    switch (stage.code) {
      case StageCode.Lost:
        projectRepository()
          .changeStageForProject(stageId, project.id)
          .then(() => setCurrentStageId(stageId))
          .then(() => onChange?.(stageId))

        break

      case StageCode.Awarded:
        projectRepository()
          .changeStageForProject(stageId, project.id)
          .then(() => setCurrentStageId(stageId))
          .then(() => onChange?.(stageId))

        break

      case StageCode.Declined:
        shouldShowDeclinedToast &&
          showMarkAsDeclinedToast(
            stagesData.getReasonsForStageCode(StageCode.Declined),
            stageReasonId =>
              projectRepository().updateProjectReason(
                project.id,
                stageReasonId,
                StageCode.Declined,
              ),
          )
        projectRepository()
          .changeStageForProject(stageId, project.id)
          .then(() => setCurrentStageId(stageId))
          .then(() => onChange?.(stageId))
          .catch(() => showErrorToast({ message: "Couldn't move project to selected stage" }))
        break

      default:
        projectRepository()
          .changeStageForProject(stageId, project.id)
          .then(() => setCurrentStageId(stageId))
          .then(() => onChange?.(stageId))
          .catch(() => showErrorToast({ message: "Couldn't move project to selected stage" }))
    }
  }

  const color = deleted ? colors.error : selectedStage.color

  return (
    <>
      <Container color={color} maxWidth={maxWidth}>
        {searchable ? (
          <Searcher
            placeHolder="Type to search..."
            onChange={stages => (stages?.length > 1 ? handleChangeStage(stages[1].id) : null)}
            options={stages}
            renderEntry={stage =>
              stage.id === selectedStage.id ? (
                <button data-testid="stage" style={{ height: 32, maxWidth: 200 }}>
                  <Text
                    as="span"
                    color={isDarkColor(selectedStage.color) ? colors.white : colors.darkBlue}
                  >
                    {selectedStage.name}
                  </Text>
                </button>
              ) : (
                <Div as="span" display="flex">
                  <Color style={{ backgroundColor: stage.color || colors.error }} />
                  <Text>{stage.name}</Text>
                </Div>
              )
            }
            value={[selectedStage]}
            onFilter={(stage, search) =>
              !search ? true : box(new RegExp(search, 'i')).fold(regex => regex.test(stage.name))
            }
          />
        ) : (
          <Menu
            title={deleted ? 'Deleted' : selectedStage.name}
            width={200}
            renderTrigger={onOpen => (
              <button data-testid="stage" onClick={onOpen} style={{ height: 32 }}>
                <Text
                  as="span"
                  color={isDarkColor(selectedStage.color) ? colors.white : colors.darkBlue}
                >
                  {selectedStage.name}
                </Text>
              </button>
            )}
            actions={stages.map(stage => ({
              active: stage.id === selectedStage.id,
              render: onClose => (
                <button
                  onClick={() => {
                    handleChangeStage(stage.id)
                    onClose()
                  }}
                >
                  <Div
                    px={spacing(2)}
                    py={spacing(0.5)}
                    as="span"
                    display="flex"
                    alignItems="center"
                  >
                    <Color style={{ backgroundColor: stage.color || colors.error }} />
                    <Text>{stage.name}</Text>
                  </Div>
                </button>
              ),
            }))}
          />
        )}
        <input name={name} value={currentStageId} className="visually-hidden" />
      </Container>

      <PrintOnly>
        <Div display="flex" alignItems="center">
          <Color style={{ backgroundColor: color }} />
          {selectedStage.name}
        </Div>
      </PrintOnly>
    </>
  )
}
