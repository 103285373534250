import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors } from '@basisboard/basis-ui/es/styles/colors'
import { borderRadiusMixin, heightMixin } from '@basisboard/basis-ui/es/styles/mixins'
import { spacing } from '@basisboard/basis-ui/es/styles/spacing'
import { box } from '@basisboard/basis-ui/es/utils/monads'
import { darken, lighten } from 'polished'
import styled, { css } from 'styled-components'

export const tagColorsVariants = {
  mainAttachment: {
    default: {
      labelBackground: lighten(0.3, colors.warning),
      labelText: darken(0.3, colors.warning),
      countBackground: colors.warning,
      countText: darken(0.35, colors.warning),
    },
    active: {
      labelBackground: colors.warning,
      labelText: colors.white,
      countBackground: darken(0.25, colors.warning),
      countText: colors.white,
    },
    hover: {
      labelBackground: lighten(0.2, colors.warning),
      labelText: darken(0.4, colors.warning),
      countBackground: darken(0.1, colors.warning),
      countText: darken(0.4, colors.warning),
    },
  },
  attachment: {
    default: {
      labelBackground: lighten(0.45, colors.blue),
      labelText: darken(0.1, colors.blue),
      countBackground: lighten(0.3, colors.blue),
      countText: darken(0.15, colors.blue),
    },
    active: {
      labelBackground: darken(0.1, colors.blue),
      labelText: colors.white,
      countBackground: darken(0.2, colors.blue),
      countText: colors.white,
    },
    hover: {
      labelBackground: lighten(0.3, colors.blue),
      labelText: darken(0.2, colors.blue),
      countBackground: lighten(0.2, colors.blue),
      countText: darken(0.3, colors.blue),
    },
  },
}

function getColorVariant({
  isActive,
  isHovered,
  isMain,
}: {
  isMain: boolean
  isHovered?: boolean
  isActive?: boolean
}) {
  if (isMain) {
    if (isActive) {
      return tagColorsVariants.mainAttachment.active
    }
    if (isHovered) {
      return tagColorsVariants.mainAttachment.hover
    }
    return tagColorsVariants.mainAttachment.default
  }
  if (isActive) {
    return tagColorsVariants.attachment.active
  }
  if (isHovered) {
    return tagColorsVariants.attachment.hover
  }
  return tagColorsVariants.attachment.default
}

interface KeywordTagProps {
  isActive: boolean
  isMain: boolean
}

const TextBase = styled(Text)`
  text-transform: uppercase;
  padding-left: ${spacing(1)};
  padding-right: ${spacing(1)};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Count = styled(TextBase)<KeywordTagProps>(({ isActive, isMain }) =>
  box(getColorVariant({ isActive, isMain })).fold(
    colors => css`
      background-color: ${colors.countBackground};
      color: ${colors.countText};
      min-width: 24px;
    `,
  ),
)

export const Keyword = styled(TextBase)<KeywordTagProps>(({ isActive, isMain }) =>
  box(getColorVariant({ isActive, isMain })).fold(
    colors => css`
      background-color: ${colors.labelBackground};
      color: ${colors.labelText};
    `,
  ),
)

export const Wrap = styled.button<{ isMain: boolean }>`
  ${borderRadiusMixin};
  ${heightMixin('xsmall')};
  overflow: hidden;
  display: flex;
  padding: 0;
  margin-right: ${spacing(1)};
  margin-bottom: ${spacing(1)};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:not([disabled]):hover > ${Count} {
    ${({ isMain }) => css`
      background-color: ${getColorVariant({ isHovered: true, isMain }).countBackground};
      color: ${getColorVariant({ isHovered: true, isMain }).countText};
    `}
  }
  &:not([disabled]):hover > ${Keyword} {
    ${({ isMain }) => css`
      background-color: ${getColorVariant({ isHovered: true, isMain }).labelBackground};
      color: ${getColorVariant({ isHovered: true, isMain }).labelText};
    `}
  }
`
