import { Div } from '@basisboard/basis-ui/es/components/Div'
import * as Sentry from '@sentry/browser'
import * as React from 'react'
import { Empty } from '../../../components'
import { eventBus, EventBusType } from '../../../services'

export class ErrorBoundary extends React.Component<
  unknown,
  { error?: { name: string }; hasError: boolean }
> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    Sentry.captureException(error, {
      tags: {
        error_type: 'ui',
      },
    })
    eventBus.publish(EventBusType.Error, error)
    return { hasError: true, error }
  }

  render() {
    const { error, hasError } = this.state

    if (hasError) {
      return (
        <Div display="flex" width={1} height="100vh" alignItems="center" justifyContent="center">
          {error?.name === 'ChunkLoadError' ? (
            <Empty
              title="New release!"
              description="Basisboard has been updated! Please refresh your browser to see the latest content."
              image={
                <img
                  src="/imgs/empty/no-results.png"
                  style={{ width: 200 }}
                  alt="Something went wrong illustration"
                />
              }
              action="Refresh"
              onAction={() => (window.location.href = window.location.href)}
            />
          ) : (
            <Empty
              title="Something went wrong"
              description="This one is on us! We'll investigate this issue, but, in the meantime, refresh your browser to jump back to your board."
              image={
                <img
                  src="/imgs/empty/no-results.png"
                  style={{ width: 200 }}
                  alt="Something went wrong illustration"
                />
              }
              action="Refresh"
              onAction={() => (window.location.href = window.location.href)}
            />
          )}
        </Div>
      )
    }

    return this.props.children
  }
}
