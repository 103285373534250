/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Paging } from '@basisboard/basis-common/lib/api'
import { box, debounce, fromBoolean, generateUuid } from '@basisboard/basis-ui/es/utils'
import { Container } from '@containrz/react-hook'
import all from 'ramda/src/all'
import any from 'ramda/src/any'
import equals from 'ramda/src/equals'
import isEmpty from 'ramda/src/isEmpty'
import keys from 'ramda/src/keys'
import omit from 'ramda/src/omit'
import remove from 'ramda/src/remove'
import slice from 'ramda/src/slice'
import uniq from 'ramda/src/uniq'
import uniqBy from 'ramda/src/uniqBy'
import { whoami } from '../../containers/App/container'
import { putUserSetting } from '../../containers/UserSettings/api'
import { eventBus, EventBusType } from '../../services'
import { checkAllEntries } from './helpers'
import { Filter, Group, GroupValues, Position, Sort, View, ViewsSettings } from './types'

export interface ViewScreenContainerState<T extends { id: string }, J = any> {
  viewId: string
  defaultViews?: View<J>[]
  views: View<J>[]
  localViews: View<J>[]
  selectedView?: View<J>
  entries?: T[]
  groupValues?: (GroupValues & {
    loading?: boolean
    totalLoaded?: number
    done?: boolean
    collapsed?: boolean
    display?: boolean
  })[]
  totalEntries?: number
  selectedEntries?: string[]
  loading?: boolean
  loadingFromGroups?: boolean
  fetchingMore?: boolean
  done?: boolean
  searchQuery?: string
  error?: boolean
  totals?: Record<string, number>
}

const getSettingsKey = (viewId: string) => `${viewId}-pageSettings`

export const viewScreenInitialState: Partial<ViewScreenContainerState<any>> = {
  loading: true,
  fetchingMore: false,
  loadingFromGroups: false,
  entries: [],
  totalEntries: 0,
  groupValues: [],
  searchQuery: '',
  selectedEntries: [],
  error: false,
}

type Fetcher<K> = (params: {
  limit?: number
  offset?: number
  filter?: Filter
  sorts?: Sort[]
}) => Promise<{ paging: Paging; entries: K[] }>

type GroupFetcher = (params: {
  limit?: number
  offset?: number
  filter?: Filter
  sorts?: Sort[]
  group?: Group
}) => Promise<GroupValues[]>

export class ViewScreenContainer<
  K extends { id: string } = any,
  T extends ViewScreenContainerState<K, J> = any,
  J = any
> extends Container<T> {
  fetchEntries: Fetcher<K>
  fetchGroups: GroupFetcher
  initialGroupsCount = 5
  countLimit = 1

  registeredEvents: { unregister: () => void }[] = []

  private nonce: number
  viewId: string

  viewStateMap: Map<string, ViewScreenContainerState<K, J>> = new Map<
    string,
    ViewScreenContainerState<K, J>
  >()

  constructor(viewId: string, defaultViews?: View<J>[]) {
    super()

    this.viewId = viewId

    const viewSettings: ViewsSettings = box(
      whoami() || { hidden: true, globalAdmin: false, basisAdmin: false },
    ).fold(({ hidden, globalAdmin, basisAdmin }) => any(Boolean, [hidden, globalAdmin, basisAdmin]))
      ? undefined
      : whoami()?.settings?.[getSettingsKey(viewId)]

    // @ts-ignore
    this.state = {
      ...viewScreenInitialState,
      totals: {},
      viewId,
      views: viewSettings?.views || [],
      localViews: viewSettings?.views || [],
      defaultViews,
      selectedView:
        (viewSettings?.views || []).find(v => v.id === viewSettings?.selectedViewId) ||
        viewSettings?.views?.[0],
    } as T

    this.registeredEvents = [
      eventBus.register(EventBusType.DeleteCustomField, ({ fieldId }) => {
        this.updateViews(
          this.state.views.map(v => ({
            ...v,
            fields: v.fields.filter(f => f.id !== fieldId),
          })),
          false,
        )
      }),
    ]
  }

  destroy = () => {
    this.registeredEvents.forEach(({ unregister }) => unregister())
  }

  setLastScrollPosition = (viewId: string, scrollPosition: Position) => {
    // @ts-ignore
    this.state = {
      ...this.state,
      views: this.state.views.map(v => (v.id === viewId ? { ...v, scrollPosition } : v)),
      selectedView:
        this.state.selectedView.id === viewId
          ? { ...this.state.selectedView, scrollPosition }
          : this.state.selectedView,
    }
  }

  buildQuery = (
    view: View,
    config?: { limit?: number; isLoadingMore?: boolean; groupIdentifier?: string; ids?: string[] },
  ): {
    limit?: number
    offset?: number
    filter?: Record<string, any>
    group?: Group
    sorts?: Sort[]
  } =>
    config?.ids
      ? {
          filter: {
            ids: box(
              this.state.groupValues.find(g => g.groupIdentifier === config.groupIdentifier)
                ?.totalLoaded || 0,
            ).fold(totalLoaded =>
              slice(totalLoaded, totalLoaded + (config?.limit ?? 50), config.ids),
            ),
          },
        }
      : {
          filter: {
            ...view.filter,
            ...((this.state.searchQuery || '').length > 0
              ? { searchQuery: this.state.searchQuery }
              : {}),
            ...(config?.ids ? { ids: config.ids } : {}),
          },
          ...(view.group && !config?.groupIdentifier
            ? { group: view.group }
            : { limit: config?.limit ?? 50 }),
          ...(config?.isLoadingMore ? { offset: this.state.entries.length } : {}),
          sorts: view.sorts,
        }

  getEntries = (
    config?: {
      limit?: number
      isLoadingMore?: boolean
      groupIdentifier?: string
      ids?: string[]
    },
    nonce = Date.now(),
  ) => {
    this.nonce = nonce

    if (config?.isLoadingMore) {
      // @ts-ignore
      this.setState(s => ({
        loading: false,
        fetchingMore: true,
        groupValues: config?.groupIdentifier
          ? s.groupValues.map(g =>
              config?.groupIdentifier === g.groupIdentifier ? { ...g, loading: true } : g,
            )
          : s.groupValues,
      }))
    }

    const data = this.buildQuery(this.state.selectedView, config)

    if (data.group && isEmpty(this.state.groupValues)) {
      return this.fetchGroups?.(data).then(async groups => {
        if (this.nonce !== nonce) {
          return
        }
        // @ts-ignore
        this.setState({
          groupValues: groups.map(g => ({
            ...g,
            loading: true,
            done: false,
            totalLoaded: 0,
            display: false,
          })),
          loadingFromGroups: groups.length > 0,
          loading: groups.length > 0,
          totalEntries: groups.reduce((acc, g) => uniq([...acc, ...g.values]), []).length,
        })

        setTimeout(() => {
          if (groups.length > 0) {
            this.loadMoreGroups(false)
          }
        }, 1)

        return { data, groups }
      })
    }

    return this.fetchEntries(data).then(response => {
      if (!response || this.nonce !== nonce) {
        return
      }

      const entries =
        config?.isLoadingMore || Boolean(config?.groupIdentifier)
          ? uniqBy(({ id }) => id, [...this.state.entries, ...response.entries])
          : response.entries

      if (!equals(data, this.buildQuery(this.state.selectedView, config))) {
        return
      }

      // @ts-ignore
      this.setState(s => ({
        entries,
        loading: false,
        ...(Boolean(config?.groupIdentifier)
          ? {
              groupValues: s.groupValues.map(g =>
                g.groupIdentifier === config?.groupIdentifier
                  ? {
                      ...g,
                      loading: false,
                      done: g.totalLoaded + response.entries.length >= g.values.length,
                      totalLoaded: g.totalLoaded + response.entries.length,
                      display: true,
                    }
                  : g,
              ),
            }
          : { totalEntries: response.paging.total }),
        done: response.paging.total <= entries.length,
        fetchingMore: false,
      }))

      return { data, response }
    })
  }

  loadMoreFromGroup = ({
    groupIdentifier,
    values,
    done,
  }: ViewScreenContainerState<K>['groupValues'][number]) => {
    if (done) {
      return
    }

    const ids = (values as string[])
      .filter(v => !this.state.entries.some(e => e.id === v))
      .slice(0, 50)

    // @ts-ignore
    this.setState(s => ({
      groupValues: s.groupValues.map(g =>
        g.groupIdentifier === groupIdentifier ? { ...g, loading: true, collapsed: false } : g,
      ),
    }))

    this.fetchEntries({ filter: { ids } }).then(response => {
      const entries = uniqBy(({ id }) => id, [...this.state.entries, ...(response?.entries || [])])

      // @ts-ignore
      this.setState(s => ({
        entries,
        groupValues: s.groupValues.map(g => {
          // @ts-ignore
          const totalLoaded = g.values.reduce(
            (acc, id) => acc + (entries.some(e => e.id === id) ? 1 : 0),
            0,
          )

          return {
            ...g,
            loading: false,
            done: totalLoaded >= g.values.length,
            totalLoaded,
          }
        }),
        fetchingMore: false,
        loadingFromGroups: false,
        loading: false,
      }))
    })
  }

  /**
   * loadEntries must be overwritten on extending class so that you can have control over the promise on `getEntries`
   */
  loadEntries = () => null

  /**
   * loadMoreEntries must be overwritten on extending class so that you can have control over the promise on `getEntries`
   */
  loadMoreEntries = () => null

  loadMoreGroups = (fetchingMore = true) => {
    if (all(g => g.display, this.state.groupValues)) {
      return
    }

    const firstHiddenIndex = Math.max(
      this.state.groupValues.findIndex(g => !g.display),
      0,
    )

    const { ids, groupIdentifiers } = remove(
      0,
      Math.max(firstHiddenIndex, 0),
      this.state.groupValues,
    ).reduce(
      (acc, group) =>
        box(acc.ids.length + 10 >= 40).fold(exceedes =>
          exceedes
            ? acc
            : {
                groupIdentifiers: [...acc.groupIdentifiers, group.groupIdentifier],
                ids: uniq([
                  ...acc.ids,
                  ...(group.values as string[])
                    .filter(id => !this.state.entries.some(e => e.id === id))
                    .slice(0, Math.min(10, 30 - acc.ids.length)),
                ]),
              },
        ),
      { groupIdentifiers: [], ids: [] },
    )

    // @ts-ignore
    this.setState(s => ({
      groupValues: s.groupValues.map(g =>
        box(groupIdentifiers.some(sg => sg === g.groupIdentifier)).fold(isInSliced => ({
          ...g,
          loading: isInSliced,
          display: g.display || isInSliced,
        })),
      ),
      loadingFromGroups: true,
      fetchingMore,
    }))

    this.fetchEntries({ filter: { ids } }).then(response => {
      const entries = uniqBy(({ id }) => id, [...this.state.entries, ...(response?.entries || [])])

      // @ts-ignore
      this.setState(s => ({
        entries,
        groupValues: s.groupValues.map(g =>
          box(groupIdentifiers.some(sg => sg === g.groupIdentifier)).fold(isInSliced => {
            // @ts-ignore
            const totalLoaded = g.values.reduce(
              (acc, id) => acc + (entries.some(e => e.id === id) ? 1 : 0),
              0,
            )
            return {
              ...g,
              loading: false,
              done: totalLoaded >= g.values.length,
              totalLoaded,
              display: g.display || isInSliced,
            }
          }),
        ),
        fetchingMore: false,
        loadingFromGroups: false,
        loading: false,
      }))
    })
  }

  loadMore = () => {
    if (
      this.state.loading ||
      this.state.fetchingMore ||
      this.state.loadingFromGroups ||
      this.state.done
    ) {
      return
    }

    if (this.state.selectedView.group) {
      this.loadMoreGroups()
    } else {
      this.loadMoreEntries()
    }
  }

  refresh = () => {
    // @ts-ignore
    this.setState({ ...viewScreenInitialState })

    this.loadEntries()
  }

  onChangeView = (selectedView: View) => {
    if (!selectedView) {
      return
    }

    const state = omit(
      ['totals', 'views', 'selectedView', 'localViews'],
      this.viewStateMap.get(selectedView.id) || viewScreenInitialState,
    )

    if (this.state.selectedView.id === selectedView.id) {
      return
    }

    this.viewStateMap.set(this.state.selectedView.id, this.state)

    // @ts-ignore
    this.setState({ ...state, selectedView })

    const hasKey = this.viewStateMap.has(selectedView.id)
    !hasKey && this.loadEntries()

    return putUserSetting<ViewsSettings>(getSettingsKey(this.state.viewId), {
      value: {
        views: this.state.views,
        selectedViewId: selectedView.id,
      },
    })
  }

  applySort = (sort: Sort) =>
    this.updateView(
      'sorts',
      // @ts-ignore
      () => (sort.direction ? [sort] : []),
      true,
      {
        ...viewScreenInitialState,
        groupValues: this.state.groupValues,
      },
    )

  // @ts-ignore
  applyGroup = (group: Group) => this.updateView('group', () => group, true, viewScreenInitialState)

  applyFilter = (filter: Partial<Filter> | null, clearOtherKeys?: boolean) => {
    const { selectedView } = this.state

    const newFilter = filter
      ? box({
          ...(clearOtherKeys ? {} : selectedView.filter),
          ...filter,
        }).fold(d => keys(d).reduce((acc, key) => (d[key] ? { ...acc, [key]: d[key] } : acc), {}))
      : {}

    // @ts-ignore
    this.updateView('filter', () => newFilter, true, viewScreenInitialState).then(() =>
      this.getTotalsForView(this.state.selectedView),
    )
  }

  onUpdateFields = () => null

  onUpdateSort = () => null

  onUpdateGroup = () => null

  createView = (view: View) => {
    // @ts-ignore
    this.updateViews([this.state.views[0], view, ...remove(0, 1, this.state.views)], false, {
      selectedView: view,
      localViews: [this.state.localViews[0], view, ...remove(0, 1, this.state.localViews)],
      defaultViews: [...this.state.defaultViews, view],
    })

    this.getTotalsForView(view)

    this.refresh()
  }

  updateViews = (
    data: Partial<View<J>> | Array<Partial<View<J>>>,
    silent?: boolean,
    stateUpdate: Partial<T> = {},
  ) => {
    const views = Array.isArray(data)
      ? ((data as Partial<View<J>>[]).map(d => ({ id: d.id || generateUuid(), ...d })) as View<J>[])
      : [...this.state.views, { id: data.id || generateUuid(), ...data } as View<J>]

    if (!silent) {
      // @ts-ignore
      this.setState(s => ({
        views,
        localViews: views,
        selectedView:
          views.find(view => view.id === (stateUpdate?.selectedView?.id || s.selectedView?.id)) ||
          views[0],
        ...stateUpdate,
      }))
    }

    return putUserSetting<ViewsSettings>(getSettingsKey(this.state.viewId), {
      value: {
        views,
        selectedViewId: this.state.selectedView?.id || views[0].id,
      },
    })
  }

  updateView = <N extends keyof View<J>>(
    dataOrKey: N | Partial<View<J>>,
    updater?: (data: Pick<View<J>, N>) => Partial<Pick<View<J>, N>>,
    refresh?: boolean,
    stateUpdate?: Partial<T>,
  ) => {
    const isKey = typeof dataOrKey === 'string'

    const data = isKey
      ? updater?.(this.state.selectedView[(dataOrKey as any) as keyof View<J>] as any)
      : dataOrKey

    const view = fromBoolean(isKey).fold(
      () => ({
        // @ts-ignore
        ...(this.state.views.find(v => v.id === (data?.id || this.state.selectedView.id)) || {}),
        ...(data || {}),
      }),
      () => ({ ...this.state.selectedView, [dataOrKey as keyof View<J>]: data }),
    )

    const views = this.state.views.map(v =>
      // @ts-ignore
      v.id === view.id ? view : v,
    )

    const selectedView = views.find(v => v.id === this.state.selectedView.id)

    return all(Boolean, [
      selectedView.id === view.id,
      this.state.selectedView.settings?.preventOverride,
      selectedView.settings?.preventOverride,
      view.name === this.state.views.find(v => v.id === view.id)?.name || '',
    ])
      ? new Promise(resolve => {
          // @ts-ignore
          this.setState({
            selectedView,
            localViews: this.state.localViews.map(lv => (lv.id === view.id ? view : lv)),
          })

          resolve(selectedView)
        }).then(() => refresh && this.refresh())
      : // @ts-ignore
        this.updateViews(views, false, {
          ...(stateUpdate?.selectedView ? {} : { selectedView }),
          ...(stateUpdate || {}),
          localViews: this.state.localViews.map(lv => (lv.id === view.id ? view : lv)),
        }).then(() => {
          refresh && this.refresh()

          setTimeout(() => {
            if (dataOrKey === 'fields') {
              this.onUpdateFields()
            } else if (dataOrKey === 'sorts') {
              this.onUpdateSort()
            } else if (dataOrKey === 'group') {
              this.onUpdateGroup()
            }
          }, 1)
        })
  }

  search = debounce((searchQuery: string) => {
    // @ts-ignore
    this.setState({ ...viewScreenInitialState, searchQuery })

    this.loadEntries()
  }, 300)

  private __dss = debounce(() => {
    // @ts-ignore
    this.setState(s => s)
  }, 10)

  debounceSetState = (
    updater:
      | Partial<ViewScreenContainerState<K, J>>
      | ((
          prevState: ViewScreenContainerState<K, J>,
        ) => Partial<ViewScreenContainerState<K, J>> | null),
  ) => {
    const nextState = box(updater instanceof Function ? updater(this.state) : updater).fold(ns =>
      ns instanceof Object ? Object.assign({}, this.state, ns) : ns,
    )

    // @ts-ignore
    this.state = nextState

    this.__dss()
  }

  selectEntry = (entryId: string, selected: boolean) =>
    // @ts-ignore
    this.setState(s => ({
      selectedEntries: selected
        ? uniq([...s.selectedEntries, entryId])
        : s.selectedEntries.filter(eId => eId !== entryId),
    }))

  clearSelection = () => {
    checkAllEntries(false)
    // @ts-ignore
    this.setState({ selectedEntries: [] })
  }

  selectAll = () => {
    checkAllEntries(true)
    // @ts-ignore
    this.setState(s => ({ selectedEntries: s.entries.map(e => e.id) }))
  }

  toggleGroup = (groupIdentifier: string) => {
    const group = this.state.groupValues.find(f => f.groupIdentifier === groupIdentifier)
    if (group && group.collapsed && group.totalLoaded < group.values.length) {
      return this.loadMoreFromGroup(group)
    }

    // @ts-ignore
    this.setState(s => ({
      groupValues: s.groupValues.map(g =>
        g.groupIdentifier === groupIdentifier ? { ...g, collapsed: !Boolean(g.collapsed) } : g,
      ),
    }))
  }

  collapseAllGroup = (collapsed: boolean) =>
    // @ts-ignore
    this.setState(s => ({
      groupValues: s.groupValues.map(g => ({
        ...g,
        collapsed: collapsed,
        display: collapsed || g.totalLoaded > 0,
      })),
    }))

  getTotals = () =>
    Promise.all(
      this.state.views
        .filter(v => v.displayTotalsInViewsList)
        .map(v =>
          (v.custom ? this.state.localViews : this.state.defaultViews).find(vw => vw.id === v.id),
        )
        .filter(Boolean)
        .map(v =>
          this.fetchEntries(
            omit(
              ['group'],
              this.buildQuery(v, {
                limit: this.countLimit ?? 1,
              }),
            ),
          )
            .then(r => ({
              [v.id]: r?.paging.total ?? undefined,
            }))
            .catch(() => ({ [v.id]: undefined })),
        ),
      // @ts-ignore
    ).then(totals => this.setState({ totals: totals.reduce((acc, t) => ({ ...acc, ...t }), {}) }))

  getTotalsForView = (view: View | string) => {
    const selectedView = box(
      this.state.defaultViews.find(
        v => v.id === (typeof view === 'string' ? (view as string) : (view as View).id),
      ),
    ).fold(v =>
      fromBoolean(v.custom).fold(
        () => v,
        () => this.state.localViews.find(vw => vw.id === v.id),
      ),
    )

    if (!selectedView.displayTotalsInViewsList) {
      return Promise.resolve()
    }

    return new Promise(resolve =>
      this.fetchEntries(
        omit(
          ['group'],
          this.buildQuery(selectedView, {
            limit: this.countLimit ?? 1,
          }),
        ),
      )
        .then(r =>
          resolve({
            [selectedView.id]: r?.paging.total ?? undefined,
          }),
        )
        .catch(() => resolve({ [selectedView.id]: undefined })),
    ).then(total =>
      // @ts-ignore
      this.setState(s => ({ totals: { ...s.totals, ...total } })),
    )
  }
}
