import { Span } from '@basisboard/basis-ui/es/components/Typography'
import { colors } from '@basisboard/basis-ui/es/styles'
import { readableName } from '@basisboard/basis-ui/es/utils/formatters'
import { getContainer } from '@containrz/react-hook'
import isEmpty from 'ramda/src/isEmpty'
import React from 'react'
import { PersonChip } from '../../../../../../components/PersonChip'
import { UsersContainer } from '../../../../../Users/container'
import { TableFieldProps } from '../../type'
import { MultiSelect } from '../MultiSelect'
import { CustomFieldSelectOption } from '../styled'

const usersToOptions = user => ({
  value: user.id,
  label: readableName(user),
  color: user.color,
})

export const UserMultiSelect: React.FC<TableFieldProps> = props => {
  const { users, allUsers } = getContainer(UsersContainer).state
  const usersWithInactive = allUsers.map(usersToOptions)
  const activeUsers = users.map(usersToOptions)

  const selectedOptions = React.useMemo(() => {
    if (!props.value) return null

    if (Array.isArray(props.value)) {
      return props.value.map(n => {
        const user = usersWithInactive.find(u => u.value === n)
        return user ? <PersonChip chipOnly key={n} name={user.label} color={user.color} /> : null
      })
    }

    const user = usersWithInactive.find(u => u.value === props.value)
    return user ? <PersonChip chipOnly name={user.label} color={user.color} /> : null
  }, [props.value])

  return (
    <MultiSelect
      searchable //={props.searchable}
      {...(props as any)}
      field={{ ...props.field, options: activeUsers }}
      renderOption={(option, props) => (
        <CustomFieldSelectOption {...(props as any)}>
          <PersonChip name={option.label ?? ''} color={option.color ?? colors.white} />
        </CustomFieldSelectOption>
      )}
    >
      {isEmpty(selectedOptions) || !selectedOptions ? (
        <Span color={colors.lightGray}>Not set</Span>
      ) : (
        selectedOptions
      )}
    </MultiSelect>
  )
}
