import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Label } from '@basisboard/basis-ui/es/components/Label'
import { borderRadiusMixin, colors, spacing } from '@basisboard/basis-ui/es/styles'
import React from 'react'
import styled, { css } from 'styled-components'
export interface RadioInputProps {
  label?: string
  initialValue: string
  name: string
  placeholder?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  options: Omit<React.HTMLProps<HTMLInputElement>, 'value'> &
    {
      label: string
      value: string
    }[]
}

const Input = styled.input.attrs({
  type: 'radio',
})`
  opacity: 0;
  position: absolute;
`

const Value = styled.label<{ active: boolean }>`
  ${({ active }) =>
    active
      ? css`
          background-color: ${colors.accent};
          color: ${colors.white};
        `
      : css`
          background-color: ${colors.lightGray3};
          color: ${colors.lightGray};
        `}

  height: 48px;
  ${borderRadiusMixin};

  font-weight: 500;
  font-size: 0.875rem;

  display: flex;
  justify-content: center;
  align-items: center;

  flex: 1;

  cursor: pointer;

  &:not(:last-child) {
    margin-right: ${spacing(1)};
  }
`

export const Radio = React.forwardRef<HTMLInputElement, RadioInputProps>(
  ({ label, initialValue, name, onChange, options }, ref) => {
    const [val, setVal] = React.useState(initialValue)

    const handleChange = e => {
      setVal(e.target.value)
      onChange && onChange(e)
    }

    return (
      <>
        {label && <Label label={label} />}
        <Div mb={spacing(1)} display="flex" justifyContent="space-between">
          {options.map((option, index) => (
            <React.Fragment key={option.value}>
              <Value active={option.value === val}>
                <Input
                  ref={index === 1 ? ref : undefined}
                  {...option}
                  name={name}
                  onChange={handleChange}
                  checked={option.value === val}
                />
                {option.label}
              </Value>
            </React.Fragment>
          ))}
        </Div>
      </>
    )
  },
)
