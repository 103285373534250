"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Google = void 0;
const React = __importStar(require("react"));
const Svg_1 = require("./Svg");
const Google = ({ width = 16, height = 16, size, color }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 59 44" },
    React.createElement("path", { d: "M4.2,44 L14,44 L14,21.1020408 L0,11 L0,39.9591837 C0,42.195102 1.883,44 4.2,44", fill: color || '#4285F4' }),
    React.createElement("path", { d: "M45,44 L54.8,44 C57.124,44 59,42.1883673 59,39.9591837 L59,11 L45,21.1020408", fill: color || '#34A853' }),
    React.createElement("path", { d: "M45,4.13450271 L45,22 L59,11.6929823 L59,6.19590625 C59,1.09736817 53.071,-1.80921081 48.92,1.24853777", fill: color || '#FBBC04' }),
    React.createElement("polyline", { fill: color || '#EA4335', points: "14 21.1363636 14 4 29.5 15.8636364 45 4 45 21.1363636 29.5 33" }),
    React.createElement("path", { d: "M0,6.19590625 L0,11.6929823 L14,22 L14,4.13450271 L10.08,1.24853777 C5.922,-1.80921081 0,1.09736817 0,6.19590625", fill: color || '#C5221F' })));
exports.Google = Google;
