"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHover = void 0;
const react_1 = require("react");
const useHover = () => {
    const [hovering, setHovering] = (0, react_1.useState)(false);
    return {
        hovering,
        onMouseEnter: () => setHovering(true),
        onMouseLeave: () => setHovering(false),
    };
};
exports.useHover = useHover;
