import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Card } from '@basisboard/basis-ui/es/components/Card'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { SafeWrapper } from '@basisboard/basis-ui/es/components/SafeWrapper'
import { useHover } from '@basisboard/basis-ui/es/hooks/useHover'
import { spacing } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import { Input, InputWrapper, OptionsList, ValueList, Wrapper } from './styled'

interface Props<T extends { id: string }> {
  options: T[]
  value: T[]
  renderEntry: (entry: T, option: boolean) => React.ReactNode
  onChange: (value: T[]) => void
  onFilter: (option: T, searchQuery: string) => boolean
  emptyText?: string
  height?: number
  placeHolder?: string
}

export const Searcher = <T extends { id: string }>({
  options,
  onChange,
  value,
  renderEntry,
  onFilter,
  height,
  emptyText,
  placeHolder,
}: Props<T>) => {
  const [searchTerm, setSearchTerm] = React.useState('')
  const [isOpen, setIsOpen] = React.useState(false)
  const target = React.useRef<HTMLDivElement>()

  const handleClickItem = React.useCallback(
    (val: T) => {
      const newVals = [...value, val]
      setIsOpen(false)
      onChange(newVals)
      setSearchTerm('')
    },
    [onChange, setIsOpen, value],
  )

  const { hovering, ...hoverProps } = useHover()

  const currentVal = (value || []).filter(v => v && options && options.some(o => o.id === v.id))

  return (
    <>
      <Wrapper
        id="searcher"
        ref={target}
        height={height || 40}
        onMouseDown={e => e.stopPropagation()}
        onClick={e => {
          e.stopPropagation()
          setIsOpen(true)
        }}
        {...hoverProps}
      >
        <ValueList>
          {currentVal.length > 0 || isOpen ? (
            currentVal.map(v => <li key={v.id}>{renderEntry(v, false)}</li>)
          ) : emptyText && hovering ? (
            <Button.Gradient preIcon="Plus" onClick={() => setIsOpen(true)}>
              {emptyText}
            </Button.Gradient>
          ) : null}
        </ValueList>
      </Wrapper>
      <SafeWrapper
        target={target.current}
        align="left"
        show={isOpen}
        onClose={() => setIsOpen(false)}
        preventFocusTrap
      >
        <Card maxHeight={320} width={260} overflow="auto" p={spacing(2)}>
          <InputWrapper>
            <Icon.Search />

            <Input
              value={searchTerm}
              placeholder={placeHolder}
              onChange={e => setSearchTerm(e.target.value)}
              autoFocus
            />
          </InputWrapper>

          <OptionsList>
            {options
              .filter(opt => !currentVal.some(v => v.id === opt.id) && onFilter(opt, searchTerm))
              .map(opt => (
                <li key={opt.id}>
                  <button
                    style={{ width: '100%', height: 32, padding: 0 }}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      handleClickItem(opt)
                    }}
                  >
                    {renderEntry(opt, true)}
                  </button>
                </li>
              ))}
          </OptionsList>
        </Card>
      </SafeWrapper>
    </>
  )
}
