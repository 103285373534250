"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Shuffle = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Shuffle = ({ width = 16, height = 16, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M12.6507 9.34307C12.8639 9.55623 12.8639 9.90182 12.6507 10.115L10.8314 11.934C10.49 12.2753 9.89962 12.0361 9.89962 11.548V10.6387H8.56273C8.48708 10.6387 8.41483 10.6073 8.36321 10.552L6.75862 8.8328L7.97152 7.53326L9.17187 8.81934H9.89962V7.91009C9.89962 7.42255 10.4896 7.18239 10.8314 7.52414L12.6507 9.34307ZM1.43958 5.18061H3.34991L4.55026 6.4667L5.76316 5.16715L4.15857 3.44794C4.10696 3.39264 4.0347 3.36125 3.95905 3.36125H1.43958C1.28886 3.36125 1.16667 3.48344 1.16667 3.63416V4.90771C1.16667 5.05842 1.28886 5.18061 1.43958 5.18061ZM9.89962 5.18061V6.08993C9.89962 6.57798 10.49 6.81722 10.8314 6.47589L12.6507 4.65689C12.8639 4.44373 12.8639 4.09814 12.6507 3.885L10.8314 2.06607C10.4896 1.7243 9.89962 1.96448 9.89962 2.452V3.36125H8.56273C8.48708 3.36126 8.41483 3.39265 8.36321 3.44794L3.34991 8.81934H1.43958C1.28886 8.81934 1.16667 8.94153 1.16667 9.09225V10.3658C1.16667 10.5165 1.28886 10.6387 1.43958 10.6387H3.95905C4.03469 10.6387 4.10694 10.6073 4.15857 10.552L9.17187 5.18061H9.89962Z", fill: color })));
exports.Shuffle = Shuffle;
