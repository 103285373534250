"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DragHandle = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const DragHandle = ({ width = 24, height = 24, size, color = styles_1.colors.gray, }) => (React.createElement(Svg_1.Svg, { width: size || width, height: size || height, viewBox: "0 0 24 24" },
    React.createElement("path", { d: "M11.3333 16C11.3333 16.7334 10.7333 17.3334 9.99996 17.3334C9.26663 17.3334 8.66663 16.7334 8.66663 16C8.66663 15.2667 9.26663 14.6667 9.99996 14.6667C10.7333 14.6667 11.3333 15.2667 11.3333 16ZM9.99996 10.6667C9.26663 10.6667 8.66663 11.2667 8.66663 12C8.66663 12.7334 9.26663 13.3334 9.99996 13.3334C10.7333 13.3334 11.3333 12.7334 11.3333 12C11.3333 11.2667 10.7333 10.6667 9.99996 10.6667ZM9.99996 6.66669C9.26663 6.66669 8.66663 7.26669 8.66663 8.00002C8.66663 8.73335 9.26663 9.33335 9.99996 9.33335C10.7333 9.33335 11.3333 8.73335 11.3333 8.00002C11.3333 7.26669 10.7333 6.66669 9.99996 6.66669ZM14 9.33335C14.7333 9.33335 15.3333 8.73335 15.3333 8.00002C15.3333 7.26669 14.7333 6.66669 14 6.66669C13.2666 6.66669 12.6666 7.26669 12.6666 8.00002C12.6666 8.73335 13.2666 9.33335 14 9.33335ZM14 10.6667C13.2666 10.6667 12.6666 11.2667 12.6666 12C12.6666 12.7334 13.2666 13.3334 14 13.3334C14.7333 13.3334 15.3333 12.7334 15.3333 12C15.3333 11.2667 14.7333 10.6667 14 10.6667ZM14 14.6667C13.2666 14.6667 12.6666 15.2667 12.6666 16C12.6666 16.7334 13.2666 17.3334 14 17.3334C14.7333 17.3334 15.3333 16.7334 15.3333 16C15.3333 15.2667 14.7333 14.6667 14 14.6667Z", fill: color })));
exports.DragHandle = DragHandle;
