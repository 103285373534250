import {
  BID_INVITE_ID,
  COMPANY_ID,
  CONTACT_ID,
  PatchBidInviteRequest,
  PatchBidInviteResponse,
  PostSplitBidInviteRequest,
  PostSplitBidInviteResponse,
  PROJECT_ID,
} from '@basisboard/basis-common/lib/api'
import { patch, post } from '../../api'

export const splitBids = (bidInviteId: BID_INVITE_ID, params: PostSplitBidInviteRequest) =>
  post<PostSplitBidInviteResponse>(`/bid-invites/${bidInviteId}/split`, params).then(
    response => response.data.bidInvite,
  )

export const patchBidInvite = (
  bidInviteId: BID_INVITE_ID,
  key: keyof PatchBidInviteRequest,
  value: any,
) => patch<PatchBidInviteResponse>(`/bid-invites/${bidInviteId}`, { [key]: value })

export const switchCompany = (bidInviteId: BID_INVITE_ID, companyId: COMPANY_ID) =>
  post(`/bid-invites/${bidInviteId}/companies/${companyId}/link`)

export const switchProject = (bidInviteId: BID_INVITE_ID, projectId: PROJECT_ID) =>
  post(`/bid-invites/${bidInviteId}/projects/${projectId}/link`)

export const linkContactFromBidInvite = (contactId: CONTACT_ID, bidInviteId: BID_INVITE_ID) =>
  post(`/contacts/${contactId}/bid-invite/${bidInviteId}/link`)
