"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Footer = exports.ContentCloseBar = exports.Wrapper = exports.WrapperFadeOut = exports.Backdrop = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const styled_system_1 = require("styled-system");
const styles_1 = require("../../styles");
const constants_1 = require("../../styles/constants");
const BackdropFadeIn = (0, styled_components_1.keyframes) `
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const BackdropFadeOut = (0, styled_components_1.keyframes) `
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
exports.Backdrop = styled_components_1.default.div(({ opening }) => (0, styled_components_1.css) `
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.45);
    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: none;

    opacity: 0;

    @media print {
      position: absolute;
      background-color: white;
    }

    ${opening
    ? (0, styled_components_1.css) `
          ${exports.Wrapper} {
            animation: ${WrapperFadeIn} ${constants_1.TransitionType.medium} forwards;
            animation-delay: ${constants_1.TransitionType.medium};
          }
          animation: ${BackdropFadeIn} ${constants_1.TransitionType.slow} forwards;
        `
    : (0, styled_components_1.css) `
          ${exports.Wrapper} {
            animation: ${exports.WrapperFadeOut} ${constants_1.TransitionType.medium} forwards;
          }
          animation: ${BackdropFadeOut} ${constants_1.TransitionType.medium} forwards;
        `};
  `);
const WrapperFadeIn = (0, styled_components_1.keyframes) `
  0% {  
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
`;
exports.WrapperFadeOut = (0, styled_components_1.keyframes) `
  0% {  
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
`;
exports.Wrapper = styled_components_1.default.div `
  max-width: 100vw;
  ${styled_system_1.width};
  max-height: 100vh;
  ${styled_system_1.height};
  ${styled_system_1.minWidth};

  pointer-events: all;

  ${(0, styles_1.mediaBreakpointUp)('medium')} {
    ${styles_1.borderRadiusMixin};
  }

  ${styled_system_1.background};

  position: relative;
  transform: scale(0);
  padding: ${(0, styles_1.spacing)(5)} ${(0, styles_1.spacing)(4)};

  ${styled_system_1.overflow};
  ${styles_1.boxShadowMixin};

  @media print {
    overflow: visible;
    box-shadow: none;
    max-height: none;
    height: auto;
    width: 100%;
  }
`;
exports.ContentCloseBar = styled_components_1.default.div `
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 5;

  @media print {
    display: none;
  }
`;
exports.Footer = styled_components_1.default.footer `
  margin: auto -${(0, styles_1.spacing)(4)} -${(0, styles_1.spacing)(5)};
  padding: ${(0, styles_1.spacing)(3)} ${(0, styles_1.spacing)(4)};
  width: calc(100% + ${(0, styles_1.spacing)(8)});
  box-shadow: inset 0px 1px 0px #eaebee;
  display: flex;
  height: 88px;
  justify-content: space-between;
  align-items: center;

  ${styled_system_1.space};
`;
