"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stats = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../../styles");
const Svg_1 = require("./Svg");
const Stats = ({ width = 24, height = 24, size, color = styles_1.colors.gray }) => (React.createElement(Svg_1.Svg, { width: width, height: height, size: size, viewBox: "0 0 24 24" },
    React.createElement("path", { d: "M17.5 15.75H18.375C18.625 15.75 18.875 15.5 18.875 15.25V6.25C18.875 6 18.625 5.75 18.375 5.75H17.5C17.25 5.75 17 6 17 6.25V15.25C17 15.5 17.25 15.75 17.5 15.75ZM10 15.75H10.875C11.125 15.75 11.375 15.5 11.375 15.25V7.5C11.375 7.25 11.125 7 10.875 7H10C9.75 7 9.5 7.25 9.5 7.5V15.25C9.5 15.5 9.75 15.75 10 15.75ZM13.75 15.75H14.625C14.875 15.75 15.125 15.5 15.125 15.25V10C15.125 9.75 14.875 9.5 14.625 9.5H13.75C13.5 9.5 13.25 9.75 13.25 10V15.25C13.25 15.5 13.5 15.75 13.75 15.75ZM21.375 17.625H3.875V5.125C3.875 4.77969 3.59531 4.5 3.25 4.5H2.625C2.27969 4.5 2 4.77969 2 5.125V18.25C2 18.9402 2.55977 19.5 3.25 19.5H21.375C21.7203 19.5 22 19.2203 22 18.875V18.25C22 17.9047 21.7203 17.625 21.375 17.625ZM6.25 15.75H7.125C7.375 15.75 7.625 15.5 7.625 15.25V12.5C7.625 12.25 7.375 12 7.125 12H6.25C6 12 5.75 12.25 5.75 12.5V15.25C5.75 15.5 6 15.75 6.25 15.75Z", fill: color })));
exports.Stats = Stats;
