import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Icon as BaseIcon, IconType } from '@basisboard/basis-ui/es/components/Icon'
import { DetailText, Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import moment from 'moment'
import * as React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { TYPE_TO_ICON, TYPE_TO_TEXT, TYPE_TO_URL } from './constants'
import { NotificationProps } from './types'

const Wrapper = styled(Link)<{ read: boolean }>`
  display: flex;
  align-items: center;
  padding: ${spacing(1.5)} ${spacing(4)};

  ${transitionMixin};
  margin-bottom: ${spacing(0.25)};

  ${({ read }) =>
    !read &&
    css`
      background: ${colors.lightGray3};
    `}

  &:hover {
    background: ${colors.lightGray3};
  }
`

const Label = styled(Text)`
  color: ${colors.gray};

  strong {
    color: ${colors.darkBlue};
  }
`

export const Notification: React.FC<NotificationProps> = ({ notification, me, onClose }) => {
  const { color, icon } = TYPE_TO_ICON[notification.type]

  const date = moment(notification.createdAt)

  const Icon = typeof icon !== 'function' ? BaseIcon[icon as IconType] : undefined

  return (
    <li>
      <Wrapper
        to={
          TYPE_TO_URL[notification.type]?.getUrl(notification) ||
          `/projects/${notification.projectId}`
        }
        onClick={onClose}
        read={notification.read}
      >
        {typeof icon === 'function' ? (
          <Div mr={spacing(1.5)}>{(icon as (param: unknown) => React.ReactNode)({ me })}</Div>
        ) : (
          <Div
            size={32}
            minWidth={32}
            mr={spacing(2)}
            borderRadius={32}
            background={color}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Icon size={16} color={colors.white} />
          </Div>
        )}
        <Div>
          <Label
            preventClip
            dangerouslySetInnerHTML={{ __html: TYPE_TO_TEXT[notification.type].text(notification) }}
          />
          <DetailText>
            {date.format(date.isSame(moment(), 'day') ? 'HH:mm' : 'MMMM D, YYYY HH:mm')}
          </DetailText>
        </Div>
      </Wrapper>
    </li>
  )
}
