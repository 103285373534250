export enum ViewId {
  InboxViewId = 'INBOX_VIEW',
  ListViewId = 'LIST_VIEW',
  BoardViewId = 'BOARD_VIEW',
  CalendarViewId = 'CALENDAR_VIEW',
  ContactsViewId = 'CONTACTS_VIEW',
  AccountsViewId = 'ACCOUNTS_VIEW',
  AnalyticsViewId = 'ANALYTICS_VIEW',
  GroupsAnalyticsViewId = 'GROUPS_ANALYTICS_VIEW',
  QuoteViewId = 'QUOTES_VIEW',
  TasksViewId = 'TASKS_VIEW',
}

export const MIN_PASSWORD_LENGTH = 6

export const DEFAULT_OUTLOOK_FOLDERS = [
  'Archive',
  'Conversation History',
  'Deleted Items',
  'Drafts',
  'Inbox',
  'Junk Email',
  'Outbox',
  'Sent Items',
]

const MILISECONDS = 1

export const SECONDS = 1000 * MILISECONDS

export const MINUTES = 60 * SECONDS

export const KEYCODE_ENTER = 13
export const KEYCODE_ESC = 27
