import { BannerPage } from '@basisboard/basis-common'
import { FeatureType } from '@basisboard/basis-common/lib/api'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Card } from '@basisboard/basis-ui/es/components/Card'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Row } from '@basisboard/basis-ui/es/components/Row'
import { H2, Span, Subtitle2 } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { differKeys, retryLazyLoad } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import groupBy from 'ramda/src/groupBy'
import isEmpty from 'ramda/src/isEmpty'
import keys from 'ramda/src/keys'
import * as React from 'react'
import { AppState } from '../../containers/App/container'
import { openConfirmActionModal } from '../../containers/App/modals'
import { Communications } from '../../containers/Communication/components'
import { openModal } from '../../containers/Modal'
import { ViewScreenContainer, ViewScreenContainerState } from './container'
import {
  ViewScreenAside,
  ViewScreenBanner,
  ViewScreenContent,
  ViewScreenHeader,
  ViewScreenViewEntry,
  ViewScreenWrapper,
} from './styled'
import { View } from './types'

export * from './container'
export * from './helpers'
export * from './types'

export interface ViewScreenProps<T extends { id: string }> {
  title: string
  container: ViewScreenContainer<T, ViewScreenContainerState<T>>
  barContent?: React.ReactNode
  children?: React.ReactNode
  titleDetails?: React.ReactNode
  omitViews?: boolean
  canEditViewsName?: boolean
  banner?: React.ReactNode
  canAddView?: boolean
}

export function ViewsScreen<K extends { id: string } = any>({
  title,
  children,
  barContent,
  container,
  banner,
  titleDetails,
  omitViews,
  canEditViewsName,
  canAddView,
}: ViewScreenProps<K>) {
  const [showSidebar, setShowSidebar] = React.useState(!omitViews)
  const containerData = useContainer(container, {
    shouldTriggerUpdate: !omitViews ? differKeys(['views', 'totals']) : () => true,
  })

  React.useEffect(() => {
    if (isEmpty(containerData.state.entries)) {
      containerData.getTotals()
      containerData.loadEntries()
    }
  }, [])

  const groupedViews = groupBy(v => v.viewGroup, containerData.state.localViews)

  const appData = useContainer(AppState)

  const showPaymentOverdueBanner = appData.state.customerSettings?.settings?.enabledFeatures.includes(
    FeatureType.LatePaymentBanner,
  )

  const renderBanner = (appData.state.customerSettings?.banners || []).find(b =>
    b.showOnPages.includes(window.location.pathname as BannerPage),
  )

  return (
    <ViewScreenWrapper key={containerData.viewId}>
      {showPaymentOverdueBanner ? (
        <ViewScreenBanner>
          <Communications.PaymentOverdueBanner />
        </ViewScreenBanner>
      ) : renderBanner ? (
        <ViewScreenBanner>
          <Communications.OperationBanner {...renderBanner} />
        </ViewScreenBanner>
      ) : (
        banner && <ViewScreenBanner>{banner}</ViewScreenBanner>
      )}

      <ViewScreenHeader>
        <H2 as="h1">{title}</H2>

        {!omitViews && (
          <Button.Gradient
            data-testid="sidebar"
            width={48}
            height={32}
            preIcon="SideView"
            onClick={() => setShowSidebar(s => !s)}
            info={showSidebar ? 'Hide Sidebar' : 'Show Sidebar'}
            ml={spacing(2)}
          />
        )}

        {titleDetails}
      </ViewScreenHeader>

      <ViewScreenAside showSidebar={showSidebar}>
        <Card p={spacing(2)} width={272}>
          {keys(groupedViews).map((key, index) => (
            <React.Fragment key={key}>
              <Row mb={spacing(2)} justifyContent="space-between">
                <Subtitle2>{key === 'undefined' ? `${title} Views` : key}</Subtitle2>
                {canAddView && index === 0 && (
                  <Button.Primary
                    preIcon="Plus"
                    height={24}
                    px={spacing(1)}
                    onClick={e => {
                      e.stopPropagation()

                      openModal({
                        Component: retryLazyLoad(() => import('./components/CreateViewModal')),
                        name: 'CreateViewModal',
                        props: {
                          onSubmit: view => containerData.createView(view),
                          isNewView: true,
                          context: title,
                          view:
                            containerData.state.defaultViews?.[0] ||
                            containerData.state.selectedView,
                        },
                        width: 600,
                      })
                    }}
                  >
                    Add View
                  </Button.Primary>
                )}
              </Row>
              <Div as="ul" mb={spacing(2)}>
                {groupedViews[key].map(view => (
                  <ViewScreenViewEntry
                    data-testid={view.id}
                    key={view.id}
                    className={view.id === containerData.state.selectedView.id ? 'active' : ''}
                    onClick={() => containerData.onChangeView(view)}
                  >
                    <Span style={{ flex: 1 }}>{view.name}</Span>

                    <Div>
                      {typeof containerData.state.totals?.[view.id] === 'number' && (
                        <Span color={colors.blueGray900}>
                          {containerData.state.totals[view.id]}
                        </Span>
                      )}
                      {canEditViewsName && (
                        <Button.Transparent
                          ml={spacing(1)}
                          preIcon="Edit"
                          size={24}
                          iconSize={14}
                          onClick={e => {
                            e.stopPropagation()

                            openModal({
                              Component: retryLazyLoad(() => import('./components/EditNameModal')),
                              name: 'EditViewNameModal',
                              props: {
                                onSubmit: ({ name }) => container.updateView({ ...view, name }),
                                initialName: view.name,
                              },
                              width: 480,
                            })
                          }}
                        />
                      )}
                      {view.settings?.canEdit && (
                        <Button.Transparent
                          ml={spacing(1)}
                          data-testid="edit-button"
                          preIcon="Edit"
                          size={24}
                          iconSize={14}
                          iconColor={colors.gray800}
                          onClick={e => {
                            e.stopPropagation()

                            openModal({
                              Component: retryLazyLoad(() =>
                                import('./components/CreateViewModal'),
                              ),
                              name: 'EditViewModal',
                              props: {
                                onSubmit: (view: View) =>
                                  view.id === containerData.state.selectedView.id
                                    ? container.updateView({
                                        ...containerData.state.selectedView,
                                        name: view.name,
                                        settings: {
                                          ...(containerData.state.selectedView.settings || {}),
                                          ...view.settings,
                                        },
                                      })
                                    : container.updateView(view),
                                initialName: view.name,
                                context: 'Inbox',
                                view,
                                onDeleteView: (viewId: string) =>
                                  openConfirmActionModal({
                                    prompt: 'Are you sure you want to delete this view?',
                                    actionLabel: 'Delete',
                                    danger: true,
                                    action: () =>
                                      container.updateViews(
                                        container.state.views.filter(v => v.id !== viewId),
                                      ),
                                  }),
                              },
                              width: 600,
                            })
                          }}
                        />
                      )}
                    </Div>
                  </ViewScreenViewEntry>
                ))}
              </Div>
            </React.Fragment>
          ))}
        </Card>

        {barContent}
      </ViewScreenAside>

      <ViewScreenContent key={containerData.state.selectedView.id}>
        <Card maxHeight="100%" display="flex" flexDirection="column">
          {children}
        </Card>
      </ViewScreenContent>
    </ViewScreenWrapper>
  )
}
