import { CommunicationType } from '@basisboard/basis-common/lib/enums'
import { Tooltip } from '@basisboard/basis-ui/es/components/Tooltip'
import { Text } from '@basisboard/basis-ui/es/components/Typography'
import { borderRadiusMixin, colors, spacing } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { background, BackgroundProps, space, SpaceProps } from 'styled-system'

export const formatCommunicationType: Record<
  CommunicationType,
  { label: string; color: string }
> = {
  [CommunicationType.Addendum]: { label: 'Addendum', color: colors.STAGES[0] },
  [CommunicationType.BidDateExtension]: {
    label: 'Bid date extension',
    color: colors.STAGES[1],
  },
  [CommunicationType.BidInvite]: { label: 'Bid invite', color: colors.STAGES[2] },
  [CommunicationType.Memos]: { label: 'Memo', color: colors.STAGES[3] },
  [CommunicationType.PlatformUpdate]: { label: 'Update', color: '#FC941A' },
  [CommunicationType.RFI]: { label: 'RFIs', color: colors.STAGES[5] },
  [CommunicationType.Unknown]: { label: 'Unknown', color: colors.STAGES[6] },
  [CommunicationType.Response]: { label: 'Response', color: colors.STAGES[9] },
}

const Wrapper = styled.div<BackgroundProps & SpaceProps & { small?: boolean }>`
  ${({ small }) =>
    small
      ? css`
          height: 16px;
          line-height: 16px;
          font-weight: bold;
          font-size: 0.625rem;
          padding: 0 ${spacing(0.5)};
          text-transform: uppercase;
        `
      : css`
          height: 24px;
          line-height: 24px;
          font-weight: 600;
          font-size: 0.875rem;
          padding: 0 ${spacing(1.5)};
        `}

  display: inline-block;
  color: ${colors.white};
  ${borderRadiusMixin};
  ${background};
  ${space};
`

interface Props extends SpaceProps {
  info?: string
  communicationType?: CommunicationType
  isNew?: boolean
  small?: boolean
}

export const EmailTag: React.FC<Props> = ({ communicationType, small, info, ...spaceProps }) => {
  const { label, color } = communicationType
    ? formatCommunicationType[communicationType]
    : { label: 'NEW', color: colors.success }

  const render = () => (
    <Wrapper background={color} small={small} {...spaceProps}>
      {label}
    </Wrapper>
  )

  return info ? (
    <Tooltip
      variant="dark"
      small
      content={<Text white>{info}</Text>}
      position="topCenter"
      defaultPosition="top"
      align="center"
    >
      {render()}
    </Tooltip>
  ) : (
    render()
  )
}
