import { get, put } from '../../api'
import { ViewId } from '../../constants'
import { Group } from './types'

export const putGroup = (viewId: ViewId, group: Group) =>
  put(`/profile/settings/${viewId}-group`, {
    value: group,
  })

export const getGroup = (viewId: ViewId) =>
  get(`/profile/settings/${viewId}-group`).then(response => response.data.value)
