import { Label } from '@basisboard/basis-ui/es/components/Label'
import { borderRadiusMixin, colors, spacing } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled from 'styled-components'

interface Props {
  label?: string
  defaultValue?: number
  required?: boolean
  value: number
  name?: string
  disabled?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Input = styled.input`
  background: ${colors.white};
  box-sizing: border-box;
  padding: 0 ${spacing(2)};
  border: none;
  width: 100%;
  height: 100%;

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const Wrapper = styled.div`
  margin-bottom: ${spacing(1)};
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid #dadce3;
  ${borderRadiusMixin};
  overflow: hidden;
`

const Symbol = styled.div`
  height: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: ${colors.gray};
  background-color: ${colors.lightGray3};
`

export const Percentage = React.forwardRef<HTMLInputElement, Props>(({ label, ...props }, ref) => {
  const handleChange = e => {
    e.target.value = Math.min(100, e.target.value)
    props.onChange(e)
  }

  return (
    <>
      {label && <Label required={props.required} label={label} />}

      <Wrapper>
        <Symbol>%</Symbol>
        <Input ref={ref} {...(props as any)} type="number" onChange={handleChange} />
      </Wrapper>
    </>
  )
})
