"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colors = void 0;
const BLUE = '#0d28f2';
const ALMOST_BLACK = '#0c141e';
const ERROR_RED = '#ff214c';
const SUCCESS_GREEN = '#32c533';
const MAIN_COLORS = {
    blue: BLUE,
    almostBlack: ALMOST_BLACK,
    darkBlue: '#1c2c40',
    darkGray: '#5c6572',
    gray: '#949ca8',
    mediumGray: '#868e9b',
    lightGray: '#ced3d9',
    lightGray2: '#e6eaed',
    lightGray3: '#f5f6f7',
    cyan: '#26c6da',
    green: '#12be33',
    teal: '#26bdaf',
    radicalRed: '#ff214c',
};
const DEFAULT_STAGES = {
    undecided: '#a9b0b9',
    declined: ERROR_RED,
    accepted: SUCCESS_GREEN,
    estimating: '#ffad32',
    submitted: '#7a33b8',
    awarded: '#26c6da',
    lost: '#ff5722',
    deleted: '#f5f6f7',
    readyToSubmit: '#ffad32',
};
const STAGES = [
    '#ced3d9',
    '#ff5722',
    '#ffa726',
    '#ffca28',
    '#a5de37',
    '#81c784',
    '#80cbc4',
    '#ab47bc',
    '#ec407a',
    '#ed4694',
    '#a1887f',
    '#bdbdbd',
];
exports.colors = Object.assign(Object.assign({ accent: BLUE, accentActive: '#00aaf1', primaryText: ALMOST_BLACK, boardIcon: '#b7c1cf', border: '#eaebee', error: ERROR_RED, success: SUCCESS_GREEN, warning: '#ffad32', white: '#fff', black: '#000', blueGray: '#f1f5f9', blueGray200: '#d4deed', blueGray300: '#CBD5E1', blueGray400: '#7e9cc9', blueGray500: '#4870ad', blueGray600: '#365481', blueGray700: '#334155', blueGray800: '#1E293B', blueGray900: '#121C2B', blueGrayBorder: '#d4deed', lightCyan: '#cbeff6', darkCyan: '#1b879d', borderColor: '#eaebee', darkBorderColor: '#2e2e2e', inputBackground: '#eeeeee', buttonGray: '#dde0e3', gray200: '#DDE0E3', gray400: '#9AA4AC', gray600: '#535d65', gray700: '#40484F', gray800: '#2E3338', orange100: '#FFEFEB', orange200: '#FFCEC2', orange500: '#FF5722', orange700: '#8F1D00', orange800: '#521000', transparent: 'transparent' }, MAIN_COLORS), { DEFAULT_STAGES,
    STAGES });
