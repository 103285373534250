import { GetInboxMessageDetailResponseBody } from '@basisboard/basis-common/lib/api'
import { getContainer } from '@containrz/react-hook'
import moment from 'moment'
import keys from 'ramda/src/keys'
import omit from 'ramda/src/omit'
import { eventBus, EventBusType } from '../../services'
import { whoami } from '../App/container'
import { PlatformsContainer } from '../Platforms/container'
import { inboxRepository } from './container'

export const publishProcessedEvent = (
  baseMessage: Partial<GetInboxMessageDetailResponseBody>,
  action: 'Declined' | 'Added' | 'Trashed' | 'Linked',
  origin: 'Importer' | 'List',
  correctedFields = {},
  duration = '0s',
) =>
  eventBus.publish(EventBusType.InboxProcessed, {
    messageId: baseMessage.message.id,
    duration,
    action,
    origin,
    ...correctedFields,
    internal: whoami().hidden,
    daysBehind: Math.abs(moment(baseMessage.message.createdAt).diff(moment(), 'days')),
    platform: baseMessage.platformId,
    platformConnected: (getContainer(PlatformsContainer).state.platforms || []).some(
      p => p.platformId === baseMessage.platformId,
    ),
    processedPercentage: keys(
      inboxRepository().state.messages.find(m => m.id === baseMessage.message.id)
        ?.scrapedIndicator || {},
    ).reduce(
      (acc, key) =>
        inboxRepository().state.messages.find(m => m.id === baseMessage.message.id)
          .scrapedIndicator[key]
          ? acc + 25
          : acc,
      0,
    ) as 0 | 25 | 50 | 75 | 100,
    communicationType: baseMessage.message.communicationType,
    payload: {
      ...baseMessage,
      message: omit(['body'], baseMessage.message),
    },
  })
