import { spacing } from '@basisboard/basis-ui/es/styles'
import styled from 'styled-components'

export const Header = styled.div`
  margin-bottom: ${spacing(0.25)};

  width: 100%;
`

export const NotificationList = styled.ul`
  padding: 0;
  margin-top: 1px;

  list-style: none;
  position: relative;
`

export const MarkAllAsReadButtonWrap = styled.div`
  position: absolute;
  right: 16px;
  top: 80px;
`
