import { User, USER_ID } from '@basisboard/basis-common/lib/api'
import { Button, ButtonVariantType } from '@basisboard/basis-ui/es/components/Button'
import { Menu } from '@basisboard/basis-ui/es/components/Menu'
import { fromNullable, readableName } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import { PersonChip } from '../../../../components'
import { UsersContainer } from '../../container'

interface Props {
  selectedUser: USER_ID
  onSelectUser: (user: USER_ID) => void
  tooltipLabel?: string
  buttonVariant?: ButtonVariantType
  chipOnly?: boolean
  disabled?: boolean
  renderTrigger?: (props: unknown, user?: User) => React.ReactNode
}

export const UserDropdown: React.FC<Props> = ({
  selectedUser,
  onSelectUser,
  tooltipLabel,
  buttonVariant = ButtonVariantType.Gradient,
  chipOnly,
  disabled,
  renderTrigger,
}) => {
  const [localUser, setLocalUser] = React.useState(selectedUser)
  const userData = useContainer(UsersContainer)

  const Btn = Button[buttonVariant]

  return (
    <Menu
      alignSide="left"
      boxWidth={250}
      boxMaxHeight={150}
      id="user-dropdown"
      renderTrigger={props =>
        renderTrigger?.(
          props,
          userData.state.allUsers.find(u => u.id === localUser),
        ) ||
        fromNullable(localUser).fold(
          () => (
            <Btn
              postIcon="Assign"
              info={tooltipLabel}
              {...(props as any)}
              onClick={e => {
                e.stopPropagation()
                props.onClick(e)
              }}
              disabled={disabled}
            />
          ),
          () => (
            <Btn
              p={0}
              size={chipOnly ? 32 : undefined}
              info={tooltipLabel}
              disabled={disabled}
              {...(props as any)}
              onClick={e => {
                e.stopPropagation()
                props.onClick(e)
              }}
            >
              {fromNullable(userData.getUserById(localUser)).fold(
                () => null,
                user => (
                  <PersonChip
                    hideTooltip
                    chipSpace={{ m: 0 }}
                    chipOnly={chipOnly}
                    size={24}
                    color={user.color}
                    name={readableName(user)}
                  />
                ),
              )}
            </Btn>
          ),
        )
      }
      entries={[
        ...(localUser
          ? [
              {
                onAction: () => {
                  setLocalUser(undefined)
                  onSelectUser(undefined)
                },
                id: 'empty',
                label: 'Clear selection',
              },
            ]
          : []),
        ...userData.state.allUsers.filter(Boolean).map(user => ({
          onAction: () => {
            setLocalUser(user.id)
            onSelectUser(user.id)
          },
          id: user.id,
          label: readableName(user),
          render: <PersonChip color={user.color} name={readableName(user)} />,
        })),
      ]}
    />
  )
}
