import { borderRadiusMixin, colors, cssCircle, spacing } from '@basisboard/basis-ui/es/styles'
import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 100%;

  & > [data-testid='tooltip'] button {
    visibility: hidden;
  }

  :hover {
    & > [data-testid='tooltip'] button {
      visibility: visible;
    }
  }
`

export const TagCircle = styled.div<BackgroundProps>`
  ${background};
  ${cssCircle(12)};
`

export const TagContent = styled.div<BackgroundProps>`
  ${background};
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 ${spacing(1)};
  margin: ${spacing(1)} 0;
  color: ${colors.white};
  ${borderRadiusMixin};
`
