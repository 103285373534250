import { CustomFieldValues } from '@basisboard/basis-common/lib/api/custom-fields'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Input } from '@basisboard/basis-ui/es/components/Input'
import { Anchor, Text } from '@basisboard/basis-ui/es/components/Typography'
import { borderRadiusMixin, colors, spacing } from '@basisboard/basis-ui/es/styles'
import { box, fromBoolean, isUrl, linkify } from '@basisboard/basis-ui/es/utils'
import * as React from 'react'
import styled from 'styled-components'
import { TextOverflow } from '../../../../../components'

const TextWrapper = styled(Text)`
  height: 40px;

  padding: 0 ${spacing(1)};
  line-height: 40px;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
  max-height: 40px;
  max-width: 100%;

  border: 1px dotted transparent;
  ${borderRadiusMixin};
  padding-right: ${spacing(1)};

  & > button,
  & > .add-text {
    transition: visibility 0;
    visibility: hidden;
  }

  &:hover {
    border-color: ${colors.accent};

    & > button,
    & > .add-text {
      visibility: visible;

      &:hover path {
        stroke: ${colors.accent};
      }
    }
  }
`

interface Props {
  value: CustomFieldValues
  onChange: (val: CustomFieldValues) => void
  type: string
  label: string
  formatter?: (val: CustomFieldValues) => CustomFieldValues
  submitFormatter?: (val: CustomFieldValues) => CustomFieldValues
  valueFormatter?: (val: CustomFieldValues) => CustomFieldValues
}

export const TextType: React.FC<Props> = ({
  value,
  onChange,
  type,
  label,
  formatter = s => s,
  submitFormatter = s => s,
  valueFormatter = s => s,
}) => {
  const [text, setText] = React.useState(valueFormatter(value))
  const [isEditing, setEditing] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>()

  const handleBlur = React.useCallback(() => {
    onChange(submitFormatter(text))
    setText(text)
    setEditing(false)
  }, [text, setText, onChange, setEditing, submitFormatter])

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Escape') {
        setText(value)

        window.setTimeout(() => {
          inputRef.current?.blur()
        }, 0)
      } else if (e.key === 'Enter') {
        inputRef.current?.blur()
      }
    },
    [setText, value, inputRef, inputRef.current],
  )

  return isEditing ? (
    <Div onClick={e => e.stopPropagation()} onMouseDown={e => e.stopPropagation()}>
      <Input
        autoFocus
        ref={inputRef}
        value={text as string}
        type={type}
        onChange={e => setText(e.currentTarget.value)}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        size={((text as string) || '').length}
      />
    </Div>
  ) : (
    <Wrapper>
      {text ? (
        fromBoolean(isUrl(text as string)).fold(
          () => (
            <TextOverflow TextComponent={TextWrapper} maxWidthMargin={32}>
              {formatter(text)}
            </TextOverflow>
          ),
          () =>
            box(linkify(text as string)).fold(link => (
              <Anchor target="_blank" href={link as string}>
                {formatter(text)}
              </Anchor>
            )),
        )
      ) : (
        <Text className="add-text" pl={spacing(1)} lineHeight="40px" color={colors.mediumGray}>
          Add {label?.toLowerCase()}
        </Text>
      )}
      <Button.Transparent
        ml={spacing(1)}
        onClick={e => {
          e.stopPropagation()
          setEditing(true)
        }}
        postIcon="PencilHollow"
      />
    </Wrapper>
  )
}
