import { Calendar } from '@basisboard/basis-ui/es/components/Calendar'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { formatMomentWithTime } from '@basisboard/basis-ui/es/components/Timeline'
import { Span } from '@basisboard/basis-ui/es/components/Typography'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import moment, { Moment } from 'moment'
import React from 'react'
import styled from 'styled-components'
import { TimePicker } from '../../../../../../components'
import { TableFieldProps } from '../../type'
import { BaseField } from '../BaseField'

const DateFieldWrapper = styled.div`
  padding: ${spacing(2)};
  z-index: 1;
`

export const DateField: React.FC<TableFieldProps> = ({
  value,
  onChange,
  onDismiss,
  forceShowEdit,
  readonly,
  actions,
}) => {
  const [localVal, setLocalVal] = React.useState<Moment>(
    value && moment(value as string).isValid() ? moment(value as string | Date) : null,
  )

  const handleDateChange = (newDate: Moment | string | null) => {
    const parsedDate = moment(newDate)
    const newValue = newDate
      ? (localVal || moment())
          .clone()
          .month(parsedDate.month())
          .date(parsedDate.date())
          .year(parsedDate.year())
          .hour(localVal?.hour() ?? 0)
          .minute(localVal?.minute() ?? 0)
      : null

    setLocalVal(newValue)
  }

  const handleTimeChange = (newTime: Moment | null) => {
    const newValue = newTime
      ? (localVal || moment())
          .clone()
          .hour(newTime?.hour() ?? moment().hour())
          .minute(newTime?.minute() ?? moment().minute())
      : null

    setLocalVal(newValue)
  }

  const formattedValue = React.useMemo(() => formatMomentWithTime(localVal), [localVal])

  const handleDismiss = React.useCallback(
    (ignoreChanges?: boolean) => {
      if (!ignoreChanges) {
        onChange(localVal?.toISOString())
      } else {
        setLocalVal(
          value && moment(value as string).isValid() ? moment(value as string | Date) : null,
        )
      }
      onDismiss?.()
    },
    [localVal],
  )

  const setRef = (ref: HTMLDivElement) => {
    if (!ref) return
    setTimeout(() => {
      ref.style.transform = `translate(-${ref.getBoundingClientRect().x}px, -${
        ref.getBoundingClientRect().y
      }px)`
    }, 1)
  }

  return (
    <BaseField
      readonly={readonly || !Boolean(onChange)}
      onDismiss={handleDismiss}
      actions={actions}
      customActions={{
        width: 324,
        customActions: (
          <>
            <Div
              position="absolute"
              className="dismiss-overlay"
              zIndex={-1}
              left={0}
              top={0}
              onClick={e => {
                e.stopPropagation()
                handleDismiss()
              }}
              ref={setRef}
              width="100vw"
              height="100vh"
            />
            <DateFieldWrapper>
              <Calendar value={localVal} onChange={handleDateChange} />
              <Div mt={spacing(2)}>
                <TimePicker
                  onChange={handleTimeChange}
                  value={localVal || moment().set({ hour: 0, minute: 0 })}
                />
              </Div>
            </DateFieldWrapper>
          </>
        ),
      }}
      forceShowEdit={forceShowEdit}
    >
      {formattedValue === 'Not set' ? (
        <Span color={colors.lightGray}>{formattedValue}</Span>
      ) : (
        formattedValue
      )}
    </BaseField>
  )
}
