import {
  GetInboxMessageDetailResponseBody,
  GetInboxMessageIdsRequestQuery,
  GetInboxMessageIdsResponseBody,
  GetInboxMessagesRequestQuery,
  GetInboxMessagesResponseBody,
  GetMessageStatsRequestQuery,
  GetMessageStatsResponseBody,
  MESSAGE_ID,
  PatchMessageRequestBody,
  PatchMessageResponse,
  PostProjectsBulkRequest,
  PostProjectsBulkResponse,
} from '@basisboard/basis-common/lib/api'
import { stringify } from 'qs'
import { del, get, patch, post, put } from '../../api'

export const getMessages = (query?: GetInboxMessagesRequestQuery) =>
  get<GetInboxMessagesResponseBody>(`/messages?${stringify(query)}`).then(response => response.data)

export const getMessageIds = (query?: GetInboxMessageIdsRequestQuery) =>
  get<GetInboxMessageIdsResponseBody>(`/messages/ids?${stringify(query)}`).then(
    response => response.data,
  )

export const getMessageDetails = (messageId: MESSAGE_ID) =>
  get<GetInboxMessageDetailResponseBody>(`/messages/${messageId}/detail`).then(
    response => response.data,
  )

export const getBaseMessage = (messageId: MESSAGE_ID) =>
  get<GetInboxMessageDetailResponseBody>(`/messages/${messageId}/base`).then(
    response => response.data,
  )

export const patchMessageDetails = (messageId: MESSAGE_ID, body: PatchMessageRequestBody) =>
  patch<PatchMessageResponse>(`/messages/${messageId}`, body).then(
    response => response.data.message,
  )

export const separateMessage = (messageId: MESSAGE_ID) =>
  post<void>(`/messages/${messageId}/separate`)

export const setWorkspaceSetting = (key: string, value: boolean) =>
  put(`/dashboard/settings/${key}`, { value: { [key]: value } })

export const getWorkspaceSetting = (key: string) =>
  get(`/dashboard/settings/${key}`).then(response => response.data.value?.[key])

export const getStats = (data: GetMessageStatsRequestQuery) =>
  get<GetMessageStatsResponseBody>(`/messages/stats?${stringify(data)}`).then(
    response => response.data,
  )

export const postProjectsFromMessages = (data: PostProjectsBulkRequest) =>
  post<PostProjectsBulkResponse>('/projects/bulk', data).then(r => r.data)

export const deleteGroupSuggestion = (messageId: MESSAGE_ID) =>
  del(`/messages/${messageId}/group-suggestion`)
