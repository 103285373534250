"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Table2 = exports.LoadingCell = void 0;
const React = __importStar(require("react"));
const styled_components_1 = __importStar(require("styled-components"));
const styled_system_1 = require("styled-system");
const styles_1 = require("../../styles");
const Div_1 = require("../Div");
const ActionsWrapper_1 = require("./components/ActionsWrapper");
const BodyCell_1 = require("./components/BodyCell");
const FootCell_1 = require("./components/FootCell");
const GroupCell_1 = require("./components/GroupCell");
const HeadCell_1 = require("./components/HeadCell");
const Row_1 = require("./components/Row");
const controller_1 = require("./controller");
const RoundedCorners = (0, styled_components_1.default)(Div_1.Div) `
  ${(0, styles_1.borderRadiusMixin)('large')};
  ${styles_1.borderMixin}
  overflow: hidden;

  thead th {
    border-top: none;
  }

  tfoot tr:last-of-type td {
    border-bottom: none;
  }

  tbody tr:last-of-type:not(:only-of-type) td {
    border-bottom: none;
  }
`;
const Wrapper = styled_components_1.default.table `
  table-layout: fixed;
  width: 100%;
  margin: auto;
  position: relative;
  padding: 0 ${(0, styles_1.spacing)(0.75)};

  &.${controller_1.SHOW_SHADOW_CLASS_NAME} {
    ${HeadCell_1.HeaderCellWrapper}:first-child,
    ${BodyCell_1.BodyCell}:first-child,
    ${FootCell_1.FootCell}:first-child {
      ::after {
        border-right-width: 1px;
      }
    }
  }
`;
exports.LoadingCell = styled_components_1.default.div `
  height: 24px;
  width: 65%;
  ${styles_1.borderRadiusMixin};

  background: linear-gradient(-45deg, ${styles_1.colors.lightGray}, ${styles_1.colors.lightGray2});
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
const THead = styled_components_1.default.thead `
  position: sticky;
  top: 0;
  z-index: 2;

  ${styled_system_1.top};
`;
const TGroup = styled_components_1.default.thead `
  position: sticky;
  top: 0;
  z-index: 2;
`;
const TBody = styled_components_1.default.tbody ``;
const GroupRow = styled_components_1.default.tr `
  ${({ onClick }) => (Boolean(onClick) ? 'cursor: pointer' : '')};

  top: 40px;
  z-index: 5;
  position: sticky;

  background-color: ${styles_1.colors.white};

  & td::after {
    ${(0, styles_1.borderMixin)(styles_1.colors.blueGray)};
    border-width: 1px 0;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
  }
`;
const TFoot = styled_components_1.default.tfoot `
  ${({ sticky }) => sticky &&
    (0, styled_components_1.css) `
      bottom: -1px;
      z-index: 2;
      position: sticky;

      td:first-child {
        z-index: 4;
      }
    `}
`;
const Table = ({ children, onLoadMore }) => {
    const controller = React.useRef(new controller_1.TableController());
    React.useEffect(() => {
        return controller.current.registerListeners(onLoadMore);
    }, []);
    return (React.createElement(Wrapper, { ref: controller.current.setTableRef },
        children,
        React.createElement(Div_1.Div, { key: "horizontal-sentinel", position: "absolute", ref: controller.current.setShowLineSentinelRef, left: 0, top: 0, bottom: 0, width: 5 }),
        React.createElement(Div_1.Div, { key: "vertical-sentinel", position: "absolute", ref: controller.current.setLoadMoreSentinelRef, left: 0, bottom: 0, maxHeight: 500, height: "100%", right: 0, zIndex: -1 })));
};
exports.Table2 = {
    Wrapper: Table,
    THead,
    Th: HeadCell_1.HeaderCell,
    ThWrapper: HeadCell_1.HeaderCellWrapper,
    TBody,
    TFoot,
    TGroup,
    Tr: Row_1.Row,
    TrGroup: GroupRow,
    Td: BodyCell_1.BodyCell,
    TdGroup: GroupCell_1.GroupCell,
    TdFoot: FootCell_1.FootCell,
    ActionsWrapper: ActionsWrapper_1.ActionsWrapper,
    RoundedCorners,
    LoadingCell: exports.LoadingCell,
};
