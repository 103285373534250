"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Card = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const styles_1 = require("../../styles");
const Div_1 = require("../Div");
const createTopArrow = (offset = 0) => (0, styled_components_1.css) `
  content: '▲';

  left: 50%;
  top: -16px;

  text-shadow: 0px -2px 2px rgb(0 0 0 / 10%);
  transform: translateX(calc(-50% + ${offset}px));
`;
const createBottomArrow = (offset = 0) => (0, styled_components_1.css) `
  content: '▼';

  left: 50%;
  bottom: -16px;

  text-shadow: 0px 2px 2px rgb(0 0 0 / 10%);
  transform: translateX(calc(-50% + ${offset}px));
`;
const createLeftArrow = (offset = 0) => (0, styled_components_1.css) `
  content: '◀';

  left: -12px;
  top: 50%;

  text-shadow: -2px 0px 2px rgb(0 0 0 / 10%);
  transform: translateY(calc(-50% + ${offset}px));
`;
const createRightArrow = (offset = 0) => (0, styled_components_1.css) `
  content: '▶';

  right: -12px;
  top: 50%;

  text-shadow: 2px 0px 2px rgb(0 0 0 / 10%);
  transform: translateY(calc(-50% + ${offset}px));
`;
exports.Card = (0, styled_components_1.default)(Div_1.Div)(({ arrowPosition, arrowOffset }) => (0, styled_components_1.css) `
    background: ${styles_1.colors.white};

    ${styles_1.borderRadiusMixin};
    ${styles_1.boxShadowMixin};

    position: relative;

    ${arrowPosition &&
    (0, styled_components_1.css) `
        &::before {
          position: absolute;
          font-size: 16px;
          color: ${styles_1.colors.white};

          ${{
        right: createRightArrow,
        left: createLeftArrow,
        top: createTopArrow,
        bottom: createBottomArrow,
    }[arrowPosition](arrowOffset)};
        }
      `}
  `);
