import { box, fromNullable } from '@basisboard/basis-ui/es/utils'
import { Container, getContainer } from '@containrz/react-hook'
import equals from 'ramda/src/equals'
import isEmpty from 'ramda/src/isEmpty'
import { ViewId } from '../../constants'
import { FieldsState } from '../../containers/Fields/container'
import { eventBus, EventBusType } from '../../services'
import { View } from '../../templates/ViewsScreen'
import { appRepository } from '../App/container'
import { GroupsState } from '../Groups/state'
import { SortsStateInstances } from '../Sorts'
import {
  getSharedCustomFiltersForView,
  setCustomFiltersForView,
  setPublicCustomFiltersForView,
  updateFiltersForView,
} from './api'
import { clearFilter, getReadableFilter } from './helpers'
import { CustomFilter } from './types'

interface CreateCustomFilterParam {
  newFilter: CustomFilter
  includeFields?: boolean
  includeGroup?: boolean
  includeSorts?: boolean
  view?: View
}

interface State {
  filter: {
    [key: string]: any
  }
  customFilters: CustomFilter[]
  sharedCustomFilters: CustomFilter[]
  error: boolean
  loading: boolean
  viewId: ViewId
}

export class FiltersState extends Container<State> {
  constructor(viewId: ViewId) {
    super()

    const settings = fromNullable(appRepository().state.profile?.settings).fold(
      () => null,
      settings => settings,
    )

    this.state = {
      filter: settings?.[`${viewId}-filter`] || {},
      customFilters: settings?.[`${viewId}-customFilters`]?.customFilters || [],
      sharedCustomFilters: [],
      error: false,
      loading: true,
      viewId,
    }
  }

  loadFilters = () => {
    const appData = appRepository()

    const settings = fromNullable(appData.state.profile?.settings).fold(
      () => null,
      settings => settings,
    )

    this.setState({
      filter: settings?.[`${this.state.viewId}-filter`] || {},
      sharedCustomFilters: [],
      customFilters: settings?.[`${this.state.viewId}-customFilters`]?.customFilters || [],
      error: false,
      loading: false,
    })

    getSharedCustomFiltersForView(this.state.viewId).then((sharedCustomFilters: CustomFilter[]) => {
      this.setState({ sharedCustomFilters })
      eventBus.publish(EventBusType.LoadedFilters, this.state.viewId)
    })
  }

  destroy = () => {
    delete FiltersStateInstances[this.state.viewId]
  }

  applyFilter = (filter: unknown) => {
    const cleared = clearFilter(filter)

    this.setState({ filter: cleared })

    eventBus.publish(EventBusType.ApplyFilter, {
      viewId: this.state.viewId,
      filter,
      ...Object.keys(cleared).reduce(
        (acc, key) => ({
          ...acc,
          [key]: getReadableFilter(this.state?.customFilters || [], key, cleared[key]),
        }),
        {},
      ),
    })

    return updateFiltersForView(this.state.viewId, cleared)
  }

  setCustomFilters = (filters: CustomFilter[], store = true) => {
    const customFilters = filters.filter(f => f.visibility !== 'shared')
    const sharedCustomFilters = filters.filter(f => f.visibility === 'shared')

    this.setState({ customFilters, sharedCustomFilters })

    return store
      ? Promise.all([
          setCustomFiltersForView(this.state.viewId, customFilters),
          setPublicCustomFiltersForView(this.state.viewId, sharedCustomFilters),
        ])
      : new Promise(resolve => resolve({}))
  }

  addCustomFilters = ({
    newFilter,
    includeFields = false,
    includeGroup = false,
    includeSorts = false,
    view,
  }: CreateCustomFilterParam) =>
    box(({
      ...newFilter,
      fields: includeFields
        ? view?.fields || getContainer(FieldsState).state[this.state.viewId]
        : [],
      group: includeGroup ? view?.group || getContainer(GroupsState).state.currentGroup : null,
      sorts: includeSorts
        ? view?.sorts || getContainer(SortsStateInstances(this.state.viewId)).state.advancedSorts
        : null,
    } as never) as CustomFilter).fold(customFilter =>
      this.setCustomFilters([
        ...this.state.customFilters,
        ...this.state.sharedCustomFilters,
        customFilter,
      ]).then(() => eventBus.publish(EventBusType.CreateCustomFilter, { customFilter })),
    )

  editCustomFilters = (
    originalName: string,
    {
      newFilter,
      includeFields = false,
      includeGroup = false,
      includeSorts = false,
      view,
    }: CreateCustomFilterParam,
    store = true,
  ) =>
    box(({
      ...newFilter,
      fields: includeFields
        ? view?.fields || getContainer(FieldsState).state[this.state.viewId]
        : [],
      group: includeGroup ? view?.group || getContainer(GroupsState).state.currentGroup : null,
      sorts: includeSorts
        ? view?.sorts || getContainer(SortsStateInstances(this.state.viewId)).state.advancedSorts
        : null,
    } as never) as CustomFilter).fold(customFilter =>
      this.setCustomFilters(
        [...this.state.customFilters, ...this.state.sharedCustomFilters].map(f =>
          f.name === originalName ? customFilter : f,
        ),
        store,
      ).then(() => eventBus.publish(EventBusType.EditCustommFilter, { customFilter })),
    )

  updateFieldsFromCurrentCustomFilter = (
    newFilter: CustomFilter,
    store: boolean,
    params?: CreateCustomFilterParam,
  ) =>
    this.editCustomFilters(
      newFilter.name,
      params || {
        newFilter,
        includeFields: !isEmpty(newFilter.fields || []),
        includeGroup: Boolean(newFilter.group),
        includeSorts: Boolean(newFilter.sorts),
      },
      store,
    )

  deleteCustomFilters = (customFilter: CustomFilter) =>
    this.setCustomFilters(
      [...this.state.customFilters, ...this.state.sharedCustomFilters].filter(
        f => f.name !== customFilter.name,
      ),
    ).then(() => eventBus.publish(EventBusType.DeleteCustomFilter, { customFilter }))

  reset = () => {
    this.applyFilter({})
    eventBus.publish(EventBusType.ClearAllFilters)
  }

  getReadableFilter = (key: string, value: any) =>
    getReadableFilter(
      [...this.state?.customFilters, ...this.state.sharedCustomFilters] || [],
      key,
      value,
    )

  getAppliedCustomFilter = () =>
    [...this.state.customFilters, ...this.state.sharedCustomFilters].find(cf =>
      equals(JSON.stringify(cf.filter), JSON.stringify(this.state.filter?.customFilter || {})),
    )
}

export const filtersStateInstancesDict = {}

export const FiltersStateInstances = (viewId): FiltersState => {
  if (filtersStateInstancesDict[viewId]) {
    return filtersStateInstancesDict[viewId]
  } else {
    const FiltersInstance = new FiltersState(viewId)
    filtersStateInstancesDict[viewId] = FiltersInstance

    return FiltersInstance
  }
}
