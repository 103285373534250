import {
  EmailStatus,
  GetInboxMessageDetailResponseBody,
  GetInboxMessageIdsRequestQuery,
  GetInboxMessagesRequestQuery,
  InboxMessage,
  InboxSortOptions,
  MESSAGE_ID,
  PatchMessageRequestBody,
} from '@basisboard/basis-common/lib/api'
import { fromNullable, isUuid } from '@basisboard/basis-ui/es/utils'
import { Container, getContainer } from '@containrz/react-hook'
import uniq from 'ramda/src/uniq'
import uniqBy from 'ramda/src/uniqBy'
import { eventBus, EventBusType } from '../../services'
import { deleteMessage } from '../Emails/api'
import { projectRepository } from '../Projects/container'
import {
  getBaseMessage,
  getMessageDetails,
  getMessageIds,
  getMessages,
  getWorkspaceSetting,
  patchMessageDetails,
  separateMessage,
  setWorkspaceSetting,
} from './api'

interface State {
  messages: InboxMessage[]
  messageDetails: GetInboxMessageDetailResponseBody[]
  duplicates: GetInboxMessageDetailResponseBody[]
  totalPendingMessages: number
  dataQualityReport: null | { subject: string; message: string }
  rememberDeclineWithReason: boolean | null
  requiresRefreshId: MESSAGE_ID[]
}

export const REMEMBER_DECLINE_WITH_REASON = 'remember-decline-with-reason'

export class InboxContainer extends Container<State> {
  state = {
    messages: [],
    duplicates: [],
    messageDetails: [],
    totalPendingMessages: 0,
    dataQualityReport: null,
    rememberDeclineWithReason: null,
    requiresRefreshId: [],
  } as State

  unregister: () => void

  constructor() {
    super()

    this.unregister = eventBus.register(
      EventBusType.InboxMessageRequiresRefresh,
      ({ messageIds }: { messageIds: MESSAGE_ID[] }) =>
        this.setState(s => ({ requiresRefreshId: uniq([...s.requiresRefreshId, ...messageIds]) })),
    ).unregister
  }

  destroy = () => this.unregister()

  loadMessages = (query?: GetInboxMessagesRequestQuery) =>
    getMessages(query).then(response => {
      this.setState({
        messages: uniqBy(({ id }) => id, [...response.messages, ...this.state.messages]),
      })

      return response
    })

  loadAllMessageIds = (query?: GetInboxMessageIdsRequestQuery) =>
    new Promise<MESSAGE_ID[]>(async resolve => {
      const ids = []

      const getIds = async () =>
        getMessageIds({ ...query, offset: ids.length }).then(response => {
          ids.push(...response.messageIds)

          if (response.messageIds.length > 0) {
            getIds()
          }
        })

      await getIds()

      resolve(ids)
    })

  loadMessageDetails = (messageId: MESSAGE_ID) =>
    fromNullable(
      this.state.requiresRefreshId.includes(messageId)
        ? null
        : this.state.messageDetails.find(m => m.message.id === messageId),
    ).fold(
      () =>
        getMessageDetails(messageId).then(async message => {
          const duplicates = await Promise.all(
            message.duplicateEmailIds.map(id => this.loadBaseMessage(id)),
          )
          const grouped = await Promise.all(
            message.groupedEmailIds.map(id => this.loadBaseMessage(id)),
          )

          this.setState(s => ({
            messageDetails: uniqBy(m => m.message.id, [...s.messageDetails, message]),
            duplicates: uniqBy(m => m.message.id, [...duplicates, ...grouped, ...s.duplicates]),
          }))

          if (isUuid(message.suggestedLink?.project?.id || '')) {
            projectRepository().getProjectsFromIds([
              message.suggestedLink.project.id,
              ...(message.suggestedProjects || []).map(s => s.id),
            ])
          }

          if (this.state.requiresRefreshId.includes(messageId)) {
            this.setState(s => ({
              requiresRefreshId: s.requiresRefreshId.filter(id => id !== messageId),
            }))
          }

          return { message, duplicates, grouped }
        }),
      message => {
        const duplicates = message.duplicateEmailIds
          .map(id => this.state.duplicates.find(d => d.message.id === id))
          .filter(Boolean)
        const grouped = message.groupedEmailIds
          .map(id => this.state.duplicates.find(d => d.message.id === id))
          .filter(Boolean)

        return Promise.resolve({ message, duplicates, grouped })
      },
    )

  loadBaseMessage = (messageId: MESSAGE_ID) => getBaseMessage(messageId)

  getTotalPendingMessages = () =>
    getMessages({
      filter: { status: EmailStatus.Unlinked },
      limit: 1,
      sorts: [{ name: InboxSortOptions.TimeReceived }],
    }).then(response => {
      this.setState({ totalPendingMessages: response.paging.total })
      return response
    })

  editInboxMessage = (messageId: MESSAGE_ID, body: PatchMessageRequestBody) => {
    this.setState(s => ({
      messages: s.messages.map(m => (m.id === messageId ? { ...m, ...body } : m)),
      messageDetails: s.messageDetails.map(m =>
        m.message.id === messageId ? { ...m, message: { ...m.message, ...body } } : m,
      ),
    }))

    return patchMessageDetails(messageId, body)
  }

  separateInboxMessage = (messageId: MESSAGE_ID) =>
    separateMessage(messageId).then(() => this.loadMessages())

  deleteMessage = (messageId: MESSAGE_ID) => {
    this.setState(s => ({
      messages: s.messages.filter(m => m.id !== messageId),
    }))

    return deleteMessage(messageId)
  }

  setDataQualityReport = (report: State['dataQualityReport']) => {
    this.setState(s => ({ ...s, dataQualityReport: report }))
  }

  getRememberDeclineWithReason = () =>
    getWorkspaceSetting(REMEMBER_DECLINE_WITH_REASON).then(rememberDeclineWithReason =>
      this.setState(s => ({ ...s, rememberDeclineWithReason })),
    )

  setRememberDeclineWithReason = (
    rememberDeclineWithReason: State['rememberDeclineWithReason'],
  ) => {
    this.setState(s => ({ ...s, rememberDeclineWithReason }))

    return setWorkspaceSetting(REMEMBER_DECLINE_WITH_REASON, rememberDeclineWithReason)
  }
}

export const inboxRepository = () => getContainer(InboxContainer)
