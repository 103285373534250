import { retryLazyLoad } from '@basisboard/basis-ui/es/utils'
import { openModal } from '../../Modal'
import { CreatePlatformModalProps } from './CreatePlatform/types'

export const openCreatePlatformModal = (props: CreatePlatformModalProps = {}) =>
  openModal({
    Component: retryLazyLoad(() => import('./CreatePlatform')),
    width: 576,
    name: 'CreatePlatform',
    props,
  })
