import { ExperimentType, FeatureTypes } from './types';

export const MAP_FEATURE_TYPE_TO_AB: Record<ExperimentType, FeatureTypes> = {
  [ExperimentType.UpdatesBanner]: {
    a: {
      showBanner: true,
    },
    b: {
      showBanner: false,
    },
  },

  [ExperimentType.CreateTaskAfterLoggingQuote]: {
    a: {
      showAtEnd: true,
      showAtNewStep: false,
    },
    b: {
      showAtEnd: false,
      showAtNewStep: true,
    },
  },
};
