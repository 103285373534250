import {
  borderMixin,
  borderRadiusMixin,
  colors,
  cssSize,
  spacing,
  transitionMixin,
} from '@basisboard/basis-ui/es/styles'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div.attrs({
  tabIndex: 0,
})<{ focused?: boolean; hideBorder?: boolean; hideIcon?: boolean }>`
  box-sizing: border-box;
  min-height: 40px;
  ${borderRadiusMixin};
  ${borderMixin('default')};
  position: relative;
  padding: ${spacing(0.5)} ${spacing(2)};
  padding-left: ${spacing(5)};
  width: 100%;

  background: ${colors.white};

  display: flex;
  align-items: center;

  outline: 0;

  ${transitionMixin};

  &:hover {
    ${borderMixin('hover')};
  }

  ${({ hideBorder }) =>
    hideBorder &&
    css`
      border-color: transparent;
    `}
    
  ${({ hideIcon }) =>
    hideIcon &&
    css`
      padding-left: ${spacing(2)};
    `}

  ${({ focused }) =>
    focused &&
    css`
      border-color: ${colors.accent};
    `}
`

export const Input = styled.input`
  border: 0;
  outline: none;
  height: 100%;

  background: transparent;
  color: ${colors.darkBlue};
  font-size: 0.875rem;
  font-weight: 500;

  &:focus {
    width: 100%;
    height: auto;
    outline: none;
  }
`

export const CalendarDay = styled.div.attrs<{ isSelected?: boolean }>(({ isSelected }) => ({
  'aria-selected': isSelected,
}))<{ isSelected?: boolean }>`
  ${cssSize(32)};

  ${borderRadiusMixin};

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ isSelected }) => isSelected && `background: ${colors.accent}`};
`
